import React, { ComponentProps, PropsWithChildren } from 'react';

import { AppTheme } from '@/components/lib/theme';
import { textVariants } from '@/components/lib/typography';
import { createText } from '@shopify/restyle';

export type StyleVariant = keyof typeof textVariants;

const RestyleText = createText<AppTheme>();

export type TextProps = PropsWithChildren<ComponentProps<typeof RestyleText>>;

export const Text = ({ children, color = 'gray1', ...props }: TextProps) => {
    return (
        <RestyleText {...props} color={color} allowFontScaling={false}>
            {children}
        </RestyleText>
    );
};
