import React from 'react';

import VoidedProgressDot from '@/assets/icons/voided-progress-dot';
import { Box, Row } from '@/components/lib/components';

export const DotStatus = {
    Open: 'open',
    Won: 'won',
    Lost: 'lost',
    Voided: 'voided',
} as const;

export type DotStatus = (typeof DotStatus)[keyof typeof DotStatus];

const backgroundColors = {
    [DotStatus.Open]: 'gray5',
    [DotStatus.Won]: 'green',
    [DotStatus.Lost]: 'red',
} as const;

const ProgressDot = ({ dot }: { dot: DotStatus }) => {
    if (dot === DotStatus.Voided) {
        return <VoidedProgressDot />;
    }
    const backgroundColor = backgroundColors[dot];
    return <Box width={8} height={8} borderRadius="r96" backgroundColor={backgroundColor} />;
};

export const ProgressDots = ({ dots }: { dots: Array<DotStatus> }) => {
    return (
        <Row columnGap="s4">
            {dots.map((dot, index) => (
                <ProgressDot key={`${dot}-${index}`} dot={dot} />
            ))}
        </Row>
    );
};
