import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import InfoIcon from '@/assets/icons/info-full-white';
import { ContactSupport } from '@/components/ContactSupport';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useRemovableCards } from '@/feature/account-details/hooks/use-fetch-removable-cards';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import {
    DueDiligence,
    DueDiligenceReasons,
    useAuthUserConfig,
    userDueDiligenceReasonSelector,
    userDueDiligenceSelector,
} from '@/hooks/use-auth-user-config';
import { LoadingScreen } from '@/screens/LoadingScreen';
import { RemovableCardType } from '@/types/removable-cards';

import { RemovableCard } from '../components/removable-card/RemovableCard';
import { useProcessCardRemoval } from '../hooks/use-process-card-removal';

export const ManageDebitCards = () => {
    const { t } = useTranslation('manage_cards');
    const navigation = useNavigation();
    const { data: userDueDiligence } = useAuthUserConfig({ select: userDueDiligenceSelector });
    const { data: userDueDiligenceReason } = useAuthUserConfig({ select: userDueDiligenceReasonSelector });
    const { data: removableCards = [], isInitialLoading: isLoadingRemovableCards } = useRemovableCards();

    const { handleRemovalProcess, isProcessingCardRemoval } = useProcessCardRemoval();

    // If the user has failed EDD and the reason is passive, then the user is not allowed to remove the card
    const hasFailedEDD =
        userDueDiligence === DueDiligence.EDD_FAILED && userDueDiligenceReason === DueDiligenceReasons.PASSIVE;

    const handleOnClose = () => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
    };

    const isCardInteractable = (card: RemovableCardType) => {
        // Condition 1: The card must be marked as removable.
        // This ensures that only cards flagged as removable are eligible for further checks.

        // Condition 2: The card must either:
        // - Not have failed the EDD (Eligibility Due Diligence) check if the card is NOT expired.
        // - OR, if the card is expired, the EDD check is ignored. This means expired cards can still be interactable,
        //   even if they failed EDD, and will not show as disabled simply due to the EDD failure.

        // Condition 3: The remaining required distribution (closed_loop_remain) must be less than $500.
        // If the remaining amount is $500 or more, the card cannot be removed, regardless of the other conditions.
        return card.is_removable && (!hasFailedEDD || card.is_expired) && card.closed_loop_remain < 500;
    };

    const hasInteractableCards = removableCards?.some(isCardInteractable);
    const showContactSupport = !hasInteractableCards;

    const renderScreenInfo = () => (
        <Box marginBottom="s24">
            <Text variant="headlineMedium" textAlign="center" marginBottom="s8" fontWeight="500">
                {t('choose_debit_card_to_remove')}
            </Text>
            <Text variant="bodyMedium" textAlign="center" color="gray3">
                {t('choose_debit_card_to_remove_description')}
            </Text>
        </Box>
    );

    const renderContent = () => {
        if (isLoadingRemovableCards || isProcessingCardRemoval) {
            return <LoadingScreen />;
        }

        return (
            <Box flex={1} alignContent="space-between">
                <Box flex={1} marginTop="s20">
                    {renderScreenInfo()}
                    {removableCards.map((cardData, index) => (
                        <RemovableCard
                            key={`${cardData.payment_type_id}-${index}`}
                            card={cardData}
                            isInteractable={isCardInteractable(cardData)}
                            handleRemovalProcess={handleRemovalProcess}
                        />
                    ))}
                    {removableCards.length === 0 ? (
                        <Box marginTop="s24">
                            <Banner
                                leftIcon={<InfoIcon />}
                                message={t('debit_card_removal_info')}
                                borderColor={'gray5'}
                                borderWidth={1}
                            />
                        </Box>
                    ) : null}
                </Box>
                {showContactSupport ? (
                    <Box marginHorizontal="s20">
                        <SizedBox value={56} />
                        <ContactSupport />
                        <SizedBox value={50} />
                    </Box>
                ) : null}
            </Box>
        );
    };

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('manage_cards'), closeIconMode: 'back', onClose: handleOnClose }}
            scrollViewProps={{
                contentContainerStyle: {
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                },
                style: {
                    paddingHorizontal: 0,
                },
            }}
        >
            <MaxWidthWrapper paddingHorizontal={'s16'} flex={1}>
                {renderContent()}
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
