import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

// This hook checks if the given league use primary hud
export const useIsPrimaryHud = (eventLeagueId: string | undefined) => {
    const leagues = useJurisdictionStore(store => store.jurisdictionSettings?.productConfig?.leagues);
    if (!eventLeagueId) {
        return false;
    }
    const isPrimaryHud = leagues?.find(league => Number(league.id) === Number(eventLeagueId))?.primary_hud;
    return !!isPrimaryHud;
};
