import React from 'react';
import { FlatList, StyleSheet } from 'react-native';

import { SizedBox } from '@/components/SizedBox';
import { useGetOrderedLeaguesByEvents } from '@/hooks/use-fantasy-league-configs';
import { useResumeEffect } from '@/hooks/use-resume';
import { common } from '@/styles/styles';
import { isWeb, showScrollIndicator } from '@/utils/constants-platform-specific';

import { LeagueButtonTitle } from './LeagueButtonTitle';
import { LobbyLeagueSkeleton } from './Skeletons/LobbyLeagueSkeleton';

const skeletonArray = isWeb ? new Array(8) : new Array(4);
export const LobbyLeagues = () => {
    const { fetching, isSorting, refetch: reload, sortedLeagues } = useGetOrderedLeaguesByEvents();

    useResumeEffect(reload);
    const leaguesData = fetching || isSorting ? skeletonArray : sortedLeagues;

    return (
        <FlatList
            horizontal
            data={leaguesData}
            style={[common.noGrow]}
            contentContainerStyle={styles.list}
            showsHorizontalScrollIndicator={showScrollIndicator}
            ItemSeparatorComponent={ItemSeparatorComponent}
            renderItem={({ item, index }) => {
                if (fetching || isSorting) {
                    return <LobbyLeagueSkeleton />;
                }

                return (
                    <LeagueButtonTitle
                        key={index}
                        {...item.league}
                        testID={`league-${index}`}
                        eventsCount={item.eventsCount}
                    />
                );
            }}
            testID="league-flatList"
        />
    );
};

const styles = StyleSheet.create({
    list: {
        paddingHorizontal: 16,
    },
});

const ItemSeparatorComponent = () => <SizedBox value={12} />;
