import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';

import { Text } from '@/components/TextComponent';
import { Column, Image, Row } from '@/components/lib/components';
import { hasMadeFirstDepositSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { common } from '@/styles/styles';

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#4F0665',
        borderRadius: 12,
        height: 80,
        overflow: 'hidden',
    },
    img: {
        height: '100%',
        maxHeight: 80,
        flex: 1,
    },
});

export const FTDBanner = (): ReactElement | null => {
    const ftdSettings = useJurisdictionStore(state => state.jurisdictionSettings?.globalSettings?.ftdSetting);
    const { data: hasMadeFirstDeposit } = useAuthUserConfig({
        select: hasMadeFirstDepositSelector,
    });

    if (!ftdSettings) {
        return null;
    }

    const { ftd_banner_heading, ftd_banner_body } = ftdSettings;
    const ftd_banner_image_url = ftdSettings.ftd_banner_image.url;
    const { width = 80, height = 80 } = ftdSettings.ftd_banner_image.dimensions ?? { width: 80, height: 80 };

    const aspectRatio = width / height;

    // Return null if the user has made their first deposit
    if (hasMadeFirstDeposit === undefined || hasMadeFirstDeposit) {
        return null;
    }
    // Return null if the relavant prismic contents are empty
    if (!ftd_banner_heading && !ftd_banner_body && !ftd_banner_image_url) {
        return null;
    }

    return (
        <Row gap="s8" style={[styles.container, common.noPadding, common.spaceBetweenRow]}>
            <Column flex={1} justifyContent={'center'} paddingLeft={'s16'}>
                {ftd_banner_heading ? (
                    <Text variant="titleLarge" fontWeight="600" color="gray1">
                        {ftd_banner_heading}
                    </Text>
                ) : null}
                {ftd_banner_body ? (
                    <Text variant="bodySmall" fontWeight="400" color="gray2">
                        {ftd_banner_body}
                    </Text>
                ) : null}
            </Column>
            {ftd_banner_image_url ? (
                <Column justifyContent={'flex-end'} alignContent="stretch" height="100%">
                    <Image
                        source={{ uri: ftd_banner_image_url }}
                        resizeMode="contain"
                        style={[styles.img, { aspectRatio }]}
                    />
                </Column>
            ) : null}
        </Row>
    );
};
