import React from 'react';
import { useTranslation } from 'react-i18next';

import { SizedBox } from '@/components/SizedBox';

import { KycStepContainer } from '../../components/KycStepContainer';
import { kycFlowTypes } from '../../const';
import { useKycFieldsStore } from '../../hooks/use-kyc-fields';
import { SsnInput, SsnLearnMore } from './SsnStep';

export const SsnRetry = () => {
    const { values, setFieldValue } = useKycFieldsStore(state => ({
        values: state.values,
        setFieldValue: state.setFieldValue,
    }));

    const { t } = useTranslation('kyc');

    return (
        <KycStepContainer
            kycFlowType={kycFlowTypes.RETRY}
            buttonText={'common:next'}
            title={t('ssn_full_retry_header')}
            subText={t('ssn_full_copy')}
            isNextDisabled={values.ssn.length < 9}
        >
            <SsnInput value={values.ssn} setFieldValue={value => setFieldValue('ssn', value)} />
            <SizedBox value={16} />
            <SsnLearnMore />
        </KycStepContainer>
    );
};
