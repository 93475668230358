import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { FeaturedLineupsScreen } from '@/feature/lobby/components/FeaturedLineupsScreen';
import { LobbyScreen } from '@/feature/lobby/screens/Lobby';
import { PickemSportScreen } from '@/feature/lobby/screens/PickemSport';
import { PickemLobbyStackParamList } from '@/navigation/pickem/types';

const Stack = createNativeStackNavigator<PickemLobbyStackParamList>();

export const LobbyStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="LobbyScreen" component={LobbyScreen} />
            <Stack.Screen name="PickemSportScreen" component={PickemSportScreen} />
            <Stack.Screen name="FeaturedLineupsScreen" component={FeaturedLineupsScreen} />
        </Stack.Navigator>
    );
};
