import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useAddUserLimits } from '@/feature/responsible-gaming/hooks/use-add-user-limits';
import { getConfirmDialogData } from '@/feature/responsible-gaming/utils/utils';
import { AccountTabNavigationProp, RootStackParamList } from '@/navigation/types';

import { LimitsModal } from '../components/LimitModal';
import { LIMIT_DURATION } from '../constants';
import { useAddLimitErrorMessage } from '../hooks/use-add-limit-error-message';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'DepositLimitEdit'>;

export const DepositLimitEdit = ({ route: { params } }: ScreenProps) => {
    const { t } = useTranslation(['rg', 'common']);
    const navigation = useNavigation<AccountTabNavigationProp>();

    const { duration } = params;
    const durationTextLower = `${LIMIT_DURATION[duration].toLowerCase()} deposit`;
    const { getErrorMessage } = useAddLimitErrorMessage(duration, 'DEPOSIT');
    const errorMessageHandler = (value: number) => getErrorMessage(value);
    const { showInfoSheet } = useAlerts();
    const { mutate: confirmLimit } = useAddUserLimits();

    const onConfirmPress = (amount: number) => {
        confirmLimit(
            { type: 'DEPOSIT', duration, amount },
            {
                onSuccess: () => {
                    BetrAnalytics.trackProductEvent(
                        amount
                            ? AnalyticsEvent.CONFIRM_UPDATE_DEPOSIT_LIMIT
                            : AnalyticsEvent.CONFIRM_REMOVE_DEPOSIT_LIMIT,
                        {
                            duration,
                            amount,
                        }
                    );
                    navigation.popToTop();
                },
            }
        );
    };

    const handlePress = (amount: number) => {
        BetrAnalytics.trackProductEvent(
            amount ? AnalyticsEvent.UPDATE_DEPOSIT_LIMIT : AnalyticsEvent.REMOVE_DEPOSIT_LIMIT,
            {
                duration,
                amount,
            }
        );
        const { title, description } = getConfirmDialogData({
            oldAmount: params.amount,
            newAmount: amount,
            limitName: durationTextLower,
        });

        showInfoSheet({
            title: title,
            description,
            buttonLabel: t('common:confirm'),
            handlePress: () => onConfirmPress(amount),
            showCancel: true,
            onPressCancel: () => {
                BetrAnalytics.trackProductEvent(
                    amount ? AnalyticsEvent.CANCEL_UPDATE_DEPOSIT_LIMIT : AnalyticsEvent.CANCEL_REMOVE_DEPOSIT_LIMIT,
                    {
                        duration,
                    }
                );
            },
        });
    };

    const title = t('duration_deposit_limit', { duration: LIMIT_DURATION[duration] });

    return (
        <LimitsModal
            title={title}
            buttonLabel={t('update_duration_limit', { duration: LIMIT_DURATION[duration] })}
            handleButtonPress={handlePress}
            initialValue={params.amount}
            errorMessageHandler={errorMessageHandler}
            handleRemovePress={handlePress}
        />
    );
};
