import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useSubscribeEventSgpOddsUpdate } from '@/feature/betslip-sbk/hooks/use-subscribe-event-sgp-odds-update';
import { generateSgpOddsId, isComboSelectionEnabled } from '@/feature/betslip-sbk/utils/betslip-utils';

import { useOrderedSelectionIds } from '../../hooks/betslip-hooks';
import { useSbkBetSlipStore } from '../../hooks/use-sbk-betslip-store';
import { ComboBetListItem } from './ComboBetListItem';
import { ComboBetListMatchupHeader } from './ComboBetListMatchupHeader';

type ComboBetListProps = {
    eventId: string;
    isLastEvent: boolean;
    testID?: string;
};

export const ComboBetList = ({ eventId, isLastEvent }: ComboBetListProps) => {
    const orderedSelectionIds = useOrderedSelectionIds(eventId);
    const lastItemIndex = useMemo(() => orderedSelectionIds.length - 1, [orderedSelectionIds]);
    const { t } = useTranslation('betslip_sbk');
    const isSgpError = useSbkBetSlipStore(state => state.sgpEventDisabled[eventId]);
    const isTurnedOffWarning = useSbkBetSlipStore(state => state.sgpTemporaryIssue[eventId]);
    const showErrorMessage = isSgpError && orderedSelectionIds.length > 1;

    const activeSelectionCount = useSbkBetSlipStore(
        state => Object.values(state.selections).filter(selection => isComboSelectionEnabled(selection, state)).length
    );
    const isValidSgp = !isSgpError && activeSelectionCount > 1;
    useSubscribeEventSgpOddsUpdate(eventId, isValidSgp);

    const renderItem = useCallback(
        ({ item, index }: { item: string; index: number }) => {
            const isFirstLeg = index === 0 && showErrorMessage === false;
            const isLastLeg = index === lastItemIndex;
            return (
                <ComboBetListItem
                    selectionId={item}
                    isLastEvent={isLastEvent}
                    isFirstLeg={isFirstLeg}
                    isLastLeg={isLastLeg}
                    testID={index.toString()}
                />
            );
        },
        [showErrorMessage, lastItemIndex, isLastEvent]
    );

    const { isHeaderDisabled, isConflictingError } = useSbkBetSlipStore(state => {
        const activeSelections = Object.values(state.selections).filter(
            selection => isComboSelectionEnabled(selection, state) && selection.eventId === eventId
        );
        const sgpId = generateSgpOddsId(
            activeSelections.map(({ id }) => id),
            eventId
        );
        const isConflicting = state.sgpOdds[sgpId] === false;
        return {
            isHeaderDisabled: activeSelections.length === 0 || isConflicting,
            isConflictingError: isConflicting,
        };
    });
    const showWarning = (isSgpError || isConflictingError || isTurnedOffWarning) && orderedSelectionIds.length > 1;
    const getWarningMessage = () => {
        if (isSgpError) {
            return t('event_one_leg_only_warning');
        } else if (isConflictingError) {
            return t('event_conflicting_selections_warning');
        } else if (isTurnedOffWarning) {
            return t('error_calculating_combo_warning');
        }
    };

    return (
        <Box>
            <ComboBetListMatchupHeader eventId={eventId} isDisabled={isHeaderDisabled} />

            {showWarning ? (
                <Box position="relative">
                    {/* Displaying top line on "waterfall" for the warning message  */}
                    <Box position="absolute" top={4} left={11} width={2} bottom={-5}>
                        <Box backgroundColor="gray6" flex={1} width={2} borderRadius={'r2'} />
                    </Box>
                    <Text variant="bodySmall" ml={'s36'} color="warning" mt="s12" testID="warningMessage">
                        {getWarningMessage()}
                    </Text>
                </Box>
            ) : null}

            <FlatList
                keyExtractor={id => `selection${id}-${eventId}`}
                scrollEnabled={false}
                data={orderedSelectionIds}
                renderItem={renderItem}
            />
        </Box>
    );
};
