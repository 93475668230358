import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg width={32} height={40} viewBox={'0 0 32 40'} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.588 4.321A1.861 1.861 0 0 0 3.077 5.88L.01 26.555a.932.932 0 0 0 1.08 1.052l6.421-1.135a.927.927 0 0 1 1.072.715.932.932 0 0 1-.018.469L5.25 38.803a.931.931 0 0 0 .99 1.192.929.929 0 0 0 .543-.254l24.36-23.259a.93.93 0 0 0-.805-1.588l-6.153 1.085a.928.928 0 0 1-1.09-.835.931.931 0 0 1 .097-.5l6.73-13.277a.932.932 0 0 0-.997-1.35L4.588 4.321Zm10.53 12.668c1.134.45 1.825.734 1.58-.666a4.664 4.664 0 0 0-3.575-3.742 4.647 4.647 0 0 0-4.82 1.875 4.666 4.666 0 0 0-.773 3.483c.25 1.4.816.888 1.711.087.707-.636 1.62-1.457 2.739-1.653 1.118-.197 2.243.26 3.139.616Z"
            fill={designSystem.colors.gray1}
        />
    </Svg>
);

export default SvgComponent;
