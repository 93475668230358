import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { eventKeys } from './use-event';
import { getEventMarketCategories } from './use-event-market-categories';

export const usePrefetchEventMarketCategories = (): ((eventId: string, marketCategoryId: string) => void) => {
    const queryClient = useQueryClient();

    return useCallback(
        (eventId: string, marketCategoryId: string) => {
            queryClient.prefetchQuery(eventKeys.marketCategories(eventId, marketCategoryId), () =>
                getEventMarketCategories(eventId, marketCategoryId)
            );
        },
        [queryClient]
    );
};
