// this file contains constants & hooks that are needed for the web version of the app to support a responsive design

export type SCREEN_SIZE = 'xs' | 'sm' | 'md' | 'lg';

//min and max values of screens widths
export const BREAKPOINTS: { [key in SCREEN_SIZE]: [number, number] } = {
    xs: [320, 480], // Mobile devices
    sm: [481, 768], // iPads, Tablets
    md: [769, 1024], // small screens, laptops
    lg: [1025, 10000], // desktops, large screens
};
