import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format, isValid } from 'date-fns';

import { validateDob } from '../components/utils';
import { useKycFieldsStore } from './use-kyc-fields';

/**
 * Hook for the date of birth input
 *
 * @returns showDobPicker, setShowDobPicker, handlePickerChange, dob, errorMessage, isNextDisabled
 */
export const useDobInput = () => {
    const [showDobPicker, setShowDobPicker] = useState(false);
    const { t } = useTranslation('kyc');
    const { values, setFieldValue } = useKycFieldsStore(state => ({
        values: state.values,
        setFieldValue: state.setFieldValue,
    }));

    const handlePickerChange = (selectedDate: Date | string) => {
        if (isValid(selectedDate)) {
            const formattedDate = format(selectedDate, 'MMddyyyy');
            setFieldValue('dateOfBirth', formattedDate);
        }
    };

    const errorMessage = values.dateOfBirth.length === 8 && !validateDob(values.dateOfBirth) ? t('dob_error_age') : '';

    return {
        showDobPicker,
        setShowDobPicker,
        handlePickerChange,
        setFieldValue,
        dob: values.dateOfBirth,
        errorMessage,
        isNextDisabled: !validateDob(values.dateOfBirth),
    };
};
