import React from 'react';

import DynamicPlayIcon from '@/assets/icons/dynamicPlay';
import PerfectPlayIcon from '@/assets/icons/perfectPlay';
import { designSystem } from '@/styles/styles';
import { GameMode } from '@/types/api.generated';

export type Props = {
    grayedOut: boolean;
    gameMode?: GameMode;
};

export const GameModeIcon = ({ grayedOut = false, gameMode }: Props) => {
    if (!gameMode) {
        return null;
    }

    if (gameMode === GameMode.Dynamic) {
        return <DynamicPlayIcon color={grayedOut ? designSystem.colors.gray3 : designSystem.colors.purple1} />;
    } else {
        return <PerfectPlayIcon color={grayedOut ? designSystem.colors.gray3 : designSystem.colors.white1} />;
    }
};
