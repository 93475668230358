import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { KycStepContainer } from '../../components/KycStepContainer';
import { kycFlowTypes } from '../../const';
import { useDobInput } from '../../hooks/use-dob-input';
import { DobInput } from './DobInput';

export const DobReview = () => {
    const { t } = useTranslation('kyc');
    const navigation = useNavigation();
    const { showDobPicker, dob, errorMessage, isNextDisabled, setShowDobPicker, setFieldValue, handlePickerChange } =
        useDobInput();

    const handlePress = () => {
        navigation.goBack();
    };

    return (
        <KycStepContainer
            kycFlowType={kycFlowTypes.RETRY}
            title={t('dob_retry_header')}
            subText={t('dob_copy')}
            isNextDisabled={isNextDisabled}
            onPressButton={handlePress}
            hasCloseIcon
            showDobPicker={showDobPicker}
            setShowDobPicker={setShowDobPicker}
            handleDatePickerChange={handlePickerChange}
            buttonText="common:save"
        >
            <DobInput
                value={dob}
                autoFocus={showDobPicker}
                errorMessage={errorMessage}
                setFieldValue={(value: string) => setFieldValue('dateOfBirth', value)}
                setShowDobPicker={setShowDobPicker}
            />
        </KycStepContainer>
    );
};
