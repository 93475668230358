import { URLS, createJurisdictionHeaders } from '@/data';
import { z } from 'zod';

export const WalletSchema = z.object({
    total: z.string(),
    real_amount: z.string(),
    bonus_amount: z.string(),
    free_amount: z.string(),
    real_currency: z.string(),
    next_top_up: z.number().optional(),
});

export const WalletBalanceSchema = z.object({
    wallet: WalletSchema,
    fantasy_wallet: WalletSchema,
});

export type Wallet = z.infer<typeof WalletSchema>;

export const getWalletBalances = async () => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/balances`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return WalletBalanceSchema.parse(data);
};
