import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

function SvgComponent(props) {
    return (
        <Svg width={80} height={80} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Circle cx={40} cy={40} r={40} fill="#1E1F24" />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.54 25.749l-3.528 23.799a1.07 1.07 0 001.245 1.211l7.639-1.347a1.07 1.07 0 011.212 1.36l-3.821 12.846c-.313 1.052.97 1.838 1.764 1.081L45.1 50.37l-6.106-6.952a7.363 7.363 0 01-10.957-7.585c.119-.711.977-.9 1.486-.39l.81.81a2.943 2.943 0 104.162-4.163l-.809-.81c-.51-.509-.321-1.367.39-1.486A7.363 7.363 0 0141.66 40.75l7.043 6.187 9.148-8.712c.768-.731.12-2.014-.924-1.83l-7.308 1.29a1.07 1.07 0 01-1.14-1.542l7.944-15.585a1.07 1.07 0 00-1.14-1.54l-27.997 4.936a2.141 2.141 0 00-1.746 1.795z"
                fill="#EDEFF3"
            />
        </Svg>
    );
}

export default SvgComponent;
