import React from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import { EventInfo, EventWithTeams, isIndividualEvent, isTeamEvent } from '@/api/events/types/types';
import MergedLogos, { Logo } from '@/components/MergedLogos';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { TeamLogos } from '@/components/scoreboard/types';
import { leagueConfigSelector, useLeagueConfigsStore } from '@/feature/betslip-pickem/hooks/use-league-configs-store';
import {
    PickemScoreBoardAccordion,
    PickemScoreBoardWithSubscription,
} from '@/feature/entries-pickem/components/PickemScoreBoardAccordion';
import { useEvent } from '@/feature/event-details-sbk/hooks/use-event';
import { designSystem } from '@/styles/styles';
import { EventStatus, League, Status } from '@/types/api.generated';
import { NFL_FULL_SEASON } from '@/utils/constants';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { gameUtils } from '@/utils/games';
import { isCollegeSport, nflFullSeason } from '@/utils/league';

type EntrySectionEventHeaderProps = {
    event?: EventInfo;
    status?: Status;
};

const scoreBoardSupportedLeagues = ['NFL', 'NBA', 'MLB', 'NHL'];
const notStartedStatus = [EventStatus.Scheduled, EventStatus.Delayed];

/**
 * This component renders the header for the event in the entry section
 * it can be the basic header or the one with the Scoreboard
 */
export const EntrySectionEventHeader = ({ event: pickemEvent, status }: EntrySectionEventHeaderProps) => {
    const isFullSeason = nflFullSeason(pickemEvent?.league);
    const pamEventId = pickemEvent?.dataFeedSourceIds.find(({ source }) => source === 'PAM')?.id;
    const leagueSupportsScoreboard = scoreBoardSupportedLeagues.includes(pickemEvent?.league || '');
    const isFutureEvent = notStartedStatus.includes(pickemEvent?.status || EventStatus.Scheduled);
    const showScoreboard = leagueSupportsScoreboard && !isFullSeason && pamEventId && !isFutureEvent;

    return showScoreboard && isTeamEvent(pickemEvent) ? (
        <TeamEventHeader eventInfo={pickemEvent} pamEventId={pamEventId} status={status} />
    ) : (
        <BasicEventHeader event={pickemEvent} status={status} />
    );
};

/**
 * This header gets the pam event details and renders the pickem scoreboard
 * if the pam endpoint can't find the event, it will render the basic event header
 * for live events it will render the scoreboard with subscription
 */
const TeamEventHeader = ({
    eventInfo,
    status,
    pamEventId,
}: {
    eventInfo: EventWithTeams;
    pamEventId: string;
    status?: Status;
}) => {
    const { data: event, refetch, error } = useEvent(pamEventId);

    return event && !error ? (
        <BorderTopWrapper style={styles.topWrapper}>
            {event.status === 'LIVE' ? (
                <PickemScoreBoardWithSubscription eventInfo={eventInfo} event={event} refetch={refetch} />
            ) : (
                <PickemScoreBoardAccordion eventInfo={eventInfo} event={event} />
            )}
        </BorderTopWrapper>
    ) : (
        <BasicEventHeader event={eventInfo} status={status} />
    );
};

const BasicEventHeader = ({ event }: EntrySectionEventHeaderProps) => {
    const titleLabel = event ? `${gameUtils.createTitleLabel(event)} • ` : '';
    const dateLabel = event?.date ? gameUtils.createTimeLabel(event, undefined, false) : '';
    const isFullSeason = nflFullSeason(event?.league);
    const fullTitle = isFullSeason ? NFL_FULL_SEASON : titleLabel;
    const showLeagueLogo = isIndividualEvent(event) || isFullSeason;

    const shouldHighlightLiveEvent = event ? gameUtils.isLive(event) : false;

    let teamsLogo: TeamLogos | undefined;
    let teamShortCodes;

    if (isTeamEvent(event) && !showLeagueLogo) {
        const [away, home] = event.teams;
        teamsLogo = [
            { icon: away.icon, color: away.color },
            { icon: home.icon, color: home.color },
        ];
        teamShortCodes = [away.name, home.name];
    }

    const logoSizes: [number, number] = isCollegeSport(event?.league) ? [24, 24] : [16, 16];

    return (
        <BorderTopWrapper style={styles.topBorder}>
            <Row style={styles.headerWrapper}>
                {teamsLogo ? (
                    <Box>
                        <MergedLogos
                            logos={teamsLogo}
                            borderColor={designSystem.colors.gray8}
                            renderMode="team"
                            overlap={6}
                            logoSizes={logoSizes}
                            backdropSizes={[28, 24]}
                            imageShape="square"
                            borderRadius={8}
                            teamShortCodes={teamShortCodes}
                        />
                    </Box>
                ) : null}
                {showLeagueLogo && event?.league ? <LeagueLogo league={event.league} /> : null}
                <Text marginLeft={'s8'} variant="bodySmall" color={'gray2'}>
                    {fullTitle}
                    {isFullSeason ? null : (
                        <Text
                            variant="bodySmall"
                            color={'gray2'}
                            style={shouldHighlightLiveEvent ? styles.liveStyle : {}}
                        >
                            {dateLabel}
                        </Text>
                    )}
                </Text>
            </Row>
        </BorderTopWrapper>
    );
};

const LeagueLogo = ({ league }: { league: League }) => {
    const { leagueIcon, leagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(league),
        defaultZustandCompareFunction
    );

    return (
        <Box style={{ backgroundColor: leagueColor || designSystem.colors.gray1 }} padding={'s4'} borderRadius={'r8'}>
            <Logo
                icon={leagueIcon || ''}
                logoStyle={styles.logoStyle}
                logoSize={24}
                teamFallbackTextColor={designSystem.colors.gray1}
                secondaryColor={designSystem.colors.gray1}
                renderMode={'team'}
            />
        </Box>
    );
};

const BorderTopWrapper = ({ children, style }: { children: React.ReactNode; style?: ViewStyle }) => {
    return (
        <Box
            borderColor={'gray6'}
            borderWidth={1}
            borderBottomWidth={0.33}
            borderBottomColor={'divider'}
            borderTopLeftRadius={'r16'}
            borderTopRightRadius={'r16'}
            style={style}
        >
            {children}
        </Box>
    );
};

const styles = StyleSheet.create({
    logoStyle: {
        alignSelf: 'center',
        width: 16,
        height: 16,
    },
    liveStyle: {
        color: designSystem.colors.red,
        fontWeight: '600',
    },
    headerWrapper: {
        padding: 15,
        height: 56,
        alignItems: 'center',
    },
    topWrapper: {
        paddingVertical: 5,
        paddingHorizontal: 3,
    },
    topBorder: {
        borderCurve: 'continuous',
    },
});
