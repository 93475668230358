import { URLS, createJurisdictionHeaders } from '@/data';
import { logger } from '@/utils/logging';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

import { authUserKeys } from './use-auth-user-config';
import { actions, user } from './use-user';

export const BIOMETRIC_LOG_TAG = '[biometric]: ';

type UpdateBiometricResponse = {
    data: {
        message: string;
        updated: boolean; // the return value will always be true eg. when biometric is set to false
    };
};

/**
 * Update biometric_enabled setting
 */
const updateBiometricSetting = async (value: boolean): Promise<UpdateBiometricResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/user-security`, {
        method: 'PUT',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            biometric_enabled: value,
        }),
    });
    const data = await response.json();
    if (!response.ok) {
        logger.error(BIOMETRIC_LOG_TAG, 'Error updating biometric_enabled', data);
        throw data;
    }
    return data;
};

/**
 * Custom react-query hook for updating biometric_enabled setting
 */
export const useBiometricSetting = (): UseMutationResult<UpdateBiometricResponse, unknown, boolean> => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['biometric-setting'],
        mutationFn: updateBiometricSetting,
        onSuccess: async () => {
            queryClient.invalidateQueries(authUserKeys.config());
            await actions.refresh(user.session);
        },
    });
};
