import React from 'react';
import { StyleSheet } from 'react-native';

import { Box } from '@/components/lib/components';

export type HudTeamSpotlightProps = {
    type: 'home' | 'away';
    primaryColor: string;
    secondaryColor: string;
};

const getStyle = (type: 'home' | 'away', colorType: 'primary' | 'secondary', color: string) => ({
    left: type === 'away' ? 0 : undefined,
    right: type === 'home' ? 0 : undefined,
    borderTopColor: colorType === 'primary' ? color : undefined,
    borderLeftColor: colorType === 'secondary' ? color : undefined,
    transform: type === 'home' ? [{ rotateY: '180deg' }] : undefined,
});

const styles = StyleSheet.create({
    shared: {
        position: 'absolute',
        width: 0,
        height: 0,
    },
    primaryTriangle: {
        borderRightWidth: 105,
        borderRightColor: 'transparent',
        borderTopWidth: 70,
    },
    secondaryTriangle: {
        borderLeftWidth: 35,
        borderBottomWidth: 55,
        borderBottomColor: 'transparent',
    },
});

export const HudTeamSpotlight = ({ type, primaryColor, secondaryColor }: HudTeamSpotlightProps) => {
    return (
        <>
            <Box style={[styles.shared, styles.primaryTriangle, getStyle(type, 'primary', primaryColor)]} />
            <Box style={[styles.shared, styles.secondaryTriangle, getStyle(type, 'secondary', secondaryColor)]} />
        </>
    );
};
