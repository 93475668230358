import { useTranslation } from 'react-i18next';

import { BadgeStatus } from '@/components/StatusBadge';
import {
    useEventDetailsCache,
    useEventStatus,
    useIsAllEventsFinished,
    useIsAnyEventLive,
} from '@/hooks/use-sbk-event-details-cache';
import { renderDate } from '@/utils/renderDate';
import { format } from 'date-fns';

import { Bet, BetEvent } from '../hooks/types';

export const voidedBetCardProps = {
    titleColor: 'gray3',
    subTitleColor: 'gray3',
} as const;

/**
 * Handles the footer content for all bet cards
 */
export const useFooter = (bet: Bet) => {
    const { t } = useTranslation('bets');
    const date = new Date(bet.created_at);
    const dateString = format(date, 'M/d/yy');
    const timeString = format(date, 'h:mm a');
    return `${t('placed')}: ${dateString} ${timeString}`;
};

/*
 * Get the market status for an event
 * Returns the score if the event is live or finished
 */
export const useMarketStatus = (event: BetEvent) => {
    const eventStatus = useEventStatus(event.id);
    const { homeScore, awayScore } = useEventDetailsCache(state => {
        return {
            homeScore: state.eventDetails[event.id]?.home_score ?? event.event_details?.home_score,
            awayScore: state.eventDetails[event.id]?.away_score ?? event.event_details?.away_score,
        };
    });

    const awayTeam = event.away_team?.short_code;
    const homeTeam = event.home_team?.short_code;

    if (eventStatus === 'LIVE' || eventStatus === 'FINISHED') {
        return `${awayTeam} ${awayScore} @ ${homeTeam} ${homeScore}`;
    }

    return `${awayTeam} @ ${homeTeam}`;
};

/**
 * Get the badge for Open bets. Returns 'Live' if any event is live, otherwise 'Open'
 */
export const useOpenBadge = (bet: Bet) => {
    const isLive = useIsAnyEventLive(bet.events.map(event => event.id));
    return isLive ? BadgeStatus.Live : BadgeStatus.Open;
};

export const useGameStatus = (bet: Bet) => {
    const { t } = useTranslation('common');
    const isFinished = useIsAllEventsFinished(bet.events.map(event => event.id));
    const startTime = renderDate(new Date(bet.events[0].start_time));
    //todo: add Final OT logic
    return isFinished ? `${t('final')}` : startTime;
};
