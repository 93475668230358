import React, { useEffect, useState } from 'react';
import { Switch } from 'react-native';

import { queryClient } from '@/data';
import { trackRUMAction } from '@/data/datadog';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { authUserKeys } from '@/hooks/use-auth-user-config';
import { useAuthUserSettings, useUpdateUserSettings } from '@/hooks/use-auth-user-settings';
import { designSystem } from '@/styles/styles';
import { logger } from '@/utils/logging';

type ProjectionChangeSwitchProps = {
    displayMode: 'modal' | 'preferences';
    onSwitchChange?: (value: boolean) => void;
};

const PROJECTION_CHANGE_SWITCH = '[projection change switch]: ';
export const ProjectionChangeSwitch = ({ displayMode, onSwitchChange }: ProjectionChangeSwitchProps) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const { data } = useAuthUserSettings();
    const { mutateAsync: updateUserSettings } = useUpdateUserSettings();
    const acceptAllProjectionsChanges = data && data.accept_all_odds_changes;

    useEffect(() => {
        if (acceptAllProjectionsChanges) {
            setIsEnabled(acceptAllProjectionsChanges);
        }
    }, [acceptAllProjectionsChanges]);

    const handleProjectionChangeToggle = async (value: boolean) => {
        if (displayMode === 'modal' && value) {
            BetrAnalytics.trackEvent(AnalyticsEvent.ALWAYS_ACCEPT_PROJECTION_CHANGES);
            trackRUMAction('always_accept_projection_changes');
        }
        if (onSwitchChange) {
            onSwitchChange(value);
            setIsEnabled(value);
        } else {
            setIsEnabled(value);
            try {
                await updateUserSettings({ accept_all_odds_changes: value });
                await queryClient.invalidateQueries(authUserKeys.settings());
            } catch (error: unknown) {
                setIsEnabled(!value);
                logger.error(PROJECTION_CHANGE_SWITCH, 'Error updating user settings', error);
            }
        }
    };

    return (
        <Switch
            trackColor={{ false: designSystem.colors.gray2, true: designSystem.colors.green }}
            thumbColor={designSystem.colors.gray1}
            ios_backgroundColor={designSystem.colors.gray2}
            onValueChange={handleProjectionChangeToggle}
            value={isEnabled}
        />
    );
};
