import React, { useCallback, useRef } from 'react';
import { Animated } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { ChipButton } from '@/components/ChipButton';
import { Box } from '@/components/lib/components';
import { useScrollToCenter } from '@/feature/event-details-sbk/components/SubMarketTabs';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';
import { MarketType } from '@/feature/event-details-sbk/types';
import { common } from '@/styles/styles';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

export type MicroMarketChipItem = {
    id: string;
    marketType: MarketType;
    side?: 'Home' | 'Away';
};

const keyExtractor = (item: MicroMarketChipItem) => JSON.stringify(item);

type MicroMarketChipButtonProps = {
    sportName: string;
    microMarketChipItem: MicroMarketChipItem;
    isSelected: boolean;
    onPress: (item: MicroMarketChipItem) => void;
    testID?: string;
};

const MicroMarketChipButton = ({
    sportName,
    microMarketChipItem,
    isSelected,
    onPress,
    testID,
}: MicroMarketChipButtonProps) => {
    const marketName = useMarketName({
        sportName,
        marketType: microMarketChipItem.marketType,
        fallback: microMarketChipItem.marketType.code,
        replaceTeamBySide: microMarketChipItem.side,
    });
    return (
        <Box mr="s8">
            <ChipButton
                label={marketName}
                active={isSelected}
                onPress={() => onPress(microMarketChipItem)}
                testID={testID}
            />
        </Box>
    );
};

type MicroMarketChipBarProps = {
    sportName: string;
    chipItems: MicroMarketChipItem[];
    activeMicroMarket: MicroMarketChipItem;
    setActiveMicroMarket: (item: MicroMarketChipItem) => void;
};

export const MicroMarketChipBar = ({
    sportName,
    chipItems,
    activeMicroMarket,
    setActiveMicroMarket,
}: MicroMarketChipBarProps) => {
    const flatListRef = useRef<FlatList<MicroMarketChipItem>>(null);
    const buttonRefs = useRef<Array<number>>([]);

    const scrollToCenter = useScrollToCenter(buttonRefs, flatListRef);

    const handlePressTab = useCallback(
        (item: MicroMarketChipItem, index: number) => {
            setActiveMicroMarket(item);
            scrollToCenter(index);
        },
        [setActiveMicroMarket, scrollToCenter]
    );

    const renderItem = useCallback(
        ({ item, index }: { item: MicroMarketChipItem; index: number }) => {
            return (
                <Animated.View
                    onLayout={event => {
                        buttonRefs.current[index] = event.nativeEvent.layout.width;
                    }}
                >
                    <MicroMarketChipButton
                        sportName={sportName}
                        microMarketChipItem={item}
                        isSelected={activeMicroMarket.id === item.id}
                        onPress={microMarketChipItem => handlePressTab(microMarketChipItem, index)}
                        testID={`subMarketTab-${index.toString()}`}
                    />
                </Animated.View>
            );
        },
        [activeMicroMarket, handlePressTab, sportName]
    );

    return (
        <Box marginVertical="s16">
            <FlatList<MicroMarketChipItem>
                horizontal
                ref={flatListRef}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                data={chipItems}
                showsHorizontalScrollIndicator={showScrollIndicator}
                contentContainerStyle={common.paddingHorizontal}
            />
        </Box>
    );
};
