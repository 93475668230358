import { DateData } from 'react-native-calendars/src/types';

import { TransactionDateOptions, TransactionDateOptionsType } from '../hooks/types';

type DateRange = {
    dateFrom: string | undefined;
    dateTo: string | undefined;
};

/**
 * Determines the date range based on the provided option and custom date range.
 *
 * @param option - The selected option to determine the date range.
 * @param customDateRange - An array of `YYYY-MM-DD` format strings representing custom date range.
 * @returns An object containing `dateFrom` and `dateTo` properties.
 */
export const getDateRangeFromOption = (
    option?: TransactionDateOptionsType,
    customDateRange?: DateData[]
): DateRange => {
    if (option === TransactionDateOptions.DATE_RANGE) {
        return extractDateRange(customDateRange);
    }

    const today = new Date();
    let dateFrom = today;

    switch (option) {
        case TransactionDateOptions.LAST_MONTH:
            dateFrom = new Date(today.getFullYear(), today.getMonth(), 1);
            break;
        case TransactionDateOptions.LAST_THREE_MONTH:
            dateFrom = new Date(today.getFullYear(), today.getMonth() - 2, 1);
            break;
        case TransactionDateOptions.LAST_SIX_MONTH:
            dateFrom = new Date(today.getFullYear(), today.getMonth() - 5, 1);
            break;
        case TransactionDateOptions.LAST_YEAR:
            dateFrom = new Date(today.getFullYear(), today.getMonth() - 11, 1);
            break;
        case TransactionDateOptions.ALL_TIME:
            return { dateFrom: undefined, dateTo: undefined };
        default:
            return { dateFrom: undefined, dateTo: undefined };
    }

    const dateTo = today.toISOString().split('T')[0];
    const fromDateStr = dateFrom.toISOString().split('T')[0];

    return { dateFrom: fromDateStr, dateTo };
};

export const extractDateRange = (customDateRange?: DateData[]): DateRange => {
    if (!customDateRange || customDateRange.length === 0) {
        // If the array is empty, it returns an object with `dateFrom` and `dateTo` set to `undefined`.
        return { dateFrom: undefined, dateTo: undefined };
    } else if (customDateRange.length === 1) {
        // If the array has one element, it sets both `dateFrom` and `dateTo` to the same value.
        return { dateFrom: customDateRange[0].dateString, dateTo: customDateRange[0].dateString };
    }
    // Use the dateString property for both dateFrom and dateTo
    return { dateFrom: customDateRange[0].dateString, dateTo: customDateRange[1].dateString };
};
