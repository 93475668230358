import React, { memo } from 'react';

import { Box } from '@/components/lib/components';
import { Event, Market } from '@/feature/event-details-sbk/types';

import { SplitStandardMarket } from '../../market-layouts/SplitStandardMarket';
import { PlayerRow } from './PlayerRow';

type PlayerPropProps = {
    market: Market;
    event?: Event;
    hasEventInfo?: boolean;
};

const PlayerProp = ({ market, event, hasEventInfo }: PlayerPropProps) => {
    if (!event || !market.player) {
        return null;
    }

    switch (market.layout) {
        case 'player_prop_list':
            return (
                <Box py="s16">
                    <PlayerRow market={market} event={event} hasEventInfo={hasEventInfo} />
                </Box>
            );
        case 'player_prop_standard':
        default:
            return (
                <Box gap="s16" py="s16">
                    <PlayerRow market={market} event={event} hasEventInfo={hasEventInfo} />
                    <SplitStandardMarket markets={[market]} event={event} />
                </Box>
            );
    }
};

const arePropsEqual = (prev: PlayerPropProps, next: PlayerPropProps) => prev.market.id === next.market.id;

export default memo(PlayerProp, arePropsEqual);
