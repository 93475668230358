import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { BetSlipMarketName } from '@/feature/betslip-sbk/components/BetSlipMarketName';
import { useComboOdds, usePreviousComboOdds } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { BetIds, isComboSelectionEnabled } from '@/feature/betslip-sbk/utils/betslip-utils';
import { formatEventTeams } from '@/feature/betslip-sbk/utils/formatter';

import { BetInfoSheet } from '../BetInfoSheet';

/**
 * Returns the formatted data for BetInfoSheet for all bets with changed odds.
 */
const useMultiplierChangedBets = () => {
    const { t } = useTranslation('bets');

    const comboOdds = useComboOdds();
    const comboBet = useSbkBetSlipStore(state => state.bets[BetIds.COMBO]);
    const previousOdds = usePreviousComboOdds();
    const activeSelectionCount = useSbkBetSlipStore(
        state => Object.values(state.selections).filter(selection => isComboSelectionEnabled(selection, state)).length
    );
    const betName = t('combo_bet_name', { count: activeSelectionCount });
    const showComboCard = comboOdds !== previousOdds && activeSelectionCount > 1 && comboOdds !== 1;

    const { oddsChanges, selections, options, bets, events, selectionIds } = useSbkBetSlipStore(state => ({
        oddsChanges: state.oddsChanges,
        selections: state.selections,
        options: state.options,
        bets: state.bets,
        events: state.events,
        selectionIds: state.selectionOrder,
    }));
    const multiplierChangedSelectionIds = selectionIds.filter(selectionId => !!oddsChanges[selectionId]);

    return useMemo(() => {
        const data = [];

        // Add combo bet if valid and odds have changed
        if (showComboCard) {
            data.push({
                name: betName,
                odds: comboOdds,
                previousOdds: previousOdds,
                stake: comboBet?.stake ?? 0,
                payout: comboBet?.stake ? comboBet.stake * comboOdds : 0,
            });
        }

        // Add singles bets with changed odds
        multiplierChangedSelectionIds.forEach(selectionId => {
            const bet = bets[selectionId];
            const option = options[selectionId];
            const event = events[selections[selectionId].eventId];
            if (option && event) {
                data.push({
                    name: (
                        <>
                            {`${formatEventTeams(event)} • `}
                            <BetSlipMarketName
                                eventId={selections[selectionId].eventId}
                                marketId={selections[selectionId].marketId}
                            />
                        </>
                    ),
                    odds: option.odds,
                    previousOdds: oddsChanges[selectionId],
                    stake: bet?.stake ?? 0,
                    payout: bet?.stake ? bet.stake * option.odds : 0,
                });
            }
        });

        return data;
    }, [
        betName,
        bets,
        comboBet?.stake,
        comboOdds,
        events,
        multiplierChangedSelectionIds,
        oddsChanges,
        options,
        previousOdds,
        selections,
        showComboCard,
    ]);
};

type MultiplierChangedInfoSheetProps = { dismissInfoSheet: () => void };

export const MultiplierChangedInfoSheetBody = ({ dismissInfoSheet }: MultiplierChangedInfoSheetProps) => {
    const { t } = useTranslation('betslip_sbk');
    const bets = useMultiplierChangedBets();

    // Dismiss info sheet when all multiplier changes are removed
    useEffect(() => {
        if (bets && bets.length === 0) {
            dismissInfoSheet();
        }
    }, [dismissInfoSheet, bets]);

    return (
        <BetInfoSheet
            header={
                <Box>
                    <Text variant="headlineSmall" color="gray1" textAlign="center" mb="s8">
                        {t('multiplier_changes')}
                    </Text>
                    <Text variant="bodyMedium" color="gray2" textAlign="center">
                        {t('multiplier_changes_description')}
                    </Text>
                </Box>
            }
            bets={bets}
            footerHeight={335}
        />
    );
};
