import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Path
            fill="#EDEFF3"
            d="m16.243 9.071 1.414-1.414L12 2 6.343 7.657 7.757 9.07 11 5.828V16h2V5.828l3.243 3.243Z"
        />
        <Path fill="#EDEFF3" d="M4.5 14h-2v8h19v-8h-2v6h-15v-6Z" />
    </Svg>
);
export default SvgComponent;
