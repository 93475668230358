import React from 'react';
import { Animated, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { designSystem } from '../styles/styles';

const styles = StyleSheet.create({
    root: {
        height: 1,
        backgroundColor: designSystem.colors.gray5,
    },
    vertical: {
        width: 1,
        height: '100%',
    },
});

const variantStyles = StyleSheet.create({
    light: {
        backgroundColor: designSystem.colors.white,
    },
});

type Props = {
    vertical?: boolean;
    style?: StyleProp<ViewStyle>;
    variant?: 'light';
    flex?: number;
};

export const LineSeparator = ({ vertical = false, style = {}, variant, ...rest }: Props) => {
    return (
        <Animated.View
            style={[styles.root, vertical && styles.vertical, style, variant && variantStyles[variant]]}
            {...rest}
        />
    );
};
