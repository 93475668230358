import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

function SvgComponent(props) {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none" {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.821 15.607l-4.95-4.95-1.414 1.414 6.364 6.364 12.02-12.02L20.429 5 9.82 15.607z"
                fill={props.color ?? designSystem.colors.white}
            />
        </Svg>
    );
}

export default SvgComponent;
