import React from 'react';
import { useTranslation } from 'react-i18next';

import { SizedBox } from '@/components/SizedBox';
import { TextInput } from '@/components/TextInput';
import { KycFields, useKycFieldsStore } from '@/feature/kyc/hooks/use-kyc-fields';
import { textInputKeyboardType } from '@/feature/kyc/utils/keyboardInput';
import { useFormFields } from '@/hooks/use-form-fields';

import { KycStepContainer } from '../../components/KycStepContainer';

export const isBtnDisabled = (firstName: KycFields['firstName'], lastName: KycFields['lastName']) => {
    return firstName.length === 0 || lastName.length === 0;
};

export const FirstLastNameStep = () => {
    const values = useKycFieldsStore(state => state.values);
    const setFieldValue = useKycFieldsStore(state => state.setFieldValue);
    const { t } = useTranslation('kyc');
    const { firstName, lastName } = values;
    const { saveField, focusField } = useFormFields<'last_name'>();

    return (
        <KycStepContainer
            title={t('name_header')}
            subText={t('name_copy')}
            isNextDisabled={isBtnDisabled(firstName, lastName)}
        >
            <TextInput
                label={t('first_name')}
                returnKeyType={'next'}
                onSubmitEditing={focusField('last_name')}
                value={firstName}
                onChangeText={(masked: string, unmasked: string) => {
                    setFieldValue('firstName', unmasked);
                }}
                keyboardType={textInputKeyboardType}
            />
            <SizedBox value={20} />
            <TextInput
                ref={saveField('last_name')}
                label={t('last_name')}
                returnKeyType={'done'}
                value={lastName}
                onChangeText={(masked: string, unmasked: string) => {
                    setFieldValue('lastName', unmasked);
                }}
                keyboardType={textInputKeyboardType}
            />
        </KycStepContainer>
    );
};
