import { createJurisdictionHeaders } from '@/data';
import { URLS } from '@/data/config';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { userLimitsKeys } from './query-keys';
import { AddUserLimitPayload } from './types';

/**
 * Custom react query mutation hook for adding user limits
 * POST /auth/user-limits/limits
 * @returns {Object} - react-query mutation object
 */
export const useAddUserLimits = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (userLimit: AddUserLimitPayload) => {
            const body = JSON.stringify({ limits: [userLimit] });
            return fetch(`${URLS.CHAMELON_API_URL}/auth/user-limits/limits`, {
                method: 'POST',
                headers: {
                    ...createJurisdictionHeaders(),
                    'Content-Type': 'application/json',
                },
                body,
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(userLimitsKeys.limits());
            },
        }
    );
};
