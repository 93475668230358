import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import {
    confirmKeycloakPhoneNumberChannel,
    formatNorthAmericanPhoneNumber,
} from '@/feature/account-details/utils/utils';
import { showPhoneNumberEditErrorModal } from '@/feature/account-details/utils/utils';
import { LOG_TAG_ACCOUNT_DETAILS_UPDATE } from '@/feature/verify-phone-number/utils/constants';
import { useActiveProductName } from '@/hooks/use-active-product-name';
import { actions, user } from '@/hooks/use-user';
import { useVerificationCode } from '@/hooks/use-verification-code';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { logger } from '@/utils/logging';

import VerificationScreenTemplate from './VerificationScreenTemplate';

type PhoneNumberEditUpdatedVerificationProps = NativeStackScreenProps<
    RootStackParamList,
    'PhoneNumberEditUpdatedVerification'
>;

export const PhoneNumberEditUpdatedVerification = ({ route }: PhoneNumberEditUpdatedVerificationProps) => {
    const [isResendRequested, setIsResendRequested] = useState(false);
    const navigation = useNavigation();
    const { t } = useTranslation(['account', 'common']);
    const product = useActiveProductName();
    const formattedPhoneNumber = formatNorthAmericanPhoneNumber(route.params?.phoneNumber || '');

    const { verificationCode, isSubmitting, errorMessage, onInputChange, setExternalError, setErrorMessage } =
        useVerificationCode(async () => {
            setErrorMessage('');

            confirmKeycloakPhoneNumberChannel().catch(err => {
                logger.error(LOG_TAG_ACCOUNT_DETAILS_UPDATE, err);
                showPhoneNumberEditErrorModal(navigation, product, t);
                return;
            });

            await actions.refresh(user.session);

            // Update valtio user store with the new phone number to ensure state data is consistent and up to date.
            user.profile.mobile_number = route.params?.phoneNumber || '';

            navigation.navigate('SuccessModal', {
                title: t('edit_phone_number_success_title'),
                subtitle: t('edit_phone_number_success_description', {
                    phoneNumber: formattedPhoneNumber,
                }),
                primaryButton: t('common:go_to_product_lobby', { product }),
                handlePress: () => navigateHome(navigation),
                closeIconMode: 'none',
            });
        });

    return (
        <VerificationScreenTemplate
            title={t('verify_your_new_number')}
            isLoading={isSubmitting}
            errorMessage={errorMessage}
            verificationCode={verificationCode}
            isResendRequested={isResendRequested}
            onResendPress={() => {
                setIsResendRequested(true);
                setErrorMessage('');
            }}
            setExternalError={setExternalError}
            onInputChange={onInputChange}
            phoneNumberToDisplay={formattedPhoneNumber}
        />
    );
};
