import React, { memo, useEffect, useState } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import PlayerImagePlaceholder from '@/assets/images/playerImagePlaceholder';
import { common, designSystem } from '@/styles/styles';
import { League, ProjectionType } from '@/types/api.generated';
import { getColorByProjType } from '@/utils/getProjectionsTypes';
import { isUFC } from '@/utils/league';
import { isPlayerImgPlaceholder } from '@/utils/player';

import { Image } from './lib/components';
import { addResizeParamToURL } from './player-profile/utils';

type Props = {
    url: string;
    backgroundColor?: string;
    imageBackgroundColor?: string;
    style?: ViewStyle;
    playerImageSizes?: { height: number; width: number };
    projectionType?: ProjectionType;
    isVoided?: boolean;
    onLoadImage?: () => void;
    specialIncrease?: boolean;
    league?: League;
};

type ContentProps = Pick<Props, 'url' | 'imageBackgroundColor' | 'playerImageSizes' | 'onLoadImage' | 'league'>;
type PlaceholderProps = Pick<Props, 'imageBackgroundColor' | 'playerImageSizes' | 'onLoadImage'>;

const BADGE_HEIGHT = 52;

const styles = StyleSheet.create({
    badge: {
        borderRadius: BADGE_HEIGHT / 2,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    image: {
        width: 48,
        height: 48,
        borderRadius: 43,
        padding: 2,
        backgroundColor: designSystem.colors.gray6,
        overflow: 'hidden',
    },
    placeholderImage: {
        paddingTop: 3,
    },
    gradient: {
        width: BADGE_HEIGHT,
        height: BADGE_HEIGHT,
        borderRadius: BADGE_HEIGHT,
        padding: 2,
        flex: 1,
    },
    noBorderRadius: {
        borderRadius: 0,
    },
});

const picksTypeStyles = StyleSheet.create({
    BOOSTED: {
        borderColor: designSystem.colors.boosted,
        borderWidth: 2,
    },
    SPECIAL: {
        borderColor: designSystem.colors.lightBlue,
        borderWidth: 2,
    },
    VOIDED: {
        borderColor: designSystem.colors.gray3,
        borderWidth: 2,
    },
    REGULAR: {},
});

const PlayerImagePlaceholderContainer = memo(
    ({ imageBackgroundColor, playerImageSizes, onLoadImage }: PlaceholderProps) => {
        const { height: imageHeight, width: imageWidth } = playerImageSizes ?? {};

        useEffect(() => {
            if (onLoadImage) {
                onLoadImage();
            }
        }, [onLoadImage]);

        return (
            <View
                style={[
                    styles.placeholderImage,
                    playerImageSizes,
                    !!imageBackgroundColor && { backgroundColor: imageBackgroundColor },
                ]}
            >
                <PlayerImagePlaceholder width={imageWidth} height={imageHeight} />
            </View>
        );
    }
);

const PlayerImageContent = ({ url, imageBackgroundColor, playerImageSizes, onLoadImage, league }: ContentProps) => {
    const [source, setSource] = useState<{ uri: string }>();
    const isPlaceholderImage = isPlayerImgPlaceholder(url);
    const isUFCLeague = isUFC(league);

    useEffect(() => {
        setSource({ uri: addResizeParamToURL(url) });
    }, [url]);

    return isPlaceholderImage ? (
        <PlayerImagePlaceholderContainer
            imageBackgroundColor={imageBackgroundColor}
            playerImageSizes={playerImageSizes}
            onLoadImage={onLoadImage}
        />
    ) : (
        <Image
            resizeMode="cover"
            style={[
                styles.image,
                playerImageSizes,
                !!imageBackgroundColor && { backgroundColor: imageBackgroundColor },
                isUFCLeague && styles.noBorderRadius,
            ]}
            source={source}
            onError={() => {
                // if both resized and original images fail to load, we don't want to keep trying to load the image
                // otherwise it will ned up in infinite loop
                if (url !== source?.uri) {
                    setSource({ uri: url });
                }
            }}
            onLoad={onLoadImage}
        />
    );
};

export const PlayerImage = ({
    url,
    backgroundColor,
    imageBackgroundColor,
    playerImageSizes,
    style,
    projectionType = ProjectionType.Regular,
    isVoided,
    onLoadImage,
    specialIncrease,
    league,
}: Props) => {
    const showVoidedColor = isVoided && projectionType !== ProjectionType.Regular;

    let projectionStyle = picksTypeStyles[showVoidedColor ? 'VOIDED' : projectionType];

    if (projectionType === ProjectionType.Special && !showVoidedColor) {
        projectionStyle = {
            ...projectionStyle,
            borderColor: designSystem.colors[getColorByProjType(ProjectionType.Special, specialIncrease)],
        };
    }

    return (
        <View
            style={[
                common.zIndexNegative1,
                styles.badge,
                !!backgroundColor && { backgroundColor },
                projectionStyle,
                style,
            ]}
        >
            <PlayerImageContent
                url={url}
                imageBackgroundColor={imageBackgroundColor}
                playerImageSizes={playerImageSizes}
                onLoadImage={onLoadImage}
                league={league}
            />
        </View>
    );
};
