import { create } from 'zustand';

export type GooglePlaceSessionTokenStore = {
    sessionToken: string;
    renewSessionToken: () => void;
};

/* Generate Random Session Token */
const generateToken = () => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // Characters to choose from
    const random = Array.from({ length: 32 }, () => charset[Math.floor(Math.random() * charset.length)]);
    return random.join('');
};

/**
 * Store that hold google place session token, which is a random string that identifies an autocomplete session for billing purposes.
 */
export const useGooglePlaceSessionTokenStore = create<GooglePlaceSessionTokenStore>()(set => {
    return {
        sessionToken: generateToken(),
        renewSessionToken: () => {
            set({ sessionToken: generateToken() });
        },
    };
});
