import { useMemo } from 'react';

import { Event, Market, MarketCategory, MarketType } from '@/feature/event-details-sbk/types';
import { MicroMarketChipItem } from '@/feature/league-hub-sbk/components/MicroMarketChipBar';
import { useEventsGroupedById } from '@/feature/league-hub-sbk/hooks/use-events';

type Side = 'Home' | 'Away';

export type MicroMarketListItem = {
    event: Event;
    market: Market;
};

export type MicroMarketData = Record<
    string, // chip id
    Array<MicroMarketListItem>
>;

// Generate unique id for each micro market
const generateMicroMarketId = (marketType: MarketType, event: Event, side?: Side) =>
    [event.id, marketType.code, side].filter(Boolean).join('-');

// Generates a unique id for each chip bar item
const generateChipItemId = (marketType: MarketType, side?: Side) => [marketType.code, side].filter(Boolean).join('-');

// Get the side ('Home' or 'Away') of the market based on the market type and event
const getSide = (marketType: MarketType, event: Event): Side | undefined => {
    if (marketType.params.team_id) {
        return marketType.params.team_id === event.home_team.id ? 'Home' : 'Away';
    }
};

// Returns a list of unique market types
const getUniqueMarketTypes = (marketCategories: MarketCategory[]) => {
    return marketCategories
        .map(({ markets }) => markets.map(({ market_type }) => market_type))
        .flat()
        .filter((marketType, index, self) => self.findIndex(m => m.code === marketType.code) === index);
};

/***
 *  Returns a list of chip items to populate the MicroMarketChipBar
 *  [
 *    { marketType: MarketType, side: Side, id: string },
 *    ...
 *  ]
 *  If the market type contains params.team_id, we create two chip items for each side (Home and Away)
 *  ie "Home outcome of next possession", "Away outcome of next possession"
 **/
const createChipItems = (marketTypes: MarketType[]): MicroMarketChipItem[] => {
    return marketTypes.flatMap(marketType =>
        marketType.params.team_id
            ? [
                  { marketType, side: 'Home', id: generateChipItemId(marketType, 'Home') },
                  { marketType, side: 'Away', id: generateChipItemId(marketType, 'Away') },
              ]
            : { marketType, id: generateChipItemId(marketType) }
    );
};

/**
 * Creates a map of the micro markets
 * {
 *   [{eventId}-{marketTypeCode}-{side}]: Market
 *   ...
 * }
 * for each event and market type, there can only be one active micro market and should be the `published` one if it exists
 */
const createMarketMap = (marketCategories: MarketCategory[], eventGroupedById?: { [p: string]: Event } | undefined) => {
    return marketCategories.reduce<Record<string, Market>>((acc, { markets }) => {
        markets.forEach(market => {
            const event = eventGroupedById?.[market.event_id];
            if (event) {
                const side = getSide(market.market_type, event);
                const microMarketId = generateMicroMarketId(market.market_type, event, side);
                if (!acc[microMarketId]) {
                    acc[microMarketId] = market;
                } else {
                    // if existing market is not published, but new market is published, replace the existing market
                    if (!acc[microMarketId].published && market.published) {
                        acc[microMarketId] = market;
                    }
                }
            }
        });
        return acc;
    }, {});
};

/**
 * Creates the data for the MicroMarketList, grouped by chip item
 * {
 *  [{marketTypeCode}-{side}]: [{ event: Event, market: Market }]
 *  ...
 * }
 */
const createMicroMarketData = (
    chipItems: MicroMarketChipItem[],
    events: Event[],
    marketMap: Record<string, Market>
) => {
    return chipItems.reduce<MicroMarketData>((acc, chipItem) => {
        const chipId = generateChipItemId(chipItem.marketType, chipItem.side);
        const marketData: MicroMarketListItem[] = [];
        events.forEach(event => {
            const marketId = generateMicroMarketId(chipItem.marketType, event, chipItem.side);
            const market = marketMap[marketId];
            if (market) {
                marketData.push({ event, market: market });
            }
        });
        acc[chipId] = marketData;
        return acc;
    }, {});
};

/**
 * Hook to generate the data for the MicroMarketChipBar and MicroMarketList
 */
export const useMicroMarketData = (leagueId: string, events: Event[], marketCategories: MarketCategory[]) => {
    const { data: eventGroupedById } = useEventsGroupedById(leagueId);

    return useMemo(() => {
        const marketTypes = getUniqueMarketTypes(marketCategories);
        const chipItems = createChipItems(marketTypes);
        const marketMap = createMarketMap(marketCategories, eventGroupedById);
        const data = createMicroMarketData(chipItems, events, marketMap);
        return { chipItems, data };
    }, [eventGroupedById, events, marketCategories]);
};
