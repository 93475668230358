import React from 'react';
import { StyleSheet } from 'react-native';

import { Button } from '@/components/ButtonComponent';

type QuickDepositButtonProps = {
    label: string;
    onPress: () => void;
};

const styles = StyleSheet.create({
    button: {
        flex: 1,
    },
});

export const QuickDepositButton = ({ label, onPress }: QuickDepositButtonProps) => {
    return (
        <Button
            size="small"
            type="tertiary"
            variant="rounded"
            label={label ?? ''}
            style={[styles.button]}
            onPress={onPress}
        />
    );
};
