import { Currency } from '@/types/api.generated';

import { EntryItemType } from './components/EntryItem';

export const getEntryCurrencyTitle = (currency?: Currency) => {
    return currency === Currency.Fre ? 'Betr Bucks' : 'Entry';
};

export const sortOnTimestamp = (a: EntryItemType, b: EntryItemType) => {
    if (a.settlementDate && b.settlementDate) {
        return Date.parse(b.settlementDate) - Date.parse(a.settlementDate);
    }
    return Date.parse(b.createdDate) - Date.parse(a.createdDate);
};
