import { PlayerInfoWithProjectionsFragment, TeamInfoWithPlayersFragment } from '@/api/events/query.generated';
import { EventInfo, isIndividualEvent, isTeamEvent } from '@/api/events/types/types';

import { PlayerProjection } from '../types';

export const eventUtils = {
    getAllPlayers: (event?: EventInfo): PlayerInfoWithProjectionsFragment[] => {
        let players: PlayerInfoWithProjectionsFragment[] = [];
        if (isTeamEvent(event)) {
            players = event.teams.reduce((acc: PlayerInfoWithProjectionsFragment[], team) => {
                return [...acc, ...(team.players ?? [])];
            }, []);
        } else if (isIndividualEvent(event)) {
            players = event.players ?? [];
        }
        return players ?? [];
    },

    getAllPlayersAsMap: (events?: EventInfo[]): Record<string, PlayerProjection[]> => {
        const allPlayers = events?.map(it => eventUtils.getAllPlayers(it)).flat() ?? [];
        const allPlayersMarkets: Record<string, PlayerProjection[]> = allPlayers.reduce((record, player) => {
            record[player.id] = player.projections;
            return record;
        }, {} as Record<string, PlayerProjection[]>);
        return allPlayersMarkets;
    },

    getTeams: (
        event?: EventInfo
    ): [TeamInfoWithPlayersFragment, TeamInfoWithPlayersFragment] | [undefined, undefined] => {
        if (isTeamEvent(event)) {
            return [event.teams[0], event.teams[1]];
        } else {
            return [undefined, undefined];
        }
    },

    getTeamById: (event?: EventInfo, teamId?: string): TeamInfoWithPlayersFragment | undefined => {
        if (isTeamEvent(event)) {
            return event.teams.find(team => team.id === teamId);
        }
        return undefined;
    },
};
