import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

import { Text } from '@/components/TextComponent';
import { Box, Image } from '@/components/lib/components';
import { PlayerImagePlaceholderContainer } from '@/components/player-profile/PlayerProfileImage';
import { addResizeParamToURL } from '@/components/player-profile/utils';
import { designSystem } from '@/styles/styles.js';
import { isPlayerImgPlaceholder } from '@/utils/player';

type Props = {
    playerImageUrl?: string;
    onLoadImage: () => void;
    playerNumber?: number | null;
    isLoading?: boolean;
};

export const ShareEntryPlayerProfileImage = ({ playerImageUrl, onLoadImage, playerNumber, isLoading }: Props) => {
    const [source, setSource] = useState<{ uri: string }>();
    const isPlaceholderImage = isPlayerImgPlaceholder(playerImageUrl);
    const hasPlayerNumber = typeof playerNumber === 'number';
    useEffect(() => {
        playerImageUrl && setSource({ uri: addResizeParamToURL(playerImageUrl) });
        hasPlayerNumber && onLoadImage();
    }, [playerImageUrl, hasPlayerNumber, onLoadImage]);

    const onLoadPlaceholderImage = useCallback(() => {
        !isLoading && onLoadImage();
    }, [isLoading, onLoadImage]);

    return (
        <Box
            style={styles.container}
            overflow={'hidden'}
            borderRadius={'r8'}
            justifyContent={hasPlayerNumber ? 'center' : 'flex-end'}
        >
            {!isPlaceholderImage && playerImageUrl ? (
                <Image
                    source={source}
                    style={styles.imageStyle}
                    onError={() => {
                        setSource({ uri: playerImageUrl });
                    }}
                    onLoad={onLoadImage}
                />
            ) : hasPlayerNumber ? (
                <Text variant={'headlineLarge'} style={styles.playerNumber}>
                    {playerNumber}
                </Text>
            ) : (
                <PlayerImagePlaceholderContainer
                    placeholderSize={{ width: 54, height: 48 }}
                    onLoad={onLoadPlaceholderImage}
                />
            )}
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: designSystem.colors.gray7,
        width: 40,
        height: 52,
        borderCurve: 'continuous',
        alignItems: 'center',
    },
    imageStyle: {
        width: 52,
        height: 52,
        alignSelf: 'center',
    },
    playerNumber: {
        fontFamily: 'Oswald-SemiBold',
    },
});
