import { useEffect, useRef } from 'react';

import { EventInfo } from '@/api/events/types/types';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { eventUtils } from '@/feature/betslip-pickem/utils/event-utils';

import { usePickSelection } from './use-pick-selection';

export const useOpenDeeplinkPlayer = (
    openPlayerWithId: string | undefined,
    sortedPlayers: PlayerWithTeam[],
    sortedEventsDetails: EventInfo[]
) => {
    const pickOpened = useRef(false);
    const { showToast } = useAlerts();
    const { openPlayerPickModal } = usePickSelection();
    useEffect(() => {
        // if we have pickToOpen it means the user got here via deep link
        // find the pick and then open the modal
        if (sortedPlayers.length > 0 && !!openPlayerWithId && !pickOpened.current) {
            const playerToOpen = sortedPlayers.find(p => p.id === openPlayerWithId);
            if (playerToOpen) {
                const event = sortedEventsDetails.find(ev => {
                    const eventPlayers = eventUtils.getAllPlayers(ev);
                    return eventPlayers.some(p => p.id === openPlayerWithId);
                });
                if (event) {
                    openPlayerPickModal({
                        player: {
                            ...playerToOpen,
                            league: event.league,
                            sport: event.sport,
                            projections: playerToOpen.projections,
                        },
                        event,
                        analyticsTag: 'deep_link_to_pick',
                    });
                }
            } else {
                showToast({ message: 'Player unavailable', toastType: 'warning' });
            }
            pickOpened.current = true;
        }
    }, [openPlayerPickModal, openPlayerWithId, showToast, sortedEventsDetails, sortedPlayers]);
};
