import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, useWindowDimensions } from 'react-native';
import { openSettings } from 'react-native-permissions';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { CreateEntryAccuracyError } from '@/hooks/use-entries';
import { showScrollIndicator } from '@/utils/constants-platform-specific';
import { getGeoComplyErrorMessage } from '@/utils/get-geocomply-error-message';

import { AlertIcon } from '../components/AlertIcon';
import { GeoComplyError, SubmitBetError } from '../types';
import { useSbkBetSlipStore } from './use-sbk-betslip-store';

const HEADER_FOOTER_HEIGHT = 275;

const BET_PLACEMENT_ERROR_FALLBACK_KEY = 'error:betslip_sbk.generic_fallback';

export const useHandleBetSubmissionErrors = () => {
    const { t } = useTranslation(['betslip_sbk', 'common', 'error']);
    const { showInfoSheet } = useAlerts();
    const { height } = useWindowDimensions();
    const safeInsets = useSafeAreaInsets();
    const updateSgpOddsForAllEvents = useSbkBetSlipStore(state => state.actions.updateSgpOddsForAllEvents);
    const maxContainerHeight = height - safeInsets.top - HEADER_FOOTER_HEIGHT;

    return useCallback(
        (error: unknown) => {
            let title = t('betslip_sbk:bet_rejected');
            let description: string | React.ReactNode = t(BET_PLACEMENT_ERROR_FALLBACK_KEY);
            let handleSecondaryPress;
            let secondaryLabel;

            if (error instanceof GeoComplyError) {
                // keeping this as constant for now to avoid duplication and confusion within Phrase
                title = 'Location failure';
                const errorMessage = getGeoComplyErrorMessage(error.payload) || description;
                // geo comply error varies in length, so we need to wrap it in a ScrollView to ensure it doesn't overflow
                // for specific screen sizes
                description = (
                    <Box maxHeight={maxContainerHeight}>
                        <ScrollView showsVerticalScrollIndicator={showScrollIndicator}>
                            <Text color="gray2" textAlign="center" variant="bodyMedium">
                                {errorMessage}
                            </Text>
                        </ScrollView>
                    </Box>
                );
            } else if (error instanceof CreateEntryAccuracyError) {
                title = t('common:precise_location_failure');
                // keeping this as constant for now to avoid duplication and confusion within Phrase
                description =
                    'Precise location must be turned on before you can place an entry.\n Go to app Settings → Permissions → Location and allow access and precise location.';
                secondaryLabel = t('common:go_to_settings');
                handleSecondaryPress = openSettings;
            } else if (error instanceof SubmitBetError) {
                description = t([`error:betslip_sbk.${error.code}`, BET_PLACEMENT_ERROR_FALLBACK_KEY], error.params);
                if (error.code === 'huddle_service_odds_are_different_than_requested') {
                    updateSgpOddsForAllEvents();
                }
            }

            showInfoSheet({
                title,
                description,
                buttonLabel: t('common:close'),
                secondaryLabel,
                handleSecondaryPress,
                icon: <AlertIcon />,
            });
        },
        [t, showInfoSheet, maxContainerHeight, updateSgpOddsForAllEvents]
    );
};
