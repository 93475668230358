import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { PickInfoFragment } from '@/api/entries/query.generated';
import { EntryDetails } from '@/api/entries/types/types';
import DynamicPlaySmallIcon from '@/assets/icons/dynamic-play-small-icon';
import PerfectPlaySmallIcon from '@/assets/icons/perfect-play-small-icon';
import { PickProgressDots } from '@/components/PickProgressDots';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { VoidedEntryWarning } from '@/components/VoidedEntryWarning';
import { Box, Row } from '@/components/lib/components';
import { SingleProjectionIcon } from '@/feature/lobby/components/PlayerDetails';
import { defaultNegativeToZero, getEntryDetails, getMultiplier, getToWin } from '@/hooks/use-entries-utils';
import { common, designSystem } from '@/styles/styles';
import { GameMode, ProjectionType, Result } from '@/types/api.generated';
import { isWeb } from '@/utils/constants-platform-specific';
import { getEntryPayout } from '@/utils/getEntryPayout';
import { getProjectionsTypes } from '@/utils/getProjectionsTypes';

import { getEntryCurrencyTitle } from '../entries-utils';
import { Separator } from './Separator';

type EntryHeaderProps = {
    entry?: EntryDetails;
    isVoided: boolean;
    isLoading: boolean;
    entryPayout: ReturnType<typeof getEntryPayout>;
    picks?: PickInfoFragment[];
    entryStatus: string;
    shareable?: boolean;
};

export const EntryHeader = ({
    entry,
    isLoading,
    isVoided,
    entryPayout,
    picks,
    entryStatus,
    shareable = false,
}: EntryHeaderProps) => {
    const { hasBoostedPicks } = getProjectionsTypes(picks?.map(it => it.projection));
    const displayGreenText = shareable && entryStatus === 'Won' && entry?.result !== Result.Loss;
    const currencyTitle = getEntryCurrencyTitle(entry?.currency);
    const { t } = useTranslation(['common', 'bets']);
    const { p2pGameType, voidedCount, multiplierType, initialMultiplierEqualsMultiplierWithDecimals } = getEntryDetails(
        entry,
        entryPayout
    );

    const textVariant = voidedCount && !initialMultiplierEqualsMultiplierWithDecimals ? 'bodyMedium' : 'titleMedium';
    const textColor =
        (isVoided || voidedCount) && !initialMultiplierEqualsMultiplierWithDecimals
            ? 'gray4'
            : hasBoostedPicks
            ? 'boosted'
            : 'gray1';

    const getMultiplierTitle = () => {
        if (hasBoostedPicks) {
            return t('common:boosted');
        }
        return t('bets:multiplier');
    };

    const getMultiplierTextColor = () => {
        if (isVoided) {
            return 'gray4';
        }
        if (hasBoostedPicks) {
            return 'boosted';
        }
        return 'gray2';
    };

    return (
        <>
            <View style={[common.row, common.alignCenter]}>
                {hasBoostedPicks ? (
                    <Row flex={1} alignItems={'center'} mb={'s6'}>
                        <Box flex={1}>
                            <Separator light={shareable} style={styles.separator} />
                        </Box>
                        <Box ml={'s6'}>
                            <SingleProjectionIcon
                                overrideColor={isVoided ? 'gray4' : undefined}
                                selectedProjection={{ type: ProjectionType.Boosted }}
                            />
                        </Box>
                        <Box flex={1}>
                            <Separator light={shareable} style={styles.separator} />
                        </Box>
                    </Row>
                ) : (
                    <Box mb={'s12'} flex={1}>
                        <Separator light={shareable} style={styles.separator} />
                    </Box>
                )}
            </View>
            <Row>
                <Box justifyContent={'center'} alignItems={'center'} width={'33%'}>
                    <Text variant="bodySmall" color={isVoided ? 'gray4' : 'gray2'} textAlign={'center'}>
                        {currencyTitle}
                    </Text>
                    <Text variant={'titleMedium'} textAlign={'center'} color={isVoided ? 'gray4' : 'gray1'}>
                        {isLoading ? <ActivityIndicator /> : <>${entry?.amount ?? ''}</>}
                    </Text>
                </Box>
                <Separator flex={0} vertical={true} light={shareable} style={styles.separator} />
                <Box alignItems={'center'} justifyContent={'center'} width={'33%'}>
                    <Text variant={hasBoostedPicks ? 'labelLarge' : 'bodySmall'} color={getMultiplierTextColor()}>
                        {getMultiplierTitle()}
                    </Text>
                    {isLoading ? (
                        <ActivityIndicator />
                    ) : (
                        <Row justifyContent={'center'} flexWrap={'wrap'} px={'s4'}>
                            <Text variant={textVariant} textAlign={'center'} color={textColor}>
                                {getMultiplier(entry, multiplierType) ?? ''}
                            </Text>
                            {!!voidedCount &&
                            entry?.result !== Result.Void &&
                            !initialMultiplierEqualsMultiplierWithDecimals ? (
                                <>
                                    <SizedBox value={4} />
                                    <Text
                                        variant="titleMedium"
                                        textAlign={'center'}
                                        color={hasBoostedPicks ? 'boosted' : 'white'}
                                    >
                                        {p2pGameType
                                            ? getMultiplier(entry, 'decimals')
                                            : getMultiplier(entry, 'adjusted')}
                                    </Text>
                                </>
                            ) : null}
                        </Row>
                    )}
                </Box>
                <Separator flex={0} light={shareable} vertical={true} style={styles.separator} />
                <Box justifyContent={'center'} alignItems={'center'} width={'33%'}>
                    {isVoided && shareable ? (
                        <Text variant="titleMedium" color={'gray1'}>
                            {t('common:voided')}
                        </Text>
                    ) : (
                        <Box alignItems={'center'}>
                            <Text
                                variant="bodySmall"
                                color={isVoided ? 'gray4' : displayGreenText ? 'green' : 'gray2'}
                                style={{ ...(displayGreenText ? styles.displayGreenText : {}) }}
                            >
                                {entryStatus}
                            </Text>
                            {isLoading ? (
                                <ActivityIndicator />
                            ) : (
                                <Text
                                    variant={'titleMedium'}
                                    color={isVoided ? 'gray4' : displayGreenText ? 'green' : 'gray1'}
                                    style={{ ...(displayGreenText ? styles.displayGreenText : {}) }}
                                >
                                    $
                                    {isVoided
                                        ? defaultNegativeToZero(entry?.initialToWin)
                                        : defaultNegativeToZero(getToWin(entry))}
                                </Text>
                            )}
                        </Box>
                    )}
                </Box>
            </Row>
            <SizedBox value={shareable ? 9 : 12} />
            <View style={[common.row, common.alignCenter, common.justifyCenter]}>
                <Box flex={1}>
                    <Separator light={shareable} style={styles.separator} />
                </Box>
                <SizedBox value={12} />
                {entry?.gameMode === GameMode.Perfect ? <PerfectPlaySmallIcon /> : <DynamicPlaySmallIcon />}
                <SizedBox value={8} />
                <Separator light={shareable} vertical={true} style={styles.separator} height={isWeb ? 16 : '100%'} />
                <SizedBox value={8} />
                <PickProgressDots picks={picks!} light={shareable} />
                <SizedBox value={12} />
                <Box flex={1}>
                    <Separator light={shareable} style={styles.separator} />
                </Box>
            </View>
            <SizedBox value={27} />
            {isVoided && !shareable ? (
                <>
                    <VoidedEntryWarning />
                    <SizedBox value={24} />
                </>
            ) : null}
        </>
    );
};

const styles = StyleSheet.create({
    displayGreenText: {
        fontWeight: '500',
        textTransform: 'uppercase',
    },
    multiplierContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    growShrink: {
        flexGrow: 1,
        flexShrink: 1,
    },
    separator: {
        backgroundColor: designSystem.colors.gray6,
    },
});
