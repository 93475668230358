import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = ({ fillColor = '#41494E', ...props }) => (
    <Svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 2a7.975 7.975 0 0 0-3.122.632L6.377 1.131A9.957 9.957 0 0 1 11 0c5.523 0 10 4.477 10 10 0 1.668-.408 3.24-1.13 4.623l-1.502-1.501A8 8 0 0 0 11 2Zm-6.327.255L6.096 3.68l-.004.003 11.226 11.226.003-.004 1.423 1.423-.003.004 2.109 2.108a.5.5 0 0 1 0 .707l-.708.708a.5.5 0 0 1-.707 0l-2.108-2.11A9.959 9.959 0 0 1 11 20C5.477 20 1 15.523 1 10c0-2.4.846-4.603 2.255-6.327L1.143 1.561a.5.5 0 0 1 0-.707L1.85.147a.5.5 0 0 1 .707 0l2.112 2.112a.675.675 0 0 0 .004-.004Zm.005 2.842a8 8 0 0 0 11.225 11.225L4.678 5.097Z"
            fill={fillColor}
        />
    </Svg>
);

export default SvgComponent;
