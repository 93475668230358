import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path d="M7.125 8.875V14H8.875V8.875H14V7.125H8.875V2H7.125V7.125H2V8.875H7.125Z" fill="#EDEFF3" />
    </Svg>
);

export default SvgComponent;
