import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { designSystem } from '@/styles/styles';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { getActiveDateText } from '../utils/utils';

const styles = StyleSheet.create({
    row: {
        paddingVertical: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    separator: {
        backgroundColor: designSystem.colors.gray5,
    },
});

type LimitEditConfirmationModalProps = {
    oldAmount: number;
    newAmount: number;
    durationTextLower: string;
    isDecrease: boolean;
};

export const LimitEditConfirmationInfo = ({
    oldAmount,
    newAmount,
    durationTextLower,
    isDecrease,
}: LimitEditConfirmationModalProps) => {
    const { t } = useTranslation('rg');
    const subText = isDecrease ? t('limit_immediate_activation_info') : t('limit_cooldown_info');

    const activeDateText = getActiveDateText(isDecrease);
    return (
        <>
            <Text textAlign={'center'} fontSize={15} lineHeight={24} color={'gray3'} testID="limitConfirModalMsg">
                {subText}
            </Text>
            <SizedBox value={24} />
            <View style={styles.row}>
                <Text fontSize={15} lineHeight={24}>
                    {t('old_duration_limit', { duration: durationTextLower })}
                </Text>
                <Text fontSize={15} lineHeight={24} fontWeight="600" testID="oldLimitAmount">
                    {toLocaleCurrency(oldAmount)}
                </Text>
            </View>
            <LineSeparator style={styles.separator} />
            <View style={styles.row}>
                <Text fontSize={15} lineHeight={24}>
                    {t('new_duration_limit', { duration: durationTextLower })}
                </Text>
                <Text fontSize={15} lineHeight={24} fontWeight="600" textAlign={'right'} testID="newLimitAmount">
                    {!newAmount
                        ? t('no_duration_limit', { duration: durationTextLower })
                        : `${toLocaleCurrency(newAmount)}`}
                </Text>
            </View>
            <LineSeparator style={styles.separator} />
            <View style={styles.row}>
                <Text fontSize={15} lineHeight={24}>
                    {t('active_date')}
                </Text>
                <Text fontSize={15} lineHeight={24} fontWeight="600">
                    {activeDateText}
                </Text>
            </View>
        </>
    );
};
