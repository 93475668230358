import { PermissionStatus, RESULTS } from 'react-native-permissions';

/**
 * Since location access is always GRANTED on iOS simulator, this can become helpful when testing on iOS simulators.
 *
 * Just call {@linkcode locationSimulator.simulateNoLocationAccess()} at the root to simulate no location access.
 * You can also call {@linkcode locationSimulator.simulateLocationInState('FL')} to simulate being located in a specific state.
 */
export const locationSimulator = {
    enabled: false,
    state: '',
    permissionStatus: undefined as PermissionStatus | undefined,

    isEnabled() {
        return this.enabled && __DEV__;
    },

    simulateLocationInState(state: string) {
        this.enabled = true;
        this.state = state;
        locationSimulator.permissionStatus = RESULTS.GRANTED;
    },

    simulateNoLocationAccess() {
        this.enabled = true;
        this.permissionStatus = RESULTS.BLOCKED;
    },

    simulateLocationDisabled() {
        this.enabled = true;
        this.permissionStatus = RESULTS.UNAVAILABLE;
    },
};
