import React from 'react';

import { Event, Market, MarketLayout } from '../../types';
import { MultiSplitStandardMarket } from './MultiSplitStandardMarket';
import { SplitStandardMarket } from './SplitStandardMarket';
import { StackedStandardMarket } from './StackedStandardMarket';

type MarketLayoutsProps = {
    event: Event;
    markets: Market[];
    layout: MarketLayout | null;
    testID?: string;
};

export const MarketLayouts = ({ event, markets, layout, testID }: MarketLayoutsProps) => {
    switch (layout) {
        case 'stacked_standard':
            return <StackedStandardMarket {...{ event, markets }} testID={`${testID}-stackedStandard`} />;
        case 'multi_split_standard':
            return <MultiSplitStandardMarket {...{ event, markets }} testID={`${testID}-multiStackedStandard`} />;
        case 'three_split_standard':
            return <SplitStandardMarket {...{ event, markets }} columns={3} testID={`${testID}-threeSplit`} />;
        case 'split_standard':
        default:
            return <SplitStandardMarket {...{ event, markets }} testID={testID} />;
    }
};
