import React, { useState } from 'react';

import { useNavigation } from '@react-navigation/native';

import { SendByMethod } from '@/hooks/use-request-verification-code';
import { useVerificationCode } from '@/hooks/use-verification-code';

import VerificationScreenTemplate from './VerificationScreenTemplate';

export const PhoneNumberEditInitialVerification = () => {
    const [isResendRequested, setIsResendRequested] = useState(false);
    const navigation = useNavigation();

    const { verificationCode, isSubmitting, errorMessage, onInputChange, setErrorMessage, setExternalError } =
        useVerificationCode(() => {
            setErrorMessage('');
            navigation.navigate('PhoneNumberEdit');
        });

    return (
        <VerificationScreenTemplate
            isLoading={isSubmitting}
            errorMessage={errorMessage}
            verificationCode={verificationCode}
            isResendRequested={isResendRequested}
            onResendPress={() => {
                setIsResendRequested(true);
                setErrorMessage('');
            }}
            setExternalError={setExternalError}
            onInputChange={onInputChange}
            sendByMethod={SendByMethod.EMAIL}
        />
    );
};
