import React from 'react';
import { StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { useNavigation } from '@react-navigation/native';

import DownIcon from '@/assets/icons/down';
import SubtractIcon from '@/assets/icons/subtract';
import RichText, { ParsedTypes } from '@/components/RichText';
import { SizedBox } from '@/components/SizedBox';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { common, designSystem } from '@/styles/styles';
import { RichTextField } from '@prismicio/client';

const styles = StyleSheet.create({
    infoBox: {
        padding: 16,
        borderWidth: 1,
        borderRadius: 10,
        backgroundColor: designSystem.colors.gray5,
        borderColor: designSystem.colors.gray5,
    },
    rightArrow: { marginRight: -5, transform: [{ rotate: '-90deg' }] },
});

type SelfExclusionPromptProps = {
    shouldRedirect: boolean;
};

type PromptContentProps = SelfExclusionPromptProps & {
    contentSlice: RichTextField;
};

const PromptContent = ({ shouldRedirect, contentSlice }: PromptContentProps) => {
    return (
        <View style={[common.row, common.alignCenter]}>
            <View style={[common.row, common.alignCenter]}>
                <SubtractIcon />
                <SizedBox value={20} />
            </View>
            <View style={common.flex}>
                <SizedBox value={2} />
                <View style={[common.row, common.alignCenter]}>
                    <RichText
                        data={contentSlice}
                        typeProps={{
                            [ParsedTypes.P]: {
                                color: 'white',
                                textAlign: 'left',
                            },
                        }}
                    />
                </View>
            </View>
            {shouldRedirect && (
                <>
                    <SizedBox value={2} />
                    <View style={[common.row, common.alignCenter]}>
                        <SizedBox value={20} />
                        <DownIcon style={styles.rightArrow} />
                    </View>
                </>
            )}
        </View>
    );
};

export const SelfExclusionPrompt = ({ shouldRedirect }: SelfExclusionPromptProps) => {
    const { navigate } = useNavigation();
    const { richTextsSlices } = useJurisdictionStore(state => ({
        richTextsSlices: state.jurisdictionSettings?.globalSettings?.richTextsSlices,
    }));

    const selfExclusionInfoBoxSlice = richTextsSlices?.SelfExclusionAddInfoBox || [];

    return shouldRedirect ? (
        <TouchableOpacity style={styles.infoBox} onPress={() => navigate('Timeout')}>
            <PromptContent shouldRedirect={shouldRedirect} contentSlice={selfExclusionInfoBoxSlice} />
        </TouchableOpacity>
    ) : (
        <View style={styles.infoBox}>
            <PromptContent shouldRedirect={shouldRedirect} contentSlice={selfExclusionInfoBoxSlice} />
        </View>
    );
};
