import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useRoute } from '@react-navigation/native';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useIsBetSlipSubmitting } from '@/feature/betslip-sbk/hooks/betslip-hooks';

import { ClosedSelectionsInfoSheet } from '../components/ClosedSelectionsInfoSheet';
import { useSbkBetSlipStore } from '../hooks/use-sbk-betslip-store';

const displayRoutes = ['BettingLobbyScreen', 'BetSlipModal', 'SBKHome'];

const TOAST_DURATION = 5000;

/**
 * Hook to show a toast when a selection is closed via websocket for the Sbk Betslip
 * Only shows the toast when the user is on the Sbk lobby or betslip
 * If a closed selection ws event is received will the toast is shown, it will be replaced with a new toast
 */
export const useClosedSelectionsToast = () => {
    const route = useRoute();
    const { showToast, clearAllToasts, showInfoSheet } = useAlerts();
    const closedSelectionsCount = useSbkBetSlipStore(state => state.closedSelections.length);
    const clearClosedSelections = useSbkBetSlipStore(state => state.actions.clearClosedSelections);
    const removeClosedSelections = useSbkBetSlipStore(state => state.actions.removeClosedSelections);
    const isBetSlipSubmitting = useIsBetSlipSubmitting();
    const customToastClicked = useRef(false);
    const isInfoSheetVisible = useRef(false);
    const toastTimeout = useRef<NodeJS.Timeout | null>(null);
    const { t } = useTranslation(['betslip_sbk', 'bets']);

    useEffect(() => {
        if (closedSelectionsCount > 0 && displayRoutes.includes(route.name) && !isBetSlipSubmitting) {
            removeClosedSelections();
            if (!isInfoSheetVisible.current) {
                showToast(
                    {
                        message: t('closed_bets_toast_message', {
                            count: closedSelectionsCount,
                        }),
                        toastType: 'warning',
                        positionY: 168,
                        onPress: () => {
                            customToastClicked.current = true;
                            isInfoSheetVisible.current = true;
                            clearAllToasts();
                            showInfoSheet({
                                title: t('closed_bets_info_sheet_title'),
                                description: <ClosedSelectionsInfoSheet />,
                                buttonLabel: t('bets:got_it'),
                                onDismiss: () => {
                                    isInfoSheetVisible.current = false;
                                    customToastClicked.current = false;
                                    clearClosedSelections();
                                },
                            });
                        },
                    },
                    TOAST_DURATION
                );
            }
            if (toastTimeout.current) {
                clearTimeout(toastTimeout.current);
            }
            toastTimeout.current = setTimeout(() => {
                if (!customToastClicked.current) {
                    clearClosedSelections();
                }
            }, TOAST_DURATION);
        }
    }, [
        t,
        clearClosedSelections,
        closedSelectionsCount,
        route.name,
        showInfoSheet,
        showToast,
        clearAllToasts,
        isBetSlipSubmitting,
        removeClosedSelections,
    ]);
};
