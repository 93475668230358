import { Platform } from 'react-native';

/**
 * Validate keyboard input. Only allow alphanumeric characters, spaces, periods and commas
 * @param input - input string to validate
 */
export const validateKeyboardInput = (input: string) => {
    const regex = /^[a-zA-Z0-9\s.,]*$/; //  alphanumeric characters, spaces, periods and commas regex
    return regex.test(input);
};

// ascii-capable and visible-password both hide the emoji option on the keyboard
export const textInputKeyboardType = Platform.OS === 'ios' ? 'ascii-capable' : 'visible-password';
