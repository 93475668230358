import React, { useEffect } from 'react';
import { FlatList, ScrollView, StyleSheet } from 'react-native';

import { ChipButton } from '@/components/ChipButton';
import { SizedBox as SizeBoxComponent } from '@/components/SizedBox';
import { usePlayerFiltersStore } from '@/feature/lobby/hooks/use-player-filters';
import { getPositionFilters } from '@/feature/lobby/utils/filters';
import { useGetOrderedLeagues } from '@/hooks/use-fantasy-league-configs';
import { common } from '@/styles/styles';
import { League } from '@/types/api.generated';

const SizedBox = () => <SizeBoxComponent value={8} />;

export const PlayerPositionsFilters = ({ league }: { league: League }) => {
    const { leagues } = useGetOrderedLeagues();

    const data = getPositionFilters(league, leagues);

    const playerPositionFilter = usePlayerFiltersStore(state => state.playerPositionFilter);
    const setPlayerPositionFilter = usePlayerFiltersStore(state => state.actions.setPlayerPositionFilter);

    const firstPositionFilter = data?.[0];

    useEffect(() => {
        // Set the filter on the first item if it exists and has not been set initially
        if (playerPositionFilter === undefined && firstPositionFilter) {
            setPlayerPositionFilter(firstPositionFilter.position ?? '', firstPositionFilter.positionDescription ?? '');
        }
    }, [firstPositionFilter, playerPositionFilter, setPlayerPositionFilter]);

    /*
    Nesting the FlatList inside a ScrollView to allow horizontal scrolling - the "spring" effect, even when the list of item is short and doesn't require scrolling
    If they have different scroll directions, there will be no warning.
    Also it fixed the swipe gesture in the Chip bar that allows the user to navigate between tabs
    */
    return (
        <ScrollView style={common.flex} scrollEnabled={false}>
            <FlatList
                horizontal
                data={data}
                ListFooterComponent={<SizeBoxComponent value={16} />}
                ListHeaderComponent={<SizeBoxComponent value={16} />}
                ItemSeparatorComponent={SizedBox}
                contentContainerStyle={styles.flatListStyle}
                showsHorizontalScrollIndicator={false}
                renderItem={({ item }) => {
                    return (
                        <ChipButton
                            key={item.label}
                            label={item.label ?? ''}
                            active={playerPositionFilter === item.position}
                            onPress={() => {
                                setPlayerPositionFilter(item.position ?? '', item.positionDescription ?? '');
                            }}
                            testID="chipButton"
                        />
                    );
                }}
            />
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    flatListStyle: {
        minWidth: '100%',
    },
});
