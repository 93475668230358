import React from 'react';
import { Image, StyleSheet } from 'react-native';

import Shield from '@/assets/icons/shield';
import { Column } from '@/components/lib/components';

type LogoBoxProps = {
    primaryColor: string;
    secondaryColor: string;
    shouldShowFallbackLogo: boolean;
    logoUrl?: string;
} & React.ComponentProps<typeof Column>;

const LOGO_SIZE = 24;

export const LogoBox = ({
    primaryColor,
    secondaryColor,
    shouldShowFallbackLogo,
    logoUrl,
    ...columnProps
}: LogoBoxProps) => (
    <Column marginLeft={'s12'} style={styles.logo} justifyContent="center" {...columnProps}>
        {shouldShowFallbackLogo ? (
            <Shield primary={primaryColor} secondary={secondaryColor} />
        ) : (
            <Image resizeMode="contain" style={styles.logo} source={{ uri: logoUrl }} />
        )}
    </Column>
);

const styles = StyleSheet.create({
    logo: {
        width: LOGO_SIZE,
        height: LOGO_SIZE,
    },
});
