import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import SubtractIcon from '@/assets/icons/subtract';
import { LineSeparator } from '@/components/LineSeparator';
import { RadioItem } from '@/components/RadioItem';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { common } from '@/styles/styles';

import { commonStyles } from './styles';

const styles = StyleSheet.create({
    listItem: {
        display: 'flex',
        height: 56,
    },
    radio: {
        width: 24,
        height: 24,
    },
});

const touchableOpacityStyles = [common.row, common.alignCenter, common.justifySpaceBetween, styles.listItem];

// TODO: Update when real data is available
type RealityCheckOption = {
    id: number;
    title: string;
};
// TODO: Update when real data is available
const REALITY_CHECK_OPTIONS: RealityCheckOption[] = [
    {
        id: 15,
        title: 'Every 15 minutes',
    },
];

type ListItemProps = {
    item: RealityCheckOption;
    isSelected: boolean;
};

const ItemSeparatorComponent = () => <LineSeparator />;

const ListItem = ({ item, isSelected }: ListItemProps) => {
    return (
        <TouchableOpacity style={touchableOpacityStyles} onPress={() => trackRG.realityCheckScreen(item.title)}>
            <View>
                <Text fontSize={15} color={'gray2'}>
                    {item.title}
                </Text>
            </View>
            <View style={[styles.radio, common.alignCenter, common.justifyCenter]}>
                <RadioItem selected={isSelected} />
            </View>
        </TouchableOpacity>
    );
};

export const RealityCheck = () => {
    const { t } = useTranslation(['rg']);
    const isSelected = true; // TODO: Update when real data is available. For now we are assuming the first option is selected.

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('rg:reality_checks') }}
            scrollViewProps={{ style: [commonStyles.scrollView, { paddingHorizontal: 0 }] }}
        >
            <MaxWidthWrapper paddingHorizontal={'s16'}>
                <Text fontSize={13} color={'gray2'} lineHeight={20}>
                    {t('rg:reality_check_body')}
                </Text>
                <SizedBox value={16} />
                <Text fontSize={13} color={'gray2'} lineHeight={20}>
                    {t('rg:reality_check_body_example')}
                </Text>
                <SizedBox value={20} />
                <FlatList
                    data={REALITY_CHECK_OPTIONS}
                    ItemSeparatorComponent={ItemSeparatorComponent}
                    renderItem={({ item }) => <ListItem {...{ item, isSelected }} />}
                />
                <LineSeparator />
                <SizedBox value={24} />
                <Banner
                    leftIcon={<SubtractIcon />}
                    message={t('rg:reality_check_banner')}
                    borderColor={'gray5'}
                    borderWidth={1}
                />
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
