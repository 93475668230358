import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 20 20" fill="none">
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1667 8.7513C14.1667 11.7428 11.7415 14.168 8.74999 14.168C5.75845 14.168 3.33332 11.7428 3.33332 8.7513C3.33332 5.75976 5.75845 3.33464 8.74999 3.33464C11.7415 3.33464 14.1667 5.75976 14.1667 8.7513ZM13.1349 14.3146C11.9288 15.2665 10.4057 15.8346 8.74999 15.8346C4.83797 15.8346 1.66666 12.6633 1.66666 8.7513C1.66666 4.83928 4.83797 1.66797 8.74999 1.66797C12.662 1.66797 15.8333 4.83928 15.8333 8.7513C15.8333 10.407 15.2652 11.93 14.3134 13.1361L18.0893 16.9121L16.9108 18.0906L13.1349 14.3146Z"
            fill={props.color ?? designSystem.colors.white}
        />
    </Svg>
);

export default SvgComponent;
