import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { common, designSystem } from '@/styles/styles';

import { SizedBox } from './SizedBox';
import { Text, TextProps } from './TextComponent';

const styles = StyleSheet.create({
    badge: {
        borderRadius: 12,
        height: 24,
        paddingHorizontal: 8,
    },
    normal: {
        backgroundColor: designSystem.colors.purple,
    },
    error: {
        backgroundColor: designSystem.colors.red,
    },
    info: {
        backgroundColor: designSystem.colors.gray3,
    },
});

export const Badge = ({
    label,
    style,
    type = 'normal',
    leftIcon,
    textProps,
    ...props
}: ViewProps & {
    leftIcon?: React.ReactNode;
    textProps?: TextProps;
    label: string;
    type?: 'normal' | 'error' | 'info';
}) => {
    return (
        <View
            style={[common.row, common.alignCenter, common.justifyCenter, styles.badge, styles[type], style]}
            {...props}
        >
            {leftIcon ? (
                <>
                    {leftIcon}
                    <SizedBox value={5} />
                </>
            ) : null}
            <Text variant="labelMedium" {...textProps} testID="badgeLabel">
                {label}
            </Text>
        </View>
    );
};
