import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={36} height={27} fill="none" {...props}>
        <Path
            fill="#000"
            fillRule="evenodd"
            d="M12.522 20.423 32.945 0 36 3.055 12.522 26.533 0 14.011l3.055-3.054 9.467 9.466Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
