import { create } from 'zustand';

export type AcknowledgementFieldsForRetry = Pick<
    KycFields,
    | 'tncAcknowledgementAccepted'
    | 'infoAcknowledgementAccepted'
    | 'prohibitedAcknowledgementAccepted'
    | 'ageAcknowledgementAccepted'
>;

export type AddressFormValues = Pick<KycFields, 'address' | 'city' | 'americanState' | 'zip'>;

export type KycFields = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    ssn: string;
    tncAcknowledgementAccepted: boolean;
    infoAcknowledgementAccepted: boolean;
    prohibitedAcknowledgementAccepted: boolean;
    ageAcknowledgementAccepted: boolean;
    address: string;
    city: string;
    americanState: string;
    zip: string;
};

/**
 * Store that holds the KYC fields state through KYC flow.
 */
type KycFieldsStore = {
    values: KycFields;
    setFieldValue: (name: keyof KycFields, value?: string | number | boolean) => void;
    setMultipleFieldValues: (fieldValues: Partial<KycFields>) => void;
    setAcknowledgementValues: (fieldValues: AcknowledgementFieldsForRetry) => void;
    setAddressFormValues: (fieldValues: AddressFormValues) => void;
    reset: () => void;
};

export const initialAcknowledgementValues = {
    tncAcknowledgementAccepted: false,
    infoAcknowledgementAccepted: false,
    prohibitedAcknowledgementAccepted: false,
    ageAcknowledgementAccepted: false,
};

// Initial Values for Regular Flow
const initialValues = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    ssn: '',
    ...initialAcknowledgementValues,
    address: '',
    city: '',
    americanState: '',
    zip: '',
};

export const useKycFieldsStore = create<KycFieldsStore>()(set => {
    return {
        values: initialValues,
        setFieldValue: (fieldName, fieldValue) => {
            set(state => ({ values: { ...state.values, [fieldName]: fieldValue } }));
        },
        setMultipleFieldValues: fieldValues => {
            set(state => ({ values: { ...state.values, ...fieldValues } }));
        },
        setAcknowledgementValues: fieldValues => {
            set(state => ({ values: { ...state.values, ...fieldValues } }));
        },
        setAddressFormValues: fieldValues => {
            set(state => ({ values: { ...state.values, ...fieldValues } }));
        },
        reset: () => {
            set({ values: initialValues });
        },
    };
});
