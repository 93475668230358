import { logger } from './logging';

export const sleep = (ms: number) => new Promise<void>(r => setTimeout(r, ms));

/**
 * A wrapped Promise that can be canceled via `cancel()`.
 * Usage : CancelablePromise.from(promise).then(res=>{})
 */
export class CancelablePromise<T> {
    private promise: Promise<T>;

    running = true;
    canceled = false;
    tag = '';

    static from<TT>(promise: Promise<TT>, tag: string = ''): CancelablePromise<TT> {
        return new CancelablePromise(promise, tag);
    }

    cancel() {
        this.canceled = true;
    }

    then(handler: (res: T) => void) {
        this.promise.then(handler, err => {
            if (!isCanceledException(err)) {
                throw err;
            } else {
                logger.debug(`Promise[${this.tag}] is canceled. Skipping.`);
            }
        });
    }

    catch(handler: (err: unknown) => void) {
        this.promise.catch(err => {
            if (!isCanceledException(err)) {
                handler(err);
            } else {
                logger.debug(`Promise[${this.tag}] is canceled. Do not throw:`, err);
            }
        });
    }

    private constructor(initialPromise: Promise<T>, tag: string) {
        this.canceled = false;
        this.tag = tag;
        this.promise = new Promise<T>((resolve, reject) => {
            initialPromise.then(
                val => {
                    this.running = false;
                    if (this.canceled) {
                        reject({ isCanceled: true });
                    } else {
                        resolve(val);
                    }
                },
                error => {
                    this.running = false;
                    if (this.canceled) {
                        reject({ isCanceled: true });
                    } else {
                        reject(error);
                    }
                }
            );
        });
    }
}

export const isCanceledException = (e: unknown) => {
    return !!e && typeof e === 'object' && 'isCanceled' in e;
};
