import React, { ReactNode } from 'react';
import { View } from 'react-native';

/**
 * This View will not be fading on the web as it causes a crash from react-native-reanimated
 * Uncaught TypeError: Cannot read properties of null (reading 'getBoundingClientRect')
 * error occurred in the <AnimatedComponent(View)>
 */
export const AnimatedFadeOutView = ({ children }: { duration?: number; children: ReactNode }) => {
    return <View>{children}</View>;
};
