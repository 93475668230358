import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Path
            fill="#EDEFF3"
            fillRule="evenodd"
            d="m11.778 4.22 1.414 1.414-5.364 5.364H21v2H7.828l5.364 5.364-1.414 1.414L4 11.998l7.778-7.778Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
