import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={8} height={8} fill="none" {...props}>
        <G clipPath="url(#a)">
            <Path fill="#D95CFF" d="M0 4a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" />
            <Path stroke="#F8E0FF" strokeWidth={0.5} d="M6-10-6 24M8-10-4 24M10-10-2 24M12-10 0 24" />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 4a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgComponent;
