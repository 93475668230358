import * as React from 'react';
import Svg, { Circle, Rect } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Rect x={14.514} y={16.201} width={2.5} height={8} rx={0.5} transform="rotate(-45 14.514 16.2)" fill="#fff" />
        <Circle cx={10.076} cy={9.64} r={8.25} stroke="#fff" strokeWidth={2.5} />
    </Svg>
);

export default SvgComponent;
