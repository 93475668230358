import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
        <Path
            fill={designSystem.colors.purple2}
            d="M6.815 9.66 6.34 14.4l6.163-6.637-3.319-1.896.474-4.267-6.163 6.637 3.319 1.422Z"
        />
    </Svg>
);
export default SvgComponent;
