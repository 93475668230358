import React, { useCallback } from 'react';
import Animated from 'react-native-reanimated';

import { useStickyTabList, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { SubMarketTabs } from '@/feature/event-details-sbk/components/SubMarketTabs';
import PlayerProp from '@/feature/event-details-sbk/components/market-page-layouts/player-props-layout/PlayerProp';
import { useActiveSubMarket } from '@/feature/event-details-sbk/hooks/use-active-submarket';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { useHasPublishedMarkets, usePublishedMarkets } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { Market, MarketCategory } from '@/feature/event-details-sbk/types';
import { common, designSystem } from '@/styles/styles';
import { QueryObserverResult } from '@tanstack/react-query';

import { useEventsGroupedById } from '../../hooks/use-events';
import { useLeagueMarketList } from '../../hooks/use-league-market-list';
import { useSectionHeights } from '../../hooks/use-section-heights';

const keyExtractor = (item: Market) => item.id;

type LeaguePlayerPropLayoutProps = {
    leagueId: string;
    sportName: string;
    marketCategories: MarketCategory[];
    refetch: () => Promise<QueryObserverResult<any, unknown>[]>;
    tabKey: string;
    isTabBarVisible: boolean;
};

export const LeaguePlayerPropLayout = ({
    leagueId,
    sportName,
    marketCategories,
    refetch,
    tabKey,
    isTabBarVisible,
}: LeaguePlayerPropLayoutProps) => {
    const { gameSectionHeight, marketHeaderSectionHeight } = useSectionHeights(leagueId);
    const { activeSubMarket, setActiveSubMarket } = useActiveSubMarket(marketCategories);
    const hasPublishedMarkets = useHasPublishedMarkets(activeSubMarket ? [activeSubMarket] : []);
    const publishedMarkets = usePublishedMarkets(activeSubMarket?.markets ?? []);

    const { data: eventsById = {} } = useEventsGroupedById(leagueId);

    const renderItem = useCallback(
        ({ item: market }: { item: Market }) => {
            const event = eventsById[market.event_id];
            return <PlayerProp hasEventInfo market={market} event={event} />;
        },
        [eventsById]
    );

    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);
    const { scrollableProps, setStickyRef, contentHeight } = useStickyTabList(tabKey, forceRefreshing, onRefresh);
    const { flatListProps } = useLeagueMarketList(
        hasPublishedMarkets,
        contentHeight,
        isTabBarVisible,
        marketHeaderSectionHeight
    );
    const { smoothScrollHeaderStyle } = useStickyTabsAnimation();

    if (!activeSubMarket) {
        return null;
    }

    const top = gameSectionHeight + (isTabBarVisible ? TAB_HEIGHT : 0);
    const paddingTop = marketHeaderSectionHeight + (isTabBarVisible ? TAB_HEIGHT : 0);

    return (
        <>
            <Animated.View
                style={[common.full, smoothScrollHeaderStyle, { top, backgroundColor: designSystem.colors.gray8 }]}
            >
                <SubMarketTabs
                    {...{
                        sportName,
                        paddingTop,
                        marketCategories,
                        activeSubMarket,
                        setActiveSubMarket,
                    }}
                />
            </Animated.View>
            <Animated.FlatList<Market>
                data={publishedMarkets}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                windowSize={10}
                initialNumToRender={10}
                maxToRenderPerBatch={10}
                updateCellsBatchingPeriod={80}
                {...scrollableProps}
                // ! flatListProps should come after scrollableProps as it overrides contentContainerStyle
                {...flatListProps}
                // ! ref cannot be set through spread operator
                ref={setStickyRef}
            />
        </>
    );
};
