import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M16 11.9637C16 12.2379 15.8875 12.5 15.6887 12.6887L10.2736 17.8331C10.0734 18.0233 9.75694 18.0152 9.56674 17.815L8.878 17.09C8.6878 16.8898 8.69592 16.5733 8.89612 16.3831L13.5481 11.9637L8.89612 7.54437C8.69592 7.35418 8.6878 7.0377 8.878 6.8375L9.56675 6.1125C9.75694 5.9123 10.0734 5.90418 10.2736 6.09437L15.6887 11.2387C15.8875 11.4275 16 11.6896 16 11.9637Z"
            fill={props.color || '#787C85'}
        />
    </Svg>
);

export default SvgComponent;
