import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexStyle, StyleProp } from 'react-native';

import { GameTrackerButton } from '@/components/GameTrackerButton';
import { Tooltip } from '@/components/Tooltip';
import { FloatingModalConfig } from '@/utils/floatingModal/FloatingModalProvider';

import { OneTimeShowKeys, useShowOneTime } from './use-show-one-time';

export const OneTimeTooltipGameTracker = ({
    gameTrackerConfig,
    screenName,
    tooltipContentPosition,
    iconColor,
}: {
    gameTrackerConfig: FloatingModalConfig;
    screenName: OneTimeShowKeys;
    tooltipContentPosition?: StyleProp<FlexStyle>;
    iconColor?: string;
}) => {
    const { t } = useTranslation();
    const { show, handleInteraction } = useShowOneTime(screenName);

    useEffect(() => {
        return () => {
            if (show) {
                handleInteraction();
            }
        };
    }, [handleInteraction, show]);

    if (!show) {
        return <GameTrackerButton iconColor={iconColor} config={gameTrackerConfig} />;
    }

    return (
        <Tooltip
            tooltipText={t('event_visualizer')}
            direction="bottom"
            initialState="visible"
            tooltipContentPosition={tooltipContentPosition ?? { right: -4, top: 32 }}
            performSideEffectOnClose={handleInteraction}
        >
            <GameTrackerButton sideEffectOnPress={handleInteraction} iconColor={iconColor} config={gameTrackerConfig} />
        </Tooltip>
    );
};
