import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Path stroke={props.color ?? designSystem.colors.gray2} strokeWidth={2} d="m6 8.5 5.657 5.657L17.314 8.5" />
    </Svg>
);
export default SvgComponent;
