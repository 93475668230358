import { useTranslation } from 'react-i18next';

import { BetSummarySectionProps } from '@/components/BetSummarySection';
import { TextProps } from '@/components/TextComponent';
import { Bet } from '@/feature/bets-sbk/hooks/types';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { toTitleCase } from '@/utils/toTitleCase';

import { getMultiplier } from '../../utils/bet-summary-section-utils';

/**
 * Shared bet summary props for all Bet Cards
 */
export const useSharedBetSummaryProps = (bet: Bet) => {
    const { t } = useTranslation(['bets', 'wallet']);
    return {
        header1Text: bet.is_betr_bucks ? t('wallet:betr_bucks') : t('bets:wager'),
        header2Text: t('multiplier'),
        header3Text: t('to_pay'),
        stake: bet.stake,
        multiplier: bet.is_betr_bucks ? bet.current_odds : bet.requested_odds,
        isBetrBucks: bet.is_betr_bucks,
    };
};

/**
 * Handles 'Voided' bet summary for all Bet Cards
 */
export const useVoidedBetSummary = (
    bet: Bet,
    voidedTextColor: TextProps['color'] = 'gray3'
): BetSummarySectionProps => {
    const { t } = useTranslation('bets');
    return {
        ...useSharedBetSummaryProps(bet),
        header3Text: undefined,
        multiplier: undefined,
        body1TextDecorationLine: 'line-through' as const,
        header1TextColor: voidedTextColor,
        header2TextColor: voidedTextColor,
        body1TextColor: voidedTextColor,
        body3TextColor: voidedTextColor,
        strikeThroughMultiplierColor: voidedTextColor,
        strikeThroughMultiplier: bet.requested_odds,
        body3Text: toTitleCase(t('voided')),
    };
};

export const getSettledTextColor = (bet: Bet) => {
    let header3TextColor;
    let body3TextColor;
    if (bet.result === 'WON') {
        header3TextColor = 'green' as const;
        body3TextColor = 'green' as const;
    }
    return { header3TextColor, body3TextColor };
};

/**
 * Handles 'Open' Script and Combo bet summary for Bet Cards
 */
export const useOpenMultiBetSummary = (bet: Bet): BetSummarySectionProps => {
    return {
        ...useSharedBetSummaryProps(bet),
        ...getMultiplier(bet),
        body3Text: toLocaleCurrency(bet.potential_payout),
    };
};

/**
 * Handles 'Settled' Script and Combo bet summary for Bet Cards
 */
export const useSettledMultiBetSummary = (bet: Bet): BetSummarySectionProps => {
    const { t } = useTranslation('bets');
    return {
        ...useSharedBetSummaryProps(bet),
        ...getMultiplier(bet),
        ...getSettledTextColor(bet),
        header3Text: t('paid'),
        body3Text: toLocaleCurrency(bet.amount_won),
    };
};
