import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
        <Path
            fill="#EDEFF3"
            fillRule="evenodd"
            d="M18 0H0v18h18V0ZM4.414 7.182l3.182 3.182L13.96 4l1.414 1.414-7.778 7.778L3 8.596l1.414-1.414Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
