import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { GestureDetector } from 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { Screen } from '@/components/ScreenComponent';
import { SCREEN_NAV_BAR_HEIGHT, ScreenNavBar } from '@/components/ScreenNavBar';
import { StickyTabsProvider, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { TOP_TAB_BAR_CONFIG, TopTabBar, TopTabBarProps } from '@/components/TopTabBar';
import { common, designSystem } from '@/styles/styles';
import { Result, Status } from '@/types/api.generated';

import { EntriesList } from '../components/EntriesList';

export interface Section {
    label: 'Open' | 'Closed' | 'Won' | 'Lost';
    criteria?: Status | Result;
    status?: Status;
    result?: Result;
}

type SECTION_KEYS = 'OPEN' | 'CLOSED' | 'WON' | 'LOST';
type SECTION_SCREENS = `Entries-${SECTION_KEYS}`;
type Sections = {
    [K in SECTION_KEYS]: Section;
};

export type EntriesTabParamsList = {
    [K in SECTION_SCREENS]: {
        currentSection: Section;
    };
};

const SECTIONS: Sections = {
    OPEN: { label: 'Open', status: Status.Pending },
    CLOSED: { label: 'Closed', status: Status.Settled },
    WON: { label: 'Won', result: Result.Win },
    LOST: { label: 'Lost', result: Result.Loss },
};

const SECTIONS_ORDER: SECTION_KEYS[] = ['OPEN', 'CLOSED', 'WON', 'LOST'];

// known warning from react-native 0.72+ regarding `onAnimatedValueUpdate` with no exact fix for tabs
// https://github.com/react-navigation/react-navigation/issues/7839
const Tab = createMaterialTopTabNavigator<EntriesTabParamsList>();

export const EntriesTopTabBarScreen = () => (
    <StickyTabsProvider>
        <EntriesStickyTabs />
    </StickyTabsProvider>
);

const EntriesStickyTabs = () => {
    const { t } = useTranslation(['pickem_entries']);
    const { panGesture, smoothScrollHeaderStyleBelowTabsPullToRefresh, tabListeners } = useStickyTabsAnimation();

    const renderStickyTabBar = useCallback(
        (props: TopTabBarProps) => {
            return (
                <Animated.View
                    style={[
                        smoothScrollHeaderStyleBelowTabsPullToRefresh,
                        { backgroundColor: designSystem.colors.gray8, top: SCREEN_NAV_BAR_HEIGHT },
                    ]}
                >
                    <TopTabBar {...props} customEdges={[]} />
                </Animated.View>
            );
        },
        [smoothScrollHeaderStyleBelowTabsPullToRefresh]
    );

    return (
        <Screen>
            <View style={[common.noOverflow, common.flex]}>
                <GestureDetector gesture={panGesture}>
                    <Animated.View
                        style={[
                            common.full,
                            smoothScrollHeaderStyleBelowTabsPullToRefresh,
                            { backgroundColor: designSystem.colors.gray8 },
                        ]}
                    >
                        <ScreenNavBar closeIconMode="none" title={t('my_entries')} />
                    </Animated.View>
                </GestureDetector>
                <Tab.Navigator
                    screenListeners={tabListeners}
                    tabBar={renderStickyTabBar}
                    initialRouteName="Entries-OPEN"
                    screenOptions={TOP_TAB_BAR_CONFIG}
                >
                    {SECTIONS_ORDER.map(sectionKey => (
                        <Tab.Screen
                            name={`Entries-${sectionKey}`}
                            key={sectionKey}
                            options={{ title: SECTIONS[sectionKey].label }}
                            initialParams={{ currentSection: SECTIONS[sectionKey] }}
                            component={EntriesList}
                        />
                    ))}
                </Tab.Navigator>
            </View>
        </Screen>
    );
};
