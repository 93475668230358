import React from 'react';
import { LayoutAnimation } from 'react-native';

import { EventInfo } from '@/api/events/types/types';
import { LineSeparator } from '@/components/LineSeparator';
import { BetslipPick } from '@/feature/betslip-pickem/types';
import { PlayerRow } from '@/feature/lobby/components/PlayerRow';
import { PlayerRowSkeleton } from '@/feature/lobby/components/Skeletons/PlayerRowSkeleton';
import { PickSelectionMethods } from '@/feature/lobby/hooks/use-pick-selection';
import { common } from '@/styles/styles';
import { runStoreUpdate } from '@/utils/zustand';

import { isPlayerSelected } from '../utils/betslip-utils';
import { eventUtils } from '../utils/event-utils';

export const PlayerPickSlipCard: React.FC<
    BetslipPick & {
        presentation: 'default' | 'sheet';
        shouldDisplaySeparator?: boolean;
        eventDetails?: EventInfo;
        loading?: boolean;
    } & PickSelectionMethods
> = ({
    player: playerFromPickSlip,
    outcome,
    projection,
    presentation,
    shouldDisplaySeparator,
    eventDetails,
    makeSelection,
    openPlayerPickModal,
    removeSelection,
    loading,
}) => {
    const team = eventUtils.getTeamById(eventDetails, playerFromPickSlip.team?.id);

    const playerWithLatestValues = team?.players?.find(p => p.id === playerFromPickSlip.id);
    const player = {
        ...(playerWithLatestValues || playerFromPickSlip),
        team,
        league: playerFromPickSlip.league,
        sport: playerFromPickSlip.sport,
    };
    const picked = player && isPlayerSelected({ playerId: player.id, eventId: eventDetails?.id });

    const animateRemove = () => {
        LayoutAnimation.configureNext({
            update: { type: 'easeInEaseOut' },
            duration: 300,
        });
        runStoreUpdate(() => {
            picked && removeSelection([{ player: player, eventId: picked.eventId }]);
        });
    };

    if (loading) {
        return <PlayerRowSkeleton />;
    }

    return (
        <>
            <PlayerRow
                mode="selection"
                projection={projection}
                player={player}
                event={eventDetails}
                outcome={outcome}
                showTrash
                pressable
                presentation={presentation}
                onRemoveFromLineup={animateRemove}
                makeSelection={makeSelection}
                openPlayerPickModal={openPlayerPickModal}
                removeSelection={removeSelection}
            />
            {shouldDisplaySeparator ? <LineSeparator style={common.hairlineHeight} /> : null}
        </>
    );
};
