import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';

import { ReplacePickModal, ReplacePickModalRef, ReplacePickProps } from './ReplacePickModal';

type ReplacePick = {
    showReplacePickModal: (props: ReplacePickProps) => void;
};

const ReplacePickContext = React.createContext<ReplacePick>({
    showReplacePickModal: () => {},
});

/**
 * Provides access to the Modal used to confirm the replacement of projection for the same player
 */
export const ReplacePickProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const replacePickModalRef = useRef<ReplacePickModalRef>(null);

    const showReplacePickModal = useCallback((props: ReplacePickProps) => {
        replacePickModalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <ReplacePickContext.Provider value={{ showReplacePickModal }}>
                {children}
                <ReplacePickModal ref={replacePickModalRef} />
            </ReplacePickContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useReplacePick = () => {
    return useContext(ReplacePickContext);
};
