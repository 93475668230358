export const formatNumber = (input: number | string | undefined): string => {
    if (!input) {
        return '';
    }
    return parseFloat(input.toString()).toLocaleString('en-US');
};

export const ordinal = (n: number) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
