import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import RocketIcon from '@/assets/icons/rocket';
import { SizedBox } from '@/components/SizedBox';
import { designSystem } from '@/styles/styles';

import { MultiplierLabels } from './MultiplierLabels';

const HEIGHT = 8;
const DOT_SIZE = 4;
const DOT_PADDING = 3;

const styles = StyleSheet.create({
    bg: {
        backgroundColor: designSystem.colors.gray6,
        height: HEIGHT,
        borderRadius: HEIGHT,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inner: {
        backgroundColor: designSystem.colors.gray1,
        height: HEIGHT,
        position: 'absolute',
        borderRadius: HEIGHT,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    dot: {
        marginHorizontal: DOT_PADDING,
        width: DOT_SIZE,
        height: DOT_SIZE,
        borderRadius: DOT_SIZE,
    },
    boostedIcon: {
        backgroundColor: designSystem.colors.boosted,
        borderWidth: 2,
        borderColor: designSystem.colors.gray8,
        borderRadius: 10,
        width: 20,
        height: 20,
        marginRight: -10,
    },
});

interface Props {
    showMultiplierLabels?: boolean;
    hasCustomPicks?: boolean;
    showCustomPicksIcon?: boolean;
    multiplierBackgroundColor?: string;
    progress: number;
    maxNumberOfPicks: number;
}

export const MultiplierProgressBar: React.FC<Props> = ({
    showMultiplierLabels,
    hasCustomPicks,
    showCustomPicksIcon = false,
    multiplierBackgroundColor = designSystem.colors.gray1,
    progress,
    maxNumberOfPicks,
}) => {
    const initialRender = useRef<boolean>(true);
    const [width, setWidth] = useState<number>(0);
    const dotGap = (width - 2 * DOT_PADDING - DOT_SIZE) / (maxNumberOfPicks - 1);
    const minWidth = DOT_SIZE + 2 * DOT_PADDING;
    const dots = new Array(maxNumberOfPicks).fill(0);

    const computeFillWidth = useCallback(() => {
        return Math.min(width, minWidth + (progress - 1) * dotGap);
    }, [dotGap, minWidth, progress, width]);

    const [progressWidth, setProgressWidth] = useState(computeFillWidth());

    useEffect(() => {
        setProgressWidth(computeFillWidth());

        if (width > 0) {
            initialRender.current = false;
        }
    }, [computeFillWidth, dotGap, minWidth, setProgressWidth, width]);

    return (
        <View>
            <View
                style={styles.bg}
                onLayout={e => {
                    setWidth(e.nativeEvent.layout.width);
                }}
            >
                <View
                    style={[
                        styles.inner,
                        {
                            width: progressWidth,
                            backgroundColor: multiplierBackgroundColor,
                        },
                    ]}
                />
                {dots.map((_, idx) => {
                    // surpassed dots must be gray 4
                    // current dot must be black
                    // not surpassed dots must be black with 0.3 opacity
                    const backgroundColor = idx > progress - 1 ? designSystem.colors.gray5 : designSystem.colors.gray8;
                    const opacity = idx < progress - 1 ? 0.2 : 1;
                    return hasCustomPicks && idx === 0 && showCustomPicksIcon ? (
                        <View key={`dot-${idx}-custom`} style={styles.boostedIcon}>
                            <RocketIcon />
                        </View>
                    ) : (
                        <View
                            key={`dot-${idx}-regular`}
                            style={[
                                styles.dot,
                                {
                                    backgroundColor,
                                    opacity,
                                },
                            ]}
                        />
                    );
                })}
            </View>
            <SizedBox value={8} />
            {showMultiplierLabels ? (
                <MultiplierLabels max={maxNumberOfPicks} dotSize={DOT_SIZE} dotPadding={DOT_PADDING} />
            ) : null}
        </View>
    );
};
