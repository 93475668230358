import React from 'react';

import { useEventTime } from '@/components/EventTime';
import { useLiveEventStatus } from '@/components/LiveEventStatus';
import { Text } from '@/components/TextComponent';
import { Event } from '@/feature/event-details-sbk/types';
import { getSportKey } from '@/utils/get-sport-key';

const getActiveTextColor = (active: boolean) => (active ? 'gray1' : 'gray2');

export const EventCardHeader = ({ event, isLive, testID }: { event: Event; isLive: boolean; testID?: string }) => {
    const eventTime = useEventTime(event);
    const liveEventStatus = useLiveEventStatus(event);
    const header =
        liveEventStatus && getSportKey(event.sport) !== 'american_football'
            ? `${eventTime}, ${liveEventStatus}`
            : eventTime;
    return (
        <Text
            color={getActiveTextColor(isLive)}
            variant="bodySmall"
            marginBottom={'s8'}
            testID={testID}
            numberOfLines={1}
        >
            {header}
        </Text>
    );
};
