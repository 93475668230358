import React from 'react';
import { StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import SearchIcon from '@/assets/icons/search';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { common, designSystem } from '@/styles/styles';

type SearchInputProps = {
    query: string;
    onQueryChange: (newQuery: string) => void;
};

const styles = StyleSheet.create({
    input: {
        fontSize: 15,
        color: designSystem.colors.white,
        height: 40,
    },
    icon: {
        height: 40,
        width: 40,
    },
    inputBox: {
        backgroundColor: designSystem.colors.gray3,
        borderRadius: 10,
    },
    root: {
        height: 40,
    },
});

export const SearchInput: React.FC<SearchInputProps> = ({ query, onQueryChange }) => {
    const navigation = useNavigation();

    return (
        <>
            <View style={[common.row, common.alignCenter, common.paddingHorizontal, styles.root]}>
                <View style={[common.row, common.flex, styles.inputBox]}>
                    <View style={[common.alignCenter, common.justifyCenter, styles.icon]}>
                        <SearchIcon />
                    </View>
                    <TextInput
                        style={[common.flex, styles.input]}
                        placeholder="Search betr..."
                        onChangeText={onQueryChange}
                        value={query}
                        placeholderTextColor={designSystem.colors.white}
                        keyboardAppearance="dark"
                    />
                </View>
                <SizedBox value={20} />
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Text>Cancel</Text>
                </TouchableOpacity>
            </View>
            <SizedBox value={20} />
            <LineSeparator />
        </>
    );
};
