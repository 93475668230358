import { SBKBetSlip, Selection } from '@/feature/betslip-sbk/types';
import { BetIds } from '@/feature/betslip-sbk/utils/betslip-utils';

/**
 * Remove selection from bet slip
 * @param state - current bet slip state
 * @param selectionId - selection id to remove
 */
export const removeSelection = (
    state: Pick<
        SBKBetSlip,
        | 'bets'
        | 'selections'
        | 'options'
        | 'markets'
        | 'events'
        | 'eventOrder'
        | 'selectionOrder'
        | 'showKeyboard'
        | 'editBetId'
        | 'oddsChanges'
        | 'sgpOddsChanges'
    >,
    selectionId: string
) => {
    const selection = state.selections[selectionId];
    if (!selection) {
        return {};
    }

    const options = { ...state.options };
    delete options[selection.optionId];

    const selections = { ...state.selections };
    delete selections[selectionId];

    // remove market if no selections left
    const markets = { ...state.markets };
    if (!Object.values(selections).find(s => s.marketId === selection.marketId)) {
        delete markets[selection.marketId];
    }

    // remove event and eventOrder if no selections left
    const events = { ...state.events };
    let eventOrder = [...state.eventOrder];
    if (!Object.values(selections).find(s => s.eventId === selection.eventId)) {
        delete events[selection.eventId];
        eventOrder = removeEventOrder(selection.eventId, state.eventOrder);
    }

    const bets = removeBets(selection, state.bets, state.selections);

    const selectionOrder = state.selectionOrder.filter(id => id !== selectionId);

    // hide keyboard if editing bet is removed
    let showKeyboard = state.showKeyboard;
    if (state.showKeyboard && state.editBetId === BetIds.SINGLE(selection)) {
        showKeyboard = false;
    }

    // remove odds change if selection is removed
    const oddsChanges = { ...state.oddsChanges };
    delete oddsChanges[selectionId];

    // remove sgp odds change if selection is removed
    const sgpOddsChanges = Object.keys(state.sgpOddsChanges).reduce<Record<string, number>>((acc, key) => {
        if (!key.includes(selectionId)) {
            acc[key] = state.sgpOddsChanges[key];
        }
        return acc;
    }, {});

    // remove editBetId if selection is removed
    const editBetId = state.editBetId === selectionId ? null : state.editBetId;

    return {
        options,
        selections,
        markets,
        events,
        bets,
        selectionOrder,
        eventOrder,
        showKeyboard,
        oddsChanges,
        editBetId,
        sgpOddsChanges,
    };
};

/**
 * Removes the necessary bets from bet slip state when a selection is removed
 * @param selection - the selection to be removed
 * @param bets - the current bets
 * @param selections - the current selections
 */
export const removeBets = (selection: Selection, bets: SBKBetSlip['bets'], selections: SBKBetSlip['selections']) => {
    const updatedBets = { ...bets };
    delete updatedBets[BetIds.SINGLE(selection)];
    if (Object.keys(selections).length <= 2) {
        delete updatedBets[BetIds.COMBO];
    }
    return updatedBets;
};

/**
 * Remove eventId from eventOrder.
 * @param eventId - the eventId to be removed from eventOrder
 * @param eventOrder - bet slip state's eventOrder
 */
export const removeEventOrder = (eventId: string, eventOrder: SBKBetSlip['eventOrder']) => {
    return eventOrder.filter(event => event !== eventId);
};
