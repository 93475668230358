import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Animated, {
    SharedValue,
    interpolate,
    useAnimatedScrollHandler,
    useAnimatedStyle,
    useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
    PlayerInfoWithProjectionsFragment,
    PlayerProjectionFragment,
    useUpcomingEventsInfoQuery,
} from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import { CloseIcon } from '@/assets/icons/close';
import DiscountIcon from '@/assets/icons/discount';
import FavoriteSelected from '@/assets/icons/favorite-selected';
import FavoriteUnselected from '@/assets/icons/favorite-unselected';
import InflatedIcon from '@/assets/icons/inflated';
import InfoIcon from '@/assets/icons/info-small';
import LockIcon from '@/assets/icons/lock';
import RocketIcon from '@/assets/icons/rocket';
import { AnimatedNumber } from '@/components/AnimatedNumber';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row, TouchableBox } from '@/components/lib/components';
import { PlayerProfileImage } from '@/components/player-profile/PlayerProfileImage';
import { ScrollableModal } from '@/feature/alerts/components/Modal';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useFantasyPoints } from '@/feature/betslip-pickem/components/FantasyPointsModalProvider';
import { isPlayerSelected } from '@/feature/betslip-pickem/utils/betslip-utils';
import { useLocalFlagsStore } from '@/feature/devsettings/hooks/use-dev-settings';
import { PickButtonsSection } from '@/feature/lobby/components/PickButtonsSection';
import { EventDetails, PlayerDescription } from '@/feature/lobby/components/PlayerDetails';
import { SingleProjectionIcon } from '@/feature/lobby/components/SingleProjectionIcon';
import { findEnabledLobbyComponentByOrderingKey } from '@/feature/lobby/hooks/use-lobby-query-variables';
import { usePickSelection } from '@/feature/lobby/hooks/use-pick-selection';
import { checkIfDiscountedProjection, checkIfRegularProjection } from '@/feature/lobby/utils/lineupsUtils';
import { DEFAULT_LOBBY_ORDERING, LobbyComponent } from '@/feature/lobby/utils/lobbySections';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useUser } from '@/hooks/use-user';
import { SEPARATOR_HEIGHT, common, designSystem, withOpacity } from '@/styles/styles';
import { MarketStatus, Outcome, ProjectionType } from '@/types/api.generated';
import { isWeb } from '@/utils/constants-platform-specific';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { gameUtils } from '@/utils/games';
import { getPlayerArcDetails } from '@/utils/get-player-arc-details';
import { getColorByProjType, getNonRegularProjections } from '@/utils/getProjectionsTypes';
import { logger } from '@/utils/logging';
import { formatNumber } from '@/utils/numeric/format';
import { sortProjections } from '@/utils/sortProjections';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { useBetslipData } from '../hooks/use-betslip-data';
import { useDebugSimulateLiveMarketsUpdates } from '../hooks/use-betslip-debug-hooks';
import { useBetslipStore } from '../hooks/use-betslip-store';
import { leagueConfigSelector, useLeagueConfigsStore } from '../hooks/use-league-configs-store';
import { useLiveEventsPropsUpdate } from '../hooks/use-live-props-update';
import {
    allPlayerPropsSelector,
    playerPropsSelector,
    usePlayerPropsStore,
    useUpdatePlayerStoreWithNewData,
} from '../hooks/use-player-props-store';
import { BetslipPick, PlayerProjection, PlayerWithTeam } from '../types';
import { useFavorites } from './FavoritesProvider';

const styles = StyleSheet.create({
    position: {
        fontWeight: 'normal',
    },
    playerImageSizes: {
        width: 46,
        height: 46,
    },
    arrow: {
        marginRight: 8,
    },
    infoLabel: {
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 8,
        marginTop: 8,
        marginHorizontal: 4,
        borderCurve: 'continuous',
    },
    specialDecreaseLabel: {
        backgroundColor: designSystem.colors.lightBlue,
    },
    specialIncreaseLabel: {
        backgroundColor: designSystem.colors.inflated,
    },
    stickeyHeader: {
        borderColor: designSystem.colors.gray5,
        position: 'absolute',
        borderBottomWidth: SEPARATOR_HEIGHT,
        alignItems: 'center',
        backgroundColor: designSystem.colors.gray6,
        justifyContent: 'center',
        top: 0,
        paddingBottom: 12,
        paddingTop: 12,
        alignSelf: 'center',
    },
    pickSection: {
        maxWidth: 210,
        width: isWeb ? 210 : 'auto',
        justifyContent: 'flex-end',
    },
    lineSeparator: {
        height: 12,
        marginHorizontal: 6,
    },
    fixedFavouriteIconContainer: {
        position: 'absolute',
        top: 10,
        right: 16,
        zIndex: 3,
    },
    fixedFavouriteIcon: {
        zIndex: 3,
    },
    webFavoriteIcon: {
        position: 'absolute',
        right: 16,
    },
});

const modalId = 'playerCard';

export interface MarketOption {
    marketOptionId: string;
    outcome: Outcome;
}

export type PlayerCardProps = {
    player: PlayerWithTeam;
    event: EventInfo;
    testID?: string;
    analyticsTag?: string;
};

export type PlayersCardlRef = {
    show: (data: PlayerCardProps) => void;
};

export const PlayerCard = React.forwardRef<PlayersCardlRef, {}>((_props, ref) => {
    const [data, setData] = useState<PlayerCardProps | undefined>(undefined);
    const modalRef = useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    return <PlayerCardModal data={data} sheetRef={modalRef} />;
});

const PlayerCardModal = ({
    sheetRef,
    data,
}: {
    sheetRef: React.RefObject<BottomSheetModal>;
    data: PlayerCardProps | undefined;
}) => {
    const [scrollableHeaderHeight, setScrollableHeaderHeight] = useState(0);

    const scrollOffset = useSharedValue(0);
    const safeInsets = useSafeAreaInsets();

    const scrollHandler = useAnimatedScrollHandler(event => {
        scrollOffset.value = event.contentOffset.y;
    });

    return (
        <ScrollableModal
            sheetRef={sheetRef}
            contentContainerStyle={[common.paddingHorizontal, { paddingBottom: safeInsets.bottom + 4 }]}
            id={modalId}
            showsVerticalScrollIndicator={false}
            onScroll={scrollHandler}
            onDismiss={() => {
                scrollOffset.value = 0;
            }}
            stickyHeader={
                <Header
                    scrollOffset={scrollOffset}
                    scrollableHeaderHeight={scrollableHeaderHeight}
                    player={data?.player}
                />
            }
        >
            {data ? <Content {...data} setScrollableHeaderHeight={setScrollableHeaderHeight} /> : null}
        </ScrollableModal>
    );
};

type HeaderProps = {
    scrollOffset: SharedValue<number>;
    scrollableHeaderHeight: number;
    player?: PlayerInfoWithProjectionsFragment;
};

const Header = ({ scrollOffset, scrollableHeaderHeight, player }: HeaderProps) => {
    const { dismiss } = useModals();
    const { favoritesIds, addFavorite, removeFavorite } = useFavorites();
    const user = useUser();
    const lobbyComponents = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.lobbyComponents?.items ?? DEFAULT_LOBBY_ORDERING
    );
    const isFavoritesEnabled = findEnabledLobbyComponentByOrderingKey(lobbyComponents, LobbyComponent.FavoritesPlayers);
    const shouldDisplayFavoriteIcon = !user.isGuest() && isFavoritesEnabled;
    const stickyHeaderStyle = useAnimatedStyle(() => {
        return {
            opacity:
                scrollableHeaderHeight > 0
                    ? interpolate(
                          scrollOffset.value,
                          [0, scrollableHeaderHeight - 1, scrollableHeaderHeight],
                          [0, 0, 1]
                      )
                    : 0,
        };
    }, [scrollOffset.value, scrollableHeaderHeight]);

    if (!player?.id) {
        return null;
    }

    const isFavorite = favoritesIds.includes(player?.id);

    const addToFavorites = () => {
        addFavorite(player?.id);
    };

    const removeFromFavorites = () => {
        removeFavorite(player?.id);
    };

    return (
        <>
            {/* Fixed Favourite Icon */}
            {shouldDisplayFavoriteIcon ? (
                <Box style={isWeb ? styles.webFavoriteIcon : styles.fixedFavouriteIconContainer}>
                    <TouchableOpacity
                        onPress={isFavorite ? removeFromFavorites : addToFavorites}
                        style={styles.fixedFavouriteIcon}
                    >
                        {isFavorite ? <FavoriteSelected /> : <FavoriteUnselected />}
                    </TouchableOpacity>
                </Box>
            ) : null}

            {/* Scrollable Header */}
            <Animated.View style={[styles.stickeyHeader, stickyHeaderStyle, common.row, common.zIndex1]}>
                <Box ml={'s16'} zIndex={1}>
                    <TouchableOpacity hitSlop={20} onPress={() => dismiss()}>
                        <CloseIcon />
                    </TouchableOpacity>
                </Box>
                <Box alignItems={'center'} flex={1} left={-20}>
                    <Text variant="titleLarge">{`${player?.firstName} ${player?.lastName}`}</Text>
                </Box>
            </Animated.View>
        </>
    );
};

const Content = ({
    player,
    event,
    analyticsTag,
    setScrollableHeaderHeight,
    testID,
}: PlayerCardProps & { setScrollableHeaderHeight: React.Dispatch<React.SetStateAction<number>> }) => {
    const { id: eventId, league } = event;

    const eventIsLive = gameUtils.isLive(event);

    const liveWebsocketEnabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings?.enable_live_market_websockets ?? true
    );

    const [eventNewData, refetchProjections] = useUpcomingEventsInfoQuery({
        variables: { ids: [eventId] },
        pause: true,
        requestPolicy: 'network-only',
    });

    useUpdatePlayerStoreWithNewData(eventNewData.data?.getUpcomingEventsByIdsV2);

    useEffect(() => {
        // Only set up the interval if live websocket updates is NOT enabled
        // This acts as a fallback if WS are disabled
        if (eventIsLive && !liveWebsocketEnabled) {
            const interval = setInterval(() => {
                refetchProjections();
            }, 7000);
            return () => clearInterval(interval);
        }
    }, [eventIsLive, refetchProjections, liveWebsocketEnabled]);

    const events = useMemo(() => [event], [event]);
    useLiveEventsPropsUpdate({ events });

    //For LIVE events we want to also show the suspended markets
    const projections = usePlayerPropsStore(
        eventIsLive ? allPlayerPropsSelector(player.id) : playerPropsSelector(player.id)
    );
    const sortedProjections = projections.sort(sortProjections);

    const betslip = useBetslipStore(store => store.betslip);

    const { removeSelection, makeSelection } = usePickSelection();
    const playerPickedEntry = isPlayerSelected({ eventId, playerId: player?.id });
    const { hasBoostedProjections, nonRegularProjectionTypes } = getNonRegularProjections(sortedProjections);
    const hasNonRegularProjections = nonRegularProjectionTypes.size > 0;
    const specialProjections = sortedProjections?.filter(projection => projection.type === ProjectionType.Special);
    const { arcText, teamLogo } = getPlayerArcDetails(player, event?.league, player.team);
    const { leagueIcon, leagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(league),
        defaultZustandCompareFunction
    );

    const { dismiss } = useModals();

    const removePlayer = useCallback(() => {
        if (eventId && player) {
            removeSelection([{ eventId, player: player }]);
            dismiss();
        }
    }, [eventId, player, removeSelection, dismiss]);

    const selectMarketOptionAndDismissCard = useCallback(
        (projection: PlayerProjectionFragment, option: MarketOption) => {
            const successfulSelection = makeSelection(
                {
                    player,
                    eventId,
                    outcome: option.outcome,
                    projection,
                },
                analyticsTag
            );
            if (successfulSelection) {
                if (analyticsTag && betslip.length === 1) {
                    BetrAnalytics.trackEvent(AnalyticsEvent.ADD_1ST_PICK, { location: analyticsTag });
                }
                dismiss();
            }
        },
        [makeSelection, player, eventId, analyticsTag, betslip.length, dismiss]
    );

    const playerNumber = getPlayerJerseyNumber(league, player?.jerseyNumber);

    return (
        <Box>
            <Box
                onLayout={layoutEvent =>
                    // the height of the header - bottom padding (20)
                    setScrollableHeaderHeight(layoutEvent.nativeEvent.layout.height - 20)
                }
                alignItems={'center'}
            >
                <SizedBox value={28} />
                <PlayerProfileImage
                    playerImageUrl={player.icon ?? ''}
                    teamImageUrl={teamLogo ?? leagueIcon}
                    imageVariant={'big'}
                    teamColor={player?.team?.color ?? leagueColor}
                    teamSecondaryColor={player?.team?.secondaryColor}
                    bgColor={'gray5'}
                    arcText={arcText}
                    playerNumber={playerNumber}
                    playerId={player.id}
                />
                <SizedBox value={12} />
                <PlayerDescription player={player} event={event} teamName={player.team?.name} showFullName />
                <EventDetails event={event} player={player} testID={testID} />
                {hasNonRegularProjections ? <SizedBox value={6} /> : null}
                {getPlayerInfoTags(hasBoostedProjections, specialProjections)}
                <DebugButtons playerId={player.id} />
                <SizedBox value={hasNonRegularProjections ? 20 : 16} />
            </Box>
            {sortedProjections.map((item, index) => {
                return (
                    <ProjectionRow
                        key={index}
                        index={index}
                        player={player}
                        item={item}
                        playerPickedEntry={playerPickedEntry}
                        onSelectMarketOption={selectMarketOptionAndDismissCard}
                        removePlayer={removePlayer}
                    />
                );
            })}
        </Box>
    );
};

type TagProps = PropsWithChildren<{ color: string; text: string }>;

const ProjectionRow = ({
    index,
    player,
    item,
    playerPickedEntry,
    onSelectMarketOption,
    removePlayer,
}: {
    index: number;
    player: PlayerWithTeam;
    item: PlayerProjection;
    playerPickedEntry?: BetslipPick;
    onSelectMarketOption: (projection: PlayerProjectionFragment, option: MarketOption) => void;
    removePlayer: () => void;
}) => {
    const isDiscountedProjection = checkIfDiscountedProjection(item.type);
    const isRegularProjection = checkIfRegularProjection(item.type);
    const isDiscountedOrRegularProjection = isDiscountedProjection || isRegularProjection;

    const { canMakeAdditionalPick } = useBetslipData();

    const enableInPlayCurrentValue = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.enable_inplay_current_value
    );

    const { showFantasyPointsModal } = useFantasyPoints();
    const isFantasyPtsText = (input: string) => input === 'FANTASY_POINTS';
    const league = player.league;
    const handleFantasyPtsPress = (pickName: string) => {
        if (isFantasyPtsText(pickName) && league) {
            showFantasyPointsModal({ league });
        }
    };

    const getPickedProjection = useCallback(
        (outcome: string, pick: PlayerProjectionFragment) => {
            return (
                playerPickedEntry &&
                playerPickedEntry.projection.name === pick.name &&
                playerPickedEntry.outcome === outcome &&
                playerPickedEntry?.projection.type === pick.type
            );
        },
        [playerPickedEntry]
    );

    const isMoreSelected = getPickedProjection(Outcome.More, item);
    const isLessSelected = getPickedProjection(Outcome.Less, item);
    const selection = isMoreSelected ? Outcome.More : isLessSelected ? Outcome.Less : undefined;
    const hasCurrentValue = item?.currentValue != null;
    const shouldShowCurrentValue = enableInPlayCurrentValue && hasCurrentValue;
    const isSuspended = item.marketStatus === MarketStatus.Suspended;

    return (
        <Box key={`${index}-${item.type}`} marginVertical={'s12'}>
            <Row alignItems={'center'} justifyContent={'space-between'}>
                <Box flex={1}>
                    <Row alignItems={'center'}>
                        {!isRegularProjection ? (
                            <SingleProjectionIcon selectedProjection={item} style={common.selfCenter} />
                        ) : null}
                        {/**
                         * For the regular projection - show the original 'value'
                         * For discounted projection - show the original 'value' with a strikethrough
                         * For other non regular projections - show the 'nonRegularValue'
                         */}
                        <AnimatedNumber
                            alignText={'flex-start'}
                            decimals={1}
                            value={(isDiscountedOrRegularProjection ? item.value : item.nonRegularValue) ?? 0}
                            textDecorationLine={isDiscountedProjection ? 'line-through' : 'none'}
                            color={
                                isSuspended ? 'gray4' : isDiscountedProjection ? 'gray3' : getColorByProjType(item.type)
                            }
                            variant={'titleMedium'}
                        />
                        {isDiscountedProjection ? (
                            <>
                                <SizedBox value={4} />
                                <Text
                                    variant="titleMedium"
                                    color={getColorByProjType(
                                        item?.type,
                                        item.nonRegularPercentage ? item.nonRegularPercentage > 0 : false
                                    )}
                                >
                                    {formatNumber(item.nonRegularValue)}
                                </Text>
                            </>
                        ) : null}
                        {shouldShowCurrentValue ? (
                            <>
                                <LineSeparator vertical style={styles.lineSeparator} />
                                <Text color={'red'} variant={'bodyMedium'}>
                                    {item.currentValue}
                                </Text>
                            </>
                        ) : null}
                    </Row>

                    <Row alignItems={'center'} flex={1}>
                        <Row minWidth={'100%'} flex={1} alignItems={'center'}>
                            <Text color={'gray2'} variant="bodySmall" numberOfLines={2}>
                                {item.label}
                            </Text>
                            {shouldShowCurrentValue ? null : isFantasyPtsText(item.key) ? (
                                <TouchableBox ml={'s4'} onPress={() => handleFantasyPtsPress(item.key)}>
                                    <InfoIcon />
                                </TouchableBox>
                            ) : null}
                        </Row>
                    </Row>
                </Box>
                <SizedBox value={6} />
                <Row>
                    {isSuspended ? (
                        <Row justifyContent={'center'} alignItems={'center'}>
                            <Text color={'gray4'} variant={'bodyMedium'} lineHeight={20} mr={'s12'}>
                                Suspended
                            </Text>
                            <LockIcon color={designSystem.colors.gray4} />
                        </Row>
                    ) : (
                        <PickButtonsSection
                            selection={selection}
                            onSelection={outcome => {
                                const marketOption = item.allowedOptions?.find(it => it.outcome === outcome);
                                if (!marketOption) {
                                    logger.warn('No market option found for outcome', outcome);
                                    return;
                                }
                                const marketId = item.marketId;
                                if (!marketId) {
                                    logger.warn('No market id found for projection', item.label);
                                    return;
                                }
                                onSelectMarketOption(item, marketOption);
                            }}
                            onRemove={removePlayer}
                            allowedOutcomes={item.allowedOptions || []}
                            projectionType={item.type}
                            style={styles.pickSection}
                            disabled={!playerPickedEntry && !canMakeAdditionalPick}
                            insideModal
                            testID={`${index}-${item.key}`}
                            nonRegularPercentage={item.nonRegularPercentage}
                        />
                    )}
                </Row>
            </Row>
        </Box>
    );
};

const Tag = ({ children, text, color }: TagProps) => {
    const backgroundColor = withOpacity(color, 0.1);
    return (
        <Row justifyContent={'center'} style={[styles.infoLabel, { backgroundColor }]}>
            <Box style={common.justifyCenter}>{children}</Box>
            <SizedBox value={4} />
            <Text style={{ color }} variant={'titleSmall'}>
                {text}
            </Text>
        </Row>
    );
};

const getPlayerInfoTags = (hasBoostedPicks: boolean, specialProjections: PlayerProjectionFragment[]) => (
    <Row marginHorizontal={'s56'} flexWrap={'wrap'} justifyContent={'center'}>
        {specialProjections?.map((item: PlayerProjectionFragment) => {
            if (item.nonRegularPercentage) {
                const increased = item.nonRegularPercentage > 0;
                const color = increased ? designSystem.colors.surge : designSystem.colors.special;
                return (
                    <Tag
                        color={color}
                        text={`${Math.abs(item.nonRegularPercentage)}% ${increased ? 'increase' : 'off'}`}
                        key={`${item.name}-${item.order}`}
                    >
                        {increased ? <InflatedIcon color={color} /> : <DiscountIcon color={color} />}
                    </Tag>
                );
            }
        })}
        {hasBoostedPicks ? (
            <Tag color={designSystem.colors.boosted} text={'Multiplier booster'}>
                <RocketIcon color={designSystem.colors.boosted} />
            </Tag>
        ) : null}
    </Row>
);

const DebugButtons = ({ playerId }: { playerId: string }) => {
    const { localFlags } = useLocalFlagsStore();
    const [debugSimulateLiveMarkets, setDebugSimulateLiveMarkets] = useState(false);

    useDebugSimulateLiveMarketsUpdates(debugSimulateLiveMarkets, playerId);

    return localFlags?.playerCardDebugButtons?.enabled ? (
        <Column gap={'s2'}>
            <Text>{playerId}</Text>
            <Button
                label={'Add current values'}
                onPress={() => {
                    usePlayerPropsStore.getState().actions._debugEnableCurrentValues(playerId);
                }}
            />
            <Button
                label={'Suspend all markets'}
                onPress={() => {
                    usePlayerPropsStore.getState().actions._debugSuspendPlayerMarkets(playerId, 'all');
                }}
            />
            <Button
                label={'Simulate websockets market'}
                onPress={() => {
                    setDebugSimulateLiveMarkets(true);
                }}
            />
        </Column>
    ) : null;
};
