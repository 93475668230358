import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetCard } from '@/components/BetCard';
import {
    getSettledTextColor,
    useSharedBetSummaryProps,
    useVoidedBetSummary,
} from '@/feature/bets-sbk/components/bet-cards/utils';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';
import { useOptionName } from '@/feature/event-details-sbk/hooks/use-option-name';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { Bet } from '../../hooks/types';
import {
    useFooter,
    useGameStatus,
    useMarketStatus,
    useOpenBadge,
    voidedBetCardProps,
} from '../../utils/bet-card-utils';

export type CommonBetCardProps = {
    bet: Bet;
    title: string;
    subTitle: string;
    footer: string;
};

/**
 * Helper Hooks
 */
const useTitle = (bet: Bet) => {
    const selection = bet.events[0].selections[0];
    const sportName = bet.events[0].sport.name;
    return useOptionName({
        optionType: selection.option.option_type,
        marketType: selection.market.market_type,
        fallback: selection.option.description,
        homeTeam: bet.events[0].home_team,
        awayTeam: bet.events[0].away_team,
        sportName,
        isDisplayFullName: true,
    });
};

const useSubTitle = (bet: Bet) => {
    const event = bet.events[0];
    const market = event.selections[0].market;
    const sportName = event.sport.name;
    const marketName = useMarketName({
        sportName,
        event,
        fallback: market.description,
        marketType: market.market_type,
        player: market.player,
        appendPlayerName: true,
        appendMicroMarketDescription: market.is_micro_market,
        isExtendedName: true,
    });
    const marketStatus = useMarketStatus(event);
    const gameStatus = useGameStatus(bet);

    return [marketName, marketStatus, gameStatus].join(' • ');
};

const useOpenBetSummary = (bet: Bet) => {
    return {
        ...useSharedBetSummaryProps(bet),
        body3Text: toLocaleCurrency(bet.potential_payout),
    };
};

export const useSettledSingleBetSummary = (bet: Bet) => {
    const { t } = useTranslation('bets');
    return {
        ...useSharedBetSummaryProps(bet),
        ...getSettledTextColor(bet),
        header3Text: t('paid'),
        body3Text: toLocaleCurrency(bet.amount_won),
    };
};

/**
 * Components
 */
const OpenSingleBetCard = ({ bet, ...commonBetDetails }: CommonBetCardProps) => {
    const status = useOpenBadge(bet);
    return <BetCard status={status} summary={useOpenBetSummary(bet)} {...commonBetDetails} />;
};

const SettledSinglesBetCard = ({
    bet,
    status,
    ...commonBetDetails
}: CommonBetCardProps & {
    status: 'won' | 'lost';
}) => {
    return <BetCard status={status} summary={useSettledSingleBetSummary(bet)} {...commonBetDetails} />;
};

const VoidedSinglesBetCard = ({ bet, ...commonBetDetails }: CommonBetCardProps) => {
    return <BetCard status="voided" summary={useVoidedBetSummary(bet)} {...commonBetDetails} {...voidedBetCardProps} />;
};

export const SingleSbkBetCard = ({ bet }: { bet: Bet }) => {
    const title = useTitle(bet);
    const subTitle = useSubTitle(bet);
    const footer = useFooter(bet);
    const commonBetDetails = { title, subTitle, footer };

    switch (bet.result) {
        case 'PENDING':
            return <OpenSingleBetCard bet={bet} {...commonBetDetails} />;
        case 'WON':
        case 'CASHEDOUT':
            return <SettledSinglesBetCard bet={bet} status={'won'} {...commonBetDetails} />;
        case 'LOST':
            return <SettledSinglesBetCard bet={bet} status={'lost'} {...commonBetDetails} />;
        case 'CANCELLED':
        case 'PUSHED':
            return <VoidedSinglesBetCard bet={bet} {...commonBetDetails} />;
    }
};
