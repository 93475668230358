import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';
import {
    LineupUpdateModal,
    LineupUpdateModalRef,
    LineupUpdateProps,
} from '@/feature/betslip-pickem/components/LineupUpdateModal';

type LineupUpdate = {
    showLineupUpdateModal: (props: LineupUpdateProps) => void;
};

const LineupUpdateContext = React.createContext<LineupUpdate>({
    showLineupUpdateModal: () => {},
});

/**
 * Provides access to the Modal used to display the list of unavailable players + players with projection changes from betslip
 */
export const LineupUpdateProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<LineupUpdateModalRef>(null);

    const showLineupUpdateModal = useCallback((props: LineupUpdateProps) => {
        if (
            [
                props?.invalidPicksByEvent,
                props.picksWithChangedProjections,
                props.picksWithMissingEvents,
                props.playersRemoved,
            ].some(it => (it?.length ?? 0) > 0)
        ) {
            modalRef.current?.show(props);
        }
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <LineupUpdateContext.Provider value={{ showLineupUpdateModal }}>
                {children}
                <LineupUpdateModal ref={modalRef} />
            </LineupUpdateContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useLineupUpdate = () => {
    return useContext(LineupUpdateContext);
};
