import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import StrikedThroughDotLarge from '@/assets/icons/striked-through-dot-large';
import { common, designSystem } from '@/styles/styles';

import { SizedBox } from './SizedBox';
import { Text } from './TextComponent';

const styles = StyleSheet.create({
    root: {
        backgroundColor: designSystem.colors.gray6,
        borderRadius: 8,
        padding: 16,
    },
});

export const VoidedEntryWarning = () => {
    const { t } = useTranslation('betslip_pickem');

    return (
        <View style={[common.row, styles.root]}>
            <View style={common.justifyCenter}>
                <StrikedThroughDotLarge fillColor={designSystem.colors.white} />
            </View>
            <SizedBox value={16} />
            <View style={common.flex}>
                <Text variant="bodySmall">{t('voided_entry_warning')}</Text>
            </View>
        </View>
    );
};
