import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { KycStepContainer } from '../../components/KycStepContainer';
import { useDobInput } from '../../hooks/use-dob-input';
import { DobInput } from './DobInput';

export const DobStep = () => {
    const { t } = useTranslation('kyc');
    const { showDobPicker, dob, errorMessage, isNextDisabled, setShowDobPicker, setFieldValue, handlePickerChange } =
        useDobInput();

    useEffect(() => {
        // Show the picker when the component mounts
        setShowDobPicker(true);
    }, [setShowDobPicker]);

    return (
        <KycStepContainer
            title={t('dob_header')}
            subText={t('dob_copy')}
            isNextDisabled={isNextDisabled}
            pickedValue={dob}
            showDobPicker={showDobPicker}
            setShowDobPicker={setShowDobPicker}
            handleDatePickerChange={handlePickerChange}
        >
            <DobInput
                value={dob}
                autoFocus={showDobPicker}
                errorMessage={errorMessage}
                setFieldValue={value => setFieldValue('dateOfBirth', value)}
                setShowDobPicker={setShowDobPicker}
            />
        </KycStepContainer>
    );
};
