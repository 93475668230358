import { useMemo } from 'react';

import { SharedEntryInfoFragment } from '@/feature/entry-share/api/query.generated';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

/**
 *
 * @param {SharedEntryInfoFragment[]} entries - the list of featured entries.
 * @returns {SharedEntryInfoFragment[]} - returns the list of the most recent lineups. One lineup per user.
 */
export const useLatestLineups = (entries: SharedEntryInfoFragment[] | undefined) => {
    const maxPicksInFeaturedLineupsSettings = useJurisdictionStore(
        store =>
            store.jurisdictionSettings?.productConfig?.keyValuePairs?.['Featured Lineups']?.max_picks_in_featured_lineup
    );

    const maxPicksInFeaturedLineups = maxPicksInFeaturedLineupsSettings ? Number(maxPicksInFeaturedLineupsSettings) : 4;

    return useMemo(() => {
        if (!entries) {
            return [];
        }

        const lineupMap = entries.reduce((map, lineup) => {
            const { entry } = lineup;
            const existingLineup = map.get(entry.userId);

            if (!existingLineup || new Date(existingLineup.entry.createdDate) < new Date(entry.createdDate)) {
                map.set(entry.userId, lineup);
            }

            return map;
        }, new Map<string, SharedEntryInfoFragment>());

        return Array.from(lineupMap.values()).filter(it => it.entry.picks.length <= maxPicksInFeaturedLineups);
    }, [entries, maxPicksInFeaturedLineups]);
};
