import React from 'react';

import { Box } from './Box';

export type RowProps = React.ComponentProps<typeof Box>;

export const Row = ({ children, ...rest }: Omit<RowProps, 'flexDirection'>) => {
    return (
        <Box {...rest} flexDirection="row">
            {children}
        </Box>
    );
};
