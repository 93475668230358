import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionTray } from '@/components/ActionTray';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { OneLineSummary } from '@/feature/betslip-sbk/components/OneLineSummary';
import { MultiplierChangedInfoSheetFooter } from '@/feature/betslip-sbk/components/bet-info-sheet/MultiplierChangedInfoSheet/MultiplerChangedInfoSheetFooter';
import { MultiplierChangedInfoSheetBody } from '@/feature/betslip-sbk/components/bet-info-sheet/MultiplierChangedInfoSheet/MultiplierChangedInfoSheetBody';
import {
    useIsBetSlipSubmitting,
    useIsBetSlipValid,
    useShowOddsChangeButton,
    useTotalBetCount,
    useTotalStake,
} from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useHandleBetSubmissionErrors } from '@/feature/betslip-sbk/hooks/use-handle-bet-submission-errors';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { useAuthUserConfig, userIdSelector } from '@/hooks/use-auth-user-config';
import { logger } from '@/utils/logging';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { useWebsocket } from '@/utils/websocket/provider';

import { SBK_BETSLIP_LOG_TAG } from '../hooks/use-sbk-betslip-store';

export const BetSlipActionTray = () => {
    const { t } = useTranslation(['betslip_sbk', 'common']);
    const { websocketService } = useWebsocket();
    const isBetSlipValid = useIsBetSlipValid();
    const totalStake = useTotalStake();
    const totalBetCount = useTotalBetCount();
    const { placeBets } = useSbkBetSlipStore(state => state.actions);
    const isBetSlipSubmitting = useIsBetSlipSubmitting();
    const { data: userId } = useAuthUserConfig({
        select: userIdSelector,
    });
    const handleBetSubmissionErrors = useHandleBetSubmissionErrors();
    const { showInfoSheet, dismissInfoSheet } = useAlerts();

    const handleOnPress = () => {
        if (isBetSlipSubmitting) {
            return;
        }

        GASbkEvents.clickPlaceBet();

        if (userId) {
            if (websocketService?.isAblyDown()) {
                logger.warn(SBK_BETSLIP_LOG_TAG, 'Bet submission fail', 'Ably disconnected');
                showInfoSheet({
                    title: t('bet_rejected'),
                    description: t('bet_rejected_description'),
                    buttonLabel: 'Close',
                });
            } else {
                placeBets(userId, handleBetSubmissionErrors);
            }
        }
    };

    const acceptAllOddsChanges = useSbkBetSlipStore(state => state.actions.acceptAllOddsChanges);
    const showOddsChangeButton = useShowOddsChangeButton();

    const showMultiplierChangesInfoSheet = () => {
        GASbkEvents.reviewMultiplierChanges('review_changes_first');
        showInfoSheet({
            description: <MultiplierChangedInfoSheetBody dismissInfoSheet={dismissInfoSheet} />,
            footerContent: <MultiplierChangedInfoSheetFooter />,
            buttonLabel: t('accept_multiplier_changes'),
            secondaryLabel: t('common:close'),
            handlePress: () => {
                GASbkEvents.reviewMultiplierChanges('accept_multipliers');
                acceptAllOddsChanges();
            },
        });
    };

    const getButtonProps = () => {
        switch (true) {
            case isBetSlipSubmitting:
                return {
                    disabled: false,
                    label: t('place_bet'),
                    onPress: handleOnPress,
                    loading: true,
                    testID: 'placeBetButton',
                };
            case showOddsChangeButton:
                return {
                    disabled: false,
                    label: t('review_multiplier_changes'),
                    onPress: showMultiplierChangesInfoSheet,
                    loading: false,
                    testID: 'showOddsChangeButton',
                };
            case totalStake === 0:
                return {
                    disabled: !isBetSlipValid,
                    label: t('place_bet'),
                    onPress: handleOnPress,
                    loading: isBetSlipSubmitting,
                    testID: 'placeBetButton',
                };
            default:
                return {
                    disabled: !isBetSlipValid,
                    label: t('place_bet_with_value', {
                        count: totalBetCount,
                        value: toLocaleCurrency(totalStake, false),
                    }),
                    onPress: handleOnPress,
                    loading: isBetSlipSubmitting,
                    testID: 'placeBetButton',
                };
        }
    };

    const { onPress, label, loading, disabled, testID } = getButtonProps();

    return (
        <ActionTray
            buttonLabel={label}
            handlePress={onPress}
            testID={testID}
            disabled={disabled}
            loading={loading}
            secondaryButton={<OneLineSummary />}
        />
    );
};
