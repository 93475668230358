import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { SizedBox } from '@/components/SizedBox';
import { TextInput } from '@/components/TextInput';
import { textInputKeyboardType } from '@/feature/kyc/utils/keyboardInput';
import { useFormFields } from '@/hooks/use-form-fields';

import { KycStepContainer } from '../../components/KycStepContainer';
import { kycFlowTypes } from '../../const';
import { useKycFieldsStore } from '../../hooks/use-kyc-fields';
import { isBtnDisabled } from './FirstLastNameStep';

export const FirstLastNameReview = () => {
    const values = useKycFieldsStore(state => state.values);
    const setFieldValue = useKycFieldsStore(state => state.setFieldValue);

    const { t } = useTranslation('kyc');
    const navigation = useNavigation();

    const [firstName, setFirstName] = useState(values.firstName);
    const [lastName, setLastName] = useState(values.lastName);
    const { saveField, focusField } = useFormFields<'last_name'>();

    const handlePress = () => {
        setFieldValue('firstName', firstName);
        setFieldValue('lastName', lastName);
        navigation.goBack();
    };

    return (
        <KycStepContainer
            kycFlowType={kycFlowTypes.RETRY}
            title={t('name_retry_header')}
            subText={t('name_copy')}
            isNextDisabled={isBtnDisabled(firstName, lastName)}
            onPressButton={handlePress}
            hasCloseIcon
            buttonText="common:save"
        >
            <TextInput
                label={t('first_name')}
                value={firstName}
                onChangeText={(masked: string, unmasked: string) => {
                    setFirstName(unmasked);
                }}
                returnKeyType="next"
                onSubmitEditing={focusField('last_name')}
                keyboardType={textInputKeyboardType}
            />
            <SizedBox value={20} />
            <TextInput
                label={t('last_name')}
                value={lastName}
                onChangeText={(masked: string, unmasked: string) => {
                    setLastName(unmasked);
                }}
                returnKeyType="done"
                ref={saveField('last_name')}
                keyboardType={textInputKeyboardType}
            />
        </KycStepContainer>
    );
};
