import React, { ReactElement, ReactNode, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import ErrorIcon from '@/assets/icons/error';
import { Button } from '@/components/ButtonComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { PaymentMethodType } from '@/feature/deposit/hooks/use-deposit-payment-methods';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { RootStackParamList } from '@/navigation/types';

import { MaxWidthWrapper } from '../feature/responsive-design/WebComponents';
import { ContactSupport } from './ContactSupport';

export const WALLET_ERROR_GENERIC_FALLBACK_KEY = 'wallet.generic_fallback';

export type PaymentErrorModalContentProps = {
    transactionType: 'deposit' | 'withdrawal';
    onClose: () => void;
    primaryButton: string | ReactElement;
    selectedAmount?: string;
    title?: string;
    errorCode?: string;
    description?: string | ReactNode;
    handlePrimaryButtonPress?: () => void;
    showSecondaryButton?: boolean;
    secondaryButton?: string | ReactElement;
    handleSecondaryButtonPress?: () => void;
    footer?: ReactNode;
    showContactSupport?: boolean;
    handleSupportEmailPress?: () => void;
    invokingScreen?: keyof RootStackParamList;
    paymentMethod?: PaymentMethodType;
};
type WalletErrorKeys = 'wallet.generic_fallback' | 'wallet.user_not_verified' | 'wallet.invalid_transaction_type';

/**
 * This component showcases an error modal designed for payment-related matters. While it bears a striking * resemblance to the current 'ErrorModal' component, it diverges in several aspects:
 * It relies on the 'errorCode' prop to serve as the translation key for the error description text.
 * If the 'errorCode' is not found from the `translation.json`, it defaults to a generic fallback text.
 * Contrary to being a modal, this component functions as a content component (it does not contain `Screen` component), suitable for integration within a modal.
 *
 * @param transactionType - The type of transaction. Either 'deposit' or 'withdrawal'.
 * @param onClose - The function to call when the close button on the top left is clicked.
 * @param title - The main text of the modal.
 * @param description - The description of the error. Comes at the bottom of the title.
 * @param errorCode - The error code used for translation. Usually, returned from APIs.
 * @param primaryButton - The label for the primary button.
 * @param handlePrimaryButtonPress - The function to call when the primary button is clicked.
 * @param showSecondaryButton - Whether to show the secondary button.
 * @param secondaryButton - The label for the secondary button.
 * @param handleSecondaryButtonPress - The function to call when the secondary button is clicked.
 * @param footer - The footer content at the bottom of the CTAs.
 * @param showContactSupport - Whether to show the contact support link. -> `ContactSupport` component
 * @param invokingScreen - The Screen name that invokes this modal.
 * @param paymentMethod - The payment method type used in the transaction. eg. paysafe, paysafe_mazooma, etc
 */
export const PaymentErrorModalContent = ({
    transactionType,
    onClose,
    selectedAmount,
    title,
    description,
    errorCode,
    primaryButton,
    handlePrimaryButtonPress,
    showSecondaryButton = true,
    secondaryButton,
    handleSecondaryButtonPress,
    footer,
    showContactSupport = false,
    handleSupportEmailPress,
    invokingScreen,
    paymentMethod,
}: PaymentErrorModalContentProps) => {
    const { t } = useTranslation(['error', 'wallet', 'common']);
    const navigation = useNavigation();
    const { activeWalletProductName } = useActiveWallet();

    const translationKey: WalletErrorKeys = `wallet.${errorCode}` as WalletErrorKeys; // Cast dynamically generated key to the type

    const modalTitle = title
        ? title
        : t('wallet:failed_payment_modal_title', {
              activeWallet: activeWalletProductName,
              transactionType,
          });

    const handlePress = (callback: (() => void) | undefined) => {
        if (callback) {
            callback();
        } else {
            navigation.goBack();
        }
    };

    const renderDescription = () => {
        if (isValidElement(description)) {
            return description;
        }

        return (
            <Text textAlign="center" color="gray2" variant="bodyMedium" mt="s8">
                {errorCode
                    ? t([translationKey, WALLET_ERROR_GENERIC_FALLBACK_KEY], { selectedAmount })
                    : description ?? t(WALLET_ERROR_GENERIC_FALLBACK_KEY)}
            </Text>
        );
    };

    const renderSecondaryButton = () => {
        if (!showSecondaryButton) {
            return null;
        }

        if (isValidElement(secondaryButton)) {
            return <Box mt="s8">{secondaryButton}</Box>;
        }

        return (
            <Box mt="s8">
                <Button
                    label={
                        <Text textAlign="center" variant="titleMedium">
                            {secondaryButton ?? t('common:dismiss')}
                        </Text>
                    }
                    type="text"
                    onPress={() => handlePress(handleSecondaryButtonPress)}
                />
            </Box>
        );
    };

    return (
        <>
            <ScreenNavBar closeIconMode="close" onClose={onClose} />
            <MaxWidthWrapper flex={1}>
                <Box flex={1} justifyContent="space-between" paddingHorizontal="s16">
                    {/* Main Content */}
                    <Box flex={1} justifyContent="center" alignItems="center">
                        <ErrorIcon />
                        <Text textAlign="center" variant="headlineMedium" mt="s16">
                            {modalTitle}
                        </Text>
                        {renderDescription()}
                    </Box>
                    <Button
                        label={primaryButton}
                        variant="rounded"
                        type="active"
                        onPress={() => handlePress(handlePrimaryButtonPress)}
                    />
                    {renderSecondaryButton()}

                    {footer ? (
                        <Box mb="s16" mt="s8" alignItems="center">
                            {footer}
                        </Box>
                    ) : null}
                    {showContactSupport ? (
                        <Box mb="s20">
                            <ContactSupport
                                onSupportPress={handleSupportEmailPress}
                                transactionType={transactionType}
                                paymentAmount={selectedAmount}
                                paymentMethod={paymentMethod}
                                invokingScreen={invokingScreen}
                            />
                        </Box>
                    ) : null}
                </Box>
            </MaxWidthWrapper>
        </>
    );
};
