import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { usePicker } from '@/hooks/use-picker';

import { AddressForm } from '../../components/HomeAddress/AddressForm';
import { KycStepContainer } from '../../components/KycStepContainer';
import { STATES, kycFlowTypes } from '../../const';
import { AddressFormValues, useKycFieldsStore } from '../../hooks/use-kyc-fields';
import { isBtnDisabled } from './HomeAddressStep';

export const HomeAddressReview = () => {
    const values = useKycFieldsStore(state => state.values);
    const setFieldValue = useKycFieldsStore(state => state.setFieldValue);
    const setAddressFormValues = useKycFieldsStore(state => state.setAddressFormValues);

    const { t } = useTranslation('kyc');
    const navigation = useNavigation();

    const { showPicker, setShowPicker } = usePicker(values, setFieldValue, 'americanState', STATES);

    const [homeAddress, setHomeAddress] = useState<AddressFormValues>({
        address: values.address,
        city: values.city,
        americanState: values.americanState,
        zip: values.zip,
    });

    const setHomeAddressValue = (key: keyof AddressFormValues, value: string) => {
        setHomeAddress(prevState => ({ ...prevState, [key]: value }));
    };

    const handlePress = () => {
        setAddressFormValues({ ...homeAddress });
        navigation.goBack();
    };

    const isNextDisabled = useMemo(() => isBtnDisabled(homeAddress), [homeAddress]);

    const handlePickerChange = (pickedValue: string) => {
        setHomeAddressValue('americanState', pickedValue);
    };

    return (
        <KycStepContainer
            kycFlowType={kycFlowTypes.RETRY}
            title={t('address_retry_header')}
            subText={t('address_copy')}
            isNextDisabled={isNextDisabled}
            hasCloseIcon
            buttonText="common:save"
            onPressButton={handlePress}
            showPicker={showPicker}
            setShowPicker={setShowPicker}
            pickedValue={homeAddress.americanState}
            handlePickerChange={handlePickerChange}
        >
            <AddressForm
                values={homeAddress}
                setFieldValue={setHomeAddressValue}
                setAddressFormValues={setHomeAddress}
                setShowPicker={setShowPicker}
            />
        </KycStepContainer>
    );
};
