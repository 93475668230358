import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutAnimation, StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components/Row';
import { designSystem } from '@/styles/styles';
import { GameMode, GameType } from '@/types/api.generated';

import { useBetslipStore } from '../hooks/use-betslip-store';

const styles = StyleSheet.create({
    cardButtons: {
        paddingVertical: 10,
        borderRadius: 24,
        borderColor: designSystem.colors.gray5,
        flex: 1,
        alignItems: 'center',
    },
    edit: {
        marginRight: 12,
    },
});

// renders the buttons for the gameplay card (edit / remove)
export const GameplayCardButtons = ({ gameMode, gameType }: { gameMode: GameMode; gameType: GameType }) => {
    const navigation = useNavigation();
    const setEntryAmount = useBetslipStore(state => state.actions.setEntryAmount);

    const { t } = useTranslation(['common']);

    const handleRemovePress = useCallback(() => {
        LayoutAnimation.easeInEaseOut();
        setEntryAmount(undefined, gameMode);
    }, [setEntryAmount, gameMode]);

    const handleEditPress = () => {
        navigation.navigate('EntryInputAmount', { gameMode, gameType });
    };

    return (
        <Row flex={1}>
            <Button
                activeOpacity={0.8}
                style={[styles.cardButtons, styles.edit]}
                onPress={handleEditPress}
                analyticsTag={`Edit - ${gameMode} game card`}
                label={
                    <Text variant={'titleSmall'} color={'gray2'}>
                        {t('edit')}
                    </Text>
                }
                variant="rounded"
                type="tertiary"
            />
            <Button
                activeOpacity={0.8}
                style={styles.cardButtons}
                onPress={handleRemovePress}
                analyticsTag={`Remove - ${gameMode} game card`}
                label={
                    <Text variant={'titleSmall'} color={'gray2'}>
                        {t('remove')}
                    </Text>
                }
                variant="rounded"
                type="tertiary"
            />
        </Row>
    );
};
