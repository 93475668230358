import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NativeModules } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { ContactSupport } from '@/components/ContactSupport';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { LOG_TAG, TrustlyKycResponse, useTrustlyKyc } from '@/feature/kyc/hooks/use-trustly-kyc';
import { TrustlyStatus } from '@/feature/kyc/types';
import { useActiveProductName } from '@/hooks/use-active-product-name';
import { failedKycCountSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useUser } from '@/hooks/use-user';
import { navigateBasedOnKYCCount, navigateHome, useCoreNavigation } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { read, save } from '@/utils/async-storage';
import { logger } from '@/utils/logging';

import { PaymentMethodTypes } from '../const';
import { debitPaymentSelector, useDepositPaymentMethods } from '../hooks/use-deposit-payment-methods';
import { PayPalStatus } from '../utils';

const { PaymentsWebView } = NativeModules;

type DepositStatusProps = NativeStackScreenProps<RootStackParamList, 'DepositStatusModal'>;

export const DepositStatus = ({ route }: DepositStatusProps) => {
    const { selectedAmount, paymentProvider, currentStatus } = route.params;
    const navigation = useNavigation();
    const productName = useActiveProductName();
    const selectedProduct = useJurisdictionStore.getState().product;
    const root = selectedProduct === Product.Sportsbook ? 'SBKHome' : 'PickemHome';
    const { t } = useTranslation(['kyc', 'common', 'wallet']);
    const isTrustlyProcessingRef = useRef(false);
    const { logout } = useUser();
    const { openLaunch } = useCoreNavigation();

    const { data: debitMethod } = useDepositPaymentMethods({
        select: debitPaymentSelector,
    });
    const navigateToDebitDeposit = useCallback(() => {
        if (debitMethod) {
            navigation.navigate('AddDebitCardWebView', {
                selectedAmount,
                paysafeAccountId: debitMethod?.accountId,
                closeIconHandlePress: () => navigateHome(navigation, 'lobby'),
                canUse3ds: debitMethod?.type === PaymentMethodTypes.Paysafe3ds,
            });
        } else {
            logger.error(LOG_TAG, 'Failed getting paysafe account ID for Trustly KYC debit card deposit');
        }
    }, [debitMethod, navigation, selectedAmount]);

    const { data: retryCount = 0 } = useAuthUserConfig({
        select: failedKycCountSelector,
        refetchOnMount: 'always',
    });

    const handleNavigateToIdcomply = useCallback(() => {
        navigateBasedOnKYCCount(navigation, retryCount);
    }, [navigation, retryCount]);

    const handleTrustlyVerificationError = useCallback(async () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.KYC_TRUSTLY_FAILED);
        await save('hasProcessedTrustlyKyc', true);
        navigation.navigate('ErrorModal', {
            title: t('trustly_verification_failed_title'),
            subtitle: t('trustly_verification_failed_description'),
            primaryButton: t('manually_verify'),
            handlePress: () => {
                logout();
                openLaunch();
            },
            gestureEnabled: false,
            showNavbarTitle: false,
            showSecondaryButton: false,
            footer: <ContactSupport />,
            closeIconHandlePress: handleNavigateToIdcomply,
        });
    }, [navigation, t, handleNavigateToIdcomply, logout, openLaunch]);

    const handleTrustlyDepositError = useCallback(async () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.KYC_TRUSTLY_FAILED);
        await save('hasProcessedTrustlyKyc', true);
        navigation.navigate('SuccessModal', {
            title: t('trustly_deposit_failed_title'),
            subtitle: t('trustly_deposit_failed_description'),
            primaryButton: t('wallet:deposit_amount_with_debit', { amount: selectedAmount }),
            secondaryButton: t('common:go_to_product_lobby', { product: productName }),
            handlePress: () => navigateToDebitDeposit(),
            handleSecondaryPress: () => navigateHome(navigation, 'lobby'),
            closeIconHandlePress: () => {
                logout();
                openLaunch();
            },
        });
    }, [navigation, t, navigateToDebitDeposit, selectedAmount, productName, logout, openLaunch]);

    const handleTrustlyVerificationAndDepositSuccess = useCallback(async () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.KYC_TRUSTLY_SUCCESS);
        await save('hasProcessedTrustlyKyc', true);
        navigation.navigate('SuccessfulDepositModal', {
            paymentProvider,
            selectedAmount,
            title: t('trustly_success_title'),
            description: t('trustly_success_description', {
                amount: selectedAmount,
                product: productName,
            }),
        });
    }, [navigation, t, paymentProvider, selectedAmount, productName]);

    const { mutate } = useTrustlyKyc();
    const handleTrustlyKyc = useCallback(async () => {
        const transactionId = (await read('trustlyTransactionId')) ?? '';
        const hasTrustlyKycBeenProcessed = await read('hasProcessedTrustlyKyc');
        if (hasTrustlyKycBeenProcessed) {
            navigateHome(navigation, 'lobby');
            return;
        }
        mutate(
            {
                transactionId,
                amount: selectedAmount,
            },
            {
                onSuccess: (responseData: TrustlyKycResponse) => {
                    if (!responseData.verification_success) {
                        return handleTrustlyVerificationError();
                    }
                    if (responseData.deposit_status !== 'success') {
                        return handleTrustlyDepositError();
                    }
                    if (responseData.verification_success && responseData.deposit_status === 'success') {
                        return handleTrustlyVerificationAndDepositSuccess();
                    }
                },
                onError: () => {
                    handleTrustlyVerificationError();
                },
            }
        );
    }, [
        mutate,
        selectedAmount,
        handleTrustlyVerificationError,
        handleTrustlyDepositError,
        handleTrustlyVerificationAndDepositSuccess,
        navigation,
    ]);

    useEffect(() => {
        const processDepositLink = async () => {
            try {
                PaymentsWebView?.closeWebView();
            } catch (err) {
                console.warn('Unable to close webview: ', err);
            }
            if (currentStatus === PayPalStatus.SUCCESS) {
                navigation.navigate('SuccessfulDepositModal', {
                    paymentProvider,
                    selectedAmount,
                });
            } else if (currentStatus === PayPalStatus.ERROR) {
                navigation.navigate('FailedDepositModal', {
                    paymentProvider,
                    selectedAmount,
                });
            } else if (currentStatus?.includes(TrustlyStatus.TRANSACTION)) {
                if (!isTrustlyProcessingRef.current) {
                    isTrustlyProcessingRef.current = true;
                    await handleTrustlyKyc();
                }
            } else if (currentStatus?.includes(TrustlyStatus.CANCEL)) {
                navigation.navigate('KycSelector');
            } else {
                isTrustlyProcessingRef.current = false;
                navigation.reset({
                    routes: [
                        {
                            name: root,
                            params: { screen: 'AccountStack' },
                        },
                        {
                            name: 'DepositScreen',
                            params: {
                                selectedAmount,
                            },
                        },
                    ],
                });
            }
        };
        processDepositLink();
    }, [currentStatus, navigation, paymentProvider, selectedAmount, root, handleTrustlyKyc]);

    return (
        <Box bg="gray8" flex={1} justifyContent="center">
            <LoadingSpinner />
        </Box>
    );
};
