import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

import TriangleLeft from '@/assets/icons/triangleLeft';
import { Odds } from '@/components/Odds';
import { SizedBox } from '@/components/SizedBox';
import { TeamScore } from '@/components/TeamScore';
import { Box, Column, Row } from '@/components/lib/components';
import { Event } from '@/feature/event-details-sbk/types';
import { useEventStatus, useEventWinningSide, useFootballPossession } from '@/hooks/use-sbk-event-details-cache';
import { getLogoColors } from '@/utils/team-logo-colors';

import { EventCardHeader } from './EventCardHeader';
import { TeamRow } from './TeamRow';

export type EventCardProps = {
    event: Event;
    navigateToEvent: (event: Event) => void;
    testID?: string;
};

const ROW_HEIGHT = 36;
const CARD_WIDTH = 162;

export const EventCard = ({ event, navigateToEvent, testID }: EventCardProps) => {
    const eventStatus = useEventStatus(event.id) ?? event.status;
    const { homeBackgroundColor, homeTextColor, awayBackgroundColor, awayTextColor, shouldShowFallbackLogo } =
        getLogoColors(event);

    const possession = useFootballPossession(event);
    const winningSide = useEventWinningSide(event);

    const isLive = eventStatus === 'LIVE';
    const isFinished = eventStatus === 'FINISHED';
    const showOdds = !isFinished && !isLive;
    const homeWin = winningSide === 'home';
    const awayWin = winningSide === 'away';

    return (
        <Box width={CARD_WIDTH} testID={`eventCard-${testID}-${isLive}`}>
            <EventCardHeader event={event} isLive={isLive} />
            <TouchableOpacity onPress={() => navigateToEvent?.(event)} accessible={false}>
                <Row height={ROW_HEIGHT * 2} alignItems="center" borderRadius={'r12'} backgroundColor="gray6" gap="s8">
                    <Column flexShrink={1} rowGap={'s4'} flex={1}>
                        <TeamRow
                            team={{
                                short_code: event.away_team.short_code,
                                logo_url: event.away_team.logo_url,
                                side: 'away',
                                shouldShowFallbackLogo: shouldShowFallbackLogo,
                            }}
                            primaryColor={awayBackgroundColor}
                            secondaryColor={awayTextColor}
                            possession={possession}
                            isLive={isLive}
                            winningSide={winningSide}
                            testID={testID}
                        />
                        <TeamRow
                            team={{
                                short_code: event.home_team.short_code,
                                logo_url: event.home_team.logo_url,
                                shouldShowFallbackLogo: shouldShowFallbackLogo,
                                side: 'home',
                            }}
                            primaryColor={homeBackgroundColor}
                            secondaryColor={homeTextColor}
                            possession={possession}
                            isLive={isLive}
                            winningSide={winningSide}
                            testID={testID}
                        />
                    </Column>
                    <Column gap="s8">
                        <ScoreOrOdds showOdds={showOdds} odds={event.away_team_winner_odds} event={event} side="away" />
                        <ScoreOrOdds showOdds={showOdds} odds={event.home_team_winner_odds} event={event} side="home" />
                    </Column>
                    <Column gap={'s20'}>
                        <WinIndicator isWinner={awayWin} />
                        <WinIndicator isWinner={homeWin} />
                    </Column>
                </Row>
            </TouchableOpacity>
        </Box>
    );
};

const WinIndicator = ({ isWinner }: { isWinner: boolean }) => (isWinner ? <TriangleLeft /> : <SizedBox value={8} />);

const ScoreOrOdds = ({
    showOdds,
    odds,
    event,
    side,
}: {
    showOdds: boolean;
    odds: number;
    event: Event;
    side: 'home' | 'away';
}) => (
    <Row alignItems={'center'} justifyContent={'flex-end'}>
        {showOdds ? (
            <Odds odds={odds} color="gray2" variant="bodySmall" />
        ) : (
            <TeamScore event={event} side={side} variant="titleSmall" fontWeight="700" />
        )}
    </Row>
);
