import { URLS } from '@/data';
import { EventDetails, League } from '@/feature/event-details-sbk/types';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

export const useGameTrackerUrl = (league: League, eventDetails: EventDetails) => {
    const enabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings?.game_tracker_enabled
    );
    const productName = useJurisdictionStore(
        store => store.jurisdictionSettings?.globalSettings?.keyValuePairs?.ApiKeys?.BET_GENIUS_PRODUCT_NAME ?? 'Betr'
    );
    if (!enabled) {
        return;
    }
    const betGeniusId = eventDetails.suppliers?.find(supplier => supplier.feed === 'BETGENIUS')?.external_id;
    if (!betGeniusId) {
        return;
    }
    if (league?.sport?.name === 'American Football') {
        switch (league.name) {
            case 'NFL':
            case 'NCAA':
                return `${URLS.BET_GENIUS_GAME_TRACKER_URL}?productName=${productName}&fixtureId=${betGeniusId}&widget=court`;
        }
        // TODO: add other leagues/sports in the future
    }
};
