import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { FeaturedLeague } from '../components/FeaturedLeagueSection';

/**
 * Fetches and parses featured leagues from prismic
 */
export const useFeaturedLeagues = (): FeaturedLeague[] => {
    const leagues = useJurisdictionStore(store => store.jurisdictionSettings?.productConfig?.leagues);
    return (
        leagues
            ?.filter(league => league.featured)
            .map(league => {
                return {
                    id: String(league.id),
                    name: String(league.label),
                    imageUrl: league.banner.url ?? undefined,
                    eventCount: league.event_display_count ? Number(league.event_display_count) : undefined,
                };
            }) ?? []
    );
};
