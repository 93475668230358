import { useCallback } from 'react';

import { useIsBetSlipSubmitting } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useBetSubmissionComplete } from '@/feature/betslip-sbk/hooks/use-bet-submission-complete';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { useResumeEffect } from '@/hooks/use-resume';

const SUBMISSION_TIMEOUT_MS = 10000; // 10 seconds

// Failsafe to poll for submitted bets when app resumes and is stuck in loading state
export const useResumeBetFailsafe = () => {
    const pollSubmittedBets = useSbkBetSlipStore(state => state.actions.pollSubmittedBets);
    const isSubmitting = useIsBetSlipSubmitting();
    const onPollFinished = useBetSubmissionComplete();
    const betSubmissionStartTime = useSbkBetSlipStore(state => state.betSubmissionStartTime);
    useResumeEffect(
        useCallback(() => {
            if (isSubmitting && betSubmissionStartTime) {
                const timeSinceSubmission = Date.now() - betSubmissionStartTime;
                // We only want to run this if the submission has been stuck for more than 10 seconds and the user returns to the bet slip
                const isGreaterThan10seconds = timeSinceSubmission > SUBMISSION_TIMEOUT_MS;
                if (isGreaterThan10seconds) {
                    pollSubmittedBets(onPollFinished);
                }
            }
        }, [isSubmitting, onPollFinished, pollSubmittedBets, betSubmissionStartTime])
    );
};
