import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { DonutChart } from '@/components/DonutChart';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { productNames } from '@/hooks/use-active-product-name';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { Product } from '@/hooks/use-jurisdiction';
import { useUser } from '@/hooks/use-user';
import { common, designSystem } from '@/styles/styles';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { toFraction } from '@/utils/numeric/fraction';

const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    bullet: {
        width: 8,
        height: 8,
        borderRadius: 4,
    },
});

const BulletPoint = ({ color }: { color: string }) => {
    return <View style={[{ backgroundColor: color }, styles.bullet]} />;
};

type BalanceInfoProps = {
    product: Product;
};

export const BalanceInfo = ({ product }: BalanceInfoProps) => {
    const productName = productNames[product];
    const { t } = useTranslation(['wallet']);
    const { wallets } = useActiveWallet();
    const { balance, total, bonus, betrBucks } = useMemo(() => {
        return {
            balance: toFraction(wallets[product].real_amount),
            total: toFraction(wallets[product].total),
            bonus: toFraction(wallets[product].bonus_amount),
            betrBucks: toFraction(wallets[product].free_amount),
        };
    }, [wallets, product]);
    const devMenuPresses = useRef<number>(0);
    const navigation = useNavigation();
    const { profile } = useUser();

    return (
        <View style={styles.container}>
            <View style={common.alignCenter}>
                <View>
                    {/* The order of the donut chart and the order of the list below should be the same. */}
                    <DonutChart
                        gap={4}
                        data={
                            !(bonus + balance)
                                ? [{ value: 100, color: designSystem.colors.gray5 }]
                                : [
                                      { value: balance, color: designSystem.colors.green },
                                      { value: bonus, color: designSystem.colors.bonusBlue },
                                      { value: betrBucks, color: designSystem.colors.purple },
                                  ]
                        }
                    />
                </View>
                <SizedBox value={20} />

                <TouchableOpacity
                    activeOpacity={0.8}
                    onPress={() => {
                        if (!profile.email.endsWith('@betr.app')) {
                            return;
                        }

                        devMenuPresses.current++;
                        if (devMenuPresses.current > 5) {
                            navigation.navigate('DeveloperScreen' as never);
                        }
                    }}
                    testID="balanceInfoTotal"
                >
                    <Text variant="displaySmall">{toLocaleCurrency(total)}</Text>
                </TouchableOpacity>

                <SizedBox value={8} />
                <Text variant="bodySmall" color={'gray2'}>
                    {t('total_product_balance', { productName })}
                </Text>
            </View>
            <SizedBox value={24} />

            <View style={common.spaceBetweenRow}>
                <View style={[common.row, common.alignCenter]}>
                    <BulletPoint color={designSystem.colors.green} />
                    <SizedBox value={8} />
                    <Text variant="bodyMedium">{t('wallet:withdrawable_cash')}</Text>
                </View>
                <Text variant="titleMedium" testID="walletBalance">
                    {toLocaleCurrency(balance)}
                </Text>
            </View>
            <SizedBox value={8} />
            {bonus !== 0 ? (
                <>
                    <View style={common.spaceBetweenRow}>
                        <View style={[common.row, common.alignCenter]}>
                            <BulletPoint color={designSystem.colors.bonusBlue} />
                            <SizedBox value={8} />
                            <Text variant="bodyMedium">{t('wallet:bonus')}</Text>
                        </View>
                        <Text variant="titleMedium">{toLocaleCurrency(bonus)}</Text>
                    </View>
                    <SizedBox value={8} />
                </>
            ) : null}
            {betrBucks !== 0 ? (
                <View style={common.spaceBetweenRow}>
                    <View style={[common.row, common.alignCenter]}>
                        <BulletPoint color={designSystem.colors.purple} />
                        <SizedBox value={8} />
                        <Text variant="bodyMedium">{t('wallet:betr_bucks')}</Text>
                    </View>
                    <Text variant="titleMedium" testID="betrBucks">
                        {toLocaleCurrency(betrBucks)}
                    </Text>
                </View>
            ) : null}
        </View>
    );
};
