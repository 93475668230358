import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import AlertIcon from '@/assets/icons/alert';
import ClockIcon from '@/assets/icons/clock';
import DownIcon from '@/assets/icons/down';
import { Badge } from '@/components/Badge';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { AccountTabNavigationProp } from '@/navigation/types';
import { common } from '@/styles/styles';
import { format } from 'date-fns';

import { Transaction } from '../hooks/types';
import {
    FailedTransactionReason,
    getActionTranslation,
    getSubText,
    isFailedTransaction,
    isPendingTransaction,
} from './helper';

type TransactionCardPropsType = { transaction: Transaction };

const styles = StyleSheet.create({
    rightArrow: { transform: [{ rotate: '-90deg' }] },
});

export const TransactionCard = ({ transaction }: TransactionCardPropsType) => {
    const { t } = useTranslation('wallet');
    const { amount, created_at, action, status, user_wallet_id, failed_reason } = transaction;
    const { navigate } = useNavigation<AccountTabNavigationProp>();
    const symbol = amount >= 0 ? '+' : '-';
    const isFailed = isFailedTransaction(status);
    const isPending = isPendingTransaction(status);
    const renderTag = () => {
        if (isFailed) {
            const label = failed_reason === FailedTransactionReason.paymentIncomplete ? t('incomplete') : t('failed');
            return (
                <Badge
                    label={label}
                    type="error"
                    textProps={{ textTransform: 'uppercase', color: 'gray8', lineHeight: 20 }}
                    leftIcon={<AlertIcon />}
                    testID="txnFailedBadge"
                />
            );
        }
        if (isPending) {
            return (
                <Badge
                    label={t('pending')}
                    type="info"
                    textProps={{ textTransform: 'uppercase', color: 'gray8', lineHeight: 20 }}
                    leftIcon={<ClockIcon />}
                />
            );
        }
        return null;
    };

    const subText = getSubText(transaction);

    return (
        <TouchableOpacity
            onPress={() => navigate('TransactionDetails', { userWalletId: user_wallet_id })}
            testID="transactionCard"
            accessible={false}
        >
            <SizedBox value={16} />
            <View style={[common.spaceBetweenRow]}>
                <View style={[common.flex]}>
                    <View style={[common.row, common.alignCenter]}>
                        <Text color={'gray2'} variant="labelMedium">
                            {format(created_at, 'MM/dd/yyyy')} AT {format(created_at, 'h:mm a')}
                        </Text>
                        <SizedBox value={8} />
                        {renderTag()}
                    </View>
                    <SizedBox value={8} />
                    <Text variant="titleMedium" testID="txnType">
                        {t(getActionTranslation(action))}
                    </Text>
                    <SizedBox value={2} />
                    {subText ? (
                        <Text variant="bodySmall" color={'gray3'} testID="txnCardSubText">
                            {subText}
                        </Text>
                    ) : null}
                </View>
                <View style={[common.spaceBetweenRow]}>
                    {isFailed ? (
                        <Text textDecorationLine={'line-through'} color={'gray2'} variant="bodyMedium">
                            ${Math.abs(amount)}
                        </Text>
                    ) : (
                        <Text variant="bodyMedium">
                            {symbol} ${Math.abs(amount)}
                        </Text>
                    )}
                    <SizedBox value={5} />
                    <DownIcon style={styles.rightArrow} />
                </View>
            </View>
            <SizedBox value={14} />
            <LineSeparator />
        </TouchableOpacity>
    );
};
