import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import DownSmallIcon from '@/assets/icons/downSmall';
import { common, designSystem } from '@/styles/styles';

import { SizedBox } from './SizedBox';
import { Text } from './TextComponent';
import { Row } from './lib/components';

const button = StyleSheet.create({
    chip: {
        flexGrow: 0,
        borderRadius: 10,
        paddingVertical: 8,
        paddingHorizontal: 14,
        backgroundColor: designSystem.colors.gray6,
        borderCurve: 'continuous',
    },
    active: {
        backgroundColor: designSystem.colors.gray1,
        borderColor: designSystem.colors.gray1,
    },
});

export type ChipButtonProps = {
    label: string;
    active?: boolean;
    onPress: () => void;
    testID?: string;
    isDropdown?: boolean;
    iconLeft?: ReactElement;
};

export const ChipButton = ({ label, active, onPress, testID, isDropdown, iconLeft }: ChipButtonProps) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            style={[button.chip, active && button.active, common.justifyCenter, common.row]}
        >
            {iconLeft ? (
                <Row>
                    {iconLeft}
                    <SizedBox value={8} />
                </Row>
            ) : null}
            <Text color={active ? 'gray8' : 'white'} variant="titleSmall" letterSpacing={-0.08} testID={testID}>
                {label}
            </Text>
            {isDropdown ? (
                <Row>
                    <SizedBox value={8} />
                    <DownSmallIcon style={common.selfCenter} />
                </Row>
            ) : null}
        </TouchableOpacity>
    );
};
