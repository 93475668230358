import { useCallback } from 'react';

import { activeLimitsSelector, useUserLimits } from '@/feature/responsible-gaming/hooks/use-user-limits';
import { useWallet } from '@/hooks/use-wallet';

import { validateTotalStake } from '../utils/validators';
import { useSbkBetSlipStore } from './use-sbk-betslip-store';

/**
 * Returns a function that validates total stake and updates bet slip state with the total stake error
 */
export const useTotalStakeValidation = () => {
    const { data: limits = [] } = useUserLimits({ select: activeLimitsSelector('WAGER') });

    const updateTotalStakeErrors = useSbkBetSlipStore(state => state.actions.updateTotalStakeErrors);
    const { realMoneyTotal: walletCashBalance, betrBucks: walletBetrBucksBalance } = useWallet();

    return useCallback(
        (totalCashStake: number, totalBetrBucksStake: number) => {
            const error = validateTotalStake({
                limits,
                totalCashStake,
                totalBetrBucksStake,
                walletCashBalance,
                walletBetrBucksBalance,
            });
            updateTotalStakeErrors(error);
        },
        [limits, updateTotalStakeErrors, walletCashBalance, walletBetrBucksBalance]
    );
};
