import { Event } from '@/feature/event-details-sbk/types';
import { designSystem } from '@/styles/styles';
import { AtLeast } from '@/types/utils';

export const getLogoColors = (event: AtLeast<Event, 'home_team' | 'away_team'>) => {
    let homeBackgroundColor = designSystem.colors.gray3;
    let homeTextColor = designSystem.colors.gray8;
    let awayBackgroundColor = designSystem.colors.gray3;
    let awayTextColor = designSystem.colors.gray8;
    let shouldShowFallbackLogo = true;

    if (event?.home_team?.primary_color && event?.away_team?.primary_color) {
        homeBackgroundColor = event.home_team.primary_color.toLowerCase();
        awayBackgroundColor = event.away_team.primary_color.toLowerCase();

        if (event?.home_team?.logo_url && event?.away_team?.logo_url) {
            shouldShowFallbackLogo = false;
        }

        if (event?.home_team?.secondary_color && event?.away_team?.secondary_color) {
            homeTextColor = event.home_team.secondary_color.toLowerCase();
            awayTextColor = event.away_team.secondary_color.toLowerCase();
        }
    }

    return {
        homeBackgroundColor,
        homeTextColor,
        awayBackgroundColor,
        awayTextColor,
        shouldShowFallbackLogo,
    };
};
