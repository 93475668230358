import React from 'react';
import { View } from 'react-native';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { ClosedLoopInfoProps, ClosedLoopMethodProps } from '@/feature/withdraw/components/closedLoop.types';
import { common } from '@/styles/styles';

import { ClosedLoopItem } from './ClosedLoopItem';

export const ClosedLoopInfo = ({ selectedMethod, amountToWithdraw, distribution }: ClosedLoopInfoProps) => {
    return (
        <View>
            <SizedBox value={5} />
            <Text fontSize={14} fontWeight="normal" lineHeight={20} color={'gray3'} textAlign={'center'}>
                For security, we require you to withdraw your winnings with the same method(s) used to make deposits.
                Any required distribution is equal to the amount deposited with that method.
            </Text>
            <SizedBox value={20} />
            {distribution
                ?.slice()
                .sort(a => (a.payment_type_id === selectedMethod.payment_type_id ? -1 : 1))
                .map((method: ClosedLoopMethodProps, idx: number) => (
                    <ClosedLoopItem
                        key={`${method.name}_${idx}`}
                        method={method}
                        title={idx === 0 ? 'Chosen method' : 'Required distribution'}
                    />
                ))}
            <SizedBox value={16} />
            <View style={[common.row, common.justifySpaceBetween]}>
                <Text fontSize={15}>Total withdraw</Text>
                <Text variant={'titleMedium'} testID="closedLoopTotalWithdrawAmount">
                    ${amountToWithdraw}
                </Text>
            </View>
            <SizedBox value={40} />
        </View>
    );
};
