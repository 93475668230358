import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
        <Path
            fill={props.fill ?? '#6D707C'}
            fillRule="evenodd"
            d="M12.9 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Zm-2-15h1V5h2v2h1a3 3 0 0 1 3 3h-2a1 1 0 0 0-1-1h-4a1 1 0 1 0 0 2h4a3 3 0 0 1 0 6h-1v2h-2v-2h-1a3 3 0 0 1-3-3h2a1 1 0 0 0 1 1h4a1 1 0 1 0 0-2h-4a3 3 0 1 1 0-6Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
