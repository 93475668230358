import React from 'react';
import { View } from 'react-native';

import { BottomTabBarProps } from '@react-navigation/bottom-tabs';

import AccountLoggedInIcon from '@/assets/icons/tab/accountLoggedIn';
import InviteFriendsIcon from '@/assets/icons/tab/gift';
import PromohubIcon from '@/assets/icons/tab/promohub';
import { TabBar as TabBarModule } from '@/components/TabBar';
import { AccountStackTabs } from '@/feature/account-details/AccountStack';
import { InviteFriendsStack } from '@/feature/invite-friends/screens/InviteFriendsStack';
import { PromoHubStackTabs } from '@/feature/promohub/PromohubStack';
import { ProductDocumentDataBodyTabsettingsSlice } from '@/types/prismic.generated';

import { PickemTabNavigatorParamList } from './pickem/types';
import { SbkTabNavigatorParamList } from './sbk/types';

export const TabBar = (props: BottomTabBarProps): JSX.Element => <TabBarModule {...props} />;

export const TabOptions = {
    headerShown: false,
    tabBarBackground: () => <View />,
    freezeOnBlur: true,
} as const;

export const CommonTabsConfig = [
    {
        name: 'PromohubStack',
        component: PromoHubStackTabs,
        icon: PromohubIcon,
        title: 'Promos',
    },
    {
        name: 'InviteFriendPromo',
        component: InviteFriendsStack,
        icon: InviteFriendsIcon,
        title: 'Earn $$$',
    },
    {
        name: 'AccountStack',
        component: AccountStackTabs,
        icon: AccountLoggedInIcon,
        title: 'Account',
    },
] as const;

export type TabItem<T extends keyof PickemTabNavigatorParamList | keyof SbkTabNavigatorParamList> = {
    name: T;
    component: () => React.ReactNode;
    icon: () => React.ReactNode;
    title: string;
};

/**
 * Updates the tab configuration based on the provided Prismic tabSettings slice.
 *
 * @param tabs - The array of tab items to be updated.
 * @param tabBarSettings - Optional settings to customize tab titles and visibility.
 * @returns The updated array of tabs with titles overridden and hidden tabs filtered out.
 */
export const mapTabsFromTabSettings = <T extends keyof PickemTabNavigatorParamList | keyof SbkTabNavigatorParamList>(
    tabs: TabItem<T>[],
    tabSettings?: ProductDocumentDataBodyTabsettingsSlice
): TabItem<T>[] => {
    if (!tabSettings) {
        return tabs;
    }

    return tabs
        .map(tab => {
            // Search for a corresponding tabname in the tabSettings slice
            const setting = tabSettings.items.find(tabSetting => tabSetting.tabname === tab.name);

            // If no corresponding setting is found, return the original tab
            if (!setting) {
                return tab;
            }

            // If hide is true, filter out (exclude) the tab
            if (setting.hide === true) {
                return null;
            }

            // If hide is false, override the title
            return {
                ...tab,
                title: setting.tablabel || tab.title, // Override title
            };
        })
        .filter(Boolean); // Exclude null values
};
