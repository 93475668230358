import { Client } from '@/data/location/client';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';

const clients = new Map<string | 'active', Client>();

/**
 * Returns a cached geocomply client appropriate for the current jurisdiction and product.
 * @param config - an optional config object to specify the product and jurisdiction; if not provided defaults to the current settings
 * @returns the Geocomply client
 */
export const getGeocomplyClient = (config?: { product: Product; jurisdiction: string }): Client => {
    const { product, jurisdiction } = config ?? useJurisdictionStore.getState();

    if (product === Product.None || !jurisdiction) {
        throw new Error('Can not request a geocomply client without an active product and jurisdiction!');
    }

    const cacheKey = `${product}-${jurisdiction}`;

    if (!clients.has(cacheKey)) {
        clients.set(cacheKey, new Client({ product, jurisdiction }));
    }

    const client = clients.get(cacheKey)!;

    const currentActiveClient = clients.get('active');

    if (client !== currentActiveClient) {
        currentActiveClient?.shutdown();
    }

    clients.set('active', client);

    return client;
};
