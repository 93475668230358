import { EventInfoWithPlayersQuery, EventInfoWithoutPlayersQuery } from '../query.generated';

export type EventInfo = EventInfoWithPlayersQuery['getEventByIdV2'];
export type EventInfoWithoutPlayers = EventInfoWithoutPlayersQuery['getUpcomingEventsV2'][number];

export type EventTeamVersus = Extract<EventInfo, { __typename?: 'TeamVersusEvent' }>;
export type EventTeamTournament = Extract<EventInfo, { __typename?: 'TeamTournamentEvent' }>;
export type EventWithTeams = EventTeamVersus | EventTeamTournament;

export type EventIndividualTournament = Extract<EventInfo, { __typename?: 'IndividualTournamentEvent' }>;
export type EventIndividualVersus = Extract<EventInfo, { __typename?: 'IndividualVersusEvent' }>;
export type EventWithoutTeams = EventIndividualTournament | EventIndividualVersus;

export const isIndividualEvent = (
    event: EventInfoWithoutPlayers | EventInfo | undefined
): event is EventWithoutTeams => {
    return event?.__typename === 'IndividualTournamentEvent' || event?.__typename === 'IndividualVersusEvent';
};

export const isTeamEvent = (event: EventInfoWithoutPlayers | EventInfo | undefined): event is EventWithTeams => {
    return event?.__typename === 'TeamVersusEvent' || event?.__typename === 'TeamTournamentEvent';
};
