import React, { PropsWithChildren, useMemo, useRef, useState } from 'react';

import { Box } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import { Toast, ToastProps, toastTypes } from '@/feature/alerts/components/Toast';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { InfoSheet, InfoSheetProps } from './InfoSheet';
import { SelectionItem, SelectionSheet, SelectionSheetProps } from './SelectionSheet';

type OnDismissFn = () => void;

export type ShowInfoSheet = (options: InfoSheetProps & { onDismiss?: OnDismissFn }) => void;

export type Alerts = {
    showInfoSheet: ShowInfoSheet;
    showSelectionSheet: <T extends SelectionItem>(options: SelectionSheetProps<T>) => void;
    showToast: (options: ToastProps, duration?: number) => void;
    clearAllToasts: () => void;
    dismissInfoSheet: () => void;
};

export const AlertsContext = React.createContext<Alerts>({
    showInfoSheet: () => {},
    showSelectionSheet: () => {},
    showToast: () => {},
    clearAllToasts: () => {},
    dismissInfoSheet: () => {},
});

/**
 * Provides access to showing generic info sheets, selection sheets or toasts(//TODO:).
 */
export const AlertsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const infoSheetRef = useRef<BottomSheetModal>(null);
    const selectionSheetRef = useRef<BottomSheetModal>(null);
    const [options, setOptions] = useState<InfoSheetProps | undefined>(undefined);
    const [selectionOptions, setSelectionOptions] = useState<SelectionSheetProps<any> | undefined>(undefined);
    const [toastData, setToastData] = useState<ToastProps[]>([]);
    const infoOnDismissRef = useRef<OnDismissFn | undefined>(undefined);
    const currentToastTimeoutId = useRef<NodeJS.Timeout | null>(null);

    /* eslint-disable react-hooks/exhaustive-deps */
    const alerts = useMemo(
        () => ({
            showInfoSheet: (o: InfoSheetProps & { onDismiss?: OnDismissFn }) => {
                //dismiss previous info-sheet and show the new one with delay (if any (: )
                infoSheetRef.current?.dismiss({ duration: 100 });
                setTimeout(() => {
                    setOptions(o);
                    infoOnDismissRef.current = o.onDismiss;
                    infoSheetRef.current?.present();
                }, 150);
            },
            showSelectionSheet: <T extends SelectionItem>(o: SelectionSheetProps<T>) => {
                setSelectionOptions(o);
                selectionSheetRef.current?.present();
            },
            showToast: ({ toastType = toastTypes.SUCCESS, ...props }: ToastProps, duration = 2000) => {
                if (currentToastTimeoutId.current) {
                    clearTimeout(currentToastTimeoutId.current);
                }

                setToastData([...toastData, { toastType, ...props }]);

                currentToastTimeoutId.current = setTimeout(() => {
                    setToastData(toastData.slice(1));
                }, duration);
            },
            clearAllToasts: () => {
                setToastData([]);
            },
            dismissInfoSheet: () => {
                infoSheetRef.current?.dismiss();
            },
        }),
        []
    );

    return (
        <AlertsContext.Provider value={alerts}>
            <Box flex={1} overflow={'hidden'}>
                {children}
                <Modal id="selection-sheet" modalRef={selectionSheetRef}>
                    {selectionOptions ? <SelectionSheet modalRef={selectionSheetRef} {...selectionOptions} /> : null}
                </Modal>
                <Modal
                    id="info-sheet"
                    modalRef={infoSheetRef}
                    onDismiss={() => {
                        infoOnDismissRef.current?.();
                    }}
                >
                    {options ? <InfoSheet modalRef={infoSheetRef} {...options} /> : null}
                </Modal>
                {toastData.map(props => (
                    <Toast key={`toast-message-${props.message}`} {...props} />
                ))}
            </Box>
        </AlertsContext.Provider>
    );
};
