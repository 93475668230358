export const firebaseConfigStaging = {
    apiKey: 'AIzaSyDQs3T8u7NYGBQxmlFVv1Eye_eMJ7obTog',
    authDomain: 'betr-staging-4e8cc.firebaseapp.com',
    projectId: 'betr-staging-4e8cc',
    storageBucket: 'betr-staging-4e8cc.appspot.com',
    messagingSenderId: '1065337814290',
    appId: '1:1065337814290:web:50f8bf42879e9d9668c7cb',
    measurementId: 'G-YZ30CK3YZM',
};

export const firebaseConfigProd = {
    apiKey: 'AIzaSyCXSI4bxeXJOuOeJpcNs2GGYG9ZwfnVeYk',
    authDomain: 'betr-instabet-prod.firebaseapp.com',
    projectId: 'betr-instabet-prod',
    storageBucket: 'betr-instabet-prod.appspot.com',
    messagingSenderId: '133829894943',
    appId: '1:133829894943:web:f551645bd20bd4b70a462a',
    measurementId: 'G-YNVSXM82RX',
};
