import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';

import { FaqModalRef, P2pFaqModal } from './P2pFaqModal';

type P2pFaq = {
    showP2pFaqModal: () => void;
};

const P2pFaqContext = React.createContext<P2pFaq>({
    showP2pFaqModal: () => {},
});

/**
 * Provides access to the Modal used to display FAQ modal
 */
export const P2pFaqProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<FaqModalRef>(null);

    const showP2pFaqModal = useCallback(() => {
        modalRef.current?.show();
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <P2pFaqContext.Provider value={{ showP2pFaqModal }}>
                {children}
                <P2pFaqModal ref={modalRef} />
            </P2pFaqContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useP2pFaq = () => {
    return useContext(P2pFaqContext);
};
