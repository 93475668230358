import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { PickerInput } from '@/components/PickerInput';
import { TextInput } from '@/components/TextInput';
import { AddressAutoComplete } from '@/feature/kyc/components/HomeAddress/AddressAutoComplete';
import { textInputKeyboardType, validateKeyboardInput } from '@/feature/kyc/utils/keyboardInput';
import { useFormFields } from '@/hooks/use-form-fields';

import { zipMask } from '../../const';
import { AddressFormValues } from '../../hooks/use-kyc-fields';
import { isPOBox } from '../../screens/HomeAddress/HomeAddressStep';
import { getStateCode, getStateName } from './get-state-info';
import { AddressError } from './types';

const styles = StyleSheet.create({
    inputsContainer: {
        gap: 20,
        paddingBottom: 80,
    },
});

type AddressFormProps = {
    values: AddressFormValues;
    setFieldValue: (name: keyof AddressFormValues, value: string) => void;
    setAddressFormValues: (fieldValues: AddressFormValues) => void;
    setShowPicker: (value: boolean) => void;
};

export const AddressForm = ({ values, setFieldValue, setAddressFormValues, setShowPicker }: AddressFormProps) => {
    const { t } = useTranslation('kyc');
    const { saveField, focusField } = useFormFields<'city' | 'state' | 'zip'>();
    const [addressError, setAddressError] = useState<AddressError | null>(null);

    const handleSelectedPlace = (addressDetails: AddressFormValues) => {
        const americanState = getStateCode(addressDetails.americanState);
        setAddressFormValues({ ...addressDetails, americanState });
        checkForPOBox(addressDetails.address);
    };

    /**
     * Checks if the given address contains a PO Box and sets an error if found.
     *
     * This function is primarily used for manually entered addresses, as PO Box
     * addresses are filtered out in the autocomplete dropdown.
     *
     * @example
     * checkForPOBox("123 Main St, Anytown, USA"); // No error set
     * checkForPOBox("PO Box 456, Anytown, USA"); // Sets PO Box error
     */
    const checkForPOBox = (address: string): void => {
        if (isPOBox(address)) {
            setAddressError({ type: 'poBox', message: t('address_po_box_error') });
        } else {
            setAddressError(null);
        }
    };

    /**
     * Handles text change for various address form fields
     * @param field - The field being updated (e.g., 'address', 'city', 'zip')
     * @param value - The new value for the field
     */
    const onChangeText = (field: keyof AddressFormValues, value: string) => {
        // Validate the input to ensure it meets keyboard input requirements
        if (validateKeyboardInput(value)) {
            // Update the form field with the new value
            setFieldValue(field, value);

            // If the field being updated is the address field, check for PO Box
            if (field === 'address') {
                checkForPOBox(value);
            }
        }
        // TODO: Add user feedback for invalid inputs
        // Currently, if the input doesn't pass validation, it's silently ignored
    };

    const americanStateName = getStateName(values.americanState);

    return (
        <View style={styles.inputsContainer}>
            <AddressAutoComplete
                autoFocus
                fieldKey="address"
                label={t('address')}
                keyboardAppearance="dark"
                onFocus={() => setShowPicker(false)}
                value={values.address ?? ''}
                setFieldValue={onChangeText}
                handleSelectedPlace={handleSelectedPlace}
                onSubmitEditing={focusField('city')}
                returnKeyType={'next'}
                keyboardType={textInputKeyboardType}
                addressError={addressError}
            />
            <TextInput
                label={t('city')}
                keyboardAppearance="dark"
                onFocus={() => setShowPicker(false)}
                value={values.city ?? ''}
                onChangeText={(_masked: string, unmasked: string) => {
                    onChangeText('city', unmasked);
                }}
                ref={saveField('city')}
                onSubmitEditing={() => setShowPicker(true)}
                returnKeyType={'next'}
                autoCorrect={false}
                keyboardType={textInputKeyboardType}
            />
            {/**TODO: focus the zip field once the user selects a state in the picker
             * currently unable because the picker is rendered in KycStepContainer
             */}
            <PickerInput label={t('state')} value={americanStateName} setShowPicker={setShowPicker} />
            <TextInput
                label={t('zip_code')}
                keyboardAppearance="dark"
                inputMode="numeric"
                onFocus={() => setShowPicker(false)}
                value={values.zip ?? ''}
                onChangeText={(_masked: string, unmasked: string) => {
                    onChangeText('zip', unmasked);
                }}
                ref={saveField('zip')}
                mask={zipMask}
                returnKeyType={'done'}
                autoCorrect={false}
            />
        </View>
    );
};
