import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, useWindowDimensions } from 'react-native';
import Animated, { AnimatedStyle } from 'react-native-reanimated';

import { EventInfoWithoutPlayers } from '@/api/events/types/types';
import { ImageWithGradient } from '@/components/ImageWithGradient';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components/Box';
import { LinearGradient } from '@/components/linear-gradient/LinearGradient';
import { common, designSystem } from '@/styles/styles';
import { EventAttributesType } from '@/types/api.generated';
import { findEventAttribute } from '@/utils/fantasy-attribute-utils';
import { gameUtils } from '@/utils/games';
import { isUFC } from '@/utils/league';

export const EVENT_HEADER_HERO_IMAGE_HEIGHT = 250;

type Props = {
    singleEvent: EventInfoWithoutPlayers | null;
    headerOverlayStyle: AnimatedStyle;
};

const BackgroundContainer = ({ image, children }: { image?: string; children: ReactNode }) => {
    const { width } = useWindowDimensions();
    return (
        <Box zIndex={0}>
            {image ? (
                <>
                    <ImageWithGradient source={{ uri: image }} />
                    {children}
                </>
            ) : (
                <>
                    <LinearGradient
                        colors={[designSystem.colors.red, '#000000']}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 0, y: 1 }}
                        width={width}
                        height={EVENT_HEADER_HERO_IMAGE_HEIGHT}
                    />
                    {children}
                </>
            )}
        </Box>
    );
};

export const EventHeader = React.memo(({ singleEvent, headerOverlayStyle }: Props) => {
    const { t } = useTranslation('sports_events');

    if (!singleEvent) {
        return null;
    }

    const roundNumber = findEventAttribute(singleEvent, EventAttributesType.RoundNumber) ?? '';
    const startDate = findEventAttribute(singleEvent, EventAttributesType.TournamentStartDateTime) ?? '';
    const endDate = findEventAttribute(singleEvent, EventAttributesType.TournamentEndDateTime) ?? '';
    const title = gameUtils.createHeaderTitleLabel(singleEvent);
    const date = isUFC(singleEvent.league)
        ? gameUtils.createTournamentTimeLabel(singleEvent)
        : gameUtils.createTimeLabel(singleEvent);
    const dateRanges = gameUtils.formatTournamentDates(startDate, endDate);
    const info = gameUtils.createInfoLabel(singleEvent);
    const image = singleEvent.headerImage;

    return (
        <BackgroundContainer image={image}>
            <Box paddingHorizontal={'s16'} zIndex={3}>
                <Box height={EVENT_HEADER_HERO_IMAGE_HEIGHT} justifyContent={'flex-end'}>
                    {roundNumber ? (
                        <Box
                            backgroundColor={'darkBlue'}
                            paddingHorizontal={'s6'}
                            style={styles.roundLabel}
                            alignSelf={'flex-start'}
                            borderRadius={'r6'}
                        >
                            <Text variant="labelLarge" textAlign={'center'}>
                                {t('round')} {roundNumber}
                            </Text>
                        </Box>
                    ) : null}
                    <Text variant={'headlineMedium'}>{title}</Text>
                    <Text variant={'bodySmall'} color={'gray2'}>
                        {info}
                    </Text>
                    <Text variant={'bodySmall'} color={'gray2'}>
                        {date !== '' ? date : dateRanges}
                    </Text>
                </Box>
            </Box>
            <Animated.View
                style={[
                    StyleSheet.absoluteFill,
                    common.absolute,
                    { backgroundColor: designSystem.colors.gray8, zIndex: designSystem.zIndex.zIndex4 },
                    headerOverlayStyle,
                ]}
            />
        </BackgroundContainer>
    );
});

const styles = StyleSheet.create({
    roundLabel: {
        paddingBottom: 2.5,
        paddingTop: 1.5,
        borderCurve: 'continuous',
    },
});
