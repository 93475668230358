import React from 'react';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';

export const SwitchContainerRow = ({ label, switchButton }: { label: string; switchButton: React.ReactNode }) => {
    return (
        <Box justifyContent="space-between" alignItems="center" flexDirection="row" paddingVertical="s16">
            <Text>{label}</Text>
            {switchButton}
        </Box>
    );
};
