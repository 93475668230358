import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { FooterRG } from '@/components/FooterRG';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';

export const AccountFooter = () => {
    const navigation = useNavigation();
    const { t } = useTranslation(['common']);

    return (
        <>
            <SizedBox value={48} />
            <TouchableOpacity onPress={() => navigation.navigate('Logout')}>
                <Text fontSize={15} fontWeight={'600'} color={'red'} textDecorationLine={'underline'}>
                    {t('logout')}
                </Text>
            </TouchableOpacity>
            <FooterRG />
        </>
    );
};
