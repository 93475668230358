import React, { ReactNode } from 'react';

export type ComposeProps = {
    providers: React.FC<{ children: ReactNode | ReactNode[] }>[];
    children: React.ReactNode;
};

/**
 * HOC component that will wrap the children with the providers in the order they are passed.
 */
export const ComposeProviders: React.FC<ComposeProps> = ({ providers, children }) => {
    return (
        <>
            {providers.reduceRight(
                (acc, Provider) => (
                    <Provider>{acc}</Provider>
                ),
                children
            )}
        </>
    );
};
