import { Linking, NativeModules, Platform } from 'react-native';

const { OpenSettingsModule } = NativeModules;

/**
 * Opens the platform settings screen for the app.
 */
export const openPlatformSettings = () => {
    if (Platform.OS === 'ios') {
        Linking.openURL('app-settings:');
    }

    if (Platform.OS === 'android') {
        OpenSettingsModule.openPrivacySettings();
    }
};
