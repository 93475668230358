import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import CreditIcon from '@/assets/icons/credit';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useDepositAmountConverter } from '@/feature/devsettings/hooks/use-dev-settings';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { AddPaymentMethod } from './AddPaymentMethod';

type PaymentMethodDebitCardProps = {
    selectedAmount: string;
    paysafeAccountId: string;
};

export const AddPaymentMethodDebitCard = ({ selectedAmount, paysafeAccountId }: PaymentMethodDebitCardProps) => {
    const navigation = useNavigation();
    const content = useJurisdictionStore(store => store.jurisdictionSettings?.productConfig?.keyValuePairs);
    const cmsContent = content?.['Content Keys'];
    const { t } = useTranslation('transactions');
    const { convertToMinorUnitAmount } = useDepositAmountConverter();

    return (
        <AddPaymentMethod
            icon={<CreditIcon />}
            title={t('add_debit_card')}
            description={cmsContent?.['deposit.debitCard.description'] ?? ''}
            onPress={() => {
                navigation.navigate('AddDebitCardWebView', {
                    selectedAmount: convertToMinorUnitAmount(selectedAmount),
                    paysafeAccountId,
                });
            }}
            analyticsEvent={AnalyticsEvent.DEPOSIT_METHOD_ADD_DEBIT}
        />
    );
};
