import React from 'react';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { common } from '@/styles/styles';

type NoActivityHistoryProps = {
    title: string;
    description: string;
};

export const NoActivityHistory = ({ title, description }: NoActivityHistoryProps) => {
    return (
        <Box paddingHorizontal={'s40'} style={[common.flex, common.justifyCenter, common.alignCenter]}>
            <Text variant="titleMedium" textAlign={'center'}>
                {title}
            </Text>
            <SizedBox value={4} />
            <Text variant="bodySmall" textAlign={'center'} color={'gray3'}>
                {description}
            </Text>
            <SizedBox value={86} />
        </Box>
    );
};
