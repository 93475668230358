import React from 'react';

import { Box } from '@/components/lib/components';
import { MarketLayouts } from '@/feature/event-details-sbk/components/market-layouts';
import { usePublishedMarkets } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { Event, Market } from '@/feature/event-details-sbk/types';

import { EventInfo } from './EventInfo';

type LeagueMarketEventCardProps = {
    event: Event;
    markets: Market[];
    testID?: string;
};

export const LeagueMarketEventCard = React.memo(({ event, markets, testID }: LeagueMarketEventCardProps) => {
    const publishedMarkets = usePublishedMarkets(markets);
    if (!publishedMarkets.length) {
        return null;
    }
    const layout = publishedMarkets[0].layout;

    return (
        <Box py="s16" gap="s16">
            <EventInfo event={event} testID={testID} />
            <MarketLayouts {...{ event, layout, testID, markets: publishedMarkets }} />
        </Box>
    );
});
