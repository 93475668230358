import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <G clipPath="url(#a)">
            <Path
                fill="#253B80"
                d="m5.844 19.436.349-2.215-.777-.018H1.707L4.285.861a.21.21 0 0 1 .21-.179h6.252c2.076 0 3.509.433 4.257 1.285.35.4.574.818.682 1.278.113.483.115 1.06.005 1.763l-.008.051v.451l.35.199c.296.156.53.336.71.541.3.342.494.777.576 1.292.085.53.057 1.16-.082 1.875-.16.821-.418 1.536-.768 2.122-.321.539-.73.986-1.216 1.333-.464.33-1.016.58-1.639.74a8.169 8.169 0 0 1-2.048.236h-.487c-.348 0-.686.125-.951.35-.266.23-.442.543-.496.885l-.037.2-.616 3.903-.028.143c-.007.046-.02.068-.038.084a.103.103 0 0 1-.064.023H5.844Z"
            />
            <Path
                fill="#179BD7"
                d="M16.365 5.111c-.018.12-.04.242-.064.367-.824 4.234-3.646 5.697-7.249 5.697H7.217c-.44 0-.812.32-.88.754l-.94 5.958-.266 1.688a.47.47 0 0 0 .464.543h3.254c.385 0 .712-.28.773-.66l.032-.165.613-3.888.039-.214a.783.783 0 0 1 .773-.661h.487c3.153 0 5.62-1.28 6.342-4.984.301-1.547.145-2.84-.652-3.748a3.11 3.11 0 0 0-.89-.687Z"
            />
            <Path
                fill="#222D65"
                d="M15.503 4.767a6.499 6.499 0 0 0-.802-.178 10.19 10.19 0 0 0-1.618-.118H8.182a.778.778 0 0 0-.773.662l-1.042 6.604-.03.192a.891.891 0 0 1 .88-.754h1.835c3.603 0 6.425-1.464 7.25-5.697.024-.125.045-.247.063-.367a4.391 4.391 0 0 0-.862-.344Z"
            />
            <Path
                fill="#253B80"
                d="M7.41 5.133a.78.78 0 0 1 .772-.661h4.901c.581 0 1.123.038 1.618.118a6.491 6.491 0 0 1 .987.235c.243.081.47.176.678.286.245-1.564-.002-2.63-.848-3.594C14.585.455 12.902 0 10.748 0H4.495c-.44 0-.816.32-.884.755l-2.604 16.51c-.052.327.2.622.53.622h3.86l.97-6.15 1.042-6.604Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M1 0h17.333v20H1z" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgComponent;
