import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
        <G clipPath="url(#clip0_17251_14831)">
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.2175 5.28234C17.6554 3.72025 15.1227 3.72025 13.5606 5.28234L11.4393 7.40366L10.0251 5.98945L12.1464 3.86813C14.4895 1.52498 18.2885 1.52498 20.6317 3.86813C22.9748 6.21127 22.9748 10.0103 20.6317 12.3534L18.5104 14.4747L17.0961 13.0605L19.2175 10.9392C20.7796 9.3771 20.7796 6.84444 19.2175 5.28234ZM5.78244 18.7174C7.34453 20.2795 9.87719 20.2795 11.4393 18.7174L13.5606 16.5961L14.9748 18.0103L12.8535 20.1316C10.5104 22.4747 6.71137 22.4747 4.36822 20.1316C2.02508 17.7884 2.02508 13.9894 4.36822 11.6463L6.48954 9.52498L7.90376 10.9392L5.78244 13.0605C4.22034 14.6226 4.22034 17.1553 5.78244 18.7174ZM8.96442 16.9496L17.4497 8.46432L16.0355 7.05011L7.5502 15.5354L8.96442 16.9496Z"
                fill={props.color ?? designSystem.colors.gray1}
            />
        </G>
        <Defs>
            <ClipPath id="clip0_17251_14831">
                <Rect width="24" height="24" fill="white" transform="translate(0.5)" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgComponent;
