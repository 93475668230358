/**
 * String.prototype.localeCompare in Hermes can be very slow, especially on Android ARM64.
 * This happens infrequently but can lead to long delays, with suggestions to reuse Intl.Collator to improve performance.
 * More details are available [here](https://github.com/facebook/hermes/issues/867).
 *
 * Please reuse this throughout the app to avoid the performance issue.
 *
 * @example
 * ```tsx
 * const sortedArray = array.sort((string1, string2) => localeCompareCollator.compare(string1, string2));
 * ```
 *
 */
export const localeCompareCollator = new Intl.Collator();
