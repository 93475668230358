import React from 'react';
import { StyleSheet, View } from 'react-native';

import { TopTabBar, TopTabBarProps } from '@/components/TopTabBar';

import { BetSlipHeader } from './BetSlipHeader';

const styles = StyleSheet.create({
    header: {
        marginTop: 1,
    },
});

export const BetSlipTabBar = (props: TopTabBarProps) => (
    <TopTabBar
        {...props}
        customEdges={[]}
        headerComponent={
            <View style={styles.header}>
                <BetSlipHeader />
            </View>
        }
    />
);
