import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useBetslipData } from '@/feature/betslip-pickem/hooks/use-betslip-data';
import { useBetslipNavigation } from '@/feature/betslip-pickem/hooks/use-betslip-navigation';
import { getWinningPicksAndToWin } from '@/feature/betslip-pickem/utils/betslip-utils';
import { getMultiplierBackgroundColor } from '@/feature/lobby/utils/multiplierUtils';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { SEPARATOR_HEGIHT, common, designSystem } from '@/styles/styles';
import { getProjectionsTypes } from '@/utils/getProjectionsTypes';

import { PicksMultiplierProgressBar } from './PicksMultiplierProgressBar';

const SIZE = 24;
const styles = StyleSheet.create({
    root: {
        backgroundColor: designSystem.colors.gray8,
        //zIndex 1, margin -1, to cover tabs top hairline when rendered
        marginBottom: -1,
        zIndex: designSystem.zIndex.zIndex1,
        height: 72,
        paddingHorizontal: 16,
        paddingTop: 16,
        paddingBottom: 12,
        borderTopWidth: SEPARATOR_HEGIHT,
        borderTopColor: designSystem.colors.gray5,
    },
    leftLabel: {
        color: designSystem.colors.gray2,
    },
    multiplierRoot: {
        flex: 1,
        paddingRight: 8,
    },
    multiplierLabel: {
        color: designSystem.colors.white,
    },
    picksNumber: {
        borderRadius: 100,
        backgroundColor: designSystem.colors.gray8,
        width: SIZE,
        height: SIZE,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export const PickBar = () => {
    const { betslip: picks, validPicks } = useBetslipData();

    return (
        <MaxWidthWrapper>
            <View style={styles.root}>
                <View style={[common.row, common.flex]}>
                    {validPicks ? <Multiplier /> : <LeftLabel />}
                    <SizedBox value={24} />
                    <ViewEntry count={picks.length} />
                </View>
            </View>
        </MaxWidthWrapper>
    );
};

const LeftLabel = () => {
    const { t } = useTranslation('betslip_pickem');
    return (
        <View style={[common.flex, common.full, common.justifyCenter]}>
            <Text style={styles.leftLabel} variant="bodySmall">
                {t('min_entry_requirements')}
            </Text>
        </View>
    );
};

const Multiplier = () => {
    const { betslip, multipliers, dynamicMultipliers, dynamicBoostedMultiplier } = useBetslipData();
    const { highestMultiplier } = getWinningPicksAndToWin(dynamicMultipliers.DYNAMIC, dynamicBoostedMultiplier);
    const maxMultiplier = multipliers[betslip.length - 1] ?? highestMultiplier;
    const { hasBoostedPicks } = getProjectionsTypes(betslip.map(pick => pick.projection));
    const backgroundColor = getMultiplierBackgroundColor(hasBoostedPicks);

    return (
        <Box flex={1} pr={'s8'}>
            <Row mb={'s10'} alignItems={'center'}>
                <Text variant="labelLarge">Multiplier: </Text>
                {maxMultiplier ? <Text>{`${maxMultiplier}x`}</Text> : null}
            </Row>
            <PicksMultiplierProgressBar hasCustomPicks={hasBoostedPicks} multiplierBackgroundColor={backgroundColor} />
        </Box>
    );
};

const ViewEntry: React.FC<{ count: number }> = ({ count }) => {
    const { navigateToPickslip } = useBetslipNavigation();
    const { t } = useTranslation('betslip_pickem');

    return (
        <View style={[common.justifyCenter, common.alignEnd]}>
            <Button
                type="active"
                variant="rounded"
                analyticsTag={t('view_lineup')}
                onPress={() => navigateToPickslip()}
                label={
                    <View style={common.row} accessible={false}>
                        <Text variant={'buttonLabel'} color={'gray8'}>
                            {t('view_lineup')}
                        </Text>
                        <SizedBox value={12} />
                        <View style={styles.picksNumber}>
                            <Text variant={'buttonLabel'} lineHeight={SIZE} testID="entrySize">
                                {count}
                            </Text>
                        </View>
                    </View>
                }
                accessible={false}
            />
        </View>
    );
};
