import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { runStoreUpdate } from '@/utils/zustand';

import { BetPlacedSheet } from '../components/bet-placed-sheet/BetPlacedSheet';
import { useSbkBetSlipStore } from './use-sbk-betslip-store';

export const useBetSuccessSheet = () => {
    const { t } = useTranslation('common');
    const { showInfoSheet } = useAlerts();
    const clearBetSlip = useSbkBetSlipStore(state => state.actions.clearBetSlip);
    const showBetSuccessSheet = useCallback(() => {
        showInfoSheet({
            description: <BetPlacedSheet />,
            buttonLabel: t('done'),
            onDismiss: () => {
                // We want to batch update the store to prevent crash here
                runStoreUpdate(clearBetSlip);
            },
        });
    }, [clearBetSlip, showInfoSheet, t]);

    return { showBetSuccessSheet };
};
