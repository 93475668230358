/**
 * Logs a message to the console if the debug flag is enabled.
 *
 * @param debug - A boolean flag indicating whether debug mode is enabled.
 * @param message - The message to be logged.
 */
export const debugLog = (debug: boolean, message: string) => {
    if (debug) {
        console.log(message);
    }
};
