import React from 'react';

import { LoadingSkeleton } from '@/components/SkeletonLoader';
import { Box } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

const carouselItemHeight = 156;
const carouselItemWidth = 331;
export const CarouselSkeleton = () => (
    <Box width={carouselItemWidth} height={carouselItemHeight} borderRadius={'r16'} backgroundColor={'gray6'}>
        <LoadingSkeleton
            width={carouselItemWidth}
            height={carouselItemHeight}
            borderRadius="r16"
            skeletonColor={designSystem.colors.gray3}
        />
    </Box>
);
