import { useEffect } from 'react';

import { useUser } from '@/hooks/use-user';
import { read, save } from '@/utils/async-storage';

/**
 * Custom hook that stores the user id to async storage.
 * This will be used to pass CUSTOM_UUID to Phrase SDK
 */
export const useStoreUserIdToAsyncStorage = async () => {
    const user = useUser();
    useEffect(() => {
        const storeUserId = async () => {
            const userIdFromAsyncStorage = await read('@phrase-custom-user-id');
            if (!userIdFromAsyncStorage && user.profile.sub) {
                save('@phrase-custom-user-id', user.profile.sub);
            }
        };
        storeUserId();
    }, [user.profile.sub]);
};
