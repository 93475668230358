import { logger } from '@/utils/logging';

/*
 * This mapping is not definitive and may not be the url we want to redirect the user to, for each browser
 * */
const browserLocationInfo = {
    chrome: 'https://support.google.com/chrome/answer/142065',
    firefox: 'https://www.google.com/search?q=change+location+settings+firefox',
    safari: 'https://www.google.com/search?q=change+location+safari',
    opera: '',
    edge: '',
    undefined: undefined,
} as const;

const getBrowserName = (): keyof typeof browserLocationInfo => {
    let userAgent = navigator.userAgent;
    if (userAgent.match(/chrome|chromium|crios/i)) {
        return 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
        return 'firefox';
    } else if (userAgent.match(/safari/i)) {
        return 'safari';
    } else if (userAgent.match(/opr\//i)) {
        return 'opera';
    } else if (userAgent.match(/edg/i)) {
        return 'edge';
    } else {
        return 'undefined';
    }
};
/*
 * open a new tab with instructions on how to change the browser's location settings
 * */
export const openLocationSettings = () => {
    const locationInstructionsURL = browserLocationInfo[getBrowserName()];
    if (locationInstructionsURL) {
        window.open(locationInstructionsURL, '_blank');
    } else {
        logger.warn('This browser is not supported');
    }
};
