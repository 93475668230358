import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { ButtonMore } from '@/components/ButtonMore';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { pendingLimitCountSelector, useUserLimits } from '@/feature/responsible-gaming/hooks/use-user-limits';
import { commonStyles } from '@/feature/responsible-gaming/screens/styles';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { AccountTabNavigationProp } from '@/navigation/types';

import { FindMoreResourcesRgr } from '../../components/FindMoreResourcesRgr';

type GamingLimitsScreenProps = {
    hasCloseIcon?: boolean;
};

export const GamingLimitsScreen = ({ hasCloseIcon = false }: GamingLimitsScreenProps) => {
    const { navigate } = useNavigation<AccountTabNavigationProp>();
    const { data: pendingDepositLimitCount } = useUserLimits({ select: pendingLimitCountSelector('DEPOSIT') });
    const { data: pendingEntryLimitCount } = useUserLimits({ select: pendingLimitCountSelector('WAGER') });
    const { data: pendingMaxSingleEntryLimitCount } = useUserLimits({
        select: pendingLimitCountSelector('SINGLE_WAGER'),
    });
    const { t } = useTranslation('rg');

    const settings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);
    const enabledRealityCheck = settings?.reality_check_enabled;

    return (
        <ScrollableScreen
            screenNavBarProps={{
                title: t('gaming_limits_screen_title'),
                closeIconMode: hasCloseIcon ? 'close' : undefined,
                handlePressTracking: () => {
                    BetrAnalytics.trackProductEvent(AnalyticsEvent.GAMING_LIMITS_ABORT);
                },
            }}
            scrollViewProps={{ style: [commonStyles.scrollView, { paddingHorizontal: 0 }] }}
        >
            <MaxWidthWrapper paddingHorizontal={'s16'} paddingBottom={'s20'}>
                <Text variant="bodySmall" color={'gray2'} testID="gamingLimitsMsg">
                    {t('gaming_limits_screen_description')}
                </Text>
                <SizedBox value={16} />
                <Text variant="titleLarge" color="white">
                    {t('limits')}
                </Text>
                <ButtonMore
                    label={t('deposit_limits')}
                    onPress={() => {
                        trackRG.gamingLimitScreen('Deposit Limits');
                        BetrAnalytics.trackProductEvent(AnalyticsEvent.DEPOSIT_LIMITS);
                        navigate('DepositLimits');
                    }}
                >
                    {pendingDepositLimitCount ? (
                        <Text fontSize={15} color={'gray2'}>
                            {pendingDepositLimitCount} {t('pending')}
                        </Text>
                    ) : null}
                </ButtonMore>

                <ButtonMore
                    label={t('play_limits')}
                    onPress={() => {
                        trackRG.gamingLimitScreen('Play Limits');
                        BetrAnalytics.trackProductEvent(AnalyticsEvent.PLAY_LIMITS);
                        navigate('EntryLimits');
                    }}
                >
                    {pendingEntryLimitCount ? (
                        <Text fontSize={15} color={'gray2'}>
                            {pendingEntryLimitCount} {t('pending')}
                        </Text>
                    ) : null}
                </ButtonMore>
                <ButtonMore
                    label={t('max_single_play_limit')}
                    onPress={() => {
                        trackRG.gamingLimitScreen('Max Single Entry Limit');
                        BetrAnalytics.trackProductEvent(AnalyticsEvent.MAX_SINGLE_PLAY_LIMITS);
                        navigate('MaxSingleEntryLimits');
                    }}
                >
                    {pendingMaxSingleEntryLimitCount ? (
                        <Text fontSize={15} color={'gray2'}>
                            {pendingMaxSingleEntryLimitCount} {t('pending')}
                        </Text>
                    ) : null}
                </ButtonMore>
                <ButtonMore
                    label={t('limits_history')}
                    onPress={() => {
                        trackRG.gamingLimitScreen('Limits History');
                        BetrAnalytics.trackProductEvent(AnalyticsEvent.LIMITS_HISTORY);
                        navigate('LimitsHistory');
                    }}
                />
                <ButtonMore
                    label={t('jurisdiction_limits')}
                    onPress={() => {
                        trackRG.gamingLimitScreen('State limits');
                        BetrAnalytics.trackProductEvent(AnalyticsEvent.STATE_LIMITS);
                        navigate('StateLimits');
                    }}
                />
                <SizedBox value={16} />
                <Text variant="titleLarge" color="white">
                    {t('alerts')}
                </Text>
                {enabledRealityCheck ? (
                    <ButtonMore
                        label={t('reality_check')}
                        onPress={() => {
                            trackRG.gamingLimitScreen('Reality Check');
                            BetrAnalytics.trackProductEvent(AnalyticsEvent.REALITY_CHECK);
                            navigate('RealityCheck');
                        }}
                    />
                ) : null}
                <SizedBox value={16} />
                <Text variant="titleLarge" color="white">
                    {t('breaks')}
                </Text>
                <ButtonMore
                    label={t('timeout')}
                    onPress={() => {
                        trackRG.gamingLimitScreen('Timeout');
                        BetrAnalytics.trackProductEvent(AnalyticsEvent.TIMEOUT_LIMITS);
                        navigate('Timeout');
                    }}
                />
                <ButtonMore
                    label={t('self_exclusion')}
                    onPress={() => {
                        trackRG.gamingLimitScreen('Self Exclusion');
                        BetrAnalytics.trackProductEvent(AnalyticsEvent.SELF_EXCLUSION);
                        navigate('SelfExclusionAdd');
                    }}
                />
                <FindMoreResourcesRgr />
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
