import React, { useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { useFocusEffect } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { PlayerAlertOverlay } from '@/components/PlayerAlertOverlay';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';
import { Box } from '@/components/lib/components/Box';
import { Modal } from '@/feature/alerts/components/Modal';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { BetslipPick, PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { nonRegularProjectionTypes } from '@/feature/lobby/utils/filters';
import { common, designSystem } from '@/styles/styles';
import { PICK_TYPE_LABEL } from '@/utils/constants';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { formatPlayerName } from '@/utils/format-player-name';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { formatNumber } from '@/utils/numeric/format';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { leagueConfigSelector, useLeagueConfigsStore } from '../hooks/use-league-configs-store';

const styles = StyleSheet.create({
    button: {
        paddingVertical: 10,
        borderWidth: 0,
    },
    text: { paddingVertical: 12 },
});

const modalId = 'restrictedCombo';

export type RestrictedComboModalRef = {
    show: (data: RestrictedComboProps) => void;
};

export type RestrictedComboProps = {
    errorCode: number;
    restrictedCombo: [BetslipPick, BetslipPick];
    removePlayers: (players: { eventId: string; player: PlayerWithTeam }[]) => void;
    message: string;
};
/**
 * Modal used to show 2 players from the PickSlip, that have a restricted combination
 */
export const RestrictedComboModal = React.forwardRef<RestrictedComboModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<RestrictedComboProps | undefined>(undefined);
    const modalRef = useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    return (
        <Modal modalRef={modalRef} id={modalId} dismissible={false} enableOverDrag={false}>
            {data ? <ModalContent {...data} modalRef={modalRef} /> : null}
        </Modal>
    );
});

const ModalContent = ({
    errorCode,
    restrictedCombo,
    removePlayers,
    modalRef,
    message,
}: RestrictedComboProps & {
    modalRef: React.RefObject<BottomSheetModal>;
}) => {
    const { t } = useTranslation('restricted_combo_modal');
    const [pick1, pick2] = restrictedCombo;
    const pick1Name = formatPlayerName(pick1.player);
    const pick1Team = pick1.player?.team;
    const pick2Name = formatPlayerName(pick2.player);
    const pick2Team = pick2.player?.team;
    const { leagueIcon: pick1LeagueIcon, leagueColor: pick1LeagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(pick1.player.league),
        defaultZustandCompareFunction
    );
    const { leagueIcon: pick2LeagueIcon, leagueColor: pick2LeagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(pick2.player.league),
        defaultZustandCompareFunction
    );
    const player1Number = getPlayerJerseyNumber(pick1.player.league, pick1.player?.jerseyNumber);
    const player2Number = getPlayerJerseyNumber(pick2.player.league, pick2.player?.jerseyNumber);

    useFocusEffect(
        useCallback(() => {
            BetrAnalytics.trackEvent(AnalyticsEvent.RESTRICTED_COMBINATION, {
                sport: pick1.player.league,
                errorCode: errorCode,
            });
        }, [errorCode, pick1.player.league])
    );

    return (
        <Box>
            <Box pt="s24" px="s24" pb="s8">
                <Box alignSelf="center">
                    <PlayerAlertOverlay
                        playerImage={pick1.player.icon}
                        secondPlayerImage={pick2.player.icon}
                        teamColor={pick1Team?.color || pick1LeagueColor}
                        secondTeamColor={pick2Team?.color || pick2LeagueColor}
                        teamImageUrl={pick1Team?.largeIcon || pick1LeagueIcon}
                        secondTeamImageUrl={pick2Team?.largeIcon || pick2LeagueIcon}
                        playerNumber={player1Number}
                        secondPlayerNumber={player2Number}
                    />
                </Box>
                <SizedBox value={16} />
                <Text variant="titleLarge" textAlign="center">
                    {t('title')}
                </Text>
                <SizedBox value={4} />
                <Text variant="bodyMedium" color="gray2" textAlign="center" testID="restrictedModalDescription">
                    {message}
                </Text>
            </Box>
            <Box padding="s16" pt="s0">
                <Row py="s16" justifyContent="space-between">
                    <Text variant="bodyMedium">{pick1Name}</Text>
                    <Text variant="bodyMedium" color={'white'}>
                        {getPickInfo(pick1)}
                    </Text>
                </Row>
                <LineSeparator style={{ backgroundColor: designSystem.colors.gray3, ...common.hairlineHeight }} />
                <Row py="s16" justifyContent="space-between">
                    <Text variant="bodyMedium">{pick2Name}</Text>
                    <Text variant="bodyMedium" color={'white'}>
                        {getPickInfo(pick2)}
                    </Text>
                </Row>
            </Box>
            <Box px="s16">
                <Button
                    label={t('keep') + ' ' + pick1Name}
                    type={'active'}
                    style={[common.full, styles.button]}
                    variant={'rounded'}
                    size={'medium'}
                    onPress={() => {
                        removePlayers([{ eventId: pick2.eventId, player: pick2.player }]);
                        modalRef.current?.dismiss();
                        BetrAnalytics.trackEvent(AnalyticsEvent.RESTRICTED_COMBINATION_KEEP_PICK, {
                            pickToKeep: pick1.player.id,
                        });
                    }}
                />
                <SizedBox value={16} />
                <Button
                    label={t('replace') + ' ' + pick2Name}
                    style={[common.full, styles.button]}
                    type={'activeGray'}
                    variant={'rounded'}
                    size={'medium'}
                    onPress={() => {
                        removePlayers([{ eventId: pick1.eventId, player: pick1.player }]);
                        modalRef.current?.dismiss();
                        BetrAnalytics.trackEvent(AnalyticsEvent.RESTRICTED_COMBINATION_REPLACE_PICK, {
                            pickToReplace: pick2.player.id,
                        });
                    }}
                    testID="replaceButton"
                />
            </Box>
        </Box>
    );
};

const getPickInfo = (pick: BetslipPick): string => {
    return `${PICK_TYPE_LABEL[pick.outcome]} ${formatNumber(
        nonRegularProjectionTypes.includes(pick.projection.type)
            ? pick.projection.nonRegularValue
            : pick.projection.value
    )} ${pick.projection.label}`;
};
