export const getNextValueOnNumberPress = (currentValue: string, numberPressed: number, charLimit = 10) => {
    // return if value is already 0
    if (currentValue === '0' && numberPressed === 0) {
        return currentValue;
    }

    // replace 0 with number
    if (currentValue === '0' && numberPressed !== 0) {
        return String(numberPressed);
    }

    // max 2 decimals
    if (currentValue.includes('.') && currentValue.split('.')[1].length === 2) {
        return currentValue;
    }

    if (currentValue.length >= charLimit) {
        return currentValue;
    }

    return currentValue + String(numberPressed);
};

export const getNextValueOnDecimalPress = (currentValue: string, decimal: '.') => {
    if (currentValue.length === 0) {
        return 0 + decimal;
    }
    // max 1 decimal point
    if (currentValue.includes('.')) {
        return currentValue;
    }
    return currentValue + decimal;
};

export const getNextValueOnBackspacePress = (currentValue: string) => {
    if (currentValue.length === 1) {
        return String(0);
    }
    return currentValue.slice(0, -1);
};

export const formatCommaSeparatedNumber = (value: string) => {
    const parts = value.split('.');
    const integer = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimal = value.includes('.') ? `.${parts[1]}` : '';
    return `${integer}${decimal}`;
};
