import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import MaintenanceLogo from '@/assets/icons/maintenance-logo';
import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useActiveProductConfig } from '@/hooks/use-active-product';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { useProductSwitchModal } from '@/hooks/use-product-switch';
import { useAppResumeEffect } from '@/hooks/use-resume';
import { useCoreNavigation } from '@/navigation/navigation';

const productTranslationMap = {
    [Product.Sportsbook]: 'sportsbook',
    [Product.Pickem]: 'picks',
} as const;

export const MaintenanceScreen = () => {
    const { openHome } = useCoreNavigation();
    const { t } = useTranslation('common');

    const productConfig = useActiveProductConfig();
    const { description, header } = productConfig?.appMaintenanceConfig?.primary || {};

    const { switchingAvailable, availableProducts, changeProduct } = useProductSwitchModal();
    const selectedProduct = useJurisdictionStore(state => state.product);

    const [otherAvailableProduct] = availableProducts.filter(item => item !== selectedProduct);

    usePreventAndroidBackNavigation();

    const productName = otherAvailableProduct !== Product.None ? t(productTranslationMap[otherAvailableProduct]) : '';

    const { enabled: isMaintenanceEnabled } = productConfig?.appMaintenanceConfig?.primary || {};

    const refetchSettings = useJurisdictionStore(store => store.actions.refetchAndUpdateSettings);
    useAppResumeEffect(
        useCallback(() => {
            refetchSettings();
        }, [refetchSettings])
    );

    useEffect(() => {
        if (!isMaintenanceEnabled) {
            //If maintenance is disabled, navigate back to the home screen
            openHome();
        }
    }, [isMaintenanceEnabled, openHome]);

    return (
        <Box flex={1} justifyContent="center" alignItems="center" paddingHorizontal={'s40'}>
            <Box paddingBottom="s16">
                <MaintenanceLogo height={80} width={80} />
            </Box>
            {header ? (
                <Text variant={'headlineMedium'} paddingBottom={'s8'} color={'gray1'}>
                    {header}
                </Text>
            ) : null}
            {description ? (
                <Text variant={'bodyMedium'} paddingBottom={'s8'} color={'gray2'} textAlign={'center'}>
                    {description}
                </Text>
            ) : null}
            {otherAvailableProduct !== Product.None && switchingAvailable ? (
                <Button
                    type={'text'}
                    label={t('go_to_product', { product: productName })}
                    onPress={() => changeProduct(otherAvailableProduct)}
                />
            ) : null}
        </Box>
    );
};
