import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';
import { LeaderboardsModalRef, P2pLeaderboardsModal } from '@/feature/p2p-leaderboards/components/P2pLeaderboardsModal';
import { Pool } from '@/types/api.generated';

type Leaderboards = {
    showLeaderboardsModal: (props: Pool[]) => void;
};

const LeaderboardsContext = React.createContext<Leaderboards>({
    showLeaderboardsModal: () => {},
});

/**
 * Provides access to the Modal used to display all p2p-leaderboards for group play
 */
export const P2pLeaderboardsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<LeaderboardsModalRef>(null);

    const showLeaderboardsModal = useCallback((props: Pool[]) => {
        modalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <LeaderboardsContext.Provider value={{ showLeaderboardsModal }}>
                {children}
                <P2pLeaderboardsModal ref={modalRef} />
            </LeaderboardsContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useLeaderboards = () => {
    return useContext(LeaderboardsContext);
};
