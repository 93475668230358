import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
        <Path
            fill="#EDEFF3"
            d="M12 7a9.77 9.77 0 0 1 8.82 5.5A9.77 9.77 0 0 1 12 18a9.77 9.77 0 0 1-8.82-5.5A9.77 9.77 0 0 1 12 7Zm0-2C7 5 2.73 8.11 1 12.5 2.73 16.89 7 20 12 20s9.27-3.11 11-7.5C21.27 8.11 17 5 12 5Zm0 5a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5Zm0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 17 12 17s4.5-2.02 4.5-4.5S14.48 8 12 8Z"
        />
    </Svg>
);
export default SvgComponent;
