import AsyncStorage from '@react-native-async-storage/async-storage';

import { PlayerInfoWithProjectionsFragment } from '@/api/events/query.generated';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface PlayerFilters {
    playerProjectionFilter: string | undefined;
    playerProjectionLabel: string | undefined;
    playerPositionFilter: string | undefined;
    playerPositionDescription: string | undefined;
    actions: {
        setPlayerProjectionFilter: (value: string, label: string) => void;
        filterProjectionsFunction: (player: PlayerInfoWithProjectionsFragment) => boolean;
        resetProjectionsFilter: () => void;
        setPlayerPositionFilter: (value: string, description: string) => void;
        filterPositionFunction: (player: PlayerInfoWithProjectionsFragment) => boolean;
        resetPositionFilter: () => void;
        resetAllFilters: () => void;
    };
}

const specialPositionFilter = (playerPositionFilter: string, playerPosition: string) => {
    return playerPositionFilter === 'P' && (playerPosition === 'RP' || playerPosition === 'SP');
};

export const usePlayerFiltersStore = create<PlayerFilters>()(
    persist(
        (set, get) => ({
            playerProjectionFilter: undefined,
            playerProjectionLabel: undefined,
            playerPositionFilter: undefined,
            playerPositionDescription: undefined,
            actions: {
                setPlayerProjectionFilter: (value: string, label: string) => {
                    set({ playerProjectionFilter: value, playerProjectionLabel: label });
                },
                filterProjectionsFunction: (player: PlayerInfoWithProjectionsFragment) => {
                    const { playerProjectionFilter } = get();

                    return (
                        !playerProjectionFilter ||
                        player.projections?.some(projection => projection.key === playerProjectionFilter)
                    );
                },
                resetProjectionsFilter: () => {
                    set({ playerProjectionFilter: undefined, playerProjectionLabel: undefined });
                },
                setPlayerPositionFilter: (value: string, description: string) => {
                    set({ playerPositionFilter: value, playerPositionDescription: description });
                },
                filterPositionFunction: (player: PlayerInfoWithProjectionsFragment) => {
                    const { playerPositionFilter } = get();

                    return (
                        !playerPositionFilter ||
                        player.position === playerPositionFilter ||
                        specialPositionFilter(playerPositionFilter, player.position)
                    );
                },
                resetPositionFilter: () => {
                    set({ playerPositionFilter: undefined, playerPositionDescription: undefined });
                },
                resetAllFilters: () => {
                    set({
                        playerPositionFilter: undefined,
                        playerPositionDescription: undefined,
                        playerProjectionFilter: undefined,
                        playerProjectionLabel: undefined,
                    });
                },
            },
        }),
        {
            name: 'playerFilters-storage',
            version: 2,
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, ...rest }: PlayerFilters) => rest,
        }
    )
);
