import { PaymentMethodType } from '@/feature/deposit/hooks/use-deposit-payment-methods';

export type APIResponsePaginationMeta = {
    current_page: number;
    from: number;
    to: number;
    total: number;
    last_page: number;
    path: string;
};

export type Action = Exclude<TransactionTypesType, 'all_types'>;

export type Transaction = {
    action: Action;
    amount: number;
    created_at: number;
    currency: string;
    payment_method: PaymentMethodType;
    status: string;
    transaction_id: string | null;
    user_wallet_id: string;
    cc_type: string | null;
    cc_last4: string | null;
    email?: string | null;
    failed_reason: string | null;
};

export const TransactionTypes = {
    ALL_TYPES: 'all_types',
    WITHDRAWAL: 'withdrawal',
    DEPOSIT: 'deposit',
    ADJUSTMENT: 'adjustment',
    BONUS: 'bonus',
    FRE_BONUS: 'fre_bonus',
    CREDIT: 'credit',
    DEBIT: 'debit',
} as const;

export type TransactionTypesType = (typeof TransactionTypes)[keyof typeof TransactionTypes];

export const TransactionDateOptions = {
    ALL_TIME: 'all_time',
    LAST_MONTH: 'last_month',
    LAST_THREE_MONTH: 'last_three_month',
    LAST_SIX_MONTH: 'last_six_month',
    LAST_YEAR: 'last_year',
    DATE_RANGE: 'date_range',
} as const;

export type TransactionDateOptionsType = (typeof TransactionDateOptions)[keyof typeof TransactionDateOptions];

export type APIResponseWallet = {
    total: string;
    real_amount: string;
    bonus_amount: string;
    free_amount: string;
    real_currency: string;
    next_top_up?: number;
};

export type TransactionActivityResponse = {
    date_from: string | null;
    date_to: string | null;
    type: TransactionTypesType | null;
    net_position: number;
    net_deposits: number;
    transactions: {
        data: Transaction[];
    } & APIResponsePaginationMeta;
};

export declare type TransactionActivityResponseWithMeta = {
    data: TransactionActivityResponse;
    amountWithoutBonus: string;
    inventoryCount: number;
    token: string;
    wallet: APIResponseWallet;
    walletAmount: string;
};

export type QueryParams = {
    dateFrom?: string;
    dateTo?: string;
    type?: TransactionTypesType;
};

export type TransactionTypeLabel = 'all_types' | 'deposits' | 'withdrawals' | 'betr_bucks' | 'play_through_cash';

export type TransactionDateLabel =
    | 'all_time'
    | 'last_month'
    | 'last_three_month'
    | 'last_six_month'
    | 'last_year'
    | 'by_date_range';

export type TransactionFilterOption<T> = {
    id: T;
    label: TransactionTypeLabel | TransactionDateLabel;
};
