import { NativeModules } from 'react-native';

const { AdvertisingModule, AndroidAdvertisingModule } = NativeModules;

/**
 * Retrieves the Identifier for Advertisers (IDFA) from the iOS device.
 *
 * @returns {Promise<string | undefined>} A promise that resolves with the IDFA as a string if available, or rejects with 'IDFA not found' if not available.
 */
export async function getIdfa(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
        AdvertisingModule.getIDFA((idfa: string) => {
            if (idfa) {
                resolve(idfa);
            } else {
                reject('IOS IDFA not found. Ensure tracking is allowed for the app.');
            }
        });
    });
}

/**
 * Retrieves the Google Advertising ID (GAID) from the Android device.
 *
 * @returns {Promise<string>} A promise that resolves with the GAID as a string or rejects with an error.
 */
export function getGaid(): Promise<string> {
    return new Promise((resolve, reject) => {
        AndroidAdvertisingModule.getAdvertisingId()
            .then((gaid: string) => resolve(gaid))
            .catch((error: any) => reject(error));
    });
}

/**
 * Checks if ad tracking is enabled on the Android device.
 *
 * @returns {Promise<boolean>} A promise that resolves with a boolean indicating whether ad tracking is enabled.
 */
export function getAndroidIsAdTrackingEnabled(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        AndroidAdvertisingModule.isLimitAdTrackingEnabled()
            .then((isAdTrackingEnabled: boolean) => resolve(isAdTrackingEnabled))
            .catch((error: any) => reject(error));
    });
}
