import { toLocaleCurrency } from '@/utils/numeric/currency';

import { activeLimitsSelector, useUserLimits } from './use-user-limits';

const MAX_SINGLE_ENTRY_AMOUNT = 1000000;

/**
 * Custom hook to return an error message if the user tries to set a max single play limit that is too high.
 * @returns {function} - function that returns an error message if the max single play limit is too high.
 */
export const useMaxSingleEntryErrorMessage = () => {
    const { data: activeEntryLimits } = useUserLimits({ select: activeLimitsSelector('WAGER') });

    return (amount: number) => {
        if (amount === 0) {
            return;
        }
        if (amount > MAX_SINGLE_ENTRY_AMOUNT) {
            return 'Max single play amount cannot be greater than $1,000,000';
        }

        if (activeEntryLimits && amount > activeEntryLimits[0]?.amount) {
            return `Max single play limit cannot be greater than the play limit (${toLocaleCurrency(
                activeEntryLimits[0].amount
            )})`;
        }
    };
};
