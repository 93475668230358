import React, { ReactElement } from 'react';

import RichText, { ParsedTypes } from '@/components/RichText';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

export const SelfExclusionDesc = (): ReactElement => {
    const { richTextsSlices } = useJurisdictionStore(state => ({
        richTextsSlices: state.jurisdictionSettings?.globalSettings?.richTextsSlices,
    }));

    const selfExclusionAddDescSlice = richTextsSlices?.SelfExclusionAddDesc || [];

    return (
        <RichText
            data={selfExclusionAddDescSlice}
            typeProps={{
                [ParsedTypes.P]: {
                    textAlign: 'left',
                },
            }}
        />
    );
};
