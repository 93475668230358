import { URLS } from '@/data';
import { EventDetails, League } from '@/feature/event-details-sbk/types';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { FloatingModalConfig } from '@/utils/floatingModal/FloatingModalProvider';

// MLB and NHL are not supported by Bet Genius
const disabledLeagues = ['MLB', 'NHL'];

const betGeniusAutoSelectTeamStats = `
(function() {
    setTimeout(() => {
        const targetText = 'TEAM STATS';
        const buttons = Array.from(document.querySelectorAll('button'));
        const targetButton = buttons.find(button => button.textContent.trim() === targetText);

        if (targetButton && 
            !targetButton.disabled && 
            window.getComputedStyle(targetButton).display !== 'none' && 
            window.getComputedStyle(targetButton).visibility !== 'hidden') {
                targetButton.click();
        } 
}, 1000); // Delay to ensure the page is fully loaded
return true;
})();
`;

const getInjectedJavaScript = (eventDetails: EventDetails, isBetGenius: boolean): string => {
    if (eventDetails.status?.toUpperCase() === 'FINISHED' && isBetGenius) {
        return betGeniusAutoSelectTeamStats;
    }
    return '';
};

export const useGameTrackerConfig = (league?: League, eventDetails?: EventDetails): FloatingModalConfig | undefined => {
    const enabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings?.game_tracker_enabled
    );
    const productName = useJurisdictionStore(
        store => store.jurisdictionSettings?.globalSettings?.keyValuePairs?.ApiKeys?.BET_GENIUS_PRODUCT_NAME ?? 'betr'
    );
    if (!enabled || !league || !eventDetails) {
        return;
    }
    const isLeagueDisabled = disabledLeagues.includes(league.name);
    if (isLeagueDisabled) {
        return;
    }
    const betGeniusId = eventDetails.suppliers?.find(supplier => supplier.feed === 'BETGENIUS')?.external_id;
    if (!betGeniusId) {
        return;
    }
    // If the betGeniusId is a negative number, it means the event id is invalid
    if (Number(betGeniusId) < 0) {
        return;
    }

    const isBetGenius = !!betGeniusId;

    return {
        url: `${URLS.BET_GENIUS_GAME_TRACKER_URL}?productName=${productName}&fixtureId=${betGeniusId}`,
        minimizedContentScale: 0.6,
        maximizedContentScale: 0.9,
        minimizedContentHeight: 180,
        maximizedContentHeight: 280,
        injectedJavaScript: isBetGenius ? getInjectedJavaScript(eventDetails, isBetGenius) : undefined,
    };
};
