import { createJurisdictionHeaders } from '@/data';
import { URLS } from '@/data/config';
import { user } from '@/hooks/use-user';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { userLimitsKeys } from './query-keys';
import { UserLimit, UserLimitConfirmation, UserLimitType, UserLimitsSchema } from './types';

type UseUserLimitsQueryOptions<TData> = UseQueryOptions<
    UserLimit[],
    unknown,
    TData,
    ReturnType<typeof userLimitsKeys.limits>
>;

/**
 * Fetches user limits from /auth/user-limits endpoint
 * @return {Promise<UserLimit[]>} - user limits array
 */
const getUserLimits = async (): Promise<UserLimit[]> => {
    const resp = await (
        await fetch(`${URLS.CHAMELON_API_URL}/auth/user-limits`, {
            method: 'GET',
            headers: createJurisdictionHeaders(),
        })
    ).json();
    return UserLimitsSchema.parse(resp.data);
};

/**
 * Custom hook for getting user limits
 * @param {Object} options - react-query options
 * @return {Object} - react-query query object
 */
export const useUserLimits = <TData = UserLimit[]>(options?: UseUserLimitsQueryOptions<TData>) => {
    return useQuery(userLimitsKeys.limits(), getUserLimits, {
        ...options,
        enabled: !user.sessionHasExpired(),
        staleTime: 10 * 60 * 1000, // 10 minutes
    });
};

/**
 * Selector for getting the count of pending limits
 * @param {string} type - limit type
 */
export const pendingLimitCountSelector = (type: UserLimitType) => (limits: UserLimit[]) =>
    limits.filter(limit => limit.type === type && limit.limit_goes_live_at > 0).length;

/**
 * Selector for getting the count of active limits
 * @param {string} type - limit type
 */
export const activeLimitsSelector = (type: UserLimitType) => (limits: UserLimit[]) =>
    limits.filter(limit => limit.type === type);

/**
 * Selector for getting the count of pending limits
 * @param {string} type - limit type
 */
export const pendingLimitsSelector = (type: UserLimitType) => (limits: UserLimit[]) =>
    limits.filter(limit => limit.type === type && limit.limit_goes_live_at > 0);

/**
 * Selector for if there are pending limits
 * @param {string} type - limit type
 */
export const hasPendingLimitsSelector = (type: UserLimitType) => (limits: UserLimit[]) =>
    pendingLimitsSelector(type)(limits).length > 0;

/**
 * Selector to get the pending limits while filtering out the limits that are already in confirmation state
 * Limits in confirmation states will also be displayed in pending state, we want to filter them out so that they are not displayed twice
 * @param {string} type - limit type
 * @param {UserLimitConfirmation[]} confirmationLimits - array of confirmation limits
 */
export const displayedPendingLimitsSelector =
    (type: UserLimitType, confirmationLimits?: UserLimitConfirmation[]) => (limits: UserLimit[]) =>
        pendingLimitsSelector(type)(limits).filter(
            limit =>
                !confirmationLimits?.find(
                    cl =>
                        cl.type === limit.type &&
                        cl.duration === limit.duration &&
                        cl.limit_goes_live_at === limit.limit_goes_live_at
                )
        );

export const limitsByTypesSelector = (types: UserLimitType[]) => (limits: UserLimit[]) => {
    return limits.filter(limit => types.includes(limit.type));
};
