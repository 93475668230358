import React, { useRef, useState } from 'react';
import { StyleSheet, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel';

import { Box } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';
import { GlobalConfigurationsDocumentDataBodyLaunchpromoSliceItem } from '@/types/prismic.generated';

import { LaunchCarouselItem } from './LaunchCarouselItem';

type LaunchCarouselProps = {
    launchPromos: GlobalConfigurationsDocumentDataBodyLaunchpromoSliceItem[];
};

const styles = StyleSheet.create({
    dot: {
        width: 8,
        height: 8,
        borderRadius: 60,
        marginHorizontal: 4,
        marginTop: 24,
    },
    activeDot: {
        backgroundColor: designSystem.colors.white,
    },
    inactiveDot: {
        backgroundColor: designSystem.colors.gray3,
    },
});

export const LaunchCarousel = ({ launchPromos }: LaunchCarouselProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const carouselRef = useRef<ICarouselInstance | null>(null);
    const { width } = useWindowDimensions();

    const renderCarouselItem = () => {
        return <LaunchCarouselItem launchPromos={launchPromos} activeIndex={activeIndex} />;
    };

    const handleDotPress = (index: number) => {
        setActiveIndex(index);
        carouselRef.current?.scrollTo({ index: index });
    };

    return (
        <Box flex={1} alignItems={'center'}>
            <Carousel
                ref={carouselRef}
                width={width}
                data={launchPromos}
                autoPlay={true}
                autoPlayInterval={5000} // 5 seconds
                scrollAnimationDuration={250}
                onSnapToItem={index => {
                    setActiveIndex(index);
                }}
                renderItem={renderCarouselItem}
            />

            <Box flexDirection={'row'} justifyContent={'center'} mb="s24">
                {launchPromos.map((_, index) => {
                    const styledDotState = activeIndex === index ? styles.activeDot : styles.inactiveDot;
                    return (
                        <TouchableOpacity key={`pagination-dot-${index}`} onPress={() => handleDotPress(index)}>
                            <View style={[styles.dot, styledDotState]} />
                        </TouchableOpacity>
                    );
                })}
            </Box>
        </Box>
    );
};
