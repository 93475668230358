import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import SubtractIcon from '@/assets/icons/subtract';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { resetToAccountScreen } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';

import { WithdrawalFrame } from '../components/WithdrawalFrame';
import { WithdrawalMethodItem } from '../components/WithdrawalMethodItem';
import { WithdrawMethod } from '../hooks/use-withdrawal-methods';

const styles = StyleSheet.create({
    separator: {
        borderBottomWidth: 1,
        borderBottomColor: designSystem.colors.gray5,
        width: '100%',
    },
    bottomContainer: { bottom: 0, backgroundColor: designSystem.colors.gray8 },
});

type NavigationChooseWithdrawalMethodProps = NativeStackScreenProps<RootStackParamList, 'ChooseWithdrawalMethod'>;

/**
 * View to show list of withdrawal methods
 * */
export const ChooseWithdrawalMethod = ({
    route: {
        params: { methods },
    },
}: NavigationChooseWithdrawalMethodProps) => {
    const navigation = useNavigation();
    const { t } = useTranslation(['wallet']);
    const { balance } = useActiveWallet();

    return (
        <WithdrawalFrame balance={balance}>
            <ScrollView bounces={false} contentContainerStyle={[common.alignCenter, common.paddingHorizontal]}>
                <MaxWidthWrapper>
                    <SizedBox value={26} />
                    <Text fontWeight="600" fontSize={22}>
                        Choose withdrawal method
                    </Text>
                    <SizedBox value={28} />

                    {methods?.map((method: WithdrawMethod, idx: number) => (
                        <View key={`${method.name}_${idx}`} style={common.full}>
                            {idx !== 0 ? <LineSeparator style={styles.separator} /> : null}
                            <WithdrawalMethodItem method={method} methods={methods} />
                        </View>
                    ))}

                    <SizedBox value={24} />
                    <Banner
                        leftIcon={<SubtractIcon />}
                        borderColor={'gray5'}
                        borderWidth={1}
                        message={t('wallet:withdrawal_choose_method_banner')}
                    />
                    <SizedBox value={16} />
                </MaxWidthWrapper>
            </ScrollView>
            <MaxWidthWrapper>
                <View style={[common.relative, common.full, styles.bottomContainer]}>
                    <LineSeparator style={styles.separator} />
                    <SizedBox value={16} />
                    <View style={[common.paddingHorizontal]}>
                        <Text fontWeight="500" fontSize={13} color={'gray2'} textAlign={'center'}>
                            Wondering about your withdrawal status?
                        </Text>
                        <SizedBox value={15} />
                        <Button
                            label="View Transaction History"
                            type="tertiary"
                            variant="rounded"
                            onPress={() => {
                                BetrAnalytics.trackProductEvent(AnalyticsEvent.WITHDRAW_VIEW_TRANSACTION_HISTORY);
                                resetToAccountScreen(navigation, 'TransactionHistory');
                            }}
                        />
                    </View>
                    <SizedBox value={50} />
                </View>
            </MaxWidthWrapper>
        </WithdrawalFrame>
    );
};
