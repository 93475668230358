import React from 'react';

import { useEventTime } from '@/components/EventTime';
import { SbkScoreboard } from '@/components/SbkScoreboard';
import { Box } from '@/components/lib/components';

import { Event } from '../../types';
import { SecondaryEventCard } from './SecondaryEventCard';

type SecondaryEventHudProps = {
    hudHeight: number;
    event: Event;
    showScoreboard?: boolean;
};

export const SecondaryEventHud = ({ hudHeight, event, showScoreboard }: SecondaryEventHudProps) => {
    const time = useEventTime(event);

    return (
        <Box height={hudHeight} paddingVertical="s16" paddingHorizontal={showScoreboard ? 's0' : 's16'}>
            {showScoreboard ? (
                <SbkScoreboard event={event} matchDetails={time} />
            ) : (
                <SecondaryEventCard event={event} />
            )}
        </Box>
    );
};
