import { ProducerStatus } from '@/feature/betslip-sbk/types';
import { OptionStatus } from '@/feature/event-details-sbk/types';

export const isOptionOpen = (
    optionStatus: OptionStatus,
    producerStatus: ProducerStatus,
    isMarketPublished: boolean
) => {
    return optionStatus === 'OPENED' && producerStatus === 'UP' && isMarketPublished;
};

export const isOptionSuspended = (
    optionStatus: OptionStatus,
    producerStatus: ProducerStatus,
    isMarketPublished: boolean
) => {
    return optionStatus === 'SUSPENDED' || producerStatus === 'DOWN' || !isMarketPublished;
};
