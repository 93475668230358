import React from 'react';

import Alert from '@/assets/icons/alert';
import Checkmark from '@/assets/icons/checkmark';
import Loading from '@/assets/icons/loading';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

import { TRANSACTION_REQUEST_STATUS } from '../const';
import { TransactionRequestStatusType } from '../hooks/use-transaction-details';

const ICON_WIDTH_HEIGHT = 12;

type StatusRowProps = {
    status: TransactionRequestStatusType;
    label: string;
    completedAt?: string;
};

const StatusIcon = ({ status }: { status: TransactionRequestStatusType }) => {
    switch (status) {
        case TRANSACTION_REQUEST_STATUS.SUCCESS:
            return (
                <Checkmark
                    width={ICON_WIDTH_HEIGHT}
                    height={ICON_WIDTH_HEIGHT}
                    color={designSystem.colors.green}
                    testID="checkMark"
                />
            );
        case TRANSACTION_REQUEST_STATUS.PENDING:
            return <Loading width={ICON_WIDTH_HEIGHT} height={ICON_WIDTH_HEIGHT} color={designSystem.colors.gray1} />;
        case TRANSACTION_REQUEST_STATUS.FAILED:
            return (
                <Alert
                    width={ICON_WIDTH_HEIGHT}
                    height={ICON_WIDTH_HEIGHT}
                    color={designSystem.colors.red}
                    testID="alertIcon"
                />
            );
        default:
            return null;
    }
};

export const StatusRow = ({ status, label, completedAt }: StatusRowProps) => {
    return (
        <Row alignItems="center">
            <Box pr={status === 'NONE' ? 's16' : 's4'}>
                <StatusIcon status={status} />
            </Box>
            <Text
                color={status === TRANSACTION_REQUEST_STATUS.SUCCESS ? undefined : 'gray3'}
                testID={`txnStatus-${label}`}
            >
                {label}
            </Text>
            {completedAt ? <Text> - </Text> : null}
            <Text>{completedAt}</Text>
        </Row>
    );
};

export const TransactionRequestStatus = ({ transaction }: { transaction: any }) => {
    return (
        <Column mb="s16">
            {transaction.requestStatus.map((item: any) => (
                <StatusRow key={item.label} {...item} />
            ))}
        </Column>
    );
};
