export type GooglePlaceDetails = google.maps.places.PlaceResult;
export type GooglePlaceAddressComponents = google.maps.GeocoderAddressComponent[];

export type PlaceComponents = {
    formattedAddress: string;
    streetNumber: string;
    streetName: string;
    city: string;
    state: string;
    stateShort: string;
    country: string;
    countryShort: string;
    postalCode: string;
    postalTown: string;
};

const findComponent = (parameterKey: string, addressComponentArray: GooglePlaceAddressComponents = []) => {
    const foundComponent = addressComponentArray?.find(component => component.types.includes(parameterKey));
    return foundComponent;
};

const isPostalCodeOnlyType = (place: GooglePlaceDetails) =>
    new Set(place.types).size === 1 && place.types?.includes('postal_code');

export const googlePlaceAddressComponents: (place: GooglePlaceDetails) => PlaceComponents = place => {
    const postalCode = findComponent('postal_code', place.address_components);
    const country = findComponent('country', place.address_components);
    const city =
        findComponent('locality', place.address_components)?.long_name ||
        findComponent('administrative_area_level_2', place.address_components)?.long_name;
    const streetName = findComponent('route', place.address_components);
    const streetNumber = findComponent('street_number', place.address_components);
    const postalTown = findComponent('postal_town', place.address_components);
    const state = findComponent('administrative_area_level_1', place.address_components);

    return {
        formattedAddress: (!isPostalCodeOnlyType(place) ? place.formatted_address : undefined) || '',
        streetNumber: streetNumber?.long_name || '',
        streetName: streetName?.long_name || '',
        city: city || '',
        state: state?.long_name || '',
        stateShort: state?.short_name || '',
        country: country?.long_name || '',
        countryShort: country?.short_name || '',
        postalCode: postalCode?.long_name || '',
        postalTown: postalTown?.long_name || '',
    };
};
