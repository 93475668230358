import React from 'react';

import DecreaseIcon from '@/assets/icons/decrease-indicator';
import IncreaseIcon from '@/assets/icons/increase-indicator';
import { AnimatedFadeOutView } from '@/components/AnimatedFadeOutView';

type AnimatedOddsChangeIconProps = {
    hasOddsChanged: boolean;
    previousOdds: number | null;
    odds: number | null;
};

export const AnimatedOddsChangeIcon = ({ hasOddsChanged, previousOdds, odds }: AnimatedOddsChangeIconProps) => {
    if (!hasOddsChanged || !previousOdds || !odds) {
        return null;
    }

    return (
        <AnimatedFadeOutView>
            {odds > previousOdds ? <IncreaseIcon /> : null}
            {odds < previousOdds ? <DecreaseIcon /> : null}
        </AnimatedFadeOutView>
    );
};
