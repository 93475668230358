import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { designSystem, withOpacity } from '@/styles/styles';

export const BadgeStatus = {
    Open: 'open',
    Live: 'live',
    Voided: 'voided',
    Won: 'won',
    Lost: 'lost',
} as const;

export type BadgeStatus = (typeof BadgeStatus)[keyof typeof BadgeStatus];

export type StatusBadgeProps = {
    status: BadgeStatus;
};

const statusProps = {
    [BadgeStatus.Open]: {
        bgColor: designSystem.colors.gray6,
        textColor: 'gray1',
    },
    [BadgeStatus.Live]: {
        bgColor: withOpacity(designSystem.colors.purple1, 0.16),
        textColor: 'purple1',
    },
    [BadgeStatus.Voided]: {
        bgColor: designSystem.colors.gray6,
        textColor: 'gray3',
    },
    [BadgeStatus.Won]: {
        bgColor: withOpacity(designSystem.colors.green, 0.16),
        textColor: 'green',
    },
    [BadgeStatus.Lost]: {
        bgColor: withOpacity(designSystem.colors.red, 0.16),
        textColor: 'red',
    },
} as const;

export const StatusBadge = ({ status }: StatusBadgeProps) => {
    const { t } = useTranslation('bets');
    const { bgColor, textColor } = statusProps[status];
    return (
        <Box
            borderRadius="r8"
            paddingVertical="s4"
            paddingHorizontal="s8"
            alignItems="center"
            style={{ backgroundColor: bgColor }}
        >
            <Text color={textColor} variant="labelMedium" textTransform="capitalize">
                {t(status)}
            </Text>
        </Box>
    );
};
