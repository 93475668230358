import { queryClient } from '@/data';
import { SBK_BETSLIP_LOG_TAG, initialState } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { getSgpOdds } from '@/feature/betslip-sbk/utils/get-sgp-odds';
import { eventKeys, getEvent } from '@/feature/event-details-sbk/hooks/use-event';
import { AcceptMultiplierChanges, defaultUserSettings } from '@/hooks/use-auth-user-settings';
import { Currency } from '@/types/api.generated';
import { logger } from '@/utils/logging';
import { runStoreUpdate } from '@/utils/zustand';
import { CancelledError } from '@tanstack/react-query';

import {
    BetType,
    SBKBetSlip,
    SgpBetError,
    StakeInputError,
    sgpConflictingErrorCodeSchema,
    sgpDisabledErrorCodeSchema,
    sgpTemporaryErrorCodeSchema,
} from '../types';
import { generateSgpOddsId } from './betslip-utils';

/**
 * Remove all selections for betslip
 */
export const clearBetSlip = () => ({
    ...initialState,
});

/**
 * Toggle selection status. The status is the current state of the selection, either true (on) or false (off).
 */
export const toggleComboSelectionStatus = (state: SBKBetSlip, selectionId: string) => {
    const newState = { ...state };
    const hasSgpError = state.sgpEventDisabled[state.selections[selectionId].eventId];
    const isTogglingOn = !state.selections[selectionId].isComboEnabled;
    if (hasSgpError && isTogglingOn) {
        return forceToggleOnlySelection(newState, selectionId);
    } else {
        return {
            ...state,
            lastToggledSelectionId: selectionId,
            selections: {
                ...state.selections,
                [selectionId]: {
                    ...state.selections[selectionId],
                    isComboEnabled: !state.selections[selectionId].isComboEnabled,
                },
            },
            sgpTemporaryIssue: {
                ...state.sgpTemporaryIssue,
                [state.selections[selectionId].eventId]: false,
            },
        };
    }
};

const forceToggleOnlySelection = (state: SBKBetSlip, selectionId: string) => {
    const eventId = state.selections[selectionId].eventId;
    const eventSelections = Object.values(state.selections).filter(s => s.eventId === eventId);
    const newSelections = eventSelections.reduce((acc, s) => {
        return {
            ...acc,
            [s.id]: {
                ...s,
                isComboEnabled: s.id === selectionId,
            },
        };
    }, {});
    return {
        ...state,
        selections: {
            ...state.selections,
            ...newSelections,
        },
    };
};

/**
 * Update Stake
 */
export const updateStake = (
    state: SBKBetSlip,
    betId: string,
    stake: number,
    displayStake: string,
    betType: BetType
) => {
    return {
        ...state,
        bets: {
            ...state.bets,
            [betId]: {
                ...state.bets[betId],
                id: betId,
                betType,
                stake,
                displayStake,
                isBetrBucks: stake === 0 ? undefined : state.useBetrBucks, // when stake is 0, reset isBetrBucks
            },
        },
    };
};

/**
 * Update Stake Currency
 */
export const updateStakeCurrency = (state: SBKBetSlip, betId: string, currency: Currency) => {
    return {
        ...state,
        bets: {
            ...state.bets,
            [betId]: {
                ...state.bets[betId],
                isBetrBucks: currency === Currency.Fre,
            },
        },
    };
};

/**
 * Update Stake Input Errors
 */
export const updateStakeInputErrors = (state: SBKBetSlip, betId: string, stakeInputError?: StakeInputError) => {
    if (!state.bets[betId]) {
        return state;
    }
    return {
        ...state,
        bets: {
            ...state.bets,
            [betId]: {
                ...state.bets[betId],
                stakeInputError,
            },
        },
    };
};

type PayloadProps = {
    event_id: string;
    bets: {
        side_bet_id: string;
        side_bet_option_id: string;
    }[];
};

const sgpOddsKeys = {
    all: ['sgpOdds'] as const,
    sgpOdds: (payload: PayloadProps) => [...sgpOddsKeys.all, payload] as const,
};

/**
 * Update SGP odds depending on the selections that are toggled on.
 *
 * If the event is valid SGP, getSgpOdds will return an odds that is of type number.
 * If the event is SGP error, getSgpOdds will return false for this combination of selections
 * and add the event into sgpEventDisabled
 * If SGP is temporary down, it will toggle off all selections and show an warning message
 */
export const updateSgpOdds = async ({
    state,
    eventId,
    selectionIds,
    onFetchingStart,
    shouldHandleOddsChangeFlow = false,
    forceUpdate = false,
}: {
    state: SBKBetSlip;
    eventId: string;
    selectionIds: string[];
    onFetchingStart: () => void;
    shouldHandleOddsChangeFlow?: boolean;
    forceUpdate?: boolean;
}): Promise<SBKBetSlip> => {
    const id = generateSgpOddsId(selectionIds, eventId);
    const sgpOdds = { ...state.sgpOdds };

    if (state.sgpEventDisabled[eventId]) {
        return state;
    }

    try {
        const payload = {
            event_id: eventId,
            bets: selectionIds.map(selectionId => {
                return {
                    side_bet_id: state.selections[selectionId].marketId,
                    side_bet_option_id: selectionId,
                };
            }),
        };
        await queryClient.cancelQueries(sgpOddsKeys.all);
        if (forceUpdate) {
            await queryClient.invalidateQueries(sgpOddsKeys.sgpOdds(payload));
        }
        const resp = await queryClient.fetchQuery(
            sgpOddsKeys.sgpOdds(payload),
            () => getSgpOdds(payload, onFetchingStart),
            {
                staleTime: 30 * 1000, // Cache for 30 seconds
                retry: (failureCount, err) => {
                    if (err instanceof Error && sgpTemporaryErrorCodeSchema.safeParse(err.message).success) {
                        return failureCount < 3;
                    }
                    return false;
                }, // Retry 3 times if it is temporary issue
                retryDelay: 1000,
            }
        );
        const shouldUpdateOddsChanges = sgpOdds[id] && sgpOdds[id] !== resp.data.odds && shouldHandleOddsChangeFlow;

        return {
            ...state,
            sgpOddsChanges: shouldUpdateOddsChanges
                ? {
                      ...state.sgpOddsChanges,
                      [id]: sgpOdds[id] as number,
                  }
                : { ...state.sgpOddsChanges },
            sgpOdds: {
                ...sgpOdds,
                [id]: resp.data.odds,
            },
            sgpEventDisabled: {
                ...state.sgpEventDisabled,
                [eventId]: false,
            },
        };
    } catch (e: unknown) {
        // cancelled request
        if (e instanceof CancelledError && e.revert) {
            return state;
        }
        // SGP temporary issue warning
        if (e instanceof SgpBetError && sgpTemporaryErrorCodeSchema.safeParse(e.code).success) {
            return {
                ...state,
                sgpTemporaryIssue: {
                    ...state.sgpTemporaryIssue,
                    [eventId]: true,
                },
            };
        }

        // conflicting selection warning
        if (e instanceof SgpBetError && sgpConflictingErrorCodeSchema.safeParse(e.code).success) {
            return {
                ...state,
                sgpOdds: {
                    ...sgpOdds,
                    [id]: false,
                },
            };
        }

        // SGP Service is down or not enabled, disable SGP for this event
        if (e instanceof SgpBetError && sgpDisabledErrorCodeSchema.safeParse(e.code).success) {
            return {
                ...state,
                sgpEventDisabled: {
                    ...state.sgpEventDisabled,
                    [eventId]: true,
                },
            };
        }
        // SGP temporary issue warning
        return {
            ...state,
            sgpTemporaryIssue: {
                ...state.sgpTemporaryIssue,
                [eventId]: true,
            },
        };
    }
};

/**
 * Handles Single Game Parlays (SGP) error logic.
 *
 * If the event is SGP error, it will default to the first selection toggled on, while
 * the rest will be toggled off.
 */
export const handleSgpError = (state: SBKBetSlip, selectionIds: string[]): SBKBetSlip => {
    // order selections by selectionOrder
    const orderedSelectionIds = selectionIds.sort(
        (a, b) => state.selectionOrder.indexOf(a) - state.selectionOrder.indexOf(b)
    );
    const selections = orderedSelectionIds.reduce((acc, id, index) => {
        return {
            ...acc,
            [id]: {
                ...state.selections[id],
                isComboEnabled: index === 0,
            },
        };
    }, {});

    return {
        ...state,
        selections: {
            ...state.selections,
            ...selections,
        },
    };
};

/**
 * Toggle off all selections for the event that has SGP temporarily goes down.
 */
export const handleSgpToggleOff = (state: SBKBetSlip, eventId: string): SBKBetSlip => {
    const selections = Object.values(state.selections).reduce((acc, s) => {
        if (s.eventId === eventId) {
            return {
                ...acc,
                [s.id]: {
                    ...s,
                    isComboEnabled: false,
                },
            };
        }
        return acc;
    }, {});

    return {
        ...state,
        selections: {
            ...state.selections,
            ...selections,
        },
    };
};

/**
 * For the two following cases:
 *  1. User has accepted all odds changes enabled
 *  2. User has higher odds changes enabled and the current odds change match the condition
 * The function will return the value to simulate show/hide animation for the odds change indicator for 2 seconds.
 * Otherwise it will return null (no animation show).
 */
export const getOddsChangeIndicatorTimeout = ({
    state,
    oddsChanges,
}: {
    state: SBKBetSlip;
    oddsChanges: { [x: string]: number };
}) => {
    let timeout = null;
    if (Object.keys(oddsChanges).length > 0) {
        if (state.oddsChangeTimeout) {
            clearTimeout(state.oddsChangeTimeout);
        }
        const { userSettings = defaultUserSettings } = state;
        timeout = setTimeout(() => {
            if (userSettings?.accept_multiplier_changes === AcceptMultiplierChanges.ALL) {
                runStoreUpdate(() => state.actions.clearOddsChanges());
            }
            if (userSettings?.accept_multiplier_changes === AcceptMultiplierChanges.HIGHER) {
                runStoreUpdate(() => state.actions.clearHigherOddsChanges());
            }
        }, 2000);
    }
    return timeout;
};

/**
 * Fetches an event using react-query
 * @param eventId - The event id
 */
export const getEventDetails = async (eventId: string) => {
    try {
        return await queryClient.fetchQuery(eventKeys.byId(eventId), () => getEvent(eventId));
    } catch (e) {
        logger.warn(SBK_BETSLIP_LOG_TAG, `Failed to fetch event ${eventId}`, e);
    }
};
