import React from 'react';

import { AnimatedNumber } from '@/components/AnimatedNumber';
import { TextProps } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';

import { useIsOddsBetrBucks } from '../hooks/betslip-hooks';
import { AnimatedOddsChangeIcon } from './AnimatedOddsChangeIcon';

type BetSlipOddsProps = {
    betId: string;
    odds: number;
    previousOdds: number | null;
    hasOddsChanged: boolean;
} & TextProps;

export const BetSlipOdds = ({ betId, odds, previousOdds, hasOddsChanged, ...textProps }: BetSlipOddsProps) => {
    const isBetrBucks = useIsOddsBetrBucks(betId);

    return (
        <Box flexDirection="row" alignItems="center">
            <Box width={10} height={8}>
                <AnimatedOddsChangeIcon hasOddsChanged={hasOddsChanged} previousOdds={previousOdds} odds={odds} />
            </Box>
            <AnimatedNumber value={isBetrBucks ? odds - 1 : odds} {...textProps} appendText="x" />
        </Box>
    );
};
