import { useRef } from 'react';
import { TextInput } from 'react-native';

export const useFormFields = <T extends string>() => {
    const inputRef = useRef<{ [key in T]?: TextInput }>({}).current;

    const saveField = (key: T) => (r: TextInput) => {
        inputRef[key] = r;
    };

    const focusField = (field: T) => () => {
        inputRef[field]?.focus();
    };

    return { saveField, focusField };
};
