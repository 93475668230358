import React, { ReactNode, useMemo } from 'react';
import { StyleSheet } from 'react-native';

import Dot from '@/assets/icons/dot';
import FootballIcon from '@/assets/icons/footballIcon';
import Shield from '@/assets/icons/shield';
import TriangleLeft from '@/assets/icons/triangleLeft';
import { EventTime } from '@/components/EventTime';
import { LiveEventStatus } from '@/components/LiveEventStatus';
import { TeamScore } from '@/components/TeamScore';
import { Text } from '@/components/TextComponent';
import { Box, Image, Row } from '@/components/lib/components';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import {
    useBaseballPitchCount,
    useEventStatus,
    useEventWinningSide,
    useFootballPossession,
} from '@/hooks/use-sbk-event-details-cache';
import { designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';
import { getSportKey } from '@/utils/get-sport-key';

import { Event } from '../types';
import { getSportHeight } from '../utils/get-hud-height';
import { getTeamColors } from '../utils/get-team-colors';
import { HudTeamSpotlight } from './HudTeamSpotlight';

export type EventHubDetailsProps = {
    event: Event;
};

type EventHudDefaultProps = {
    matchSportSpecificDetails?: ReactNode;
    awayTeamIndicator?: ReactNode;
    homeTeamIndicator?: ReactNode;
} & EventHubDetailsProps;

export const LOGO_HEIGHT_WIDTH = 40;

const Divider = () => {
    return <Box width={8} height={2} backgroundColor="gray1" />;
};

export const EventHudDetails = ({ event }: EventHubDetailsProps) => {
    // ! add here other "custom" huds for each sport which requires a different one
    switch (getSportKey(event.sport)) {
        case 'american_football':
            return <EventHudFootball event={event} />;
        case 'baseball':
            return <EventHudBaseball event={event} />;
    }

    // ! Basketball and hockey use the default one
    return <EventHudDefault event={event} />;
};

const FootballPossession = () => (
    <Box paddingTop="s4">
        <FootballIcon color={designSystem.colors.white} opacity={0.4} />
    </Box>
);

const WinTriangle = ({ reversed }: { reversed: boolean }) => (
    <Box style={reversed ? styles.rightTriangle : {}}>
        <TriangleLeft />
    </Box>
);

const EventHudFootball = ({ event }: EventHubDetailsProps) => {
    const eventStatus = useEventStatus(event.id) ?? event.status;
    const ballPossession = useFootballPossession(event);
    const isLive = eventStatus === 'LIVE';
    const showHomeTeamPossession = isLive && ballPossession === 'HOME';
    const showAwayTeamPossession = isLive && ballPossession === 'AWAY';

    return (
        <EventHudDefault
            event={event}
            awayTeamIndicator={showAwayTeamPossession ? <FootballPossession /> : null}
            homeTeamIndicator={showHomeTeamPossession ? <FootballPossession /> : null}
            matchSportSpecificDetails={
                <LiveEventStatus event={event} color="white" opacity={0.6} variant="bodySmall" />
            }
        />
    );
};

const EventHudBaseball = ({ event }: EventHubDetailsProps) => {
    const eventStatus = useEventStatus(event.id) ?? event.status;
    const isLive = eventStatus === 'LIVE';
    const pitchCountAwayTeam = '';
    const pitchCountHomeTeam = '';

    const showPitchCountAwayTeam = isLive && pitchCountAwayTeam;
    const showPitchCountHomeTeam = isLive && pitchCountHomeTeam;

    const globalSettings = useJurisdictionStore(store => store.jurisdictionSettings?.globalSettings?.featureFlags);
    // Feature to display the amount of balls, strikes and outs in the event hud
    const isBSOEnabled = globalSettings?.baseball_bso_enabled?.enabled ?? false;

    return (
        <EventHudDefault
            event={event}
            awayTeamIndicator={
                showPitchCountAwayTeam ? (
                    <Text variant="bodySmall" color={'gray2'}>{`P: ${pitchCountAwayTeam}`}</Text>
                ) : null
            }
            homeTeamIndicator={
                showPitchCountHomeTeam ? (
                    <Text variant="bodySmall" color={'gray2'}>{`P: ${pitchCountHomeTeam}`}</Text>
                ) : null
            }
            matchSportSpecificDetails={isLive && isBSOEnabled ? <BaseballPitchCount event={event} /> : null}
        />
    );
};

const BetweenScoreSymbols = ({ symbol }: { symbol: string }) => {
    return (
        <Box
            width={26}
            height={24}
            borderWidth={1}
            borderRadius="r8"
            alignItems="center"
            justifyContent="center"
            style={styles.borderColor}
        >
            <Text variant="bodySmall">{symbol}</Text>
        </Box>
    );
};

const EventHudDefault = ({
    event: { home_team: homeTeam, away_team: awayTeam },
    event,
    awayTeamIndicator,
    homeTeamIndicator,
    matchSportSpecificDetails,
}: EventHudDefaultProps) => {
    const eventStatus = useEventStatus(event.id) ?? event.status;
    const winningSide = useEventWinningSide(event);
    const { homePrimary, awayPrimary, homeSecondary, awaySecondary, shouldShowFallbackLogo } = getTeamColors(event);

    const height = getSportHeight(eventStatus, event.sport);

    const isPreGame = eventStatus === 'NOTSTARTED' || eventStatus === 'UNKNOWN'; // defaults to pregame if unkwown
    const isFinished = eventStatus === 'FINISHED';
    const homeWin = winningSide === 'home';
    const awayWin = winningSide === 'away';
    const draw = winningSide === 'draw';

    return (
        <Box paddingHorizontal="s32" position="relative" height={height}>
            {isWeb && (
                <>
                    <HudTeamSpotlight type="away" primaryColor={awayPrimary} secondaryColor={awaySecondary} />
                    <HudTeamSpotlight type="home" primaryColor={homePrimary} secondaryColor={homeSecondary} />
                </>
            )}
            <Row flex={1}>
                <Box paddingTop="s16" alignItems={'center'}>
                    {shouldShowFallbackLogo ? (
                        <Shield
                            primary={awayPrimary}
                            secondary={awaySecondary}
                            width={LOGO_HEIGHT_WIDTH}
                            height={LOGO_HEIGHT_WIDTH}
                        />
                    ) : (
                        <Image source={{ uri: awayTeam?.logo_url }} style={styles.logo} />
                    )}
                    <Text
                        marginTop="s4"
                        variant="titleSmall"
                        color={!isFinished || draw || awayWin ? 'gray1' : 'white'}
                        opacity={homeWin ? 0.6 : 1}
                        testID="awayTeam"
                    >
                        {awayTeam.short_code}
                    </Text>
                    {awayTeamIndicator}
                </Box>
                <Box flex={1}>
                    {isPreGame ? (
                        <Box flex={1} alignItems={'center'} paddingTop={'s24'}>
                            <BetweenScoreSymbols symbol="@" />
                            <EventTime event={event} variant="bodySmall" marginTop="s14" color="white" opacity={0.6} />
                        </Box>
                    ) : (
                        <Box flex={1} paddingTop="s16">
                            <Row height={LOGO_HEIGHT_WIDTH} alignItems={'center'}>
                                <Box flex={1} alignItems={'center'} justifyContent={'center'}>
                                    <TeamScore
                                        event={event}
                                        side="away"
                                        lineHeight={28}
                                        fontSize={32}
                                        fontFamily={'Oswald-SemiBold'}
                                        paddingTop={'s14'}
                                    />
                                </Box>
                                {isFinished && !draw ? <WinTriangle reversed={homeWin} /> : <Divider />}
                                <Box flex={1} alignItems={'center'} justifyContent={'center'}>
                                    <TeamScore
                                        event={event}
                                        side="home"
                                        lineHeight={28}
                                        fontSize={32}
                                        fontFamily={'Oswald-SemiBold'}
                                        paddingTop={'s14'}
                                    />
                                </Box>
                            </Row>
                            <Box flex={1} alignItems={'center'}>
                                <EventTime
                                    event={event}
                                    variant="bodySmall"
                                    marginTop="s4"
                                    color={isFinished ? 'gray2' : 'gray1'}
                                />
                                {matchSportSpecificDetails}
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box paddingTop="s16" alignItems={'center'}>
                    {shouldShowFallbackLogo ? (
                        <Shield
                            primary={homePrimary}
                            secondary={homeSecondary}
                            width={LOGO_HEIGHT_WIDTH}
                            height={LOGO_HEIGHT_WIDTH}
                        />
                    ) : (
                        <Image source={{ uri: homeTeam.logo_url }} style={styles.logo} />
                    )}
                    <Text
                        variant="titleSmall"
                        marginTop="s4"
                        color={!isFinished || draw || homeWin ? 'gray1' : 'white'}
                        opacity={awayWin ? 0.6 : 1}
                        testID="homeTeam"
                    >
                        {homeTeam.short_code}
                    </Text>
                    {homeTeamIndicator}
                </Box>
            </Row>
        </Box>
    );
};

const getDotColor = (activeValue: number, dotValue: number) =>
    activeValue > dotValue ? designSystem.colors.gray1 : designSystem.colors.white;

const getDotOpacity = (activeValue: number, dotValue: number) => (activeValue > dotValue ? 1 : 0.2);

const Dots = ({ activeValue, numberOfDots, type }: { activeValue: number; numberOfDots: number; type: string }) => {
    const dotsValues = useMemo(() => [...Array(numberOfDots).keys()], [numberOfDots]);
    return (
        <>
            {dotsValues.map(dot => (
                <Box marginRight={dot === dotsValues.length - 1 ? 's0' : 's2'} key={`${type}-${activeValue}-${dot}`}>
                    <Dot color={getDotColor(activeValue, dot)} opacity={getDotOpacity(activeValue, dot)} />
                </Box>
            ))}
        </>
    );
};

const BaseballPitchCount = ({ event }: { event: Event }) => {
    const { balls, strikes, outs } = useBaseballPitchCount(event);
    return (
        <Row>
            <Row marginRight={'s8'} alignItems={'center'}>
                <Text marginRight={'s4'}>B</Text>
                <Dots activeValue={balls} numberOfDots={3} type={'balls'} />
            </Row>
            <Row marginRight={'s8'} alignItems={'center'}>
                <Text marginRight={'s4'}>S</Text>
                <Dots activeValue={strikes} numberOfDots={2} type={'strikes'} />
            </Row>
            <Row alignItems={'center'}>
                <Text marginRight={'s4'}>O</Text>
                <Dots activeValue={outs} numberOfDots={2} type={'outs'} />
            </Row>
        </Row>
    );
};

const styles = StyleSheet.create({
    logo: {
        width: LOGO_HEIGHT_WIDTH,
        height: LOGO_HEIGHT_WIDTH,
    },
    rightTriangle: {
        transform: [
            {
                rotate: '180deg',
            },
        ],
    },
    borderColor: {
        borderColor: 'rgba(255,255,255,0.12)',
    },
});
