import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { useSubscribeMultipleEventPublic } from '@/utils/websocket/hooks';

export const useSubscribeEventsMatchUpdate = (eventIds: string[], enabled?: boolean) => {
    const handleMatchUpdate = useEventDetailsCache(state => state.actions.handleMatchUpdate);

    useSubscribeMultipleEventPublic<'eventMatchUpdate'>(
        'event:{eventId}',
        'match_update',
        eventIds,
        handleMatchUpdate,
        enabled
    );
};
