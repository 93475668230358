import { BetslipPick } from '@/feature/betslip-pickem/types';
import { EntryRulesOutput, GameMode, ProjectionType } from '@/types/api.generated';
import { TFunction } from 'i18next';

import { BetValidationData } from '../api/types';

export const getGameModeError = (
    betslip: BetslipPick[],
    allEntryRules: EntryRulesOutput[],
    data: BetValidationData | undefined
) => {
    if (!data) {
        return { error: 'Not available at this time', disabled: true };
    }

    const rules = allEntryRules.find(it => it.id === data.entryRulesId);
    if (!rules) {
        return { error: 'Not available at this time', disabled: true };
    }

    const maxNumberOfPicks = data.maxNumberOfPicks ?? rules.maxNumberOfPicks;
    const minNumberOfPicks = data.minNumberOfPicks ?? rules.minNumberOfPicks;
    const maxNumberOfBoostedPicks = data.maxNumberOfBoostedPicks ?? rules.maxNumberOfBoostedPicks;
    const maxNumberOfSpecialPicks = data.maxNumberOfSpecialPicks ?? rules.maxNumberOfSpecialPicks;

    //we do not consider a mode disabled if the only error is INVALID_AMOUNT
    const errors = data.errors.filter(it => it.key !== 'INVALID_AMOUNT');
    if (errors.length === 0) {
        return { error: undefined, disabled: false };
    }

    if (betslip.length > maxNumberOfPicks) {
        return { error: `Max ${maxNumberOfPicks} picks allowed`, disabled: true };
    }

    if (betslip.length < minNumberOfPicks) {
        return { error: `Minimum ${minNumberOfPicks} picks needed`, disabled: true };
    }

    const boostedPicks = betslip.filter(it => it.projection.type === ProjectionType.Boosted).length;
    const specialPicks = betslip.filter(it => it.projection.type === ProjectionType.Special).length;
    if (boostedPicks > 0 && maxNumberOfBoostedPicks !== undefined) {
        if (maxNumberOfBoostedPicks === 0) {
            return { error: 'Not available with boosted lineups', disabled: true };
        } else if (boostedPicks > maxNumberOfBoostedPicks) {
            return {
                error: `Max ${maxNumberOfBoostedPicks} boosted picks allowed`,
                disabled: true,
            };
        }
    }
    if (specialPicks > 0 && maxNumberOfSpecialPicks !== undefined) {
        if (maxNumberOfSpecialPicks === 0) {
            return { error: 'Not available with special picks', disabled: true };
        } else if (specialPicks > maxNumberOfSpecialPicks) {
            return {
                error: `Max ${maxNumberOfSpecialPicks} special picks allowed`,
                disabled: true,
            };
        }
    }
    return {
        error: errors[0]?.message ?? 'Not available at this time',
        disabled: false,
    };
};

export const getGameModeDetails = (gameMode: GameMode, allEntryRules: EntryRulesOutput[]) => {
    switch (gameMode) {
        case GameMode.Perfect:
            const perfectRules = allEntryRules.find(rule => rule.gameMode === GameMode.Perfect);
            return {
                name: perfectRules?.name ?? '',
                description: perfectRules?.description ?? '',
            };
        case GameMode.Dynamic:
            const dynamicRules = allEntryRules.find(rule => rule.gameMode === GameMode.Dynamic);
            return {
                name: dynamicRules?.name ?? '',
                description: dynamicRules?.description ?? '',
            };
        default:
            return {
                name: '',
                description: '',
            };
    }
};

export const getGameModeSubtitle = ({
    gameMode,
    p2pGameType,
    perfectModeError,
    dynamicModeError,
    betslip,
    lowRange,
    highRange,
    t,
}: {
    gameMode: GameMode;
    p2pGameType: boolean;
    perfectModeError: string | undefined;
    dynamicModeError: string | undefined;
    betslip: BetslipPick[];
    lowRange: string;
    highRange: string;
    t: TFunction<['common', 'p2p'], undefined>;
}) => {
    const betslipLength = betslip.length;
    if (gameMode === GameMode.Perfect) {
        return (
            perfectModeError ??
            (p2pGameType ? t('p2p:get_first_place', { betslipLength }) : t('p2p:all_to_win', { betslipLength }))
        );
    }
    if (gameMode === GameMode.Dynamic) {
        return dynamicModeError ?? t('p2p:at_least_ranges', { lowRange, highRange });
    }
    return '';
};
