import { useCallback } from 'react';

import { useIsFocused } from '@react-navigation/native';

import { useAppResumeEffect } from '@/hooks/use-resume';

/**
 * Custom hook that invokes the refetch function when the app is resumed from background and screen is focused.
 * @param refetch - The function that triggers the data refresh.
 */
export const useFetchOnResume = <T>(refetch: () => Promise<T>) => {
    const isFocused = useIsFocused();
    useAppResumeEffect(
        useCallback(() => {
            if (isFocused) {
                refetch();
            }
        }, [refetch, isFocused])
    );
};
