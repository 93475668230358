import { Platform } from 'react-native';
import geocomply from 'react-native-geo-comply-sdk-wrapper';

import { getGeocomplyClient } from '@/data/location/geocomply-common';
import { BetSelectionType } from '@/feature/bets-sbk/hooks/types';
import { SBKBetSlipState } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { Bet, GenerateBetsPayloadError, GeoComplyError, MAX_SELECTIONS, Selection } from '@/feature/betslip-sbk/types';
import { isWeb } from '@/utils/constants-platform-specific';
import { isMobileBrowser } from '@/utils/is-mobile-browser/is-mobile-browser';

import { generateSgpOddsId, getComboBetType, isBetValid, isComboSelectionEnabled } from '../betslip-utils';
import {
    BetChannel,
    BetPayloadError,
    BetSettings,
    BetType,
    PlaceBet,
    PlaceBetSelection,
    PlaceBetsRequest,
} from './types';

const SINGLE_ERROR_PREFIX = 'single_';
const COMBO_ERROR_PREFIX = 'combo_';
const SGP_ERROR_PREFIX = 'sgp_';
const SGP_PLUS_ERROR_PREFIX = 'sgp_plus_';

/**
 * Returns the channel for the bet
 */
export const getChannel = (): BetChannel => {
    if (Platform.OS === 'ios') {
        return 'IOS';
    }
    if (Platform.OS === 'android') {
        return 'ANDROID';
    }
    if (isWeb) {
        if (isMobileBrowser()) {
            return 'MOBILE_WEB';
        } else {
            return 'WEB';
        }
    }
    return 'OTHER';
};

/**
 * Create geotoken and validate if the user can bet
 */
export const getGeoToken = async (): Promise<string> => {
    const geocomplyClient = getGeocomplyClient();
    geocomplyClient.configs.reason_code = geocomply().ReasonCode.PRE_WAGER;
    geocomplyClient.configs.reason = 'PRE_WAGER';
    const { encoded, decoded } = await geocomplyClient.request({ readCache: true, decode: true });
    if (decoded && !decoded?.can_bet) {
        throw new GeoComplyError(decoded);
    }
    return encoded;
};

/**
 * Generates the payload for the bet slip
 */
export const generateBetsPayload = (
    userId: string,
    state: SBKBetSlipState,
    geotoken: string,
    betSettings: BetSettings
): PlaceBetsRequest => {
    return {
        customer: {
            user_id: userId,
            geotoken,
        },
        bets: [
            ...Object.values(state.bets)
                .filter(bet => isBetValid(bet, state))
                .filter(bet => bet.stake)
                .map(bet => generateBetPayload(bet, state, betSettings)),
        ],
    };
};

const generateBetPayload = (bet: Bet, state: SBKBetSlipState, betSettings: BetSettings) => {
    switch (bet.betType) {
        case 'SINGLE':
            return generateSinglesBet(bet, state, betSettings);
        case 'COMBO':
            return generateComboBet(bet, state, betSettings);
    }
};

/**
 * Generates a Singles bet payload
 */
const generateSinglesBet = (bet: Bet, state: SBKBetSlipState, betSettings: BetSettings): PlaceBet => {
    const selection = state.selections[bet.id];
    if (!selection) {
        throw new GenerateBetsPayloadError(SINGLE_ERROR_PREFIX + BetPayloadError.MissingSelection);
    }
    const option = state.options[selection.id];
    if (!option) {
        throw new GenerateBetsPayloadError(SINGLE_ERROR_PREFIX + BetPayloadError.MissingOption);
    }
    if (!bet.stake) {
        throw new GenerateBetsPayloadError(SINGLE_ERROR_PREFIX + BetPayloadError.MissingStake);
    }

    return {
        ...betSettings,
        is_fixed_odds: true,
        bet_type: 'SINGLE',
        stake_per_line: bet.stake,
        use_free_money: !!bet.isBetrBucks,
        currency: bet.isBetrBucks ? 'FRE' : betSettings.currency,
        selections: [
            {
                selection_type: 'WIN',
                parts: [
                    {
                        fixture_id: selection.eventId,
                        side_bet_id: selection.marketId,
                        winner_id: selection.id,
                        type: 'WIN',
                        order: 1,
                        odds: option.odds,
                        starting_price: false,
                        best_odds_guaranteed: false,
                    },
                ],
            },
        ],
    };
};

const parlayBetTypes: { [key: number]: BetType } = {
    2: 'DOUBLE',
    3: 'TREBLE',
    4: 'FOURFOLD',
    5: 'FIVEFOLD',
    6: 'SIXFOLD',
    7: 'SEVENFOLD',
    8: 'EIGHTFOLD',
    9: 'NINEFOLD',
    10: 'TENFOLD',
    11: 'ELEVENFOLD',
    12: 'TWELVEFOLD',
    13: 'THIRTEENFOLD',
    14: 'FOURTEENFOLD',
    15: 'FIFTEENFOLD',
    16: 'SIXTEENFOLD',
    17: 'SEVENTEENFOLD',
    18: 'EIGHTEENFOLD',
    19: 'NINETEENFOLD',
    20: 'TWENTYFOLD',
} as const;

const generateSelectionPayload = ({
    selectionType,
    selection,
    selectionId,
    odds,
}: {
    selectionType: BetSelectionType;
    selection: Selection;
    selectionId: string;
    odds: number;
}) =>
    ({
        selection_type: selectionType,
        parts: [
            {
                fixture_id: selection.eventId,
                side_bet_id: selection.marketId,
                winner_id: selectionId,
                type: 'WIN',
                order: 1,
                odds,
                starting_price: false,
                best_odds_guaranteed: false,
            },
        ],
    } as PlaceBetSelection);

/**
 * Generates a Combo bet payload
 */
const generateComboBet = (bet: Bet, state: SBKBetSlipState, betSettings: BetSettings): PlaceBet => {
    const comboBetType = getComboBetType(state);
    const { selections, options } = state;
    if (comboBetType === 'SGP') {
        return generateSgpBet(bet, state, betSettings);
    }

    if (comboBetType === 'SGP+') {
        return generateSgpPlusBet(bet, state, betSettings);
    }

    const selectionIds = Object.values(state.selections)
        .filter(selection => isComboSelectionEnabled(selection, state))
        .map(selection => selection.id); // filtered toggled on selections

    if (selectionIds.length < 2) {
        throw new GenerateBetsPayloadError(COMBO_ERROR_PREFIX + BetPayloadError.ComboMinSelections);
    }
    if (selectionIds.length > MAX_SELECTIONS) {
        throw new GenerateBetsPayloadError(COMBO_ERROR_PREFIX + BetPayloadError.ComboBetMaxSelections);
    }
    if (!bet.stake) {
        throw new GenerateBetsPayloadError(COMBO_ERROR_PREFIX + BetPayloadError.MissingStake);
    }
    const betSelections: PlaceBetSelection[] = [];
    selectionIds.map(selectionId => {
        const selection = selections[selectionId];
        if (!selection) {
            throw new GenerateBetsPayloadError(COMBO_ERROR_PREFIX + BetPayloadError.MissingSelection);
        }
        const option = options[selectionId];
        if (!option) {
            throw new GenerateBetsPayloadError(COMBO_ERROR_PREFIX + BetPayloadError.MissingOption);
        }
        betSelections.push(
            generateSelectionPayload({ selectionType: 'WIN', selection, selectionId, odds: option.odds })
        );
    });

    return {
        ...betSettings,
        is_fixed_odds: true,
        bet_type: parlayBetTypes[selectionIds.length],
        stake_per_line: bet.stake,
        use_free_money: !!bet.isBetrBucks,
        currency: bet.isBetrBucks ? 'FRE' : betSettings.currency,
        selections: betSelections,
    };
};

/**
 * Generates a Sgp bet payload
 */
const generateSgpBet = (bet: Bet, state: SBKBetSlipState, betSettings: BetSettings): PlaceBet => {
    const { selections, options, sgpOdds } = state;
    const selectionIds = Object.values(selections)
        .filter(selection => isComboSelectionEnabled(selection, state))
        .map(selection => selection.id);

    if (selectionIds.length < 2) {
        throw new GenerateBetsPayloadError(SGP_ERROR_PREFIX + BetPayloadError.ComboMinSelections);
    }
    if (selectionIds.length > MAX_SELECTIONS) {
        throw new GenerateBetsPayloadError(SGP_ERROR_PREFIX + BetPayloadError.ComboBetMaxSelections);
    }
    if (!bet.stake) {
        throw new GenerateBetsPayloadError(SGP_ERROR_PREFIX + BetPayloadError.MissingStake);
    }

    const sgpId = generateSgpOddsId(selectionIds, selections[selectionIds[0]].eventId);
    return {
        ...betSettings,
        is_fixed_odds: true,
        bet_type: parlayBetTypes[selectionIds.length],
        stake_per_line: bet.stake,
        use_free_money: !!bet.isBetrBucks,
        currency: bet.isBetrBucks ? 'FRE' : betSettings.currency,
        selections: selectionIds.map(selectionId => {
            const selection = selections[selectionId];
            if (!selection) {
                throw new GenerateBetsPayloadError(SGP_ERROR_PREFIX + BetPayloadError.MissingSelection);
            }
            const option = options[selectionId];
            if (!option) {
                throw new GenerateBetsPayloadError(SGP_ERROR_PREFIX + BetPayloadError.MissingOption);
            }
            const odds = sgpOdds[sgpId];
            if (!odds) {
                throw new GenerateBetsPayloadError(SGP_ERROR_PREFIX + BetPayloadError.MissingSgpOdds);
            }

            return generateSelectionPayload({ selectionType: 'BETBUILDER', selection, selectionId, odds });
        }),
    };
};

/**
 * Generates a SgpPlus bet payload
 */
export const generateSgpPlusBet = (bet: Bet, state: SBKBetSlipState, betSettings: BetSettings): PlaceBet => {
    const { selections, options, sgpOdds } = state;
    if (!bet.stake) {
        throw new GenerateBetsPayloadError(SGP_PLUS_ERROR_PREFIX + BetPayloadError.MissingStake);
    }

    // Group selections by event id to create selections payload for each event
    let groupedSelectionsByEventId: { [key: string]: Record<string, Selection> } = {};
    Object.values(selections)
        .filter(selection => isComboSelectionEnabled(selection, state))
        .map(selection => {
            groupedSelectionsByEventId[selection.eventId] = {
                ...groupedSelectionsByEventId[selection.eventId],
                [selection.id]: selection,
            };
        });

    const payloadSelections: PlaceBetSelection[] = [];
    Object.values(groupedSelectionsByEventId).forEach(eventSelections => {
        const selectionIds = Object.values(eventSelections).map(eventSelection => eventSelection.id);
        if (selectionIds.length < 2) {
            // If there is only one selection, it should be a single bet
            const selectionId = selectionIds[0];
            const selection = selections[selectionId];
            if (!selection) {
                throw new GenerateBetsPayloadError(SGP_PLUS_ERROR_PREFIX + BetPayloadError.MissingSelection);
            }
            const option = options[selectionId];
            if (!option) {
                throw new GenerateBetsPayloadError(SGP_PLUS_ERROR_PREFIX + BetPayloadError.MissingOption);
            }
            payloadSelections.push(
                generateSelectionPayload({ selectionType: 'WIN', selection, selectionId, odds: option.odds })
            );
        } else {
            // If there are more than one selection, it should be a sgp bet
            if (selectionIds.length > MAX_SELECTIONS) {
                throw new GenerateBetsPayloadError(SGP_PLUS_ERROR_PREFIX + BetPayloadError.ComboBetMaxSelections);
            }

            const sgpId = generateSgpOddsId(selectionIds, eventSelections[selectionIds[0]].eventId);
            selectionIds.forEach(selectionId => {
                const selection = selections[selectionId];
                if (!selection) {
                    throw new GenerateBetsPayloadError(SGP_PLUS_ERROR_PREFIX + BetPayloadError.MissingSelection);
                }
                const option = options[selectionId];
                if (!option) {
                    throw new GenerateBetsPayloadError(SGP_PLUS_ERROR_PREFIX + BetPayloadError.MissingOption);
                }
                const odds = sgpOdds[sgpId];
                if (!odds) {
                    throw new GenerateBetsPayloadError(SGP_PLUS_ERROR_PREFIX + BetPayloadError.MissingSgpOdds);
                }

                payloadSelections.push(
                    generateSelectionPayload({ selectionType: 'BETBUILDER', selection, selectionId, odds })
                );
            });
        }
    });
    return {
        ...betSettings,
        is_fixed_odds: true,
        bet_type: parlayBetTypes[payloadSelections.length],
        stake_per_line: bet.stake,
        use_free_money: !!bet.isBetrBucks,
        currency: bet.isBetrBucks ? 'FRE' : betSettings.currency,
        selections: payloadSelections,
    };
};
