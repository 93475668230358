import React from 'react';

import { useNavigation } from '@react-navigation/native';

import { ActionItem } from '@/components/ActionItem';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { commonStyles } from '@/feature/responsible-gaming/screens/styles';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';

export const TimeoutScreen = () => {
    const { navigate } = useNavigation();

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: 'Timeout' }}
            scrollViewProps={{ style: [commonStyles.scrollView, { paddingHorizontal: 0 }] }}
        >
            <MaxWidthWrapper paddingHorizontal={'s16'}>
                <Text variant="bodySmall" color={'gray2'} testID="timeOutHeaderMsg">
                    If you wish to disable your account for any reason, you can do so for 3 to 365 days. The account
                    will be automatically reenabled at the end of the timeout period.
                </Text>
                <SizedBox value={16} />
                <ActionItem
                    label="Timeout"
                    buttonLabel="Add"
                    handlePress={() => {
                        BetrAnalytics.trackProductEvent(AnalyticsEvent.ADD_TIMEOUT);
                        trackRG.timeoutScreen('Timeout Add');
                        navigate('TimeoutAdd');
                    }}
                />
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
