import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const ShieldIcon = ({
    primary = designSystem.colors.gray3,
    secondary = designSystem.colors.gray8,
    width = 24,
    height = 24,
}) => (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M12 4.06152V19.7639L10.4223 18.975C7.71202 17.6199 6 14.8498 6 11.8196V5.56152L12 4.06152Z"
            fill={secondary}
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2L20 4V11.8197C20 15.6074 17.86 19.07 14.4721 20.7639L12 22L9.52786 20.7639C6.14002 19.07 4 15.6074 4 11.8197V4L12 2ZM12 19.7639V4.06155L6 5.56155V11.8197C6 14.8498 7.71202 17.6199 10.4223 18.9751L12 19.7639Z"
            fill={primary}
        />
        <Path
            d="M19.5 4.39039L12 2.51539L4.5 4.39039V11.8197C4.5 15.418 6.53302 18.7075 9.75147 20.3167L12 21.441L14.2485 20.3167C17.467 18.7075 19.5 15.418 19.5 11.8197V4.39039ZM12 22L14.4721 20.7639C17.86 19.07 20 15.6074 20 11.8197V4L12 2L4 4V11.8197C4 15.6074 6.14002 19.07 9.52786 20.7639L12 22Z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="white"
        />
    </Svg>
);

export default ShieldIcon;
