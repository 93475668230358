import React from 'react';

import { Box, Column, Row } from '@/components/lib/components';
import { AppSpacing } from '@/components/lib/theme';

export const Grid = <T extends any>({
    columns,
    data,
    spacing,
    renderItem,
}: {
    columns: number;
    data: T[];
    spacing: AppSpacing;
    renderItem: (item: T | null, row: number, col: number) => React.ReactNode;
}) => {
    const partitionData = () => {
        const partitions: (T | null)[][] = [];
        for (let i = 0; i < data.length; i += columns) {
            const partition: (T | null)[] = data.slice(i, i + columns);
            partitions.push(partition);
            if (partition.length < columns) {
                for (let j = partition.length; j < columns; j++) {
                    partition.push(null);
                }
            }
        }
        return partitions;
    };
    const dataByRow = partitionData();
    return (
        <Column gap={spacing}>
            {dataByRow.map((row, i) => {
                return (
                    <Row key={`row-${i}`} gap={spacing}>
                        {row.map((item, j) => {
                            return (
                                <Box flex={1} key={`row-${i}-col-${j}`}>
                                    {renderItem(item, i, j)}
                                </Box>
                            );
                        })}
                    </Row>
                );
            })}
        </Column>
    );
};
