import { LeagueConfigsFragment } from '@/api/leagues/query.generated';
import { League, ProjectionType } from '@/types/api.generated';
import { isPGA, isUFC } from '@/utils/league';

export const Filters = {
    Position: 'Position',
    Projection: 'Projection',
} as const;

export type FilterType = (typeof Filters)[keyof typeof Filters];

export const nonRegularProjectionTypes: ProjectionType[] = Object.values(ProjectionType).filter(
    it => it !== ProjectionType.Regular
);

export function leagueHasPositions(league?: League) {
    return !isPGA(league) && !isUFC(league);
}

export const getPositionFilters = (league: League, leagues: LeagueConfigsFragment[]) => {
    const activeLeague = leagues.find(it => it.league === league);

    if (!activeLeague) {
        return;
    }

    return activeLeague.positionFilters;
};
