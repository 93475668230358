import { useEffect, useState } from 'react';

/**
 * Debounce function for search queries and delayed tasks based on string var.
 * @param delay - ms debounce time
 * @param value - value expected to be changing rapidly
 */
export function useDebounce(delay: number, value: string) {
    const [debouncedValue, setDebouncedValue] = useState('');

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}
