import { useCallback, useMemo, useRef } from 'react';

import { eventKeys } from '@/feature/event-details-sbk/hooks/use-event';
import { leagueKeys } from '@/feature/league-hub-sbk/hooks/use-league';
import { useIsNativeSportsbook } from '@/hooks/use-is-native-sportsbook';
import { useSubscribePublic } from '@/utils/websocket/hooks';
import { ProducerChangeMessage } from '@/utils/websocket/types';
import { useQueryClient } from '@tanstack/react-query';
import { Types } from 'ably';

import { useSbkBetSlipStore } from './use-sbk-betslip-store';

export const useSubscribeProducerStatus = () => {
    const updateProducerStatus = useSbkBetSlipStore(state => state.actions.updateProducerStatus);
    const enabled = useIsNativeSportsbook();
    const queryClient = useQueryClient();
    const initialRender = useRef(true);

    const handleProducerChange = useCallback(
        (message: ProducerChangeMessage) => {
            if (message.is_up) {
                if (!initialRender.current) {
                    queryClient.invalidateQueries(eventKeys.all);
                    queryClient.invalidateQueries(leagueKeys.all);
                }
                updateProducerStatus('UP');
            } else {
                updateProducerStatus('DOWN');
            }
            initialRender.current = false;
        },
        [queryClient, updateProducerStatus]
    );

    const values = useMemo<{
        params: {};
        eventName: ['producer_up', 'producer_down'];
        options: Types.ChannelOptions;
    }>(() => {
        return {
            params: {},
            options: { params: { rewind: '1' } },
            eventName: ['producer_up', 'producer_down'],
        };
    }, []);

    useSubscribePublic<'systemProducerChange'>(
        'system:producer',
        values.params,
        values.eventName,
        handleProducerChange,
        enabled,
        values.options
    );
};
