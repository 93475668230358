import React from 'react';
import { View } from 'react-native';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { common } from '@/styles/styles';

type ResultComponentProps = {
    textTitle: string;
    resultIcon: React.ReactNode;
    footerItems: React.ReactNode;
    textDescription?: string;
    textDescriptionComponent?: React.ReactNode;
};

/**
 * Resulting view for any transaction
 * */
export const ResultComponent = ({
    textTitle,
    textDescription,
    textDescriptionComponent,
    resultIcon,
    footerItems,
}: ResultComponentProps) => {
    usePreventAndroidBackNavigation();

    return (
        <View style={[common.flex, common.justifySpaceAround, common.paddingHorizontal]}>
            <SizedBox value={0} />
            <View style={[common.justifyCenter, common.alignCenter]}>
                {resultIcon}
                <SizedBox value={30} />
                <Text fontWeight="bold" fontSize={24}>
                    {textTitle}
                </Text>
                <SizedBox value={10} />
                {textDescriptionComponent ? (
                    textDescriptionComponent
                ) : (
                    <Text textAlign={'center'} color={'gray2'} testID="withdrawMsg">
                        {textDescription}
                    </Text>
                )}
            </View>
            <View>{footerItems}</View>
        </View>
    );
};
