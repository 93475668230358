import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { PlayerInfoWithProjectionsFragment, TeamInfoFragment } from '@/api/events/query.generated';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { ProjectionChangeSwitch } from '@/components/ProjectionChangeSwitch';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';
import { trackRUMAction } from '@/data/datadog';
import { ScrollableModal } from '@/feature/alerts/components/Modal';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useBetslipActions } from '@/feature/betslip-pickem/hooks/use-betslip-actions';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import {
    InvalidPicksByEvent,
    PickAndEventWithStatus,
    PickWithStatus,
    ProjectionsChangedProps,
} from '@/feature/betslip-pickem/types';
import { PlayerRow } from '@/feature/lobby/components/PlayerRow';
import { useUpdateUserSettings } from '@/hooks/use-auth-user-settings';
import { SEPARATOR_HEGIHT, common } from '@/styles/styles.js';
import { League, Sport } from '@/types/api.generated';
import { runStoreUpdate } from '@/utils/zustand';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { TFunction } from 'i18next';

const styles = StyleSheet.create({
    container: {
        flexShrink: 1,
        flexGrow: 0,
    },
    letterSpacing: {
        letterSpacing: -0.43,
    },
    list: {
        paddingVertical: 8,
        paddingHorizontal: 16,
    },
    button: {
        paddingVertical: 10,
        borderWidth: 0,
    },
});

const modalId = 'lineupUpdate';

export type LineupUpdateProps = {
    invalidPicksByEvent?: InvalidPicksByEvent[];
    picksWithMissingEvents?: PickWithStatus[];
    picksWithChangedProjections?: PickAndEventWithStatus[];
    playersRemoved?: {
        eventId: string;
        player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment; league: League; sport: Sport };
    }[];
};

export type LineupUpdateModalRef = {
    show: (data: LineupUpdateProps) => void;
};

/**
 * Modal used to show:
 * - the unavailable players + players with projection changes
 * - just unavailable players
 * - just players with projection changes
 */
export const LineupUpdateModal = React.forwardRef<LineupUpdateModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<LineupUpdateProps>();
    const modalRef = useRef<BottomSheetModal>(null);
    const [remainingPicks, setRemainingPicks] = useState<PickAndEventWithStatus[] | undefined>([]);
    const { removeSelection } = useBetslipActions();
    const updateProjections = useBetslipStore(state => state.actions.updateProjections);
    const { t } = useTranslation(['common', 'lineup_update_modal']);
    const playersAlreadyRemoved = data?.playersRemoved;

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    useEffect(() => {
        if (data?.picksWithChangedProjections) {
            setRemainingPicks(data.picksWithChangedProjections);
        } else {
            setRemainingPicks([]);
        }
    }, [data]);

    const modalData = getModalDetails(data, t);

    const removeAllChanges = (shouldDismiss: boolean = true) => {
        BetrAnalytics.trackEvent(AnalyticsEvent.REJECT_PROJECTION_CHANGES);
        trackRUMAction('reject_projection_changes');

        const playersToRemove = remainingPicks?.map(pick => ({
            eventId: pick.event.id,
            player: pick.pick.player,
        }));
        if (playersToRemove && playersToRemove.length > 0) {
            runStoreUpdate(() => {
                removeSelection(playersToRemove, false);
            });
        }
        if (playersAlreadyRemoved && playersAlreadyRemoved.length > 0) {
            runStoreUpdate(() => {
                removeSelection(playersAlreadyRemoved);
            });
        }
        if (shouldDismiss) {
            modalRef.current?.dismiss();
        }
    };

    const acceptAllChanges = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.ACCEPT_PROJECTION_CHANGES);
        trackRUMAction('accept_projection_changes');

        if (remainingPicks && remainingPicks.length > 0) {
            runStoreUpdate(() => {
                updateProjections(remainingPicks);
            });
        }
        if (playersAlreadyRemoved && playersAlreadyRemoved.length > 0) {
            runStoreUpdate(() => {
                removeSelection(playersAlreadyRemoved);
            });
        }
        modalRef.current?.dismiss();
    };

    const dismissModal = () => {
        if (modalData?.mode === 'players-removed') {
            // the onDismiss callback will handle the removal of players
            modalRef.current?.dismiss();
            return;
        }

        if (playersAlreadyRemoved && playersAlreadyRemoved.length > 0) {
            runStoreUpdate(() => {
                removeSelection(playersAlreadyRemoved, false);
            });
        }
        modalRef.current?.dismiss();
    };

    return (
        <ScrollableModal
            onDismiss={() => {
                if (modalData?.mode === 'players-removed') {
                    runStoreUpdate(() => {
                        //we should never call modalRef.current?.dismiss() in the modals onDismiss callback
                        //it will cause an infinite loop on the web & call it twice on mobile
                        removeAllChanges(false);
                    });
                }
            }}
            dismissible={modalData?.mode === 'players-removed'}
            footer={
                modalData ? (
                    <ModalButtonsFooter
                        mode={modalData.mode}
                        dismissModal={() => {
                            runStoreUpdate(() => {
                                dismissModal();
                            });
                        }}
                        acceptAllChanges={() => {
                            runStoreUpdate(() => {
                                acceptAllChanges();
                            });
                        }}
                        removeAllChanges={() => {
                            runStoreUpdate(() => {
                                removeAllChanges();
                            });
                        }}
                        removeButtonLabel={
                            (remainingPicks ?? []).length > 1 ? t('common:remove_all') : t('common:remove')
                        }
                    />
                ) : null
            }
            sheetRef={modalRef}
            id={modalId}
        >
            {data && modalData ? (
                <ModalContent
                    invalidPicksByEvent={data?.invalidPicksByEvent}
                    picksWithMissingEvents={data?.picksWithMissingEvents}
                    picksWithChangedProjections={remainingPicks}
                    setRemainingPicks={setRemainingPicks}
                    title={modalData.title}
                    description={modalData.description}
                    modalRef={modalRef}
                    mode={modalData.mode}
                />
            ) : null}
        </ScrollableModal>
    );
});

const ModalContent = (
    props: LineupUpdateProps & {
        modalRef: React.RefObject<BottomSheetModal>;
        setRemainingPicks: React.Dispatch<React.SetStateAction<PickAndEventWithStatus[] | undefined>>;
        title: string;
        description?: string;
        mode: 'lineup-update' | 'players-removed' | 'projections-changed';
    }
) => {
    const {
        title,
        modalRef,
        description,
        invalidPicksByEvent,
        picksWithMissingEvents,
        picksWithChangedProjections,
        setRemainingPicks,
        mode,
    } = props;

    return (
        <Box>
            <Box px={'s16'} pt={'s16'} pb={'s8'} alignItems={'center'}>
                <Text variant={'headlineMedium'} color={'gray1'}>
                    {title}
                </Text>
                {description ? (
                    <Text variant={'titleMedium'} textAlign={'center'} color={'gray2'}>
                        {description}
                    </Text>
                ) : null}
            </Box>
            <PlayersRemovedContent
                invalidPicksByEvent={invalidPicksByEvent}
                picksWithMissingEvents={picksWithMissingEvents}
            />
            <ProjectionChangedModalContent
                picks={picksWithChangedProjections}
                setRemainingPicks={setRemainingPicks}
                modalRef={modalRef}
                mode={mode}
            />
        </Box>
    );
};

const getModalDetails = (
    data: LineupUpdateProps | undefined,
    t: TFunction<['common', 'lineup_update_modal'], undefined>
): {
    title: string;
    description?: string;
    mode: 'lineup-update' | 'players-removed' | 'projections-changed';
} | null => {
    const lineupUpdateModal =
        (data?.picksWithChangedProjections?.length ?? -1) > 0 &&
        ((data?.picksWithMissingEvents?.length ?? -1) > 0 || (data?.invalidPicksByEvent?.length ?? -1) > 0);

    const playersRemovedModal =
        (data?.picksWithMissingEvents?.length ?? -1) > 0 || (data?.invalidPicksByEvent?.length ?? -1) > 0;

    const projectionsChangedModal = (data?.picksWithChangedProjections?.length ?? -1) > 0;

    switch (true) {
        case lineupUpdateModal:
            return {
                title: t('lineup_update_modal:title'),
                description: t('lineup_update_modal:description'),
                mode: 'lineup-update',
            };
        case playersRemovedModal:
            return {
                title: t('lineup_update_modal:players_unavailable'),
                mode: 'players-removed',
            };
        case projectionsChangedModal:
            return {
                title: t('lineup_update_modal:projections_have_changed'),
                mode: 'projections-changed',
            };
        default:
            return null;
    }
};

const PlayersRemovedContent = ({
    invalidPicksByEvent,
    picksWithMissingEvents,
}: {
    invalidPicksByEvent?: InvalidPicksByEvent[];
    picksWithMissingEvents?: PickWithStatus[];
}) => {
    const data = [...(picksWithMissingEvents ?? []), ...(invalidPicksByEvent?.flatMap(it => it.invalidPicks) ?? [])];
    const events = invalidPicksByEvent?.map(it => it.event) ?? [];
    return (
        <Box px={'s16'}>
            {data.map((item, index) => {
                const pick = item.pick;
                const isLastItem = index + 1 === data.length;
                const event = events.find(e => e.id === pick.eventId);
                return (
                    <Column key={`${pick.player.id}`}>
                        <PlayerRow
                            player={pick.player}
                            mode="unavailable"
                            reason={item.projectionStatus.status}
                            event={event}
                            presentation="sheet"
                            projection={pick.projection}
                        />
                        {!isLastItem ? <LineSeparator style={common.hairlineHeight} /> : null}
                    </Column>
                );
            })}
        </Box>
    );
};

export const ProjectionChangedModalContent = ({
    picks,
    setRemainingPicks,
    modalRef,
    mode,
    disableChanges,
}: ProjectionsChangedProps & {
    modalRef: React.RefObject<BottomSheetModal>;
    mode: 'lineup-update' | 'players-removed' | 'projections-changed';
    disableChanges?: boolean;
}) => {
    const { removeSelection } = useBetslipActions();

    return (
        <Box px={'s16'} borderBottomWidth={SEPARATOR_HEGIHT} borderColor={'gray5'}>
            {picks?.map(item => {
                const { pick, event } = item;
                const { player } = pick;
                return (
                    <PlayerRow
                        key={`${pick.eventId}-${player.id}`}
                        presentation={'sheet'}
                        player={player}
                        projection={pick.projection}
                        mode={disableChanges ? 'disable-changes' : 'change'}
                        newProjection={item.projectionStatus.projection}
                        outcome={pick.outcome}
                        showTrash={!disableChanges}
                        onRemoveFromLineup={() => {
                            const playerToRemove = [
                                {
                                    eventId: pick.eventId,
                                    player: pick.player,
                                },
                            ];
                            runStoreUpdate(() => {
                                removeSelection(playerToRemove, false);
                            });
                            // when modal is only for Projections Changed and there is only one player -> if removed, close the modal
                            // when modal is Lineup Update - do not close the modal because players removed are displayed as well
                            if (picks?.length === 1 && mode === 'projections-changed') {
                                modalRef.current?.dismiss();
                            } else {
                                setRemainingPicks(prevPicks =>
                                    prevPicks?.filter(
                                        (p: PickAndEventWithStatus) => p.pick.player.id !== pick.player.id
                                    )
                                );
                            }
                        }}
                        event={event}
                        onOutcomeChange={(outcome, projection) => {
                            // when user selectes another outcome, update the new outcome and projection for the pick
                            setRemainingPicks(prevPicks => {
                                const updatedPicks = prevPicks?.map((prevPick: PickAndEventWithStatus) => {
                                    if (prevPick.pick.player.id === player.id) {
                                        return {
                                            ...prevPick,
                                            pick: {
                                                ...prevPick.pick,
                                                outcome,
                                                projection,
                                            },
                                        };
                                    }
                                    return prevPick;
                                });
                                return updatedPicks;
                            });
                        }}
                    />
                );
            })}
        </Box>
    );
};

const ModalButtonsFooter = ({
    acceptAllChanges,
    removeAllChanges,
    removeButtonLabel,
    dismissModal,
    mode,
}: {
    acceptAllChanges: () => void;
    removeAllChanges: () => void;
    removeButtonLabel: string;
    dismissModal: () => void;
    mode: 'lineup-update' | 'players-removed' | 'projections-changed';
}) => {
    const safeInsets = useSafeAreaInsets();
    const { t } = useTranslation(['betslip_pickem', 'account', 'common', 'preferences', 'lineup_update_modal']);
    const [acceptAllProjections, setAcceptAllProjections] = useState(false);

    const { mutateAsync: updateUserSettings } = useUpdateUserSettings();

    return (
        <Box style={{ paddingBottom: safeInsets.bottom + 16 }}>
            {mode === 'players-removed' ? (
                <Box pt={'s16'} px={'s16'}>
                    <Button
                        label={t('lineup_update_modal:got_it')}
                        type={'active'}
                        style={[common.full, styles.button]}
                        variant={'rounded'}
                        size={'medium'}
                        onPress={dismissModal}
                    />
                </Box>
            ) : (
                <>
                    <Row alignItems={'center'} p={'s16'}>
                        <Box flex={1}>
                            <Text variant="bodyMedium" color={'gray1'}>
                                {t('preferences:always_accept_projection_changes')}
                            </Text>
                            <Text variant="bodySmall" color={'gray2'}>
                                {t('account:toggle_on')}
                            </Text>
                        </Box>
                        <Box ml={'s16'}>
                            <ProjectionChangeSwitch displayMode={'modal'} onSwitchChange={setAcceptAllProjections} />
                        </Box>
                    </Row>
                    <Box px={'s16'}>
                        <Button
                            label={t('betslip_pickem:accept_all_changes')}
                            type={'active'}
                            style={styles.button}
                            variant={'rounded'}
                            onPress={async () => {
                                if (acceptAllProjections) {
                                    await updateUserSettings({ accept_all_odds_changes: acceptAllProjections });
                                }
                                acceptAllChanges();
                            }}
                        />
                        <SizedBox value={12} />
                        <Button
                            label={removeButtonLabel}
                            type={'activeGray'}
                            variant={'rounded'}
                            onPress={async () => {
                                if (acceptAllProjections) {
                                    await updateUserSettings({ accept_all_odds_changes: acceptAllProjections });
                                }
                                removeAllChanges();
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};
