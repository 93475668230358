import { URLS, createJurisdictionHeaders } from '@/data';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { GetSgpOddsResponse, SgpBetError, SgpPayload } from '../types';

export const getSgpOdds = async (payload: SgpPayload, onFetchingStart: () => void): Promise<GetSgpOddsResponse> => {
    // Feature flag for enabling SGP.
    const isSgpEnabled = useJurisdictionStore.getState().jurisdictionSettings?.productConfig?.settings?.sgp_enabled;
    if (!isSgpEnabled) {
        // If it's disabled throw an error to force the only 1 active selection flow.
        throw new SgpBetError('sgp_not_enabled', 'sgp_not_enabled');
    }
    onFetchingStart();
    const body = JSON.stringify(payload);
    const resp = await fetch(`${URLS.CHAMELON_API_URL}/bet-builder`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body,
    });
    const responseData = await resp.json();
    if (!resp.ok) {
        throw new SgpBetError(
            responseData.message || 'Unable to generate SGP or bet builder odds.',
            responseData.error_codes[0] || 'sgp_temporarily_unavailable'
        );
    }
    return responseData;
};
