import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import Clipboard from '@react-native-clipboard/clipboard';

import CopyLinkCheck from '@/assets/icons/copyLinkCheck';
import CopyLinkIcon from '@/assets/icons/copyLinkIcon';
import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import { ToastProps } from '@/feature/alerts/components/Toast';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { designSystem } from '@/styles/styles';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

const modalId = 'shareEntry';

export type ShareEntryProps = {
    link?: string;
    imageUri?: string;
    showToast?: (options: ToastProps, duration?: number) => void;
    onDismiss?: () => void;
};
export type ShareEntryModalRef = {
    show: (data: ShareEntryProps) => void;
};
/**
 * Modal used to show the share options/channels for your entry
 */
export const ShareEntryModal = React.forwardRef<ShareEntryModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<ShareEntryProps>();
    const modalRef = useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    return (
        <Modal id={modalId} modalRef={modalRef} onDismiss={data?.onDismiss}>
            <ModalContent {...data} />
        </Modal>
    );
});

const ModalContent = (props: ShareEntryProps) => {
    const { t } = useTranslation('pickem_share_entry_modal');
    const { showToast } = useAlerts();
    const shareData = { ...props, showToast };
    const [linkCopied, setLinkCopied] = useState(false);
    const shareMessage = t('share_message', { url: shareData.link ?? '' });

    return (
        <Box paddingHorizontal={'s16'}>
            <Box pt={'s16'} paddingHorizontal={'s8'}>
                <Text textAlign={'center'} variant="headlineMedium" fontWeight={'700'}>
                    {t('title')}
                </Text>
                <Text variant="bodyMedium" textAlign={'center'} color={'gray2'} pt={'s4'} pb={'s22'}>
                    {t('description')}
                </Text>
            </Box>
            <Box justifyContent={'space-around'}>
                <Button
                    label={
                        <Row alignItems={'center'} justifyContent={'center'}>
                            {linkCopied ? (
                                <CopyLinkCheck color={designSystem.colors.gray8} />
                            ) : (
                                <CopyLinkIcon color={designSystem.colors.gray8} />
                            )}
                            <Text variant={'buttonLabel'} color={'gray8'} marginLeft={'s8'} paddingRight={'s16'}>
                                {t('buttons.copy_link')}
                            </Text>
                        </Row>
                    }
                    type={'active'}
                    style={styles.button}
                    variant={'rounded'}
                    onPress={() => {
                        BetrAnalytics.trackEvent(AnalyticsEvent.SHARE_OPTION, { platform: 'copy_link' });
                        copyLink({ shareData, setLinkCopied, shareMessage });
                    }}
                />
            </Box>
        </Box>
    );
};

type CopyLinkProps = {
    shareData: ShareEntryProps;
    setLinkCopied: (value: boolean) => void;
    shareMessage?: string;
};

const copyLink = ({ shareData: { link, showToast }, setLinkCopied }: CopyLinkProps) => {
    showToast?.({ message: 'Link copied', toastType: 'success' });
    Clipboard.setString(link ?? '');
    setLinkCopied(true);
};

const styles = StyleSheet.create({
    button: {
        marginHorizontal: 16,
        marginBottom: 16,
    },
});
