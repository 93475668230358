import React from 'react';
import { Animated, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { StackCardInterpolatedStyle, StackCardInterpolationProps } from '@react-navigation/stack';

import { designSystem } from '@/styles/styles';

const { multiply } = Animated;

export const navAnim = {
    slideUp: { cardStyleInterpolator: slideupCardInterpolator, gestureDirection: 'vertical' },
} as const;

export function slideupCardInterpolator({
    current,
    inverted,
    layouts: { screen },
}: StackCardInterpolationProps): StackCardInterpolatedStyle {
    const translateY = multiply(
        current.progress.interpolate({
            inputRange: [0, 1],
            outputRange: [screen.height, 0],
            extrapolate: 'clamp',
        }),
        inverted
    );

    return {
        cardStyle: {
            transform: [{ translateY }],
        },
    };
}

export function dialogCardInterpolator({
    current,
    inverted,
    layouts: { screen },
}: StackCardInterpolationProps): StackCardInterpolatedStyle {
    const translateY = multiply(
        current.progress.interpolate({
            inputRange: [0, 1],
            outputRange: [screen.height, 0],
            extrapolate: 'clamp',
        }),
        inverted
    );

    const overlayOpacity = current.progress.interpolate({
        inputRange: [0, 1, 1.0001, 2],
        outputRange: [0, 0.8, 1, 1],
    });

    return {
        cardStyle: {
            transform: [{ translateY }],
        },
        overlayStyle: { opacity: overlayOpacity },
    };
}

export const dialogScrimOverlay = (props: { style: Animated.WithAnimatedValue<StyleProp<ViewStyle>> }) => {
    return <Animated.View {...props} style={[styles.overlay, props.style]} />;
};

const styles = StyleSheet.create({
    overlay: {
        flex: 1,
        backgroundColor: designSystem.colors.gray8,
    },
});
