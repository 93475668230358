import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Text } from '@/components/TextComponent';
import { designSystem } from '@/styles/styles.js';

import { TeamLogoFallbackText } from './TeamLogoFallback';
import { Image } from './lib/components';

const styles = StyleSheet.create({
    teamIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 24,
        alignSelf: 'center',
        overflow: 'hidden',
        width: 24,
        height: 24,
    },
    imageSize: {
        height: 16,
        width: 16,
    },
    countryContainer: {
        width: 24,
        height: 24,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export type TeamIconProps = {
    onLoadImage: () => void;
    teamIcon?: string;
    teamColor?: string;
    borderColor?: string;
    text?: string;
    shouldDisplayTeamName?: boolean;
};

export const TeamIcon = ({
    onLoadImage,
    teamIcon,
    teamColor,
    borderColor,
    text,
    shouldDisplayTeamName,
}: TeamIconProps) => {
    const backgroundColor = teamColor ? teamColor : designSystem.colors.gray4;
    const numberOfLetters = text?.length || 0;
    let fontSize = 12;
    if (numberOfLetters > 2 && numberOfLetters < 5) {
        fontSize = 8;
    } else if (numberOfLetters > 4) {
        fontSize = 5;
    }

    return (
        <View style={[styles.teamIcon, { backgroundColor, borderColor: borderColor }]}>
            {shouldDisplayTeamName ? (
                <TeamLogoFallbackText
                    height={24}
                    teamName={text || ''}
                    variant="iconFallback"
                    textColor={designSystem.colors.gray1}
                    showFullTeamName={true}
                    fontSize={fontSize}
                />
            ) : text ? (
                <View style={styles.countryContainer}>
                    <Text fontSize={8} fontWeight="600">
                        {text}
                    </Text>
                </View>
            ) : (
                <Image resizeMode="contain" source={{ uri: teamIcon }} style={styles.imageSize} onLoad={onLoadImage} />
            )}
        </View>
    );
};
