import { z } from 'zod';

export const DELETE_DEBIT_CARD_LOG_TAG = '[delete-debit-card]: ';
export const REMOVABLE_DEBIT_CARDS_LOG_TAG = '[removable-debit-cards]: ';

export type DebitCardRemovalError = {
    message: string;
    fields: string[];
    errors: Error;
    error_codes: string[];
};

const RemovableCardSchema = z.object({
    payment_type_id: z.string(),
    is_removable: z.boolean(),
    is_expired: z.boolean(),
    card_brand: z.string(),
    last4_digits: z.string(),
    logo_image_url: z.string(),
    payment_logos: z.string().array().optional(), // update images
    closed_loop_remain: z.number(),
});

export type RemovableCardType = z.infer<typeof RemovableCardSchema>;

export const RemovableCardsResponseSchema = z.object({
    data: z.array(RemovableCardSchema),
});

export type RemovableCardsResponse = z.infer<typeof RemovableCardsResponseSchema>;

export const removableCardKeys = {
    all: ['removable-debit-cards'] as const,
    delete: ['delete-debit-card'] as const,
};
