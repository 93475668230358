import React from 'react';

import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { LoadingScreen } from '@/screens/LoadingScreen';
import { toLocaleCurrency } from '@/utils/numeric/currency';

type WithdrawalFrameProps = {
    children: React.ReactNode;
    showTitle?: boolean;
    hasCloseIcon?: boolean;
    balance?: number;
    isLoading?: boolean;
    onClose?: () => void;
};

/**
 * Frame to contain withdraw views
 * */
export const WithdrawalFrame = ({
    hasCloseIcon = true,
    showTitle = true,
    balance = 0,
    isLoading = false,
    children,
    onClose,
}: WithdrawalFrameProps) => {
    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <Screen>
            <ScreenNavBar
                {...(showTitle ? { title: 'Withdraw Funds', subTitle: `up to ${toLocaleCurrency(balance)}` } : {})}
                closeIconMode={hasCloseIcon ? 'close' : undefined}
                onClose={onClose ? onClose : undefined}
            />
            <>{children}</>
        </Screen>
    );
};
