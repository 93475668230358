import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { usePlayerFiltersStore } from '@/feature/lobby/hooks/use-player-filters';
import { PickemLobbyTabNavigationProp } from '@/navigation/pickem/types';
import { League } from '@/types/api.generated';

import { getLeagueCategoryLabel } from '../utils/tabs/sectionUtils';
import { LeagueButton } from './LeagueButton';

type LeagueButtonTitleProps = {
    league: League;
    testID?: string;
    eventsCount?: number;
    icon?: string;
};

export const LeagueButtonTitle = (props: LeagueButtonTitleProps) => {
    const { t } = useTranslation('sports_events');
    const navigation = useNavigation<PickemLobbyTabNavigationProp>();
    const { eventsCount, league, testID, icon } = props;
    const resetAllFilters = usePlayerFiltersStore(state => state.actions.resetAllFilters);

    const leagueCategoryLabel = getLeagueCategoryLabel(league);
    const leagueName = league === League.NflFullSeason ? League.Nfl : league;
    const subtitle = league === League.NflFullSeason ? 'Season' : t(leagueCategoryLabel, { count: eventsCount });

    const subLabel = eventsCount === undefined ? '' : subtitle;
    const handlePress = () => {
        if (league) {
            resetAllFilters();
            navigation.navigate('PickemSportScreen', {
                league: league,
            });
        }
    };

    return (
        <LeagueButton
            disabled={league === League.NflFullSeason ? false : !eventsCount}
            banner={{ url: icon }}
            label={leagueName}
            subLabel={subLabel}
            onPress={handlePress}
            testID={testID}
        />
    );
};
