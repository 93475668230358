import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { useLinkTo } from '@react-navigation/native';

import { common } from '@/styles/styles';
import { handleWebviewMessage } from '@/utils/webview';

import { LoadingSpinner } from './LoadingSpinner';
import WebView, { WebViewMessageEvent, WebViewNavigation } from './Webview';

type Props = {
    uri: string;
    onNavigationStateChange?: (event: WebViewNavigation) => void;
    openNewTabOnWeb?: boolean;
    styles?: StyleProp<ViewStyle>;
};

const CustomWebView = ({ uri, onNavigationStateChange, openNewTabOnWeb = false, styles = {} }: Props) => {
    const linkTo = useLinkTo();
    return (
        <WebView
            source={{ uri }}
            startInLoadingState={true}
            style={[common.backgroundColorTransparent, styles]}
            renderLoading={() => (
                <View style={[common.alignCenter, common.justifyCenter, common.fullHeight]}>
                    <LoadingSpinner />
                </View>
            )}
            onMessage={(event: WebViewMessageEvent) => handleWebviewMessage(event?.nativeEvent, linkTo)}
            onNavigationStateChange={onNavigationStateChange}
            openNewTabOnWeb={openNewTabOnWeb}
        />
    );
};

export default CustomWebView;
