import { useCallback, useEffect, useMemo, useState } from 'react';

import useWebKeyboard from '@/feature/responsible-gaming/hooks/use-web-keyboard';
import {
    formatCommaSeparatedNumber,
    getNextValueOnBackspacePress,
    getNextValueOnDecimalPress,
    getNextValueOnNumberPress,
} from '@/utils/currency-num-pad-utils';

/**
 * Custom hook for handling currency input values with the number pad
 * @param {number} initialValue - optional initial value of the input
 * @param {number} charLimit - max limit of the input
 * @returns {Object} - object containing the display value, number value, and numpad functions
 */
export const useCurrencyNumberPad = (initialValue?: number, charLimit = 10) => {
    const [inputValue, setInputValue] = useState(initialValue ? String(initialValue) : '0');
    const [pristine, setPristine] = useState(true);

    useEffect(() => {
        setInputValue(initialValue ? String(initialValue) : '0');
    }, [initialValue]);

    const handleNextValue = useCallback(
        (nextValue: string) => {
            if (pristine) {
                setPristine(false);
            }
            if (nextValue !== inputValue) {
                setInputValue(nextValue);
            }
        },
        [pristine, inputValue, setInputValue]
    );

    const onNumberPress = useCallback(
        (value: number) => handleNextValue(getNextValueOnNumberPress(inputValue, value, charLimit)),
        [handleNextValue, inputValue, charLimit]
    );

    const onDecimalPress = useCallback(
        () => handleNextValue(getNextValueOnDecimalPress(inputValue, '.')),
        [handleNextValue, inputValue]
    );

    const onBackspacePress = useCallback(
        () => handleNextValue(getNextValueOnBackspacePress(inputValue)),
        [handleNextValue, inputValue]
    );

    const displayValue = useMemo(() => {
        return formatCommaSeparatedNumber(inputValue);
    }, [inputValue]);

    useWebKeyboard({ onNumberPress, onDecimalPress, onBackspacePress });

    return {
        displayValue,
        number: Number(inputValue),
        onNumberPress,
        onDecimalPress,
        onBackspacePress,
        setInputValue,
        pristine,
    };
};
