import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { PickerInput } from '@/components/PickerInput';
import { TextInput } from '@/components/TextInput';
import { AddressAutoComplete } from '@/feature/kyc/components/HomeAddress/AddressAutoComplete';
import { textInputKeyboardType, validateKeyboardInput } from '@/feature/kyc/utils/keyboardInput';
import { useFormFields } from '@/hooks/use-form-fields';
import { UseMutateAsyncFunction } from '@tanstack/react-query';

import { zipMask } from '../../const';
import { AddressFormValues } from '../../hooks/use-kyc-fields';
import { isPOBox } from '../../screens/HomeAddress/HomeAddressStep';
import { getStateCode, getStateName } from './get-state-info';
import { AddressErrors } from './types';

const styles = StyleSheet.create({
    inputsContainer: {
        gap: 20,
        paddingBottom: 80,
    },
});

type AddressFormProps = {
    values: AddressFormValues;
    setFieldValue: (name: keyof AddressFormValues, value: string) => void;
    setAddressFormValues: (fieldValues: AddressFormValues) => void;
    setShowPicker: (value: boolean) => void;
    verifyZip: UseMutateAsyncFunction<boolean, Error, string, unknown>;
};

export const AddressForm = ({
    values,
    setFieldValue,
    setAddressFormValues,
    setShowPicker,
    verifyZip,
}: AddressFormProps) => {
    const { t } = useTranslation('kyc');
    const { saveField, focusField } = useFormFields<'city' | 'state' | 'zip'>();
    const [addressErrors, setAddressErrors] = useState<AddressErrors>({});

    const handleZipVerification = useCallback(
        (zip: string) => {
            verifyZip(zip, {
                onSuccess: isValid => {
                    if (isValid) {
                        setAddressErrors(prev => {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const { zip: _, ...rest } = prev;
                            return rest;
                        });
                    } else {
                        setAddressErrors(prev => ({
                            ...prev,
                            zip: t('zip_code_invalid'),
                        }));
                    }
                },
                onError: () => {
                    setAddressErrors(prev => ({
                        ...prev,
                        zip: t('zip_code_invalid'),
                    }));
                },
            });
        },
        [verifyZip, t]
    );

    const handleVerifyZip = useCallback(
        (zip: string) => {
            if (zip.length === 5) {
                handleZipVerification(zip);
            } else {
                // Clear any previous zip verification errors
                setAddressErrors(prev => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { zip: _, ...rest } = prev;
                    return rest;
                });
            }
        },
        [handleZipVerification]
    );

    const handleSelectedPlace = (addressDetails: AddressFormValues) => {
        const americanState = getStateCode(addressDetails.americanState);
        setAddressFormValues({ ...addressDetails, americanState });
        checkForPOBox(addressDetails.address);
        // When prefilling input fields, we need to validate the zipcode status
        // Otherwise, the CTA button (e.g., Next button) won't be enabled
        handleVerifyZip(addressDetails.zip);
    };

    /**
     * Checks if the given address contains a PO Box and sets an error if found.
     *
     * This function is primarily used for manually entered addresses, as PO Box
     * addresses are filtered out in the autocomplete dropdown.
     *
     * @example
     * checkForPOBox("123 Main St, Anytown, USA"); // No error set
     * checkForPOBox("PO Box 456, Anytown, USA"); // Sets PO Box error
     */
    const checkForPOBox = useCallback(
        (address: string): void => {
            if (isPOBox(address)) {
                setAddressErrors(prev => ({
                    ...prev,
                    poBox: t('address_po_box_error'),
                }));
            } else {
                setAddressErrors(prev => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { poBox, ...rest } = prev;
                    return rest;
                });
            }
        },
        [t]
    );

    /**
     * Handles text change for various address form fields
     * @param field - The field being updated (e.g., 'address', 'city', 'zip')
     * @param value - The new value for the field
     */
    const onChangeText = useCallback(
        (field: keyof AddressFormValues, value: string) => {
            if (validateKeyboardInput(value)) {
                setFieldValue(field, value);

                if (field === 'address') {
                    checkForPOBox(value);
                }

                if (field === 'zip') {
                    handleVerifyZip(value);
                }
            }
        },
        [setFieldValue, checkForPOBox, handleVerifyZip]
    );

    useEffect(() => {
        if (values.zip.length > 0) {
            handleZipVerification(values.zip);
        }
    }, [values.zip, handleZipVerification, t]);

    const americanStateName = getStateName(values.americanState);

    return (
        <View style={styles.inputsContainer}>
            <AddressAutoComplete
                autoFocus
                fieldKey="address"
                label={t('address')}
                keyboardAppearance="dark"
                onFocus={() => setShowPicker(false)}
                value={values.address ?? ''}
                setFieldValue={onChangeText}
                handleSelectedPlace={handleSelectedPlace}
                onSubmitEditing={focusField('city')}
                returnKeyType={'next'}
                keyboardType={textInputKeyboardType}
                addressErrors={addressErrors}
            />
            <TextInput
                label={t('city')}
                keyboardAppearance="dark"
                onFocus={() => setShowPicker(false)}
                value={values.city ?? ''}
                onChangeText={(_masked: string, unmasked: string) => {
                    onChangeText('city', unmasked);
                }}
                ref={saveField('city')}
                onSubmitEditing={() => setShowPicker(true)}
                returnKeyType={'next'}
                autoCorrect={false}
                keyboardType={textInputKeyboardType}
            />
            {/**TODO: focus the zip field once the user selects a state in the picker
             * currently unable because the picker is rendered in KycStepContainer
             */}
            <PickerInput label={t('state')} value={americanStateName} setShowPicker={setShowPicker} />
            <TextInput
                label={t('zip_code')}
                keyboardAppearance="dark"
                inputMode="numeric"
                onFocus={() => setShowPicker(false)}
                value={values.zip ?? ''}
                onChangeText={(_masked: string, unmasked: string) => {
                    onChangeText('zip', unmasked);
                }}
                ref={saveField('zip')}
                mask={zipMask}
                returnKeyType={'done'}
                autoCorrect={false}
                hasError={!!addressErrors.zip}
                errorText={addressErrors.zip}
            />
        </View>
    );
};
