import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import DownIcon from '@/assets/icons/downSmall';
import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useBalanceModal } from '@/hooks/use-balance-modal';
import { useWallet } from '@/hooks/use-wallet';
import { designSystem } from '@/styles/styles';
import { toLocaleCurrency } from '@/utils/numeric/currency';

const styles = StyleSheet.create({
    balanceButton: {
        paddingHorizontal: 14,
        paddingVertical: 7,
        borderRadius: 20,
        borderColor: designSystem.colors.gray5,
    },
});

type BetSlipBalanceButtonProps = {
    testId?: string;
};

export const BetSlipBalanceButton = ({ testId }: BetSlipBalanceButtonProps) => {
    const { total } = useWallet();
    const { showBalanceModal } = useBalanceModal();
    const { t } = useTranslation('common');

    return (
        <Box alignItems={'center'}>
            <Button
                style={styles.balanceButton}
                onPress={showBalanceModal}
                label={
                    <Row alignItems="center">
                        <Text variant="labelLarge" color={'white'}>
                            {`${t('balance')}:`}
                        </Text>
                        <Text mr={'s8'} variant="labelLarge" color={total === 0 ? 'red' : 'white'}>
                            {` ${toLocaleCurrency(total)}`}
                        </Text>
                        <DownIcon color={designSystem.colors.gray1} />
                    </Row>
                }
                variant="rounded"
                type="tertiary"
                testID={testId}
            />
        </Box>
    );
};
