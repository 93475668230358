import { IdpvSourceType } from '@/navigation/types';
import { create } from 'zustand';

type DebitCardRemovalState = {
    /** The ID of the payment type (debit card) being removed */
    paymentTypeId: string | null;
    /** Indicates whether the selected debit card can be removed */
    canProcessRemoval: boolean;
    /** The source of the verification process for card removal */
    verificationSource: IdpvSourceType;
    /** Sets the payment type ID of the card to be removed */
    setPaymentTypeId: (id: string | null) => void;
    /** Sets whether the selected card can be removed */
    setCanProcessRemoval: (can: boolean) => void;
    /** Sets the source of the verification process for card removal */
    setVerificationSource: (source: IdpvSourceType) => void;
    /** Resets the removal process state to its initial values */
    resetState: () => void;
};

/**
 * Store to manage the state of the debit card removal process
 * @returns DebitCardRemovalState
 */
export const useDebitCardRemovalStore = create<DebitCardRemovalState>(set => ({
    paymentTypeId: null,
    canProcessRemoval: false,
    verificationSource: undefined,
    setPaymentTypeId: id => set({ paymentTypeId: id }),
    setCanProcessRemoval: can => set({ canProcessRemoval: can }),
    setVerificationSource: source => set({ verificationSource: source }),
    resetState: () => set({ paymentTypeId: null, canProcessRemoval: false, verificationSource: undefined }),
}));
