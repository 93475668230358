import React from 'react';

import { ChipButton } from '@/components/ChipButton';
import { Box } from '@/components/lib/components';

import { usePublishedMarkets } from '../hooks/use-market-cache';
import { useMarketName } from '../hooks/use-market-name';
import { MarketCategory } from '../types';

type SubMarketTabItemProps = {
    sportName: string;
    marketCategory: MarketCategory;
    isSelected: boolean;
    onPress: (marketCategory: MarketCategory) => void;
    testID?: string;
};

export const SubMarketTabItem = ({ sportName, marketCategory, isSelected, onPress, testID }: SubMarketTabItemProps) => {
    const marketName = useMarketName({
        sportName,
        marketType: marketCategory.market_type,
        fallback: marketCategory.market_type.code,
    });

    const publishedMarkets = usePublishedMarkets(marketCategory.markets);

    if (!publishedMarkets.length && !isSelected) {
        return null;
    }

    return (
        <Box mr="s8">
            <ChipButton
                label={marketName}
                active={isSelected}
                onPress={() => onPress(marketCategory)}
                testID={testID}
            />
        </Box>
    );
};
