import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { AcceptHigherMultiplerSwitch } from '@/components/AcceptHigherMultiplierSwitch';
import { AcceptMultiplierChangeSwitch } from '@/components/AcceptMultiplierChangeSwitch';
import { Box } from '@/components/lib/components';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { SwitchContainerRow } from './SwitchContainerRow';

const styles = StyleSheet.create({
    footer: {
        marginHorizontal: -16,
    },
});

export const MultiplierChangedInfoSheetFooter = () => {
    const { t } = useTranslation('betslip_sbk');
    const settings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);
    const enabledHigherMultipliers = settings?.higher_multiplier_toggle_enabled;
    return (
        <Box borderTopWidth={0.33} borderTopColor="gray5" px="s16" style={styles.footer} py="s8">
            <SwitchContainerRow
                label={t('always_accept_multiplier_changes')}
                switchButton={<AcceptMultiplierChangeSwitch />}
            />
            {enabledHigherMultipliers ? (
                <SwitchContainerRow
                    label={t('always_accept_higher_multipliers')}
                    switchButton={<AcceptHigherMultiplerSwitch />}
                />
            ) : null}
        </Box>
    );
};
