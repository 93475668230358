import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import FootballIcon from '@/assets/icons/footballIcon';
import Shield from '@/assets/icons/shield';
import TriangleLeft from '@/assets/icons/triangleLeft';
import { useEventTime } from '@/components/EventTime';
import { useLiveEventStatus } from '@/components/LiveEventStatus';
import { TeamScore, getTextColor } from '@/components/TeamScore';
import { Text } from '@/components/TextComponent';
import { Box, Column, Image, Row } from '@/components/lib/components';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { Event } from '@/feature/event-details-sbk/types';
import { getTeamColors } from '@/feature/event-details-sbk/utils/get-team-colors';
import { useEventStatus, useEventWinningSide, useFootballPossession } from '@/hooks/use-sbk-event-details-cache';
import { SbkLobbyTabNavigationProp } from '@/navigation/sbk/types';
import { SEPARATOR_HEGIHT } from '@/styles/styles';

const LOGO_SIZE = 24;

const styles = StyleSheet.create({
    logo: {
        width: LOGO_SIZE,
        height: LOGO_SIZE,
    },
});

type TeamMainInfoRowProps = {
    event: Event;
    side: 'home' | 'away';
    hasFootBallIcon: boolean;
    shouldShowFallbackLogo: boolean;
    testID?: string;
};

const TeamMainInfoRow = ({ side, event, shouldShowFallbackLogo, hasFootBallIcon, testID }: TeamMainInfoRowProps) => {
    const { homePrimary, homeSecondary, awayPrimary, awaySecondary } = getTeamColors(event);
    const winningSide = useEventWinningSide(event);
    const possession = useFootballPossession(event);
    const upperSide = side.toUpperCase();
    const shouldShowFootballIcon = hasFootBallIcon && possession === upperSide;

    const isAwayTeam = side === 'away';
    const secondaryColor = isAwayTeam ? awaySecondary : homeSecondary;
    const primaryColor = isAwayTeam ? awayPrimary : homePrimary;
    const teamName = isAwayTeam ? event.away_team.name : event.home_team.name;
    const uri = isAwayTeam ? event.away_team.logo_url : event.home_team.logo_url;

    return (
        <Row alignItems="center" position="relative" gap="s8">
            <Row gap="s8">
                {shouldShowFallbackLogo ? (
                    <Shield primary={primaryColor} secondary={secondaryColor} width={LOGO_SIZE} height={LOGO_SIZE} />
                ) : (
                    <Box height={LOGO_SIZE} width={LOGO_SIZE} alignItems="center" justifyContent="center">
                        <Image source={{ uri }} resizeMode="contain" style={styles.logo} />
                    </Box>
                )}
                <Text numberOfLines={1} variant="titleMedium" color={getTextColor(side, winningSide)} testID={testID}>
                    {teamName}
                </Text>
                {shouldShowFootballIcon ? (
                    <Row alignItems={'center'}>
                        <FootballIcon />
                    </Row>
                ) : null}
            </Row>
        </Row>
    );
};

type TeamScoreRowProps = {
    event: Event;
    side: 'home' | 'away';
    shouldShowTeamScore: boolean;
};

const TeamScoreRow = ({ event, side, shouldShowTeamScore }: TeamScoreRowProps) => {
    const winningSide = useEventWinningSide(event);
    const opacity = winningSide === side ? 1 : 0;

    return (
        <Row alignItems="center" position="relative" justifyContent="flex-end">
            {shouldShowTeamScore ? (
                <TeamScore event={event} side={side} variant="titleMedium" fontWeight="700" mr="s16" />
            ) : null}
            <Box position="absolute" right={0} opacity={opacity}>
                <TriangleLeft />
            </Box>
        </Row>
    );
};

const EventStatus = ({ event }: { event: Event }) => {
    const eventStatus = useEventStatus(event.id) ?? event.status;
    const eventTime = useEventTime(event);
    const liveEventStatus = useLiveEventStatus(event);
    const shortLiveEventStatus = liveEventStatus?.split(', ')[0];
    const isLive = eventStatus === 'LIVE';
    const isFinished = eventStatus === 'FINISHED';
    if (isLive) {
        return (
            <>
                {eventTime ? (
                    <Text color="gray2" variant="bodySmall">
                        {eventTime}
                    </Text>
                ) : null}
                {liveEventStatus ? (
                    <Text color="gray2" variant="bodySmall">
                        {shortLiveEventStatus}
                    </Text>
                ) : null}
            </>
        );
    }
    if (isFinished) {
        return (
            <Text color="gray2" variant="bodySmall">
                {eventTime}
            </Text>
        );
    }

    const [date, time] = eventTime.split(', ');
    return (
        <>
            <Text color="gray2" variant="bodySmall">
                {date}
            </Text>
            <Text color="gray2" variant="bodySmall">
                {time}
            </Text>
        </>
    );
};

export type EventInfoProps = {
    event: Event;
    testID?: string;
};

export const EventInfo = ({ event, testID }: EventInfoProps) => {
    const navigation = useNavigation<SbkLobbyTabNavigationProp>();
    const eventStatus = useEventStatus(event.id) ?? event.status;

    const isFootball = event.sport.name === 'American Football';
    const isFinished = eventStatus === 'FINISHED';
    const isLive = eventStatus === 'LIVE';

    const shouldShowTeamScore = isLive || isFinished;
    const hasFootBallIcon = isFootball && isLive;
    const shouldShowFallbackLogo = !event.home_team.logo_url || !event.away_team.logo_url;

    const handlePress = () => {
        GASbkEvents.clickEvent(event.id);
        navigation.navigate('EventDetails', { eventId: event.id, leagueId: event?.league?.id });
    };

    return (
        <TouchableOpacity onPress={() => handlePress()} accessible={false}>
            <Row flex={1} alignItems="stretch">
                <Column flex={1} gap="s4" pr="s16">
                    <TeamMainInfoRow
                        side="away"
                        testID={`awayTeamName-${testID}`}
                        {...{ event, shouldShowFallbackLogo, hasFootBallIcon }}
                    />
                    <TeamMainInfoRow
                        side="home"
                        testID={`homeTeamName-${testID}`}
                        {...{ event, shouldShowFallbackLogo, hasFootBallIcon }}
                    />
                </Column>

                <Row>
                    <Column gap="s4">
                        <TeamScoreRow side="away" {...{ event, shouldShowTeamScore }} />
                        <TeamScoreRow side="home" {...{ event, shouldShowTeamScore }} />
                    </Column>
                    <Column
                        width={96}
                        borderLeftWidth={SEPARATOR_HEGIHT}
                        py="s6"
                        pl="s16"
                        justifyContent="center"
                        alignItems="flex-start"
                        borderLeftColor="gray7"
                    >
                        <EventStatus event={event} />
                    </Column>
                </Row>
            </Row>
        </TouchableOpacity>
    );
};
