import React from 'react';

import { Bet } from '../../hooks/types';
import { MultiBetSummarySection } from './MultiBetSummarySection';
import { SingleBetSummarySection } from './SingleBetSummarySection';

export const BetSummarySection = ({ bet }: { bet: Bet }) => {
    switch (bet.bet_type) {
        case 'SINGLE':
            return <SingleBetSummarySection bet={bet} />;
        case 'SCRIPT':
        case 'COMBO':
            return <MultiBetSummarySection bet={bet} />;
    }
};
