import React from 'react';
import { ActivityIndicator, Animated, StyleSheet, View } from 'react-native';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { nativeDriverValue } from '@/utils/constants-platform-specific';
import { format, isSameMonth } from 'date-fns';

import { Transaction } from '../hooks/types';
import { TransactionCard } from './TransactionCard';

type LoadingMoreProps = { isFetchingNextPage: boolean };

const styles = StyleSheet.create({
    monthHeader: {
        paddingVertical: 8,
        marginTop: 20,
    },
    firstMonth: {
        marginTop: 8,
    },
});

const LoadingMore = ({ isFetchingNextPage }: LoadingMoreProps) => {
    return isFetchingNextPage ? (
        <View>
            <SizedBox value={8} />
            <ActivityIndicator />
            <SizedBox value={8} />
        </View>
    ) : null;
};

type TransactionListProps = {
    transactions: Transaction[];
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;
    scrollAnim: React.MutableRefObject<Animated.Value>;
};

export const TransactionList = ({
    transactions,
    fetchNextPage,
    isFetchingNextPage,
    scrollAnim,
}: TransactionListProps) => {
    return (
        <Animated.FlatList
            testID="transactionList"
            data={transactions}
            onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollAnim.current } } }], {
                useNativeDriver: nativeDriverValue,
            })}
            renderItem={({ item, index }) => {
                const isMonthRowIncluded =
                    index === 0 || !isSameMonth(item.created_at, transactions[index - 1].created_at);

                return (
                    <MaxWidthWrapper paddingHorizontal={'s16'}>
                        {isMonthRowIncluded ? (
                            <>
                                <Text
                                    variant="headlineMedium"
                                    style={[styles.monthHeader, index === 0 && styles.firstMonth]}
                                    testID={`monthHeader-${format(item.created_at, 'MMMM')}`}
                                >
                                    {format(item.created_at, 'MMMM')}
                                </Text>
                                <TransactionCard transaction={item} />
                            </>
                        ) : (
                            <TransactionCard transaction={item} />
                        )}
                    </MaxWidthWrapper>
                );
            }}
            keyExtractor={item => item.transaction_id ?? item.user_wallet_id}
            onEndReachedThreshold={0.5}
            onEndReached={fetchNextPage}
            ListFooterComponent={<LoadingMore isFetchingNextPage={isFetchingNextPage} />}
        />
    );
};
