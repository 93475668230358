import React from 'react';

import { Text, TextProps } from '@/components/TextComponent';
import { Event } from '@/feature/event-details-sbk/types';
import { useEventWinningSide, useTeamScore } from '@/hooks/use-sbk-event-details-cache';

type TeamScoreProps = {
    event: Event;
    side: 'home' | 'away';
} & TextProps;

export const isLosingSide = (side: 'home' | 'away', winningSide?: 'home' | 'away' | 'draw') => {
    return (side === 'home' && winningSide === 'away') || (side === 'away' && winningSide === 'home');
};

export const getTextColor = (side: 'home' | 'away', winningSide?: 'home' | 'away' | 'draw') => {
    return isLosingSide(side, winningSide) ? 'white' : 'gray1';
};

export const getTextOpacity = (side: 'home' | 'away', winningSide?: 'home' | 'away' | 'draw') => {
    return isLosingSide(side, winningSide) ? 0.6 : 1;
};

export const TeamScore = ({ event, side, ...textProps }: TeamScoreProps) => {
    const score = useTeamScore(event, side);
    const winningSide = useEventWinningSide(event);
    return (
        <Text color={getTextColor(side, winningSide)} opacity={getTextOpacity(side, winningSide)} {...textProps}>
            {score}
        </Text>
    );
};
