import React from 'react';
import { useTranslation } from 'react-i18next';

import PayPalIcon from '@/assets/icons/paypalAlt';

import { useDepositButtonNavigation } from '../hooks/use-deposit-button-navigation';
import { PaymentMethod } from '../hooks/use-deposit-payment-methods';
import { AddPaymentMethod } from './AddPaymentMethod';

type AddPaymentMethodPayPalProps = {
    paymentMethod: PaymentMethod;
    selectedAmount: string;
};

export const AddPaymentMethodPayPal = ({ paymentMethod, selectedAmount }: AddPaymentMethodPayPalProps) => {
    const { t } = useTranslation('wallet');
    const handleNavigation = useDepositButtonNavigation({
        selectedPaymentMethod: paymentMethod,
        selectedAmount,
    });

    return (
        <AddPaymentMethod
            icon={<PayPalIcon />}
            title={t('connect_paypal_account')}
            description={t('complete_on_paypal', { paymentMethodName: paymentMethod.name })}
            onPress={handleNavigation}
        />
    );
};
