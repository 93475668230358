import { useCallback } from 'react';

import { Product, getProductType, useJurisdictionStore } from './use-jurisdiction';

export const useAvailableProducts = () => {
    const availableProducts = useJurisdictionStore(state => state.jurisdictionSettings?.products ?? []);
    const activeProduct = useJurisdictionStore.getState().product;

    const isProductAvailable = useCallback(
        (product: Product) => {
            return availableProducts.find(p => getProductType(p.uid) === product);
        },
        [availableProducts]
    );

    const isProductActive = useCallback(
        (product: Product) => {
            return product === activeProduct;
        },
        [activeProduct]
    );

    return { isProductAvailable, isProductActive };
};
