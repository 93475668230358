/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View } from 'react-native';

export type SizedBoxProps = {
    value: number;
    width?: number;
    height?: number;
    debug?: boolean;
};

export const SizedBox = ({ value, width, height, debug = false }: SizedBoxProps) => {
    return (
        <View
            style={[
                {
                    width: value ? value : width || height,
                    height: value ? value : height || width,
                },
                debug
                    ? {
                          backgroundColor: 'rgba(125, 256, 125, 0.5)',
                          borderWidth: 1,
                          borderColor: 'cyan',
                      }
                    : null,
            ]}
        />
    );
};
