import React, { createContext, useCallback, useContext, useState } from 'react';

import { FloatingModal } from '@/components/FloatingModal';
import { Box } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

type FloatingModalContextType = {
    openModal: (uri: string) => void;
    closeModal: () => void;
    webviewUri: string;
};

const FloatingModalContext = createContext<FloatingModalContextType>({
    openModal: () => {},
    closeModal: () => {},
    webviewUri: '',
});

export const useFloatingModal = () => useContext(FloatingModalContext);

interface FloatingModalProviderProps {
    children: React.ReactNode;
}

export const FloatingModalProvider: React.FC<FloatingModalProviderProps> = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [webviewUri, setWebviewUri] = useState('https://betr.app/');

    const openModal = useCallback((uri: string) => {
        setWebviewUri(uri);
        setIsModalOpen(true);
    }, []);

    const closeModal = useCallback(() => setIsModalOpen(false), []);

    return (
        <FloatingModalContext.Provider value={{ openModal, closeModal, webviewUri: webviewUri }}>
            <Box height={0} zIndex={designSystem.zIndex.zIndex2}>
                <React.Fragment>{isModalOpen ? <FloatingModal key={webviewUri} /> : null}</React.Fragment>
            </Box>
            {children}
        </FloatingModalContext.Provider>
    );
};
