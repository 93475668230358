import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import BackArrow from '@/assets/icons/backArrow';
import DownIcon from '@/assets/icons/down';
import { SCREEN_NAV_BAR_HEIGHT } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { common, designSystem } from '@/styles/styles';

type LeagueNavigationBarProps = {
    hasBackground: boolean;
    selectedLeagueLabel?: string;
    showLeagueSelector: () => void;
};

export const LeagueNavigationBar = ({
    showLeagueSelector,
    hasBackground,
    selectedLeagueLabel,
}: LeagueNavigationBarProps) => {
    const navigation = useNavigation();

    return (
        <Box
            style={[
                common.spaceBetweenRow,
                { backgroundColor: hasBackground ? designSystem.colors.transparent : designSystem.colors.gray8 },
            ]}
            paddingVertical="s12"
            paddingLeft="s16"
            paddingRight={'s30'}
            height={SCREEN_NAV_BAR_HEIGHT}
        >
            <TouchableOpacity
                onPress={() => {
                    navigation.goBack();
                }}
                hitSlop={20}
                testID="goBackBtn"
            >
                <BackArrow />
            </TouchableOpacity>
            <TouchableOpacity style={styles.leagueSelector} onPress={showLeagueSelector}>
                <Text variant="titleLarge" testID="selectedLeagueLabel">
                    {selectedLeagueLabel}
                </Text>
                <DownIcon color={designSystem.colors.white} />
            </TouchableOpacity>
            <SizedBox value={5} />
        </Box>
    );
};

const styles = StyleSheet.create({
    leagueSelector: {
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
    },
});
