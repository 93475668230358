import { LobbyTrendingEventsQuery } from '@/api/events/query.generated';
import { EventInfo, isIndividualEvent, isTeamEvent } from '@/api/events/types/types';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';

export type GeneratedTrendingPlayer = PlayerWithTeam & { event: EventInfo };

export const generateTrendingPlayers = (
    events?: LobbyTrendingEventsQuery['getUpcomingLobbyEventsV2']
): GeneratedTrendingPlayer[] => {
    let trendingPlayers: GeneratedTrendingPlayer[] = [];

    events?.forEach(event => {
        if (isTeamEvent(event)) {
            event.teams.forEach(team => {
                team.players.forEach(player => {
                    trendingPlayers.push({
                        ...player,
                        team,
                        sport: team.sport,
                        league: team.league,
                        event,
                    });
                });
            });
        } else if (isIndividualEvent(event)) {
            event.players.forEach(player => {
                trendingPlayers.push({
                    ...player,
                    team: undefined,
                    sport: event.sport,
                    league: event.league,
                    event,
                });
            });
        }
    });
    return trendingPlayers;
};
