import React, { FC, ReactElement } from 'react';
import { StyleSheet, TouchableOpacity, TouchableOpacityProps } from 'react-native';

import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { designSystem } from '@/styles/styles';

import { LoadingSpinner } from './LoadingSpinner';
import { Text } from './TextComponent';
import { textVariants } from './lib/typography';

const styles = StyleSheet.create({
    root: {
        borderRadius: 8,
        paddingVertical: 9,
        paddingHorizontal: 19,
        borderWidth: 1,
        justifyContent: 'center',
        borderCurve: 'continuous',
    },
    primary: {
        backgroundColor: designSystem.colors.purple,
        borderColor: designSystem.colors.purple,
    },
    alert: {
        backgroundColor: designSystem.colors.red,
        borderColor: designSystem.colors.red,
    },
    secondary: {
        backgroundColor: designSystem.colors.gray6,
        borderColor: designSystem.colors.gray4,
    },
    active: {
        backgroundColor: designSystem.colors.gray1,
        borderColor: designSystem.colors.gray1,
    },
    activeGray: {
        backgroundColor: designSystem.colors.gray5,
        borderColor: designSystem.colors.gray5,
    },
    tertiary: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: designSystem.colors.white,
        paddingVertical: 9,
    },
    warningTertiary: {
        backgroundColor: 'transparent',
        borderColor: designSystem.colors.red,
    },
    text: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    plainText: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    disabled: {
        backgroundColor: designSystem.colors.gray5,
        borderColor: designSystem.colors.gray5,
    },
    // Size
    small: {
        paddingHorizontal: 16,
        paddingVertical: 8,
    },
    medium: {},
    large: {
        paddingHorizontal: 24,
        paddingVertical: 18,
    },
    pickButton: {
        paddingVertical: 9,
        paddingHorizontal: 19,
        borderColor: designSystem.colors.gray5,
        borderRadius: 22,
    },
    pickButtonActive: {
        borderColor: 'transparent',
    },
    delete: {
        borderColor: designSystem.colors.gray5,
        backgroundColor: designSystem.colors.gray5,
        borderRadius: 22,
    },
    emphasis: {
        backgroundColor: designSystem.colors.purple2,
    },
    muted: {
        backgroundColor: designSystem.colors.gray5,
    },
});

const textStyles = StyleSheet.create({
    root: {
        color: designSystem.colors.white,
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 15,
        lineHeight: 24,
    },
    primary: {
        color: designSystem.colors.white,
    },
    secondary: {
        color: designSystem.colors.white,
    },
    tertiary: {
        color: designSystem.colors.gray1,
    },
    warningTertiary: {
        color: designSystem.colors.red,
    },
    active: {
        color: designSystem.colors.gray8,
    },
    activeGray: {
        color: designSystem.colors.gray1,
    },
    text: {
        textDecorationLine: 'underline',
    },
    plainText: {
        textDecorationLine: 'none',
    },
    disabled: {
        color: designSystem.colors.gray8,
    },
    pickButton: {
        fontSize: 14,
        fontWeight: '600',
        letterSpacing: -0.15,
    },
    alert: {
        color: designSystem.colors.gray6,
    },
    delete: {
        color: designSystem.colors.red,
    },
    emphasis: {
        color: designSystem.colors.gray8,
        ...textVariants.titleMedium,
    },
    muted: {
        color: designSystem.colors.white,
        ...textVariants.titleMedium,
    },
});

const disabledStyles = StyleSheet.create({
    primary: {},
    secondary: {},
    tertiary: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    warningTertiary: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    alert: {},
    text: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    plainText: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    active: {
        backgroundColor: designSystem.colors.gray6,
        borderColor: designSystem.colors.gray6,
    },
    activeGray: {},
    delete: {},
    emphasis: {},
    muted: {},
});

const stylesVariant = StyleSheet.create({
    default: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    green: {
        backgroundColor: designSystem.colors.green,
        borderColor: designSystem.colors.green,
        color: designSystem.colors.gray8,
    },
    outlined: {
        backgroundColor: 'transparent',
        borderColor: designSystem.colors.gray6,
        color: designSystem.colors.white,
        fontWeight: '600',
        letterSpacing: -0.31,
    },
    roundedOutlined: {
        backgroundColor: 'transparent',
        borderColor: designSystem.colors.gray5,
        color: designSystem.colors.gray1,
        borderRadius: 24,
        fontSize: 13,
        fontWeight: '700',
        letterSpacing: -0.08,
        lineHeight: 20,
    },
    rounded: {
        borderRadius: 22,
    },
    redText: {
        color: designSystem.colors.red,
    },
    squareRoundedCorner: {
        borderRadius: 12,
    },
});

export interface ButtonProps {
    label: string | ReactElement;
    type?:
        | 'primary'
        | 'alert'
        | 'secondary'
        | 'active'
        | 'activeGray'
        | 'tertiary'
        | 'text'
        | 'plainText'
        | 'warningTertiary'
        | 'delete'
        | 'emphasis'
        | 'muted';
    size?: 'medium' | 'small' | 'large';
    variant?: keyof typeof stylesVariant;
    pickButton?: boolean;
    active?: boolean;
    loading?: boolean;
    loaderColor?: string;
    analyticsTag?: string;
}

export const Button: FC<ButtonProps & TouchableOpacityProps> = ({
    label,
    type = 'primary',
    size = 'medium',
    variant,
    style,
    pickButton = false,
    active = false,
    loading = false,
    loaderColor = designSystem.colors.gray8,
    analyticsTag,
    onPress,
    ...props
}) => {
    return (
        <TouchableOpacity
            style={[
                styles.root,
                styles[type],
                styles[size],
                variant && stylesVariant[variant],
                props.disabled && styles.disabled,
                props.disabled && disabledStyles[type],
                pickButton && styles.pickButton,
                active && pickButton && styles.pickButtonActive,
                style,
            ]}
            onPress={event => {
                const button = typeof label === 'string' ? label : analyticsTag;
                if (button) {
                    let params = { button, isPickButton: pickButton };
                    BetrAnalytics.trackEvent(AnalyticsEvent.CLICK, params);
                }

                onPress?.(event);
            }}
            {...props}
        >
            {loading ? (
                <LoadingSpinner size={24} color={loaderColor} displayLogo={false} />
            ) : typeof label === 'string' ? (
                <Text
                    style={[
                        textStyles.root,
                        textStyles[type],
                        variant && stylesVariant[variant],
                        props.disabled && textStyles.disabled,
                        active && textStyles.active,
                        pickButton && textStyles.pickButton,
                    ]}
                    variant={'buttonLabel'}
                >
                    {label}
                </Text>
            ) : (
                label
            )}
        </TouchableOpacity>
    );
};
