import { StyleSheet } from 'react-native';

import { designSystem } from '@/styles/styles';

const WIDTH = 48;
const HEIGHT = 36;

export const paymentMethodStyles = StyleSheet.create({
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 20,
        paddingRight: 8,
        paddingVertical: 16,
    },
    lineItemBorder: {
        borderBottomWidth: 1,
        borderBottomColor: designSystem.colors.gray5,
    },
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: WIDTH,
        height: HEIGHT,
        marginRight: 16,
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 8,
        overflow: 'hidden',
    },
    icon: {
        width: WIDTH,
        height: HEIGHT,
        borderWidth: 1,
        borderColor: designSystem.colors.gray6,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
    },
});
