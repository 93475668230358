import {
    TransactionDateOptions,
    TransactionDateOptionsType,
    TransactionFilterOption,
    TransactionTypes,
    TransactionTypesType,
} from './hooks/types';
import { TransactionRequestStatusType } from './hooks/use-transaction-details';

export const TRANSACTION_TYPE_OPTIONS: TransactionFilterOption<TransactionTypesType>[] = [
    {
        id: TransactionTypes.ALL_TYPES,
        label: 'all_types',
    },
    {
        id: TransactionTypes.DEPOSIT,
        label: 'deposits',
    },
    {
        id: TransactionTypes.WITHDRAWAL,
        label: 'withdrawals',
    },
    {
        id: TransactionTypes.FRE_BONUS,
        label: 'betr_bucks',
    },
    {
        id: TransactionTypes.BONUS,
        label: 'play_through_cash',
    },
];

export const TRANSACTION_DATE_OPTIONS: TransactionFilterOption<TransactionDateOptionsType>[] = [
    {
        id: TransactionDateOptions.ALL_TIME,
        label: 'all_time',
    },
    {
        id: TransactionDateOptions.LAST_MONTH,
        label: 'last_month',
    },
    {
        id: TransactionDateOptions.LAST_THREE_MONTH,
        label: 'last_three_month',
    },
    {
        id: TransactionDateOptions.LAST_SIX_MONTH,
        label: 'last_six_month',
    },
    {
        id: TransactionDateOptions.LAST_YEAR,
        label: 'last_year',
    },
    {
        id: TransactionDateOptions.DATE_RANGE,
        label: 'by_date_range',
    },
];

export const TRANSACTION_REQUEST_STATUS: Record<TransactionRequestStatusType, TransactionRequestStatusType> = {
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    NONE: 'NONE',
};
