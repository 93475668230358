import * as React from 'react';
import Svg, { Circle, ClipPath, Defs, G, Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
        <G fill={props.color ?? designSystem.colors.red} clipPath="url(#a)">
            <Circle cx={8} cy={8} r={8} opacity={0.2} />
            <Circle cx={8} cy={8} r={3} />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 0h16v16H0z" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgComponent;
