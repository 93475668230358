import { useEffect, useState } from 'react';

export const usePicker = <T extends Record<string, any>>(
    values: T,
    setFieldValue: <K extends keyof T>(name: K, value?: T[K]) => void,
    field: keyof T,
    items: { value: string; label: string }[]
) => {
    const [showPicker, setShowPicker] = useState(false);
    useEffect(() => {
        if (showPicker && !values[field]) {
            setFieldValue(field, items[0].value as T[keyof T]);
        }
    }, [values, showPicker, setFieldValue, field, items]);

    return { showPicker, setShowPicker };
};
