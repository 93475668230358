import {
    GlobalConfigurationsDocumentDataBodyBooleanFlagsSlice,
    GlobalConfigurationsDocumentDataBodyButtonSlice,
    GlobalConfigurationsDocumentDataBodyRichTextSlice,
} from '@/types/prismic.generated';
import { LinkField, RichTextField, Slice, SliceZone } from '@prismicio/client';

export function findSlice<T extends Slice>(type: T['slice_type'], slices: SliceZone<any> | undefined): T | undefined {
    return slices?.find(slice => slice.slice_type === type) as T | undefined;
}

export function findAllSlices<T extends Slice>(type: T['slice_type'], slices: SliceZone<any> | undefined): T[] {
    return (slices?.filter(slice => slice.slice_type === type) ?? []) as T[];
}

function getLinkFieldUrl(link: LinkField | undefined): string | undefined {
    if (link && 'url' in link) {
        return link.url;
    }
    return undefined;
}

export enum BooleanFlagKeys {
    AppFeatureFlags = 'AppFeatureFlags',
    AppFeatureFlagsWeb = 'AppFeatureFlagsWeb',
    DepositSalvageOptionSettings = 'DepositSalvageOptionSettings',
    DepositSalvageOptionSettingsWeb = 'DepositSalvageOptionSettingsWeb',
}

export const parseRichTextSlices = (
    slices: GlobalConfigurationsDocumentDataBodyRichTextSlice[]
): Record<string, RichTextField> => {
    return slices.reduce((acc: Record<string, RichTextField>, slice) => {
        const primaryLabel = slice.primary?.label;
        if (primaryLabel) {
            acc[primaryLabel] = slice.items[0]?.rich_text_editor;
        }
        return acc;
    }, {});
};

export const parseButtons = (slices: GlobalConfigurationsDocumentDataBodyButtonSlice[]) => {
    const data: Record<string, { label: string; url: string | undefined }> = {};
    slices?.forEach(slice => {
        const values = {
            label: slice.primary?.label ?? '',
            url: getLinkFieldUrl(slice.primary?.url),
        };
        if (slice.primary.name) {
            data[slice.primary.name] = values;
        }
    });
    return data;
};

export type AppFeatureFlags = Record<string, { enabled: boolean; description: string | undefined }>;

export const parseBooleanFlags = (
    slices: GlobalConfigurationsDocumentDataBodyBooleanFlagsSlice[],
    key: BooleanFlagKeys
): AppFeatureFlags => {
    const data: AppFeatureFlags = {};
    slices?.forEach(slice => {
        if (slice.primary?.name?.[0]?.text === key) {
            slice.items.forEach(item => {
                const featureKeyText = item.feature_key[0]?.text;
                if (featureKeyText) {
                    data[featureKeyText] = {
                        enabled: item.enabled,
                        description: item.description?.[0]?.text,
                    };
                }
            });
        }
    });
    return data;
};
