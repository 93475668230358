import React from 'react';
import { Switch } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { designSystem } from '@/styles/styles';

import { usePaymentFlags } from '../hooks/use-payment-flags-store';

export const PaymentMethodDebugFlags = () => {
    const { flags, toggleFlag, clearStorage } = usePaymentFlags();
    const { showInfoSheet } = useAlerts();

    return (
        <Box>
            <Text variant="headlineSmall" marginHorizontal="s8">
                Payment Method Debug Flags
            </Text>
            {Object.entries(flags).map(([key, flag]) => (
                <Box key={key} marginVertical="s8" flexDirection="row" alignItems="center">
                    <Switch
                        ios_backgroundColor={designSystem.colors.gray5}
                        value={flag.enabled}
                        onValueChange={() => toggleFlag(key as keyof typeof flags)}
                    />
                    <SizedBox value={8} />
                    <TouchableOpacity
                        onPress={() => {
                            showInfoSheet({
                                title: 'Description',
                                description: flag.description,
                                buttonLabel: 'OK',
                            });
                        }}
                    >
                        <Text>{flag.label}</Text>
                    </TouchableOpacity>
                </Box>
            ))}
            <SizedBox value={8} />
            <Button label="Clear Payment Flag Storage" onPress={clearStorage} />
            <SizedBox value={8} />
        </Box>
    );
};
