import { Linking, Platform } from 'react-native';
import { WebViewMessage } from 'react-native-webview/lib/WebViewTypes';

import { Share } from '@/feature/entry-share/utils/share';
import { logger } from '@/utils/logging';

const MessageType = {
    CALL_NATIVE_SHARE: 'CALL_NATIVE_SHARE',
    CALL_NATIVE_NAVIGATION: 'CALL_NATIVE_NAVIGATION',
} as const;

export const handleWebviewMessage = async (nativeEvent: WebViewMessage, linkTo?: (to: string) => void) => {
    try {
        const action = JSON.parse(nativeEvent?.data || '{}');
        switch (action?.type) {
            case MessageType.CALL_NATIVE_SHARE:
                // RN's share feature is used for reasons that browser share feature is not supported in Android webview.
                // Message source: https://github.com/betrapp/promos/pull/13/files#diff-c72d0ddf542005278f505746af2a70a9209256243880125580e8e85b2e52adc4R62-R64
                await Share.open({ ...action.payload, failOnCancel: false });
                break;
            case MessageType.CALL_NATIVE_NAVIGATION:
                linkTo?.('/' + action.payload.linkTo);
                break;
            default:
                break;
        }
    } catch (error) {
        logger.error('ModalWebView-onMessage', error);
    }
};

export const openExternalUrlAndroid = async (eventUrl: string) => {
    /* Checking whether there was a failed attempt to open an external URL (Android) */
    if (eventUrl?.includes('#Intent') && Platform.OS === 'android') {
        /* Removing "#Intent" string added by android to initial url*/
        const externalUrl = eventUrl.split('#Intent;')[0];
        /* Checking whether intent external url is supported */
        const urlIsSupported = await Linking.canOpenURL(externalUrl);
        if (urlIsSupported) {
            /* Opening external url */
            await Linking.openURL(externalUrl);
        } else {
            /* Opening external url on external browser */
            await Linking.openURL(externalUrl.replace('intent', 'https'));
        }
    }
};
