import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, TouchableOpacity, ViewStyle, useWindowDimensions } from 'react-native';

import { MaterialTopTabBarProps, createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { DefaultTheme, NavigationContainer, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';

import { CloseIcon } from '@/assets/icons/close';
import InfoIcon from '@/assets/icons/info';
import { Text } from '@/components/TextComponent';
import { SCROLLABLE_TOP_TAB_BAR_CONFIG, TopTabBar } from '@/components/TopTabBar';
import { Box, Row } from '@/components/lib/components';
import { P2pFaqModalContent } from '@/feature/p2p-faq/components/P2pFaqModal';
import { P2pLeaderboardContent } from '@/feature/p2p-leaderboards/components/P2pLeaderboardContent';
import { common, designSystem } from '@/styles/styles';
import { Pool } from '@/types/api.generated';
import { MAX_WEB_WIDTH, MODAL_PADDINGS, VERTICAL_MODAL_PADDINGS, isWeb } from '@/utils/constants-platform-specific';

const styles = StyleSheet.create({
    infoIcon: {
        flex: 1,
        alignItems: 'flex-end',
    },
    separator: {
        zIndex: -1,
        backgroundColor: designSystem.colors.gray5,
    },
    sceneContainerStyle: {
        backgroundColor: 'transparent',
    },
    tabBarLabelStyle: {
        textTransform: 'capitalize',
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 24,
        letterSpacing: -0.23,
    },
});

type Route = {
    key: string;
    title: string;
};

type P2pLeaderboardNavigationProp = NativeStackNavigationProp<P2pLeaderboardParamList, 'P2pLeaderboard'>;

const Tab = createMaterialTopTabNavigator();

type P2pLeaderboardProps = {
    boards: Pool[];
    dismissModal: () => void;
};

export const P2pLeaderboard = memo(({ boards, dismissModal }: P2pLeaderboardProps) => {
    // This navigation is linked to the independent NavigationContainer we use on P2pLeaderboardModal, not the main one
    const { navigate } = useNavigation<P2pLeaderboardNavigationProp>();
    const routes: Route[] = useMemo(
        () => boards.map((_, i) => ({ key: `board-${i + 1}`, title: `Board-${i + 1}` })),
        [boards]
    );
    const { t } = useTranslation('p2p');
    const { width } = useWindowDimensions();

    // avoid division by zero
    const notZeroTabLength = Math.max(1, routes.length);
    const widthPerTab = Math.min(4, notZeroTabLength);
    const screenWidth = MAX_WEB_WIDTH > width ? width : MAX_WEB_WIDTH;
    const totalWidth = isWeb ? screenWidth - MODAL_PADDINGS : width;
    // due to how tabs are rendered on web
    // we need to set a fixed width for the tabs if we want them to be animated/scrolled correctly
    const topTabBarWidth = routes.length < 10 ? totalWidth / widthPerTab : isWeb ? 120 : 'auto';

    const renderTabBar = useCallback(
        (props: MaterialTopTabBarProps) => (
            <TopTabBar
                {...props}
                customEdges={[]}
                forceShowOneTab={true}
                separatorStyles={styles.separator}
                customWidth={totalWidth}
                headerComponent={
                    <Row alignItems={'center'} justifyContent={'space-between'} paddingHorizontal={'s16'}>
                        <Box style={common.flex}>
                            {isWeb ? (
                                <Pressable onPress={dismissModal}>
                                    <CloseIcon />
                                </Pressable>
                            ) : null}
                        </Box>
                        <Box justifyContent={'center'}>
                            <Text variant={'titleLarge'} paddingVertical={'s12'}>
                                {t('leaderboards')}
                            </Text>
                        </Box>
                        <Box style={styles.infoIcon}>
                            <TouchableOpacity hitSlop={20} activeOpacity={0.8} onPress={() => navigate('P2pFAQ')}>
                                <InfoIcon width={24} height={24} color={designSystem.colors.white} />
                            </TouchableOpacity>
                        </Box>
                    </Row>
                }
            />
        ),
        [dismissModal, navigate, t, totalWidth]
    );

    return (
        <Tab.Navigator
            tabBar={renderTabBar}
            screenOptions={({ route }) => ({
                ...SCROLLABLE_TOP_TAB_BAR_CONFIG,
                tabBarItemStyle: {
                    ...(SCROLLABLE_TOP_TAB_BAR_CONFIG.tabBarItemStyle as ViewStyle),
                    width: topTabBarWidth,
                },
                tabBarLabel: route.name,
                tabBarLabelStyle: styles.tabBarLabelStyle,
                tabBarActiveTintColor: designSystem.colors.white,
                tabBarInactiveTintColor: designSystem.colors.gray3,
            })}
            sceneContainerStyle={styles.sceneContainerStyle}
            initialLayout={{ width: totalWidth }}
        >
            {/*
                avoid rendering no screens
                if no boards are present, render an empty screen instead
            */}
            {boards?.length > 0 ? (
                boards.map((board, index) => (
                    <Tab.Screen key={board.id} name={`board-${index + 1}`} options={{ title: 'Betr' }}>
                        {() => <P2pLeaderboardContent key={board.id} entries={board.entries} />}
                    </Tab.Screen>
                ))
            ) : (
                <Tab.Screen name={'board-empty'} options={{ title: 'Betr' }} component={Box} />
            )}
        </Tab.Navigator>
    );
});

export type P2pLeaderboardParamList = {
    P2pLeaderboard: undefined;
    P2pFAQ: undefined;
};

const Stack = createStackNavigator<P2pLeaderboardParamList>();

/**
 * We need to use the default screen transition for the P2pLeaderboard modal when navigating to the FAQ
 * We cannot render two navigators at the same time, so we need to create a new one for the P2pLeaderboard
 * It is independent from the main one, so it will not affect the main navigation, and it will not be affected by the main navigation
 * Any navigation action used down the component hierarchy will be done in the context of the P2pLeaderboard (navigation prop, or `useNavigation` hook)
 */
export const P2pLeaderboardModalContent = ({ boards, dismissModal }: P2pLeaderboardProps) => {
    const { height, width } = useWindowDimensions();

    return (
        <Box
            flex={isWeb ? undefined : 1}
            // on web the navigator adds `flex: 1` to all its children so we need to specify the width and height explicitly
            height={isWeb ? height - VERTICAL_MODAL_PADDINGS : undefined}
            width={isWeb ? (MAX_WEB_WIDTH > width ? width : MAX_WEB_WIDTH) - MODAL_PADDINGS : undefined}
        >
            <NavigationContainer
                independent
                theme={{
                    ...DefaultTheme,
                    // We need to set the background color to the same as the modal background color
                    // otherwise, the screen will flicker when navigating back
                    colors: { ...DefaultTheme.colors, background: designSystem.colors.gray6 },
                }}
            >
                <Stack.Navigator
                    initialRouteName="P2pLeaderboard"
                    screenOptions={{
                        cardStyle: {
                            backgroundColor: designSystem.colors.gray6,
                        },
                        headerShown: false,
                    }}
                >
                    <Stack.Screen name="P2pLeaderboard">
                        {() => <P2pLeaderboard dismissModal={dismissModal} boards={boards} />}
                    </Stack.Screen>
                    <Stack.Screen
                        name="P2pFAQ"
                        options={{
                            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                        }}
                    >
                        {/* This navigation is linked to the independent NavigationContainer we use on P2pLeaderboardModal, not the main one */}
                        {({ navigation }) => <P2pFaqModalContent goBack={() => navigation.goBack()} />}
                    </Stack.Screen>
                </Stack.Navigator>
            </NavigationContainer>
        </Box>
    );
};
