import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const IconPencil = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <G clipPath="url(#a)">
            <Path
                fill={designSystem.colors.white}
                d="m4.415 17.25-.193 2.528 2.526-.194 9.646-9.645-2.333-2.334-9.646 9.646ZM19.995 6.34 17.66 4.005 15.84 5.84l2.333 2.334 1.821-1.835Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill={designSystem.colors.white} d="M0 0h24v24H0z" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default IconPencil;
