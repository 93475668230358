import React from 'react';

import { Text, TextProps } from '@/components/TextComponent';

import { UseMarketNameProps, useMarketName } from '../hooks/use-market-name';

type MarketNameProps = UseMarketNameProps & TextProps;

export const MarketName = ({
    sportName,
    marketType,
    event,
    player,
    fallback,
    appendTeamName,
    appendPlayerName,
    appendMicroMarketDescription,
    isExtendedName,
    ...textProps
}: MarketNameProps) => {
    const marketName = useMarketName({
        sportName,
        marketType,
        player,
        event,
        fallback,
        appendTeamName,
        appendPlayerName,
        appendMicroMarketDescription,
        isExtendedName,
    });
    return <Text {...textProps}>{marketName}</Text>;
};
