import React from 'react';

import { SbkScoreboard } from '@/components/SbkScoreboard';
import { Box } from '@/components/lib/components';
import { useShowSgcInfo } from '@/feature/event-details-sbk/hooks/use-show-sgc-info';

import { Event } from '../types';
import { EventHudDetails } from './EventHudDetails';
import { SgcInfo } from './SgcInfo';

type EventHudProps = {
    hudHeight: number;
    event: Event;
    showScoreboard?: boolean;
};

export const EventHud = ({ event, hudHeight, showScoreboard }: EventHudProps) => {
    const showSgcInfo = useShowSgcInfo(event.is_sgp_enabled);
    return (
        <Box height={hudHeight}>
            <EventHudDetails event={event} />
            {showScoreboard ? (
                <Box py="s16">
                    <SbkScoreboard event={event} />
                </Box>
            ) : null}
            {showSgcInfo ? (
                <Box pt="s8" pb="s12">
                    <SgcInfo />
                </Box>
            ) : null}
        </Box>
    );
};
