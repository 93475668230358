import React from 'react';

import { TypographyProps } from '@shopify/restyle';

import { SizedBox } from '../SizedBox';
import { Text } from '../TextComponent';
import { Box } from '../lib/components';
import { AppTheme, DesignSystemColor } from '../lib/theme';
import { GridPeriodScore } from './types';

export const PeriodColumn = ({
    homeScore,
    awayScore,
    periodLabel,
    highlighted,
    minWidth = 16,
    fontSize,
    sizedBoxDimensions,
    fontWeight,
    textColor = 'gray3',
}: GridPeriodScore & {
    highlighted?: boolean;
    periodLabel: string;
    minWidth?: number;
    fontSize: number;
    sizedBoxDimensions: { top: number; middle: number; bottom: number };
    fontWeight?: TypographyProps<AppTheme>['fontWeight'];
    textColor?: DesignSystemColor;
}) => {
    const isOvertimeOrShootouts = periodLabel.includes('OT') || periodLabel.includes('SO');
    return (
        <Box minWidth={minWidth} alignItems={'flex-end'} marginLeft={isOvertimeOrShootouts ? 's8' : 's0'}>
            <SizedBox value={sizedBoxDimensions.top} />
            <Text
                variant="labelMedium"
                color={highlighted ? 'gray1' : textColor}
                fontSize={fontSize}
                fontWeight={fontWeight}
            >
                {periodLabel}
            </Text>
            <SizedBox value={sizedBoxDimensions.middle} />
            <Text
                variant="labelMedium"
                color={highlighted ? 'gray1' : 'gray2'}
                fontSize={fontSize}
                fontWeight={fontWeight}
            >
                {awayScore ?? '-'}
            </Text>
            <SizedBox value={sizedBoxDimensions.bottom} />
            <Text
                variant="labelMedium"
                color={highlighted ? 'gray1' : 'gray2'}
                fontSize={fontSize}
                fontWeight={fontWeight}
            >
                {homeScore ?? '-'}
            </Text>
        </Box>
    );
};
