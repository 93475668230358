import React from 'react';

import { Loading } from '@/components/Loading';
import { TextProps } from '@/components/TextComponent';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';

import { useComboOdds, useHasComboOddsChanged, usePreviousComboOdds } from '../../hooks/betslip-hooks';
import { BetIds } from '../../utils/betslip-utils';
import { BetSlipOdds } from '../BetSlipOdds';

export const ComboOdds = (props: TextProps) => {
    const odds = useComboOdds();
    const previousOdds = usePreviousComboOdds();
    const hasOddsChanged = useHasComboOddsChanged();
    const betId = BetIds.COMBO;
    const isSgpFetching = useSbkBetSlipStore(state => state.isSgpFetching);

    if (isSgpFetching) {
        return <Loading />;
    }

    return <BetSlipOdds {...{ odds, betId, previousOdds, hasOddsChanged }} {...props} />;
};
