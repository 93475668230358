import React, { Fragment } from 'react';
import { View } from 'react-native';

import Success from '@/assets/icons/checkmark';
import Fail from '@/assets/icons/fail-white';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { PaymentMethodTypes } from '@/feature/deposit/const';
import { ClosedLoopMethodProps } from '@/feature/withdraw/components/closedLoop.types';
import { common } from '@/styles/styles';

type TransactionItemResultProps = ClosedLoopMethodProps & { failed: boolean };

/**
 * Transaction item result for failed or successful transactions
 * */
export const TransactionItemResult = (item: TransactionItemResultProps) => {
    const getPaymentMethodDescription = () => {
        switch (item.type) {
            case PaymentMethodTypes.PaysafePaypal:
                return 'PayPal';
            case PaymentMethodTypes.PaysafeMazooma:
                return `Account (•••• ${item?.cc_last4})`;
            default:
                return `Debit card (•••• ${item?.cc_last4})`;
        }
    };

    return (
        <Fragment>
            <View style={[common.row, common.alignStart]}>
                {item.failed ? (
                    <Fail width={20} height={20} viewBox="0 0 60 60" />
                ) : (
                    <Success width={20} height={20} viewBox="0 0 25 25" />
                )}
                <SizedBox value={5} />
                <Text textAlign={'center'} color={'gray2'} fontWeight="500">
                    {getPaymentMethodDescription()} for ${item.amountToReturn}{' '}
                    {item.failed ? 'has failed' : 'is processing'}.
                </Text>
            </View>
            <SizedBox value={5} />
        </Fragment>
    );
};
