import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Path
            fill="#FA052E"
            fillRule="evenodd"
            d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
            clipRule="evenodd"
        />
        <Path
            fill="#000"
            d="m12 13.238 3.624 3.623 1.237-1.237L13.238 12l3.623-3.624-1.237-1.237L12 10.763 8.376 7.139 7.14 8.376 10.763 12l-3.624 3.624 1.237 1.237L12 13.238Z"
        />
    </Svg>
);
export default SvgComponent;
