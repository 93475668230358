import React, { memo } from 'react';

import { LineSeparator } from '@/components/LineSeparator';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { DollarSigns } from './DollarSigns';

export type WinningProjectionProps = {
    showSeparator?: boolean;
    boostedMultiplier?: number;
    multiplier?: number;
    winning: number;
    placeholderFilling?: number;
    displayMode: 'gameCard' | 'sheet';
    description: string;
};

export const WinningProjection = memo(
    ({
        description,
        multiplier,
        winning,
        showSeparator,
        placeholderFilling,
        displayMode,
        boostedMultiplier,
    }: WinningProjectionProps) => {
        const noAmount = winning <= 0;
        const sheetDisplay = displayMode === 'sheet';

        return (
            <Box>
                <Row alignItems={'center'} justifyContent={'space-between'}>
                    <Row alignItems={'center'}>
                        <Text variant={'bodySmall'} paddingVertical={sheetDisplay ? 's16' : 's10'} mr={'s12'}>
                            {description}
                        </Text>
                        <Row
                            paddingVertical={'s4'}
                            borderRadius={'r4'}
                            paddingHorizontal={'s6'}
                            backgroundColor={'gray5'}
                        >
                            {multiplier ? (
                                <Text
                                    variant={'labelMedium'}
                                    color={boostedMultiplier ? 'gray2' : 'gray1'}
                                    textDecorationLine={boostedMultiplier ? 'line-through' : 'none'}
                                >{`${multiplier}x`}</Text>
                            ) : null}
                            {boostedMultiplier ? (
                                <Text
                                    ml={'s4'}
                                    color={'boosted'}
                                    variant={'labelMedium'}
                                >{`${boostedMultiplier}x`}</Text>
                            ) : null}
                        </Row>
                    </Row>
                    {noAmount ? (
                        sheetDisplay ? (
                            <Text variant={'bodyMedium'} color={noAmount ? 'gray2' : 'white'}>
                                $--
                            </Text>
                        ) : (
                            <DollarSigns filling={placeholderFilling} />
                        )
                    ) : (
                        <Text variant={'bodyMedium'} color={noAmount ? 'gray2' : 'white'}>
                            {toLocaleCurrency(winning, false)}
                        </Text>
                    )}
                </Row>
                {showSeparator ? <LineSeparator /> : null}
            </Box>
        );
    }
);
