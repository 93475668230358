import React from 'react';

import { Text, TextProps } from '@/components/TextComponent';
import { Event } from '@/feature/event-details-sbk/types';
import { useEventDetailsCache, useEventStatus } from '@/hooks/use-sbk-event-details-cache';
import { formatSportPeriodDetails } from '@/utils/format-sport-period-details/format-sport-period-details';
import { useShallow } from 'zustand/react/shallow';

type EventTimeProps = {
    event: Event;
} & TextProps;

/**
 * Returns the current status of a live event. This is sport-specific and only applies to baseball and american football.
 *  - Baseball: '2 Outs'
 *  - American Football: '2nd & 10, MIA 20'
 */
export const useLiveEventStatus = (event: Event) => {
    const eventStatus = useEventStatus(event.id) ?? event.status;
    const sportSpecific = useEventDetailsCache(
        useShallow(state => state.eventDetails[event.id]?.sport_specific ?? event.event_details?.sport_specific)
    );
    if (eventStatus !== 'LIVE') {
        return null;
    }
    return formatSportPeriodDetails(event, sportSpecific);
};

export const LiveEventStatus = ({ event, ...textProps }: EventTimeProps) => {
    const time = useLiveEventStatus(event);
    return <Text {...textProps}>{time}</Text>;
};
