import { useFormatPeriod } from '@/hooks/use-format-period';
import { renderDate } from '@/utils/renderDate';

import { useEvent } from './betslip-hooks';
import { BetSlipEvent } from '../types';
import { FeaturedEvent } from '@/feature/lobby-sbk/components/FeaturedBets/types';

/**
 * Returns the formatted event header. Event header is dependent on different sports and statuses.
 * Live events will show the current score and time of the match, while upcoming events will show the team names and start time.
 */

export const useFormatEventHeaderByEventId = (eventId: string) => {
    const event = useEvent(eventId);
    return useFormatEventHeader(event);
};

export const useFormatEventHeader = (event: BetSlipEvent | FeaturedEvent) => {
    const periodTime = useFormatPeriod({
        event,
        periodName: event.event_details?.period_name,
        gameClock: event.event_details?.game_clock,
        sportSpecific: event.event_details?.sport_specific,
    });

    const eventTime = periodTime || renderDate(new Date(event.start_time));

    let eventTeams = `${event.away_team.short_code} @ ${event.home_team.short_code}`;

    if (event.status === 'LIVE') {
        const awayTeamDetails = [`${event?.away_team?.short_code ?? ''}`, `${event?.event_details?.away_score ?? ''}`]
            .filter(Boolean)
            .join(' ');
        const homeTeamDetails = [`${event?.home_team?.short_code ?? ''}`, `${event?.event_details?.home_score ?? ''}`]
            .filter(Boolean)
            .join(' ');
        eventTeams = `${awayTeamDetails} @ ${homeTeamDetails}`;
    }

    return [eventTeams, eventTime].filter(Boolean).join(' • ');
};
