import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { HTMLIFrameElement, IframeHandle } from '@/types/web';

type WebviewProps = {
    openNewTabOnWeb?: boolean;
    source: { uri: string; html?: string };
    onMessage?: (event: any) => void;
};

/**
 * Opens the external URL in a new tab
 * opens an iframe when we need to be inside the app navigation: login, signup, refer a friend etc
 */
const Webview = forwardRef<IframeHandle, WebviewProps>((props: WebviewProps, ref) => {
    const navigation = useNavigation();
    const { openNewTabOnWeb, source, onMessage } = props;
    const frameRef = useRef<HTMLIFrameElement>(null);

    useImperativeHandle(ref, () => {
        const postMessage = (message: string) => {
            if (frameRef.current) {
                frameRef.current.contentWindow?.postMessage(message, '*');
            }
        };
        return {
            postMessage,
        };
    });

    useEffect(() => {
        if (openNewTabOnWeb && source && 'uri' in source) {
            window.open(source.uri, '_blank');
            navigation.goBack();
        }
    }, [navigation, openNewTabOnWeb, source]);

    const onMessageWrapper = useCallback(
        (nativeEvent: any) => {
            if (onMessage) {
                onMessage({ nativeEvent });
            }
        },
        [onMessage]
    );

    useEffect(() => {
        if (onMessage) {
            window.addEventListener('message', onMessageWrapper, true);
        }
        return () => {
            if (onMessage) {
                window.removeEventListener('message', onMessageWrapper, true);
            }
        };
    }, [onMessage, onMessageWrapper]);

    return (
        <iframe
            ref={frameRef}
            src={source.uri}
            // needs this to allow clipboard access for the promos
            allow="clipboard-read; clipboard-write; web-share;"
            srcDoc={source.html}
            style={styles.screenSize}
        />
    );
});

const styles = StyleSheet.create({
    screenSize: { width: '100%', height: '100%' },
});
export default Webview;
