import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { Text } from '@/components/TextComponent';
import { Image, Row } from '@/components/lib/components';

import { PreBuiltBetType } from '../types';

type FeaturedBetCardFooterProps = {
    sponsorImage: string;
    prebuiltBetType: PreBuiltBetType;
};

export const FeaturedBetCardFooter = ({ sponsorImage, prebuiltBetType }: FeaturedBetCardFooterProps) => {
    const { t } = useTranslation('bets');

    return (
        <>
            {prebuiltBetType !== 'STANDARD' && sponsorImage !== '' ? (
                <Row alignItems={'center'} justifyContent={'center'} gap={'s6'} marginTop="s16">
                    <Text fontWeight={400} fontSize={13} lineHeight={16}>
                        {t('presented_by')}
                    </Text>
                    <Image source={{ uri: sponsorImage }} resizeMode="contain" style={styles.promotedImg} />
                </Row>
            ) : null}
        </>
    );
};

const styles = StyleSheet.create({
    promotedImg: {
        width: 70,
        height: 24,
    },
});
