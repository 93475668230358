import React from 'react';

import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';

import { BetListIcon } from './BetListIcon';

type BetListItemProps = {
    isFirstLeg: boolean;
    isLastLeg: boolean;
    isLastEvent: boolean;
    isEnabled: boolean;
    isSuspended: boolean;
    isConflicting: boolean;
    optionName: string;
    marketName: string;
    odds: number;
    previousOdds?: number;
    endComponent?: React.ReactElement;
};

export const BetListItem = ({
    isFirstLeg,
    isLastLeg,
    isLastEvent,
    isEnabled,
    isSuspended,
    isConflicting,
    endComponent,
    optionName,
    marketName,
    odds,
    previousOdds,
}: BetListItemProps) => {
    const isLast = isLastLeg && isLastEvent;

    return (
        <Box pt={isFirstLeg ? 's16' : 's12'} pb={isLastLeg ? 's20' : 's0'} pl="s16" pr="s0">
            <Box
                justifyContent="center"
                alignItems="center"
                top={isFirstLeg ? 4 : 0}
                left={10}
                width={2}
                bottom={0}
                position="absolute"
            >
                <Box
                    position="absolute"
                    top={0}
                    backgroundColor="gray6"
                    left={1}
                    width={2}
                    height={isSuspended ? 12 : 16}
                    borderRadius={'r2'}
                />
                <BetListIcon odds={odds} previousOdds={previousOdds} isSuspended={isSuspended} />
                <Box
                    position="absolute"
                    top={isSuspended ? 36 : 32}
                    backgroundColor="gray6"
                    left={1}
                    width={isLast ? 0 : 2}
                    borderRadius={'r2'}
                    bottom={isLastLeg ? 4 : -4}
                />
            </Box>

            <Row ml="s20" alignItems="center" justifyContent="space-between">
                <Box alignItems="flex-start" flexShrink={1}>
                    <Text
                        variant="titleMedium"
                        color={isSuspended || !isEnabled || isConflicting ? 'gray4' : 'gray1'}
                        testID="optionName"
                    >
                        {optionName}
                    </Text>
                    <Text
                        variant="bodySmall"
                        color={isSuspended || !isEnabled || isConflicting ? 'gray4' : 'gray2'}
                        testID="comboMarketName"
                    >
                        {marketName}
                    </Text>
                </Box>
                {endComponent ? (
                    <Box alignItems="flex-end" ml="s16">
                        {endComponent}
                    </Box>
                ) : null}
            </Row>
        </Box>
    );
};
