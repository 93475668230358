import { PlayerInfoWithProjectionsFragment } from '@/api/events/query.generated';

export const formatPlayerName = (
    { firstName, lastName }: Partial<PlayerInfoWithProjectionsFragment>,
    showFullName?: boolean
) => {
    return firstName && lastName
        ? `${showFullName ? firstName : `${firstName[0]}.`} ${lastName}`
        : firstName || lastName || '';
};
