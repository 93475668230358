import { DepositErrorResponse } from '@/feature/deposit/hooks/use-deposit';

/**
 * Get the error code from the error response.
 *
 * error.errors?.error_code -> used for errors with numbers, which are directly from Paysafe in the case of deposit error
 * error.error_codes?.[0] -> used for internal error_codes
 */
//TODO: Should make this generic in the future to handle API response's error_codes.
export const getWalletErrorCode = (error: DepositErrorResponse) => error.errors?.error_code ?? error.error_codes?.[0];
