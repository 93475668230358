import { MAX_SELECTIONS } from '../../types';

export type BetChannel = 'WEB' | 'MOBILE_WEB' | 'ANDROID' | 'IOS' | 'RETAIL' | 'OTHER';

export type BetType =
    | 'SINGLE'
    | 'DOUBLE'
    | 'TREBLE'
    | 'FOURFOLD'
    | 'FIVEFOLD'
    | 'SIXFOLD'
    | 'SEVENFOLD'
    | 'EIGHTFOLD'
    | 'NINEFOLD'
    | 'TENFOLD'
    | 'ELEVENFOLD'
    | 'TWELVEFOLD'
    | 'THIRTEENFOLD'
    | 'FOURTEENFOLD'
    | 'FIFTEENFOLD'
    | 'SIXTEENFOLD'
    | 'SEVENTEENFOLD'
    | 'EIGHTEENFOLD'
    | 'NINETEENFOLD'
    | 'TWENTYFOLD';

export type BetSelectionType = 'WIN' | 'BETBUILDER';

export type BetSettings = {
    currency: string;
    channel: BetChannel;
};

export type PlaceBetSelectionPart = {
    fixture_id: string;
    side_bet_id: string;
    winner_id: string;
    odds?: number;
    type: 'WIN';
    starting_price: boolean;
    best_odds_guaranteed: boolean;
    order: number;
};

export type PlaceBetSelection = {
    selection_type: BetSelectionType;
    parts: PlaceBetSelectionPart[];
};

export type PlaceBet = {
    is_fixed_odds: boolean;
    total_stake?: number;
    channel: BetChannel;
    bet_type: BetType;
    currency?: string;
    stake_per_line: number;
    use_free_money: boolean;
    selections: PlaceBetSelection[];
};

export type Customer = {
    user_id: string;
    latitude?: number;
    longitude?: number;
    esp_bet_key?: string;
    geotoken: string;
};

export type PlaceBetsRequest = {
    customer: Customer;
    bets: PlaceBet[];
};

export type PlaceBetsResponse = {
    data: Array<{ id: string }>;
};

// Internal errors, don't need to phrase
export const BetPayloadError = {
    MissingSelection: 'missing_selection',
    MissingOption: 'missing_option',
    MissingStake: 'missing_stake',
    MissingSgpOdds: 'missing_sgp_odds',
    ComboMinSelections: 'min_2_selections',
    ComboBetMaxSelections: `max_${MAX_SELECTIONS}_selections`,
};
