import React, { useCallback, useEffect, useRef } from 'react';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Box } from '@/components/lib/components';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { RootStackParamList } from '@/navigation/types';
import { getWalletErrorCode } from '@/utils/get-wallet-error-code';
import { AppFeatureFlags } from '@/utils/parse-prismic-content';

import { PaymentMethodTypes } from '../const';
import { DepositErrorResponse, useDeposit } from '../hooks/use-deposit';

type VerifyingDebitDepositProps = NativeStackScreenProps<RootStackParamList, 'VerifyingDebitDeposit'>;

// The error codes that we consider to be salvageable. If the error code returned from API is in this list, we will show the user the option to salvage the deposit.
const DEPOSIT_SALVAGEABLE_ERROR_CODES = [
    'paysafe_error_4002',
    'paysafe_error_3013',
    'paysafe_error_3023',
    'paysafe_error_3024',
];

export const VerifyingDebitDeposit = ({ route }: VerifyingDebitDepositProps) => {
    const { selectedAmount, currency = 'USD', payment_token = '', payment_type_id = '' } = route.params || {};

    const navigation = useDebitDepositStatusNavigation();
    const navigationRef = useRef(navigation);

    const depositSalvageOptions = useJurisdictionStore(
        state => state.jurisdictionSettings?.globalSettings?.depositSalvageOptions
    );

    const { mutate: deposit } = useDeposit();

    const handleDepositError = useCallback(
        (error: DepositErrorResponse) => {
            if (!depositSalvageOptions) {
                return;
            }
            const salvageOptionAvailable = hasAvailableSalvageOption(depositSalvageOptions);

            if (salvageOptionAvailable && DEPOSIT_SALVAGEABLE_ERROR_CODES.includes(error.errors.error_code)) {
                navigationRef.current.navigateToSalvageScreen(depositSalvageOptions, route.params);
            } else {
                navigationRef.current.navigateToErrorScreen(selectedAmount, getWalletErrorCode(error));
            }
        },
        [depositSalvageOptions, route.params, selectedAmount]
    );

    // The ref is used here to store the current navigation object, so that it can be accessed later in the onSuccess and onError callbacks of the mutate function.
    // This is necessary because the navigation object can change over the lifetime of the component, but we want to ensure that we're using the navigation object that was current at the time the mutate function was called.
    useEffect(() => {
        navigationRef.current = navigation;
    }, [navigation]);

    useEffect(() => {
        deposit(
            {
                amount: selectedAmount,
                type: PaymentMethodTypes.Paysafe,
                currency,
                payment_token,
                payment_type_id,
            },
            {
                onSuccess: () => navigationRef.current.navigateToSuccessScreen(selectedAmount),
                onError: handleDepositError,
            }
        );
    }, [currency, handleDepositError, deposit, payment_token, payment_type_id, selectedAmount]);

    return (
        <Box bg="gray8" flex={1} justifyContent="center">
            <LoadingSpinner />
        </Box>
    );
};

const hasAvailableSalvageOption = (options: AppFeatureFlags | undefined): boolean => {
    return options ? Object.values(options).some(option => option.enabled) : false;
};

/**
 * Custom hook to handle navigation for debit deposit operations.
 * It provides 3 functions to navigate to different screens:
 * - navigateToSuccessScreen: Navigates to the 'SuccessfulDepositModal' screen with a status of 'SUCCESS'.
 * - navigateToErrorScreen: Navigates to the 'FailedDepositModal' screen with a generic error message.
 * - navigateToSalvageScreen: Navigates to the 'DepositSalvage' screen.
 *
 * @returns An object with three functions for navigation.
 */
const useDebitDepositStatusNavigation = (): {
    navigateToSuccessScreen: (selectedAmount: string) => void;
    navigateToErrorScreen: (selectedAmount: string, errorCode: string) => void;
    navigateToSalvageScreen: (
        options: AppFeatureFlags,
        paymentParams: {
            selectedAmount: string;
            currency?: string;
            payment_token?: string;
            payment_type_id?: string;
        }
    ) => void;
} => {
    const navigation = useNavigation();

    const navigateToSuccessScreen = useCallback(
        (selectedAmount: string) => {
            navigation.navigate('SuccessfulDepositModal', {
                selectedAmount,
                paymentProvider: PaymentMethodTypes.Paysafe,
            });
        },
        [navigation]
    );

    const navigateToErrorScreen = useCallback(
        (selectedAmount: string, errorCode: string) => {
            navigation.navigate('FailedDepositModal', {
                selectedAmount,
                paymentProvider: PaymentMethodTypes.Paysafe,
                errorCode,
            });
        },
        [navigation]
    );

    const navigateToSalvageScreen = useCallback(
        (
            options: AppFeatureFlags,
            paymentParams: {
                selectedAmount: string;
                currency?: string;
                payment_token?: string;
                payment_type_id?: string;
            }
        ) => {
            navigation.navigate('DepositSalvage', {
                options,
                paymentParams,
            });
        },
        [navigation]
    );

    return {
        navigateToSuccessScreen,
        navigateToErrorScreen,
        navigateToSalvageScreen,
    };
};
