/**
 * Helper function for formatting date of birth for API
 * @param dob - date of birth string in format MMDDYYYY
 */
export const convertDobToObject = (dob: string) => {
    return {
        dob_day: Number(dob.slice(2, 4)),
        dob_month: Number(dob.slice(0, 2)),
        dob_year: Number(dob.slice(4, 8)),
    };
};

/**
 * Formats address-related strings into a formatted text.
 *
 * @param address - The street address.
 * @param city - The city name.
 * @param americanState - The state or region.
 * @param zip - The postal or ZIP code.
 * @returns The formatted text combining address, city, state, and ZIP.
 *
 * @example
 * formatAddressText('123 Main St', 'Vancouver', 'BC', '12345');
 * // Output: '123 Main St\nVancouver, BC 12345'
 */
export const formatAddressText = (address: string, city: string, americanState: string, zip: string): string => {
    const formattedAddress = address ? `${address}\n` : '';
    const formattedCity = city ? `${city}${americanState || zip ? ', ' : ''}` : '';
    const formattedState = americanState ? `${americanState} ` : '';
    const formattedZip = zip ? `${zip}` : '';
    return `${formattedAddress}${formattedCity}${formattedState}${formattedZip}`.trim();
};
