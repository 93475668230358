import React, { useCallback, useRef } from 'react';
import { useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { RefreshControl } from 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { EventInfo, EventInfoWithoutPlayers, isIndividualEvent, isTeamEvent } from '@/api/events/types/types';
import { LineSeparator } from '@/components/LineSeparator';
import { Loading } from '@/components/Loading';
import { SizedBox } from '@/components/SizedBox';
import { useStickyTabList } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { EmptyPage } from '@/feature/lobby/components/PlayersList';
import { usePickSelection } from '@/feature/lobby/hooks/use-pick-selection';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { SEPARATOR_HEGIHT, common, designSystem } from '@/styles/styles';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

import { GameCard } from './GameCard';

const styles = StyleSheet.create({
    separator: {
        marginHorizontal: 16,
        backgroundColor: designSystem.colors.gray5,
        height: SEPARATOR_HEGIHT,
    },
});

type Props = {
    sortedEvents: EventInfoWithoutPlayers[] | EventInfo[];
    loading: boolean;
    onRefresh: () => void;
    hideLoader: boolean;
    translationDistance: number;
};

const Footer = () => {
    return (
        <MaxWidthWrapper>
            <SizedBox value={78} />
        </MaxWidthWrapper>
    );
};

const ItemSeparator = () => (
    <MaxWidthWrapper>
        <LineSeparator style={[styles.separator]} />
    </MaxWidthWrapper>
);

export const EventsList = ({ sortedEvents, loading, onRefresh, translationDistance }: Props) => {
    const eventsListRef = useRef<FlatList<EventInfoWithoutPlayers> | null>(null);
    const lastAutoScrollIdx = useRef<number>(-1);
    const [openedGameIndex, setOpenedGameIndex] = useState(-1);
    const { makeSelection, openPlayerPickModal, removeSelection } = usePickSelection();

    const scrollToHeader = useCallback(
        (index: number) => {
            // Ensure that the index is within the bounds of sortedEvents
            if (index >= 0 && index < sortedEvents.length) {
                setTimeout(() => {
                    eventsListRef?.current?.scrollToIndex({
                        index,
                        animated: true,
                        viewPosition: 0,
                        viewOffset: translationDistance + TAB_HEIGHT,
                    });
                }, 200);
            }
        },
        [sortedEvents.length, translationDistance]
    );

    const insets = useSafeAreaInsets();
    const { scrollableProps, setStickyRef, fakeLoading, fakeRefresh, contentHeight, isActivelyScrolling } =
        useStickyTabList('events', loading, onRefresh);
    const paddingTop = translationDistance + TAB_HEIGHT;

    if (loading && sortedEvents.length === 0) {
        return (
            <View style={[common.justifyCenter, common.flex]}>
                <Loading />
            </View>
        );
    }

    return (
        <Animated.FlatList
            showsVerticalScrollIndicator={showScrollIndicator}
            nestedScrollEnabled={true}
            data={sortedEvents}
            extraData={openedGameIndex}
            ListFooterComponent={<Footer />}
            ListEmptyComponent={<EmptyPage loading={false} />}
            ListFooterComponentStyle={[common.full]}
            ItemSeparatorComponent={ItemSeparator}
            renderItem={({ item: game, index }) => {
                const isOpen = index === openedGameIndex;
                if (isTeamEvent(game) || isIndividualEvent(game)) {
                    return (
                        <MaxWidthWrapper>
                            <GameCard
                                key={game.id}
                                game={game}
                                isOpen={isOpen}
                                onPress={({ isLoaded }) => {
                                    setOpenedGameIndex(isOpen ? -1 : index);
                                    if (isLoaded && !isOpen) {
                                        //expanding game, scroll to the header
                                        scrollToHeader(index);
                                    }
                                }}
                                onDataLoaded={() => {
                                    if (isOpen && lastAutoScrollIdx.current !== index) {
                                        //game data loaded auto-scroll to the header
                                        lastAutoScrollIdx.current = index;
                                        scrollToHeader(index);
                                    }
                                }}
                                testID={`${index}`}
                                makeSelection={makeSelection}
                                removeSelection={removeSelection}
                                openPlayerPickModal={openPlayerPickModal}
                            />
                        </MaxWidthWrapper>
                    );
                }
                return null;
            }}
            {...scrollableProps}
            ref={ref => {
                eventsListRef.current = ref;
                setStickyRef(ref);
            }}
            refreshControl={
                <RefreshControl
                    colors={[designSystem.colors.purple]}
                    tintColor={designSystem.colors.white}
                    refreshing={fakeLoading}
                    progressViewOffset={paddingTop}
                    onRefresh={fakeRefresh}
                />
            }
            contentContainerStyle={[
                common.grow,
                {
                    // move all the content down to make space for the sticky tabs
                    paddingTop,
                    // set the height of the available content to be size of the content + padding
                    // so that the tabs can be completely scrolled up or down
                    minHeight: contentHeight + translationDistance - insets.top - TAB_HEIGHT,
                },
            ]}
            scrollToOverflowEnabled={isActivelyScrolling.current}
        />
    );
};
