import React, { useMemo } from 'react';

import { ScoreBoardGrid } from '@/components/scoreboard/ScoreBoard';
import { getActivePeriodIndex, getFinalScoreColumns, getScoreboard } from '@/feature/bets-sbk/utils/utils';
import { Event } from '@/feature/event-details-sbk/types';
import { getTeamName } from '@/feature/event-details-sbk/utils/get-team-name';
import { useEventDetail } from '@/hooks/use-sbk-event-details-cache';

type SbkScoreboardProps = {
    event: Event;
    matchDetails?: string;
};

export const SbkScoreboard = ({ event, matchDetails }: SbkScoreboardProps) => {
    const eventDetails = useEventDetail(event.id) ?? event.event_details;
    const breakdown = useMemo(() => getScoreboard(event.sport, eventDetails), [event.sport, eventDetails]);

    const finalScoreColumns = useMemo(
        () => (event && eventDetails ? getFinalScoreColumns(eventDetails, event.sport) : []),
        [event, eventDetails]
    );

    if (!breakdown) {
        return null;
    }

    const activePeriodIndex = breakdown && getActivePeriodIndex(breakdown, eventDetails.period_name);

    return (
        <ScoreBoardGrid
            awayTeamShortCode={getTeamName(event?.away_team)}
            awayTeamIcon={event?.away_team.logo_url || ''}
            homeTeamIcon={event?.home_team.logo_url || ''}
            homeTeamShortCode={getTeamName(event?.home_team)}
            breakdown={breakdown}
            finalScoreColumns={finalScoreColumns}
            activePeriodIndex={activePeriodIndex}
            matchDetails={matchDetails}
        />
    );
};
