import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.3333 10.0013C18.3333 14.6037 14.6023 18.3346 9.99996 18.3346C5.39759 18.3346 1.66663 14.6037 1.66663 10.0013C1.66663 5.39893 5.39759 1.66797 9.99996 1.66797C14.6023 1.66797 18.3333 5.39893 18.3333 10.0013ZM7.25907 6.07878L9.99996 8.81966L12.7408 6.07878L13.9194 7.25729L11.1785 9.99817L13.9257 12.7454L12.7472 13.924L9.99996 11.1767L7.25269 13.924L6.07418 12.7454L8.82145 9.99817L6.08056 7.25729L7.25907 6.07878Z"
            fill={props.color ?? designSystem.colors.gray3}
        />
    </Svg>
);

export default SvgComponent;
