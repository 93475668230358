import React from 'react';

import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';

type YearEndStatementRowProps = {
    leftLabel: string;
    rightLabel: string;
};

export const YearEndStatementRow = ({ rightLabel, leftLabel }: YearEndStatementRowProps) => {
    return (
        <Row
            alignItems="center"
            justifyContent="space-between"
            paddingVertical="s12"
            borderBottomWidth={1}
            borderBottomColor="gray5"
        >
            <Text variant="bodyMedium" pr="s12">
                {leftLabel}
            </Text>
            <Text variant="bodyMedium">{rightLabel}</Text>
        </Row>
    );
};
