import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutAnimation, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { common, designSystem } from '@/styles/styles';

const styles = StyleSheet.create({
    inputBox: {
        color: designSystem.colors.white,
        fontSize: 104,
        flexShrink: 1,
    },
    dollarContainer: {
        paddingTop: 20,
    },
});

type DepositNumberSectionProps = {
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    displayValue: string;
    quickDepositValuesComponent?: JSX.Element | null;
};

export const DepositNumberSection = ({
    isEditing,
    setIsEditing,
    displayValue,
    quickDepositValuesComponent = null,
}: DepositNumberSectionProps) => {
    const inputRef = useRef<TextInput>(null);
    const { t } = useTranslation(['wallet', 'common']);
    const { activeWalletProductName } = useActiveWallet();

    useEffect(() => {
        if (isEditing) {
            const timer = setTimeout(() => {
                inputRef.current?.focus();
            }, 300);

            return () => clearTimeout(timer);
        }
    }, [isEditing]);

    return (
        <>
            <View style={[common.row, common.justifyCenter]}>
                <View style={styles.dollarContainer}>
                    <Text variant="headlineLarge">$</Text>
                </View>
                <Text
                    style={styles.inputBox}
                    lineHeight={114}
                    fontWeight="600"
                    numberOfLines={1}
                    adjustsFontSizeToFit
                    testID="depositValue"
                >
                    {displayValue}
                </Text>
            </View>
            <Box paddingHorizontal="s16" marginBottom="s12">
                {quickDepositValuesComponent}
            </Box>
            {!isEditing && (
                <>
                    <TouchableOpacity
                        onPress={() => {
                            LayoutAnimation.configureNext({
                                duration: 300,
                                update: { type: 'linear' },
                            });
                            setIsEditing(true);
                        }}
                    >
                        <View style={[common.column, common.justifyCenter, common.alignCenter]}>
                            <Text>
                                {t('wallet:to_product_wallet', {
                                    activeWalletProduct: activeWalletProductName,
                                })}
                            </Text>
                            <Text fontWeight="bold" textDecorationLine={'underline'}>
                                {t('common:edit')}
                            </Text>
                        </View>
                    </TouchableOpacity>
                    <SizedBox value={12} />
                </>
            )}
        </>
    );
};
