import React, { createContext, useCallback, useContext, useState } from 'react';

import { FloatingModal } from '@/components/FloatingModal';
import { Box } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

export type FloatingModalConfig = {
    url: string;
    minimizedContentScale?: number;
};

type FloatingModalContextType = {
    openModal: (config: FloatingModalConfig) => void;
    closeModal: () => void;
    config: FloatingModalConfig;
};

const FloatingModalContext = createContext<FloatingModalContextType>({
    openModal: () => {},
    closeModal: () => {},
    config: {
        url: 'https://betr.app/',
    },
});

export const useFloatingModal = () => useContext(FloatingModalContext);

interface FloatingModalProviderProps {
    children: React.ReactNode;
}

export const FloatingModalProvider: React.FC<FloatingModalProviderProps> = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [config, setConfig] = useState<FloatingModalConfig>({ url: 'https://betr.app/' });

    const openModal = useCallback((modalConfig: FloatingModalConfig) => {
        setIsModalOpen(true);
        setConfig(modalConfig);
    }, []);

    const closeModal = useCallback(() => setIsModalOpen(false), []);

    return (
        <FloatingModalContext.Provider
            value={{
                openModal,
                closeModal,
                config,
            }}
        >
            <Box height={0} zIndex={designSystem.zIndex.zIndex2}>
                {isModalOpen ? <FloatingModal key={config.url} /> : null}
            </Box>
            {children}
        </FloatingModalContext.Provider>
    );
};
