import React from 'react';

import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';

/**
 * Renders 4 dollar signs with color filling.
 */
export const DollarSigns = ({ filling = 4 }: { filling?: number }) => {
    const dollarSigns = Array(4).fill('$');
    const normalizedFilling = Math.min(4, Math.max(1, filling));

    return (
        <Row>
            {dollarSigns.map((dollar, i) => {
                const color = i < normalizedFilling ? 'gray1' : 'gray4';
                return (
                    <Text key={dollar + i} variant={'bodyMedium'} color={color}>
                        {dollar}
                    </Text>
                );
            })}
        </Row>
    );
};
