import { useCallback, useRef } from 'react';
import { Platform } from 'react-native';
import geocomply from 'react-native-geo-comply-sdk-wrapper';
import { PERMISSIONS, check, checkLocationAccuracy, request, requestLocationAccuracy } from 'react-native-permissions';

import {
    CreateEntryMutation,
    GetEntryStatusDocument,
    GetEntryStatusQuery,
    useCreateEntryMutation,
} from '@/api/entries/query.generated';
import { GetEntryStatusQueryVariables } from '@/api/entries/query.generated';
import { getGeocomplyClient } from '@/data/location/geocomply-common';
import { useActiveGameMode } from '@/feature/betslip-pickem/hooks/use-active-game-mode';
import { useCurrency } from '@/feature/betslip-pickem/hooks/use-currency';
import { useEntryRules } from '@/feature/betslip-pickem/hooks/use-entry-rules';
import { BetslipPick } from '@/feature/betslip-pickem/types';
import { mapPicksToApiData } from '@/feature/betslip-pickem/utils/betslip-utils';
import { user, actions as userActions } from '@/hooks/use-user';
import { Entry, State } from '@/types/api.generated';
import { logger } from '@/utils/logging';
import { sleep } from '@/utils/promises';
import { useClient } from 'urql';

const POLL_ENTRY_STATUS_MAX_RETRIES = 5;

export const useEntries = () => {
    const client = useClient();

    const gameMode = useActiveGameMode();
    const { allEntryRules } = useEntryRules();
    const activeModeEntryRuleId = allEntryRules.find(rule => rule.gameMode === gameMode)?.id;
    const gameType = allEntryRules.find(rule => rule.gameMode === gameMode)?.gameType;
    const username = user.profile.preferred_username;

    const currency = useCurrency(gameMode);

    const [{ fetching: creating }, createEntry] = useCreateEntryMutation();

    const pollEntryRetries = useRef<number>(0);

    const addEntry = useCallback(
        async ({ amount, picks }: { amount: string; picks: BetslipPick[] }) => {
            let payload;

            await validateLocationAccuracy();

            try {
                if (!user.profile?.sub) {
                    logger.warn('ENTRY', 'required profile data not loaded, fetching now.');
                    userActions.loadProfile();
                }

                const geocomplyClient = getGeocomplyClient();
                geocomplyClient.configs.reason_code = geocomply().ReasonCode.PRE_WAGER;
                geocomplyClient.configs.reason = 'PRE_WAGER';
                const { decoded } = await geocomplyClient.request({ readCache: true, decode: true });
                payload = decoded;
                const notInCluj = payload?.original_payload?.nodes?.ip?.region_code !== 'CJ';

                if ((!payload?.can_bet && notInCluj) || !payload?.geo_session_token) {
                    throw payload;
                }
            } catch (e) {
                logger.error('ENTRY', 'geocomply error', `msg:${payload?.original_payload?.nodes?.error_message}`, e);
                throw payload;
            }
            const ip = payload?.original_payload?.nodes?.ip;
            const gps = payload?.original_payload?.nodes?.gps;
            const wifi = payload?.original_payload?.nodes?.wifi;
            const gsm = payload?.original_payload?.nodes?.gsm;

            const entryPayload: Entry = {
                amount: parseFloat(amount),
                entryRulesId: activeModeEntryRuleId,
                gameMode,
                username,
                gameType,
                currency,
                geoInformation: {
                    geoToken: payload.geo_session_token,
                    ip: ip?.ipaddress ?? '',
                    state: gps?.region_code || gsm?.region_code || ip?.region_code || wifi?.region_code || '',
                },
                picks: mapPicksToApiData(picks),
            };
            logger.info('ENTRY PAYLOAD', entryPayload);

            try {
                pollEntryRetries.current = 0;
                const { data: response, error: errorOnCreate } = await createEntry(
                    { entry: entryPayload },
                    { requestPolicy: 'network-only' }
                );
                if (response?.createEntry?.state === State.Accepted) {
                    return response!.createEntry;
                } else if (response?.createEntry.state === State.Processing) {
                    while (pollEntryRetries.current <= POLL_ENTRY_STATUS_MAX_RETRIES) {
                        pollEntryRetries.current = pollEntryRetries.current + 1;
                        logger.info('ENTRY', 'Polling submitted entry status attempt:', pollEntryRetries.current);
                        await sleep(pollEntryRetries.current * 1e3);
                        const { data: entryResponse, error: errorOnPoll } = await client
                            .query<GetEntryStatusQuery, GetEntryStatusQueryVariables>(
                                GetEntryStatusDocument,
                                {
                                    id: response.createEntry.id,
                                },
                                {
                                    requestPolicy: 'network-only',
                                }
                            )
                            .toPromise();

                        if (errorOnPoll) {
                            logger.error(
                                `Create entry poll-rejected! GraphQL error: ${errorOnPoll.name}(${errorOnPoll.response.reason_code})`,
                                { geoPayload: payload, err: errorOnPoll }
                            );
                        }
                        if (entryResponse?.getEntry?.state === State.Accepted) {
                            return entryResponse.getEntry;
                        } else if (entryResponse?.getEntry?.state === State.Rejected) {
                            throw new CreateEntryError(
                                `Create entry poll-rejected!${entryResponse.getEntry.reasonText}(${entryResponse.getEntry.reasonCode})`,
                                entryResponse?.getEntry
                            );
                        }
                    }
                    logger.info(
                        'ENTRY',
                        'Entry still processing after 5 retries. Bail out from polling and inform user.'
                    );
                    throw new CreateEntryTimeout(response.createEntry);
                } else {
                    let createEntryMsg = `Create entry:${response?.createEntry.state}!${response?.createEntry.reasonText}(${response?.createEntry.reasonCode})}`;
                    if (errorOnCreate && !response) {
                        createEntryMsg = `Create entry GQL error: ${errorOnCreate.message},${errorOnCreate.name},${errorOnCreate.response.reason_code}`;
                    }
                    throw new CreateEntryError(createEntryMsg, response?.createEntry);
                }
            } catch (e) {
                logger.error('ENTRY', 'error', e);
                throw e;
            }
        },
        [activeModeEntryRuleId, client, createEntry, currency, gameMode, gameType, username]
    );

    return {
        creating,
        addEntry,
    };
};

/**
 * Checks if the user has granted Precise location access, as this is needed by Geocomply, otherwise it will remain stuck in a loop
 * indefinitely waiting for a precise enough location.
 *
 * @throws CreateEntryAccuracyError if precise location access not granted.
 */
export const validateLocationAccuracy = async () => {
    if (Platform.OS === 'ios') {
        if (Number.parseInt(Platform.Version, 10) <= 14) {
            //No location accuracy pre iOS 14, so return
            return;
        }
        let locationAccuracy;
        try {
            locationAccuracy = await checkLocationAccuracy();
        } catch (e) {
            throw new CreateEntryAccuracyError('Can not check accuracy. Probably user not granted access.');
        }
        if (locationAccuracy === 'reduced') {
            //User has reduced accuracy, try to request precise as otherwise geocomply can't resolve
            const accuracyGranted = await requestLocationAccuracy({ purposeKey: 'fantasy-entry' });
            if (accuracyGranted !== 'full') {
                logger.warn('User not granted precise location.');
                throw new CreateEntryAccuracyError('Full location accuracy not granted.');
            }
        }
    } else if (Platform.OS === 'android') {
        const fineLocationAccess = await check(PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION);
        if (fineLocationAccess !== 'granted') {
            const fineLocationGranted = await request(PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION);
            if (fineLocationGranted !== 'granted') {
                logger.warn('User not granted precise location.');
                throw new CreateEntryAccuracyError('Full location accuracy not granted.');
            }
        }
    }
};

export class CreateEntryError extends Error {
    response: CreateEntryMutation['createEntry'] | undefined;

    constructor(message: string, response?: CreateEntryMutation['createEntry']) {
        super(message);
        this.response = response;
    }
}

export class CreateEntryAccuracyError extends Error {}

export class CreateEntryTimeout extends Error {
    response: CreateEntryMutation['createEntry'];

    constructor(response: CreateEntryMutation['createEntry']) {
        super('Create entry time-out getting accepted at FU');
        this.response = response;
    }
}
