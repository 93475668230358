import React from 'react';
import { useTranslation } from 'react-i18next';

import { RadioItem } from '@/components/RadioItem';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, TouchableBox } from '@/components/lib/components';
import { Product } from '@/hooks/use-jurisdiction';
import { designSystem } from '@/styles/styles';

type Props = {
    product: Product.Pickem | Product.Sportsbook;
    selected: boolean;
    onPress: () => void;
};

export const GameOption = ({ product, selected, onPress }: Props) => {
    const { t } = useTranslation('product_switch');

    return (
        <TouchableBox
            padding={'s20'}
            borderRadius={'r16'}
            borderWidth={2}
            gap={'s16'}
            borderColor={'gray5'}
            flexDirection={'row'}
            style={[selected && { borderColor: designSystem.colors.white }]}
            onPress={onPress}
        >
            <Box flex={1}>
                <Text variant="titleLarge">{t(`${product}.title`)}</Text>
                <SizedBox value={4} />
                <Text color={'gray2'} variant="bodyMedium">
                    {t(`${product}.description`)}
                </Text>
            </Box>
            <RadioItem selected={selected} />
        </TouchableBox>
    );
};
