import React, { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

type PopupContextType = {
    openPopUp: (popupName: string) => void;
    closePopUp: (popupName: string) => void;
    isPopUpOpen: (popupName: string) => boolean;
    closeAllPopUps: () => void;
    closeLastPopUp: () => void;
};
const PopupContext = createContext<PopupContextType>({
    openPopUp: () => {},
    closePopUp: () => {},
    isPopUpOpen: () => false,
    closeAllPopUps: () => {},
    closeLastPopUp: () => {},
});

export const PopupsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [popUps, setPopUps] = useState<Array<string>>([]);

    const openPopUp = useCallback(
        (popupName: string) => {
            setPopUps(prevPopUps => [...new Set([...prevPopUps, popupName])]);
        },
        [setPopUps]
    );

    const closePopUp = useCallback(
        (popupName: string) => {
            setPopUps(prevPopUps => prevPopUps.filter(popup => popup !== popupName));
        },
        [setPopUps]
    );

    const closeLastPopUp = useCallback(() => {
        setPopUps(prevPopUps => prevPopUps.slice(0, prevPopUps.length - 1));
    }, [setPopUps]);

    const closeAllPopUps = useCallback(() => {
        setPopUps([]);
    }, [setPopUps]);

    const isPopUpOpen = useCallback((popupName: string) => popUps.includes(popupName), [popUps]);

    return (
        <PopupContext.Provider value={{ isPopUpOpen, openPopUp, closePopUp, closeLastPopUp, closeAllPopUps }}>
            {children}
        </PopupContext.Provider>
    );
};

export const usePopup = () => useContext(PopupContext);
