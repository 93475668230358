import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import Star from '@/assets/icons/star';
import Trophy from '@/assets/icons/trophy';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { DesignSystemColor } from '@/components/lib/theme';
import { BSFlatList } from '@/feature/alerts/components/utils';
import { useUser } from '@/hooks/use-user';
import { designSystem, withOpacity } from '@/styles/styles';
import { PoolEntry, Result, UserExperienceLevel } from '@/types/api.generated';
import { showScrollIndicator } from '@/utils/constants-platform-specific';
import { TFunction } from 'i18next';

const styles = StyleSheet.create({
    tableHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: designSystem.colors.gray6,
        paddingVertical: 10,
        paddingHorizontal: 16,
        height: 52,
        alignItems: 'center',
    },
    columnRank: {
        flex: 1,
        textAlign: 'left',
    },
    columnPlayer: {
        flex: 3,
        textAlign: 'left',
    },
    columnAlignCenter: {
        flex: 1,
        textAlign: 'center',
    },
    resultLabel: {
        borderRadius: 6,
        minWidth: 46,
        height: 24,
    },
    trophy: {
        marginLeft: -5,
    },
});

type TableRowProps = {
    item: PoolEntry;
    index: number;
};

export const TableHeader = memo(() => {
    const { t } = useTranslation(['common', 'bets']);
    return (
        <Row style={styles.tableHeader}>
            <Text variant={'titleSmall'} style={[styles.columnRank]}>
                Rnk
            </Text>
            <Text variant={'titleSmall'} style={[styles.columnPlayer]}>
                {t('player')}
            </Text>
            <Text variant={'titleSmall'} style={[styles.columnAlignCenter]}>
                {t('picks')}
            </Text>
            <Text variant={'titleSmall'} style={[styles.columnAlignCenter]}>
                Pts
            </Text>
            <Text variant={'titleSmall'} style={[styles.columnAlignCenter]}>
                {t('bets:result')}
            </Text>
        </Row>
    );
});

const renderResultBox = (backgroundColor: string, textColor: DesignSystemColor, textContent: string) => (
    <Box
        paddingHorizontal={'s6'}
        justifyContent={'center'}
        alignItems={'center'}
        style={[styles.resultLabel, { backgroundColor }]}
    >
        <Text variant={'labelLarge'} color={textColor}>
            {textContent}
        </Text>
    </Box>
);

const getResultLabel = (t: TFunction<['p2p', 'common'], undefined>, result?: Result) => {
    const defaultBackgroundColor = withOpacity(designSystem.colors.white, 0.1);
    const defaultTextColor = 'gray2';

    switch (result) {
        case null:
            return renderResultBox(defaultBackgroundColor, defaultTextColor, t('common:open'));
        case Result.Win:
            const winBackgroundColor = withOpacity(designSystem.colors.green, 0.1);
            return renderResultBox(winBackgroundColor, 'green', t('common:won'));
        case Result.Void:
            return renderResultBox(defaultBackgroundColor, defaultTextColor, t('common:void'));
        case Result.Loss:
            return renderResultBox(defaultBackgroundColor, defaultTextColor, t('common:lost'));
        default:
            return null;
    }
};

const userExperienceStarsCount = {
    [UserExperienceLevel.Rookie]: 1,
    [UserExperienceLevel.Veteran]: 2,
    [UserExperienceLevel.AllStar]: 3,
};

export const TableRow = memo(({ item, index }: TableRowProps) => {
    const { numberOfWinningPicks, p2pPoints, result, username, userExperienceLevel, rank, count } = item;
    const { profile } = useUser();
    const entryWon = result === Result.Win;
    const { t } = useTranslation(['p2p', 'common']);
    const userWithHighestRank = index === 0;
    const isCurrentUser = profile?.preferred_username === username;
    const starCount = userExperienceStarsCount[userExperienceLevel];

    return (
        <Row
            paddingHorizontal={'s16'}
            paddingVertical={'s16'}
            backgroundColor={userWithHighestRank ? 'gray5' : 'gray6'}
            alignItems={'center'}
            position={'relative'}
        >
            {userWithHighestRank ? (
                <Box position="absolute" top={0} bottom={0} left={0} width={4} backgroundColor="purple" />
            ) : null}
            <Box flex={1}>
                {entryWon ? (
                    <Box style={styles.trophy}>
                        <Trophy />
                    </Box>
                ) : (
                    <Text variant={'bodySmall'} color={'white'}>
                        {rank ?? '--'}
                    </Text>
                )}
            </Box>
            <Box flex={3}>
                <Text variant={'bodySmall'} color={'white'} numberOfLines={1} ellipsizeMode="tail">
                    {isCurrentUser ? t('you') : username} {count > 1 ? `(${count})` : ''}
                </Text>
                <Row alignItems={'center'}>
                    <Text variant={'bodySmall'} color={'gray2'}>
                        {t('experience')}
                    </Text>
                    {Array.from({ length: starCount }).map((_, i) => (
                        <Star key={i} />
                    ))}
                </Row>
            </Box>
            <Text style={[styles.columnAlignCenter]} variant={'bodySmall'}>
                {numberOfWinningPicks ?? '--'} of {item.numberOfNonVoidPicks}
            </Text>
            <Text style={[styles.columnAlignCenter]} variant={'bodySmall'}>
                {p2pPoints ?? '--'}
            </Text>
            <Box style={styles.columnAlignCenter} alignItems={'center'} justifyContent={'center'}>
                {getResultLabel(t, result)}
            </Box>
        </Row>
    );
});

export const P2pLeaderboardContent = memo(({ entries }: { entries: PoolEntry[] }) => {
    return (
        <>
            <TableHeader />
            <BSFlatList
                data={entries}
                renderItem={({ item, index }) => <TableRow key={item.id} item={item} index={index} />}
                keyExtractor={(item, index) => `${item.id}-${index}`}
                showsVerticalScrollIndicator={showScrollIndicator}
                stickyHeaderIndices={[0]}
            />
        </>
    );
});
