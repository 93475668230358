import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { SEPARATOR_HEGIHT, common, designSystem } from '@/styles/styles';

import { Button } from './ButtonComponent';
import { Text } from './TextComponent';
import { Box } from './lib/components';

const styles = StyleSheet.create({
    container: {
        paddingTop: 7,
        backgroundColor: designSystem.colors.gray8,
    },
});

type ActionTrayProps = {
    buttonLabel: string;
    handlePress: () => void;
    secondaryLabel?: string;
    handleSecondaryPress?: () => void;
    loading?: boolean;
    disabled?: boolean;
    secondaryButton?: React.ReactNode;
    testID?: string;
};

export const ActionTray = ({
    buttonLabel,
    handlePress,
    secondaryLabel,
    handleSecondaryPress,
    loading,
    disabled,
    secondaryButton,
    testID = 'actionTrayButton',
}: ActionTrayProps) => {
    const safeInsets = useSafeAreaInsets();
    const [isLoading, setIsLoading] = useState(false);

    const handleSecondaryOnPress = async () => {
        if (!isLoading && handleSecondaryPress) {
            setIsLoading(true);
            await handleSecondaryPress();
            setIsLoading(false);
        }
    };

    return (
        <Box
            style={[
                common.full,
                common.zIndex1,
                styles.container,
                {
                    paddingBottom: safeInsets.bottom + 16,
                },
            ]}
            position={'absolute'}
            borderTopWidth={secondaryButton ? 0 : SEPARATOR_HEGIHT}
            borderTopColor="divider"
            paddingHorizontal="s16"
            bottom={0}
        >
            {secondaryButton ? (
                secondaryButton
            ) : (
                <Box mb="s4" alignItems="center">
                    <Button
                        label={
                            <Box borderBottomWidth={1} borderColor="gray2">
                                <Text textAlign="center" variant="labelLarge" color={'gray2'}>
                                    {secondaryLabel}
                                </Text>
                            </Box>
                        }
                        type="text"
                        disabled={isLoading}
                        onPress={handleSecondaryOnPress}
                    />
                </Box>
            )}
            <Button
                loading={isLoading || loading}
                label={buttonLabel}
                type="active"
                onPress={handlePress}
                variant="rounded"
                disabled={disabled}
                testID={testID}
            />
        </Box>
    );
};
