import { URLS, createJurisdictionHeaders } from '@/data';
import { FeaturedBet } from '@/feature/lobby-sbk/components/FeaturedBets/types';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { useQuery } from '@tanstack/react-query';

type GetFeaturedBetsResponse = {
    data: FeaturedBet[];
};

export const getFeaturedBets = async (): Promise<GetFeaturedBetsResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/events/featured-bets`, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            ...cacheControlHeader,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }

    return data;
};

export const featuredBetsKeys = {
    all: ['featured-bets'],
};

export const useFeaturedBets = () => {
    return useQuery(featuredBetsKeys.all, () => getFeaturedBets(), {
        // TODO filter out SGP and SGP+ bets until backend supports it
        select: data => data.data.filter(bet => bet.bet_type !== 'SGP' && bet.bet_type !== 'SGP+'),
    });
};
