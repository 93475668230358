import { useCallback, useMemo } from 'react';

import { useMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { useSubscribePublic } from '@/utils/websocket/hooks';
import { OddsUpdateMessageOption } from '@/utils/websocket/types';

/**
 * Subscribe to 'league:{leagueId}:marketGroup:{marketGroupId}' channel and listens for `odds_update` messages
 * Only handle messages that have marketId in the provided list
 */
export const useSubscribeFilteredLeagueOddsUpdate = (
    leagueId: string,
    marketGroupId: string,
    marketIds: string[],
    enabled: boolean = true
) => {
    const handleOddsUpdate = useMarketCacheStore(state => state.actions.handleOddsUpdate);
    const marketIdSet = useMemo(() => new Set(marketIds), [marketIds]);
    const params = useMemo(() => ({ leagueId, marketGroupId }), [leagueId, marketGroupId]);
    const messageHandler = useCallback(
        (messages: OddsUpdateMessageOption[]) => {
            const filteredMessages = messages.filter(message => marketIdSet.has(message.marketId));
            if (filteredMessages.length > 0) {
                handleOddsUpdate(filteredMessages);
            }
        },
        [marketIdSet, handleOddsUpdate]
    );

    useSubscribePublic<'leagueOddsUpdate'>(
        'league:{leagueId}:marketGroup:{marketGroupId}',
        params,
        'odds_update',
        messageHandler,
        enabled
    );
};
