import React from 'react';

import BetrBucks from '@/assets/icons/betr-bucks';
import { SEPARATOR_HEGIHT } from '@/styles/styles';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { Odds } from './Odds';
import { Text, TextProps } from './TextComponent';
import { Column, Row } from './lib/components';

export type BetSummarySectionProps = {
    stake: number;
    multiplier?: number;
    multiplierColor?: TextProps['color'];
    strikeThroughMultiplier?: number;
    strikeThroughMultiplierColor?: TextProps['color'];
    header1Text: string;
    header1TextColor?: TextProps['color'];
    body1TextColor?: TextProps['color'];
    body1TextDecorationLine?: TextProps['textDecorationLine'];
    header2Text: string;
    header2TextColor?: TextProps['color'];
    header3Text?: string;
    header3TextColor?: TextProps['color'];
    body3Text: string;
    body3TextColor?: TextProps['color'];
    bodyTextVariant?: TextProps['variant'];
    isBetrBucks: boolean;
};

export const BetSummarySection = ({
    stake,
    multiplier,
    multiplierColor = 'gray1',
    strikeThroughMultiplier,
    strikeThroughMultiplierColor = 'gray2',
    header1Text,
    header1TextColor = 'gray2',
    body1TextColor = 'gray1',
    body1TextDecorationLine = 'none',
    header2Text,
    header2TextColor = 'gray2',
    header3Text,
    header3TextColor = 'gray2',
    body3Text,
    body3TextColor = 'gray1',
    bodyTextVariant = 'labelLarge',
    isBetrBucks,
}: BetSummarySectionProps) => {
    return (
        <>
            <Column flex={1} alignItems="center">
                <Text variant="bodySmall" color={header1TextColor}>
                    {header1Text}
                </Text>
                <Row alignItems="center">
                    {isBetrBucks ? <BetrBucks height={16} /> : null}
                    <Text variant={bodyTextVariant} color={body1TextColor} textDecorationLine={body1TextDecorationLine}>
                        {toLocaleCurrency(stake)}
                    </Text>
                </Row>
            </Column>
            <Column
                flex={1}
                alignItems="center"
                borderLeftWidth={SEPARATOR_HEGIHT}
                borderRightWidth={SEPARATOR_HEGIHT}
                borderColor="gray7"
            >
                <Text variant="bodySmall" color={header2TextColor}>
                    {header2Text}
                </Text>
                <Row columnGap="s4">
                    {strikeThroughMultiplier ? (
                        <Odds
                            variant={bodyTextVariant}
                            odds={strikeThroughMultiplier}
                            textDecorationLine="line-through"
                            color={strikeThroughMultiplierColor}
                        />
                    ) : null}
                    {multiplier ? <Odds odds={multiplier} variant={bodyTextVariant} color={multiplierColor} /> : null}
                </Row>
            </Column>
            <Column flex={1} alignItems="center" justifyContent="center">
                {header3Text ? (
                    <Text variant="bodySmall" color={header3TextColor} textTransform="capitalize">
                        {header3Text}
                    </Text>
                ) : null}
                <Text variant={bodyTextVariant} color={body3TextColor}>
                    {body3Text}
                </Text>
            </Column>
        </>
    );
};
