import { useEffect } from 'react';

import { CONSTANTS } from '@/data';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';

const LOG_TAG = '[OneTrust]';

/**
 * Custom hook to initialize the OneTrust script for cookie consent management.
 *
 * This hook appends two script elements to the document body:
 * 1. A script to load the OneTrust SDK from a CDN.
 * 2. A script with inline JavaScript to define the `OptanonWrapper` function.
 *
 * The scripts are removed from the document body when the component using this hook is unmounted.
 *
 * @returns {void}
 */
export const useInitOneTrustScript = () => {
    useEffect(() => {
        if (isWeb) {
            const script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');

            script.setAttribute('charset', 'UTF-8');
            script.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
            script.setAttribute('async', 'true');
            script.setAttribute('data-domain-script', CONSTANTS.ONE_TRUST_WEB_APP_ID);
            document.body.appendChild(script);

            const scriptWithInnerHTML = document.createElement('script');
            scriptWithInnerHTML.setAttribute('type', 'text/javascript');
            scriptWithInnerHTML.innerHTML = /* js */ `
            function OptanonWrapper() {
                // Hide the floating cookie banner all the time
                if(OneTrust.IsAlertBoxClosed() === false){
                    const cookie = document.getElementById('ot-sdk-btn-floating')
                    cookie.style = 'display:none;'
                }
            }`;
            document.body.appendChild(scriptWithInnerHTML);

            logger.info(LOG_TAG, 'WEB SDK is initialized');

            return () => {
                document.body.removeChild(script);
                document.body.removeChild(scriptWithInnerHTML);
            };
        }
    }, []);
};
