import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Path
            fill="#6D707C"
            fillRule="evenodd"
            d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
            clipRule="evenodd"
        />
        <Path
            fill="#000"
            fillRule="evenodd"
            d="m16.743 18.559-1.163-1.164A6.502 6.502 0 0 1 6.593 8.41L5.43 7.245l1.237-1.237L17.981 17.32l-1.238 1.238ZM8.7 15.289a4.752 4.752 0 0 0 5.602.829L7.871 9.686a4.752 4.752 0 0 0 .83 5.602Z"
            clipRule="evenodd"
        />
        <Path
            fill="#000"
            d="M17.906 14.772a6.503 6.503 0 0 0-8.69-8.69l1.34 1.34a4.75 4.75 0 0 1 6.01 6.01l1.34 1.34Z"
        />
    </Svg>
);
export default SvgComponent;
