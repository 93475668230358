import React, { useEffect } from 'react';
import { Switch } from 'react-native';

import { queryClient } from '@/data';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { authUserKeys } from '@/hooks/use-auth-user-config';
import { AcceptMultiplierChanges, useAuthUserSettings, useUpdateUserSettings } from '@/hooks/use-auth-user-settings';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { designSystem } from '@/styles/styles';
import { logger } from '@/utils/logging';

const LOG_TAG = '[multiplier change switch]: ';

export const AcceptMultiplierChangeSwitch = () => {
    const { data } = useAuthUserSettings();
    const { mutateAsync: updateUserSettings } = useUpdateUserSettings();
    const acceptAllMultiplierChanges = data?.accept_multiplier_changes === AcceptMultiplierChanges.ALL;
    const settings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);
    const enabledHigherMultipliers = settings?.higher_multiplier_toggle_enabled;
    const updateBetSlipUserSettings = useSbkBetSlipStore(state => state.actions.updateUserSettings);
    const userSettingFromBetSlip = useSbkBetSlipStore(state => state.userSettings);
    useEffect(() => {
        if (data) {
            updateBetSlipUserSettings(data);
        }
    }, [acceptAllMultiplierChanges, data, updateBetSlipUserSettings]);

    const getAcceptMultiplierChangesValue = (value: boolean) => {
        if (!value) {
            return enabledHigherMultipliers ? AcceptMultiplierChanges.HIGHER : AcceptMultiplierChanges.NONE;
        }
        return AcceptMultiplierChanges.ALL;
    };

    const handleMultiplierChangeToggle = async (value: boolean) => {
        const acceptMultiplierChangesValue = getAcceptMultiplierChangesValue(value);
        if (value) {
            GASbkEvents.reviewMultiplierChanges('toggle_on');
        }
        try {
            await updateUserSettings({ accept_multiplier_changes: acceptMultiplierChangesValue });
            await queryClient.invalidateQueries(authUserKeys.settings());
        } catch (error: unknown) {
            logger.error(LOG_TAG, 'Error updating user settings', error);
            return;
        }
        if (userSettingFromBetSlip) {
            updateBetSlipUserSettings({
                ...userSettingFromBetSlip,
                accept_multiplier_changes: acceptMultiplierChangesValue,
            });
        }
    };

    return (
        <Switch
            trackColor={{ false: designSystem.colors.gray2, true: designSystem.colors.green }}
            thumbColor={designSystem.colors.gray1}
            ios_backgroundColor={designSystem.colors.gray2}
            onValueChange={handleMultiplierChangeToggle}
            value={acceptAllMultiplierChanges}
        />
    );
};
