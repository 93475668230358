import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = () => (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M4 4L12 12" stroke="#EB9500" strokeWidth={1.75} />
    </Svg>
);

export default SvgComponent;
