import { MarketType } from '@/feature/event-details-sbk/types';
import { formatPlayerName } from '@/utils/format-player-name';
import { ordinal } from '@/utils/numeric/format';

type AdditionalMicroMarketParams = {
    plate_appearance_ordinal?: string;
    score_index_ordinal?: string;
    drive_index_ordinal?: string;
    pitch_index_ordinal?: string;
    play_ordinal?: string;
    batter_player_name?: string;
    pitcher_player_name?: string;
};

export type MicroMarketParams = MarketType['params'] & AdditionalMicroMarketParams;

export const getMicroMarketDescriptionParams = (marketType: MarketType) => {
    let params: MicroMarketParams = { ...marketType?.params } ?? {};

    if (params.plate_appearance) {
        params = { ...params, plate_appearance_ordinal: ordinal(params.plate_appearance) };
    }
    if (params.score_index) {
        params = { ...params, score_index_ordinal: ordinal(params.score_index) };
    }
    if (params.pitch_index) {
        params = { ...params, pitch_index_ordinal: ordinal(params.pitch_index) };
    }
    if (params.drive_index) {
        params = { ...params, drive_index_ordinal: ordinal(params.drive_index) };
    }
    if (params.play) {
        params = { ...params, play_ordinal: ordinal(params.play) };
    }
    if (params.batter_player) {
        params = {
            ...params,
            batter_player_name: formatPlayerName({
                firstName: params.batter_player.first_name,
                lastName: params.batter_player.last_name,
            }),
        };
    }
    if (params.pitcher_player) {
        params = {
            ...params,
            pitcher_player_name: formatPlayerName({
                firstName: params.pitcher_player.first_name,
                lastName: params.pitcher_player.last_name,
            }),
        };
    }
    return params;
};
