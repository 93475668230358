import { useCallback } from 'react';

import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { useMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-market-cache';

import {
    AlternateMarketOptions,
    useAlternateMarketsModal,
} from '../components/alternate-markets/AlternateMarketsModalProvider';
import { Market } from '../types';

type UseMarketLineSelectionProps = {
    eventId: string;
    markets: Array<Market>;
    sortOptions?: AlternateMarketOptions['sortOptions'];
    marketAppendTeamName?: boolean;
};

export const MAX_NUM_OPTIONS = 10;
/**
 * Hook that for a given array of markets and event id, will check if there was made any selection for those market codes
 * (checking against POINTS_OVER_UNDER, PERIOD_POINT_OVER_UNDER etc.) and will return :
 * - selectedMarket - the last selected market or the main market (main line) if no market was selected for this market code
 * - hasAlternateMarkets - boolean indicating if there are more than one active markets
 * - showAlternateMarkets - function to show the alternate markets modal
 */
export const useMarketLineSelection = ({
    eventId,
    markets,
    sortOptions,
    marketAppendTeamName,
}: UseMarketLineSelectionProps): {
    selectedMarket: Market | undefined;
    hasAlternateMarkets: boolean;
    showAlternateMarkets: () => void;
    hasAdditionalOptions: boolean;
} => {
    const marketType = markets?.[0]?.market_type;
    const layout = markets?.[0]?.layout;
    const hasAdditionalOptions = markets?.[0]?.options.length > MAX_NUM_OPTIONS;
    const mainLineMarketOrFirst = useMarketCacheStore(
        state => markets?.find(m => state.markets[m.id]?.isMainLine) ?? markets?.[0]
    );

    const hasAlternateMarkets = markets.length > 1;

    const lastSelectedMarket = useSbkBetSlipStore(state => {
        const selectionsOrder = state.selectionOrder;
        const selectionsForMarketType = Object.values(state.selections).filter(
            selection => state.markets[selection.marketId].marketType.code === marketType?.code
        );
        // ! for multi_split_standard filter for selections only for the selected team (only the options found in active markets)
        const selectionsForMarketTypeAndTeam = selectionsForMarketType.filter(
            option => layout !== 'multi_split_standard' || !!markets.find(market => market.id === option.marketId)
        );

        const selectionsForMarketTypeSorted = selectionsForMarketTypeAndTeam.sort((a, b) => {
            return selectionsOrder.indexOf(b.id) - selectionsOrder.indexOf(a.id);
        });
        const lastMarketSelectionId = selectionsForMarketTypeSorted[0]?.marketId;
        return markets.find(m => m.id === lastMarketSelectionId);
    });

    const selectedMarket = lastSelectedMarket ?? mainLineMarketOrFirst;

    const { showAlternateMarketsModal } = useAlternateMarketsModal();
    const showAlternateMarkets = useCallback(() => {
        showAlternateMarketsModal({ eventId, selectedMarket, markets, sortOptions, marketAppendTeamName });
    }, [showAlternateMarketsModal, eventId, selectedMarket, markets, sortOptions, marketAppendTeamName]);

    return {
        selectedMarket,
        hasAlternateMarkets,
        hasAdditionalOptions,
        showAlternateMarkets,
    };
};
