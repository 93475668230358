import { AtLeast } from '@/types/utils';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { CustomTypeOptions, TFunction } from 'i18next';

import { LimitHistoryTypes, UserLimitDuration, UserLimitHistory, UserLimitType } from '../hooks/types';
import { SelfExcludeType } from '../hooks/use-self-exclude';

type Duration = UserLimitDuration;
type LimitHistoryTFunction = TFunction<['rg', 'common', 'self_exclusion']>;
type TranslationKeys = AtLeast<
    { [K in UserLimitType]: { [D in Duration]: keyof CustomTypeOptions['resources']['rg'] } },
    'DEPOSIT' | 'WAGER'
>;

const translationKeys: TranslationKeys = {
    DEPOSIT: {
        DAY: 'daily_deposit_limit',
        WEEK: 'weekly_deposit_limit',
        MONTH: 'monthly_deposit_limit',
    },
    WAGER: {
        DAY: 'daily_play_limit',
        WEEK: 'weekly_play_limit',
        MONTH: 'monthly_play_limit',
    },
};

/**
 * Get the translation key for a given limit type and duration.
 *
 * @param t - The translation function from `useTranslation`.
 * @param limitType - The type of limit.
 * @param duration - The duration of the limit. `DAY | WEEK | MONTH`.
 * @returns The translation key.
 */
export const getLimitHistoryTranslation = (
    t: LimitHistoryTFunction,
    limitType: LimitHistoryTypes,
    duration?: Duration
): string => {
    switch (limitType) {
        case 'SINGLE_WAGER':
            return t('max_single_play_limit');
        case SelfExcludeType.TIME_OUT:
            return t('timeout');
        case SelfExcludeType.SELF_EXCLUDE:
            return t('self_exclusion:self_exclude');
        default:
            if (duration && limitType !== 'LOSE') {
                return t(translationKeys[limitType]?.[duration]);
            }
            return '';
    }
};

export const getLimitHistoryDisplayAmount = (limitHistoryItem: UserLimitHistory, t: LimitHistoryTFunction) => {
    if (limitHistoryItem.amount === 0) {
        return t('common:removed');
    } else if (
        limitHistoryItem.limit_type === SelfExcludeType.TIME_OUT ||
        limitHistoryItem.limit_type === SelfExcludeType.SELF_EXCLUDE
    ) {
        return limitHistoryItem.amount;
    } else {
        return `${toLocaleCurrency(limitHistoryItem.amount)}`;
    }
};
