import { differenceInYears, format } from 'date-fns';

/**
 * Parses a string representation of date in the format "MMDDYYYY" and returns a Date object.
 * @param {string} dateString - The string representation of date in the format "MMDDYYYY".
 * @returns {Date} The Date object representing the parsed date from the input string.
 *
 * @example
 * const dateString = "20230727";
 * const dateObject = parseDateStringToDate(dateString);
 * // dateObject will be a Date object representing July 27, 2023.
 */
export const parseDateStringToDate = (dateString: string) => {
    const month = parseInt(dateString.substring(0, 2), 10);
    const day = parseInt(dateString.substring(2, 4), 10);
    const year = parseInt(dateString.substring(4, 8), 10);

    return new Date(year, month - 1, day);
};

/**
 * Formats the given date of birth string into a specified pattern.
 *
 * @param dob - The date of birth in string format.
 * @param pattern - The pattern to format the date of birth.
 * @returns {string} The formatted date of birth.
 *
 * @example
 * formatDob('07272023', 'dd/MM/yyyy')
 * returns '27/07/2023'
 */
export const formatDob = (dob: string, pattern: string) => {
    const birthDate = parseDateStringToDate(dob);
    return format(birthDate, pattern);
};

/**
 * Validate the date of birth is over 21 years old (including 21)
 * @param date string of date of birth (MMDDYYYY). e.g. 12012023
 * @returns boolean
 */
export function validateDob(date: string) {
    const dateRegex = new RegExp(/^(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(19|20)\d{2}$/);

    if (!dateRegex.test(date)) {
        return false;
    }
    const birth = parseDateStringToDate(date);
    const today = new Date();

    return differenceInYears(today, birth) >= 21;
}
