import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { ContactSupport } from '@/components/ContactSupport';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useUser } from '@/hooks/use-user';
import { navigateHome } from '@/navigation/navigation';
import { IdpvSource, IdpvSourceType } from '@/navigation/types';

/**
 * Hook to navigate to error modal with context-specific content.
 * @param {Object} params - Hook parameters
 * @param {IdpvSourceType} [params.source=IdpvSource.KYC] - Source of the error
 * @returns Function to navigate to error modal
 */
export const useNavigateToErrorModal = ({ source = IdpvSource.KYC }: { source?: IdpvSourceType } = {}) => {
    const navigation = useNavigation();
    const { t } = useTranslation(['kyc', 'common', 'manage_cards']);
    const { logout } = useUser();

    const handleCloseKyc = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.IDPV_FAILED_DISMISS);
        logout();
    };

    const navigateToErrorModal = () => {
        let title,
            subtitle,
            primaryButton,
            closeIconMode,
            handlePress,
            showSecondaryButton = true,
            closeIconHandlePress,
            showNavbarTitle = true,
            footer = null;

        switch (source) {
            case IdpvSource.ACCOUNT_SETTING:
                title = t('verification_account_settings_fail_title');
                subtitle = t('verification_account_settings_fail_description');
                primaryButton = t('common:done');
                showSecondaryButton = false;
                showNavbarTitle = false;
                closeIconMode = 'none' as const;
                handlePress = () => {
                    navigateHome(navigation, 'account');
                };
                break;
            case IdpvSource.MANAGE_CARD:
                title = t('manage_cards:not_able_to_complete_profile');
                subtitle = t('manage_cards:enhanced_profile_setup_failed');
                primaryButton = t('common:done');
                closeIconMode = 'close' as const;
                showSecondaryButton = false;
                closeIconHandlePress = () => navigation.navigate('ManageDebitCards');
                handlePress = () => navigation.navigate('AccountHome');
                footer = <ContactSupport />;
                break;
            default: // IdpvSource.KYC
                title = t('attempt_incomplete_title');
                subtitle = t('attempt_incomplete_subtitle');
                primaryButton = t('common:done');
                closeIconMode = 'close' as const;
                handlePress = handleCloseKyc;
                closeIconHandlePress = handleCloseKyc;
                showSecondaryButton = false;
                footer = <ContactSupport />;
        }

        navigation.navigate('ErrorModal', {
            title,
            subtitle,
            primaryButton,
            closeIconMode,
            showSecondaryButton,
            closeIconHandlePress,
            handlePress,
            showNavbarTitle,
            footer,
        });
    };

    return navigateToErrorModal;
};
