import React from 'react';

import { Accordion } from '@/components/Accordion';
import { AnimatedMicroMarketDescription } from '@/components/AnimatedMicroMarketDescription';
import { Box } from '@/components/lib/components';
import { useIsMarketPublished, useMarketStatus } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { useMicroMarket } from '@/feature/event-details-sbk/hooks/use-micro-market-cache';
import { Event } from '@/feature/event-details-sbk/types';

import { MarketName } from '../../MarketName';
import { MarketLayouts } from '../../market-layouts';

type MicroMarketAccordionProps = {
    microMarketId: string;
    event: Event;
    isExpanded?: boolean;
    testID?: string;
};

export const MicroMarketAccordion = ({ microMarketId, event, isExpanded, testID }: MicroMarketAccordionProps) => {
    const market = useMicroMarket(microMarketId);
    const isMarketPublished = useIsMarketPublished(market.id) ?? market.published;
    const marketStatus = useMarketStatus(market.id);
    const showDescription = marketStatus !== 'SUSPENDED' && isMarketPublished;

    if (!market) {
        return null;
    }

    return (
        <Accordion
            header={
                <>
                    <MarketName
                        sportName={event.sport.name}
                        event={event}
                        fallback={market.description}
                        marketType={market.market_type}
                        testID={`marketName-${testID}`}
                    />
                    <Box flexShrink={1}>
                        <AnimatedMicroMarketDescription
                            event={event}
                            marketType={market.market_type}
                            showDescription={showDescription}
                        />
                    </Box>
                </>
            }
            content={<MarketLayouts {...{ event, markets: [market], layout: market.layout }} />}
            initialExpanded={isExpanded}
        />
    );
};
