import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';

import { CancelEntryModal, CancelEntryModalRef, CancelEntryProps } from './CancelEntryModal';

type CancelEntry = {
    showCancelEntryModal: (props: CancelEntryProps) => void;
    dismissCancelEntryModal: () => void;
};

const CancelEntryContext = React.createContext<CancelEntry>({
    showCancelEntryModal: () => {},
    dismissCancelEntryModal: () => {},
});

/**
 * Provides access to the Modal responsible for displaying the confirmation prompt when canceling an entry.
 */
export const CancelEntryProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<CancelEntryModalRef>(null);

    const showCancelEntryModal = useCallback((props: CancelEntryProps) => {
        modalRef.current?.show(props);
    }, []);

    const dismissCancelEntryModal = useCallback(() => {
        modalRef.current?.dismiss();
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <CancelEntryContext.Provider value={{ showCancelEntryModal, dismissCancelEntryModal }}>
                {children}
                <CancelEntryModal ref={modalRef} />
            </CancelEntryContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useCancelEntry = () => {
    return useContext(CancelEntryContext);
};
