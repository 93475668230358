import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={8} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            fill={props.fill ?? '#6D707C'}
            d="M1.56.419a.5.5 0 0 0-.706 0l-.708.707a.5.5 0 0 0 0 .707l4.95 4.95a.5.5 0 0 1 0 .707l-4.95 4.95a.5.5 0 0 0 0 .706l.708.708a.5.5 0 0 0 .707 0L6.864 8.55a2 2 0 0 0 0-2.828L1.561.419Z"
        />
    </Svg>
);

export default SvgComponent;
