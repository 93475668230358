import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 26" width={48} height={26} fill="none" {...props}>
        <Path
            d="M7.62939e-06 4C7.62939e-06 4 9.479 0.5 24 0.5C38.521 0.5 48 4 48 4L48 26H0L7.62939e-06 4Z"
            fill={props.color ?? designSystem.colors.white}
        />
    </Svg>
);
export default SvgComponent;
