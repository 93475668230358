import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { ScrollableModal } from '@/feature/alerts/components/Modal';
import { common } from '@/styles/styles';
import { runStoreUpdate } from '@/utils/zustand';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { useBetslipStore } from '../hooks/use-betslip-store';
import { PickAndEventWithStatus } from '../types';
import { ProjectionChangedModalContent } from './LineupUpdateModal';

export type InfoLineupUpdateProps = {
    picksWithChangedProjections?: PickAndEventWithStatus[];
};

export type InfoLineupUpdateModalRef = {
    show: (data: InfoLineupUpdateProps) => void;
};

const modalId = 'infoLineupUpdate';

export const InfoLineupUpdateModal = React.forwardRef<InfoLineupUpdateModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<InfoLineupUpdateProps>();
    const updateProjections = useBetslipStore(state => state.actions.updateProjections);
    const [remainingPicks, setRemainingPicks] = useState<PickAndEventWithStatus[] | undefined>([]);
    const { t } = useTranslation('lineup_update_modal');

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    useEffect(() => {
        if (data?.picksWithChangedProjections) {
            setRemainingPicks(data.picksWithChangedProjections);
        } else {
            setRemainingPicks([]);
        }
    }, [data]);

    const modalRef = useRef<BottomSheetModal>(null);
    const safeInsets = useSafeAreaInsets();

    return (
        <ScrollableModal
            dismissible={false}
            footer={
                <Box pt={'s16'} px={'s16'} style={{ paddingBottom: safeInsets.bottom + 16 }}>
                    <Button
                        label={t('continue')}
                        type={'active'}
                        style={common.full}
                        variant={'rounded'}
                        size={'medium'}
                        onPress={() => {
                            if (remainingPicks) {
                                runStoreUpdate(() => {
                                    updateProjections(remainingPicks);
                                });
                            }
                            modalRef.current?.dismiss();
                        }}
                    />
                </Box>
            }
            sheetRef={modalRef}
            id={modalId}
        >
            {data ? (
                <Box>
                    <Box px={'s16'} pt={'s16'} pb={'s8'} alignItems={'center'}>
                        <Text variant={'headlineMedium'} color={'gray1'}>
                            {t('projections_have_changed')}
                        </Text>
                        <Text variant={'titleMedium'} textAlign={'center'} color={'gray2'}>
                            {t('accepted_changes')}
                        </Text>
                    </Box>
                    <ProjectionChangedModalContent
                        picks={remainingPicks}
                        setRemainingPicks={setRemainingPicks}
                        modalRef={modalRef}
                        mode={'lineup-update'}
                        disableChanges
                    />
                </Box>
            ) : null}
        </ScrollableModal>
    );
});
