import React, { ReactElement, useEffect, useState } from 'react';
import { Switch } from 'react-native';

import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { isBiometricEnabledSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useBiometricSetting } from '@/hooks/use-biometric-setting';
import { designSystem } from '@/styles/styles';
import { getIsBiometricsGranted, isBiometricSupported } from '@/utils/biometrics';

type BiometricsSwitchProps = {
    forceTrueOnDefault?: boolean;
};

export const BiometricsSwitch = ({ forceTrueOnDefault = false }: BiometricsSwitchProps): ReactElement | null => {
    const [enableBiometrics, setEnableBiometrics] = useState(forceTrueOnDefault);
    const [isSensorAvailable, setIsSensorAvailable] = useState(false);
    const { mutate: updateBiometricSetting } = useBiometricSetting();
    const { data: isBiometricEnabled = false, isFetched } = useAuthUserConfig({ select: isBiometricEnabledSelector });

    useEffect(() => {
        const initialize = async () => {
            const sensorAvailable = await isBiometricSupported();
            setIsSensorAvailable(sensorAvailable.available);

            if (forceTrueOnDefault && sensorAvailable.available) {
                setEnableBiometrics(true);
                if (await getIsBiometricsGranted()) {
                    updateBiometricSetting(true, {
                        onError: () => setEnableBiometrics(false),
                    });
                } else {
                    setEnableBiometrics(isBiometricEnabled);
                }
            } else {
                setEnableBiometrics(sensorAvailable.available && isBiometricEnabled);
            }
        };
        if (isFetched) {
            initialize();
        }
        // Removed isBiometricSupported from the dependency array
        // to avoid re-triggering code if forceTrueOnDefault is true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetched, forceTrueOnDefault, updateBiometricSetting, setEnableBiometrics]);

    const handleBiometricsToggle = async (value: boolean) => {
        setEnableBiometrics(value); // optimistic update
        const trackedEvent = value
            ? AnalyticsEvent.DETAILS_EDIT_BIOMETRICS_TURNON
            : AnalyticsEvent.DETAILS_EDIT_BIOMETRICS_TURNOFF;
        BetrAnalytics.trackProductEvent(trackedEvent);
        if (value) {
            const isBiometricsGranted = await getIsBiometricsGranted();
            if (!isBiometricsGranted) {
                setEnableBiometrics(!value); // revert if no permission
                BetrAnalytics.trackProductEvent(AnalyticsEvent.DETAILS_EDIT_BIOMETRICS_TURNOFF);
                return;
            }
        }

        updateBiometricSetting(value, {
            onError: () => setEnableBiometrics(!value), // revert if failed
        });
    };

    // Do not render the component if biometric sensor is not available
    if (!isSensorAvailable) {
        return null;
    }

    return (
        <Switch
            trackColor={{ false: designSystem.colors.gray2, true: designSystem.colors.green }}
            thumbColor={designSystem.colors.gray1}
            ios_backgroundColor={designSystem.colors.gray2}
            onValueChange={handleBiometricsToggle}
            value={enableBiometrics}
        />
    );
};
