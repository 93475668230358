import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Pressable, StyleSheet, useWindowDimensions } from 'react-native';
import { Gesture, GestureDetector, TouchableOpacity } from 'react-native-gesture-handler';
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withSpring, withTiming } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { CloseIcon } from '@/assets/icons/close';
import FullScreenIcon from '@/assets/icons/fullScreen';
import MinimizeIcon from '@/assets/icons/minimize';
import { designSystem, withOpacity } from '@/styles/styles';
import { useFloatingModal } from '@/utils/floatingModal/FloatingModalProvider';
import { openExternalUrlAndroid } from '@/utils/webview';

import CustomWebView from './CustomWebView';
import { SCREEN_NAV_BAR_HEIGHT } from './ScreenNavBar';
import { WebViewNavigation } from './Webview';
import { Box } from './lib/components';

const clamp = (val: number, min: number, max: number) => {
    'worklet';
    return Math.min(Math.max(val, min), max);
};

const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
    'worklet';
    return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
};

const MAX_X_VELOCITY = 600;
const MAX_Y_VELOCITY = 2000;

const MINIMIZED_MODAL_WIDTH = 249;
const MINIMIZED_MODAL_HEIGHT = 140;

const FULL_SCREEN_MODAL_HEIGHT = 203;

const FLOATING_CONTAINER_PADDING_HORIZONTAL = 16;

const ANIMATION_DURATION = 300;
const BUTTON_FADE_OUT_DELAY = 2000;
const BOTTOM_BAR_HEIGHT = 60;

export const FloatingModal = () => {
    const { width, height } = useWindowDimensions();

    const safeInsets = useSafeAreaInsets();

    const topBound = safeInsets.top + SCREEN_NAV_BAR_HEIGHT;
    const bottomBound = safeInsets.bottom + BOTTOM_BAR_HEIGHT + FLOATING_CONTAINER_PADDING_HORIZONTAL;

    const buttonOpacity = useSharedValue(0);
    const fadeOutTimeout = useRef<NodeJS.Timeout | null>(null);

    const animatedButtonStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(buttonOpacity.value, { duration: ANIMATION_DURATION }),
        };
    });

    // Clear the fade-out timeout if the buttons are toggled again
    const clearFadeOutTimeout = useCallback(() => {
        if (fadeOutTimeout.current) {
            clearTimeout(fadeOutTimeout.current);
            fadeOutTimeout.current = null; // Reset the timeout reference
        }
    }, []);

    const hideButtons = useCallback(() => {
        buttonOpacity.value = withTiming(0, { duration: ANIMATION_DURATION });
    }, [buttonOpacity]);

    // Function to trigger fade-in and fade-out of overlay and buttons
    const toggleButtonsOverlay = useCallback(() => {
        const isVisible = buttonOpacity.value === 0;
        buttonOpacity.value = isVisible ? 1 : 0;

        clearFadeOutTimeout();

        if (isVisible) {
            // fade out buttons after delay
            fadeOutTimeout.current = setTimeout(() => {
                runOnJS(hideButtons)();
            }, BUTTON_FADE_OUT_DELAY);
        }
    }, [buttonOpacity, clearFadeOutTimeout, hideButtons]);

    useEffect(() => clearFadeOutTimeout, [clearFadeOutTimeout]);

    const fullscreenModalWidth = width - FLOATING_CONTAINER_PADDING_HORIZONTAL * 2;

    const [isMinimized, setIsMinimized] = useState(true);

    // get current width
    const activeInstanceModalWidth = isMinimized ? MINIMIZED_MODAL_WIDTH : fullscreenModalWidth;
    const activeInstanceModalHeight = isMinimized ? MINIMIZED_MODAL_HEIGHT : FULL_SCREEN_MODAL_HEIGHT;

    // minized modal bounds
    const minXForMinimizedModal = FLOATING_CONTAINER_PADDING_HORIZONTAL;
    const maxXForMinimizedModal = width - MINIMIZED_MODAL_WIDTH - FLOATING_CONTAINER_PADDING_HORIZONTAL;
    const minYForMinimizedModal = topBound;
    const maxYForMinimizedModal = height - activeInstanceModalHeight - bottomBound;

    // full screen modal bounds
    const minXForFullScreenModal = FLOATING_CONTAINER_PADDING_HORIZONTAL;
    const maxXForFullScreenModal = FLOATING_CONTAINER_PADDING_HORIZONTAL;
    const minYForFullScreenModal = topBound;
    const maxYForFullScreenModal = height - activeInstanceModalHeight - bottomBound;

    // active bounds
    const activeMaxX = isMinimized ? maxXForMinimizedModal : maxXForFullScreenModal;
    const activeMaxY = isMinimized ? maxYForMinimizedModal : maxYForFullScreenModal;
    const activeMinX = isMinimized ? minXForMinimizedModal : minXForFullScreenModal;
    const activeMinY = isMinimized ? minYForMinimizedModal : minYForFullScreenModal;

    const ActiveIconComponent = isMinimized ? FullScreenIcon : MinimizeIcon;

    const modalWidth = useSharedValue(activeInstanceModalWidth);
    const modalHeight = useSharedValue(activeInstanceModalHeight);

    const translationX = useSharedValue(maxXForMinimizedModal);
    const translationY = useSharedValue(topBound);
    const prevTranslationX = useSharedValue(maxXForMinimizedModal);
    const prevTranslationY = useSharedValue(topBound);

    const animatedStyles = useAnimatedStyle(() => ({
        transform: [{ translateX: translationX.value }, { translateY: translationY.value }],
        width: withTiming(modalWidth.value, { duration: ANIMATION_DURATION }),
        height: withTiming(modalHeight.value, { duration: ANIMATION_DURATION }),
    }));

    const adjustModalPositionOnSizeToggle = () => {
        if (translationX.value >= maxXForFullScreenModal) {
            translationX.value = withTiming(maxXForFullScreenModal, { duration: ANIMATION_DURATION });
        }

        if (translationY.value >= maxYForFullScreenModal) {
            translationY.value = withTiming(maxYForFullScreenModal - BOTTOM_BAR_HEIGHT, {
                duration: ANIMATION_DURATION,
            });
        }
    };

    const handleExpandMinimize = () => {
        adjustModalPositionOnSizeToggle();
        modalWidth.value = isMinimized ? fullscreenModalWidth : MINIMIZED_MODAL_WIDTH;
        modalHeight.value = isMinimized ? FULL_SCREEN_MODAL_HEIGHT : MINIMIZED_MODAL_HEIGHT;
        setIsMinimized(prev => !prev);
    };

    const pan = Gesture.Pan()
        .minDistance(1)
        .onStart(() => {
            'worklet';
            prevTranslationX.value = translationX.value;
            prevTranslationY.value = translationY.value;
        })
        .onUpdate(event => {
            'worklet';
            translationX.value = clamp(prevTranslationX.value + event.translationX, activeMinX, activeMaxX);
            translationY.value = clamp(prevTranslationY.value + event.translationY, activeMinY, activeMaxY);
        })
        .onEnd(event => {
            'worklet';
            const toss = 0.2;

            const targetX = clamp(translationX.value + toss * event.velocityX, activeMinX, activeMaxX);
            const targetY = clamp(translationY.value + toss * event.velocityY, activeMinY, activeMaxY);

            // Calculate distances to each corner (top-left, top-right, bottom-left, bottom-right)
            const distanceToTopLeft = getDistance(targetX, targetY, activeMinX, activeMinY);
            const distanceToTopRight = getDistance(targetX, targetY, activeMaxX, activeMinY);
            const distanceToBottomLeft = getDistance(targetX, targetY, activeMinX, activeMaxY);
            const distanceToBottomRight = getDistance(targetX, targetY, activeMaxX, activeMaxY);

            // Determine the closest corner
            const minDistance = Math.min(
                distanceToTopLeft,
                distanceToTopRight,
                distanceToBottomLeft,
                distanceToBottomRight
            );

            let snapX = targetX;
            let snapY = targetY;

            if (minDistance === distanceToTopLeft) {
                snapX = activeMinX;
                snapY = activeMinY;
            } else if (minDistance === distanceToTopRight) {
                snapX = activeMaxX;
                snapY = activeMinY;
            } else if (minDistance === distanceToBottomLeft) {
                snapX = activeMinX;
                snapY = activeMaxY;
            } else if (minDistance === distanceToBottomRight) {
                snapX = activeMaxX;
                snapY = activeMaxY;
            }

            translationX.value = withSpring(snapX, {
                velocity: clamp(event.velocityX, -MAX_X_VELOCITY, MAX_X_VELOCITY),
                damping: 15,
                stiffness: 120,
                mass: 1,
            });

            translationY.value = withSpring(snapY, {
                velocity: clamp(event.velocityY, -MAX_Y_VELOCITY, MAX_Y_VELOCITY),
                damping: 15,
                stiffness: 110,
                mass: 1,
            });
        });

    const { webviewUri, closeModal } = useFloatingModal();

    return (
        <GestureDetector gesture={pan}>
            <Animated.View style={[animatedStyles, styles.container]}>
                <CustomWebView
                    uri={webviewUri}
                    onNavigationStateChange={(event: WebViewNavigation) => openExternalUrlAndroid(event?.url)}
                    openNewTabOnWeb={true}
                    styles={[styles.webView]}
                />
                <Pressable onPress={toggleButtonsOverlay} style={{ ...StyleSheet.absoluteFillObject }}>
                    {/* Close and Minimize Buttons */}
                    <Animated.View style={[styles.overlay, animatedButtonStyle, { ...StyleSheet.absoluteFillObject }]}>
                        <Box position={'absolute'} top={10} right={10} zIndex={designSystem.zIndex.zIndex1}>
                            <TouchableOpacity
                                onPress={() => {
                                    if (buttonOpacity.value === 0) {
                                        closeModal();
                                    }
                                }}
                            >
                                <CloseIcon height={24} width={24} />
                            </TouchableOpacity>
                        </Box>
                        <Box position={'absolute'} top={10} left={10} zIndex={designSystem.zIndex.zIndex1}>
                            <TouchableOpacity
                                onPress={() => {
                                    if (buttonOpacity.value === 0) {
                                        handleExpandMinimize();
                                    }
                                }}
                            >
                                <ActiveIconComponent height={24} width={24} />
                            </TouchableOpacity>
                        </Box>
                    </Animated.View>
                </Pressable>
            </Animated.View>
        </GestureDetector>
    );
};

const styles = StyleSheet.create({
    webView: {
        borderRadius: 16,
        borderColor: designSystem.colors.gray5,
        borderWidth: 1,
    },
    overlay: {
        backgroundColor: withOpacity(designSystem.colors.gray6, 0.5),
        ...StyleSheet.absoluteFillObject,
        zIndex: -1,
    },
    container: {
        backgroundColor: designSystem.colors.gray6,
        borderRadius: 16,
        zIndex: designSystem.zIndex.zIndex1,
        overflow: 'hidden',
        position: 'absolute',
    },
});
