export const useFetchAndActivateFirebaseRemoteConfigs = () => {};

export const remoteConfig = () => {
    return {
        getValue: () => {
            return {
                asString: () => undefined,
                asBoolean: () => undefined,
                asNumber: () => undefined,
                getSource: () => undefined,
            };
        },
        activate: () => Promise.resolve(),
        getAll: () => {
            return {};
        },
        getNumber: () => undefined,
        getBoolean: () => undefined,
        getString: () => undefined,
        settings: {},
        defaultConfig: {},
        fetchTimeMillis: undefined,
        lastFetchStatus: undefined,
        ensureInitialized: () => Promise.resolve(),
        fetch: () => Promise.resolve(),
        fetchAndActivate: () => Promise.resolve(),
        setLogLevel: () => {},
    };
};
