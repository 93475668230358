import React, { PropsWithChildren } from 'react';

import { useSubscribeBetGroupUpdate } from '@/feature/betslip-sbk/hooks/use-subscribe-bet-group-update';
import { useSubscribeEvents } from '@/feature/betslip-sbk/hooks/use-subscribe-events';
import { useSubscribeMarkets } from '@/feature/betslip-sbk/hooks/use-subscribe-markets';
import { useSubscribeProducerStatus } from '@/feature/betslip-sbk/hooks/use-subscribe-producer-status';
import { useClearMarketCache } from '@/hooks/use-clear-market-cache';

export const AuthWebsocketSubscriptions = ({ children }: PropsWithChildren) => {
    useSubscribeMarkets();
    useSubscribeEvents();
    useSubscribeProducerStatus();
    useSubscribeBetGroupUpdate();
    useClearMarketCache();
    return <>{children}</>;
};
