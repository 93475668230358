import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => {
    const { width = 36, height = 36, fill } = props;

    return (
        <Svg
            width={width}
            height={height}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 33.75L18 2.25L36 33.75H0ZM19.5 14.25V23.25H16.5V14.25H19.5ZM19.5 26.25V29.25H16.5V26.25H19.5Z"
                fill={fill || '#EDEFF3'}
            />
        </Svg>
    );
};
export default SvgComponent;
