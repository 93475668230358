import { useCallback, useMemo } from 'react';

import { getRemoteConfigByKey } from '@/feature/analytics/hooks/use-firebase-remote-config';
import { FirebaseRemoteSettings } from '@/feature/analytics/utils/firebaseSettings';
import { hasNoLimit } from '@/feature/betslip-sbk/utils/validators';
import { limitsByTypesSelector, useUserLimits } from '@/feature/responsible-gaming/hooks/use-user-limits';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useWalletStore } from '@/hooks/use-wallet';
import { GameMode } from '@/types/api.generated';

import { getPickslipDefaultEntryAmount } from '../utils/betslip-utils';
import { useActiveGameMode } from './use-active-game-mode';
import { useBetslipActions } from './use-betslip-actions';
import { useBetslipData } from './use-betslip-data';
import { useBetslipStore } from './use-betslip-store';

export const useGameModeActions = () => {
    const setEntryAmount = useBetslipStore(state => state.actions.setEntryAmount);
    const setCurrency = useBetslipStore(state => state.actions.setCurrency);
    const clearGameMode = useBetslipStore(state => state.actions.clearGameMode);
    const { dynamicModeError, perfectModeError } = useBetslipData();
    const activeGameMode = useActiveGameMode();
    const activeGameModeEntryDetails = useBetslipStore(state => state.plays[activeGameMode]);
    const { validatePicks, removeSelection } = useBetslipActions();
    const betslip = useBetslipStore(state => state.betslip);
    const validating = useBetslipStore(state => state.validating);
    const validationData = useBetslipStore(state => state.validationData);

    const { data: userLimits } = useUserLimits({
        select: limitsByTypesSelector(['SINGLE_WAGER', 'WAGER']),
    });
    const filteredLimits = userLimits
        ?.filter(limit => !hasNoLimit(limit))
        .map(limit => limit.amount - limit.current_state)
        .sort((a, b) => a - b);

    const pickslipConfig = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.keyValuePairs?.PickslipConfig
    );

    const firebaseDefaultEntryAmount = getRemoteConfigByKey(
        FirebaseRemoteSettings.DEFAULT_FANTASY_ENTRY_AMOUNT
    ).asNumber();
    const prismicDefaultEntryAmount = pickslipConfig?.default_entry_amount
        ? parseInt(pickslipConfig.default_entry_amount, 10)
        : undefined;
    const defaultEntryAmount = firebaseDefaultEntryAmount ?? prismicDefaultEntryAmount;

    const gameModesErrorsMap = useMemo(
        () => [
            { gameMode: GameMode.Dynamic, error: dynamicModeError },
            { gameMode: GameMode.Perfect, error: perfectModeError },
        ],
        [dynamicModeError, perfectModeError]
    );

    const activeEntryAmount = activeGameModeEntryDetails?.amount;
    const activeEntryCurrency = activeGameModeEntryDetails?.currency;

    const switchGameMode = useCallback(
        (gameMode: GameMode) => {
            if (activeEntryAmount && activeEntryCurrency) {
                setEntryAmount(activeEntryAmount, gameMode);
                setCurrency(activeEntryCurrency, gameMode);
                clearGameMode(activeGameMode);
                validatePicks(
                    betslip,
                    activeEntryAmount,
                    activeEntryCurrency,
                    undefined,
                    removeSelection,
                    'cancelPrevious'
                );
            }
        },
        [
            activeEntryAmount,
            activeEntryCurrency,
            activeGameMode,
            betslip,
            clearGameMode,
            removeSelection,
            setCurrency,
            setEntryAmount,
            validatePicks,
        ]
    );

    const setDefaultGameMode = useCallback(() => {
        if (validating) {
            return;
        }
        // if there's already a game mode selected, return
        const gameAlreadySelected = Object.entries(useBetslipStore.getState().plays).some(
            ([_, { amount }]) => !!amount
        );
        if (gameAlreadySelected) {
            return;
        }

        // if there's no default entry amount, return
        if (!defaultEntryAmount) {
            return;
        }

        const realMoneyTotal = useWalletStore.getState().realMoneyTotal;
        const betrBucks = useWalletStore.getState().betrBucks;
        const hasMoney = betrBucks >= 1 || realMoneyTotal >= 1;

        if (!hasMoney) {
            return;
        }

        const gameModeWithoutError = gameModesErrorsMap.find(({ error }) => !error);

        // if there is no game mode without errors, return
        if (!gameModeWithoutError) {
            return;
        }
        const maxAllowedEntryAmount =
            validationData[gameModeWithoutError.gameMode].amountValidation.maxAllowedEntryAmount;
        const amountToSet = getPickslipDefaultEntryAmount(
            defaultEntryAmount,
            realMoneyTotal,
            betrBucks,
            maxAllowedEntryAmount,
            filteredLimits
        );

        setEntryAmount(amountToSet.amount, gameModeWithoutError.gameMode);
        setCurrency(amountToSet.currency, gameModeWithoutError.gameMode);
        validatePicks(betslip, amountToSet.amount, amountToSet.currency, undefined, removeSelection, 'cancelPrevious');
    }, [
        betslip,
        defaultEntryAmount,
        filteredLimits,
        gameModesErrorsMap,
        removeSelection,
        setCurrency,
        setEntryAmount,
        validatePicks,
        validating,
        validationData,
    ]);

    return {
        switchGameMode,
        setDefaultGameMode,
    };
};
