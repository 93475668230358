import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg width={48} height={36} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Rect x={0.5} y={0.5} width={47} height={35} rx={5.5} fill={designSystem.colors.gray8} />
        <Path
            d="M23 17V10.5C23 10.2239 23.2239 10 23.5 10H24.5C24.7761 10 25 10.2239 25 10.5V17H31.5C31.7761 17 32 17.2239 32 17.5V18.5C32 18.7761 31.7761 19 31.5 19H25V25.5C25 25.7761 24.7761 26 24.5 26H23.5C23.2239 26 23 25.7761 23 25.5V19H16.5C16.2239 19 16 18.7761 16 18.5V17.5C16 17.2239 16.2239 17 16.5 17H23Z"
            fill="white"
        />
        <Rect x={0.5} y={0.5} width={47} height={35} rx={5.5} stroke="#171B1F" />
    </Svg>
);

export default SvgComponent;
