import React from 'react';

import { useNavigation } from '@react-navigation/native';

import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';

import { LimitLobby } from '../components/LimitLobby';
import { LimitLobbyLinks } from '../components/LimitLobbyLinks';
import { ENTRY_LIMIT_HEADER } from '../constants';
import { UserLimit, UserLimitDuration } from '../hooks/types';

export const EntryLimitsScreen = () => {
    const { navigate } = useNavigation();

    const handlePress = (label: string, duration: UserLimitDuration, limit?: UserLimit) => {
        trackRG.playLimitsScreen(label);
        if (!limit) {
            BetrAnalytics.trackProductEvent(AnalyticsEvent.ADD_PLAY_LIMIT, { duration });
            navigate('EntryLimitAdd', { duration });
        } else {
            BetrAnalytics.trackProductEvent(AnalyticsEvent.EDIT_PLAY_LIMIT, { duration });
            navigate('EntryLimitEdit', { duration, amount: limit.amount });
        }
    };
    return (
        <LimitLobby
            type="WAGER"
            {...ENTRY_LIMIT_HEADER}
            links={<LimitLobbyLinks type="WAGER" handlePress={handlePress} />}
        />
    );
};
