import { useEffect } from 'react';

import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { logger } from '@/utils/logging';
import { RudderAnalytics } from '@rudderstack/analytics-js';

import { LOG_TAG } from '../constants';

export const useRudderStack = (enabled: boolean) => {
    const globalSettings = useJurisdictionStore(store => store.jurisdictionSettings?.globalSettings?.keyValuePairs);
    const apiKeys = globalSettings?.ApiKeys;
    const urlKeys = globalSettings?.Urls;
    useEffect(() => {
        if (!apiKeys || !urlKeys) {
            return;
        }

        const rudderInitialize = async () => {
            const rudderAnalytics = new RudderAnalytics();
            try {
                await rudderAnalytics.load(apiKeys.RUDDERSTACK_WEB_WRITE_KEY, urlKeys.RUDDERSTACK_PLANE_URL, {});

                logger.info(LOG_TAG, 'WEB SDK is initialized');
            } catch (e) {
                logger.error(LOG_TAG, 'Error initializing WEB SDK:', e);
            }
        };

        if (enabled) {
            rudderInitialize();
        }
    }, [apiKeys, enabled, urlKeys]);
};
