import React from 'react';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { RootStackParamList } from '@/navigation/types';

import { Authentication } from '../components/Authentication';

export type RegisterScreenProps = NativeStackScreenProps<RootStackParamList, 'Register'>;

export const RegisterScreen = ({ route }: RegisterScreenProps) => {
    return (
        <Authentication initAuthType="register" code={route.params?.code} sessionState={route.params?.session_state} />
    );
};
