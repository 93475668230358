import { StyleSheet } from 'react-native';

import { paymentMethodStyles } from '@/feature/deposit/styles';
import { common } from '@/styles/styles';

export const lineItemStyle = [
    paymentMethodStyles.lineItemBorder,
    common.row,
    common.alignCenter,
    common.justifySpaceBetween,
    { paddingVertical: 16 },
];

export const styles = StyleSheet.create({
    expiredCardTagContainer: {
        width: 76,
    },
});
