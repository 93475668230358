export const substringExpDate = (date: string) => {
    return {
        year: parseInt(date.substring(2, 4), 10),
        month: parseInt(date.substring(0, 2), 10),
    };
};

const isValidExpiryDateFormat = (expiryDateString: string): boolean => {
    const dateFormatRegex = /^(0[1-9]|1[0-2])([0-9]{2})$/;
    return dateFormatRegex.test(expiryDateString);
};

const isExpiryDateFuture = (expiryDateString: string): boolean => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear() % 100;

    const { year: inputYear, month: inputMonth } = substringExpDate(expiryDateString);

    if (inputYear > currentYear) {
        return true;
    } else if (inputYear === currentYear && inputMonth > currentMonth) {
        return true;
    }

    return false;
};

export const validateExpiryDate = (expiryDateString: string) => {
    return (
        expiryDateString.length === 4 &&
        isValidExpiryDateFormat(expiryDateString) &&
        isExpiryDateFuture(expiryDateString)
    );
};
