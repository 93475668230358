import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
        <Path
            fill="#FFC657"
            d="M6.333 17.5v-1.667h3.334V13.25a4.475 4.475 0 0 1-1.823-.865 3.733 3.733 0 0 1-1.177-1.593 4.045 4.045 0 0 1-2.615-1.365A4.01 4.01 0 0 1 3 6.667v-.834c0-.458.163-.85.49-1.177.326-.326.718-.49 1.177-.49h1.666V2.5h8.334v1.667h1.666c.459 0 .851.163 1.177.49.327.326.49.718.49 1.176v.834a4.01 4.01 0 0 1-1.052 2.76 4.045 4.045 0 0 1-2.615 1.365 3.732 3.732 0 0 1-1.177 1.593 4.474 4.474 0 0 1-1.823.865v2.583h3.334V17.5H6.333Zm0-8.5V5.833H4.667v.834c0 .527.152 1.003.458 1.427.306.423.708.725 1.208.906Zm8.334 0c.5-.18.902-.483 1.208-.906.306-.424.458-.9.458-1.427v-.834h-1.666V9Z"
        />
    </Svg>
);
export default SvgComponent;
