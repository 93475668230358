import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { actions, user } from '@/hooks/use-user';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';
import { useSnapshot } from 'valtio';

const TAG = '[UserSessionLoader]';
/**
 * Waits for the user store to be hydrated, and also does a session refresh on app start, before rendering it's children.
 * This ensure that we wait for the previous session to be restored from AsyncStorage before rendering our app and attempting API calls.
 */
export const UserSessionLoader = (props: PropsWithChildren) => {
    const [refreshed, setRefreshed] = useState(false);
    const refreshing = useRef<boolean>(false);
    const userState = useSnapshot(user);

    const hasPreviousSession = userState.hydrated && userState.session;
    const loaded = isWeb
        ? userState.hydrated
        : userState.hydrated && (refreshed || !hasPreviousSession || !userState.loading);

    useEffect(() => {
        const refreshSession = async () => {
            //if the user has a previous session, attempt to refresh it
            if (hasPreviousSession && !refreshed && !refreshing.current) {
                refreshing.current = true;
                try {
                    logger.info(TAG, 'Refreshing session');
                    await actions.refresh(userState.session);
                } catch (e) {
                    logger.warn(TAG, 'Failed to refresh session', e);
                } finally {
                    setRefreshed(true);
                }
            }
        };
        if (!isWeb) {
            // don't do the session refresh on web because it will cause losing the user session on each hot reload
            // we also avoid doing a new login on every new tab opened
            refreshSession();
        }
    }, [hasPreviousSession, refreshed, userState.session]);
    useEffect(() => {
        actions.hydrate();
    }, []);

    if (loaded) {
        return <>{props.children}</>;
    } else {
        return <></>;
    }
};
