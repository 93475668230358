import { useCallback, useEffect, useRef, useState } from 'react';
import { AppState } from 'react-native';

import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { user } from '@/hooks/use-user';

/**
 * Custom hook that manages the display of a "reality check" modal.
 * This modal is displayed when the user has been active in the app for a certain duration.
 * The active time resets if the app goes to the background or becomes inactive.
 */
export const useRealityCheck = () => {
    const [showModal, setShowModal] = useState(false);
    // Track the active time of the user in the foreground (in seconds).
    const [activeTime, setActiveTime] = useState(0); // Time in seconds
    const activeProduct = useJurisdictionStore(state => state.product);
    const globalPairs = useJurisdictionStore(state => state.jurisdictionSettings?.globalSettings?.keyValuePairs);

    // Retrieve user settings and determine the interval for the reality check.
    const userSettings = globalPairs?.UserSettings ?? {};
    const minutesBetweenChecks = parseInt(userSettings.reality_check_interval ?? 15, 10);

    // Ref to manage the interval timer for tracking active time.
    const checkInterval = useRef<NodeJS.Timeout>();

    /**
     * Starts an interval that increments the activeTime every second.
     */
    const startInterval = useCallback(() => {
        if (checkInterval.current) {
            clearInterval(checkInterval.current);
        }
        checkInterval.current = setInterval(() => {
            setActiveTime(prev => prev + 1);
        }, 1000);
    }, []);

    // Monitor the accumulated active time to display the modal upon reaching the threshold.
    useEffect(() => {
        if (!user.sessionHasExpired() && activeTime >= minutesBetweenChecks * 60) {
            setShowModal(true);
        }
    }, [activeTime, minutesBetweenChecks]);

    // Handle app state changes (foreground/background) to manage the counting of active time.
    useEffect(() => {
        const handleAppStateChange = (nextAppState: string) => {
            if (nextAppState === 'active') {
                startInterval();
            } else {
                if (checkInterval.current) {
                    clearInterval(checkInterval.current);
                }
                setActiveTime(0); // Reset the active time if the app goes to the background
            }
        };

        const appStateSubscription = AppState.addEventListener('change', handleAppStateChange);

        if (activeProduct !== Product.None) {
            startInterval();
        }

        // Cleanup listeners on unmount.
        return () => {
            appStateSubscription.remove(); // Remove the AppState listener
            if (checkInterval.current) {
                clearInterval(checkInterval.current);
            }
        };
    }, [activeProduct, startInterval]);

    /**
     * Resets the activeTime counter and starts the interval again.
     */
    const hideModalAndResetTimer = useCallback(() => {
        setShowModal(false);
        setActiveTime(0);
        startInterval();
    }, [startInterval]);

    return { showModal, minutesBetweenChecks, hideModalAndResetTimer };
};
