import { URLS } from '@/data/config';

export const addResizeParamToURL = (url: string): string => {
    return `${URLS.BETR_CDN_URL}/image/width=${128}/${url}`;
};

export const arcTextFontStyle = {
    small: {
        fontSize: 10,
        lineHeight: 15,
    },
    big: {
        fontSize: 11,
        lineHeight: 18,
    },
};

export const playerNumberFontStyle = {
    small: {
        fontSize: 24,
        lineHeight: 28,
    },
    big: {
        fontSize: 28,
        lineHeight: 31.5,
    },
};

export const cardBorderWidth = 0.75;

export const sizes = {
    small: { width: 48, height: 68 },
    big: { width: 54, height: 76 },
};

export const teamLogoSizes = {
    small: { width: 20, height: 20 },
    big: { width: 22, height: 22 },
};

export const borderRadii = {
    small: 12,
    big: 14,
};

export const playerMaskCYOffsets = {
    small: 8,
    big: 9,
};

export const playerMaskBlurs = {
    small: 5,
    big: 5.625,
};

export const teamColorCircleBlurs = {
    small: 20,
    big: 22,
};

export const teamColorCircleOffsets = {
    small: 8,
    big: 9,
};

export const textSeparatorWidths = {
    small: 28,
    big: 30,
};
