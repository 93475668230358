import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, KeyboardAvoidingView, Platform } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { TextInput } from '@/components/TextInput';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useUpdateExpiredCard } from '@/hooks/use-update-expired-card';
import { common } from '@/styles/styles';
import { substringExpDate, validateExpiryDate } from '@/utils/validate-card-expiry-date';

import { Button } from './ButtonComponent';
import { ScrollableScreen } from './ScrollableScreen';
import { SizedBox } from './SizedBox';
import { Text } from './TextComponent';
import { Box } from './lib/components';

const expDateMask = [/\d/, /\d/, '/', /\d/, /\d/];

type UpdateExpiryCardFormProps = {
    paymentTypeId: string;
    onSuccessUpdateCard: () => void;
    onPressSecondaryButton: () => void;
    secondaryButtonLabel: string;
    primaryButtonLabel?: string;
};

export const UpdateExpiryCardForm = ({
    paymentTypeId,
    onSuccessUpdateCard,
    primaryButtonLabel,
    onPressSecondaryButton,
    secondaryButtonLabel,
}: UpdateExpiryCardFormProps) => {
    const navigation = useNavigation();
    const { t } = useTranslation(['wallet', 'common']);

    const [expiryDate, setExpiryDate] = useState('');
    const [error, setError] = useState('');

    const { mutate: updateExpiredCard, isLoading } = useUpdateExpiredCard();

    const resetError = () => {
        if (error !== '') {
            setError('');
        }
    };

    const onChangeText = (_masked: string, unmasked: string) => {
        resetError();
        setExpiryDate(unmasked);

        if (!validateExpiryDate(unmasked)) {
            setError(t('expiry_date_field_invalid_error'));
        }
    };

    const handleOnPressPrimaryButton = () => {
        if (validateExpiryDate(expiryDate)) {
            const { year, month } = substringExpDate(expiryDate);
            updateExpiredCard(
                {
                    paymentTypeId,
                    expiryDateYear: 2000 + year, // Should we think about the year 2100 and beyond?
                    expiryDateMonth: month,
                },
                {
                    onSuccess: () => {
                        onSuccessUpdateCard();
                        Keyboard.dismiss();
                    },
                    onError: () => {
                        navigation.navigate('ErrorModal', {
                            title: t('update_expiry_card_failed_title'),
                            subtitle: t('update_expiry_card_failed_subtitle'),
                            primaryButton: t('common:review_information'),
                            showSecondaryButton: true,
                            secondaryButton: secondaryButtonLabel,
                            handleSecondaryButtonPress: onPressSecondaryButton,
                            closeIconMode: 'back',
                        });
                    },
                }
            );
        } else {
            setError(t('expiry_date_field_invalid_error'));
        }
    };

    return (
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined} style={common.flex}>
            <ScrollableScreen
                screenNavBarProps={{
                    title: '',
                    onClose: () => navigation.goBack(),
                }}
                screenProps={{ edges: ['top', 'bottom'] }}
                scrollViewProps={{ nestedScrollEnabled: true, showsVerticalScrollIndicator: false }}
                footer={
                    <MaxWidthWrapper paddingHorizontal={'s16'}>
                        <Box gap="s8" paddingHorizontal="s16">
                            <Button
                                label={primaryButtonLabel || t('common:proceed')}
                                variant="rounded"
                                type="active"
                                loading={isLoading}
                                onPress={handleOnPressPrimaryButton}
                            />
                            <Button
                                label={secondaryButtonLabel}
                                type="text"
                                onPress={onPressSecondaryButton}
                                disabled={isLoading}
                            />
                        </Box>
                    </MaxWidthWrapper>
                }
            >
                <MaxWidthWrapper paddingHorizontal={'s16'}>
                    <Box alignItems="center" paddingHorizontal="s16" gap="s8">
                        <Text variant="headlineMedium">{t('update_expiry_card_form_title')}</Text>
                        <Text variant="bodyMedium" textAlign="center" color="gray2">
                            {t('update_expiry_card_form_description')}
                        </Text>
                    </Box>
                    <SizedBox value={24} />
                    <Box gap="s8">
                        <TextInput
                            label={t('expiry_date_field_label')}
                            value={expiryDate}
                            onChangeText={onChangeText}
                            autoFocus
                            keyboardType="numeric"
                            mask={expDateMask}
                            hasError={error !== ''}
                            errorText={error}
                        />
                    </Box>
                    <Box flex={1} />
                </MaxWidthWrapper>
            </ScrollableScreen>
        </KeyboardAvoidingView>
    );
};
