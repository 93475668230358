import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';

export type BetSummaryTableHeaderProps = {
    wagerColWidth: number;
    payoutColWidth: number;
};

export const BetSummaryTableHeader = ({ wagerColWidth, payoutColWidth }: BetSummaryTableHeaderProps) => {
    const { t } = useTranslation('betslip_sbk');
    return (
        <Row justifyContent="space-between">
            <Box paddingRight="s16">
                <Text variant="bodySmall" color="gray2">
                    {t('bet')} / {t('multiplier')}
                </Text>
            </Box>
            <Row justifyContent="flex-end">
                <Box paddingLeft="s16" width={wagerColWidth}>
                    <Text variant="bodySmall" color="gray2" textAlign="right">
                        {t('wager')}
                    </Text>
                </Box>
                <Box paddingLeft="s16" width={payoutColWidth}>
                    <Text variant="bodySmall" color="gray2" textAlign="right">
                        {t('payout')}
                    </Text>
                </Box>
            </Row>
        </Row>
    );
};
