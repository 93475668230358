import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Box, Image } from '@/components/lib/components';
import { paymentMethodStyles } from '@/feature/deposit/styles';
import { useLogoUrl } from '@/hooks/use-logo-url';
import { common, designSystem } from '@/styles/styles';

type ImageContainerProps = {
    width: number;
    height: number;
    children: React.ReactNode;
};

type PaymentLogoProps = {
    source?: string[];
    width?: number;
    height?: number;
    style?: StyleProp<ViewStyle>;
};

const ImageContainer = ({ width, height, children }: ImageContainerProps) => (
    <Box width={width} height={height} style={styles.imageContainer}>
        {children}
    </Box>
);

export const PaymentLogo: React.FC<PaymentLogoProps> = ({ source, width = 48, height = 36, style }) => {
    const { data: logoUrl, isLoading } = useLogoUrl(source);

    if (isLoading) {
        return (
            <ImageContainer width={width} height={height}>
                <LoadingSpinner size={24} displayLogo={false} />
            </ImageContainer>
        );
    }
    if (logoUrl) {
        return (
            <ImageContainer width={width} height={height}>
                <Image
                    source={{ uri: logoUrl }}
                    style={[
                        paymentMethodStyles.image,
                        style as StyleProp<{
                            width: number;
                            height: number;
                        }>,
                    ]}
                />
            </ImageContainer>
        );
    }

    return null;
};

const styles = StyleSheet.create({
    imageContainer: {
        marginRight: 16,
        borderWidth: 1,
        borderColor: designSystem.colors.gray6,
        borderRadius: 8,
        ...common.alignCenter,
        ...common.justifyCenter,
    },
});
