import {
    DueDiligence,
    DueDiligenceReasons,
    useAuthUserConfig,
    userDueDiligenceReasonSelector,
    userDueDiligenceSelector,
} from '@/hooks/use-auth-user-config';
import { RemovableCardType } from '@/types/removable-cards';

export const useIsCardRemovable = () => {
    const { data: userDueDiligence } = useAuthUserConfig({ select: userDueDiligenceSelector });
    const { data: userDueDiligenceReason } = useAuthUserConfig({ select: userDueDiligenceReasonSelector });
    // If the user has failed EDD and the reason is passive, then the user is not allowed to remove the card
    const hasFailedEDD =
        userDueDiligence === DueDiligence.EDD_FAILED && userDueDiligenceReason === DueDiligenceReasons.PASSIVE;

    // Condition 1: The card must be marked as removable.
    // This ensures that only cards flagged as removable are eligible for further checks.

    // Condition 2: The card must not have failed the EDD (Eligibility Due Diligence) check.
    //   even if they failed EDD, and will not show as disabled simply due to the EDD failure.

    // Condition 3: The remaining required distribution (closed_loop_remain) must be less than $500.
    // If the remaining amount is $500 or more, the card cannot be removed, regardless of the other conditions.
    return (card: RemovableCardType) => {
        return card.is_removable && !hasFailedEDD && card.closed_loop_remain < 500;
    };
};
