import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = ({ width = 24, height = 24, ...props }) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" {...props}>
        <Path
            fill={designSystem.colors.gray1}
            fillRule="evenodd"
            d="M2.753 2.593a1.117 1.117 0 0 0-.907.935L.006 15.933a.559.559 0 0 0 .648.631l3.853-.68a.557.557 0 0 1 .632.71l-1.99 6.688a.56.56 0 0 0 .92.563L18.685 9.889a.559.559 0 0 0-.483-.953l-3.692.652a.557.557 0 0 1-.596-.802L17.953.82a.558.558 0 0 0-.598-.81L2.753 2.593Zm6.318 7.6c.68.27 1.095.44.947-.399a2.798 2.798 0 0 0-2.144-2.245 2.789 2.789 0 0 0-2.892 1.125 2.8 2.8 0 0 0-.464 2.09c.15.839.49.533 1.027.051.424-.381.972-.873 1.643-.991.67-.118 1.346.156 1.883.37Z"
            clipRule="evenodd"
        />
    </Svg>
);

export default SvgComponent;
