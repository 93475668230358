import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, TextProps } from '@/components/TextComponent';
import { Event } from '@/feature/event-details-sbk/types';
import { useFormatPeriod } from '@/hooks/use-format-period';
import { useEventDetailsCache, useEventStatus } from '@/hooks/use-sbk-event-details-cache';
import { renderDate } from '@/utils/renderDate';
import { useShallow } from 'zustand/react/shallow';

type EventTimeProps = {
    event: Event;
} & TextProps;

/**
 * Returns the current time of the event. The display time varies based on sport and current status of the game
 * FINISHED - "Final"
 * LIVE
 *   - Basketball: "1st, 8:23"
 *   - American Football: "1st, 12:34"
 *   - Ice Hockey: "1st, 19:00"
 *   - Baseball: "Top 7"
 * NOTSTARTED - Date
 */
export const useEventTime = (event: Event) => {
    const { t } = useTranslation(['common', 'event_time']);
    const eventStatus = useEventStatus(event.id) ?? event.status;

    const { periodName, gameClock, sportSpecific } = useEventDetailsCache(
        useShallow(state => {
            return {
                periodName: state.eventDetails[event.id]?.period_name ?? event.event_details?.period_name,
                gameClock: state.eventDetails[event.id]?.game_clock ?? event.event_details?.game_clock,
                sportSpecific: state.eventDetails[event.id]?.sport_specific ?? event.event_details?.sport_specific,
            };
        })
    );

    const eventTime = useFormatPeriod({ event, periodName, gameClock, sportSpecific });

    switch (eventStatus) {
        case 'FINISHED':
            // TODO to handle OT cases
            return t('final');
        case 'LIVE':
            return eventTime || ' ';
    }
    return renderDate(new Date(event.start_time));
};

export const EventTime = ({ event, ...textProps }: EventTimeProps) => {
    const time = useEventTime(event);
    return (
        <Text testID="eventPageEventTime" {...textProps}>
            {time}
        </Text>
    );
};
