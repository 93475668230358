import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import AlertStraightTriangleIcon from '@/assets/icons/alertStraightTriangle';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';
import { showScrollIndicator } from '@/utils/constants-platform-specific';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { useIsSuspendedOption, useTotalPayout } from '../../hooks/betslip-hooks';
import { useTotalStakeErrorMessages } from '../../hooks/use-total-stake-error-message';
import { Banner } from '../Banner';
import { BetSummaryTableBody } from './BetSummaryTableBody';
import { BetSummaryTableHeader } from './BetSummaryTableHeader';

const styles = StyleSheet.create({
    scrollContainer: {
        paddingBottom: 0,
        marginTop: -20,
    },
});

const COL_MIN_WIDTH = 80;
const BOTTOM_PADDING = 16;

export const BetSummary = () => {
    const { width, height } = useWindowDimensions();
    const [screenModalHeight, setScreenModalHeight] = useState(0);
    const headerHeight = useRef(0);
    const safeInsets = useSafeAreaInsets();
    const [wagerColWidth, setWagerColWidth] = useState(COL_MIN_WIDTH);
    const [payoutColWidth, setPayoutColWidth] = useState(COL_MIN_WIDTH);
    const { t } = useTranslation('betslip_sbk');
    const errorMessages = useTotalStakeErrorMessages();

    const maxWidth = width - 32; // Max width without horizontal padding
    /* Calculates max width for Bet/Multiplier Column based columns width (wager and payout) */
    const maxBetMultiplierWidth = maxWidth - (wagerColWidth + payoutColWidth);
    const totalPayout = useTotalPayout();

    // Screen size - safe insets - close button height
    const maxContainerHeight = height - safeInsets.top - 150;
    const isSuspendedOption = useIsSuspendedOption();

    return (
        <Box onLayout={event => setScreenModalHeight(event.nativeEvent.layout.height)} maxHeight={maxContainerHeight}>
            <ScrollView
                scrollEnabled={screenModalHeight >= maxContainerHeight}
                style={[styles.scrollContainer]}
                showsVerticalScrollIndicator={showScrollIndicator}
                stickyHeaderIndices={[1]}
            >
                {errorMessages.length > 0 ? (
                    <Box paddingVertical="s20" gap="s12">
                        {errorMessages.map((msg, index) => {
                            return (
                                <Banner
                                    key={index}
                                    backgroundColor="red"
                                    textColor="gray8"
                                    leftIcon={
                                        <AlertStraightTriangleIcon
                                            width="24"
                                            height="24"
                                            fill={designSystem.colors.gray8}
                                        />
                                    }
                                    message={msg}
                                    testID={index.toString()}
                                />
                            );
                        })}
                    </Box>
                ) : null}

                {isSuspendedOption ? (
                    <Box paddingVertical="s20" gap="s12">
                        <Banner
                            backgroundColor="gray5"
                            textColor="gray1"
                            leftIcon={
                                <AlertStraightTriangleIcon width="24" height="24" fill={designSystem.colors.gray1} />
                            }
                            message={t('suspended_selection_bet_summary_description')}
                        />
                    </Box>
                ) : null}

                <Box
                    onLayout={event => {
                        headerHeight.current = event.nativeEvent.layout.height - BOTTOM_PADDING;
                    }}
                    marginBottom="s16"
                >
                    <Text variant="headlineSmall" color="gray1" textAlign="center" testID="betSummaryTitle">
                        {t('bet_summary')}
                    </Text>
                    <Text variant="bodyMedium" color="gray2" textAlign="center">
                        {t('total_payout')}: {toLocaleCurrency(totalPayout)}
                    </Text>
                </Box>
                <Box paddingVertical="s16" backgroundColor="gray6">
                    <BetSummaryTableHeader wagerColWidth={wagerColWidth} payoutColWidth={payoutColWidth} />
                </Box>
                <BetSummaryTableBody
                    {...{
                        setWagerColWidth,
                        setPayoutColWidth,
                        maxBetMultiplierWidth,
                        colMinWidth: COL_MIN_WIDTH,
                    }}
                />
            </ScrollView>
        </Box>
    );
};
