import { ReactElement } from 'react';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { CompositeNavigationProp, NavigatorScreenParams } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { EventInfo } from '@/api/events/types/types';
import { Bet } from '@/feature/bets-sbk/hooks/types';
import { BetslipPick, InvalidPicksByEvent } from '@/feature/betslip-pickem/types';
import { PaymentMethodType } from '@/feature/deposit/hooks/use-deposit-payment-methods';
// TODO: Refactor this to types file
import { KycProvider } from '@/feature/kyc/screens/KycSelector';
import { UserLimitDuration } from '@/feature/responsible-gaming/hooks/types';
import { ClosedLoopMethodProps } from '@/feature/withdraw/components/closedLoop.types';
import { WithdrawMethod } from '@/feature/withdraw/hooks/use-withdrawal-methods';
import { Product } from '@/hooks/use-jurisdiction';
import { GameMode, GameType } from '@/types/api.generated';
import { RemovableCardType } from '@/types/removable-cards';
import { AppFeatureFlags } from '@/utils/parse-prismic-content';

import { PickemTabNavigatorParamList } from './pickem/types';
import { SbkTabNavigatorParamList } from './sbk/types';

export enum IdpvSource {
    KYC = 'kyc',
    ACCOUNT_SETTING = 'account_setting',
    MANAGE_CARD = 'manage_card',
}

export type IdpvSourceType = IdpvSource | undefined;

export type RootStackParamList = {
    Splash: undefined;
    Launch: undefined;
    IneligibleLocationWeb: undefined;
    AllowLocation: { errorMode: 'countyDetect' | 'locationAccess'; onSelectUsState: (state: string) => void };
    Logout: undefined;
    Login?: {
        initialEmail: string | undefined;
        //code & session_state used on web for the redirect
        code: string | undefined;
        session_state: string | undefined;
        registering: string | undefined;
    };
    PickemHome: NavigatorScreenParams<PickemTabNavigatorParamList>;
    SBKHome: NavigatorScreenParams<SbkTabNavigatorParamList>;
    SearchScreen: {
        searchBarPosition?: number;
    };
    FantasyPickSlip: {
        shouldOpenLineupModal?: boolean;
    };
    EntryInputAmount: {
        gameMode: GameMode;
        gameType: GameType;
    };
    QuickAmountSheetScreen: {
        gameMode: GameMode;
        gameType: GameType;
    };
    EditQuickAmounts: undefined;
    BetSlipModal: undefined;
    SbkBetScreen: { betId: string; bet?: Bet };
    BetShareScreen: { betId: string; bet?: Bet };
    Register:
        | {
              //code & session_state used on web for the redirect
              code: string | undefined;
              session_state: string | undefined;
          }
        | undefined;
    SubmitEntry: { amount: number; onInvalidProjectionsFound: () => void };
    CancelEntry: {
        entryId: string;
        events: EventInfo[];
    };
    EntryScreen: { id: string };
    PushPrimer: undefined;
    EntryShare: {
        id?: string;
    };
    ErrorModal: {
        title: string;
        subtitle?: string;
        primaryButton: string | ReactElement;
        showPrimaryButton?: boolean;
        showSecondaryButton?: boolean;
        secondaryButton?: string;
        footer?: React.ReactNode;
        gestureEnabled?: boolean;
        closeIconMode?: 'back' | 'close' | 'none';
        handlePress?: () => void;
        handleSecondaryButtonPress?: () => void;
        closeIconHandlePress?: () => void;
        showNavbarTitle?: boolean;
    };
    IneligibleStateModal: {
        title: string;
        subtitle?: string;
        primaryButton: string;
        showPrimaryButton?: boolean;
        showSecondaryButton?: boolean;
        secondaryButton?: string;
        footer?: React.ReactNode;
        gestureEnabled?: boolean;
        handlePress?: () => void;
        handleSecondaryButtonPress?: () => void;
        closeIconHandlePress?: () => void;
        closeIconMode?: 'back' | 'close' | 'none';
        showNavbarTitle?: boolean;
    };
    PlayersRemovedModal: { invalidPicksByEvent: InvalidPicksByEvent[]; picksWithMissingEvents: BetslipPick[] };
    ModalWebView: { uri: string; title?: string; includeAuthToken?: boolean };
    Withdraw: undefined;
    DeveloperScreen: undefined;
    ProductSwitcher: undefined;
    DepositScreen: {
        promoSlug?: string;
        selectedAmount: string;
        shouldRedirectToLobby?: boolean;
        disableTransition?: boolean;
        forceDepositWithDebitCard?: boolean;
        isRedirectedFromVerificationSuccess?: boolean;
    };
    DepositMethodWebViewModal: {
        selectedAmount: string;
        selectedPaymentMethodType: PaymentMethodType;
        currency?: string;
        payment_token?: string;
        payment_type_id?: string;
    };
    MazoomaDepositWebViewModal: {
        selectedAmount: string;
        currency?: string;
        payment_token?: string;
        payment_type_id?: string;
    };
    PayByBankDepositWebViewModal: {
        selectedAmount: string;
        currency?: string;
    };
    VenmoDepositScreen: {
        selectedAmount: string;
        currency?: string;
    };
    TrustlyDepositScreen: {
        selectedAmount: string;
        currency?: string;
        payment_type_id?: string;
    };
    DepositStatusModal: {
        selectedAmount: string;
        paymentProvider: PaymentMethodType;
        currentStatus: string;
    };
    SuccessfulDepositModal: {
        selectedAmount: string;
        paymentProvider: PaymentMethodType;
        title?: string;
        description?: string;
    };
    FailedDepositModal: {
        selectedAmount: string;
        paymentProvider: PaymentMethodType;
        errorCode?: string;
        showContactSupport?: boolean;
    };
    AddDebitCardWebView: {
        selectedAmount: string;
        paysafeAccountId: string;
        closeIconHandlePress?: () => void;
        canUse3ds: boolean;
    };
    VerifyingDebitDeposit: {
        selectedAmount: string;
        canUse3ds?: boolean;
        currency?: string;
        payment_token?: string;
        payment_type_id?: string;
    };
    DepositSalvage: {
        options: AppFeatureFlags;
        paymentParams: {
            selectedAmount: string;
            currency?: string;
            payment_token?: string;
            payment_type_id?: string;
        };
    };
    DepositLimitAdd: { duration: UserLimitDuration };
    DepositExpiryUpdate: {
        selectedAmount: string;
        currency?: string;
        payment_token?: string;
        payment_type_id?: string;
    };
    ChooseWithdrawalMethod: { methods: WithdrawMethod[] };
    WithdrawForm: { selectedMethod: WithdrawMethod; methods: WithdrawMethod[] };
    SuccessfulWithdrawal: { amountToWithdraw: number; methodType?: string };
    FailedWithdrawal: { errorCode: string };
    FailedMultipleWithdrawal: { promisesResult: any[]; distribution: ClosedLoopMethodProps[] };
    WithdrawalExpiryUpdate: { selectedMethod: WithdrawMethod; methods: WithdrawMethod[] };
    DepositLimitEdit: { duration: UserLimitDuration; amount: number };
    MaxSingleEntryLimitEdit: { amount: number };
    EntryLimitAdd: { duration: UserLimitDuration };
    EntryLimitEdit: { duration: UserLimitDuration; amount: number };
    MaxSingleEntryLimitAdd: undefined;
    ManageDebitCards: undefined;
    ManageExpiredDebitCardDetail: { selectedMethod: RemovableCardType };
    ManageDebitCardExpiryUpdate: { selectedMethod: RemovableCardType };
    DepositLimits: undefined;
    ResponsibleGaming: undefined;
    StateLimits: undefined;
    EntryLimits: undefined;
    MaxSingleEntryLimits: undefined;
    Timeout: undefined;
    TimeoutAdd: undefined;
    KycSelector: undefined;
    KycSsn: undefined;
    KycFirstLastName: undefined;
    KycDob: undefined;
    KycHomeAddress: undefined;
    KycAcknowledgements: { provider?: KycProvider } | undefined;
    KycReviewPersonalInfo: undefined;
    KycVerificationFailed?: { hasIdScanFailed?: boolean };
    KycVerifyingIdentity: undefined;
    KycVerifyingIdpv: undefined;
    KycVerificationSuccess: undefined;
    KycSsnRetry: undefined;
    KycFirstLastNameReview: undefined;
    KycDobReview: undefined;
    KycHomeAddressReview: undefined;
    KycAcknowledgementsRetry: { provider?: KycProvider } | undefined;
    KycScanId?: { source?: IdpvSourceType };
    KycIdpvWebView: { uri: string };
    TrustlyWebview: { amount: string };
    TrustlyNumpad: undefined;
    SelfExclusionAdd: undefined;
    AccountHome: undefined;
    Promohub: undefined;
    PasswordEdit: undefined;
    SuccessModal: {
        title: string;
        subtitle?: string;
        primaryButton: string;
        closeIconMode?: 'back' | 'close' | 'none';
        closeIconHandlePress?: () => void;
        handlePress?: () => void;
        secondaryButton?: string;
        handleSecondaryPress?: () => void;
    };
    PhoneVerification: { title?: string; screenTitle?: string; redirectRoute?: string };
    EmailUpdate: undefined;
    EmailUpdateConfirm: { newEmail: string };
    PhoneNumberEditInitialVerification: undefined;
    PhoneNumberEditUpdatedVerification: { phoneNumber: string };
    PhoneNumberEdit: undefined;
    GuardBiometricsScreen: undefined;
    MaintenanceScreen: undefined;
    FeaturedLineupsScreen: { title: string };
    ProductTransitionScreen: { product: Product; shouldFadeOut?: boolean };
    InviteFriends: undefined;
    InviteFriendsV2: undefined;
    ReferralHistory: undefined;
};

export type PromohubStackParamList = {
    Promohub: { promoId?: string; mode: 'tabs' | 'stack' };
};

export type InviteFriendsStackParamList = {
    InviteFriends: undefined;
    ReferralHistory: undefined;
    InviteFriendsV2: undefined;
};

export type AccountStackParamList = {
    Account: { mode: 'tabs' | 'stack' };
    ResponsibleGaming: undefined;
    DepositLimits: undefined;
    LimitsHistory: undefined;
    EntryLimits: undefined;
    MaxSingleEntryLimits: undefined;
    RealityCheck: undefined;
    Timeout: undefined;
    SelfExclusion: undefined;
    TransactionHistory: undefined;
    AccountDetails: undefined;
    YearEndStatement: undefined;
    TransactionDetails: { userWalletId: string };
    SelfExclusionAdd: undefined;
    StateSelector: undefined;
    Preferences: undefined;
};

export type SharedTabsParamList = {
    PromohubStack: NavigatorScreenParams<PromohubStackParamList>;
    AccountStack: NavigatorScreenParams<AccountStackParamList>;
    InviteFriendPromo: NavigatorScreenParams<InviteFriendsStackParamList>;
};

export type AccountTabNavigationProp = CompositeNavigationProp<
    BottomTabNavigationProp<PickemTabNavigatorParamList, 'AccountStack'>,
    CompositeNavigationProp<StackNavigationProp<AccountStackParamList>, StackNavigationProp<RootStackParamList>>
>;

declare global {
    namespace ReactNavigation {
        interface RootParamList extends RootStackParamList {}
    }

    // used to know which architecture we are using
    // false/undefined -> Paper UI Manager -> old architecture
    // true -> Fabric UI Manager -> new architecture
    var nativeFabricUIManager: boolean;
}
