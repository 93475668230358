import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 9 8" fill="none">
        <Path d="M2 4L8.5 8V0L2 4Z" fill={props.color ?? designSystem.colors.white} />
    </Svg>
);
export default SvgComponent;
