import * as React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { LineSeparator } from '@/components/LineSeparator';

type SeparatorProps = {
    light: boolean;
    flex?: number;
    vertical?: boolean;
    style?: StyleProp<ViewStyle>;
    height?: ViewStyle['height'];
};

export const Separator = ({ light, flex, vertical, style, height = 40 }: SeparatorProps) => {
    const limitHeightStyle = light && vertical ? { height, marginTop: 2 } : {};
    return light ? (
        <LineSeparator
            flex={flex}
            vertical={vertical}
            variant="light"
            style={{ ...styles.separator, ...limitHeightStyle }}
        />
    ) : (
        <LineSeparator flex={flex} vertical={vertical} style={style} />
    );
};

const styles = StyleSheet.create({
    separator: { opacity: 0.1 },
});
