export const LIMIT_DURATION = {
    DAY: 'Daily',
    WEEK: 'Weekly',
    MONTH: 'Monthly',
};

export const USER_LIMIT_TYPE = {
    DEPOSIT: 'Deposit',
    LOSE: 'Lose',
    WAGER: 'Wager',
    SINGLE_WAGER: 'Single wager',
};

export const DEPOSIT_LIMIT_HEADER = {
    title: 'Deposit Limits',
    content: 'Set deposit limits to decide the maximum amount of money you can deposit on betr in a given time period.',
    subTitle: 'Current limits',
    example:
        'Example: Set your weekly limit to $150 to allow you to deposit up $150 every 7 days. You can modify your limits whenever you choose, but there is a waiting period when increasing or removing.',
};

export const ENTRY_LIMIT_HEADER = {
    title: 'Play Limit',
    content:
        'For sportsbook users set a play limit to decide the maximum amount of money you can wager over a given time. For fantasy users set a play limit to decide the maximum amount of money you can enter in a contest over a given time.',
    subTitle: 'Current limits',
    example:
        'Example: Set your weekly limit to $150 to allow you to place up $150 every 7 days on either fantasy contests or betting wagers. You can modify your limits whenever you choose, but there is a waiting period when increasing or removing.',
};

export const MAX_SINGLE_ENTRY_LIMIT_HEADER = {
    title: 'Max Single Play Limit',
    content:
        'Set a maximum single play limit to decide the max amount of money you can submit for fantasy on an entry and for sportsbook on a wager.',
    subTitle: 'Current limits',
    example:
        'Example: Set your maximum single play limit to $150 to allow you to place up $150 on a single fantasy contests. You can modify your limits whenever you choose, but there is a waiting period when increasing or removing.',
};
