import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-native';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useTrackingEnabled } from '@/hooks/use-tracking-enabled';
import { designSystem } from '@/styles/styles';
import { openPlatformSettings } from '@/utils/open-platform-settings';

export const AppTrackingAdvertisingSwitch = (): ReactElement | null => {
    const { t } = useTranslation(['account', 'common']);
    const { showInfoSheet } = useAlerts();

    const { isLoading, isEnabled } = useTrackingEnabled();

    const handleTargetedAdvertisingToggle = () => {
        showInfoSheet({
            title: t('targeted_advertising'),
            description: t('targeted_advertising_subtext'),
            buttonLabel: t('common:go_to_settings'),
            handlePress: () => openPlatformSettings(),
        });
    };

    if (isLoading) {
        return null;
    }

    return (
        <Switch
            trackColor={{ false: designSystem.colors.gray2, true: designSystem.colors.green }}
            thumbColor={designSystem.colors.gray1}
            ios_backgroundColor={designSystem.colors.gray2}
            onValueChange={handleTargetedAdvertisingToggle}
            value={isEnabled}
        />
    );
};
