import React, { useCallback } from 'react';
import { FlatList } from 'react-native-gesture-handler';

import { Box } from '@/components/lib/components';
import { BetSelection } from '@/feature/bets-sbk/hooks/types';

import { FeaturedEvent } from '../types';
import { FeaturedBetCardSelection } from './FeaturedBetCardSelection';
import { Header } from './Header';

export const FeaturedBetCardEvent = ({ event, isLastEvent }: { event: FeaturedEvent; isLastEvent: boolean }) => {
    const renderItem = useCallback(
        ({ item, index }: { item: BetSelection; index: number }) => {
            return (
                <FeaturedBetCardSelection
                    selection={item}
                    event={event}
                    isLastEvent={isLastEvent}
                    isFirstLeg={index === 0}
                    isLastLeg={event.selections.length - 1 === index}
                />
            );
        },
        [event, isLastEvent]
    );

    return (
        <Box>
            <Header event={event} />
            <FlatList
                keyExtractor={(id, idx) => `selection${id}-${event.id}-${idx}`}
                scrollEnabled={false}
                data={event.selections}
                renderItem={renderItem}
            />
        </Box>
    );
};
