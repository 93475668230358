import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressFormValues, useKycFieldsStore } from '@/feature/kyc/hooks/use-kyc-fields';
import { usePicker } from '@/hooks/use-picker';

import { AddressForm } from '../../components/HomeAddress/AddressForm';
import { KycStepContainer } from '../../components/KycStepContainer';
import { STATES } from '../../const';

/**
 * Check if the address contains a PO Box
 * @param address - The address to check
 * @returns true if the address contains a PO Box, false otherwise
 */
export const isPOBox = (address: string): boolean => {
    return /\bP\.?\s*O\.?\s*Box\b/i.test(address) || /po\s*box\d+/i.test(address);
};

export const isBtnDisabled = ({ address, city, americanState, zip }: AddressFormValues) => {
    return (
        !address ||
        address.length === 0 ||
        isPOBox(address) ||
        city.length === 0 ||
        americanState.length === 0 ||
        zip.length < 5
    );
};

export const HomeAddressStep = () => {
    const values = useKycFieldsStore(state => state.values);
    const setFieldValue = useKycFieldsStore(state => state.setFieldValue);
    const setAddressFormValues = useKycFieldsStore(state => state.setAddressFormValues);

    const { t } = useTranslation('kyc');
    const { showPicker, setShowPicker } = usePicker(values, setFieldValue, 'americanState', STATES);

    const { address, city, americanState, zip } = values;

    const isNextDisabled = useMemo(
        () => isBtnDisabled({ address, city, americanState, zip }),
        [address, city, americanState, zip]
    );

    const handlePickerChange = (pickedValue: string) => {
        setFieldValue('americanState', pickedValue);
    };

    return (
        <KycStepContainer
            title={t('address_header')}
            subText={t('address_copy')}
            isNextDisabled={isNextDisabled}
            showPicker={showPicker}
            setShowPicker={setShowPicker}
            pickedValue={values.americanState}
            handlePickerChange={handlePickerChange}
        >
            <AddressForm
                values={values}
                setFieldValue={setFieldValue}
                setAddressFormValues={setAddressFormValues}
                setShowPicker={setShowPicker}
            />
        </KycStepContainer>
    );
};
