import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Masks } from 'react-native-mask-input';

import { TextInput } from '@/components/TextInput';
import { AnimatedPressableOpacity } from '@/components/animated-pressable-opacity/AnimatedPressableOpacity';
import { Box } from '@/components/lib/components';
import { isWeb } from '@/utils/constants-platform-specific';
import { format, parse } from 'date-fns';

export type InputProps = {
    value: string;
    setFieldValue: (value: string) => void;
    errorMessage?: string;
    setShowDobPicker?: (value: boolean) => void;
};

export const DobInput: React.FC<InputProps> = ({ value, errorMessage, setFieldValue, setShowDobPicker }) => {
    const { t } = useTranslation('kyc');

    if (isWeb) {
        return (
            <TextInput
                label={t('date_of_birth')}
                keyboardType="numeric"
                value={value}
                onChangeText={(_masked: string, unmasked: string) => setFieldValue(unmasked)}
                mask={Masks.DATE_MMDDYYYY}
                hasError={!!errorMessage}
                errorText={errorMessage}
            />
        );
    }

    return (
        <View>
            <AnimatedPressableOpacity onPress={() => setShowDobPicker?.(true)}>
                <Box pointerEvents="none">
                    <TextInput
                        label={t('date_of_birth')}
                        value={value ? format(parse(value, 'MMddyyyy', new Date()), 'MM/dd/yyyy') : ''}
                        editable={false}
                        hasError={!!errorMessage}
                        errorText={errorMessage}
                    />
                </Box>
            </AnimatedPressableOpacity>
        </View>
    );
};
