import { useEffect, useState } from 'react';

import { useAnimatedOptionEffect } from '@/feature/event-details-sbk/components/OptionButton';

import { usePrevious } from './use-previous';

export const useOddsDiffAnimation = ({
    odds,
    isAlreadySelected,
    unselectedSourceColor,
}: {
    odds: number;
    isAlreadySelected: boolean;
    unselectedSourceColor?: string;
}) => {
    const [oddsDiff, setOddsDiff] = useState(0);

    const prevOdds = usePrevious<number>(odds) || odds;

    useEffect(() => {
        const diff = odds - prevOdds;
        if (diff !== 0) {
            setOddsDiff(diff);
        }
    }, [odds, prevOdds, setOddsDiff]);

    const { animatedOpacityStyle, animatedColorStyle } = useAnimatedOptionEffect(
        odds,
        prevOdds,
        isAlreadySelected,
        oddsDiff,
        unselectedSourceColor
    );

    return { animatedOpacityStyle, animatedColorStyle, oddsDiff };
};
