import { useMemo } from 'react';

import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { useSubscribePublic } from '@/utils/websocket/hooks';

export const useSubscribeLeagueMatchUpdate = (leagueId: string) => {
    const handleMatchUpdate = useEventDetailsCache(state => state.actions.handleMatchUpdate);
    const params = useMemo(() => ({ leagueId }), [leagueId]);
    useSubscribePublic<'leagueMatchUpdate'>('league:{leagueId}', params, 'match_update', handleMatchUpdate);
};
