import React from 'react';
import { LayoutAnimation, StyleSheet, TouchableOpacity } from 'react-native';

import { EventInfo } from '@/api/events/types/types';
import EdgeComboIcon from '@/assets/icons/edge-combo-icon.js';
import { LineSeparator } from '@/components/LineSeparator';
import { Box, Row } from '@/components/lib/components';
import { BetslipPick } from '@/feature/betslip-pickem/types';
import { PlayerRow } from '@/feature/lobby/components/PlayerRow';
import { PlayerRowSkeleton } from '@/feature/lobby/components/Skeletons/PlayerRowSkeleton';
import { PickSelectionMethods } from '@/feature/lobby/hooks/use-pick-selection';
import { common, designSystem } from '@/styles/styles';
import { runStoreUpdate } from '@/utils/zustand';

import { isPlayerSelected } from '../utils/betslip-utils';
import { eventUtils } from '../utils/event-utils';
import { useEdgeCombo } from './EdgeComboModalProvider';

export const PlayerPickSlipCard: React.FC<
    BetslipPick & {
        presentation: 'default' | 'sheet';
        shouldDisplayEdgeComboIcon?: boolean;
        shouldDisplaySeparator?: boolean;
        eventDetails?: EventInfo;
        loading?: boolean;
        edgeComboPlayers?: [BetslipPick, BetslipPick];
    } & PickSelectionMethods
> = ({
    player: playerFromPickSlip,
    outcome,
    projection,
    presentation,
    shouldDisplayEdgeComboIcon,
    shouldDisplaySeparator,
    eventDetails,
    makeSelection,
    openPlayerPickModal,
    removeSelection,
    loading,
    edgeComboPlayers,
}) => {
    const { showAcceptEdgeComboPairModal } = useEdgeCombo();
    const team = eventUtils.getTeamById(eventDetails, playerFromPickSlip.team?.id);

    const playerWithLatestValues = team?.players?.find(p => p.id === playerFromPickSlip.id);
    const player = {
        ...(playerWithLatestValues || playerFromPickSlip),
        team,
        league: playerFromPickSlip.league,
        sport: playerFromPickSlip.sport,
    };
    const picked = player && isPlayerSelected({ playerId: player.id, eventId: eventDetails?.id });

    const animateRemove = () => {
        LayoutAnimation.configureNext({
            update: { type: 'easeInEaseOut' },
            duration: 300,
        });
        runStoreUpdate(() => {
            picked && removeSelection([{ player: player, eventId: picked.eventId }]);
        });
    };

    const handlePress = () => {
        if (edgeComboPlayers) {
            showAcceptEdgeComboPairModal({
                edgeCombo: edgeComboPlayers,
                displayMode: 'info',
            });
        }
    };

    if (loading) {
        return (
            <>
                <PlayerRowSkeleton showSeparator={true} paddingVertical={'s0'} />
                {shouldDisplaySeparator ? <LineSeparator style={[common.hairlineHeight, styles.separator]} /> : null}
            </>
        );
    }

    return (
        <Box>
            <PlayerRow
                mode="selection"
                projection={projection}
                player={player}
                event={eventDetails}
                outcome={outcome}
                showTrash
                pressable
                presentation={presentation}
                onRemoveFromLineup={animateRemove}
                makeSelection={makeSelection}
                openPlayerPickModal={openPlayerPickModal}
                removeSelection={removeSelection}
                paddingVertical={'s0'}
            />
            {shouldDisplayEdgeComboIcon ? (
                <Row alignItems={'center'} style={styles.edgeComboSeparator}>
                    <TouchableOpacity
                        hitSlop={EDGE_COMBO_ICON_HEIGHT * 2}
                        onPress={handlePress}
                        style={styles.edgeComboIcon}
                    >
                        <EdgeComboIcon
                            color={designSystem.colors.gray3}
                            width={EDGE_COMBO_ICON_HEIGHT}
                            height={EDGE_COMBO_ICON_HEIGHT}
                        />
                    </TouchableOpacity>
                    <Box flex={1}>
                        <LineSeparator style={common.hairlineHeight} />
                    </Box>
                </Row>
            ) : shouldDisplaySeparator ? (
                <LineSeparator style={[common.hairlineHeight, styles.separator]} />
            ) : null}
        </Box>
    );
};

const EDGE_COMBO_ICON_HEIGHT = 16;

const styles = StyleSheet.create({
    edgeComboIcon: {
        paddingLeft: 16,
        marginRight: 32,
    },
    separator: {
        marginVertical: 16,
    },
    edgeComboSeparator: {
        marginVertical: 8,
    },
});
