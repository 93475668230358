import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const CloseThin = props => (
    <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            fill={props.color ?? '#2F3038'}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.05676 8.00018L3.28552 4.22894L4.22833 3.28613L7.99957 7.05737L11.7708 3.28613L12.7136 4.22894L8.94238 8.00018L12.7136 11.7714L11.7708 12.7142L7.99957 8.94299L4.22833 12.7142L3.28552 11.7714L7.05676 8.00018Z"
        />
    </Svg>
);

export default CloseThin;
