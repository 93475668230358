import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TextInput, useWindowDimensions } from 'react-native';
import Animated, {
    Extrapolation,
    SharedValue,
    interpolate,
    measure,
    runOnJS,
    runOnUI,
    useAnimatedRef,
    useAnimatedStyle,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';

import ClearIcon from '@/assets/icons/clear';
import SearchIcon from '@/assets/icons/pickem-search';
import { PRODUCT_SWITCH_BUTTON_HEIGHT } from '@/components/LobbyProductSwitchButton';
import { Text } from '@/components/TextComponent';
import { Row, TouchableBox } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { SCROLLBAR_WIDTH } from '@/feature/responsive-design/WebComponents';
import { PickemLobbyTabNavigationProp } from '@/navigation/pickem/types';
import { common, designSystem } from '@/styles/styles';
import { MAX_WEB_WIDTH } from '@/utils/constants-platform-specific';

import { SEARCH_BAR_PADDING_TOP } from '../screens/SearchScreen';

type SearchBarProps = {
    query?: string;
    setQuery?: (query: string) => void;
    display?: 'lobby' | 'search';
    inputRef?: React.RefObject<TextInput>;
    onFocus?: () => void;
    onBlur?: () => void;
    onPressCancel?: () => void;
    animatedValue?: SharedValue<number>;
    disableCancelButton?: boolean;
};

export const SEARCH_BAR_HEIGHT = 42;
const SEARCH_BAR_PADDING = 16;

const CANCEL_BUTTON_WIDTH = 78;

export const SearchBar = memo(
    ({
        query,
        setQuery,
        display = 'lobby',
        inputRef,
        onFocus,
        onBlur,
        animatedValue,
        onPressCancel,
        disableCancelButton,
    }: SearchBarProps) => {
        const navigation = useNavigation<PickemLobbyTabNavigationProp>();
        const { t } = useTranslation(['common', 'search']);
        const { width } = useWindowDimensions();

        const animatedSearchRef = useAnimatedRef();

        const animatedCancelStyle = useAnimatedStyle(() => {
            return {
                // make the "cancel" button appear only after the search bar has expanded at least 80%
                // so it has the "space" to appear
                opacity: interpolate(animatedValue?.value ?? 0, [0, 1], [0, 1], Extrapolation.CLAMP),
                transform: [
                    {
                        translateX: interpolate(animatedValue?.value ?? 0, [0, 1], [50, 0], Extrapolation.CLAMP),
                    },
                ],
            };
        });

        const maxAvailableWidth = MAX_WEB_WIDTH > width ? width - SCROLLBAR_WIDTH : MAX_WEB_WIDTH;

        const fullSearchBarWidth = maxAvailableWidth - SEARCH_BAR_PADDING * 2;

        const animatedWidthStyle = useAnimatedStyle(() => {
            if (display !== 'search' || !animatedValue) {
                return {
                    width: fullSearchBarWidth,
                };
            }

            return {
                width: interpolate(
                    animatedValue.value,
                    [0, 1],
                    // the cancel button has it's own padding, so that's why here we use only one padding
                    [
                        maxAvailableWidth - SEARCH_BAR_PADDING * 2,
                        maxAvailableWidth - SEARCH_BAR_PADDING - CANCEL_BUTTON_WIDTH,
                    ],
                    Extrapolation.CLAMP
                ),
            };
        });

        const { top } = useSafeAreaInsets();

        const handleOnPress = useCallback(() => {
            if (display === 'lobby') {
                BetrAnalytics.trackEvent(AnalyticsEvent.SEARCH_OPEN);
                runOnUI(() => {
                    // when navigating to the search screen we need to measure the search bar
                    // so we can find it's current position on the screen (since the search bar is sticky)
                    const measuredSearch = measure(animatedSearchRef);

                    const pageY = measuredSearch?.pageY
                        ? measuredSearch?.pageY - top - SEARCH_BAR_PADDING_TOP
                        : PRODUCT_SWITCH_BUTTON_HEIGHT;

                    runOnJS(navigation.navigate)({
                        name: 'SearchScreen',
                        params: {
                            searchBarPosition: pageY,
                        },
                    });
                })();
            } else {
                inputRef?.current?.focus();
            }
        }, [animatedSearchRef, display, inputRef, navigation, top]);

        return (
            <Animated.View ref={animatedSearchRef}>
                <Row alignItems={'center'}>
                    <Animated.View
                        style={[styles.searchBarContainer, display === 'search' ? animatedWidthStyle : common.full]}
                    >
                        <TouchableBox activeOpacity={display === 'lobby' ? 0.7 : 1} onPress={handleOnPress} flex={1}>
                            <Row
                                pl={'s12'}
                                backgroundColor={'gray6'}
                                borderRadius={'r12'}
                                gap={'s12'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <SearchIcon />
                                <TextInput
                                    pointerEvents={display === 'lobby' ? 'none' : 'auto'}
                                    editable={display === 'search'}
                                    style={styles.input}
                                    placeholder={t('search:placeholder')}
                                    placeholderTextColor={designSystem.colors.gray3}
                                    value={query}
                                    onChangeText={text => setQuery?.(text)}
                                    returnKeyType={'done'}
                                    ref={inputRef}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                />
                                {query && query.length > 0 ? (
                                    <TouchableBox
                                        onPress={() => {
                                            inputRef?.current?.focus();
                                            setQuery?.('');
                                        }}
                                        paddingRight={'s16'}
                                        paddingLeft={'s12'}
                                        paddingVertical={'s10'}
                                    >
                                        <ClearIcon />
                                    </TouchableBox>
                                ) : null}
                            </Row>
                        </TouchableBox>
                    </Animated.View>
                    {display === 'search' ? (
                        <Animated.View style={[styles.cancelButtonContainer, animatedCancelStyle]}>
                            <TouchableBox
                                paddingLeft={'s16'}
                                onPress={onPressCancel}
                                paddingVertical={'s8'}
                                paddingRight={'s16'}
                                disabled={disableCancelButton}
                            >
                                <Text variant="bodyMedium">{t('common:cancel')}</Text>
                            </TouchableBox>
                        </Animated.View>
                    ) : null}
                </Row>
            </Animated.View>
        );
    }
);

const styles = StyleSheet.create({
    input: {
        flex: 1,
        color: designSystem.colors.white,
        fontSize: 15,
        textAlignVertical: 'center',
        fontWeight: '400',
        letterSpacing: -0.23,
        paddingVertical: 12,
        lineHeight: 20,
    },
    cancelButtonContainer: {
        position: 'absolute',
        right: 0,
        width: CANCEL_BUTTON_WIDTH,
    },
    searchBarContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: SEARCH_BAR_HEIGHT,
    },
});
