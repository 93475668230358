import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';

import { InfoLineupUpdateModal, InfoLineupUpdateModalRef, InfoLineupUpdateProps } from './InfoLineupUpdateModal';

type InfoLineupUpdate = {
    showInfoLineupUpdateModal: (props: InfoLineupUpdateProps) => void;
};

const InfoLineupUpdateContext = React.createContext<InfoLineupUpdate>({
    showInfoLineupUpdateModal: () => {},
});

/**
 * Provides access to the Modal used to display the list of players with projection changes from betslip when always accept progection changes is on
 */
export const InfoLineupUpdateProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<InfoLineupUpdateModalRef>(null);

    const showInfoLineupUpdateModal = useCallback((props: InfoLineupUpdateProps) => {
        modalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <InfoLineupUpdateContext.Provider value={{ showInfoLineupUpdateModal }}>
                {children}
                <InfoLineupUpdateModal ref={modalRef} />
            </InfoLineupUpdateContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useInfoLineupUpdate = () => {
    return useContext(InfoLineupUpdateContext);
};
