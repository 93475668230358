import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { common, designSystem } from '@/styles/styles';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { LIMIT_DURATION } from '../constants';
import { ConfirmUserLimitPayload, UserLimit, UserLimitType } from '../hooks/types';
import { useConfirmPendingLimits } from '../hooks/use-confirm-pending-limits';

const styles = StyleSheet.create({
    root: {
        backgroundColor: designSystem.colors.gray6,
        paddingHorizontal: 20,
        paddingTop: 14,
        paddingBottom: 20,
        borderRadius: 10,
        borderColor: designSystem.colors.gray4,
        borderWidth: 1,
        marginBottom: 32,
    },
    row: {
        borderBottomWidth: 1,
        borderBottomColor: designSystem.colors.gray5,
        paddingVertical: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    confirmBtn: {
        width: 174,
    },
});

interface UserLimitProp extends UserLimit {
    user_limit_id?: string;
}

type PendingLimitCardProps = {
    limit: UserLimitProp;
    type: UserLimitType;
};

export const PendingLimitCard: React.FC<PendingLimitCardProps> = ({ limit, type }) => {
    const { mutate: confirmLimit } = useConfirmPendingLimits();
    const { t } = useTranslation(['rg', 'common']);

    const handleOnPress = (action: ConfirmUserLimitPayload['confirmation_action']) => {
        BetrAnalytics.trackProductEvent(
            action === 'accept' ? AnalyticsEvent.ACCEPT_PAYMENT_LIMIT : AnalyticsEvent.CANCEL_PAYMENT_LIMIT,
            { type }
        );
        if (limit.user_limit_id) {
            confirmLimit({ user_limit_id: limit.user_limit_id, confirmation_action: action });
        }
    };

    const durationText = limit.duration ? LIMIT_DURATION[limit.duration] : '';
    const durationTextToLowerCase = limit.duration ? LIMIT_DURATION[limit.duration].toLowerCase() : '';
    let typeText = '';

    switch (type) {
        case 'DEPOSIT':
            typeText = 'deposit';
            break;
        case 'WAGER':
            typeText = 'play';
            break;
        case 'SINGLE_WAGER':
            typeText = 'Max single play';
            break;
    }
    const liveTime = new Date(limit?.limit_goes_live_at ?? 0);
    const dateString = liveTime.toLocaleDateString('default', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
    const timeString = liveTime.toLocaleTimeString('default', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZoneName: 'short',
    });
    const isConfirmDisabled = liveTime > new Date() || !limit.user_limit_id; // cannot confirm before activation time or no user_limit_id

    // Used dynamic variables to remove unwanted whitespace when duration is not provided (ie SINGLE_WAGER)
    const cardHeaderText = durationText ? `${durationText} ${typeText} limit` : `${typeText} limit`;
    const limitText = durationTextToLowerCase ? `${durationTextToLowerCase} limit` : 'limit';

    return (
        <View style={styles.root}>
            <Text fontSize={15} fontWeight="bold" lineHeight={24}>
                {cardHeaderText}
            </Text>
            <SizedBox value={8} />
            <View style={styles.row}>
                <Text fontSize={15} lineHeight={24}>{`Old ${limitText}`}</Text>
                <Text fontSize={15} lineHeight={24} fontWeight="600" testID="pendingOldLimitAmount">
                    {toLocaleCurrency(limit.amount)}
                </Text>
            </View>
            <View style={styles.row}>
                <Text fontSize={15} lineHeight={24}>{`New ${limitText}`}</Text>
                <Text fontSize={15} lineHeight={24} fontWeight="600" testID="pendingNewLimitAmount">
                    {!limit.new_amount ? `No ${limitText}` : `${toLocaleCurrency(limit.new_amount)}`}
                </Text>
            </View>
            <SizedBox value={16} />
            <Text fontSize={13} color={'gray3'}>
                {t('confirm_activation', { date: dateString, time: timeString })}
            </Text>
            <SizedBox value={16} />
            <View style={[common.row, common.justifyEvenly]}>
                <Button
                    style={styles.confirmBtn}
                    label={
                        isConfirmDisabled ? (
                            <Text fontSize={15} fontWeight="bold" textAlign="center" color="gray3">
                                {t('common:confirm')}
                            </Text>
                        ) : (
                            t('common:confirm')
                        )
                    }
                    onPress={() => handleOnPress('accept')}
                    variant="rounded"
                    type={isConfirmDisabled ? 'activeGray' : 'active'}
                    disabled={isConfirmDisabled}
                />

                <View style={[common.flex, common.alignCenter, common.justifyCenter]}>
                    <TouchableOpacity onPress={() => handleOnPress('reject')} disabled={!limit.user_limit_id}>
                        <Text
                            fontWeight="bold"
                            textDecorationLine={'underline'}
                            fontSize={15}
                            lineHeight={20}
                            color={limit.user_limit_id ? undefined : 'gray3'}
                        >
                            {t('common:cancel')}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};
