import React from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { TextInput as TextInputComponent, TextInputProps } from '@/components/TextInput';
import { designSystem } from '@/styles/styles';

type CurrencyInputProps = {
    value: string;
} & TextInputProps;

export const CurrencyInput = React.forwardRef<RNTextInput, CurrencyInputProps>(({ value, ...textProps }, ref) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const style = { color: value !== '$' || isFocused ? designSystem.colors.gray1 : designSystem.colors.gray2 };

    return (
        <TextInputComponent
            ref={ref}
            {...textProps}
            value={value}
            textInputStyle={style}
            onFocus={e => {
                setIsFocused(true);
                textProps?.onFocus?.(e);
            }}
            onBlur={e => {
                setIsFocused(false);
                textProps?.onBlur?.(e);
            }}
        />
    );
});
