import React from 'react';

import TrashCanIcon from '@/assets/icons/trash';
import { Box } from '@/components/lib/components';
import { RemovableCardType } from '@/types/removable-cards';

import { styles } from '../../styles';
import { BaseCardInfo, InfoIconComponent } from './BaseCardItemInfo';
import { lineItemStyle } from './styles';

export const NonInteractableCardItem = ({ card }: { card: RemovableCardType }) => {
    return (
        <Box testID={`debit-card-${card.payment_type_id}`} style={lineItemStyle}>
            <BaseCardInfo card={card} showDistributionAmount isInteractable={false} />
            <Box gap="s18" flexDirection="row" alignItems="center" justifyContent="center">
                <InfoIconComponent />
                <Box alignItems="center" justifyContent="center" style={styles.opacity}>
                    <TrashCanIcon width={24} height={24} />
                </Box>
            </Box>
        </Box>
    );
};
