import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
        <Path
            fill="#000"
            fillRule="evenodd"
            d="M14 7A7 7 0 1 1 0 7a7 7 0 0 1 14 0Zm-6.25.19V2.5h-1.5v5.31l2.72 2.72 1.06-1.06-2.28-2.28Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
