import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import DownIcon from '@/assets/icons/down';

import { TextInput } from './TextInput';

type PickerInputProps = {
    label: string;
    value: string;
    setShowPicker: (value: boolean) => void;
};

const styles = StyleSheet.create({
    downArrow: {
        position: 'absolute',
        top: 20,
        right: 22,
        marginRight: -10,
    },
});

export const PickerInput = ({ label, value, setShowPicker }: PickerInputProps) => {
    return (
        <TouchableOpacity onPress={() => setShowPicker(true)} activeOpacity={1} accessible={false}>
            <View pointerEvents={'none'} accessible={false}>
                <TextInput
                    label={label}
                    value={value}
                    editable={false}
                    keyboardAppearance="dark"
                    rightIcon={<DownIcon style={styles.downArrow} />}
                    accessible={true}
                    testID={`stateSelected-${value}`}
                />
            </View>
        </TouchableOpacity>
    );
};
