import AsyncStorage from '@react-native-async-storage/async-storage';

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type LocalFlag = {
    enabled: boolean;
    description?: string;
    label?: string;
};

const defaultValues = {
    simulatePaysafeError: {
        enabled: false,
        label: 'Simulate Paysafe Error',
        description:
            'This flag enables to convert the deposit amount into minor unit amounts (e.g., $0.01) for debit deposits, simulating Paysafe payment responses. It allows individuals to independently test the deposit salvage flow without configuration changes.', // Ref: https://developer.paysafe.com/en/api-docs/cards/test-and-go-live/simulating-card-payments/
    },
    playerCardDebugButtons: {
        enabled: false,
        label: 'Player Card Debug Buttons',
        description:
            'This flag enable some debug buttons on the player card, to allow us to easily debug some hard to produce scenarios like suspended markets, live projections etc.',
    },
} as const;

export type Flags = keyof typeof defaultValues;

export interface LocalFlagsStore {
    localFlags: Record<Flags, LocalFlag>;
    _hydrated: boolean;
    actions: { changeFlag: (id: Flags) => void; setHydrated: () => void; resetFlags: () => void };
}

export const useLocalFlagsStore = create<LocalFlagsStore>()(
    persist(
        (set, _get) => ({
            localFlags: defaultValues,
            _hydrated: false,
            actions: {
                changeFlag: (id: Flags) => {
                    set(({ localFlags }) => ({
                        localFlags: {
                            ...localFlags,
                            [id]: { ...localFlags[id], enabled: !localFlags[id].enabled },
                        },
                    }));
                },
                setHydrated: () => {
                    set({ _hydrated: true });
                },
                resetFlags: () => {
                    set({ localFlags: defaultValues });
                },
            },
        }),
        {
            name: 'localFlags-storage',
            version: 3,
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, ...rest }: LocalFlagsStore) => rest,
            onRehydrateStorage: () => store => {
                store?.actions.setHydrated();
            },
        }
    )
);

// This hook is used to convert the deposit amount into minor/major unit amounts for debit deposits, simulating Paysafe payment responses. This returns the originally passed amount if the `simulatePaysafeError` flag is not enabled on `DeveloperScreen`. Otherwise, it returns the converted amount.
export const useDepositAmountConverter = () => {
    const { localFlags } = useLocalFlagsStore();

    const convertToMinorUnitAmount = (initialAmount: string) => {
        if (localFlags?.simulatePaysafeError?.enabled && initialAmount) {
            return (parseFloat(initialAmount) / 100).toString(); // $13 -> $0.13
        }
        return initialAmount;
    };

    const convertToMajorUnitAmount = (minorUnitAmount: string) => {
        if (localFlags?.simulatePaysafeError?.enabled && minorUnitAmount) {
            return (parseFloat(minorUnitAmount) * 100).toString(); // $0.13 -> $13
        }
        return minorUnitAmount;
    };

    return { convertToMinorUnitAmount, convertToMajorUnitAmount };
};
