import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { designSystem } from '@/styles/styles';

const SvgComponent = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <Path
      stroke={props.color ?? designSystem.colors.gray1}
      strokeWidth={2}
      d="M2 8h6V2M16 2v6h6M8 22v-6H2M22 16h-6v6"
    />
  </Svg>
);
export default SvgComponent;
