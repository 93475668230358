import React, { useEffect } from 'react';
import { ActivityIndicator, Modal, StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { getGeocomplyClient } from '@/data/location/geocomply-common';

import { Screen } from '../../components/ScreenComponent';
import { actions, useUser } from '../../hooks/use-user';

const styles = StyleSheet.create({
    spinner: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export const LogoutScreen = () => {
    const navigation = useNavigation<StackNavigationProp<{ Intro: undefined }>>();
    const { logout, session, loading } = useUser();

    useEffect(() => {
        actions.logout();
        getGeocomplyClient().shutdown();
    }, [logout, session, navigation]);

    return (
        <Screen>
            <Modal visible={loading} animationType="fade" transparent style={styles.spinner}>
                <View style={styles.spinner}>
                    <ActivityIndicator color={'white'} size={'large'} />
                </View>
            </Modal>
        </Screen>
    );
};
