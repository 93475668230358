import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} testID="specialsDiscountIcon" fill="none" {...props}>
        <Path
            fill={props.color ?? '#000'}
            fillRule="evenodd"
            d="M5.737 15.5 4.47 13.367l-2.4-.534.233-2.466L.67 8.5l1.633-1.867-.233-2.466 2.4-.534L5.737 1.5l2.266.967L10.27 1.5l1.267 2.133 2.4.534-.234 2.466L15.337 8.5l-1.634 1.867.234 2.466-2.4.534L10.27 15.5l-2.267-.967-2.266.967ZM9.974 4.983l.806.592-4.736 6.448-.806-.592 4.736-6.448ZM4.927 7.48A1 1 0 1 0 6.11 5.868 1 1 0 0 0 4.927 7.48Zm4.972 3.652a1 1 0 1 0 1.184-1.612A1 1 0 0 0 9.9 11.132Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
