import { useEffect } from 'react';

import { useNavigation } from '@react-navigation/native';
import { NavigationProp } from '@react-navigation/native';

import { getAuthUserConfig } from '@/hooks/use-auth-user-config';
import { RootStackParamList } from '@/navigation/types';
import { read, save } from '@/utils/async-storage';
import { isWeb } from '@/utils/constants-platform-specific';

export const DID_PROMPT_BIOMETRICS_ENABLE = 'didPromptBiometricsEnable';

const promptEnableBiometricSettings = async (navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>) => {
    const userConfig = await getAuthUserConfig();
    const didPromptBiometricsEnable = await read(DID_PROMPT_BIOMETRICS_ENABLE);
    if (!userConfig.data.config.IS_BIOMETRIC_ENABLED && !didPromptBiometricsEnable) {
        navigation.navigate('GuardBiometricsScreen');
    }
    // Ensuring that the biometric screen is shown only once per log in.
    // The value doesn't matter here since we are only using the key to check.
    save(DID_PROMPT_BIOMETRICS_ENABLE, 'true');
};

/**
 * Custom hook that prompts the user to enable biometric settings if they haven't done so.
 * The prompt is only shown once per login, enforced via AsyncStorage.
 */
export const usePromptBiometricGuard = () => {
    const navigation = useNavigation();

    useEffect(() => {
        // Bypass GuardBiometricsScreen on WEB
        if (!isWeb) {
            promptEnableBiometricSettings(navigation);
        }
    }, [navigation]);
};
