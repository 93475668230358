import React, { useCallback, useState } from 'react';
import { ActivityIndicator } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Picker } from '@/components/Picker';
import { PickerInput } from '@/components/PickerInput';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useActiveWalletStore } from '@/hooks/use-active-wallet';
import { useBackHandler } from '@/hooks/use-back-handler';
import { Product, State, getProductType, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { navigateToProduct, useCoreNavigation } from '@/navigation/navigation';
import { t } from 'i18next';

export const getStateLabel = (value: string, app_availability_states: State[]) =>
    app_availability_states.reduce((label, state) => (state.value === value ? state.label : label), '');

export const StateSelector = () => {
    const navigation = useNavigation();
    const { showIneligibleStateError } = useCoreNavigation();
    const activeJurisdiction = useJurisdictionStore(state => state.jurisdiction) || '';
    const activeProduct = useJurisdictionStore(state => state.product);
    const setJurisdiction = useJurisdictionStore(state => state.actions.setJurisdictionAndUpdateSettings);
    const isNativeSportsbookEnabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings?.native_sportsbook_enabled
    );
    const stateSelectorList =
        useJurisdictionStore(state => state.jurisdictionSettings?.globalSettings?.statesSelector) || [];

    const resetActiveWallet = useActiveWalletStore(state => state.resetActiveWallet);

    const [loading, setLoading] = useState<boolean>(false);
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [selectedState, setSelectedState] = useState<string>(activeJurisdiction);

    const handleValueSelected = async (value: string) => {
        //Await for the new settings to be applied
        BetrAnalytics.trackProductEvent(AnalyticsEvent.STATE_CHANGE, {
            state: getStateLabel(value, stateSelectorList),
        });
        setLoading(true);
        const newSettings = await setJurisdiction(value, 'manual');
        setLoading(false);
        const newDefaultProduct = getProductType(newSettings.productType);
        const newIsNativeSportsbookEnabled = newSettings.productConfig?.settings?.native_sportsbook_enabled;

        // Initialize the active wallet by resetting it to its default state
        resetActiveWallet();

        if (newDefaultProduct === Product.None) {
            //User has selected a state that is ineligible.
            //Show the error and revert back to the previous jurisdiction
            showIneligibleStateError();
            setJurisdiction(activeJurisdiction, 'manual');
            setSelectedState(activeJurisdiction);
        } else if (newDefaultProduct !== activeProduct) {
            //Default product is different, go to the new product, current one might not be available
            navigateToProduct(navigation, newDefaultProduct, 'account');
        } else if (newDefaultProduct === activeProduct && isNativeSportsbookEnabled !== newIsNativeSportsbookEnabled) {
            //Default product is the same, but the native sportsbook setting has changed, go to the new product
            navigateToProduct(navigation, newDefaultProduct, 'account');
        } else {
            //New setetings applied, just go back
            navigation.goBack();
        }
    };

    const handleBackPress = useCallback(() => {
        if (showPicker) {
            setShowPicker(false);
            return true;
        }
        return false;
    }, [showPicker]);

    useBackHandler(handleBackPress);

    if (loading) {
        return <LoadingState />;
    }

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('account:state_settings') }}
            scrollViewProps={{
                nestedScrollEnabled: true,
                showsVerticalScrollIndicator: false,
                style: { paddingHorizontal: 0 },
            }}
            footer={
                showPicker ? (
                    <Picker
                        items={stateSelectorList}
                        pickedValue={selectedState}
                        onValueSelected={handleValueSelected}
                        closePicker={() => setShowPicker(false)}
                        handleValueChange={setSelectedState}
                    />
                ) : null
            }
        >
            <MaxWidthWrapper paddingHorizontal={'s16'}>
                <SizedBox value={8} />
                <Text variant="headlineMedium">{t('account:select_state')}</Text>
                <SizedBox value={12} />
                <Text>{t('account:state_settings_subtext')}</Text>
                <SizedBox value={20} />
                <PickerInput
                    label="State"
                    value={getStateLabel(activeJurisdiction, stateSelectorList)}
                    setShowPicker={setShowPicker}
                />
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};

const LoadingState = () => {
    return (
        <Box flex={1} justifyContent={'center'} alignItems={'center'} paddingHorizontal={'s12'}>
            <ActivityIndicator />
            <Text textAlign={'center'}>{t('account:loading_state')}</Text>
        </Box>
    );
};
