import React from 'react';

import MergedLogos from '@/components/MergedLogos';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

import { TeamLogos } from '../scoreboard/types';

type MatchupHeaderProps = {
    logos: TeamLogos;
    shouldShowFallbackLogo: boolean;
    teamFallbackTextColors: string[];
    teamShortCodes: string[];
    isHeaderDisabled: boolean;
    eventHeader: string;
};

export const MatchupHeader = ({ isHeaderDisabled, eventHeader, ...matchUpHeaderProps }: MatchupHeaderProps) => {
    return (
        <Row alignItems="center" gap="s12">
            <Box width={44} height={24} overflow="hidden">
                <Box position="absolute" top={-2} left={-2}>
                    <MergedLogos
                        borderColor={designSystem.colors.gray8}
                        renderMode="shield"
                        overlap={6}
                        logoSizes={[16, 16]}
                        backdropSizes={[28, 24]}
                        imageShape="square"
                        borderRadius={8}
                        {...matchUpHeaderProps}
                    />
                </Box>
            </Box>
            <Text
                variant="bodySmall"
                paddingVertical={'s2'}
                color={isHeaderDisabled ? 'gray4' : 'gray2'}
                testID="comboCardEventDetails"
            >
                {eventHeader}
            </Text>
        </Row>
    );
};
