import { InteractionManager } from 'react-native';

import { isWeb } from './constants-platform-specific';

/**
 * Schedules a callback to run after the current interactions have completed.
 *
 * On mobile platforms, it uses `InteractionManager.runAfterInteractions`.
 * As InteractionManager doesn't work on web with React Navigation, uses `requestAnimationFrame` to schedule the callback.
 *
 * @param callback - The function to be executed after interactions.
 */
export const runAfterInteractions = (callback: () => void) => {
    if (isWeb) {
        if ('requestAnimationFrame' in window) {
            requestAnimationFrame(callback);
        } else {
            // Fallback for browsers that do not support requestAnimationFrame
            setTimeout(callback, 0);
        }
    } else {
        InteractionManager.runAfterInteractions(callback);
    }
};
