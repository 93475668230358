import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';

import { CalendarSheetModal, CalendarSheetModalRef, CalendarSheetProps } from './CalendarSheetModal';

type CalendarSheet = {
    showCalendarSheetModal: (props: CalendarSheetProps) => void;
};

const CalendarSheetContext = React.createContext<CalendarSheet>({
    showCalendarSheetModal: () => {},
});

/**
 * Provides a context for the CalendarSheet component, allowing other components to show and hide the calendar sheet modal.
 */
export const CalendarSheetProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<CalendarSheetModalRef>(null);

    const showCalendarSheetModal = useCallback((props: CalendarSheetProps) => {
        modalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <CalendarSheetContext.Provider value={{ showCalendarSheetModal }}>
                {children}
                <CalendarSheetModal ref={modalRef} />
            </CalendarSheetContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useCalendarSheet = () => {
    return useContext(CalendarSheetContext);
};
