// sort-imports-ignore
import 'core-js/actual/array/from';
import 'core-js/actual/object/assign';
import 'core-js/actual/promise';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import './public/index.css';

import { AppRegistry } from 'react-native';

import { setupEnv } from '@/data';
import { setupI18N } from '@/i18n/i18n.config.web';
import 'core-js/actual/array/from';
import 'core-js/actual/object/assign';
import 'core-js/actual/promise';
import 'resize-observer-polyfill/dist/ResizeObserver.global';

import app from './app.json';
import App from './src/app';
import { logger } from './src/utils/logging';
import './src/styles/fonts.css';

setupEnv();
setupI18N();
AppRegistry.registerComponent(app.name, () => App);
AppRegistry.runApplication(app.name, {
    rootTag: document.getElementById('root'),
});

/**
 * Service Worker:
 * - Registers the service worker on window load if supported by the browser.
 * - Logs the registration scope or error to the console.
 */
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then(registration => logger.info('Service Worker registered with scope:', registration.scope))
            .catch(error => logger.error('Service Worker registration failed:', error));
    });
}
