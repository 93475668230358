import React from 'react';

import { TouchableBox } from '@/components/lib/components';

import { AnimatedPressableOpacityProps } from './types';

/**
 * Wrapper around TouchableBox since on iOS we want the same behavior as before
 */
export const AnimatedPressableOpacity = ({ children, ...rest }: AnimatedPressableOpacityProps) => {
    return <TouchableBox {...rest}>{children}</TouchableBox>;
};
