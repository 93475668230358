import React, { Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useEventsInfoQuery } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Box } from '@/components/lib/components';
import { ScrollableModal } from '@/feature/alerts/components/Modal';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useBetslipActions } from '@/feature/betslip-pickem/hooks/use-betslip-actions';
import { useBetslipNavigation } from '@/feature/betslip-pickem/hooks/use-betslip-navigation';
import { BetslipRefferer, useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { useUpdatePlayerStoreWithNewData } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { mapPicksToBetSlipPick } from '@/feature/betslip-pickem/utils/betslip-utils';
import { eventUtils } from '@/feature/betslip-pickem/utils/event-utils';
import { SharedEntryPicks } from '@/feature/entry-share/api/types';
import { PlayerRow } from '@/feature/lobby/components/PlayerRow';
import { PlayerRowSkeleton } from '@/feature/lobby/components/Skeletons/PlayerRowSkeleton';
import { usePickSelection } from '@/feature/lobby/hooks/use-pick-selection';
import { common } from '@/styles/styles';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

export type AddLineupProps = {
    lineup: SharedEntryPicks;
    refferer?: BetslipRefferer;
};

const modalId = 'addLineup';

export type AddLineupModalRef = {
    show: (data: AddLineupProps) => void;
};

export const AddLineupModal = React.forwardRef<AddLineupModalRef, {}>((_props, ref) => {
    const [modalProps, setModalProps] = useState<AddLineupProps | undefined>(undefined);
    const modalRef = useRef<BottomSheetModal>(null);
    const [finalPicks, setFinalPicks] = useState<SharedEntryPicks | undefined>(undefined);
    const [{ data }, execute] = useEventsInfoQuery({
        variables: { ids: modalProps ? modalProps?.lineup.map(it => it.eventId) : [] },
        pause: true,
    });

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setModalProps(pickData);
            modalRef.current?.present();
        },
    }));

    useEffect(() => {
        if (modalProps) {
            setFinalPicks(modalProps.lineup);
            execute();
        }
    }, [modalProps, execute]);

    useUpdatePlayerStoreWithNewData(data?.getEventsByIdsV2);

    const allEvents = useMemo(() => {
        return data?.getEventsByIdsV2 ?? [];
    }, [data]);

    const loading = allEvents.length === 0 || !finalPicks;

    return (
        <ScrollableModal
            id={modalId}
            sheetRef={modalRef}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={[common.paddingHorizontal]}
            footer={
                <Footer
                    loading={loading}
                    onCancel={() => modalRef.current?.dismiss()}
                    allEvents={allEvents}
                    referrer={modalProps?.refferer}
                    finalPicks={finalPicks}
                />
            }
        >
            <ModalContent
                loading={loading}
                finalPicks={finalPicks}
                allEvents={allEvents}
                setFinalPicks={setFinalPicks}
                dismissModal={() => modalRef.current?.dismiss()}
            />
        </ScrollableModal>
    );
});

const ModalContent = ({
    finalPicks,
    allEvents,
    setFinalPicks,
    dismissModal,
    loading,
}: {
    loading: boolean;
    dismissModal: () => void;
    allEvents?: EventInfo[];
    finalPicks?: SharedEntryPicks;
    setFinalPicks: React.Dispatch<(prevState: SharedEntryPicks | undefined) => SharedEntryPicks | undefined>;
}) => {
    const { makeSelection, removeSelection, openPlayerPickModal } = usePickSelection();

    return finalPicks?.map((pick, index) => {
        const event = allEvents?.find(ev => ev.id === pick.eventId);
        const team = eventUtils.getTeamById(event, pick.teamId);
        return (
            <Fragment key={`${pick.player.id}`}>
                {loading ? (
                    <PlayerRowSkeleton />
                ) : (
                    <PlayerRow
                        mode="selection"
                        projection={pick.projection}
                        player={{ ...pick.player, team: team, projections: [pick.projection] }}
                        event={event}
                        outcome={pick.outcome}
                        presentation={'sheet'}
                        showTrash={true}
                        onRemoveFromLineup={() => {
                            if (finalPicks.length === 1) {
                                dismissModal();
                            }
                            setFinalPicks(prevPicks => {
                                return (prevPicks ?? []).filter(prevPick => prevPick.player.id !== pick.player.id);
                            });
                        }}
                        onOutcomeChange={outcome => {
                            setFinalPicks(prevPicks => {
                                return (prevPicks ?? []).map(prevPick =>
                                    prevPick.player.id === pick.player.id ? { ...prevPick, outcome } : prevPick
                                );
                            });
                        }}
                        openPlayerPickModal={openPlayerPickModal}
                        makeSelection={makeSelection}
                        removeSelection={removeSelection}
                    />
                )}
                {index === finalPicks.length - 1 ? <LineSeparator style={common.hairlineHeight} /> : null}
            </Fragment>
        );
    });
};

const Footer = ({
    loading,
    onCancel,
    referrer,
    allEvents,
    finalPicks,
}: {
    finalPicks?: SharedEntryPicks;
    allEvents: EventInfo[];
    loading: boolean;
    onCancel: () => void;
    referrer?: BetslipRefferer;
}) => {
    const { bottom } = useSafeAreaInsets();
    const { t } = useTranslation(['lineup_update_modal', 'common']);
    const { showInfoSheet } = useAlerts();
    const replacePicks = useBetslipStore(state => state.actions.replacePicks);
    const betslip = useBetslipStore(state => state.betslip);
    const { syncPicks } = useBetslipActions();
    const { navigateToPickslip } = useBetslipNavigation();

    const onAddLineup = useCallback(() => {
        if (betslip.length > 0) {
            //User already has picks, show a confirmation modal
            showInfoSheet({
                title: t('replace_lineup_title'),
                description: t('replace_lineup_info'),
                buttonLabel: t('replace_lineup'),
                handlePress: () => {
                    replacePicks(mapPicksToBetSlipPick(finalPicks ?? [], allEvents), referrer);
                    syncPicks({ expectError: false });
                    navigateToPickslip();
                },
                secondaryLabel: t('keep_lineup'),
            });
        } else {
            replacePicks(mapPicksToBetSlipPick(finalPicks ?? [], allEvents), referrer);
            syncPicks({ expectError: false });
            navigateToPickslip();
        }
        onCancel();
    }, [
        allEvents,
        betslip.length,
        finalPicks,
        referrer,
        navigateToPickslip,
        replacePicks,
        showInfoSheet,
        syncPicks,
        onCancel,
        t,
    ]);
    return (
        <Box padding="s16">
            <Button
                label={t('lineup_update_modal:add_lineup')}
                type={'active'}
                loading={loading}
                disabled={loading}
                style={[common.full]}
                variant={'rounded'}
                size={'medium'}
                onPress={onAddLineup}
            />
            <SizedBox value={16} />
            <Button
                label={t('common:cancel')}
                style={[common.full]}
                type="activeGray"
                variant={'rounded'}
                size={'medium'}
                onPress={onCancel}
            />
            <SizedBox value={bottom} />
        </Box>
    );
};
