import { NewMarketMessage } from '@/utils/websocket/types';
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { Market, MarketCategory, MarketCategoryType } from '../types';

export const generateUniqueMicroMarketId = (market: Market) =>
    [market.event_id, market.market_type.code, market.market_type.params?.team_id].filter(Boolean).join('-');

const generateUniqueEventMarketCategoryId = (eventId: string, marketCategoryType: MarketCategoryType) => {
    return `${eventId}-${marketCategoryType}`;
};

type MicroMarketCache = {
    markets: Record<string, Market>;
    marketOrder: Record<string, string[]>;
    actions: {
        handleNewMarket: (message: NewMarketMessage, marketCategoryType: MarketCategoryType) => void;
        handleLeagueNewMarket: (message: NewMarketMessage) => void;
        populate: (marketCategories: MarketCategory[], eventId: string, marketCategoryType: MarketCategoryType) => void;
        clearCache: () => void;
    };
};

export const useMicroMarketCacheStore = create<MicroMarketCache>()(set => {
    return {
        markets: {},
        marketOrder: {},
        actions: {
            clearCache: () => set({ markets: {}, marketOrder: {} }),
            handleLeagueNewMarket: (message: NewMarketMessage) => {
                const microMarketId = generateUniqueMicroMarketId(message);
                set(state => {
                    return {
                        ...state,
                        markets: {
                            ...state.markets,
                            [microMarketId]: message,
                        },
                    };
                });
            },
            handleNewMarket: (message: NewMarketMessage, marketCategoryType: MarketCategoryType) => {
                const microMarketId = generateUniqueMicroMarketId(message);
                set(state => {
                    const id = generateUniqueEventMarketCategoryId(message.event_id, marketCategoryType);
                    const marketOrder = [...state.marketOrder[id]] || [];
                    if (!marketOrder.includes(microMarketId)) {
                        marketOrder.push(microMarketId);
                    }
                    return {
                        ...state,
                        marketOrder: {
                            ...state.marketOrder,
                            [message.event_id]: marketOrder,
                        },
                        markets: {
                            ...state.markets,
                            [microMarketId]: message,
                        },
                    };
                });
            },
            populate: (marketCategories: MarketCategory[], eventId: string, marketCategoryType: MarketCategoryType) => {
                const marketCache: MicroMarketCache['markets'] = {};
                const marketOrder: MicroMarketCache['marketOrder'] = {};
                const id = generateUniqueEventMarketCategoryId(eventId, marketCategoryType);

                marketCategories.forEach(marketCategory => {
                    const publishedMarketOrLastMarket =
                        marketCategory.markets.find(market => market.published) ||
                        marketCategory.markets[marketCategory.markets.length - 1];

                    const microMarketId = generateUniqueMicroMarketId(publishedMarketOrLastMarket);
                    marketCache[microMarketId] = publishedMarketOrLastMarket;
                    if (!marketOrder[id]) {
                        marketOrder[id] = [];
                    }
                    if (!marketOrder[id].includes(microMarketId)) {
                        marketOrder[id].push(microMarketId);
                    }
                });

                set(state => ({
                    ...state,
                    markets: {
                        ...state.markets,
                        ...marketCache,
                    },
                    marketOrder: {
                        ...state.marketOrder,
                        [id]: marketOrder[id],
                    },
                }));
            },
        },
    };
});

export const useMicroMarketOrder = (eventId: string, marketCategoryType: MarketCategoryType) =>
    useMicroMarketCacheStore(
        useShallow(state => state.marketOrder?.[generateUniqueEventMarketCategoryId(eventId, marketCategoryType)] || [])
    );

export const useMicroMarket = (microMarketId: string) =>
    useMicroMarketCacheStore(state => state.markets[microMarketId]);
