/**
 * Initializes Google Autocomplete and handles input events on the #street input field.
 * When the user types in the #street field, it fetches predictions from Google Autocomplete
 * and displays them in the #predictions element. When a prediction is clicked, it fills
 * the #street, #state, and #zip fields with the details of the selected place.
 */

export const googleAutocomplete = `
    function initGoogleAutocomplete() {
        // Get the input and output elements from the DOM
        const input = document.getElementById('street');
        const predictionsElement = document.getElementById('predictions');
        const stateElement = document.getElementById('state');
        const zipElement = document.getElementById('zip');

        // Initialize Google Autocomplete and Places services
        const autocompleteService = new google.maps.places.AutocompleteService();
        const placesService = new google.maps.places.PlacesService(input);
        const sessionToken = new google.maps.places.AutocompleteSessionToken();

        function debounce(func, delay) {
            let debounceTimer;
            return function() {
                const context = this;
                const args = arguments;
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => func.apply(context, args), delay);
            }
        }
        
        // Handle input events on the #street input field
        const handleInput = debounce(function() {
            const inputValue = input.value;
            if (inputValue) {
                // If there's input, get predictions from the Autocomplete service
                autocompleteService.getPlacePredictions({
                    input: inputValue,
                    componentRestrictions: { country: 'us' },
                    sessionToken: sessionToken
                }, handlePredictions);
            } else {
                // If there's no input, clear the predictions
                predictionsElement.innerHTML = '';
                predictionsElement.style.display = 'none';
            }
        }, 800); // Aligned with the useDebounce hook.
        
        function handlePredictions(predictions, status) {
            if (status == google.maps.places.PlacesServiceStatus.OK) {
                // If the status is OK, display the predictions
                predictionsElement.innerHTML = '';
                predictions.forEach(prediction => {
                    const li = document.createElement('li');
                    li.textContent = prediction.description;
                    li.addEventListener('click', () => handlePredictionClick(prediction.place_id));
                    predictionsElement.appendChild(li);
                });
                predictionsElement.style.display = 'block';
                scrollIntoViewIfNeeded();
            }
        }
        function handlePredictionClick(placeId) {
            placesService.getDetails({ placeId: placeId }, handlePlaceDetails);
        }
        function handlePlaceDetails(place, status) {
            if (status == google.maps.places.PlacesServiceStatus.OK) {
                // If the status is OK, fill the input fields with the place details
                let street = '';
                let state = '';
                let zip = '';
                place.address_components.forEach(component => {
                    const componentType = component.types[0];
                    switch (componentType) {
                        case 'street_number': {
                            street = component.long_name + ' ';
                            break;
                        }
                        case 'route': {
                            street += component.short_name;
                            break;
                        }
                        case 'administrative_area_level_1': {
                            state = component.long_name;
                            selectedStateValue = component.short_name;
                            updateAmericanStateFieldStyle();
                            postMessageToParentOrWebView(JSON.stringify({ type: 'state', payload: component.short_name }));
                            break;
                        }
                        case 'postal_code': {
                            zip = component.short_name;
                            break;
                        }
                    }
                });
                input.value = street;
                stateElement.value = state;
                zipElement.value = zip;
                predictionsElement.style.display = 'none';
            }
        }
        // Scroll the predictions into view if they're not visible
        function scrollIntoViewIfNeeded() {
            const rect = predictionsElement.getBoundingClientRect();
            const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
            if (!isVisible) {
                const offset = 50;
                window.scrollBy({ top: rect.bottom - window.innerHeight + offset, behavior: 'smooth' });
            }
        }
        function handleClickOutside(event) {
            if (event.target !== input && event.target !== predictionsElement) {
                // If the click is outside the input and predictions, hide the predictions
                predictionsElement.style.display = 'none';
            }
        }
        // Add event listeners for input and click events
        input.addEventListener('input', handleInput);
        document.addEventListener('click', handleClickOutside);
    };

    initGoogleAutocomplete();
`;
