import React, { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { designSystem } from '@/styles/styles';

const section = StyleSheet.create({
    root: {
        backgroundColor: designSystem.colors.gray8,
        padding: 16,
    },
});

export const Section = ({ children, style }: PropsWithChildren<{ style?: StyleProp<ViewStyle> }>) => {
    return <View style={[section.root, style]}>{children}</View>;
};
