import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

const SvgComponent = ({ size = 16, color = '#959AA6', fillColor = '#8E979C', ...props }) => (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Circle cx={8} cy={8} r={6.25} stroke={color} strokeWidth={1.5} />
        <Path
            d="M8.66663 4.66663L7.33329 4.66663L7.33329 5.99996L8.66663 5.99996L8.66663 4.66663ZM8.66663 7.33329L7.33329 7.33329L7.33329 11.3333L8.66663 11.3333L8.66663 7.33329Z"
            fill={fillColor}
        />
    </Svg>
);

export default SvgComponent;
