import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = ({ color = designSystem.colors.white }) => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Circle cx="12" cy="12" r="2" fill={color} />

            <Path d="M3 6V3H6" stroke={color} strokeWidth="2" />
            <Path d="M18 3H21V6" stroke={color} strokeWidth="2" />
            <Path d="M6 21H3V18" stroke={color} strokeWidth="2" />
            <Path d="M21 18V21H18" stroke={color} strokeWidth="2" />
            <Path
                d="M12 7C7.5 7 5 10.3333 4.5 12C5 13.6667 7.5 17 12 17C16.5 17 19 13.6667 19.5 12C19 10.3333 16.5 7 12 7Z"
                stroke={color}
                strokeWidth="2"
            />
        </Svg>
    );
};

export default SvgComponent;
