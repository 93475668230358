import React, { useMemo } from 'react';
import { FlexAlignType, StyleSheet, View } from 'react-native';

import { TeamInfoFragment } from '@/api/events/query.generated';
import Replace from '@/assets/icons/replace';
import Shield from '@/assets/icons/shield';
import { common, designSystem } from '@/styles/styles';
import { League } from '@/types/api.generated';
import { AtLeast } from '@/types/utils';

import { PlayerImage } from './PlayerImage';
import { TeamLogoFallbackText } from './TeamLogoFallback';
import { Image } from './lib/components';

const styles = StyleSheet.create({
    backdrop: {
        justifyContent: 'center',
        alignItems: 'center',
        borderCurve: 'continuous',
    },
    logo: {
        alignSelf: 'center',
    },
    overflow: {
        overflow: 'hidden',
    },
});

type CommonProps = {
    renderMode: 'player' | 'team' | 'playerAndTeam' | 'shield';
    playerImageSizes?: { height: number; width: number };
    imagePlayerBgColor?: string;
    mode?: 'players' | 'replace';
    shouldShowFallbackLogo?: boolean;
    showFullTeamName?: boolean;
};

export type MergedLogosProps = {
    logos: [AtLeast<TeamInfoFragment, 'icon'>?, AtLeast<TeamInfoFragment, 'icon'>?];
    logoSizes?: [number, number];
    backdropSizes?: [number, number];
    overlap?: number;
    borderColor?: string;
    imageShape?: 'circle' | 'square';
    borderRadius?: number;
    teamFallbackTextColors?: string[];
    teamShortCodes?: (string | undefined)[];
    league?: League;
    fontSizes?: [number, number];
    borderWidth?: number;
} & CommonProps;

const getBackgroundColor = (
    renderMode: 'player' | 'team' | 'playerAndTeam' | 'shield',
    shouldShowFallbackLogo: boolean,
    first: AtLeast<TeamInfoFragment, 'icon'> | undefined,
    second: AtLeast<TeamInfoFragment, 'icon'> | undefined,
    imagePlayerBgColor: string | undefined,
    teamFallbackTextColors: string[]
) => {
    if (
        renderMode === 'shield' &&
        shouldShowFallbackLogo &&
        first?.color === designSystem.colors.gray3 &&
        second?.color === designSystem.colors.gray3
    ) {
        return [designSystem.colors.gray7, designSystem.colors.gray7];
    }

    if (renderMode === 'shield' && shouldShowFallbackLogo) {
        return teamFallbackTextColors;
    }

    const firstColor = first?.color ? first.color : imagePlayerBgColor ? imagePlayerBgColor : designSystem.colors.gray4;

    const secondColor = second?.color
        ? second.color
        : imagePlayerBgColor
        ? imagePlayerBgColor
        : designSystem.colors.gray4;

    return [firstColor, secondColor];
};

const MergedLogos = ({
    logos: [first, second],
    backdropSizes = [36, 36],
    logoSizes = [24, 24],
    overlap = 8,
    renderMode,
    playerImageSizes,
    imagePlayerBgColor,
    borderColor,
    mode = 'players',
    imageShape = 'circle',
    borderRadius = 10,
    teamFallbackTextColors = [designSystem.colors.gray1, designSystem.colors.gray1],
    teamShortCodes = ['', ''],
    shouldShowFallbackLogo = false,
    league,
    showFullTeamName = false,
    fontSizes = [12, 12],
    borderWidth,
}: MergedLogosProps) => {
    const firstLogoBorderWidth = borderWidth ?? 2;
    const firstLogoBorderRadius = borderWidth ? borderRadius : borderRadius + firstLogoBorderWidth;

    const [firstBgColor, secondBgColor] = getBackgroundColor(
        renderMode,
        shouldShowFallbackLogo,
        first,
        second,
        imagePlayerBgColor,
        teamFallbackTextColors
    );

    const backdropStyles = useMemo(
        () => ({
            first: {
                ...styles.backdrop,
                width: backdropSizes[0],
                height: backdropSizes[0],
                borderWidth: firstLogoBorderWidth,
                borderRadius: imageShape === 'square' ? firstLogoBorderRadius : backdropSizes[0] / 2,
                borderColor: borderColor ?? '',
                backgroundColor: firstBgColor,
            },
            second: {
                ...styles.backdrop,
                width: backdropSizes[1],
                height: backdropSizes[1],
                marginLeft: -overlap,
                borderWidth: borderWidth,
                borderRadius: imageShape === 'square' ? borderRadius : backdropSizes[1] / 2,
                borderColor: borderColor ?? '',
                backgroundColor: secondBgColor,
            },
        }),
        [
            backdropSizes,
            imageShape,
            firstLogoBorderWidth,
            firstLogoBorderRadius,
            borderColor,
            firstBgColor,
            overlap,
            borderWidth,
            borderRadius,
            secondBgColor,
        ]
    );
    const logoStyles = useMemo(
        () => ({
            first: { ...styles.logo, width: logoSizes[0], height: logoSizes[0] },
            second: { ...styles.logo, width: logoSizes[1], height: logoSizes[1] },
        }),
        // ! check only if values have changed, not the array dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [logoSizes[0], logoSizes[1]]
    );

    // Can't render without these two
    if (!first || !second) {
        return null;
    }

    return (
        <View style={[common.row, common.alignCenter]}>
            <View style={[backdropStyles.first, styles.overflow]}>
                <View style={backdropStyles.first}>
                    <Logo
                        renderMode={renderMode}
                        mode={mode}
                        icon={first.icon}
                        shouldShowFallbackLogo={shouldShowFallbackLogo}
                        imagePlayerBgColor={imagePlayerBgColor}
                        isFirst={true}
                        logoSize={logoSizes[0]}
                        teamFallbackTextColor={teamFallbackTextColors[0]}
                        teamShortCode={teamShortCodes[0]}
                        logoStyle={logoStyles.first}
                        playerImageSizes={playerImageSizes}
                        showFullTeamName={showFullTeamName}
                        fontSize={fontSizes[0]}
                        league={league}
                        primaryColor={first.color}
                        secondaryColor={teamFallbackTextColors[0]}
                    />
                </View>
            </View>
            <View style={[backdropStyles.second, common.zIndexNegative1, styles.overflow]}>
                <Logo
                    renderMode={renderMode}
                    mode={mode}
                    icon={second.icon}
                    shouldShowFallbackLogo={shouldShowFallbackLogo}
                    imagePlayerBgColor={imagePlayerBgColor}
                    logoSize={logoSizes[1]}
                    teamFallbackTextColor={teamFallbackTextColors[1]}
                    teamShortCode={teamShortCodes[1]}
                    logoStyle={logoStyles.second}
                    playerImageSizes={playerImageSizes}
                    league={league}
                    showFullTeamName={showFullTeamName}
                    fontSize={fontSizes[1]}
                    primaryColor={second.color}
                    secondaryColor={teamFallbackTextColors[1]}
                />
            </View>
        </View>
    );
};

export default MergedLogos;

type LogoProps = {
    icon: string;
    isFirst?: boolean;
    logoStyle: {
        width: number;
        height: number;
        alignSelf: 'auto' | FlexAlignType | undefined;
    };
    logoSize: number;
    teamShortCode?: string;
    teamFallbackTextColor: string;
    league?: League;
    fontSize?: number;
    primaryColor?: string;
    secondaryColor: string;
} & CommonProps;

export const Logo = ({
    renderMode,
    playerImageSizes,
    logoSize,
    imagePlayerBgColor,
    icon,
    shouldShowFallbackLogo,
    isFirst,
    mode,
    teamShortCode,
    logoStyle,
    teamFallbackTextColor,
    league,
    showFullTeamName,
    fontSize,
    primaryColor,
    secondaryColor,
}: LogoProps) => {
    const source = useMemo(() => ({ uri: icon }), [icon]);

    if (renderMode === 'player' || renderMode === 'playerAndTeam') {
        return (
            <PlayerImage
                url={icon}
                playerImageSizes={playerImageSizes}
                imageBackgroundColor={imagePlayerBgColor}
                league={league}
            />
        );
    }

    if (icon && !shouldShowFallbackLogo) {
        return (
            <Image source={source} resizeMode="contain" style={logoStyle}>
                {!isFirst && mode === 'replace' ? <Replace /> : null}
            </Image>
        );
    }

    if (renderMode === 'shield') {
        return (
            <Shield
                primary={primaryColor}
                secondary={secondaryColor}
                width={logoStyle.width}
                height={logoStyle.height}
            />
        );
    }

    return (
        <TeamLogoFallbackText
            height={logoSize - 1}
            teamName={teamShortCode}
            variant="iconFallback"
            textColor={teamFallbackTextColor}
            showFullTeamName={showFullTeamName}
            fontSize={fontSize}
        />
    );
};
