import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = ({ color = designSystem.colors.gray8, width = 14, height = 14, ...props }) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none" {...props}>
        <Path
            fill={color}
            fillRule="evenodd"
            d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14Zm-.75-3V9h1.5v2h-1.5Zm0-8v4.5h1.5V3h-1.5Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
