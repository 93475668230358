import { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigation } from '@react-navigation/native';

import { PaymentMethodTypes } from '@/feature/deposit/const';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { isWeb } from '@/utils/constants-platform-specific';

import { WithdrawMethod, useWithdrawalMethods } from './use-withdrawal-methods';

/**
 * This is a hook used to get withdrawal methods and and according to this,
 * it verifies if it will navigate to the view of choose multiple payment methods
 * or view of the withdrawal form (with single withdrawal method).
 */
export const useNavigateToWithdraw = () => {
    const { data: withdrawMethods = [], isLoading: loadingMethods } = useWithdrawalMethods({
        select: data => data?.data,
    });
    const jurisdictionSettings = useJurisdictionStore(state => state.jurisdictionSettings);
    const showWithdrawIbt = !jurisdictionSettings?.globalSettings?.featureFlags?.hide_deposit_ibt?.enabled ?? false;
    const showWithdrawPayByBankWeb =
        !jurisdictionSettings?.globalSettings?.featureFlags?.hide_pay_by_bank_web?.enabled ?? false;
    const [selectedMethod, setSelectedMethod] = useState<WithdrawMethod>(withdrawMethods?.[0]);
    const navigation = useNavigation();

    /**
     * Filters withdrawal methods based on closed loop completion status and platform-specific criteria.
     *
     * @description
     * This function applies the following filtering logic:
     * 1. If there are any methods with closed loop not completed, only these will be shown.
     * 2. Otherwise, all available withdrawal methods will be displayed.
     * 3. On web platform, additional filtering is applied:
     *    - PaysafeMazooma method is hidden if showWithdrawIbt is false.
     */
    const filteredWithdrawalMethods = useMemo(() => {
        const closedLoopNotCompletedList = withdrawMethods?.filter(
            (method: WithdrawMethod) => !method?.closed_loop_completed
        );
        const filteredByClosedLoop =
            closedLoopNotCompletedList?.length > 0 ? closedLoopNotCompletedList : withdrawMethods;

        const filteredMethods = filteredByClosedLoop.filter(method => {
            if (isWeb) {
                if (!showWithdrawIbt && method.type === PaymentMethodTypes.PaysafeMazooma) {
                    return false;
                } else if (!showWithdrawPayByBankWeb && method.type === PaymentMethodTypes.PaysafePayByBank) {
                    return false;
                }
            }
            return true;
        });

        return filteredMethods;
    }, [showWithdrawIbt, showWithdrawPayByBankWeb, withdrawMethods]);

    const onGoToWithdrawHome = useCallback(() => {
        if (filteredWithdrawalMethods?.length > 1) {
            navigation.navigate('ChooseWithdrawalMethod', {
                methods: filteredWithdrawalMethods,
            });
        } else {
            navigation.navigate('WithdrawForm', {
                selectedMethod,
                methods: filteredWithdrawalMethods,
            });
        }
    }, [filteredWithdrawalMethods, navigation, selectedMethod]);

    /*
    If there is only one withdrawal method coming from backend, this method is selected by default from the
    withdrawal methods array.
    */
    useEffect(() => {
        if (!loadingMethods && filteredWithdrawalMethods?.length === 1) {
            setSelectedMethod(filteredWithdrawalMethods?.[0]);
            navigation.setParams({
                selectedMethod: filteredWithdrawalMethods?.[0],
                methods: filteredWithdrawalMethods,
            });
        }
    }, [filteredWithdrawalMethods, navigation, loadingMethods]);

    return { onGoToWithdrawHome };
};
