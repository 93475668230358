import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { TeamLogoFallbackText } from '@/components/TeamLogoFallback';
import { Text } from '@/components/TextComponent';
import { Box, Image, Row } from '@/components/lib/components';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';
import { Event, Market, Player, Team } from '@/feature/event-details-sbk/types';
import { designSystem } from '@/styles/styles';
import { formatPlayerName } from '@/utils/format-player-name';
import { renderDate } from '@/utils/renderDate';

import { OptionButton } from '../../OptionButton';

export const BOX_SIZE = 48;
export const LOGO_SIZE = 36;

const styles = StyleSheet.create({
    teamLogo: {
        width: LOGO_SIZE,
        height: LOGO_SIZE,
        borderRadius: 10,
    },
});

type PlayerInfoProps = {
    position?: string;
    shortCode?: string;
};

const PlayerInfo = ({ position, shortCode }: PlayerInfoProps) => {
    if (!position && !shortCode) {
        return null;
    }

    const playerInfo = [position, shortCode].filter(Boolean).join(', ');

    return (
        <Text variant="bodySmall" color="gray2">
            {` • ${playerInfo}`}
        </Text>
    );
};

const MarketPlayerPropStandard = ({ market, event }: { market: Market; event: Event }) => {
    const marketName = useMarketName({
        sportName: event.sport.name,
        marketType: market.market_type,
        fallback: market.market_type.code,
    });
    const { t } = useTranslation('sbk_prop_market');
    const { market_type } = market;
    let adjustedParams = { ...market_type.params };

    if (/^PLAYER_.*_MILESTONE$/.test(market_type.code)) {
        // PLAYER_{{STATISTIC}}_MILESTONE markets use translations displays milestone_yards to use 0.5 less than the provided value
        const milestoneYards = market_type.params?.milestone_yards;
        if (milestoneYards !== undefined) {
            adjustedParams = { ...adjustedParams, milestone_yards: milestoneYards - 0.5 };
        }
    }

    return (
        <>
            <Text variant="titleMedium">{t('line_value', { ...adjustedParams, fallback: ' ' })}</Text>

            <Text variant="bodySmall" color="gray2">
                {marketName}
            </Text>
        </>
    );
};

const renderMarketLayout = (market: Market, event: Event) => {
    switch (market.layout) {
        case 'player_prop_standard':
            return <MarketPlayerPropStandard market={market} event={event} />;
        case 'player_prop_list': {
            let option;
            if (market.options.length > 1) {
                // ignore the NO option
                option = market.options.find(op => op.option_type.code === 'YES');
            } else if (market.options.length === 1) {
                option = market.options[0];
            }

            if (!option) {
                return null;
            }

            return (
                <Row maxWidth={110} flexGrow={1}>
                    <OptionButton market={market} option={option} event={event} />
                </Row>
            );
        }
        default:
            return null;
    }
};

const renderEventInfo = (event: Event, player: Player) => {
    const date = new Date(event.start_time);
    const againstTeamShortCode =
        player.team.short_code === event.away_team.short_code
            ? `@ ${event?.home_team?.short_code}`
            : `vs ${event?.away_team?.short_code}`;

    return (
        <Text color="gray2" variant="bodySmall">
            {`${againstTeamShortCode} • ${renderDate(date)}`}
        </Text>
    );
};

const getTeamColorsLogo = (team: Team) => {
    let textColor = team.secondary_color;
    let backgroundColor = team.primary_color;
    const isMissingColors = !team.primary_color || !team.secondary_color;
    const shouldDisplayFallbackText = !team.logo_url || isMissingColors;
    if (isMissingColors) {
        textColor = designSystem.colors.gray1;
        backgroundColor = designSystem.colors.gray5;
    }

    return { textColor, backgroundColor, shouldDisplayFallbackText };
};

type PlayerRowProps = {
    market: Market;
    event: Event;
    hasEventInfo?: boolean;
};

export const PlayerRow = ({ market, event, hasEventInfo }: PlayerRowProps) => {
    const player = market.player;
    if (!player) {
        return null;
    }

    const { textColor, backgroundColor, shouldDisplayFallbackText } = getTeamColorsLogo(player.team);
    const playerTeamName = player.team.name;

    return (
        <Row>
            <Box
                borderRadius="r10"
                alignItems="center"
                justifyContent="center"
                width={BOX_SIZE}
                height={BOX_SIZE}
                style={{ backgroundColor }}
            >
                {shouldDisplayFallbackText ? (
                    <TeamLogoFallbackText textColor={textColor} height={BOX_SIZE} teamName={player.team.short_code} />
                ) : (
                    <Image resizeMode="contain" source={{ uri: player.team.logo_url }} style={styles.teamLogo} />
                )}
            </Box>

            <Box mx="s16" justifyContent="center" flexGrow={1}>
                <Text variant="titleMedium">
                    {formatPlayerName({ firstName: player.first_name, lastName: player.last_name })}
                    <PlayerInfo position={player.position} shortCode={hasEventInfo ? player.team.short_code : ''} />
                </Text>
                <Text color="gray2" variant="bodySmall" numberOfLines={1}>
                    {hasEventInfo ? renderEventInfo(event, player) : `${playerTeamName}`}
                </Text>
            </Box>
            <Box alignItems="flex-end" justifyContent="center" flexGrow={1}>
                {renderMarketLayout(market, event)}
            </Box>
        </Row>
    );
};
