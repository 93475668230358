import React, { PropsWithChildren, useEffect } from 'react';

import { user } from '@/hooks/use-user';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { subscribe } from 'valtio';

import { useModals } from '../hooks/use-modals';

/**
 * BottomSheetModalProvider that will dismiss all modals when the session expires.
 */
export const CustomBottomSheetModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { dismissAll } = useModals();

    useEffect(() => {
        return subscribe(user, () => {
            const expired = user.sessionHasExpired();
            if (expired) {
                dismissAll();
            }
        });
    }, [dismissAll]);
    return <BottomSheetModalProvider>{children}</BottomSheetModalProvider>;
};
