import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import ChevronRight from '@/assets/icons/chevronRight';
import InfoIcon from '@/assets/icons/info-full-white';
import TrashCanIcon from '@/assets/icons/trash';
import { PaymentLogo } from '@/components/PaymentLogo';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { ExpiredCardTag } from '@/feature/deposit/components/PaymentMethodItem';
import { ccTypeMap } from '@/feature/deposit/const';
import { paymentMethodStyles } from '@/feature/deposit/styles';
import { common } from '@/styles/styles';
import { RemovableCardType } from '@/types/removable-cards';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { styles } from '../styles';

const lineItemStyle = [
    paymentMethodStyles.lineItemBorder,
    common.row,
    common.alignCenter,
    common.justifySpaceBetween,
    { paddingVertical: 16 },
];

type DebitCardProps = {
    card: RemovableCardType;
    isInteractable: boolean;
    handleRemovalProcess: (paymentTypeId: string) => void;
};

/**
 * Component to render a removable debit card.
 * @param card - debit card details
 * @param isInteractable - flag to enable/disable interactions
 * @param handleRemovalProcess - function to handle the removal process
 * @returns a removable debit card component
 */
export const RemovableCard = ({ card, isInteractable, handleRemovalProcess }: DebitCardProps) => {
    const { t } = useTranslation(['manage_cards', 'common']);
    const { showInfoSheet } = useAlerts();
    const navigation = useNavigation();

    const { payment_type_id, card_brand, last4_digits, is_expired = false, is_removable, closed_loop_remain } = card;

    const last4Digits = `${t('debit_card')} ${last4_digits ? `(•••• ${last4_digits})` : ''}`;

    const handlePress = () => {
        if (!isInteractable) {
            return;
        }

        if (is_expired) {
            navigation.navigate('UpdateExpiredCard', { selectedMethod: card });
        } else {
            showInfoSheet({
                title: t('removing_a_debit_card'),
                description: t('debit_card_removal_steps_info'),
                buttonLabel: t('confirm'),
                secondaryLabel: t('close'),
                handlePress: () => handleRemovalProcess(payment_type_id),
            });
        }
    };

    const handlePressInfo = () => {
        showInfoSheet({
            title: t('removing_a_debit_card'),
            description: t('security_removable_cards_info'),
            buttonLabel: t('close'),
        });
    };

    const renderCardInfo = () => (
        <Box flex={1}>
            <Text variant="bodyMedium" style={!isInteractable && styles.opacity}>
                {card_brand ? ccTypeMap[card_brand] : ''}
            </Text>
            <Text color="gray2" variant="bodySmall" numberOfLines={1} style={!isInteractable && styles.opacity}>
                {last4Digits}
            </Text>
            {!is_removable ? (
                <Text color="gray2" variant="bodySmall" numberOfLines={1} style={styles.opacity}>
                    {t('required_distribution', {
                        close_loop_remain: toLocaleCurrency(closed_loop_remain),
                    })}
                </Text>
            ) : null}
        </Box>
    );

    const renderCardActions = () => {
        if (is_expired) {
            return (
                <Box gap="s18" flexDirection="row" alignItems="center" justifyContent="center">
                    <Box ml="s18" style={styles.expiredCardTagContainer}>
                        <ExpiredCardTag />
                    </Box>
                    <Box alignItems="flex-end" justifyContent="center">
                        <ChevronRight />
                    </Box>
                </Box>
            );
        }
        if (isInteractable) {
            return (
                <Box alignItems="center" justifyContent="center">
                    <TrashCanIcon width={24} height={24} />
                </Box>
            );
        }
        return (
            <Box gap="s18" flexDirection="row" alignItems="center" justifyContent="center">
                <Box flexDirection="row" alignItems="center" justifyContent="center">
                    <TouchableOpacity onPress={handlePressInfo}>
                        <InfoIcon width={24} height={24} />
                    </TouchableOpacity>
                </Box>
                <Box alignItems="center" justifyContent="center" style={styles.opacity}>
                    <TrashCanIcon width={24} height={24} />
                </Box>
            </Box>
        );
    };

    const CardWrapper = isInteractable ? TouchableOpacity : Box;
    const wrapperProps = isInteractable ? { onPress: handlePress } : {};

    return (
        <CardWrapper style={lineItemStyle} {...wrapperProps}>
            <Box flexDirection="row" flex={1} alignItems="center" testID={`debit-card-${payment_type_id}`}>
                <PaymentLogo source={card?.payment_logos} style={!isInteractable && styles.opacity} />
                {renderCardInfo()}
            </Box>
            {renderCardActions()}
        </CardWrapper>
    );
};
