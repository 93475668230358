import React, { Fragment, ReactElement, useMemo } from 'react';

import { useNavigation } from '@react-navigation/native';

import { openDialerWithNumber } from '@/utils/dial-phone-number';
import { RichTextField } from '@prismicio/client';

import { SizedBox, SizedBoxProps } from './SizedBox';
import { Text, TextProps } from './TextComponent';

// Supported types
export const ParsedTypes = {
    ROOT: 'root',
    H1: 'h1',
    P: 'p',
    BR: 'br',
    INNER_TEXT: 'inner_text',
    SITE: 'site',
    TEL: 'tel',
} as const;

export type ParsedType = (typeof ParsedTypes)[keyof typeof ParsedTypes];
type ParsedTypesProps = {
    [ParsedTypes.H1]: TextProps;
    [ParsedTypes.P]: TextProps;
    [ParsedTypes.BR]: SizedBoxProps;
    [ParsedTypes.SITE]: TextProps & { handleOnPress?: (url: string) => void };
    [ParsedTypes.TEL]: TextProps & { handleOnPress?: (url: string) => void };
};
export type ParsedTypeProps = Partial<ParsedTypesProps>;

type ParsedNode = {
    type: ParsedType;
    text: string;
    url: string | undefined;
    children: ParsedNode[];
};

/**
 * Create Parsed tree node object
 * @param type ParsedType
 * @param text string
 * @param url string | undefined
 * @returns ParsedNode
 */
function createNode(type: ParsedType, text: string = '', url: string | undefined = undefined): ParsedNode {
    return {
        type,
        text,
        url,
        children: [],
    };
}

/**
 *
 * @param data the data from prismic and filtered. eg. richTeparseGlobalSettings
 * @returns ParsedNode tree
 */
function convertToRichTextTree(data: RichTextField): ParsedNode {
    const rootNode: ParsedNode = createNode(ParsedTypes.ROOT);
    let currentNode = rootNode;

    data.forEach(item => {
        if (item?.type === 'heading1') {
            // heading1
            // src example: <h1>hello world</h1>
            // output example: <Text variant="h1">hello world</Text>
            const node = createNode(ParsedTypes.H1, item.text);
            currentNode.children.push(node);
        } else if (item?.type === 'paragraph' && item.text.length > 0 && item.spans.length === 0) {
            // Pure Text
            // src example: <p>hello world</p>
            // output example: <Text>hello world</Text>
            const node = createNode(ParsedTypes.P, item.text);
            currentNode.children.push(node);
        } else if (item?.type === 'paragraph' && item.text.length === 0 && item.spans.length === 0) {
            // Spacer
            // src example: <p></p>
            // output example: <SizedBox />
            const node = createNode(ParsedTypes.BR);
            currentNode.children.push(node);
        } else if (item?.type === 'paragraph' && item.spans.length > 0) {
            // Text with children such as link, tel, em, bold and etc.
            // src exmaple: <p>hello <a href="google.ca">link</a> world</p>
            // output example: <Text>hello <Text onPress{()=>{url}}>link</Text> world</Text>
            const node = createNode(ParsedTypes.P);
            let textPointer = 0;

            for (const span of item.spans) {
                if (span.start > textPointer) {
                    // Create a text node for text between spans
                    node.children.push(createNode(ParsedTypes.INNER_TEXT, item.text.slice(textPointer, span.start)));
                }
                switch (span.type) {
                    case 'hyperlink':
                        if (span.data.url?.startsWith('tel:')) {
                            // if beginning of span.data.url starts with 'tel:' then createNode with type 'tel'
                            node.children.push(
                                createNode(ParsedTypes.TEL, item.text.slice(span.start, span.end), span.data.url)
                            );
                        } else if (span.data.url?.startsWith('https://') || span.data.url?.startsWith('http://')) {
                            // site-link
                            node.children.push(
                                createNode(ParsedTypes.SITE, item.text.slice(span.start, span.end), span.data.url)
                            );
                        }
                        break;
                    default:
                }
                // Update the textPointer
                textPointer = span.end;
            }
            // Add reamining text
            if (textPointer < item.text.length) {
                node.children.push(createNode(ParsedTypes.INNER_TEXT, item.text.slice(textPointer)));
            }
            // add node to currentNode
            currentNode.children.push(node);
        }
    });

    return rootNode;
}

/**
 * Traverse the tree and convert it to ReactElement
 * @param node ParseNode
 * @returns ReactElement
 */
function traverseTree(node: ParsedNode, parsedTypesProps: ParsedTypesProps): ReactElement | null {
    switch (node.type) {
        case ParsedTypes.ROOT:
            return (
                <>
                    {node.children.map((child, index) => (
                        <Fragment key={`${node.type}-${index}`}>{traverseTree(child, parsedTypesProps)}</Fragment>
                    ))}
                </>
            );
        case ParsedTypes.H1:
            return (
                <Text variant={parsedTypesProps[node.type].variant} textAlign={parsedTypesProps[node.type].textAlign}>
                    {node.text}
                </Text>
            );
        case ParsedTypes.P:
            // P has two cases.
            // 1. <Text>{text}</Text>
            // 2. <Text>{children tree}</Text>
            return (
                <Text
                    variant={parsedTypesProps[node.type].variant}
                    color={parsedTypesProps[node.type].color}
                    textAlign={parsedTypesProps[node.type].textAlign}
                >
                    {node.children.length === 0
                        ? node.text
                        : node.children.map((child, index) => {
                              return (
                                  <Fragment key={`${node.type}-${index}`}>
                                      {traverseTree(child, parsedTypesProps)}
                                  </Fragment>
                              );
                          })}
                </Text>
            );
        case ParsedTypes.INNER_TEXT:
            // if the text is already in paragraph, let's call it inner_text.
            return <>{node.text}</>;
        case ParsedTypes.SITE:
            return (
                <Text
                    variant={parsedTypesProps[node.type].variant}
                    color={parsedTypesProps[node.type].color}
                    textAlign={parsedTypesProps[node.type].textAlign}
                    textDecorationLine={parsedTypesProps[node.type].textDecorationLine}
                    onPress={() => {
                        if (
                            node.url &&
                            parsedTypesProps[ParsedTypes.SITE] !== undefined &&
                            parsedTypesProps[ParsedTypes.SITE].handleOnPress !== undefined
                        ) {
                            parsedTypesProps[ParsedTypes.SITE]?.handleOnPress?.(node.url);
                        }
                    }}
                >
                    {node.text}
                </Text>
            );
        case ParsedTypes.TEL:
            return (
                <Text
                    variant={parsedTypesProps[node.type].variant}
                    color={parsedTypesProps[node.type].color}
                    textAlign={parsedTypesProps[node.type].textAlign}
                    textDecorationLine={parsedTypesProps[node.type].textDecorationLine}
                    onPress={() => {
                        if (node.url) {
                            parsedTypesProps[ParsedTypes.TEL]?.handleOnPress?.(node.url);
                        }
                    }}
                >
                    {node.text}
                </Text>
            );
        case ParsedTypes.BR:
            return <SizedBox value={parsedTypesProps[ParsedTypes.BR].value || 0} />;
        default:
            return null;
    }
}

/**
 * RichText component read the data from prismic and convert it to ReactElement
 *
 * @param param0 data is the data from prismic and filtered. eg. richTeparseGlobalSettings
 * @param param1 typeProps is the props for each type. eg. { [ParsedTypes.BR]: { value: 20 } }
 * @returns
 */
export default function RichText({ data, typeProps }: { data: RichTextField; typeProps?: ParsedTypeProps }) {
    const navigation = useNavigation();

    const defaultTemplateProps: ParsedTypesProps = useMemo(
        () => ({
            [ParsedTypes.ROOT]: {},
            [ParsedTypes.H1]: {
                variant: 'titleMedium',
                textAlign: 'center',
                ...typeProps?.[ParsedTypes.H1],
            },
            [ParsedTypes.P]: {
                variant: 'bodySmall',
                color: 'gray2',
                textAlign: 'center',
                ...typeProps?.[ParsedTypes.P],
            },
            [ParsedTypes.BR]: {
                value: 16,
                ...typeProps?.[ParsedTypes.BR],
            },
            [ParsedTypes.INNER_TEXT]: {},
            [ParsedTypes.SITE]: {
                variant: 'bodySmall',
                color: 'gray2',
                textAlign: 'center',
                textDecorationLine: 'underline',
                handleOnPress: (url: string) => {
                    navigation.navigate('ModalWebView', { uri: url });
                },
                ...typeProps?.[ParsedTypes.SITE],
            },
            [ParsedTypes.TEL]: {
                variant: 'bodySmall',
                color: 'gray2',
                textAlign: 'center',
                textDecorationLine: 'underline',
                handleOnPress: (url: string) => {
                    url && openDialerWithNumber(url);
                },
                ...typeProps?.[ParsedTypes.TEL],
            },
        }),
        [navigation, typeProps]
    );

    const tree = convertToRichTextTree(data);
    const result = traverseTree(tree, defaultTemplateProps);

    return <>{result}</>;
}
