import { NavigationProp, RouteProp } from '@react-navigation/native';
import { NavigationState } from '@react-navigation/routers';

import { URLS } from '@/data';
import { user } from '@/hooks/use-user';
import { IdpvSourceType, RootStackParamList } from '@/navigation/types';
import { isWeb } from '@/utils/constants-platform-specific';
import { useQuery } from '@tanstack/react-query';

type GenerateIdpvUrlResponse = { data: { applicant_id: string; url: string } };

type GetIdpvStatusResponse = {
    data: { status: IdpvStatus };
};

type Navigation = Omit<NavigationProp<ReactNavigation.RootParamList>, 'getState'> & {
    getState(): NavigationState | undefined;
};

export type IdpvStatus = 'activated' | 'created' | 'processing' | 'complete' | 'failed' | 'archived';
export type Route = RouteProp<RootStackParamList, keyof RootStackParamList>;

export const getPreviousRouteName = (navigation: Navigation, route: Route): string => {
    const navState = navigation.getState();
    const currentRouteIndex = navState && navState?.routes.findIndex(r => r.name === route.name);
    return currentRouteIndex && currentRouteIndex > 0 ? navState.routes[currentRouteIndex - 1].name : '';
};

export const generateIdpvUrl = async (
    applicantId: string,
    source: IdpvSourceType
): Promise<GenerateIdpvUrlResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/id-comply/document-scan`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${user.session?.access_token}`,
            'Content-Type': 'application/json',
            // X-Agent-Source is a custom header used to determine the source of the request (web or mobile)
            'X-Agent-Source': isWeb ? 'web' : 'mobile',
        },
        body: JSON.stringify({ applicant_id: applicantId, source }),
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return data;
};

export const getIdpvStatus = async (applicantId: string): Promise<GetIdpvStatusResponse> => {
    const query = new URLSearchParams();
    query.append('applicant_id', applicantId);
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/id-comply/document-scan?${query}`, {
        headers: {
            Authorization: `Bearer ${user.session?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return data;
};

export const idpvStatusKeys = {
    all: ['idpv-status'] as const,
    byApplicantId: (applicantId: string) => [...idpvStatusKeys.all, applicantId] as const,
};

export const useGetIdpvStatus = (applicantId: string) => {
    return useQuery([idpvStatusKeys.byApplicantId, applicantId], () => getIdpvStatus(applicantId), {
        select: data => data.data,
        enabled: !!applicantId,
    });
};
