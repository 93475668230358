import { URLS } from '@/data/config';
import { createActiveWalletJurisdictionHeaders, useActiveWalletStore } from '@/hooks/use-active-wallet';
import { logger } from '@/utils/logging';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { PaymentMethodTypes } from '../const';

// Constants
const FETCH_DEPOSIT_METHODS_ERROR_LOG = 'Error fetching deposit methods';
const UNKNOWN_FETCH_DEPOSIT_METHODS_ERROR = 'Unknown error occurred while fetching deposit methods.';

// Query keys
const depositMethodQueryKey = {
    all: ['deposit-payment-methods'],
    byJurisdiction: (jurisdiction: string) => [...depositMethodQueryKey.all, jurisdiction] as const,
};

// Schemas
const PaymentMethodTypeSchema = z.union([z.nativeEnum(PaymentMethodTypes), z.string()]);

const PaymentOptionSchema = z.object({
    name: z.string(),
    logos: z.string().array(),
    countries: z.string().array().optional(),
    position: z.number(),
});

const PaymentMethodSchema = z.object({
    iframe: z.boolean(),
    logos: z.string().array(),
    payment_logos: z.string().array().optional(), // update images
    min: z.number(),
    max: z.number(),
    methods: z.record(z.string(), PaymentOptionSchema).optional(), // Make methods optional
    name: z.string(),
    order: z.number(),
    supplier: z.string(),
    type: PaymentMethodTypeSchema,
    value: z.string(),
    accountId: z.string(),
});

const PreferredPaymentSchema = z.object({
    type: PaymentMethodTypeSchema,
    payment_method: z.string(),
    email: z.string().optional(),
    name: z.string().optional(),
    cc_type: z.string().optional(),
    cc_last4: z.string().optional(),
    logos: z.string().array().optional(),
    payment_logos: z.string().array().optional(), // update images
    payment_type_id: z.string().optional(),
    is_expired: z.boolean().optional().nullable(),
});

const DepositMethodsResponseSchema = z.object({
    data: PaymentMethodSchema.array(),
    preferred_payments: PreferredPaymentSchema.array(),
});

// Types
export type PaymentMethod = z.infer<typeof PaymentMethodSchema>;
export type PreferredPayment = z.infer<typeof PreferredPaymentSchema>;
export type PaymentMethodType = z.infer<typeof PaymentMethodTypeSchema>;

export type PaymentOption = z.infer<typeof PaymentOptionSchema>;
type DepositMethodsResponse = {
    data: PaymentMethod[];
    preferred_payments: PreferredPayment[];
};

type DepositMethodsQueryOptions<TData> = UseQueryOptions<
    DepositMethodsResponse,
    unknown,
    TData,
    ReturnType<typeof depositMethodQueryKey.byJurisdiction>
>;

// Extracted functions
const logDepositMethodsFetchError = (err: unknown) => {
    const message = err instanceof Error ? err.message : UNKNOWN_FETCH_DEPOSIT_METHODS_ERROR;
    const stack = err instanceof Error ? err.stack : undefined;
    logger.warn(FETCH_DEPOSIT_METHODS_ERROR_LOG, { message, stack });
};

const fetchDepositMethods = async () => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/deposit/options`, {
        method: 'GET',
        headers: {
            ...createActiveWalletJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return DepositMethodsResponseSchema.parse(data);
};

/**
 * Custom hook for fetching `data` which represents available payment providers and `preferred_payments` which are deposit payment methods you have access to.
 */
export const useDepositPaymentMethods = <TData = DepositMethodsResponse>(
    options?: DepositMethodsQueryOptions<TData>
) => {
    return useQuery(
        depositMethodQueryKey.byJurisdiction(useActiveWalletStore.getState().jurisdiction),
        fetchDepositMethods,
        {
            ...options,
            onError: logDepositMethodsFetchError,
        }
    );
};

// Selectors
export const preferredDebitPaymentSelector = (data: DepositMethodsResponse) => {
    return data.preferred_payments.filter(payment => payment.type === PaymentMethodTypes.Paysafe);
};
export const preferredPayPalPaymentSelector = (data: DepositMethodsResponse) => {
    return data.preferred_payments.find(payment => payment.type === PaymentMethodTypes.PaysafePaypal);
};
export const debitPaymentSelector = (data: DepositMethodsResponse) => {
    return data.data.find(payment => payment.type === PaymentMethodTypes.Paysafe);
};
// Checking if there are any saved Nuvei methods in the preferred payments
export const hasSavedNuveiMethodsSelector = (data: DepositMethodsResponse | undefined) => {
    return data?.preferred_payments.some(payment => payment.type === PaymentMethodTypes.PaysafeMazooma) ?? false;
};
