import { useCallback } from 'react';

import { toLocaleCurrency } from '@/utils/numeric/currency';

import { LIMIT_DURATION, USER_LIMIT_TYPE } from '../constants';
import { UserLimit, UserLimitDuration, UserLimitType } from './types';
import { activeLimitsSelector, useUserLimits } from './use-user-limits';

const DURATIONS = ['DAY', 'WEEK', 'MONTH'] as const;

export const limitTooHighError = (duration: UserLimitDuration, amount: number, type: UserLimitType) =>
    `Cannot be exceed ${LIMIT_DURATION[duration]} ${USER_LIMIT_TYPE[type]} Limit: ${toLocaleCurrency(amount)}`;

export const limitTooLowError = (duration: UserLimitDuration, amount: number, type: UserLimitType) =>
    `Must be higher than ${LIMIT_DURATION[duration]} ${USER_LIMIT_TYPE[type]} Limit: ${toLocaleCurrency(amount)}`;

/**
 * Custom hook to return an error message if the user tries to set a limit that is too high or too low.
 * @param {string} userLimitDuration - The duration of the limit being set.
 * @param {string} type - The type the limit being set.
 * @returns {object} - object that contains a function (getErrorMessage) that returns an error message if the limit is too high or too low.
 */
export const useAddLimitErrorMessage = (userLimitDuration: UserLimitDuration, type: UserLimitType) => {
    const { data: activeLimits } = useUserLimits({ select: activeLimitsSelector(type) });

    const getErrorMessage = useCallback(
        (amount: number): string | undefined => {
            if (amount === 0) {
                return;
            }
            return checkLimitConstraints(amount, userLimitDuration, type, activeLimits);
        },
        [activeLimits, userLimitDuration, type]
    );

    return { getErrorMessage };
};

const checkLimitConstraints = (
    amount: number,
    userLimitDuration: UserLimitDuration,
    type: UserLimitType,
    activeLimits?: UserLimit[]
): string | undefined => {
    const remainingDurations = DURATIONS.filter(duration => duration !== userLimitDuration);
    for (const duration of remainingDurations) {
        const limit = activeLimits?.find(l => l.duration === duration);

        if (limit) {
            // Get the index of the user's limit duration in the durations array.
            // A lower index means a shorter duration.
            const userLimitDurationIndex = DURATIONS.indexOf(userLimitDuration);

            // Get the index of the current duration in the durations array.
            // A higher index means a longer duration.
            const currentDurationIndex = DURATIONS.indexOf(duration);

            // If the user's limit duration is shorter (lower index) and the amount exceeds the limit, trigger an error.
            if (userLimitDurationIndex < currentDurationIndex && limit.amount && amount > limit.amount) {
                return limitTooHighError(duration, limit.amount, type);
            }

            // If the user's limit duration is longer (higher index) and the amount is below the limit, trigger an error.
            if (userLimitDurationIndex > currentDurationIndex && amount < limit.amount) {
                return limitTooLowError(duration, limit.amount, type);
            }
        }
    }

    return;
};
