import React, { useEffect, useRef, useState } from 'react';
import { Animated, StyleSheet } from 'react-native';

import { Text, TextProps } from '@/components/TextComponent';
import { Box, BoxProps, Column } from '@/components/lib/components';
import { textVariants } from '@/components/lib/typography';
import { usePrevious } from '@/hooks/use-previous';
import { nativeDriverValue } from '@/utils/constants-platform-specific';

type AnimatedNumberProps = {
    value: number;
    decimals?: number;
    appendText?: string;
    alignText?: BoxProps['alignItems'];
} & TextProps;

const styles = StyleSheet.create({
    tickerColumn: {
        position: 'absolute',
    },
    animateDown: {
        bottom: 0,
    },
});

const DOWN = 1;
const UP = -1;

export const AnimatedNumber = ({
    value,
    appendText,
    decimals = 2,
    alignText = 'flex-end',
    ...textProps
}: AnimatedNumberProps) => {
    const prevValue = usePrevious(value);
    const [displayValue, setDisplayValue] = useState(value);
    const position = useRef(new Animated.Value(0)).current;
    const lineHeight = textVariants[(textProps.variant as keyof typeof textVariants) || 'bodyMedium'].lineHeight;
    const animateDirection = value > (prevValue || 0) ? DOWN : UP;

    useEffect(() => {
        // Ensure that prevValue is not undefined
        if (prevValue !== undefined && prevValue !== value) {
            Animated.timing(position, {
                toValue: animateDirection * lineHeight,
                duration: 250,
                useNativeDriver: nativeDriverValue,
            }).start(() => {
                position.setValue(0);
                setDisplayValue(value); // Update the display value when the animation finishes
            });
        }
    }, [value, lineHeight, position, prevValue, animateDirection]);

    const isAnimateDown = animateDirection === DOWN;
    const decimalValue = value?.toFixed(decimals);
    const decimalDisplayValue = displayValue?.toFixed(decimals);

    return (
        <Box overflow="hidden" flexDirection="row-reverse">
            {appendText ? (
                <Column>
                    <Text {...textProps}>{appendText}</Text>
                </Column>
            ) : null}
            <Column alignItems={alignText}>
                <Animated.View
                    style={[
                        { transform: [{ translateY: position }] },
                        isAnimateDown && styles.animateDown,
                        styles.tickerColumn,
                    ]}
                >
                    <Text {...textProps}>{isAnimateDown ? decimalValue : decimalDisplayValue}</Text>
                    <Text {...textProps}>{isAnimateDown ? decimalDisplayValue : decimalValue}</Text>
                </Animated.View>
                {/** This is never actually displayed as it has an opacity = 0 but is used to ensure the display area is large enough to display the animated number. **/}
                <Text {...textProps} opacity={0}>
                    {decimalValue}
                </Text>
            </Column>
        </Box>
    );
};
