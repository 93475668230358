import { useFormatPeriod } from '@/hooks/use-format-period';
import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import {
    EventPeriodDetails,
    formatSportPeriodDetails,
} from '@/utils/format-sport-period-details/format-sport-period-details';
import { useShallow } from 'zustand/react/shallow';

/**
 * Custom hook that formats the match details for an event.
 *
 * @param event - The event period details.
 * @returns The formatted match details base on event sport type.
 * @example:
 *      Baseball: "Top 7, 2 Outs"
 *      American Football: "2nd, 15:00 • 2nd & 20, MIN 20"
 *      Basketball: "2nd, 15:00"
 *      Ice Hockey: "2nd, 20:00"
 */
export const useFormatMatchDetails = (event: EventPeriodDetails) => {
    const { periodName, gameClock, sportSpecific } = useEventDetailsCache(
        useShallow(state => {
            return {
                periodName: state.eventDetails[event.id]?.period_name ?? event.event_details?.period_name,
                gameClock: state.eventDetails[event.id]?.game_clock ?? event.event_details?.game_clock,
                sportSpecific: state.eventDetails[event.id]?.sport_specific ?? event.event_details?.sport_specific,
            };
        })
    );
    const periodLabel = useFormatPeriod({ event, periodName, gameClock, sportSpecific });
    const periodSportDetailsLabel = formatSportPeriodDetails(event, event.event_details?.sport_specific);

    const isFootball = event?.sport?.name === 'American Football';

    if (event.status === 'FINISHED') {
        return '';
    }

    if (periodLabel && periodSportDetailsLabel) {
        if (isFootball) {
            return `${periodLabel} • ${periodSportDetailsLabel}`;
        }
        return `${periodLabel}, ${periodSportDetailsLabel}`;
    } else if (periodLabel) {
        return periodLabel;
    }

    return '';
};
