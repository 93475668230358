import * as Urql from 'urql';
import gql from 'graphql-tag';

import * as Types from '../../types/api.generated';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetPoolsByEntryIdQueryVariables = Types.Exact<{
    entryId: Types.Scalars['String']['input'];
}>;

export type GetPoolsByEntryIdQuery = {
    __typename?: 'Query';
    getPoolsByEntryId: Array<{
        __typename?: 'Pool';
        id: string;
        status: Types.PoolStatus;
        numberOfPicks: number;
        entries: Array<{
            __typename?: 'PoolEntry';
            id: string;
            username: string;
            userExperienceLevel: Types.UserExperienceLevel;
            numberOfWinningPicks?: number;
            numberOfNonVoidPicks?: number;
            rank?: number;
            p2pPoints?: number;
            status: Types.Status;
            result?: Types.Result;
            count: number;
        }>;
    }>;
};

export type NotifyOnUpdatedPoolEntrySubscriptionVariables = Types.Exact<{
    id: Types.Scalars['String']['input'];
}>;

export type NotifyOnUpdatedPoolEntrySubscription = {
    __typename?: 'Subscription';
    notifyOnUpdatedPoolEntry: Array<{
        __typename?: 'Pool';
        id: string;
        status: Types.PoolStatus;
        numberOfPicks: number;
        entries: Array<{
            __typename?: 'PoolEntry';
            id: string;
            username: string;
            userExperienceLevel: Types.UserExperienceLevel;
            numberOfWinningPicks?: number;
            numberOfNonVoidPicks?: number;
            rank?: number;
            p2pPoints?: number;
            status: Types.Status;
            result?: Types.Result;
            count: number;
        }>;
    }>;
};

export type PoolDetailsFragment = {
    __typename?: 'Pool';
    id: string;
    status: Types.PoolStatus;
    numberOfPicks: number;
    entries: Array<{
        __typename?: 'PoolEntry';
        id: string;
        username: string;
        userExperienceLevel: Types.UserExperienceLevel;
        numberOfWinningPicks?: number;
        numberOfNonVoidPicks?: number;
        rank?: number;
        p2pPoints?: number;
        status: Types.Status;
        result?: Types.Result;
        count: number;
    }>;
};

export const PoolDetailsFragmentDoc = gql`
    fragment PoolDetails on Pool {
        id
        status
        numberOfPicks
        entries {
            id
            username
            userExperienceLevel
            numberOfWinningPicks
            numberOfNonVoidPicks
            rank
            p2pPoints
            status
            result
            count
        }
    }
`;
export const GetPoolsByEntryIdDocument = gql`
    query GetPoolsByEntryId($entryId: String!) {
        getPoolsByEntryId(entryId: $entryId) {
            ...PoolDetails
        }
    }
    ${PoolDetailsFragmentDoc}
`;

export function useGetPoolsByEntryIdQuery(options: Omit<Urql.UseQueryArgs<GetPoolsByEntryIdQueryVariables>, 'query'>) {
    return Urql.useQuery<GetPoolsByEntryIdQuery, GetPoolsByEntryIdQueryVariables>({
        query: GetPoolsByEntryIdDocument,
        ...options,
    });
}
export const NotifyOnUpdatedPoolEntryDocument = gql`
    subscription NotifyOnUpdatedPoolEntry($id: String!) {
        notifyOnUpdatedPoolEntry(id: $id) {
            ...PoolDetails
        }
    }
    ${PoolDetailsFragmentDoc}
`;

export function useNotifyOnUpdatedPoolEntrySubscription<TData = NotifyOnUpdatedPoolEntrySubscription>(
    options: Omit<Urql.UseSubscriptionArgs<NotifyOnUpdatedPoolEntrySubscriptionVariables>, 'query'>,
    handler?: Urql.SubscriptionHandler<NotifyOnUpdatedPoolEntrySubscription, TData>
) {
    return Urql.useSubscription<
        NotifyOnUpdatedPoolEntrySubscription,
        TData,
        NotifyOnUpdatedPoolEntrySubscriptionVariables
    >({ query: NotifyOnUpdatedPoolEntryDocument, ...options }, handler);
}
