import { useNavigation } from '@react-navigation/native';

import { failedKycCountSelector, isIdpvAllowedInKycSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useUser } from '@/hooks/use-user';
import { IdpvSource, IdpvSourceType } from '@/navigation/types';

import { initialKycScreens, kycRouteNames } from '../const';
import { useApplicantId } from './use-applicant-id';
import { useKycFieldsStore } from './use-kyc-fields';
import { useNavigateToErrorModal } from './use-navigate-to-error-modal';

type KycRetryControlParams = {
    hasIdScanFailed?: boolean;
    source?: IdpvSourceType;
};

type VerificationFailureMessage =
    | 'verification_failed_copy_account_locked'
    | 'verification_failed_copy_idpv'
    | 'verification_failed_copy'
    | 'verification_account_settings_fail_description';

type CtaLabel = 'logout' | 'done' | 'scan_id' | 'retry';

type KycRetryControlData = {
    verificationFailureMessage: VerificationFailureMessage;
    ctaLabel: CtaLabel;
    retryOrLogout: () => void;
    source?: IdpvSourceType;
};

const determineCtaLabelAndMessage = (
    idScanFailed: boolean,
    shouldGoIdpvFlow: boolean,
    source?: IdpvSourceType
): {
    message: VerificationFailureMessage;
    label: CtaLabel;
} => {
    if (source === IdpvSource.ACCOUNT_SETTING) {
        return { label: 'done', message: 'verification_account_settings_fail_description' };
    }
    if (idScanFailed) {
        return { label: 'logout', message: 'verification_failed_copy_account_locked' };
    }
    if (shouldGoIdpvFlow) {
        return { label: 'scan_id', message: 'verification_failed_copy_idpv' };
    }
    return { label: 'retry', message: 'verification_failed_copy' };
};

/*
 * Manages KYC verification failure scenarios and determines appropriate actions.
 */
export const useKycRetryControl = ({
    hasIdScanFailed = false,
    source = IdpvSource.KYC,
}: KycRetryControlParams): KycRetryControlData => {
    const navigation = useNavigation();
    const { logout } = useUser();

    const navigateToErrorModal = useNavigateToErrorModal();

    const globalSettings = useJurisdictionStore(store => store.jurisdictionSettings?.globalSettings?.featureFlags);
    const isIdpvEnabled = globalSettings?.idpv_flow_enabled.enabled ?? false;

    const { data: retryCount = 0 } = useAuthUserConfig({
        select: failedKycCountSelector,
        refetchOnMount: 'always',
    });

    // Fetch the personal flag indicating if IDPV is allowed for this user during KYC
    // This flag is used to determine whether to show IDPV or repeat the SSN9 step
    const { data: isIdpvAllowedInKyc = true } = useAuthUserConfig({
        select: isIdpvAllowedInKycSelector,
    });

    const setFieldValue = useKycFieldsStore(state => state.setFieldValue);

    const { data } = useApplicantId();

    const isRetryCountSufficient = retryCount === initialKycScreens.indexOf(kycRouteNames.SCAN_ID);
    const isApplicantIdValid = !!data?.applicant_id;

    // Determine if we should proceed with IDPV based on multiple conditions:
    // 1. IDPV is globally enabled
    // 2. The retry count is sufficient (user has failed the initial KYC steps)
    // 3. IDPV is allowed for this specific user during KYC (based on the new personal flag) -- This flag is from /auth/user/config endpoint
    const shouldProceedWithIdpv = isIdpvEnabled && isRetryCountSufficient && isIdpvAllowedInKyc;
    const isAccountLocked =
        source !== IdpvSource.ACCOUNT_SETTING && (hasIdScanFailed || retryCount > initialKycScreens.length - 1);

    const { message: verificationFailureMessage, label: ctaLabel } = determineCtaLabelAndMessage(
        isAccountLocked,
        shouldProceedWithIdpv,
        source
    );

    const retryOrLogout = () => {
        if (isAccountLocked) {
            logout();
        } else if (shouldProceedWithIdpv) {
            isApplicantIdValid ? navigation.navigate('KycScanId', { source: IdpvSource.KYC }) : navigateToErrorModal();
        } else {
            setFieldValue('ssn', '');
            navigation.navigate(kycRouteNames.SSN_RETRY);
        }
    };

    return {
        verificationFailureMessage,
        ctaLabel,
        retryOrLogout,
    };
};
