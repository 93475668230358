import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import InfoIcon from '@/assets/icons/info-small';
import { InfoSheetProps } from '@/feature/alerts/components/InfoSheet';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';
import { Event, MarketType } from '@/feature/event-details-sbk/types';
import { designSystem } from '@/styles/styles';
import { getSportKey } from '@/utils/get-sport-key';

import { Box } from './lib/components';

type EducationalTouchpointProps = {
    infoSheetProps: InfoSheetProps;
    iconSize?: number;
};

const EducationalTouchpoint = ({ infoSheetProps, iconSize }: EducationalTouchpointProps) => {
    const { t } = useTranslation('bets');
    const { showInfoSheet } = useAlerts();

    const handlePress = () => {
        showInfoSheet({
            ...infoSheetProps,
            buttonLabel: t('got_it'),
        });
    };

    return (
        <TouchableOpacity onPress={handlePress}>
            <InfoIcon size={iconSize} color={designSystem.colors.gray3} fillColor={designSystem.colors.gray3} />
        </TouchableOpacity>
    );
};

type MarketEducationalTouchpointProps = {
    marketType: MarketType;
    event: Event;
};

export const MarketEducationalTouchpoint = ({ marketType, event }: MarketEducationalTouchpointProps) => {
    const title = useMarketName({ marketType, event, sportName: event.sport.name, fallback: marketType.code });
    const { t } = useTranslation('educational_touchpoint');
    const sportKey = getSportKey(event.sport);
    const key = `sbk_markets.${sportKey}.${marketType.code}`;
    const translationParams = marketType.params || {};
    // @ts-expect-error -- cannot type safe dynamic translation
    const description: string | React.ReactNode = t(key, translationParams);
    const isInvalidDescriptionTranslation = description === key;

    if (isInvalidDescriptionTranslation) {
        return null;
    }
    return (
        <Box mx="s16">
            <EducationalTouchpoint
                infoSheetProps={{
                    title,
                    description,
                }}
                iconSize={22}
            />
        </Box>
    );
};
