import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import Animated, {
    Easing,
    FadeInDown,
    runOnJS,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withTiming,
} from 'react-native-reanimated';

import { CommonActions, NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native';

import WelcomePicksIcon from '@/assets/icons/welcomePicks';
import WelcomeSportsbookIcon from '@/assets/icons/welcomeSportsbook';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components/Box';
import { useActiveWalletStore } from '@/hooks/use-active-wallet';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { MAX_WEB_WIDTH } from '@/utils/constants-platform-specific';
import { runAfterInteractions } from '@/utils/runAfterInteractions';

type ProductTransitionRouteProp = RouteProp<RootStackParamList, 'ProductTransitionScreen'>;

const FADE_IN_DURATION = 300;
const FADE_OUT_DURATION = 300;
const FADE_OUT_DELAY = 400;
const NAVIGATION_DELAY = 50;

/**
 * This screen is used to transition between products. It fades in and out the product name.
 * You need to navigate to this screen with the product you want to transition to (as a navigation param)
 * This screen will then navigate to the correct product screen after the fade out animation.
 *
 * By default, for this screen we disabled all the react-navigation's animations
 * because we want to control the animations here.
 *
 * When the screen is mounted, it will fade in the product name if `shouldFadeOut` is false.
 * Since the navigation's animation is disabled, only our animation will run (the fade in animation).
 * When the fade in animation is finished, we will do the following:
 * - reset the current navigation, such that only this screen is left in the stack
 *      - it ensures that the next animation doesn't flicker
 *      - it also makes sure that the next product lobby is not rendered until we navigate to it
 *      - react-navigation reuses the `ProductTransitionScreen`, it doesn't re-render it
 * - set the product in the jurisdiction store
 * - we reset the navigation again in the following manner:
 *      - we put first the active product lobby screen
 *      - then we put this screen again with `shouldFadeOut` set to true
 *          - this also triggers the fade out animation
 * - when the fade out animation is finished, we navigate back to the previous screen, which is the active product lobby
 */
export const ProductTransitionScreen = () => {
    const { t } = useTranslation('kyc');

    const navigation = useNavigation();
    const {
        params: { product, shouldFadeOut },
    } = useRoute<ProductTransitionRouteProp>();
    const setProduct = useJurisdictionStore(state => state.actions.setProduct);

    const fadeInOpacity = useSharedValue(shouldFadeOut ? 1 : 0);

    const resetNavigationFlow = useCallback(() => {
        navigation.dispatch(state => {
            // remove all the screens except for ProductTransitionScreen
            // react-navigation reuses this screen since is already mounted
            // which doesn't trigger a re-render of the screen
            // meaning that after the fade-in animation
            // the reset lets the screen stay in the same state (the finished state of the fade-in animation)
            return CommonActions.reset({
                ...state,
                routes: [...state.routes.filter(r => r.name === 'ProductTransitionScreen')],
                index: 0,
            });
        });

        // set the product in the store
        setProduct(product);

        runAfterInteractions(() => {
            navigateToLobbyProductWithTransitionScreen(navigation, product);
        });
    }, [navigation, product, setProduct]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (!shouldFadeOut && fadeInOpacity.value === 0) {
            // start the fade in animation
            fadeInOpacity.value = withTiming(
                1,
                {
                    duration: FADE_IN_DURATION,
                    easing: Easing.linear,
                },
                () => {
                    // reset the navigation as explained above
                    runOnJS(resetNavigationFlow)();
                }
            );
        } else {
            // fade out animation if `shouldFadeOut` is true
            fadeInOpacity.value = withDelay(
                FADE_OUT_DELAY + NAVIGATION_DELAY,
                withTiming(
                    0,
                    {
                        duration: FADE_OUT_DURATION,
                        easing: Easing.linear,
                    },
                    () => {
                        // navigate back to the previous screen, which is the active product lobby
                        runOnJS(navigation.dispatch)({ type: 'GO_BACK' });
                    }
                )
            );
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [fadeInOpacity, navigation, product, resetNavigationFlow, setProduct, shouldFadeOut]);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            opacity: fadeInOpacity.value,
        };
    });

    return (
        <Box flex={1}>
            <Animated.View style={[styles.container, animatedStyle]} key="container">
                <Animated.View
                    key="text"
                    style={common.alignCenter}
                    entering={shouldFadeOut ? undefined : FadeInDown.duration(FADE_IN_DURATION).delay(100)}
                >
                    <Box maxWidth={MAX_WEB_WIDTH} width={'100%'}>
                        <Text variant={'bodyLarge'} marginBottom={'s12'}>
                            {t('welcome_to')}
                        </Text>
                        {product === Product.Sportsbook ? <WelcomeSportsbookIcon /> : <WelcomePicksIcon />}
                    </Box>
                </Animated.View>
            </Animated.View>
        </Box>
    );
};

const navigateToLobbyProductWithTransitionScreen = (
    navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>,
    product?: Product
) => {
    // reset active wallet whenever switching products, it will re-initialized when navigating to account screen
    useActiveWalletStore.getState().resetActiveWallet();
    const root = product === Product.Sportsbook ? 'SBKHome' : 'PickemHome';
    navigation.reset({
        index: 1,
        routes: [
            {
                name: root,
                params: { screen: 'Lobby' },
            },
            {
                name: 'ProductTransitionScreen' as const,
                params: {
                    shouldFadeOut: true,
                    product,
                },
            },
        ],
    });
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: designSystem.colors.gray8,
        paddingLeft: 24,
    },
});
