import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path d="M0 0L20 0V2H0L0 0Z" fill="white" />
        <Path d="M0 11H20L20 14H0L0 11Z" fill="white" />
        <Path d="M0 5H20V8H0L0 5Z" fill="white" />
        <Path d="M0 8H13V11H0L0 8Z" fill="white" />
        <Path d="M17 8H20L20 11H17V8Z" fill="white" />
    </Svg>
);

export default SvgComponent;
