import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { BetsScreen } from '@/feature/bets-sbk/screens/BetsScreen';

import { SBKBetsStackParamList } from './types';

const Stack = createStackNavigator<SBKBetsStackParamList>();

export const BetsStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="BetsScreen" component={BetsScreen} />
        </Stack.Navigator>
    );
};
