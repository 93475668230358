import React from 'react';
import { Image, Linking, StyleSheet, TouchableOpacity } from 'react-native';

import BetrLogo from '@/assets/icons/betr-small';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { GlobalConfigurationsDocumentDataBodyLaunchpromoSliceItem } from '@/types/prismic.generated';

const styles = StyleSheet.create({
    image: {
        flex: 1,
        width: '100%',
        height: '100%',
        marginTop: 24,
        resizeMode: 'contain',
    },
    launchPromoTitle: {
        fontSize: 36,
        lineHeight: 36,
        fontWeight: '800',
        textTransform: 'uppercase',
    },
});

type LaunchCarouselItemProps = {
    activeIndex: number;
    launchPromos: GlobalConfigurationsDocumentDataBodyLaunchpromoSliceItem[];
};

// TODO: There is an issue (web only) with react-native-reanimated-carousel on moving forward/backward slides with item passed.
// To circumvent this issue, we are passing the entire launchPromos array of promos.
// If needed likely we will need to look into a different library to handle the web launch carousel.
// https://github.com/dohooo/react-native-reanimated-carousel/issues/625
// https://github.com/dohooo/react-native-reanimated-carousel/issues/509
export const LaunchCarouselItem = ({ launchPromos, activeIndex }: LaunchCarouselItemProps) => {
    const activeIdx = activeIndex !== undefined ? activeIndex : 0;
    const isCentered = launchPromos?.[activeIdx]?.centered ?? true;
    const isCenterAligned = isCentered ? 'center' : undefined;
    const isHighlightedTitleExist = !!launchPromos?.[activeIdx].highlighted_title;

    return (
        <MaxWidthWrapper flex={1}>
            <Box alignItems={isCenterAligned} flex={1} my="s10">
                {launchPromos?.[activeIdx].promoimage?.url ? (
                    <Image
                        source={{
                            uri: launchPromos?.[activeIdx].promoimage?.url ?? undefined,
                        }}
                        style={styles.image}
                    />
                ) : (
                    <Box flex={1} />
                )}
                {!isCentered ? (
                    <Box px="s28">
                        <BetrLogo width={80} height={80} />
                    </Box>
                ) : null}
                {isHighlightedTitleExist ? (
                    <Text style={styles.launchPromoTitle} color="purple2" px="s28" mt="s42" textAlign={isCenterAligned}>
                        {launchPromos?.[activeIdx].highlighted_title}
                    </Text>
                ) : null}
                <Text
                    px="s28"
                    mt={isHighlightedTitleExist ? undefined : 's42'}
                    textAlign={isCenterAligned}
                    style={styles.launchPromoTitle}
                >
                    {launchPromos?.[activeIdx].title}
                </Text>
                <Text
                    variant="bodyMedium"
                    textAlign={isCenterAligned}
                    color={'gray2'}
                    my={isCentered ? 's10' : undefined}
                    px="s28"
                >
                    {launchPromos?.[activeIdx].body}
                </Text>
                <TouchableOpacity
                    onPress={() => {
                        const link = launchPromos?.[activeIdx].disclaimer_link;
                        if (link && 'url' in link) {
                            if (link.url) {
                                Linking.openURL(link.url);
                            }
                        }
                    }}
                >
                    <Text variant="labelLarge" color="purple2" px="s28" textAlign={isCenterAligned}>
                        {launchPromos?.[activeIdx].disclaimer_text}
                    </Text>
                </TouchableOpacity>
            </Box>
        </MaxWidthWrapper>
    );
};
