import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import WebView, { WebViewNavigation } from '@/components/Webview';
import { useDeposit } from '@/feature/deposit/hooks/use-deposit';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';
import { getWalletErrorCode } from '@/utils/get-wallet-error-code';

import { PaymentMethodTypes } from '../const';
import { useRejectDeposit } from '../hooks/use-reject-deposit';
import { PAYMENT_METHOD_NAME, PayPalStatus, PaymentProviderHandler, getPaymentProviderHandlers } from '../utils';

const styles = StyleSheet.create({
    loaderContainer: { backgroundColor: designSystem.colors.gray8 },
});

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'DepositMethodWebViewModal'>;

const handleMatch = (match: RegExpMatchArray | null, handler: PaymentProviderHandler) => {
    if (match && match[1]) {
        const currentStatus = match[1];
        if (handler.statuses.includes(currentStatus)) {
            handler.onSuccess(currentStatus);
        } else if (currentStatus === PayPalStatus.DEFAULT) {
            handler.onDefault();
        }
    }
};

export const DepositMethodWebView = ({ route }: ScreenProps) => {
    const {
        selectedAmount,
        selectedPaymentMethodType,
        currency = 'USD',
        payment_token = '',
        payment_type_id = '',
    } = route.params || {};
    const navigation = useNavigation();
    const { t } = useTranslation('transactions');

    usePreventAndroidBackNavigation();

    const paymentProviderHandlers = getPaymentProviderHandlers(navigation, selectedPaymentMethodType, selectedAmount);

    const handler = paymentProviderHandlers[selectedPaymentMethodType.split('_')[0]];

    const { mutate: deposit, data: depositResponse, isLoading } = useDeposit();

    const userWalletId = depositResponse?.extra.user_wallet_id;
    const { mutate: rejectDepositMutation } = useRejectDeposit();

    const webviewRef = useRef<WebView>(null);
    const handleNavigationChange = useCallback(
        () => (navState: WebViewNavigation) => {
            if (handler) {
                const match = navState?.url.match(handler.regex);
                handleMatch(match, handler);
            }
        },
        [handler]
    );

    useEffect(() => {
        deposit(
            {
                amount: selectedAmount,
                type: selectedPaymentMethodType,
                currency,
                payment_token,
                payment_type_id,
            },
            {
                onSuccess: data => {
                    if (selectedPaymentMethodType === PaymentMethodTypes.Paysafe) {
                        if (data.status === 'success') {
                            navigation.navigate('SuccessfulDepositModal', {
                                selectedAmount,
                                paymentProvider: selectedPaymentMethodType,
                            });
                        } else {
                            navigation.navigate('FailedDepositModal', {
                                selectedAmount,
                                paymentProvider: selectedPaymentMethodType,
                            });
                        }
                    }
                },
                onError: error => {
                    navigation.navigate('FailedDepositModal', {
                        selectedAmount,
                        paymentProvider: selectedPaymentMethodType,
                        errorCode: getWalletErrorCode(error),
                    });
                },
            }
        );
    }, [currency, deposit, navigation, payment_token, payment_type_id, selectedAmount, selectedPaymentMethodType]);

    const paymentMethodTypeName = PAYMENT_METHOD_NAME[selectedPaymentMethodType];

    if (isLoading) {
        return (
            <View style={[common.flex, styles.loaderContainer, common.justifyCenter]}>
                <LoadingSpinner />
            </View>
        );
    }

    if (!depositResponse) {
        return null;
    }
    if (isWeb && depositResponse.payment_url) {
        // if it's third party payment method, it is handled by the payment provider at their site.
        window.location.href = depositResponse.payment_url;
        return null;
    }

    return (
        <Screen>
            <ScreenNavBar
                title={t('deposit_with_payment_name', { paymentMethodTypeName })}
                closeIconMode="back"
                onClose={() => {
                    if (userWalletId) {
                        rejectDepositMutation(userWalletId);
                    }
                    navigation.goBack();
                }}
            />
            <WebView
                ref={webviewRef}
                source={{
                    uri: depositResponse.payment_url || '',
                }}
                onLoadStart={navState => {
                    if (handler) {
                        const match = navState.nativeEvent.url.match(handler.regex);
                        handleMatch(match, handler);
                    }
                }}
                onShouldStartLoadWithRequest={req => {
                    if (req.url.includes('paypal_default') && userWalletId) {
                        rejectDepositMutation(userWalletId);
                    }
                    return true;
                }}
                onNavigationStateChange={handleNavigationChange}
            />
        </Screen>
    );
};
