import React, { memo, useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import {
    PlayerInfoFragment,
    PlayerProjectionFragment,
    useEventInfoWithPlayersQuery,
} from '@/api/events/query.generated';
import { isTeamEvent } from '@/api/events/types/types';
import LossIcon from '@/assets/icons/lossBig';
import Void from '@/assets/icons/void';
import WonIcon from '@/assets/icons/wonBig';
import { TeamIcon } from '@/components/TeamIcon';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { leagueConfigSelector, useLeagueConfigsStore } from '@/feature/betslip-pickem/hooks/use-league-configs-store';
import { PickProjectionsIcons } from '@/feature/entries-pickem/components/PickProjectionsIcons';
import { ShareEntryPlayerProfileImage } from '@/feature/entry-share/components/ShareEntryPlayerProfileImage';
import { EventDetails, PlayerDescription } from '@/feature/lobby/components/PlayerDetails';
import { nonRegularProjectionTypes } from '@/feature/lobby/utils/filters';
import { common } from '@/styles/styles';
import { Outcome, Result } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { getPlayerArcDetails } from '@/utils/get-player-arc-details';
import { getColorByProjType } from '@/utils/getProjectionsTypes';
import { isCollegeSport } from '@/utils/league';
import { formatNumber } from '@/utils/numeric/format';

const styles = StyleSheet.create({
    coverTeamIcon: {
        position: 'absolute',
        left: -2,
        borderWidth: 2,
        borderRadius: 46,
    },
    arrowContainer: {
        marginLeft: -9,
    },
    container: {
        paddingLeft: 16,
        paddingVertical: 12,
    },
    pickProjectionsIcons: { marginRight: 6 },
});

type Props = {
    player: PlayerInfoFragment;
    outcome: Outcome;
    projection: PlayerProjectionFragment;
    teamId?: string;
    eventId: string;
    result?: Result;
    isLast: boolean;
    onLoadImage: () => void;
    onLoadEvent: () => void;
    currentValue?: number;
};

export const ShareEntryPlayerCard = memo(
    ({ player, outcome, projection, teamId, eventId, result, currentValue, onLoadImage, onLoadEvent }: Props) => {
        const isVoided = result === Result.Void;
        const [{ data, fetching }] = useEventInfoWithPlayersQuery({ variables: { id: eventId } });
        const event = data?.getEventByIdV2;
        const { leagueIcon, leagueColor } = useLeagueConfigsStore(
            leagueConfigSelector(event?.league),
            defaultZustandCompareFunction
        );
        const team = isTeamEvent(event) ? event.teams.find(t => t.id === teamId) : null;
        const { teamLogo, arcText } = getPlayerArcDetails(player, event?.league, team);
        const showTeamIcon = !!result; // when we have a result we show a result specific icon instead of the team icon
        const [loadedImages, setLoadedImages] = useState(Number(showTeamIcon));
        const icon = team?.icon ?? teamLogo ?? leagueIcon;

        const computedSpecialIncrease = projection?.nonRegularPercentage ? projection.nonRegularPercentage > 0 : null;

        const pickColor = isVoided
            ? 'gray4'
            : projection && getColorByProjType(projection.type, computedSpecialIncrease ?? false);
        const playerNumber = getPlayerJerseyNumber(event?.league, player?.jerseyNumber);

        useEffect(() => {
            // make sure we have the event
            !fetching && setLoadedImages(prevLoadedImages => prevLoadedImages + Number(!!arcText));
        }, [arcText, fetching]);

        useEffect(() => {
            // both player and team image to be loaded
            if (loadedImages === 2) {
                onLoadImage && onLoadImage();
            }
        }, [loadedImages, onLoadImage]);

        useEffect(() => {
            if (event) {
                onLoadEvent();
            }
        }, [event, onLoadEvent]);

        const onLoad = useCallback(() => {
            setLoadedImages(prevLoadedImages => prevLoadedImages + 1);
        }, []);

        return (
            <View style={[common.flex, common.spaceBetweenRow, common.row, styles.container]}>
                <ShareEntryPlayerProfileImage
                    playerImageUrl={player.icon}
                    onLoadImage={onLoad}
                    playerNumber={playerNumber}
                    isLoading={fetching}
                />
                <Box flex={1} justifyContent={'center'} ml={'s16'}>
                    <PlayerDescription player={player} teamName={team?.name} isVoided={isVoided} event={event} />
                    <EventDetails
                        event={event}
                        isVoided={isVoided}
                        player={player}
                        projectionStatus={{ currentValue, result }}
                    />
                </Box>
                <Box alignItems={'flex-end'}>
                    <Row alignItems={'center'}>
                        <PickProjectionsIcons
                            isVoided={isVoided}
                            projection={projection}
                            outcome={outcome}
                            containerStyles={styles.pickProjectionsIcons}
                            arrowStyles={styles.arrowContainer}
                        />
                        <Text variant="titleMedium" textAlign={'right'} color={pickColor}>
                            {`${formatNumber(
                                nonRegularProjectionTypes.includes(projection.type)
                                    ? projection.nonRegularValue
                                    : projection.value
                            )}`}
                        </Text>
                    </Row>
                    <Text variant="bodySmall" color={isVoided ? 'gray4' : 'gray2'} textAlign={'right'}>
                        {projection.label}
                    </Text>
                </Box>
                <View style={styles.coverTeamIcon}>
                    {isVoided ? (
                        <Void />
                    ) : result === Result.Win ? (
                        <WonIcon />
                    ) : result === Result.Loss ? (
                        <LossIcon />
                    ) : (
                        <TeamIcon
                            teamIcon={icon}
                            teamColor={team?.color ?? leagueColor}
                            text={arcText}
                            shouldDisplayTeamName={isCollegeSport(event?.league)}
                            onLoadImage={onLoad}
                        />
                    )}
                </View>
            </View>
        );
    }
);
