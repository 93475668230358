import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/ButtonComponent';
import { Box, Column, Row } from '@/components/lib/components';

import { useMarketLineSelection } from '../../hooks/use-market-line-selection';
import { Event, Market } from '../../types';
import { sortOptionsFavouriteOnTop } from '../../utils/sort-options';
import { OptionButton } from '../OptionButton';

type StackedStandardMarketProps = {
    markets: Array<Market>;
    event: Event;
    testID?: string;
};

export const StackedStandardMarket = ({ markets, event, testID }: StackedStandardMarketProps) => {
    const { hasAlternateMarkets, selectedMarket, showAlternateMarkets } = useMarketLineSelection({
        eventId: event?.id,
        markets,
        sortOptions: sortOptionsFavouriteOnTop,
    });

    const { t } = useTranslation(['common']);

    return (
        <Column flex={1} gap={'s16'}>
            <Box flex={1} gap="s8">
                {selectedMarket?.options
                    .sort((a, b) => sortOptionsFavouriteOnTop(a, b, selectedMarket?.market_type))
                    .map(option => {
                        return (
                            <Row key={option.id}>
                                {event ? (
                                    <OptionButton
                                        option={option}
                                        market={selectedMarket}
                                        event={event}
                                        testID={testID}
                                    />
                                ) : null}
                            </Row>
                        );
                    })}
            </Box>
            {hasAlternateMarkets ? (
                <Button
                    label={t('view_more')}
                    variant="roundedOutlined"
                    onPress={showAlternateMarkets}
                    testID={`viewMoreButton-${testID}`}
                />
            ) : null}
        </Column>
    );
};
