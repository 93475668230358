import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useIsGameplayOfflineForActiveProductWallet } from '@/hooks/use-active-product';
import { productNames } from '@/hooks/use-active-product-name';
import { useActiveWallet, useActiveWalletStore, useSwitchActiveWallet } from '@/hooks/use-active-wallet';
import { Product, getProductType, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { RootStackParamList } from '@/navigation/types';

export const DepositButton = () => {
    const switchActiveWallet = useSwitchActiveWallet();
    const { t } = useTranslation(['wallet', 'common', 'transactions']);
    const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
    const settings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);
    const { activeWalletProductName, activeWalletProduct } = useActiveWallet();

    const beforeYouDepositData = useJurisdictionStore(
        state => state.jurisdictionSettings?.globalSettings?.keyValuePairs?.BeforeYouDepositInfo
    );
    const beforeYouDepositWarning = beforeYouDepositData?.[activeWalletProduct] ?? '';

    const gameplayOfflineForActiveWalletProduct = useIsGameplayOfflineForActiveProductWallet();
    const alternativeGameplayOnlineProduct = useAlternativeGamplayOnlineProduct();

    const { showInfoSheet } = useAlerts();

    const handleOnPress = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.WALLET_DEPOSIT);
        if (settings?.deposit_enabled) {
            !gameplayOfflineForActiveWalletProduct || !beforeYouDepositWarning
                ? navigation.navigate('DepositScreen', { selectedAmount: '' })
                : showInfoSheet({
                      title: t('transactions:before_you_deposit'),
                      description: (
                          <ScrollView style={styles.sheet}>
                              <Text color={'gray2'} variant="bodyMedium">
                                  {beforeYouDepositWarning ?? ''}
                              </Text>
                          </ScrollView>
                      ),
                      buttonLabel: t('transactions:deposit_to_product', { product: activeWalletProductName }),
                      secondaryLabel: alternativeGameplayOnlineProduct
                          ? t('common:go_to_product', { product: productNames[alternativeGameplayOnlineProduct] })
                          : t('common:cancel'),
                      handlePress: () => {
                          navigation.navigate('DepositScreen', { selectedAmount: '' });
                      },
                      handleSecondaryPress: () => {
                          if (alternativeGameplayOnlineProduct) {
                              switchActiveWallet(alternativeGameplayOnlineProduct);
                              navigation.navigate('DepositScreen', { selectedAmount: '' });
                          }
                      },
                  });
        } else {
            showInfoSheet({
                title: t('wallet:deposits_unavailable'),
                description: t('wallet:unavailable_deposit'),
                buttonLabel: t('common:close'),
            });
        }
    };

    return (
        <Button
            type="active"
            variant="rounded"
            onPress={handleOnPress}
            label={t('transactions:deposit_to_product', { product: activeWalletProductName })}
        />
    );
};

/**
 * Hook returning the alternative Product that has gameplay online, where the user can deposit.
 * If there is no alternative Product in this state, or there are multiple alternative product,
 * this returns undefined as we don't know what Product we should recommend the user to deposit to.
 */
const useAlternativeGamplayOnlineProduct = (): Product | undefined => {
    const activeWalletProduct = useActiveWalletStore(state => state.activeWalletProduct);
    const usState = useJurisdictionStore(state => state.jurisdiction);
    const productsConfigs = useJurisdictionStore(state => state.jurisdictionSettings?.products);
    const otherProducts = productsConfigs?.filter(it => getProductType(it.uid) !== activeWalletProduct) ?? [];
    //Only suggest alternative product if there is one. When there are multiple alternative products
    //we can't decide which to suggest for user to deposit
    if (otherProducts.length === 1) {
        if (!otherProducts[0].gameplayOfflineStates.includes(usState ?? '')) {
            return getProductType(otherProducts[0].uid);
        }
    }

    return undefined;
};

const styles = StyleSheet.create({
    sheet: { maxHeight: 400 },
});
