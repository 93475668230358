import { EventStatus } from '@/feature/bets-sbk/hooks/types';

import { Sport } from '../types';

const sportToHudHeight: { [key: string]: number } = {
    Basketball: 96,
    'Ice Hockey': 96,
    Baseball: 116,
    'American Football': 116,
};

export const getSportHeight = (eventStatus: EventStatus, sport?: Sport) => {
    const sportName = sport?.name || '';

    const sportHeight = sportToHudHeight[sportName] || 96;

    return !eventStatus || ['NOTSTARTED', 'FINISHED', 'UNKNOWN'].includes(eventStatus) ? 96 : sportHeight;
};

/**
 * Helper function to get HUD's height dynamically based on the event sport type
 * and whether periods are available or not
 */
export const getHudHeight = (eventStatus: EventStatus, hasScores?: boolean, sport?: Sport, isPrimaryHud?: boolean) => {
    const sportHeight = getSportHeight(eventStatus, sport);
    const periodsHeight = hasScores && isPrimaryHud ? 104 : 0;

    return sportHeight + periodsHeight;
};
