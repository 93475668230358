import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { useNavigation } from '@react-navigation/native';

import BackArrow from '@/assets/icons/backArrow';
import CheckmarkLargeWhite from '@/assets/icons/checkmark-large-white';
import { Button } from '@/components/ButtonComponent';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { handleUserBlockedError } from '@/feature/authentication/utils';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { productNames } from '@/hooks/use-active-product-name';
import { useSwitchActiveWallet } from '@/hooks/use-active-wallet';
import { useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { Product, getProductType, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { useProductSwitchModal } from '@/hooks/use-product-switch';
import { RootStackParamList } from '@/navigation/types';
import { designSystem } from '@/styles/styles';
import { runAfterInteractions } from '@/utils/runAfterInteractions';

type LimitRoutes = Pick<RootStackParamList, 'DepositLimits' | 'EntryLimits' | 'MaxSingleEntryLimits'>;

type LimitOption = {
    label: string;
    navigateTo: keyof LimitRoutes;
    analyticsTag: string;
};

export const VerificationSuccess = (): React.ReactElement | null => {
    const { handleSwitchNavigation } = useProductSwitchModal();
    const switchActiveWallet = useSwitchActiveWallet();

    const activeProduct = useJurisdictionStore(state => state.product);
    const featureFlags = useJurisdictionStore(state => state.jurisdictionSettings?.globalSettings?.featureFlags);
    const { t } = useTranslation(['kyc', 'common', 'product_switch', 'transactions']);
    const navigation = useNavigation();
    const { showInfoSheet } = useAlerts();
    const { error, isFetched } = useAuthUserConfig();
    usePreventAndroidBackNavigation();

    useEffect(() => {
        if (isFetched && error) {
            handleUserBlockedError(error, navigation);
        }
    }, [isFetched, error, navigation]);

    const setProduct = useJurisdictionStore(state => state.actions.setProduct);

    const limitOptions: LimitOption[] = useMemo(
        () => [
            {
                label: t('set_deposit_limits'),
                navigateTo: 'DepositLimits',
                analyticsTag: 'deposit',
            },
            {
                label: t('set_wager_limits'),
                navigateTo: 'EntryLimits',
                analyticsTag: 'wager',
            },
            {
                label: t('set_max_wager_limits'),
                navigateTo: 'MaxSingleEntryLimits',
                analyticsTag: 'single_max',
            },
        ],
        [t]
    );

    const navigateToDeposit = useCallback(
        (product: Product) => {
            const rootScreen = product === Product.Sportsbook ? 'SBKHome' : 'PickemHome';
            navigation.reset({
                index: 0,
                routes: [
                    {
                        name: rootScreen,
                    },
                    {
                        name: 'DepositScreen',
                        params: {
                            selectedAmount: '',
                            shouldRedirectToLobby: true,
                            // Determines if only debit card deposits are allowed after KYC success
                            forceDepositWithDebitCard: featureFlags?.kyc_CTA_debit_only?.enabled,
                        },
                    },
                ],
            });
        },
        [navigation, featureFlags?.kyc_CTA_debit_only]
    );

    const warnBeforeDeposit = useCallback(
        (product: Product, beforeYouDepositWarning: string) => {
            showInfoSheet({
                title: t('kyc:before_you_deposit'),
                description: (
                    <ScrollView style={styles.sheet}>
                        <Text color={'gray2'} variant="bodyMedium">
                            {beforeYouDepositWarning ?? ''}
                        </Text>
                    </ScrollView>
                ),
                buttonLabel: t('transactions:deposit_to_product', {
                    product: productNames[product],
                }),
                secondaryLabel: t('common:cancel'),
                handlePress: () => {
                    runAfterInteractions(() => {
                        navigateToDeposit(product);
                    });
                },
            });
        },
        [navigateToDeposit, showInfoSheet, t]
    );

    const handleProductDeposit = useCallback(() => {
        handleSwitchNavigation({
            title: t('product_switch:select_wallet'),
            additionalDescription: t('product_switch:select_wallet_additional_description'),
            customCallback: product => {
                // we update the products in the store
                setProduct(product);
                switchActiveWallet(product);

                // we use `getState` function to read the "newly" updated state
                const { jurisdiction: usState, products, jurisdictionSettings } = useJurisdictionStore.getState();

                const config = products?.find(p => getProductType(p.uid) === product);
                const gameplayOfflineStates = config?.gameplayOfflineStates ?? [];

                const isGameplayOffline = gameplayOfflineStates.includes(usState ?? '');

                const beforeYouDepositData = jurisdictionSettings?.globalSettings?.keyValuePairs?.BeforeYouDepositInfo;

                const beforeYouDepositWarning = beforeYouDepositData?.[activeProduct] ?? '';

                // wait for the modal to be dismissed before navigating
                runAfterInteractions(() => {
                    if (isGameplayOffline && beforeYouDepositWarning) {
                        warnBeforeDeposit(product, beforeYouDepositWarning);
                    } else {
                        navigateToDeposit(product);
                    }
                });
            },
        });
    }, [
        activeProduct,
        handleSwitchNavigation,
        navigateToDeposit,
        setProduct,
        switchActiveWallet,
        t,
        warnBeforeDeposit,
    ]);

    const onMakeDepositPress = useCallback(() => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.KYC_SUCCESS_DEPOSIT);

        handleProductDeposit();
    }, [handleProductDeposit]);

    if (isFetched && error) {
        return null;
    }

    return (
        <Screen>
            <MaxWidthWrapper flex={1} paddingHorizontal={'s16'}>
                <Box flex={1} justifyContent="center" paddingHorizontal="s16">
                    <Box alignItems="center" pb="s20">
                        <CheckmarkLargeWhite />
                        <Text fontSize={22} pt="s16" fontWeight="600">
                            {t('verification_success_header')}
                        </Text>
                        <Box paddingHorizontal="s24" pt="s8">
                            <Text fontSize={15} color={'gray2'} textAlign={'center'}>
                                {t('verification_success_copy')}
                            </Text>
                        </Box>
                    </Box>
                    <Box gap="s12">
                        {limitOptions.map(option => (
                            <Banner
                                key={`option-${option.label}`}
                                message={option.label}
                                onPress={() => {
                                    BetrAnalytics.trackEvent(AnalyticsEvent.KYC_SUCCESS_LIMIT, {
                                        limitType: option.analyticsTag,
                                    });
                                    navigation.navigate(option.navigateTo);
                                }}
                                rightIcon={
                                    <BackArrow
                                        height={16}
                                        width={16}
                                        viewBox="0 0 25 25"
                                        marginLeft={12}
                                        style={styles.rightArrow}
                                    />
                                }
                            />
                        ))}
                    </Box>
                </Box>
                <Box position="relative" padding="s16">
                    <Button type="active" variant="rounded" label={t('make_deposit')} onPress={onMakeDepositPress} />
                    <SizedBox value={12} />
                    <Button
                        type="tertiary"
                        variant="default"
                        label={t('common:go_to_lobby')}
                        onPress={() => {
                            BetrAnalytics.trackProductEvent(AnalyticsEvent.KYC_SUCCESS_LOBBY);
                            handleSwitchNavigation({ title: t('product_switch:select_game_mode') });
                        }}
                    />
                </Box>
            </MaxWidthWrapper>
        </Screen>
    );
};

const styles = StyleSheet.create({
    limitsButton: {
        width: '100%',
        padding: 16,
        borderRadius: 10,
        backgroundColor: designSystem.colors.gray6,
    },
    rightArrow: { transform: [{ rotate: '-180deg' }] },
    sheet: { maxHeight: 400 },
});
