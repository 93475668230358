import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
        <Path
            fill={props.color ?? designSystem.colors.green}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.21408 10.5711L2.91424 7.27124L1.97144 8.21404L6.21408 12.4567L14.228 4.44281L13.2851 3.5L6.21408 10.5711Z"
        />
    </Svg>
);

export default SvgComponent;
