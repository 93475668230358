import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useValidateVerificationCode } from '@/feature/account-details/hooks/use-validate-verification-code';
import { INCORRECT_CODE, MAX_ATTEMPTS_REACHED_ERROR_CODE } from '@/feature/verify-phone-number/utils/constants';

import { ErrorResponse } from './use-request-verification-code';

/**
 * Custom hook for handling verification code submission and its state management.
 */
export const useVerificationCode = (onVerificationSuccess: () => void) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
    const [externalError, setExternalError] = useState<ErrorResponse | null>();
    const [errorMessage, setErrorMessage] = useState('');
    const [isResendRequested, setIsResendRequested] = useState(false);
    const { mutateAsync: validateCode, error: validateCodeError } = useValidateVerificationCode();
    const { t } = useTranslation(['account', 'common']);

    const handleVerificationCodeSubmit = useCallback(async () => {
        setIsSubmitting(true);
        setIsAlreadySubmitted(true);

        try {
            await validateCode(verificationCode);
            onVerificationSuccess();
            setErrorMessage('');
        } catch (e) {
            setErrorMessage(e instanceof Error ? e.message : t('an_unexpected_error_occurred'));
        } finally {
            setIsSubmitting(false);
        }
    }, [verificationCode, t, validateCode, onVerificationSuccess]);

    const processError = useCallback(
        (error: ErrorResponse) => {
            const errorCodes = error.responseData.error_codes || [];
            let message = error.message;

            if (errorCodes.includes(MAX_ATTEMPTS_REACHED_ERROR_CODE)) {
                message = t('error_max_attempts_reached');
            } else if (errorCodes.includes(INCORRECT_CODE)) {
                message = t('error_incorrect_code');
            }

            setErrorMessage(message);
        },
        [t]
    );

    const onInputChange = (text: string) => {
        if (text.trim().length !== 6) {
            setIsAlreadySubmitted(false); // Reset when the code length changes
        }
        setVerificationCode(text.trim().substring(0, 6));
    };

    useEffect(() => {
        if (verificationCode.length === 6 && !isAlreadySubmitted) {
            handleVerificationCodeSubmit();
        }
    }, [verificationCode, handleVerificationCodeSubmit, isAlreadySubmitted]);

    useEffect(() => {
        // If there's a validateCodeError, set and process it as an external error.
        if (validateCodeError) {
            const error = validateCodeError as ErrorResponse;
            processError(error);
            setExternalError(error);
        }
        // If externalError changes independently, process it as well.
        else if (externalError) {
            processError(externalError);
        }
    }, [validateCodeError, externalError, t, processError]);

    return {
        verificationCode,
        isSubmitting,
        errorMessage,
        setErrorMessage,
        setExternalError,
        isResendRequested,
        setIsResendRequested,
        onInputChange,
        handleVerificationCodeSubmit,
    };
};
