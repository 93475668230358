import { useMutation } from '@tanstack/react-query';

type ZipCodeResponse = {
    'post code': string;
    country: string;
    'country abbreviation': string;
    places: Array<{
        'place name': string;
        longitude: string;
        state: string;
        'state abbreviation': string;
        latitude: string;
    }>;
};

/**
 * Verifies if a given ZIP code exists using the Zippopotamus API.
 *
 * @param zip - The 5-digit US ZIP code to verify.
 * @returns A promise that resolves to a boolean indicating whether the ZIP code is valid.
 *
 */
const verifyZipCode = async (zip: string): Promise<boolean> => {
    try {
        // Verifying ZIP code
        const response = await fetch(`https://api.zippopotam.us/us/${zip}`);
        if (!response.ok) {
            // ZIP code is invalid (API response not OK)
            return false;
        }
        const data: ZipCodeResponse = await response.json();
        const isValid = data.places && data.places.length > 0;
        // ZIP code is ${isValid ? 'valid' : 'invalid'}
        return isValid;
    } catch (error) {
        // Error verifying ZIP code
        return false;
    }
};

/**
 * This hook utilizes the Zippopotamus API to check if a given ZIP code exists.
 * It's designed to work with US ZIP codes only and returns a mutation object with a boolean data value.
 *
 * @returns A mutation object where data is a boolean indicating if the ZIP code is valid, defaulting to false.
 */
export const useVerifyZipCode = () => {
    const mutation = useMutation<boolean, Error, string>({
        mutationFn: async (zip: string) => {
            return await verifyZipCode(zip);
        },
    });

    return {
        ...mutation,
        data: mutation.data ?? false,
    };
};
