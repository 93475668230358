import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <Path
            fill="#000"
            d="M4.167 14.167V8.333h1.666v5.834H4.167Zm5 0V8.333h1.666v5.834H9.167Zm-7.5 3.333v-1.667h16.666V17.5H1.667Zm12.5-3.333V8.333h1.666v5.834h-1.666Zm-12.5-7.5V5L10 .833 18.333 5v1.667H1.667Z"
        />
    </Svg>
);
export default SvgComponent;
