import React from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import ChevronRight from '@/assets/icons/chevronRight';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { common } from '@/styles/styles';

import { paymentMethodStyles } from '../styles';

type AddPaymentMethodProps = {
    icon: React.ReactNode;
    title: string;
    description: string;
    onPress: () => void;
    analyticsEvent?: AnalyticsEvent;
    tag?: React.ReactNode;
};

export const AddPaymentMethod = ({ icon, title, description, tag, onPress, analyticsEvent }: AddPaymentMethodProps) => {
    const handlePress = () => {
        if (analyticsEvent) {
            BetrAnalytics.trackProductEvent(analyticsEvent);
        }
        onPress();
    };

    return (
        <TouchableOpacity
            style={[
                paymentMethodStyles.listItem,
                paymentMethodStyles.lineItemBorder,
                common.row,
                common.alignCenter,
                common.justifySpaceBetween,
            ]}
            onPress={handlePress}
        >
            <View style={[common.row, common.alignCenter, common.justifyCenter]}>
                <View style={paymentMethodStyles.imageContainer}>
                    <Box style={paymentMethodStyles.icon}>{icon}</Box>
                </View>

                <View>
                    <Text fontSize={15}>{title}</Text>
                    <Text color="gray2" fontSize={13}>
                        {description}
                    </Text>
                </View>
            </View>
            <View style={paymentMethodStyles.footerContainer}>
                {tag}
                <ChevronRight />
            </View>
        </TouchableOpacity>
    );
};
