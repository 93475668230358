import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useEntryRules } from '@/feature/betslip-pickem/hooks/use-entry-rules';
import { triggerHapticFeedback } from '@/feature/entry-share/utils/haptic-feedback';
import { GameMode, GameType } from '@/types/api.generated';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { useBetslipActions } from '../hooks/use-betslip-actions';
import { useBetslipData } from '../hooks/use-betslip-data';
import { useBetslipStore } from '../hooks/use-betslip-store';
import { useEntryAmount } from '../hooks/use-entry-amount';
import { getWinningPicksAndToWin } from '../utils/betslip-utils';

const styles = StyleSheet.create({
    placeEntry: {
        paddingVertical: 10,
        borderRadius: 24,
        width: '100%',
    },
});

export const Footer = () => {
    const { t } = useTranslation(['betslip_pickem', 'common']);
    const validatingBetslip = useBetslipStore(state => state.validating);
    const validationData = useBetslipStore(state => state.validationData);
    const isSyncingPicks = useBetslipStore(state => state.isSyncingPicks);
    const { validPicks, selectedGameModes, dynamicMultipliers, dynamicBoostedMultiplier } = useBetslipData();
    const { syncPicks, isGameModeValid } = useBetslipActions();
    const { gameTypes } = useEntryRules();
    const isEntryValid = selectedGameModes.length > 0 && isGameModeValid(selectedGameModes[0]);
    const selectedMode = selectedGameModes[0];
    const { highestToWin } = getWinningPicksAndToWin(dynamicMultipliers.DYNAMIC, dynamicBoostedMultiplier);
    const toWin = selectedMode === GameMode.Perfect ? validationData.PERFECT?.betValidation?.toWin : highestToWin;
    const entryAmount = useEntryAmount(selectedMode);
    const navigation = useNavigation();
    const buttonDisabled = !isEntryValid || validatingBetslip || !validPicks || isSyncingPicks;
    const p2pGameType = gameTypes.includes(GameType.P2P);
    const description = p2pGameType ? `${t('common:add_entry_amount')}.` : t('choose_entry_type_and_amount');

    const goToSubmitEntry = useCallback(
        (amount: number) => {
            navigation.navigate('SubmitEntry', {
                amount: amount,
                onInvalidProjectionsFound: () => {
                    syncPicks({ expectError: true, promptIfOddsChanged: true });
                },
            });
        },
        [navigation, syncPicks]
    );

    const submitEntry = useCallback(() => {
        triggerHapticFeedback();
        if (p2pGameType) {
            BetrAnalytics.trackEvent(AnalyticsEvent.PLACE_ENTRY_P2P, { amount: entryAmount });
        } else {
            BetrAnalytics.trackEvent(AnalyticsEvent.PLACE_ENTRY, { amount: entryAmount });
        }
        goToSubmitEntry(Number(entryAmount));
    }, [entryAmount, goToSubmitEntry, p2pGameType]);

    return (
        <Box paddingVertical={'s16'} alignItems={'center'}>
            {validPicks ? (
                <Text variant={'bodySmall'} color={'gray2'} mb={'s16'}>
                    {isEntryValid
                        ? t('count_entry_to_win_up_to_amount', {
                              count: selectedGameModes.length,
                              amount: toLocaleCurrency(toWin),
                          })
                        : description}
                </Text>
            ) : null}
            <Button
                style={styles.placeEntry}
                variant={'rounded'}
                type={'active'}
                loading={validatingBetslip || isSyncingPicks}
                label={
                    !isEntryValid || !validPicks
                        ? t('place_entry')
                        : t('place_entry_amount', { amount: toLocaleCurrency(entryAmount) })
                }
                disabled={buttonDisabled}
                onPress={submitEntry}
                testID="placeEntryBtn"
            />
        </Box>
    );
};
