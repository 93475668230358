import { useEffect } from 'react';

import { PROD, getEnv } from '@/data';
import { activate, fetchAndActivate, fetchConfig, getAll, getRemoteConfig, getValue } from 'firebase/remote-config';

import { app } from '../analytics.web';

export const remoteConfig = getRemoteConfig(app);

export const useFetchAndActivateFirebaseRemoteConfigs = () => {
    useEffect(() => {
        fetchAndActivate(remoteConfig);
    }, []);
};

export const getAllRemoteConfigs = () => getAll(remoteConfig);

export const getRemoteConfigByKey = (key: string) => getValue(remoteConfig, key);

export const activateRemoteConfig = () => activate(remoteConfig);

export const refetchRemoteConfigs = () => {
    if (getEnv() !== PROD) {
        remoteConfig.settings.minimumFetchIntervalMillis = 3000;
    }
    return fetchConfig(remoteConfig);
};
