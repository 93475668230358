import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Rect width={24} height={24} fill={props.color ?? '#EDEFF3'} rx={6} />
        <G clipPath="url(#a)">
            <Path
                fill="#000"
                d="m16.454 10.09.796-1.75L19 7.546l-1.75-.795L16.454 5l-.795 1.75-1.75.795 1.75.796.795 1.75Zm-4.772.32-1.591-3.5-1.591 3.5L5 12l3.5 1.59 1.59 3.5 1.592-3.5 3.5-1.59-3.5-1.59Zm4.772 3.5-.795 1.75-1.75.794 1.75.796.795 1.75.796-1.75 1.75-.796-1.75-.795-.796-1.75Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M4 4h16v16H4z" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgComponent;
