import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { LineSeparator } from '@/components/LineSeparator';
import { RadioItem } from '@/components/RadioItem';
import { Text } from '@/components/TextComponent';
import { Column, Image } from '@/components/lib/components';
import { Box } from '@/components/lib/components/Box';
import { common, designSystem } from '@/styles/styles';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

export type SelectionItem = {
    id: string;
    label: string;
    imageUrl?: string;
    secondaryLabel?: string;
};

type ListItem = {
    label: string;
    secondaryLabel?: string;
    selected: boolean;
    imageUrl?: string;
    onPress: () => void;
    testID?: string;
};

export type SelectionSheetProps<T extends SelectionItem> = {
    title: string;
    items: T[];
    selection: T;
    onItemSelected: (item: T) => void;
};

const ItemSeparator = () => (
    <LineSeparator style={{ backgroundColor: designSystem.colors.gray5, ...common.hairlineHeight }} />
);

const ListItem = ({ label, selected, onPress, secondaryLabel, imageUrl, testID }: ListItem) => {
    return (
        <TouchableOpacity key={label} style={[common.spaceBetweenRow, styles.option]} onPress={onPress} testID={testID}>
            {imageUrl ? (
                <Box pr="s16">
                    <Image source={{ uri: imageUrl ?? '' }} style={styles.image} resizeMode={'contain'} />
                </Box>
            ) : null}
            <Column flex={1} paddingRight={'s16'}>
                <Text variant="bodyMedium">{label}</Text>
                {secondaryLabel ? (
                    <Text variant="labelLarge" color="gray2">
                        {secondaryLabel}
                    </Text>
                ) : null}
            </Column>
            <RadioItem selected={selected} />
        </TouchableOpacity>
    );
};

export function SelectionSheet<T extends SelectionItem>({
    title,
    items,
    selection,
    onItemSelected,
    modalRef,
}: SelectionSheetProps<T> & {
    modalRef: React.RefObject<BottomSheetModal>;
}) {
    const [userSelection, setUserSelection] = useState(selection);

    const onPressItem = (item: T) => {
        if (item !== selection) {
            setUserSelection(item);
            onItemSelected(item);
        }
        setTimeout(() => modalRef.current?.dismiss({ duration: 200 }), 200);
    };

    return (
        <Box style={styles.content}>
            <Text textAlign={'center'} style={styles.selectorTitle} variant="titleLarge" fontWeight={'700'}>
                {title}
            </Text>
            {items.map((item, idx) => {
                const isLast = idx + 1 === items.length;
                return (
                    <Column key={`${idx}-item`}>
                        <ListItem
                            key={item.id}
                            label={item.label}
                            secondaryLabel={item.secondaryLabel}
                            selected={item.id === userSelection.id}
                            imageUrl={item.imageUrl}
                            onPress={() => onPressItem(item)}
                            testID={`selectionSheetItem-${idx}`}
                        />
                        {isLast ? null : <ItemSeparator />}
                    </Column>
                );
            })}
        </Box>
    );
}

const styles = StyleSheet.create({
    content: {
        paddingHorizontal: 16,
        paddingBottom: 8,
        maxHeight: '100%',
    },
    selectorTitle: {
        paddingVertical: 12,
    },
    option: {
        paddingVertical: 16,
    },
    image: {
        width: 24,
        height: 24,
    },
});
