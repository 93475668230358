import React, { ReactNode } from 'react';
import { LayoutAnimation, LayoutAnimationConfig, StyleSheet, ViewStyle } from 'react-native';

import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { OneTimeTooltipGameTracker } from '@/hooks/one-time-tooltip';
import { OneTimeShowKeys } from '@/hooks/use-show-one-time';
import { designSystem } from '@/styles/styles';
import { FloatingModalConfig } from '@/utils/floatingModal/FloatingModalProvider';

import { Accordion } from '../Accordion';
import { GameTrackerButton } from '../GameTrackerButton';
import MergedLogos from '../MergedLogos';
import { Text } from '../TextComponent';
import { Box, Row } from '../lib/components';
import { DesignSystemColor } from '../lib/theme';
import { ScoreBoardGrid } from './ScoreBoard';
import { GridPeriodScore, TeamLogos, TeamsDetails } from './types';

export type ScoreBoardAccordionProps = {
    breakdown: GridPeriodScore[];
    // ! background of the entire container but also the shadow's color
    backgroundColor?: DesignSystemColor;
    finalScoreColumns?: GridPeriodScore[];
    isLive?: boolean;
    eventTitle: ReactNode | string;
    matchDetails?: string;
    // ! use this if you want to scroll to an active period (for live events)
    activePeriodIndex?: number;
    teamFallbackTextColors?: string[];
    shouldShowFallbackLogo?: boolean;
    isBetSettled?: boolean;
    accordionStyle?: ViewStyle;
    animateExplicitInitialExpansion?: boolean;
    gameTrackerConfig?: FloatingModalConfig;
    showGameTrackerTooltip?: boolean;
    screenName: OneTimeShowKeys;
} & TeamsDetails;

export const ScoreBoardAccordion = ({
    awayTeamIcon,
    awayTeamShortCode,
    homeTeamIcon,
    homeTeamShortCode,
    breakdown,
    backgroundColor = 'gray6',
    finalScoreColumns,
    isLive,
    eventTitle,
    awayTeamColor,
    homeTeamColor,
    matchDetails,
    activePeriodIndex,
    teamFallbackTextColors,
    shouldShowFallbackLogo = false,
    isBetSettled,
    accordionStyle,
    animateExplicitInitialExpansion = false,
    gameTrackerConfig,
    showGameTrackerTooltip,
    screenName,
}: ScoreBoardAccordionProps) => {
    const teamLogo: TeamLogos = [
        { icon: awayTeamIcon, color: awayTeamColor },
        { icon: homeTeamIcon, color: homeTeamColor },
    ];

    const isAccordianEnabled = !!breakdown?.length;
    const headerActiveOpacity = isLive && gameTrackerConfig && showGameTrackerTooltip ? 1 : undefined;

    const handleToggleExpanded = (nextIsExpanded: boolean) => {
        if (nextIsExpanded) {
            BetrAnalytics.trackProductEvent(AnalyticsEvent.SCOREBOARD_EXPAND);
        } else {
            BetrAnalytics.trackProductEvent(AnalyticsEvent.SCOREBOARD_COLLAPSE);
        }
        return nextIsExpanded;
    };

    return (
        <Box paddingVertical="s10" backgroundColor={backgroundColor} borderRadius="r10" width="100%">
            <Accordion
                enabled={isAccordianEnabled}
                initialExpanded={isLive || isBetSettled || animateExplicitInitialExpansion}
                accordionStyle={{ ...styles.accordion, ...accordionStyle }}
                headerStyle={styles.header}
                animateExplicitExpansion={animateExplicitInitialExpansion}
                contentStyle={styles.accordionContent}
                customLayoutAnimation={CUSTOM_LAYOUT_ANIMATION}
                onToggle={handleToggleExpanded}
                header={
                    <Row alignItems="center" justifyContent="space-between">
                        <Row alignItems="center">
                            <Box style={styles.mergedLogoContainer}>
                                <MergedLogos
                                    logos={teamLogo}
                                    borderColor={designSystem.colors[backgroundColor]}
                                    renderMode="shield"
                                    overlap={6}
                                    logoSizes={[16, 16]}
                                    backdropSizes={[28, 24]}
                                    imageShape="square"
                                    borderRadius={8}
                                    teamFallbackTextColors={teamFallbackTextColors}
                                    shouldShowFallbackLogo={shouldShowFallbackLogo}
                                />
                            </Box>
                            <Text
                                marginLeft="s12"
                                variant="bodySmall"
                                color={isLive ? 'gray1' : 'gray2'}
                                testID="betCardEventDetails"
                            >
                                {eventTitle}
                            </Text>
                        </Row>
                        {isLive && gameTrackerConfig ? (
                            <Box mr={isAccordianEnabled ? 's12' : 's0'}>
                                {showGameTrackerTooltip ? (
                                    <OneTimeTooltipGameTracker
                                      screenName={screenName}
                                        gameTrackerConfig={gameTrackerConfig}
                                        tooltipContentPosition={{ right: -14, top: 32 }}
                                    />
                                ) : (
                                    <GameTrackerButton config={gameTrackerConfig} />
                                )}
                            </Box>
                        ) : null}
                    </Row>
                }
                headerActiveOpacity={headerActiveOpacity}
                content={
                    <ScoreBoardGrid
                        backgroundColor={backgroundColor}
                        awayTeamIcon={awayTeamIcon}
                        homeTeamIcon={homeTeamIcon}
                        awayTeamShortCode={awayTeamShortCode}
                        homeTeamShortCode={homeTeamShortCode}
                        breakdown={breakdown}
                        finalScoreColumns={finalScoreColumns}
                        matchDetails={matchDetails}
                        activePeriodIndex={activePeriodIndex}
                        variant="small"
                    />
                }
            />
        </Box>
    );
};

const styles = StyleSheet.create({
    teamLogo: {
        marginRight: 8,
    },
    accordion: {
        backgroundColor: designSystem.colors.gray6,
    },
    header: {
        flexWrap: 'wrap',
        paddingHorizontal: 12,
        paddingVertical: 0,
        alignItems: 'center',
    },
    accordionContent: {
        paddingTop: 12,
        paddingBottom: 0,
        zIndex: designSystem.zIndex.zIndexNegative1,
    },
    mergedLogoContainer: {
        marginLeft: -2,
    },
});

const CUSTOM_LAYOUT_ANIMATION: LayoutAnimationConfig = {
    ...LayoutAnimation.Presets.easeInEaseOut,
    create: { type: 'easeInEaseOut', property: 'opacity', delay: 200, duration: 200 },
    delete: { type: 'easeInEaseOut', property: 'opacity', duration: 200 },
    update: { type: 'easeInEaseOut', property: 'scaleXY', delay: 50, duration: 300 },
};
