import { EventInfoWithoutPlayers, isTeamEvent } from '@/api/events/types/types';
import { isUFC } from '@/utils/league';

import { localeCompareCollator } from './collator';

export const sortEventsByDate = (firstEvent: EventInfoWithoutPlayers, secondEvent: EventInfoWithoutPlayers): number => {
    const firstEventDate = firstEvent.date;
    const secondEventDate = secondEvent.date;
    return localeCompareCollator.compare(firstEventDate, secondEventDate);
};

export const sortEventsByName = (firstEvent: EventInfoWithoutPlayers, secondEvent: EventInfoWithoutPlayers) => {
    if (isTeamEvent(firstEvent) && isTeamEvent(secondEvent) && !isUFC(firstEvent.league)) {
        const firstAwayName = firstEvent.teams[0].name;
        const secondAwayName = secondEvent.teams[0].name;
        return localeCompareCollator.compare(firstAwayName, secondAwayName);
    }
    return 0;
};
export const sortEvents = (firstEvent: EventInfoWithoutPlayers, secondEvent: EventInfoWithoutPlayers): number => {
    if (isUFC(firstEvent.league)) {
        return 0;
    }
    return sortEventsByDate(firstEvent, secondEvent) || sortEventsByName(firstEvent, secondEvent);
};
