import React, { useCallback } from 'react';

import { useIsFocused } from '@react-navigation/native';

import { TAB_HEIGHT } from '@/components/TopTabBar';
import { Box } from '@/components/lib/components';
import { LoadingBox } from '@/feature/event-details-sbk/components/LoadingBox';
import { Event, MarketCategoryType } from '@/feature/event-details-sbk/types';
import { LeagueMicroMarketLayout } from '@/feature/league-hub-sbk/components/market-layouts/LeagueMicroMarketLayout';

import { useLeagueMarketCategoryData } from '../hooks/use-league-market-category-data';
import { useSectionHeights } from '../hooks/use-section-heights';
import { useSubscribeLeagueOddsUpdate } from '../hooks/use-subscribe-league-odds-update';
import { LeagueEventListMarketLayout } from './market-layouts/LeagueEventListMarketLayout';
import { LeaguePlayerPropLayout } from './market-layouts/LeaguePlayerPropLayout';

type LeagueMarketCategoryContentProps = {
    leagueId: string;
    marketCategoryId: string;
    marketCategoryType: MarketCategoryType;
    events: Event[];
    tabKey: string;
    isTabBarVisible: boolean;
};

export const LeagueMarketCategoryContent = ({
    leagueId,
    marketCategoryId,
    marketCategoryType,
    events,
    tabKey,
    isTabBarVisible,
}: LeagueMarketCategoryContentProps) => {
    const isFocused = useIsFocused();
    useSubscribeLeagueOddsUpdate(leagueId, marketCategoryId, isFocused);

    const { isLoading, sportName, marketCategories, refetch } = useLeagueMarketCategoryData(
        leagueId,
        marketCategoryId,
        isFocused
    );
    const { gameSectionHeight } = useSectionHeights(leagueId);

    const renderContent = useCallback(() => {
        switch (marketCategoryType) {
            case 'player_props':
                return (
                    <LeaguePlayerPropLayout
                        sportName={sportName}
                        marketCategories={marketCategories}
                        leagueId={leagueId}
                        refetch={refetch}
                        tabKey={tabKey}
                        isTabBarVisible={isTabBarVisible}
                    />
                );
            case 'play_by_play':
            case 'pitch_by_pitch':
            case 'plate_appearance':
            case 'drive':
                return (
                    <LeagueMicroMarketLayout
                        marketCategoryId={marketCategoryId}
                        events={events}
                        sportName={sportName}
                        marketCategories={marketCategories}
                        leagueId={leagueId}
                        refetch={refetch}
                        tabKey={tabKey}
                        isTabBarVisible={isTabBarVisible}
                    />
                );
            default:
                return (
                    <LeagueEventListMarketLayout
                        events={events}
                        leagueId={leagueId}
                        sportName={sportName}
                        marketCategories={marketCategories}
                        refetch={refetch}
                        tabKey={tabKey}
                        isTabBarVisible={isTabBarVisible}
                    />
                );
        }
    }, [
        events,
        isTabBarVisible,
        leagueId,
        marketCategories,
        marketCategoryId,
        marketCategoryType,
        refetch,
        sportName,
        tabKey,
    ]);

    if (isLoading) {
        return <LoadingBox style={{ paddingTop: gameSectionHeight + TAB_HEIGHT }} />;
    }

    return <Box zIndex={-1}>{renderContent()}</Box>;
};
