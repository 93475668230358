import React from 'react';
import { useTranslation } from 'react-i18next';

import { LineSeparator } from '@/components/LineSeparator';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { common, designSystem } from '@/styles/styles';

type P2pMaxEntryAmountExceededProps = {
    placedEntryAmount: number;
    creditedBack: number;
};

export const P2pMaxEntryAmountExceeded = ({ placedEntryAmount, creditedBack }: P2pMaxEntryAmountExceededProps) => {
    const { t } = useTranslation('p2p');

    return (
        <Box>
            <Text
                color={'gray2'}
                textAlign={'center'}
                variant="bodyMedium"
                paddingBottom={'s12'}
                paddingHorizontal={'s8'}
            >
                {t('max_entry_amount_exceeded_description', { placedEntryAmount, creditedBack })}
            </Text>
            <Box pt="s0">
                <Row py="s16" justifyContent="space-between">
                    <Text variant="bodyMedium">{t('placed_entry_amount')}</Text>
                    <Text variant="bodyMedium" color={'white'}>
                        ${placedEntryAmount}
                    </Text>
                </Row>
                <LineSeparator style={{ backgroundColor: designSystem.colors.gray5, ...common.hairlineHeight }} />
                <Row py="s16" justifyContent="space-between">
                    <Text variant="bodyMedium">{t('credited_back')}</Text>
                    <Text variant="bodyMedium" color={'white'}>
                        ${creditedBack}
                    </Text>
                </Row>
            </Box>
            <LineSeparator style={{ backgroundColor: designSystem.colors.gray5, ...common.hairlineHeight }} />
        </Box>
    );
};
