import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { DEFAULT_QUICK_AMOUNTS, useUserQuickAmounts } from './use-user-quick-amounts-store';

export const useQuickAmounts = () => {
    const savedQuickAmounts = useUserQuickAmounts(state => state.quickAmounts);
    const configuredQuickAmounts = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.keyValuePairs?.['Quick Amounts']
    );

    const quickButtons = configuredQuickAmounts
        ? Object.keys(configuredQuickAmounts).map(key => ({
              id: parseInt(key, 10),
              value: parseInt(configuredQuickAmounts[key], 10),
          }))
        : DEFAULT_QUICK_AMOUNTS;

    return savedQuickAmounts.length > 0 ? savedQuickAmounts : quickButtons;
};
