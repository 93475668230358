import { useCallback } from 'react';

import { activeLimitsSelector, useUserLimits } from '@/feature/responsible-gaming/hooks/use-user-limits';
import { useWallet } from '@/hooks/use-wallet';

import { validateStakeInput } from '../utils/validators';
import { useSbkBetSlipStore } from './use-sbk-betslip-store';

/**
 * Returns a function that validates stake input and updates betslip state with the stake input errors
 */
export const useStakeInputValidation = () => {
    const { data: limits = [] } = useUserLimits({ select: activeLimitsSelector('SINGLE_WAGER') });
    const updateInputStakeError = useSbkBetSlipStore(state => state.actions.updateStakeInputErrors);
    const { realMoneyTotal: walletCashBalance, betrBucks: walletBetrBucksBalance } = useWallet();

    return useCallback(
        (betId: string, betStake: number, isBetrBucks: boolean) => {
            if (betStake === 0) {
                updateInputStakeError(betId, undefined);
            } else {
                const error = validateStakeInput({
                    limits,
                    betStake,
                    walletCashBalance,
                    walletBetrBucksBalance,
                    isBetrBucks,
                });
                updateInputStakeError(betId, error);
            }
        },
        [limits, updateInputStakeError, walletBetrBucksBalance, walletCashBalance]
    );
};
