import { useCallback, useState } from 'react';

import { TransactionDateOptionsType, TransactionTypesType } from './types';

export type TransactionFilterCategory = 'date' | 'type';

export type TransactionFilterValue<T extends TransactionFilterCategory> = T extends 'date'
    ? TransactionDateOptionsType
    : T extends 'type'
    ? TransactionTypesType
    : never;

export type UseTransactionFilter = {
    filterOptions: {
        type?: TransactionTypesType;
        date?: TransactionDateOptionsType;
    };
    setFilter: (
        filterCategory: TransactionFilterCategory,
        item: TransactionFilterValue<TransactionFilterCategory>
    ) => void;
};

/**
 *
 * Custom hook that provides a way to manage and apply filters to transactions based on their filter options (`type` or `date`).
 *
 * @returns An object containing the current filter options and a function to update these filters.
 *
 */
export const useFilterTransaction = (): UseTransactionFilter => {
    const [type, setType] = useState<TransactionTypesType | undefined>(undefined);
    const [date, setDate] = useState<TransactionDateOptionsType | undefined>(undefined);

    const setFilter = useCallback(
        (filterCategory: TransactionFilterCategory, item: TransactionFilterValue<TransactionFilterCategory>) => {
            if (filterCategory === 'date') {
                setDate(item as TransactionDateOptionsType);
            } else {
                setType(item as TransactionTypesType);
            }
        },
        []
    );

    return {
        filterOptions: {
            date,
            type,
        },
        setFilter,
    };
};
