import { CONSTANTS } from '@/data';

import { initializePaysafe } from './formScript';
import { css } from './styles';

export type DebitCardAdditionFormHTMLProps = {
    productName: string;
    selectedAmount: number;
    username: string;
    pubKey: string;
    paysafeAccountId: number;
    paysafeEnv: string;
    paysafeSdk: string;
    userInfo: string;
    badBinThreshold: number;
};

export const DebitCardAdditionFormHTML = (params: DebitCardAdditionFormHTMLProps) => /* HTML */ `<html>
        <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0">
            <link href="https://fonts.cdnfonts.com/css/sf-pro-display" rel="stylesheet">
            </link>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.0/jquery.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.15.0/jquery.validate.min.js"></script>
            <script src="https://maps.googleapis.com/maps/api/js?key=${
                CONSTANTS.GOOGLE_PLACES_API_KEY
            }&libraries=places"></script>
            <style>
              ${css}
            </style>
        </head>

            <body>
                <div class="container">
                    <form role="form" id="payment-form" method="POST" action="javascript:void(0);" class="flexContainer form">

                        <div class="cardInfo">
                            <div class="sectionHeaderWrapper"><span class="sectionHeader">Card info</span></div>

                            <div class="cardNumber inputWrapper">
                                <div class="inputContainer">
                                    <div class="inputField">
                                        <div id="cardNumber" class="iframeInput"></div>
                                    </div>
                                    <span class="label paysafeLabel">Card Number</span>
                                </div>
                                <div class="errorMessage"></div>
                            </div>

                            <div class="holderName inputWrapper">
                                <div class="inputContainer">
                                    <input id="holderName" class="inputField htmlInput" required onkeypress="return handleAlphanumericKeyPress(event)" onpaste="return handleAlphanumericPaste(event)">
                                    <span class="label htmlLabel">Name on card</span>
                                </div>
                                <div class="errorMessage"></div>
                            </div>

                            <div class="flexContainer inputWrapper">
                                <div class="expiryDate">
                                    <div class="inputContainer">
                                        <div class="expiryDate inputField">
                                            <div id="expiryDate" class="iframeInput"></div>
                                        </div>
                                        <span class="label paysafeLabel">Exp. date</span>
                                    </div>
                                    <div class="errorMessage"></div>
                                </div>

                                <div class="cvv">
                                    <div class="inputContainer">
                                        <div class="cvv inputField">
                                            <div id="cvv" class="iframeInput"></div>
                                        </div>
                                        <span class="label paysafeLabel">CVV</span>
                                    </div>
                                    <div class="errorMessage"></div>
                                </div>
                            </div>
                        </div>

                        <div class="sectionHeaderWrapper"><span class="sectionHeader">Billing address</span></div>

                        <div class="street inputWrapper">
                            <div class="inputContainer">
                                <input id="street" class="inputField htmlInput" required onkeypress="return handleAlphanumericKeyPress(event)" onpaste="return handleAlphanumericPaste(event)">
                                <label for="street" class="label htmlLabel">Address</label>
                                <ul id="predictions" class="predictionsDropdown"></ul>
                            </div>
                            <div class="errorMessage"></div>
                        </div>

                        <div class="flexContainer inputWrapper">
                            <div class="state">
                                <div class="inputContainer">
                                    <input id="state" class="inputField htmlInput" required>
                                    <span class="label htmlLabel">State</span>
                                </div>
                                <div class="errorMessage"></div>
                            </div>

                            <div class="zip">
                                <div class="inputContainer">
                                    <input id="zip" class="inputField htmlInput" inputmode="numeric" maxlength="5" required>
                                    <span class="label htmlLabel">Zip code</span>
                                </div>
                                <div class="errorMessage"></div>
                            </div>
                        </div>

                        <div class="billing-warning">
                            <p>Apartment or unit numbers not required.</p>
                        </div>

                        <div class="btnContainer">
                            <div class="errorMessage general"></div>
                            <button id="deposit" type="button"></button>
                        </div>
                    </form>
                </div>
            </body>

            <script type="text/javascript">
                ${initializePaysafe(params)}
            </script>
    </html>`;
