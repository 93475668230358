import React from 'react';
import { StyleSheet, View } from 'react-native';

import { LoadingDots } from '@/components/LoadingDots';
import { common, designSystem } from '@/styles/styles';

import { SizedBox } from './SizedBox';

const styles = StyleSheet.create({
    dot: {
        backgroundColor: designSystem.colors.white,
        width: 8,
        height: 8,
        borderRadius: 8,
    },
});

export const Loading = () => {
    return (
        <View style={[common.row, common.justifyCenter]}>
            <LoadingDots
                size={8}
                bounceHeight={4}
                components={[
                    <View style={[common.row]}>
                        <View style={styles.dot} />
                        <SizedBox value={4} />
                    </View>,
                    <View style={[common.row]}>
                        <View style={styles.dot} />
                        <SizedBox value={4} />
                    </View>,
                    <View style={[common.row]}>
                        <View style={styles.dot} />
                    </View>,
                ]}
            />
        </View>
    );
};
