import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { SelectionParam } from '@/feature/betslip-sbk/types';

export const GASbkEvents = {
    // View events
    viewLobby: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.VIEW_LOBBY);
    },
    viewBetConfirmation: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.VIEW_BET_CONFIRMATION);
    },
    // Click events
    clickLeague: (leagueId: string, leagueName: string) => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CLICK_LEAGUE, {
            league_id: leagueId,
            league_name: leagueName,
        });
    },
    clickEvent: (eventId?: string) => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CLICK_EVENT, {
            event_id: eventId,
        });
    },
    addSelection: (marketId: string, optionId: string, selectionNumber: number = 1) => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.ADD_SELECTION, {
            side_bet_id: marketId,
            side_bet_option_id: optionId,
            selection_number: selectionNumber,
        });
    },
    addFeaturedBet: (selections: SelectionParam[], selectionNumber: number = selections.length) => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.ADD_PREBUILT_BETS, {
            selections: selections.map(selection => ({
                side_bet_id: selection.market.id,
                side_bet_option_id: selection.option.id,
            })),
            selection_number: selectionNumber,
        });
    },
    clickViewBets: (numberOfSelections: number) => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CLICK_VIEW_BETS, {
            num_selections_before_open: numberOfSelections,
        });
    },
    clickPlaceBet: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CLICK_PLACE_BET);
    },
    clickBetSlipCloseButton: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CLICK_BETSLIP_CLOSE_BUTTON);
    },
    toggleBetTypeTab: (tabType: 'singles' | 'combo') => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.TOGGLE_BET_TYPE_TAB, { bet_type: tabType });
    },
    toggleSelection: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.TOGGLE_SELECTION);
    },
    clickWagerInput: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CLICK_WAGER_INPUT);
    },
    clickPayoutSection: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CLICK_PAYOUT_SECTION);
    },
    toggleCurrency: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.TOGGLE_CURRENCY);
    },
    clearBetSlip: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CLEAR_BET_SLIP);
    },
    removeSelection: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.DELETE_INDIVIDUAL_SELECTION);
    },
    clickBalanceDropdown: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CLICK_BALANCE_DROPDOWN);
    },
    clickBetSummary: () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CLICK_BET_SUMMARY);
    },
    reviewMultiplierChanges: (action: 'accept_multipliers' | 'review_changes_first' | 'toggle_on') => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.REVIEW_MULTIPLIER_CHANGES, { action });
    },
    // Session completion events
    betSlipInteractionSummary: (numBetsCreated: number, numBetsPlaced?: number, dropOffStage?: string) => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.BETSLIP_INTERACTION_SUMMARY, {
            num_bets_created: numBetsCreated,
            num_bets_placed: numBetsPlaced,
            drop_off_stage: dropOffStage,
        });
    },
};
