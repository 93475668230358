import React from 'react';

import { AnimatedMicroMarketDescription } from '@/components/AnimatedMicroMarketDescription';
import { Box } from '@/components/lib/components';
import { MarketLayouts } from '@/feature/event-details-sbk/components/market-layouts';
import { useIsMarketPublished, useMarketStatus } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { generateUniqueMicroMarketId, useMicroMarket } from '@/feature/event-details-sbk/hooks/use-micro-market-cache';
import { Event, Market } from '@/feature/event-details-sbk/types';
import { EventInfo } from '@/feature/league-hub-sbk/components/EventInfo';

type MicroMarketEventCardProps = {
    event: Event;
    market: Market;
};

export const MicroMarketEventCard = ({ event, market }: MicroMarketEventCardProps) => {
    const microMarketId = generateUniqueMicroMarketId(market);
    const isMarketPublished = useIsMarketPublished(market.id) ?? market.published;
    const marketStatus = useMarketStatus(market.id);
    const cachedMicroMarket = useMicroMarket(microMarketId);
    const microMarket = cachedMicroMarket ?? market;
    const showDescription = marketStatus !== 'SUSPENDED' && isMarketPublished;

    if (!microMarket) {
        return null;
    }
    return (
        <>
            <Box my="s16">
                <EventInfo event={event} />
            </Box>
            <AnimatedMicroMarketDescription
                event={event}
                marketType={microMarket.market_type}
                showDescription={showDescription}
                prependTeamName
            />
            <Box mt="s6">
                <MarketLayouts {...{ event, markets: [microMarket], layout: microMarket.layout }} />
            </Box>
        </>
    );
};
