import { useCallback, useState } from 'react';

import { URLS } from '@/data';
import { BetslipRefferer } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { user } from '@/hooks/use-user';
import { logger } from '@/utils/logging';

const LOG_TAG = '[LinkShare]';

const SHORT_ENTRY_LINK = `${URLS.APP_LINKS_URL}/e/`;
const DEEP_LINK = 'betrpickem://entry/';
const DEEP_LINK_PATH = 'entry/';

export type SocialLink = {
    payload: { entryId: string };
    type: 'fantasy' | 'sbk';
    user_id: string;
    user_email: string;
    meta?: {
        title?: string;
        desc?: string;
    };
};

export type CreateLinkResponse = {
    id: string;
    shortUrl: string;
};

export const isSocialShareLink = (url: string | null) => {
    if (url?.startsWith(SHORT_ENTRY_LINK)) {
        return true;
    } else if (url?.startsWith(DEEP_LINK)) {
        return true;
    } else if (url?.startsWith(DEEP_LINK_PATH)) {
        return true;
    }
    return false;
};

/**
 * Extracts the shortID from the given URL.
 * The URLs can be deeplink (betrpickem://...) or universal links (l.betr.app/...) and the short id will be the last on the path
 */
export const extractShortIdFromURL = (url: string | null): string | undefined => {
    if (url?.startsWith(DEEP_LINK) || url?.startsWith('entry/')) {
        const fullId = url.split('/').pop();
        return fullId;
    } else if (url?.startsWith(SHORT_ENTRY_LINK)) {
        const shortId = new URL(url).pathname.split('/').pop();
        return shortId;
    }
    return undefined;
};

export const useSocialLink = () => {
    const [creatingSocialLink, setCreatingSocialLink] = useState<boolean>(false);
    const [importingSocialLink, setImportingSocialLink] = useState<boolean>(false);
    const importSocialLink = useCallback(async (shortId: string): Promise<SocialLink | undefined> => {
        setImportingSocialLink(true);
        try {
            const response = await fetch(`${URLS.APP_LINKS_URL}/api/link/${shortId}`, {
                headers: { Authorization: user.session?.access_token ?? '' },
            });
            const entryData = await response.json();
            if (response.ok) {
                return entryData;
            } else {
                logger.warn(LOG_TAG, 'Can not fetch shortlink data.', shortId);
                throw Error('Can not fetch shortLink data!');
            }
        } catch (e) {
            throw e;
        } finally {
            setImportingSocialLink(false);
        }
    }, []);

    const createSocialLink = useCallback(async (link: SocialLink) => {
        setCreatingSocialLink(true);
        try {
            const createLinkResponse: CreateLinkResponse = await (
                await fetch(`${URLS.APP_LINKS_URL}/api/link`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.session?.access_token ?? '',
                    },
                    body: JSON.stringify(link),
                })
            ).json();
            return createLinkResponse;
        } catch (e) {
            throw e;
        } finally {
            setCreatingSocialLink(false);
        }
    }, []);

    const uploadImageMetaToLink = useCallback(async (linkShortId: string, fileUri: string) => {
        try {
            const formData = new FormData();
            formData.append('file', {
                name: `${linkShortId}.jpg`,
                uri: fileUri,
                type: 'image/jpg',
            });
            const response = await fetch(`${URLS.APP_LINKS_URL}/api/link/upload?refId=${linkShortId}`, {
                method: 'POST',
                headers: {
                    Authorization: user.session?.access_token ?? '',
                },
                body: formData,
            });
            const data = await response.json();
            return data?.url;
        } catch (e) {
            logger.warn('Upload Image', e);
        }
    }, []);

    const markLinkConversion = useCallback(
        ({ refferer, amount, entryId }: { refferer: BetslipRefferer; amount: number; entryId: string }) => {
            const body = {
                entry_id: refferer.entryId,
                user_id: user.profile.sub,
                user_email: user.profile.email,
                link_id: refferer.linkId,
                conversion_entry_id: entryId,
                wager: amount,
            };
            fetch(`${URLS.APP_LINKS_URL}/api/link/conversion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.session?.access_token ?? '',
                },
                body: JSON.stringify(body),
            });
        },
        []
    );

    return {
        importSocialLink,
        createSocialLink,
        uploadImageMetaToLink,
        markLinkConversion,
        creatingSocialLink,
        importingSocialLink,
    };
};
