// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("playerMask.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".playerImage{\n  -webkit-mask-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  mask-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  mask-size: cover;\n  object-fit: cover;\n}", "",{"version":3,"sources":["webpack://./src/components/player-profile/playerImage.css"],"names":[],"mappings":"AAAA;EACE,2DAAuC;EACvC,mDAA+B;EAC/B,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".playerImage{\n  -webkit-mask-image: url(playerMask.svg);\n  mask-image: url(playerMask.svg);\n  mask-size: cover;\n  object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
