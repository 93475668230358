import { useCallback, useEffect, useState } from 'react';
import { AppState, Platform } from 'react-native';
import { PERMISSIONS, RESULTS, check } from 'react-native-permissions';

import BetrAnalytics from '@/feature/analytics/analytics';
import { getAndroidIsAdTrackingEnabled } from '@/utils/advertising';

/**
 * Hook that checks if the app has tracking enabled for App Tracking Transparency (ATT).
 */
export const useTrackingEnabled = () => {
    const [isTargetedAdvertisingEnabled, setIsTargetedAdvertisingEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const checkPermissions = useCallback(async () => {
        let isPermissionGranted = false;

        // TODO: Add account level check from the API as well here in || (OR) condition to both iOS and Android
        // Account level check will be handling multiple devices with multiple consent
        if (Platform.OS === 'android') {
            isPermissionGranted = await getAndroidIsAdTrackingEnabled();
        } else if (Platform.OS === 'ios') {
            const appTrackingTransparencyPermission = await check(PERMISSIONS.IOS.APP_TRACKING_TRANSPARENCY);
            isPermissionGranted = appTrackingTransparencyPermission === RESULTS.GRANTED;
        }

        setIsTargetedAdvertisingEnabled(isPermissionGranted);
        BetrAnalytics.setAnalyticsConsent(isPermissionGranted);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        const subscription = AppState.addEventListener('change', nextAppState => {
            if (nextAppState === 'active') {
                checkPermissions();
            }
        });

        checkPermissions();

        return () => {
            subscription.remove();
        };
    }, [checkPermissions]);

    return { isEnabled: isTargetedAdvertisingEnabled, isLoading };
};
