import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={22} fill="none" {...props}>
        <G clipPath="url(#clip0_4655_2594)">
            <Path
                fill="#253B80"
                d="M5.32841 21.3796L5.71194 18.9435L4.85761 18.9237H0.778076L3.61314 0.947468C3.62194 0.893201 3.65054 0.842601 3.69234 0.806668C3.73414 0.770735 3.78768 0.750935 3.84341 0.750935H10.7221C13.0057 0.750935 14.5816 1.22613 15.4044 2.16407C15.7901 2.60407 16.0358 3.06387 16.1546 3.56987C16.2793 4.1008 16.2815 4.73514 16.1597 5.5088L16.1509 5.56527V6.061L16.5367 6.27953C16.8615 6.45187 17.1197 6.64914 17.3177 6.875C17.6477 7.2512 17.8611 7.72933 17.9513 8.2962C18.0444 8.8792 18.0136 9.57294 17.8611 10.3583C17.6851 11.2618 17.4005 12.0487 17.0163 12.6925C16.6628 13.2858 16.2125 13.7779 15.6779 14.1592C15.1675 14.5215 14.5611 14.7965 13.8754 14.9725C13.211 15.1455 12.4535 15.2328 11.6226 15.2328H11.0873C10.7045 15.2328 10.3327 15.3707 10.0408 15.6178C9.74821 15.8701 9.55461 16.2147 9.49521 16.5917L9.45488 16.8109L8.77728 21.1046L8.74648 21.2623C8.73841 21.3121 8.72448 21.3371 8.70394 21.3539C8.68561 21.3693 8.65921 21.3796 8.63354 21.3796H5.32841Z"
            />
            <Path
                fill="#737373"
                d="M16.9018 5.62247C16.8813 5.75373 16.8578 5.88793 16.8314 6.0258C15.9243 10.6832 12.8208 12.2921 8.85717 12.2921H6.83904C6.35431 12.2921 5.94584 12.6441 5.87031 13.1223L4.83704 19.6753L4.54444 21.5329C4.49531 21.8467 4.73731 22.1298 5.05411 22.1298H8.63351C9.05737 22.1298 9.41744 21.8218 9.48417 21.4038L9.51937 21.2219L10.1933 16.9451L10.2366 16.7105C10.3026 16.291 10.6634 15.983 11.0872 15.983H11.6226C15.0905 15.983 17.8053 14.575 18.5988 10.5006C18.9302 8.79853 18.7586 7.37733 17.8816 6.3778C17.6161 6.0764 17.2868 5.82633 16.9018 5.62247Z"
            />
            <Path
                fill="#B2B2B2"
                d="M15.953 5.24407C15.8144 5.20374 15.6714 5.16707 15.5247 5.13407C15.3773 5.1018 15.2262 5.0732 15.0708 5.04827C14.5266 4.96027 13.9304 4.91847 13.2917 4.91847H7.90023C7.76749 4.91847 7.64136 4.94854 7.52843 5.0028C7.27983 5.12234 7.09503 5.35774 7.05029 5.64594L5.90336 12.9103L5.87036 13.1223C5.94589 12.6441 6.35436 12.2921 6.83909 12.2921H8.85723C12.8209 12.2921 15.9244 10.6825 16.8315 6.0258C16.8586 5.88794 16.8814 5.75374 16.9019 5.62247C16.6724 5.50074 16.4238 5.3966 16.1561 5.30787C16.0901 5.28587 16.0219 5.2646 15.953 5.24407Z"
            />
            <Path
                fill="white"
                d="M7.05026 5.64593C7.09499 5.35773 7.27979 5.12233 7.52839 5.00353C7.64206 4.94927 7.76746 4.9192 7.90019 4.9192H13.2917C13.9304 4.9192 14.5266 4.961 15.0707 5.049C15.2262 5.07393 15.3773 5.10253 15.5247 5.1348C15.6713 5.1678 15.8143 5.20447 15.9529 5.2448C16.0219 5.26533 16.0901 5.2866 16.1568 5.30787C16.4245 5.3966 16.6731 5.50147 16.9026 5.62247C17.1725 3.90133 16.9004 2.72947 15.9698 1.66833C14.9439 0.500133 13.0922 0 10.7228 0H3.84413C3.36013 0 2.94726 0.352 2.87246 0.830867L0.00732514 18.9919C-0.0491415 19.3512 0.228058 19.6753 0.590325 19.6753H4.83706L5.90333 12.9103L7.05026 5.64593Z"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4655_2594">
                <Rect width={19.0667} height={22} fill="white" />
            </ClipPath>
        </Defs>
    </Svg>
);

export default SvgComponent;
