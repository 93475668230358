import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { common } from '@/styles/styles';

import CheckmarkIcon from '../assets/icons/checkmark-square';
import SquareIcon from '../assets/icons/square';

export const Checkbox = ({ value, setValue }: { value: boolean; setValue: () => void }) => {
    return (
        <TouchableOpacity style={[common.row]} onPress={setValue}>
            {value ? <CheckmarkIcon /> : <SquareIcon />}
        </TouchableOpacity>
    );
};
