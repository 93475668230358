import React, { useImperativeHandle, useRef, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Modal } from '@/feature/alerts/components/Modal';
import { P2pLeaderboardModalContent } from '@/feature/p2p-leaderboards/components/P2pLeaderboardModalContent';
import { Pool } from '@/types/api.generated';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

const modalId = 'p2p-leaderboards';

export type LeaderboardsModalRef = {
    show: (data: Pool[]) => void;
};

export const P2pLeaderboardsModal = React.forwardRef<LeaderboardsModalRef, {}>((_props, ref) => {
    const [boards, setBoards] = useState<Pool[]>([]);
    const modalRef = useRef<BottomSheetModal>(null);
    const { top } = useSafeAreaInsets();
    const { height } = useWindowDimensions();

    useImperativeHandle(ref, () => ({
        show: leaderboardsData => {
            setBoards(leaderboardsData);
            modalRef.current?.present();
        },
    }));
    const snapPoints = [0.6 * height, height - top];

    return (
        <Modal
            id={modalId}
            modalRef={modalRef}
            snapPoints={snapPoints}
            enableDynamicSizing={false}
            hideCloseButtonOnWeb
            hasBottomSheetView={false}
            activeOffsetX={[-99999, 99999]} //Range along X axis (in points) where is not activating the handler. Moving outside this range will activate the handler.
            activeOffsetY={[-5, 5]} //Range along Y axis (in points) where is not activating the handler. Moving outside this range will activate the handler.
        >
            <P2pLeaderboardModalContent boards={boards} dismissModal={() => modalRef.current?.dismiss()} />
        </Modal>
    );
});
