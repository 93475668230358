import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import AddIcon from '@/assets/icons/addAlt';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useWallet } from '@/hooks/use-wallet';

import { AddPaymentMethod } from './AddPaymentMethod';

type PaymentMethodBankTransfersProps = {
    selectedAmount: string;
};

export const AddPaymentMethodBankTransfers = ({ selectedAmount }: PaymentMethodBankTransfersProps) => {
    const navigation = useNavigation();
    const { t } = useTranslation('transactions');
    const { wallet } = useWallet();

    return (
        <AddPaymentMethod
            icon={<AddIcon />}
            title={t('make_a_bank_transfer')}
            description={t('add_pay_by_bank_description')}
            onPress={() => {
                navigation.navigate('MazoomaDepositWebViewModal', {
                    selectedAmount,
                    currency: wallet?.real_currency,
                });
            }}
            analyticsEvent={AnalyticsEvent.DEPOSIT_METHOD_ADD_IBT}
        />
    );
};
