import React, { useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import { NavigationContainerRefWithCurrent } from '@react-navigation/native';

import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { useIsNativeSportsbook } from '@/hooks/use-is-native-sportsbook';
import { RootStackParamList } from '@/navigation/types';

type AnalyticsWrapperProps = {
    children: React.ReactNode;
    navigationRef: NavigationContainerRefWithCurrent<RootStackParamList>;
};

export const AnalyticsWrapper = ({ children, navigationRef }: AnalyticsWrapperProps) => {
    const isNativeSportsbook = useIsNativeSportsbook();
    const numberBetsCreated = useSbkBetSlipStore(state => Object.keys(state.bets).length);

    useEffect(() => {
        // Tracking drop off when user send app to background and doesn't submit any bets
        if (isNativeSportsbook) {
            const handleAppStateChange = (nextAppState: AppStateStatus) => {
                if (nextAppState === 'background') {
                    GASbkEvents.betSlipInteractionSummary(
                        numberBetsCreated,
                        0,
                        navigationRef?.current?.getCurrentRoute()?.name
                    );
                }
            };
            const subscription = AppState.addEventListener('change', handleAppStateChange);
            return () => {
                subscription.remove();
            };
        }
    }, [numberBetsCreated, isNativeSportsbook, navigationRef]);

    return <>{children}</>;
};
