import React, { useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';

import { useEventsInfoQuery } from '@/api/events/query.generated';
import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Box } from '@/components/lib/components';
import { ScrollableModal } from '@/feature/alerts/components/Modal';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import { useEntryRules } from '@/feature/betslip-pickem/hooks/use-entry-rules';
import { usePickSelection } from '@/feature/lobby/hooks/use-pick-selection';
import { common, designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { PlayerPickSlipCard } from '../components/PlayerPickSlipCard';
import { useBetslipStore } from '../hooks/use-betslip-store';
import { useUpdatePlayerStoreWithNewData } from '../hooks/use-player-props-store';

const modalId = 'playersPicksList';

const styles = StyleSheet.create({
    content: {
        backgroundColor: designSystem.colors.gray6,
        flexGrow: 0,
        flexShrink: 1,
    },
    button: {
        paddingVertical: 9,
        marginHorizontal: isWeb ? 0 : 16,
    },
    contentVerticalPadding: { paddingVertical: 8 },
});

const Footer = () => {
    const safeInsets = useSafeAreaInsets();
    const { dismiss } = useModals();
    const navigation = useNavigation();
    const { t } = useTranslation('betslip_pickem');
    return (
        <Box style={{ paddingBottom: safeInsets.bottom }}>
            <SizedBox value={16} />
            <Button
                label={t('add_more_picks')}
                variant="rounded"
                type="active"
                style={styles.button}
                onPress={() => {
                    navigation.goBack();
                    dismiss();
                }}
            />
            <SizedBox value={16} />
        </Box>
    );
};

export type PlayersPicksListModalRef = {
    show: () => void;
};

export const PlayersPicksListModal = React.forwardRef<PlayersPicksListModalRef, {}>((_props, ref) => {
    const modalRef = useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current?.present();
        },
    }));

    return (
        <ScrollableModal
            id={modalId}
            sheetRef={modalRef}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={[common.paddingHorizontal, styles.contentVerticalPadding]}
            footer={<FooterContent />}
        >
            <ModalContent
                dismissModal={() => {
                    modalRef.current?.close();
                }}
            />
        </ScrollableModal>
    );
});

const ModalContent = ({ dismissModal }: { dismissModal: () => void }) => {
    const betslip = useBetslipStore(state => state.betslip);
    const { minNumberOfPicks } = useEntryRules();
    const hasMinNumberOfPicks = betslip.length < minNumberOfPicks;

    useEffect(() => {
        if (hasMinNumberOfPicks) {
            dismissModal();
        }
    }, [hasMinNumberOfPicks, dismissModal]);

    const { makeSelection, openPlayerPickModal, removeSelection } = usePickSelection();

    const eventIds = useMemo(() => {
        return betslip.map(item => item.eventId);
    }, [betslip]);

    const [{ data, fetching }] = useEventsInfoQuery({
        variables: { ids: eventIds },
        requestPolicy: 'network-only',
    });

    useUpdatePlayerStoreWithNewData(data?.getEventsByIdsV2);

    return betslip.map((item, index) => {
        if (!betslip.find(pick => pick.player.id === item.player.id)) {
            return <></>;
        }
        return (
            <PlayerPickSlipCard
                key={`${item.player.id}-key`}
                presentation={'sheet'}
                {...item}
                shouldDisplaySeparator={betslip.length !== index + 1}
                eventDetails={data?.getEventsByIdsV2?.find(event => event.id === item.eventId)}
                loading={fetching}
                makeSelection={makeSelection}
                openPlayerPickModal={openPlayerPickModal}
                removeSelection={removeSelection}
            />
        );
    });
};

const FooterContent = () => {
    const betslip = useBetslipStore(state => state.betslip);
    const { maxNumberOfPicks } = useEntryRules();

    const displayFooter = betslip.length < maxNumberOfPicks;
    return displayFooter ? <Footer /> : <SizedBox value={16} />;
};
