import { isWeb } from '@/utils/constants-platform-specific';

/**
 * getCookie is for web only
 */
export const getCookie = (name: string) => {
    if (!isWeb) {
        return undefined;
    }
    var b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : undefined;
};

const twoWeeks = 1209600;
/**
 * setCookie is for web only
 */
export const setCookie = (name: string, value: string, maxAge: number = twoWeeks) => {
    if (isWeb) {
        document.cookie = `${name}=${value};path=/;max-age=${maxAge}`;
    }
};
