import { useTranslation } from 'react-i18next';

import { ordinal } from '@/utils/numeric/format';

import { MarketType, OptionType, Team } from '../types';

const HANDICAP_HOME_FAVOURITE_KEY = 'HANDICAP_HOME_FAVOURITE';
const HANDICAP_AWAY_FAVOURITE_KEY = 'HANDICAP_AWAY_FAVOURITE';
const HIGHEST_SCORING_PERIOD_KEY = 'HIGHEST_SCORING_PERIOD';

const HANDICAP_KEY = 'HANDICAP';

type UseOptionName = {
    optionType: OptionType;
    marketType: MarketType;
    fallback: string;
    homeTeam: Pick<Team, 'short_code' | 'name'>;
    awayTeam: Pick<Team, 'short_code' | 'name'>;
    sportName: string;
    isDisplayFullName?: boolean;
};

type HandicapTranslation = {
    optionType: OptionType;
    marketTypeParams: MarketType['params'];
    fallback: string;
    homeTeam: Pick<Team, 'short_code' | 'name'>;
    awayTeam: Pick<Team, 'short_code' | 'name'>;
    t: ReturnType<typeof useTranslation>['t'];
    isDisplayFullName: boolean;
};

type OptionNameReturner = {
    optionName: string | undefined;
    key: string;
    fallback: string;
};

type OptionTypeParams = MarketType['params'] &
    OptionType['params'] & {
        PERIOD_X_ORDINAL?: string;
        HALF_X_ORDINAL?: string;
        team?: string;
        context?: number;
    };

const getDisplayLine = (line: number) => {
    return Math.ceil(Math.abs(line));
};

// This function will check if we can find key in translation file, if not it will return fallback
const getOptionNameWithFallback = ({ optionName, key, fallback }: OptionNameReturner) => {
    if (!optionName || optionName === key) {
        return fallback;
    }
    return optionName;
};

const getHandicapTranslation = ({
    marketTypeParams,
    optionType,
    homeTeam,
    awayTeam,
    fallback,
    t,
    isDisplayFullName,
}: HandicapTranslation) => {
    if (marketTypeParams.line !== undefined) {
        const handicapKey = marketTypeParams.line < 0 ? HANDICAP_HOME_FAVOURITE_KEY : HANDICAP_AWAY_FAVOURITE_KEY;
        const optionKey = `${optionType.code}_${handicapKey}`;
        //@ts-ignore - Passing unknown string to t function
        const optionName = t(optionKey, {
            line: getDisplayLine(marketTypeParams.line),
            homeTeam: isDisplayFullName ? homeTeam.name : homeTeam.short_code,
            awayTeam: isDisplayFullName ? awayTeam.name : awayTeam.short_code,
        }) as string;
        return getOptionNameWithFallback({ optionName, key: optionKey, fallback });
    }
    return fallback;
};

// checks if UPPER_BOUND and LOWER_BOUND are equal
const isEqualBounds = (params: OptionTypeParams) => {
    return (
        params.UPPER_BOUND !== undefined &&
        params.LOWER_BOUND !== undefined &&
        params.UPPER_BOUND === params.LOWER_BOUND
    );
};

// checks if market type is PLAYER_{{STATISTIC}}_MILESTONE
const isPlayerMilestone = (marketTypeCode: string) => {
    return /^PLAYER_.*_MILESTONE$/.test(marketTypeCode);
};

export const useOptionName = ({
    optionType,
    marketType,
    fallback,
    homeTeam,
    awayTeam,
    sportName,
    isDisplayFullName = false,
}: UseOptionName) => {
    const { t } = useTranslation('sbk_options');
    let params: OptionTypeParams = { ...marketType?.params, ...optionType.params };
    let marketTypeCode = marketType.code;
    let code: string = optionType.code;
    if (marketType?.code?.includes(HANDICAP_KEY)) {
        return getHandicapTranslation({
            marketTypeParams: params,
            optionType,
            homeTeam,
            awayTeam,
            fallback,
            t,
            isDisplayFullName,
        });
    }
    if (params.line !== undefined) {
        params.line = getDisplayLine(params.line);
    }
    if (optionType.code === HIGHEST_SCORING_PERIOD_KEY && params.period) {
        const sportNameSpace = sportName?.replace(/\s/g, '_').toUpperCase();
        const key = `${HIGHEST_SCORING_PERIOD_KEY}_${sportNameSpace}`;
        // @ts-ignore - Passing unknown string to t function
        const optionName = t(key, {
            period_ordinal: ordinal(params.period),
        }) as string;
        return getOptionNameWithFallback({ optionName, key, fallback });
    }

    if (params.PERIOD_X) {
        params = { ...params, PERIOD_X_ORDINAL: ordinal(params.PERIOD_X) };
    }
    if (params.HALF_X) {
        params = { ...params, HALF_X_ORDINAL: ordinal(params.HALF_X) };
    }
    if (params.TEAM !== undefined) {
        if (params.TEAM === 0) {
            params = { ...params, team: homeTeam.short_code };
        }
        if (params.TEAM === 1) {
            params = { ...params, team: awayTeam.short_code };
        }
    }

    if (isEqualBounds(params)) {
        // Appending EQUAL to option code if UPPER_BOUND and LOWER_BOUND are equal to adjust the translation
        // from being "CHW by 1-1" to "CHW by 1"
        code = `${code}_EQUAL`;
    }

    if (params.selections) {
        // using the `context` option here to allow for translations with the same key but different number of selections
        // https://www.i18next.com/translation-function/context
        params = { ...params, context: params.selections };
    }

    if (isPlayerMilestone(marketType.code)) {
        marketTypeCode = 'player_milestone';
    }

    const key = `${marketTypeCode}.${code}`;
    const fallbackKey = code;
    // @ts-ignore - Passing unknown string to t function
    const optionName = t([key, fallbackKey], {
        ...params,
        homeTeam: isDisplayFullName ? homeTeam.name : homeTeam.short_code,
        awayTeam: isDisplayFullName ? awayTeam.name : awayTeam.short_code,
        fallback: code,
    }) as string;
    return getOptionNameWithFallback({ optionName, key: code, fallback });
};
