import { URLS, createJurisdictionHeaders } from '@/data';
import { eventKeys } from '@/feature/event-details-sbk/hooks/use-event';
import { Event } from '@/feature/event-details-sbk/types';
import { EventsResponse } from '@/feature/league-hub-sbk/hooks/use-events';
import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { useQuery } from '@tanstack/react-query';

/**
 * Fetches the featured events
 * GET /events/featured
 */
const getFeaturedEvents = async (): Promise<EventsResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/events/featured`, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            ...cacheControlHeader,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    useEventDetailsCache
        .getState()
        .actions.populate(
            data?.data
                ?.map((event: Event) => ({ ...(event.event_details ?? {}), id: event.id, status: event.status }))
                .flat() ?? []
        );
    return data;
};

/**
 * Hook to fetch the featured events
 */
export const useFeaturedEvents = () => {
    return useQuery(eventKeys.featured(), getFeaturedEvents, {
        select: data => data.data,
    });
};
