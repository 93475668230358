import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet } from 'react-native';

import { SizedBox } from '../SizedBox';
import { Text } from '../TextComponent';
import { Box, Row } from '../lib/components';
import { AppColors, AppSpacing } from '../lib/theme';
import { TeamsDetails } from './types';

export const MatchUpStatus = ({
    awayTeamIcon,
    homeTeamIcon,
    awayTeamShortCode,
    homeTeamShortCode,
    matchDetails,
    maxMatchupWidth,
    fontSize,
    innerPadding,
    logoWidth,
    logoHeight,
    sizedBoxDimensions,
    textColor = 'gray3',
}: TeamsDetails & {
    matchDetails?: string;
    maxMatchupWidth: number;
    fontSize: number;
    innerPadding: AppSpacing;
    logoWidth: number;
    logoHeight: number;
    sizedBoxDimensions: {
        top: number;
        middle: number;
        bottom: number;
    };
    textColor?: AppColors;
}) => {
    const { t } = useTranslation('common');

    return (
        <Box paddingRight={innerPadding} maxWidth={maxMatchupWidth}>
            <Row marginTop="s2" width={'100%'}>
                <Text variant="labelMedium" fontWeight="400" numberOfLines={1} color={textColor} fontSize={fontSize}>
                    {matchDetails || t('box_score')}
                </Text>
            </Row>
            <SizedBox value={sizedBoxDimensions.top} />
            <Row>
                {awayTeamIcon && (
                    <Image
                        source={{ uri: awayTeamIcon }}
                        style={[styles.teamLogo, { height: logoHeight, width: logoWidth }]}
                    />
                )}
                <Text variant="labelMedium" numberOfLines={1} fontSize={fontSize} style={styles.text}>
                    {awayTeamShortCode}
                </Text>
            </Row>
            <SizedBox value={sizedBoxDimensions.middle} />
            <Row width={'100%'}>
                {homeTeamIcon && (
                    <Image
                        source={{ uri: homeTeamIcon }}
                        style={[styles.teamLogo, { height: logoHeight, width: logoWidth }]}
                    />
                )}
                <Text variant="labelMedium" numberOfLines={1} fontSize={fontSize} style={styles.text}>
                    {homeTeamShortCode}
                </Text>
            </Row>
            <SizedBox value={sizedBoxDimensions.bottom} />
        </Box>
    );
};

const styles = StyleSheet.create({
    teamLogo: {
        marginRight: 8,
    },
    text: {
        flexShrink: 1,
        fontWeight: '600',
    },
});
