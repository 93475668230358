import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Edge, useSafeAreaInsets } from 'react-native-safe-area-context';

import { designSystem } from '../styles/styles';

const styles = StyleSheet.create({
    root: {
        backgroundColor: designSystem.colors.gray8,
        height: '100%',
        flexDirection: 'column',
    },
});

export type ScreenProps = {
    children: (JSX.Element | null)[] | JSX.Element;
    unsafe?: boolean | undefined | null;
    edges?: Edge[];
};

type InsetType = {
    paddingTop: number;
    paddingRight: number;
    paddingBottom: number;
    paddingLeft: number;
};

const EdgeMap: { [key in Edge]: keyof InsetType } = {
    top: 'paddingTop',
    right: 'paddingRight',
    bottom: 'paddingBottom',
    left: 'paddingLeft',
};

export const Screen = ({ children, unsafe, edges = ['left', 'right', 'top'] }: ScreenProps) => {
    const insets = useSafeAreaInsets();

    const pageInsets = useMemo(() => {
        const insetStyles: InsetType = { paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 };

        edges.forEach(edge => {
            insetStyles[EdgeMap[edge]] = insets[edge];
        });

        return insetStyles;
    }, [edges, insets]);

    if (unsafe) {
        return <View style={styles.root}>{children}</View>;
    }

    return <View style={[pageInsets, styles.root]}>{children}</View>;
};
