import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import { useRealityCheck } from '@/hooks/use-reality-check';
import { useUser } from '@/hooks/use-user';

/**
 * `RealityReminderWrapper` is a higher-order component that wraps its children
 * and checks if a "reality check" should be shown to the user based on their
 * session duration or activity. If required, it navigates
 * the user to an info screen (modal) that informs them of their playtime.
 *
 * @param children - The children components to wrap.
 *
 * @returns {React.ReactElement} Wrapped children components.
 */
export const RealityReminderWrapper = ({ children }: { children: React.ReactNode }): React.ReactElement => {
    const { showModal, minutesBetweenChecks, hideModalAndResetTimer } = useRealityCheck();
    const { logout } = useUser();
    const { showInfoSheet } = useAlerts();
    const { dismissAll } = useModals();

    const navigation = useNavigation();
    const { t } = useTranslation(['account', 'common']);

    useEffect(() => {
        if (showModal) {
            // Set a new reality check timer once the modal is triggered.
            // This is crucial since the info sheet component lets the user
            // dismiss the modal by tapping outside, potentially bypassing the reality check.
            hideModalAndResetTimer();
            showInfoSheet({
                title: t('account:reality_check'),
                description: t('account:reality_check_desc', { reality_check_interval: minutesBetweenChecks }),
                buttonLabel: t('common:continue_playing'),
                secondaryLabel: t('common:logout'),
                handlePress: hideModalAndResetTimer,
                handleSecondaryPress: () => {
                    logout();
                    dismissAll();
                },
            });
        }
    }, [t, navigation, showModal, minutesBetweenChecks, hideModalAndResetTimer, logout, showInfoSheet, dismissAll]);

    return <>{children}</>;
};
