import React from 'react';

import { Text } from '@/components/TextComponent';
import { Box, Column } from '@/components/lib/components';

import { HeaderWithAccordionUI } from './HeaderWithAccordian';
import { CardHeader, Header, HeaderWithSubHeading } from './types';

export const FeaturedBetCardHeader = ({ cardHeader }: { cardHeader: CardHeader }) => {
    switch (cardHeader.type) {
        case 'HeaderWithAccordion':
            return <HeaderWithAccordionUI {...cardHeader} />;
        case 'Header':
            return <HeaderUI {...cardHeader} />;
        case 'HeaderWithSubHeading':
            return <HeaderWithSubHeadingUI {...cardHeader} />;
    }
};

const HeaderUI = ({ content }: Header) => {
    return (
        <Box flexShrink={1}>
            <Text variant="titleLarge" lineHeight={24} letterSpacing={-0.43} numberOfLines={1}>
                {content}
            </Text>
        </Box>
    );
};

export const HeaderWithSubHeadingUI = ({ header, media }: HeaderWithSubHeading) => {
    return (
        <Column>
            <HeaderUI {...header} />
            <Box flexShrink={1}>
                <Text variant="bodySmall" letterSpacing={-0.08} color="gray2" numberOfLines={1}>
                    {media.mediaName}
                </Text>
            </Box>
        </Column>
    );
};
