import React from 'react';
import { StyleSheet, TouchableOpacity, TouchableOpacityProps, View } from 'react-native';

import { common, designSystem } from '@/styles/styles';
import { ProductDocumentDataBodyLeaguesSliceItem } from '@/types/prismic.generated';

import { Image } from './lib/components';

const styles = StyleSheet.create({
    base: {
        width: 72,
        height: 118,
    },
    logoContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: designSystem.colors.gray6,
        borderCurve: 'continuous',
        borderRadius: 100,
    },
    image: {
        width: 32,
        height: 32,
    },
});

type Props = {
    Icon?: JSX.Element;
    children?: React.ReactNode;
    image?: ProductDocumentDataBodyLeaguesSliceItem['banner'] | { url?: string };
    disabled?: boolean;
} & TouchableOpacityProps;

export const ButtonTitleIcon = ({ Icon, image, children, disabled, ...props }: Props) => {
    const opacity = disabled ? 0.3 : 1;
    return (
        <TouchableOpacity
            {...props}
            style={[styles.base, common.alignCenter, common.justifyEnd, { opacity }]}
            disabled={disabled}
        >
            <View style={[common.flex, common.justifyCenter, common.alignCenter, styles.logoContainer]}>
                {image && image?.url ? (
                    <Image source={{ uri: image.url }} style={styles.image} resizeMode={'contain'} />
                ) : (
                    Icon
                )}
            </View>
            {children}
        </TouchableOpacity>
    );
};
