import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import AlertIcon from '@/assets/icons/alert';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { designSystem, withOpacity } from '@/styles/styles';

export const ExpiredCardTag = () => {
    const { t } = useTranslation();
    return (
        <Box style={styles.tagContainer}>
            <AlertIcon color={designSystem.colors.red} />
            <Text style={styles.tagLabel}>{t('expired')}</Text>
        </Box>
    );
};

const styles = StyleSheet.create({
    tagContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
        borderRadius: 6,
        backgroundColor: withOpacity(designSystem.colors.red, 0.16),
        paddingVertical: 2,
        paddingLeft: 6,
        paddingRight: 8,
    },
    tagLabel: {
        color: designSystem.colors.red,
        fontSize: 13,
    },
});
