import React from 'react';

import { Text, TextProps } from '@/components/TextComponent';

import { useOptionName } from '../hooks/use-option-name';
import { MarketType, OptionType, Team } from '../types';

type OptionNameProps = {
    optionType: OptionType;
    marketType: MarketType;
    homeTeam: Pick<Team, 'short_code' | 'name' | 'alias'>;
    awayTeam: Pick<Team, 'short_code' | 'name' | 'alias'>;
    fallback: string;
    sportName: string;
    value?: number;
    isDisplayFullName?: boolean;
} & TextProps;

export const OptionName = ({
    optionType,
    marketType,
    homeTeam,
    awayTeam,
    fallback,
    sportName,
    isDisplayFullName = false,
    ...textProps
}: OptionNameProps) => {
    const optionsName = useOptionName({
        optionType,
        marketType,
        homeTeam,
        awayTeam,
        fallback,
        sportName,
        isDisplayFullName,
    });
    return <Text {...textProps}>{optionsName}</Text>;
};
