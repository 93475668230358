import * as React from 'react';
import { Path, Svg } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
        <Path
            fill={props.color}
            fillRule="evenodd"
            d="M2.43 8.432 7.998 14l5.569-5.568-1.238-1.238-3.456 3.456V2h-1.75v8.65L3.667 7.194 2.43 8.432Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
