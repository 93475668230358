import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';
import { PlayerCard, PlayerCardProps, PlayersCardlRef } from '@/feature/betslip-pickem/components/PlayerCard';

type PlayerCardType = {
    showPlayerCard: (props: PlayerCardProps) => void;
};

const PlayerCardContext = React.createContext<PlayerCardType>({
    showPlayerCard: () => {},
});

/**
 * Provides access to the Modal used to display the player card
 */
export const PlayerCardProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<PlayersCardlRef>(null);

    const showPlayerCard = useCallback((props: PlayerCardProps) => {
        modalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <PlayerCardContext.Provider value={{ showPlayerCard }}>
                {children}
                <PlayerCard ref={modalRef} />
            </PlayerCardContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const usePlayerCard = () => {
    return useContext(PlayerCardContext);
};
