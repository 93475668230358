import React, { useEffect } from 'react';
import { NativeModules, StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useDeposit } from '@/feature/deposit/hooks/use-deposit';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { getWalletErrorCode } from '@/utils/get-wallet-error-code';

import { PaymentMethodTypes } from '../const';

const { PaymentsWebView } = NativeModules;

const styles = StyleSheet.create({
    loaderContainer: { backgroundColor: designSystem.colors.gray8 },
});

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'MazoomaDepositWebViewModal'>;

export const MazoomaDepositWebView = ({ route }: ScreenProps) => {
    const { selectedAmount, currency = 'USD', payment_token = '', payment_type_id = '' } = route.params || {};
    const selectedPaymentMethodType = PaymentMethodTypes.PaysafeMazooma;
    const navigation = useNavigation();

    const { mutate: deposit } = useDeposit();

    useEffect(() => {
        deposit(
            {
                amount: selectedAmount,
                type: PaymentMethodTypes.PaysafeMazooma,
                currency,
                payment_token,
                payment_type_id,
            },
            {
                onSuccess: data => {
                    PaymentsWebView.openURL(data.payment_url); // Open out-of-process WebView
                    navigation.goBack(); // Navigate back to prevent being stuck on loading screen
                },
                onError: error => {
                    navigation.navigate('FailedDepositModal', {
                        selectedAmount,
                        paymentProvider: selectedPaymentMethodType,
                        errorCode: getWalletErrorCode(error),
                    });
                },
            }
        );
    }, [currency, deposit, navigation, payment_token, payment_type_id, selectedAmount, selectedPaymentMethodType]);

    return (
        <View style={[common.flex, styles.loaderContainer, common.justifyCenter]}>
            <LoadingSpinner />
        </View>
    );
};
