import { BREAKPOINTS, SCREEN_SIZE } from '@/feature/responsive-design/constants';

export const getBreakpointByWidth = (width: number): SCREEN_SIZE => {
    if (width <= BREAKPOINTS.xs[1]) {
        return 'xs';
    } else if (width <= BREAKPOINTS.sm[1]) {
        return 'sm';
    } else if (width <= BREAKPOINTS.md[1]) {
        return 'md';
    } else {
        return 'lg';
    }
};
