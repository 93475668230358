import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, useWindowDimensions } from 'react-native';
import { RefreshControl, TouchableOpacity } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';

// import ShareIcon from '@/assets/icons/share-small';
import { CloseIcon } from '@/assets/icons/close';
import { useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import { designSystem } from '@/styles/styles';

import { Button } from './ButtonComponent';
import { LineSeparator } from './LineSeparator';
import { DotStatus, ProgressDots } from './ProgressDots';
import { SizedBox } from './SizedBox';
import { Text } from './TextComponent';
import { Box, Column, Row } from './lib/components';
import { LinearGradient } from './linear-gradient/LinearGradient';

const ACTION_TRAY_HEIGHT = 112;

const styles = StyleSheet.create({
    rounded: {
        borderRadius: 24,
    },
    gradient: {
        zIndex: designSystem.zIndex.zIndexNegative2,
        height: 200,
    },
});

type BetDetailShellProps = {
    title: string;
    progressDots: Array<DotStatus>;
    summary: ReactNode;
    footer: Footer;
    gradientColor: Array<string>;
    shouldShowProgressDots: boolean;
    hasActionTray?: boolean;
    isRefreshing?: boolean;
    onRefresh?: () => void;
    children: ReactNode;
};
/**
 * In the future, this component will be used for both pickem and sbk. So it doesn't use useBetDetail Hook directly.
 */
export const BetDetailShell = ({
    title,
    progressDots,
    summary,
    footer,
    gradientColor,
    shouldShowProgressDots,
    hasActionTray = false,
    isRefreshing = false,
    onRefresh,
    children,
}: BetDetailShellProps) => {
    const { t } = useTranslation(['common']);
    const { top } = useSafeAreaInsets();
    const { launchIntercomMessenger } = useLaunchIntercomMessenger();

    const { width } = useWindowDimensions();

    return (
        <Box style={{ paddingTop: top }}>
            <Box position="absolute" top={0} left={0} right={0} bottom={0} zIndex={-1}>
                <LinearGradient
                    colors={gradientColor}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 0, y: 1 }}
                    height={200}
                    width={width}
                    style={[styles.gradient]}
                />
            </Box>
            <ScreenHeader shouldShowProgressDots={shouldShowProgressDots} title={title} dots={progressDots} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        enabled
                        colors={[designSystem.colors.white]}
                        tintColor={designSystem.colors.white}
                        refreshing={isRefreshing}
                        onRefresh={onRefresh}
                    />
                }
            >
                <SizedBox value={24} />
                <Box flex={1} gap="s32" px="s16">
                    <Row>{summary}</Row>
                    {children}
                    <LineSeparator />
                    <Box>
                        <Footer rows={footer} />
                        <SizedBox value={24} />
                        <Button label={t('contact_us')} onPress={launchIntercomMessenger} variant="roundedOutlined" />
                        <SizedBox value={96} />
                        {hasActionTray ? <SizedBox value={ACTION_TRAY_HEIGHT} /> : null}
                    </Box>
                </Box>
            </ScrollView>
        </Box>
    );
};

type ScreenHeader = {
    shouldShowProgressDots: boolean;
    title: string;
    dots: Array<DotStatus>;
};

export const ScreenHeader = ({ shouldShowProgressDots, title, dots = [] }: ScreenHeader) => {
    const navigation = useNavigation();

    const renderTitle = () => (
        <Box flex={1} alignItems="center">
            {shouldShowProgressDots ? (
                <Column gap="s8">
                    <Row justifyContent="center" testID="betCardScreenTitle">
                        <Text variant="titleLarge">{title}</Text>
                    </Row>
                    <Row justifyContent="center">
                        <ProgressDots dots={dots} />
                    </Row>
                </Column>
            ) : (
                <Text variant="titleLarge" testID="betCardScreenTitle">
                    {title}
                </Text>
            )}
        </Box>
    );

    return (
        <Box paddingHorizontal="s16" height={60} alignItems="center" flexDirection="row">
            <Box flexShrink={1}>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <CloseIcon />
                </TouchableOpacity>
            </Box>
            {renderTitle()}
            <Box flexShrink={1}>
                {/* TODO: to remove this when we add share icon */}
                {/* Share Icon */}
                <SizedBox value={24} />
            </Box>
        </Box>
    );
};

type Footer = Array<{
    label: string;
    value: string;
    testID?: string;
}>;

const Footer = ({ rows }: { rows: Footer }) => (
    <Box>
        {rows.map(row => (
            <Row key={row.label} justifyContent="space-between">
                <Text variant="bodySmall" textTransform="capitalize" color="gray3" testID={row.testID}>
                    {row.label}
                </Text>
                <Text variant="bodySmall" color="gray3" testID={row.testID}>
                    {row.value}
                </Text>
            </Row>
        ))}
    </Box>
);
