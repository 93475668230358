import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { NotifyOnUpdatedPoolEntryDocument, useGetPoolsByEntryIdQuery } from '@/api/pools/query.generated';
import { Button } from '@/components/ButtonComponent';
import { Box } from '@/components/lib/components';
import { useLeaderboards } from '@/feature/p2p-leaderboards/components/P2pLeaderboardsProvider';
import { useResumeEffect } from '@/hooks/use-resume';
import { SEPARATOR_HEGIHT, common } from '@/styles/styles';
import { Pool } from '@/types/api.generated';
import { logger } from '@/utils/logging';
import { useSubscription } from 'urql';

const LOG_TAG = '[Leaderboards]';

const styles = StyleSheet.create({
    button: { paddingVertical: 10, borderWidth: 0 },
});

export const ViewP2pLeaderboardsButton = ({ id }: { id: string }) => {
    const { showLeaderboardsModal } = useLeaderboards();
    const { t } = useTranslation('p2p');
    const [localBoards, setLocalBoards] = useState<Pool[]>([]);
    const [{ data }, executeGetPoolsByEntryId] = useGetPoolsByEntryIdQuery({
        variables: { entryId: id },
        pause: true,
    });
    const [] = useSubscription({ query: NotifyOnUpdatedPoolEntryDocument, variables: { id } }, (_, next) => {
        setLocalBoards(val => {
            const nextPayload = next?.notifyOnUpdatedPoolEntry;
            logger.info(LOG_TAG, 'Received updated pool entry', nextPayload);

            if (nextPayload) {
                return nextPayload;
            }

            return val;
        });
    });

    useEffect(() => {
        if (data?.getPoolsByEntryId) {
            setLocalBoards(data.getPoolsByEntryId);
        }
    }, [data?.getPoolsByEntryId]);

    const reload = useCallback(() => {
        executeGetPoolsByEntryId({ requestPolicy: 'cache-and-network' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useResumeEffect(reload);

    return (
        <Box paddingVertical={'s16'} borderTopWidth={SEPARATOR_HEGIHT} borderColor={'gray5'}>
            <Button
                label={t('view_leaderboard')}
                type={'active'}
                style={[common.full, styles.button]}
                variant={'rounded'}
                size={'medium'}
                // Disable the button if there are no leaderboards
                disabled={!localBoards.length}
                onPress={() => {
                    showLeaderboardsModal(localBoards);
                }}
            />
        </Box>
    );
};
