import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Text, TextProps } from '@/components/TextComponent';
import { Row, RowProps } from '@/components/lib/components';
import { common } from '@/styles/styles';

export type BannerProps = {
    backgroundColor?: RowProps['backgroundColor'];
    textColor?: TextProps['color'];
    borderColor?: RowProps['borderColor'];
    borderWidth?: RowProps['borderWidth'];
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    message: string | JSX.Element;
    onPress?: () => void;
    testID?: string;
};

export const Banner = ({
    backgroundColor = 'gray6',
    textColor,
    borderColor,
    borderWidth = 0,
    leftIcon,
    rightIcon,
    message,
    onPress,
    testID,
}: BannerProps) => {
    const rowElement = (
        <Row
            padding="s16"
            gap="s16"
            borderRadius="r10"
            backgroundColor={backgroundColor}
            alignItems="center"
            borderColor={borderColor}
            borderWidth={borderWidth}
        >
            {leftIcon}

            <Text variant="bodySmall" color={textColor} style={common.flex} testID={`betSummaryError-${testID}`}>
                {message}
            </Text>

            {rightIcon}
        </Row>
    );
    return onPress ? <TouchableOpacity onPress={onPress}>{rowElement}</TouchableOpacity> : rowElement;
};
