import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { common } from '@/styles/styles';

import { Section } from '../screens/EntriesTopTabBarScreen';

type EntriesEmptyListProps = {
    currentSection: Section;
    navigateHome: () => void;
};

const EntriesEmptyList = ({ currentSection, navigateHome }: EntriesEmptyListProps) => {
    const { t } = useTranslation('pickem_entries');

    return (
        <View style={[common.full, common.alignCenter]}>
            <SizedBox value={180} />
            <Text variant="titleLarge">{t('empty_list_title', { section: currentSection.label.toLowerCase() })}</Text>
            <SizedBox value={4} />
            <Text variant="bodyMedium" color={'gray2'} textAlign={'center'}>
                {t('empty_list_details', { section: currentSection.label.toLowerCase() })}
            </Text>
            <SizedBox value={24} />
            <Button label="Browse players" variant="outlined" onPress={navigateHome} />
        </View>
    );
};

export default EntriesEmptyList;
