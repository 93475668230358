import { NavigationContainerRefWithCurrent } from '@react-navigation/native';

import { getAppVersion } from '@/utils/get-app-version';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export const dataDogEnabled = () => !__DEV__;

export const enableDataDog = (env: string) => {
    if (dataDogEnabled()) {
        datadogRum.init({
            applicationId: '3e872b40-a383-40bb-9a76-551420084421',
            clientToken: 'pubbced38c66a2394e072299c590977909c',
            site: 'datadoghq.com',
            service: 'betr-app-web',
            env: env.toLowerCase(),
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            silentMultipleInit: true,
            version: getAppVersion(),
        });
        datadogLogs.init({
            clientToken: 'pubbced38c66a2394e072299c590977909c',
            site: 'datadoghq.com',
            silentMultipleInit: true,
        });
    }
};

// could not find a way to enable navigation tracking in the browser
export const enableDataDogNavTracking = (_nav: NavigationContainerRefWithCurrent<any>) => {};

export const setDatadogUser = (userId: string) => {
    if (dataDogEnabled()) {
        datadogRum.setUser({ id: userId });
    }
};

export const trackRUMAction = (action: string, args?: object) => {
    if (dataDogEnabled()) {
        datadogRum.addAction(action, args);
    }
};

export const trackRUMView = (view: string) => {
    if (dataDogEnabled()) {
        datadogRum.startView(view);
    }
};

export const DdLogs = {
    debug: ([tag, args]: [string, object]) => {
        datadogLogs.logger.debug(tag, args);
    },
    info: ([tag, args]: [string, object]) => {
        datadogLogs.logger.info(tag, args);
    },
    warn: ([tag, args]: [string, object]) => {
        datadogLogs.logger.warn(tag, args);
    },
    error: ([tag, args]: [string, object]) => {
        console.log('DdLogs.error');
        datadogLogs.logger.error(tag, args);
    },
};
