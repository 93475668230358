import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Rect width={24} height={24} fill={props.color ?? '#C93EF4'} rx={6} />
        <Path fill="#000" d="m10.772 13.728-.491 4.939 6.386-6.914-3.439-1.975.492-4.445-6.387 6.914 3.44 1.481Z" />
    </Svg>
);
export default SvgComponent;
