import React from 'react';
import { StyleSheet, TouchableOpacity, TouchableOpacityProps, View } from 'react-native';

import RightIcon from '@/assets/icons/right';
import { SEPARATOR_HEGIHT, common, designSystem } from '@/styles/styles';

import { Text } from './TextComponent';

export const ButtonMore = ({ label, children, style, ...props }: TouchableOpacityProps & { label?: string }) => {
    return (
        <TouchableOpacity activeOpacity={0.8} style={[styles.buttonMore, common.spaceBetweenRow, style]} {...props}>
            <Text variant="bodyMedium">{label}</Text>
            <View style={[common.row, common.alignCenter]}>
                {children}
                <RightIcon style={styles.rightIcon} />
            </View>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    buttonMore: {
        minHeight: 56,
        borderBottomWidth: SEPARATOR_HEGIHT,
        borderBottomColor: designSystem.colors.gray5,
    },
    rightIcon: {
        marginLeft: 22,
    },
});
