import { SharedEntryInfoFragment } from '@/feature/entry-share/api/query.generated';
import { ProjectionType } from '@/types/api.generated';

export const sortPicksByProjectionType = (prebuiltLineup: SharedEntryInfoFragment): SharedEntryInfoFragment => {
    const { picks } = prebuiltLineup.entry;

    picks.sort((a, b) => {
        const boostedA = a.projection.type === ProjectionType.Boosted ? -1 : 1;
        const boostedB = b.projection.type === ProjectionType.Boosted ? -1 : 1;

        const isSpecialA = a.projection.type === ProjectionType.Special;
        const isSpecialB = b.projection.type === ProjectionType.Special;

        // Objects with 'Boosted' type come first
        if (boostedA !== boostedB) {
            return boostedA - boostedB;
        }

        // Next, special projections
        if (isSpecialA && !isSpecialB) {
            return -1;
        }
        if (!isSpecialA && isSpecialB) {
            return 1;
        }

        return 0;
    });

    return prebuiltLineup;
};
