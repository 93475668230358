import { useCallback } from 'react';

import { useNavigation } from '@react-navigation/native';

import { useProcessCardRemoval } from '@/feature/account-details/hooks/use-process-card-removal';
import { useDebitCardRemovalStore } from '@/hooks/use-debit-card-removal-store';

/**
 * Custom hook for handling successful verification and navigation.
 *
 * @returns A function to handle successful verification and navigate to the specified route.
 */
export const usePhoneVerificationSuccess = (): { handleSuccessfulVerification: (redirectRoute?: string) => void } => {
    const navigation = useNavigation();
    const { handleVerificationSuccess } = useProcessCardRemoval();
    const { paymentTypeId } = useDebitCardRemovalStore();

    const handleSuccessfulVerification = useCallback(
        (redirectRoute?: string) => {
            switch (redirectRoute) {
                case 'passwordEdit':
                    navigation.navigate('PasswordEdit');
                    break;
                case 'manageDebitCards':
                    handleVerificationSuccess(paymentTypeId);
                    break;
                default:
                    navigation.goBack();
            }
        },
        [navigation, handleVerificationSuccess, paymentTypeId]
    );

    return { handleSuccessfulVerification };
};
