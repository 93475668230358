import React, { ReactNode, useMemo } from 'react';

import { EventWithTeams } from '@/api/events/types/types';
import { Text } from '@/components/TextComponent';
import { ScoreBoardAccordion } from '@/components/scoreboard/ScoreBoardAccordion';
import { getEventTitle, getFinalScoreColumns, getScoreboard } from '@/feature/bets-sbk/utils/utils';
import { useFormatMatchDetails } from '@/feature/event-details-sbk/hooks/use-format-match-details';
import { useSubscribeEventMatchUpdate } from '@/feature/event-details-sbk/hooks/use-subscribe-event-match-update';
import { Event, EventDetails } from '@/feature/event-details-sbk/types';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useEventDetail, useEventStatus } from '@/hooks/use-sbk-event-details-cache';
import { designSystem } from '@/styles/styles';

export const PickemScoreBoardAccordion = ({ event, eventInfo }: { event: Event; eventInfo: EventWithTeams }) => {
    const eventDetails = useEventDetail(event.id) ?? event.event_details;
    const breakdown = useMemo(() => getScoreboard(event.sport, eventDetails), [event.sport, eventDetails]);
    const eventStatus = useEventStatus(event.id) ?? event.status;
    const productFlags = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);
    const defaultExpandedScoreboard = productFlags?.default_opened_scoreboard;

    const [away, home] = eventInfo.teams;

    const updatedEvent = {
        ...event,
        status: eventStatus,
        event_details: eventDetails,
    };

    const finalScoreColumns = useMemo(
        () => (event && eventDetails ? getFinalScoreColumns(eventDetails, event.sport) : []),
        [event, eventDetails]
    );
    const matchDetails = useFormatMatchDetails(updatedEvent);

    return (
        <ScoreBoardAccordion
            breakdown={breakdown}
            eventTitle={getEventTitleWithGameClock(updatedEvent, eventDetails)}
            awayTeamIcon={away.icon}
            homeTeamIcon={home.icon}
            backgroundColor={'gray8'}
            awayTeamShortCode={event.away_team.short_code}
            homeTeamShortCode={event.home_team.short_code}
            awayTeamColor={away.color}
            homeTeamColor={home.color}
            finalScoreColumns={finalScoreColumns}
            isLive={eventStatus === 'LIVE'}
            matchDetails={matchDetails}
            animateExplicitInitialExpansion={Boolean(defaultExpandedScoreboard)}
            accordionStyle={{ backgroundColor: designSystem.colors.gray8 }}
        />
    );
};

const getEventTitleWithGameClock = (event: Event, eventDetails: EventDetails): ReactNode | string => {
    const teamsTitle = getEventTitle(event);
    if (event.status === 'LIVE') {
        const liveText = eventDetails.game_clock?.event_time ?? 'LIVE';
        return (
            <Text>
                {`${teamsTitle} • `}
                <Text color={'red'}>{liveText}</Text>
            </Text>
        );
    }
    return teamsTitle;
};

export const PickemScoreBoardWithSubscription = ({
    event,
    eventInfo,
    refetch,
}: {
    event: Event;
    eventInfo: EventWithTeams;
    refetch: () => Promise<unknown>;
}) => {
    useSubscribeEventMatchUpdate(event.id);
    useFetchOnResume(refetch);

    return <PickemScoreBoardAccordion eventInfo={eventInfo} event={event} />;
};
