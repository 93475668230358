import React from 'react';

import { Checkbox } from '@/components/Checkbox';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, TouchableBox } from '@/components/lib/components';

export const AcknowledgementRow = ({
    checkBoxValue,
    setCheckBoxValue,
    isRowClickable,
    children,
}: {
    checkBoxValue: boolean;
    setCheckBoxValue: () => void;
    isRowClickable: boolean;
    children: React.ReactNode;
}) => {
    const commonContent = (
        <>
            <TouchableBox flexDirection="row" alignItems="center" alignSelf="stretch" onPress={setCheckBoxValue}>
                <Checkbox value={checkBoxValue} setValue={() => {}} />
                <SizedBox value={15} />
            </TouchableBox>
            <Box flex={1}>
                <Text variant="bodyMedium">{children}</Text>
            </Box>
        </>
    );

    const handlePress = () => {
        if (isRowClickable) {
            setCheckBoxValue();
        }
    };

    return (
        <>
            <Box paddingVertical="s14" paddingHorizontal="s4" flex={1} flexDirection="row" alignItems="center">
                {isRowClickable ? (
                    <TouchableBox
                        flex={1}
                        flexDirection="row"
                        alignItems="center"
                        onPress={handlePress}
                        disabled={!isRowClickable}
                    >
                        {commonContent}
                    </TouchableBox>
                ) : (
                    commonContent
                )}
            </Box>
            <LineSeparator />
        </>
    );
};
