import { useCallback, useEffect, useMemo, useState } from 'react';

import useWebKeyboard from './use-web-keyboard';

/**
 * Custom hook for handling integer input values with the number pad
 * @returns {Object} - object containing the display value, number value, and numpad functions
 */
export const useIntegerNumberPad = (initialValue?: number) => {
    const [inputValue, setInputValue] = useState('0');
    const [pristine, setPristine] = useState(true);

    useEffect(() => {
        setInputValue(initialValue ? String(initialValue) : '0');
    }, [initialValue]);

    const onNumberPress = useCallback(
        (number: number) => {
            if (pristine) {
                setPristine(false);
            }
            // return if value is already 0
            if (inputValue === '0' && number === 0) {
                return;
            }

            // replace 0 with number
            if (inputValue === '0' && number !== 0) {
                setInputValue(String(number));
                return;
            }
            setInputValue(prev => prev + String(number));
        },
        [inputValue, setInputValue, pristine]
    );

    const onDecimalPress = useCallback(() => {
        return;
    }, []);

    const onBackspacePress = useCallback(() => {
        if (pristine) {
            setPristine(false);
        }
        if (inputValue.length === 1) {
            setInputValue(String(0));
            return;
        }
        setInputValue(prev => prev.slice(0, -1));
    }, [inputValue, setInputValue, pristine]);

    const displayValue = useMemo(() => {
        // comma separated thousands
        return inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }, [inputValue]);

    useWebKeyboard({ onNumberPress, onDecimalPress, onBackspacePress });

    return {
        displayValue,
        number: Number(inputValue),
        onNumberPress,
        onDecimalPress,
        onBackspacePress,
        pristine,
        setInputValue,
    };
};
