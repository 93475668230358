import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { KeyboardAvoidingView } from 'react-native';

import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { Picker } from '@/components/Picker';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { failedKycCountSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { logger } from '@/utils/logging';
import { parse } from 'date-fns';

import { STATES, kycFlowOrderedSteps, kycFlowTypes, kycRetryFlowOrderedSteps, kycRouteNames } from '../const';
import { useKycFieldsStore } from '../hooks/use-kyc-fields';
import { useProveKycMutation } from '../hooks/use-prove-kyc';
import DatePicker from './DatePicker';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: designSystem.colors.gray8,
    },
    action: {
        width: '100%',
        backgroundColor: 'inherit',
        height: 75,
        justifyContent: 'center',
    },
});

type KYCRoutes = Pick<RootStackParamList, (typeof kycRouteNames)[keyof typeof kycRouteNames]>;
type KycFlowTypes = (typeof kycFlowTypes)[keyof typeof kycFlowTypes];

type KycStepContainerProps = {
    title: string;
    subText: string;
    isNextDisabled: boolean;
    kycFlowType?: KycFlowTypes;
    buttonText?: 'common:next' | 'kyc:verify_identity' | 'common:save';
    headerTitle?: string;
    onPressButton?: () => void;
    hasCloseIcon?: boolean;
    showPicker?: boolean;
    showDobPicker?: boolean;
    setShowPicker?: (show: boolean) => void;
    setShowDobPicker?: (show: boolean) => void;
    pickedValue?: string;
    handlePickerChange?: (pickedValue: string) => void;
    handleDatePickerChange?: (pickedValue: Date) => void;
    onFormSubmit?: () => Promise<boolean>;
    errorMessage?: string;
    onCloseIconPress?: () => void;
    handleButtonPressTracking?: () => void;
};

type KycStepContainerPropsWithKycFieldHandler = PropsWithChildren<KycStepContainerProps>;

const defaultTitle = 'Sign Up';

export const KycStepContainer: React.FC<KycStepContainerPropsWithKycFieldHandler> = ({
    children,
    headerTitle = defaultTitle,
    title,
    subText,
    isNextDisabled,
    onPressButton,
    hasCloseIcon,
    buttonText = 'common:next',
    kycFlowType = kycFlowTypes.INITIAL,
    showPicker,
    showDobPicker,
    setShowPicker,
    setShowDobPicker,
    pickedValue = '',
    handlePickerChange,
    handleDatePickerChange,
    onFormSubmit,
    errorMessage,
    onCloseIconPress,
    handleButtonPressTracking,
}) => {
    const route = useRoute<RouteProp<RootStackParamList, keyof KYCRoutes>>();
    const { t } = useTranslation(['kyc', 'common']);
    const navigation = useNavigation();

    const { data: retryCount = 0 } = useAuthUserConfig({
        select: failedKycCountSelector,
    });
    const globalSettings = useJurisdictionStore(store => store.jurisdictionSettings?.globalSettings?.featureFlags);

    const { mutate: fetchKycDataBySsn, isLoading } = useProveKycMutation();

    const values = useKycFieldsStore(state => state.values);
    const date = values.dateOfBirth ? parse(values.dateOfBirth, 'MMddyyyy', new Date()) : new Date();

    const isProveKycEnabled = globalSettings?.prove_kyc_enabled?.enabled ?? false;
    const isFirstAttemptForSSN = retryCount === 0 && route.name === kycRouteNames.SSN;
    const shouldFetchDataWithProve = isProveKycEnabled && isFirstAttemptForSSN;

    const navBarTitle = headerTitle === defaultTitle ? t('verify_identity') : headerTitle;
    const btnText = t(buttonText, { ns: ['kyc', 'common'] });

    const flowSteps = {
        [kycFlowTypes.INITIAL]: kycFlowOrderedSteps,
        [kycFlowTypes.RETRY]: kycRetryFlowOrderedSteps,
    };

    /* Get next route name on flow base on current route name (current screen) */
    const getNextRouteName = () => {
        const currentFlow = flowSteps[kycFlowType];
        const currentRoute = route.name;
        const routeNameIndex = currentFlow.findIndex(elm => elm === currentRoute);
        const nextRoute = currentFlow[routeNameIndex + 1];
        return nextRoute;
    };

    const handlePress = async () => {
        handleButtonPressTracking?.();
        try {
            const res = await onFormSubmit?.();
            if (res === false) {
                return; // return if submit response is false, don't navigate to next route
            }
        } catch (error) {
            logger.warn('[KYC form submit]', error);
            return; // return if error, don't navigate to next route
        }

        if (onPressButton) {
            onPressButton();
        } else if (shouldFetchDataWithProve) {
            fetchKycDataBySsn(values.ssn, {
                onError: () => navigation.navigate(getNextRouteName()),
            });
        } else {
            navigation.navigate(getNextRouteName());
        }
    };

    return (
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined} style={common.flex}>
            <ScrollableScreen
                screenProps={{ edges: ['bottom', 'top'] }}
                footer={
                    <>
                        {!showDobPicker ? (
                            <View>
                                <>
                                    <View style={common.alignCenter}>
                                        <Text
                                            color="red"
                                            variant="bodySmall"
                                            textAlign="center"
                                            paddingHorizontal="s16"
                                            style={common.justifyCenter}
                                        >
                                            {errorMessage}
                                        </Text>
                                    </View>
                                    <View style={styles.action}>
                                        <View style={common.paddingHorizontal}>
                                            <Button
                                                loading={isLoading}
                                                variant="rounded"
                                                type="active"
                                                label={btnText}
                                                disabled={isLoading || isNextDisabled}
                                                onPress={handlePress}
                                            />
                                        </View>
                                    </View>
                                    {showPicker && handlePickerChange ? (
                                        <Picker
                                            items={STATES}
                                            pickedValue={pickedValue}
                                            closePicker={() => setShowPicker?.(false)}
                                            handleValueChange={handlePickerChange}
                                        />
                                    ) : null}
                                </>
                            </View>
                        ) : (
                            <Box backgroundColor="gray6" flex={1} padding="s0">
                                <Box
                                    flexDirection="row"
                                    justifyContent="flex-end"
                                    paddingVertical="s12"
                                    paddingHorizontal="s12"
                                >
                                    <Text textAlign="right" onPress={() => setShowDobPicker?.(false)}>
                                        {t('common:done')}
                                    </Text>
                                </Box>
                                <Box flexDirection="row" justifyContent="center">
                                    <DatePicker
                                        modal={false}
                                        open={showDobPicker}
                                        date={date || new Date()}
                                        title={title}
                                        mode="date"
                                        theme="dark"
                                        onDateChange={(value: Date) => handleDatePickerChange?.(value)}
                                    />
                                </Box>
                            </Box>
                        )}
                    </>
                }
                screenNavBarProps={{
                    closeIconMode: hasCloseIcon ? 'close' : 'back',
                    title: hasCloseIcon ? '' : navBarTitle,
                    onClose: onCloseIconPress,
                }}
            >
                <Text lineHeight={32} fontSize={22} fontWeight="600">
                    {title}
                </Text>
                <SizedBox value={4} />
                <Text lineHeight={24} fontSize={15} color={'gray2'}>
                    {subText}
                </Text>
                <SizedBox value={24} />
                {children}
            </ScrollableScreen>
        </KeyboardAvoidingView>
    );
};
