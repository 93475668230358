import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
        <Path
            d="M0 2C0 0.895431 0.895431 0 2 0H4C8.41828 0 12 3.58172 12 8V10C12 11.1046 11.1046 12 10 12H8C3.58172 12 0 8.41828 0 4V2Z"
            fill={props.color ?? designSystem.colors.gray4}
        />
    </Svg>
);
export default SvgComponent;
