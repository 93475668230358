import { StyleSheet } from 'react-native';

import { designSystem } from '@/styles/styles';

export const styles = StyleSheet.create({
    image: {
        width: 95,
        height: 40,
        marginRight: 16,
        borderRadius: 5,
    },
    listItem: {
        borderBottomWidth: 1,
        borderBottomColor: designSystem.colors.gray7,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16,
        paddingVertical: 16,
    },
    opacity: {
        opacity: 0.5,
    },
    beforeYouDepositSheet: { maxHeight: 400 },
});
