import { useCallback, useEffect } from 'react';

import { useFocusEffect } from '@react-navigation/native';

import { useWebsocket } from '@/utils/websocket/provider';
import { AuthEvents, AuthMessageMap, PublicChannel, PublicChannelMap } from '@/utils/websocket/types';
import { Types } from 'ably';

/**
 * Subscription hook for listening from multiple eventIds from public channels
 */
export const useSubscribeMultipleEventPublic = <T extends keyof PublicChannelMap>(
    channelTemplate: PublicChannel<T>['channel'],
    eventName: PublicChannel<T>['eventName'],
    eventIds: string[],
    handler: (data: any) => void,
    enabled: boolean = true
) => {
    const { websocketService, connected } = useWebsocket();

    useFocusEffect(
        useCallback(() => {
            if (connected && enabled) {
                const listeners = eventIds.map(eventId =>
                    websocketService?.subscribePublic(channelTemplate, { eventId }, eventName, handler)
                );

                return () => {
                    listeners.forEach((listener, index) => {
                        if (listener) {
                            websocketService?.unsubscribePublic(
                                channelTemplate,
                                { eventId: eventIds[index] },
                                eventName,
                                listener
                            );
                        }
                    });
                };
            }
        }, [channelTemplate, connected, enabled, eventIds, eventName, handler, websocketService])
    );
};

/**
 * Subscription hook for listening to events from public channels
 */
export const useSubscribePublic = <T extends keyof PublicChannelMap>(
    channelTemplate: PublicChannel<T>['channel'],
    params: PublicChannel<T>['params'],
    eventName: PublicChannel<T>['eventName'],
    handler: (data: PublicChannel<T>['message']) => void,
    enabled: boolean = true,
    options?: Types.ChannelOptions
) => {
    const { websocketService, connected } = useWebsocket();

    useFocusEffect(
        useCallback(() => {
            if (connected && enabled) {
                const listener = websocketService?.subscribePublic(
                    channelTemplate,
                    params,
                    eventName,
                    handler,
                    options
                );
                return () => {
                    if (listener) {
                        websocketService?.unsubscribePublic(channelTemplate, params, eventName, listener);
                    }
                };
            }
        }, [connected, enabled, websocketService, channelTemplate, params, eventName, handler, options])
    );
};

/**
 * Subscription hook for listening to events from authenticated channels
 */
export const useSubscribeAuth = <T extends keyof AuthMessageMap>(
    eventName: AuthEvents<T>['eventName'],
    handler: (data: AuthEvents<T>['message']) => void,
    enabled: boolean = true
) => {
    const { websocketService, connected, authenticated } = useWebsocket();
    useEffect(() => {
        if (connected && enabled && authenticated) {
            websocketService?.subscribeAuth(eventName, handler);
            return () => {
                websocketService?.unsubscribeAuth(eventName);
            };
        }
    }, [authenticated, eventName, handler, connected, enabled, websocketService]);
};
