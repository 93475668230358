import React, { useCallback } from 'react';

import { useNavigation } from '@react-navigation/native';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { ProductSwitcherModalList } from '@/feature/product-switcher/ProductSwitcherModalList';
import { logger } from '@/utils/logging';
import { runAfterInteractions } from '@/utils/runAfterInteractions';

import { Product, getProductType, useJurisdictionStore } from './use-jurisdiction';

type SwitcherOptions = {
    title?: string;
    additionalDescription?: string;
    customCallback?: (product: Product) => void;
};

export const useProductSwitchModal = () => {
    const { showInfoSheet } = useAlerts();
    const products = useJurisdictionStore(state => state.jurisdictionSettings?.products ?? []);
    const availableProducts: Product[] = products.map(it => getProductType(it.uid)).filter(it => it !== Product.None);
    const navigation = useNavigation();

    const switchingAvailable = availableProducts.length > 1;

    // function that only shows the product switch modal if switching is available
    const showSwitcher = useCallback(
        ({ title, additionalDescription, customCallback }: SwitcherOptions = {}) => {
            if (switchingAvailable) {
                showInfoSheet({
                    title: title ?? 'Gameplay',
                    description: (
                        <ProductSwitcherModalList
                            additionalDescription={additionalDescription}
                            customCallback={customCallback}
                        />
                    ),
                });
            } else {
                logger.warn('Trying to show the ProductSwitcher with only one available product', availableProducts);
            }
        },
        [availableProducts, showInfoSheet, switchingAvailable]
    );

    // function that shows the product switch modal if switching is available
    // or directly navigates to the only available product
    const handleSwitchNavigation = useCallback(
        (options: SwitcherOptions = {}) => {
            if (switchingAvailable) {
                showSwitcher(options);
            } else {
                // should navigate directly to the available product
                const availableProduct = availableProducts[0] || Product.Pickem;
                if (options.customCallback) {
                    options.customCallback(availableProduct);
                    return;
                }
                navigation.navigate('ProductTransitionScreen', { product: availableProduct });
            }
        },
        [availableProducts, navigation, showSwitcher, switchingAvailable]
    );

    const changeProduct = useCallback(
        (product: Product) => {
            runAfterInteractions(() => {
                // ProductTransitionScreen updates the product value on the store when the navigation is finished
                if (product !== Product.None) {
                    navigation.navigate('ProductTransitionScreen', { product });
                } else {
                    navigation.navigate('ProductTransitionScreen', { product: Product.Pickem });
                }
            });
        },
        [navigation]
    );

    return { availableProducts, switchingAvailable, handleSwitchNavigation, showSwitcher, changeProduct };
};
