import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { InviteFriendsStackParamList } from '@/navigation/types';

import { InviteFriendsScreen } from './InviteFriendsScreen';

const Stack = createNativeStackNavigator<InviteFriendsStackParamList>(); //type should adjust

export const InviteFriendsStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="InviteFriends" component={InviteFriendsScreen} />
        </Stack.Navigator>
    );
};
