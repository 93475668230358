import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';
import {
    PlayersPicksListModal,
    PlayersPicksListModalRef,
} from '@/feature/betslip-pickem/screens/PlayersPicksListModal';

type PicksList = {
    showPicksListModal: () => void;
};

const PicksListContext = React.createContext<PicksList>({
    showPicksListModal: () => {},
});
/**
 * Provides access to the Modal used to display the list of players in the pickslip
 */
export const PlayersPicksListProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<PlayersPicksListModalRef>(null);

    const showPicksListModal = useCallback(() => {
        modalRef.current?.show();
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <PicksListContext.Provider value={{ showPicksListModal }}>
                {children}
                <PlayersPicksListModal ref={modalRef} />
            </PicksListContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const usePicksList = () => {
    return useContext(PicksListContext);
};
