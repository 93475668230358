import { Linking } from 'react-native';

import { logger } from '@/utils/logging';

/**
 * Asynchronously attempts to open the phone dialer with the specified phone number.
 *
 * @param {string} phoneNumber - A phone number string that may contain hyphens (e.g., "1-XXX-XXX-XXXX" or "XXX-XXX-XXXX").
 * @returns {Promise<void>} Resolves when the operation is complete. If the URL scheme is unsupported or an error occurs, it logs the issue to the console.
 * @note If you're testing on a simulator (iOS) or emulator (Android), they might not support the `tel:` URL scheme.
 */
export const openDialerWithNumber = (phoneNumber: string) => {
    const parsedPhoneNumber = phoneNumber.replace(/-/g, '');
    const url = `tel:${parsedPhoneNumber}`;

    try {
        Linking.openURL(url);
    } catch (err) {
        logger.error(`Unable to open dialer for the following phone number: ${phoneNumber}. With error: ${err}`);
    }
};
