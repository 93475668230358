import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const IconCircledClose = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <Path
            fill={props.color ?? designSystem.colors.gray3}
            fillRule="evenodd"
            d="M18.333 10a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0ZM7.26 6.078 10 8.818l2.74-2.74 1.18 1.178-2.742 2.741 2.748 2.748-1.179 1.178L10 11.176l-2.747 2.747-1.179-1.178 2.747-2.748-2.74-2.74 1.178-1.18Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default IconCircledClose;
