import { STATES, State } from '../../const';

/**
 * Find a property in the state based on a given key and return another property's value.
 *
 * @param {keyof State} sourceProp - The source property in the state to match against. 'label' or 'value'.
 * @param {keyof State} targetProp - The target property in the state to return the value from. 'label' or 'value'.
 * @returns {(key: string) => string} A function that takes a key and returns the corresponding target property's value.
 */
const findStatePropertyByKey = (sourceProp: keyof State, targetProp: keyof State) => (key: string) => {
    const foundState = STATES.find(state => state[sourceProp] === key);
    return foundState ? foundState[targetProp] : '';
};
export const getStateCode = findStatePropertyByKey('label', 'value');
export const getStateName = findStatePropertyByKey('value', 'label');
