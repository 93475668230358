import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { EntryDetails } from '@/api/entries/types/types';
import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Row } from '@/components/lib/components';
import { trackRUMAction } from '@/data/datadog';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useRateApp } from '@/feature/rate-app/hooks/use-rate-app';
import { common, designSystem } from '@/styles/styles';

import { useBetslipActions } from '../hooks/use-betslip-actions';

type Props = {
    entry: EntryDetails;
};

export const SuccessButtons = ({ entry }: Props) => {
    const navigation = useNavigation();
    const { maybeShowAppRateAfterEntryPlaced } = useRateApp();
    const { removeAllPicks } = useBetslipActions();

    const { t } = useTranslation(['betslip_pickem', 'common']);

    return (
        <>
            <SizedBox value={16} />
            <Row style={[common.spaceBetweenRow]}>
                <Button
                    label={t('reuse_lineup')}
                    variant="rounded"
                    type="active"
                    style={common.flex}
                    onPress={() => {
                        BetrAnalytics.trackEvent(AnalyticsEvent.REUSE_LINEUP);
                        trackRUMAction('re-use_lineup');
                        navigation.goBack();
                    }}
                />
                <SizedBox value={12} />
                <Button
                    label={t('share_entry')}
                    variant="rounded"
                    type="active"
                    style={common.flex}
                    onPress={() => {
                        trackRUMAction('share-entry');
                        BetrAnalytics.trackEvent(AnalyticsEvent.SHARE_ENTRY, { screen: 'entry_submitted' });
                        entry?.id &&
                            navigation.navigate('EntryShare', {
                                id: entry?.id,
                            });
                    }}
                />
            </Row>
            <SizedBox value={16} />
            <Button
                label={t('common:done')}
                variant="rounded"
                type="secondary"
                style={{ borderColor: designSystem.colors.gray6 }}
                onPress={() => {
                    trackRUMAction('entry-confirmation-done-pressed');
                    removeAllPicks();
                    navigation.navigate('PickemHome', { screen: 'Lobby', params: { screen: 'LobbyScreen' } });
                    maybeShowAppRateAfterEntryPlaced({ delay: 2000 });
                }}
            />
        </>
    );
};
