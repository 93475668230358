import React, { useState } from 'react';
import { View } from 'react-native';

import { Screen } from '@/components/ScreenComponent';
import { common } from '@/styles/styles';

import { SearchInput } from '../components/SearchInput';

export const BettingSearchScreen = () => {
    const [searchValue, setSearchValue] = useState('');

    const onQueryChange = (newQuery: string) => setSearchValue(newQuery);

    return (
        <Screen>
            <View style={[common.paddingVertical]}>
                <SearchInput query={searchValue} onQueryChange={onQueryChange} />
            </View>
        </Screen>
    );
};
