import { EventAttribute, EventAttributesType, PlayerAttribute, PlayerAttributesType } from '@/types/api.generated';

export const findPlayerAttribute = (
    player?: { attributes?: PlayerAttribute[] },
    key?: PlayerAttributesType
): string | undefined => {
    return player?.attributes?.find(attr => attr?.key === key)?.value;
};

export const findEventAttribute = (
    event?: { attributes?: EventAttribute[] },
    key?: EventAttributesType
): string | undefined => {
    return event?.attributes?.find(attr => attr?.key === key)?.value;
};
