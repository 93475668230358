import React, { useMemo } from 'react';
import { FlatList } from 'react-native';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import { GameOption } from '@/feature/product-switcher/GameOption';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useProductSwitchModal } from '@/hooks/use-product-switch';
import { common } from '@/styles/styles';

const ItemSeparatorComponent = () => <SizedBox value={12} />;

const GAMEPLAY_OPTIONS = [Product.Pickem, Product.Sportsbook] as const;

export const ProductSwitcherModalList = ({
    additionalDescription = '',
    customCallback,
}: {
    customCallback?: (product: Product) => void;
    additionalDescription?: string;
}) => {
    const { availableProducts, changeProduct } = useProductSwitchModal();
    const { dismissAll } = useModals();
    const selectedProduct = useJurisdictionStore(state => state.product);

    const products = useMemo(() => GAMEPLAY_OPTIONS.filter(it => availableProducts.includes(it)), [availableProducts]);

    const ListHeaderComponent = useMemo(
        () => (
            <>
                {additionalDescription ? (
                    <Box alignItems={'center'} paddingHorizontal={'s24'}>
                        <Text textAlign={'center'} variant={'bodyMedium'} color={'gray2'}>
                            {additionalDescription}
                        </Text>
                    </Box>
                ) : null}
                <SizedBox value={24} />
            </>
        ),
        [additionalDescription]
    );

    return (
        <FlatList
            data={products}
            ListHeaderComponent={ListHeaderComponent}
            ItemSeparatorComponent={ItemSeparatorComponent}
            renderItem={({ item }) => (
                <GameOption
                    product={item}
                    selected={item === selectedProduct}
                    onPress={() => {
                        dismissAll();
                        if (customCallback) {
                            customCallback(item);
                            return;
                        }
                        changeProduct(item);
                    }}
                />
            )}
            style={common.noGrow}
        />
    );
};
