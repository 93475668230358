import React, { ReactNode } from 'react';
import { TouchableOpacity } from 'react-native';

import TrashIcon from '@/assets/icons/trash';
import { Box } from '@/components/lib/components';

import { useSelection } from '../../hooks/betslip-hooks';
import { BetSlipMarketName } from '../BetSlipMarketName';
import { CardInfoTemplate } from '../CardInfoTemplate';
import { EventName } from '../EventName';
import { OptionName } from '../OptionName';

type SinglesCardInfoProps = {
    selectionId: string;
    onDelete: () => void;
    oddsColumn: ReactNode;
    isSuspended?: boolean;
    isBetSlipSubmitting?: boolean;
    borderColor?: string;
};

export const SinglesCardInfo = ({
    selectionId,
    onDelete,
    oddsColumn,
    isSuspended = false,
    isBetSlipSubmitting = false,
    borderColor,
}: SinglesCardInfoProps) => {
    const selection = useSelection(selectionId);

    return (
        <CardInfoTemplate
            headerLeft={<EventName selectionId={selectionId} isSuspended={isSuspended} borderColor={borderColor} />}
            headerRight={
                <TouchableOpacity hitSlop={8} onPress={onDelete} disabled={isBetSlipSubmitting}>
                    <TrashIcon />
                </TouchableOpacity>
            }
            descriptionLeft={
                <>
                    <OptionName
                        variant="titleMedium"
                        optionId={selection?.optionId}
                        color={isSuspended ? 'gray4' : 'gray1'}
                    />
                    <Box mb="s12">
                        <BetSlipMarketName
                            variant="bodySmall"
                            eventId={selection.eventId}
                            marketId={selection.marketId}
                            color={isSuspended ? 'gray4' : 'gray2'}
                        />
                    </Box>
                </>
            }
            descriptionRight={oddsColumn}
        />
    );
};
