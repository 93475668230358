export const textVariants = {
    displayLarge: {
        fontSize: 92,
        lineHeight: 104,
        fontWeight: '600',
    },
    displayMedium: {
        fontSize: 38,
        lineHeight: 44,
        fontWeight: '800',
    },
    displaySmall: {
        fontSize: 34,
        lineHeight: 44,
        fontWeight: '600',
    },
    headlineLarge: {
        fontSize: 28,
        lineHeight: 36,
        fontWeight: '600',
    },
    headlineMedium: {
        fontSize: 22,
        lineHeight: 32,
        fontWeight: '700',
        letterSpacing: -0.26,
    },
    headlineSmall: {
        fontSize: 20,
        lineHeight: 28,
        fontWeight: '600',
    },
    titleLarge: {
        fontSize: 17,
        lineHeight: 24,
        fontWeight: '600',
    },
    titleMedium: {
        fontSize: 15,
        lineHeight: 24,
        fontWeight: '600',
        letterSpacing: -0.23,
    },
    titleSmall: {
        fontSize: 13,
        lineHeight: 20,
        fontWeight: '600',
    },
    bodyLarge: {
        fontSize: 17,
        lineHeight: 25,
        fontWeight: '400',
    },
    bodyMedium: {
        fontSize: 15,
        lineHeight: 24,
        fontWeight: '400',
        letterSpacing: -0.23,
    },
    bodySmall: {
        fontSize: 13,
        lineHeight: 20,
        fontWeight: '400',
    },
    bodySmaller: {
        fontSize: 12,
        lineHeight: 20,
        fontWeight: '400',
    },
    labelLarge: {
        fontSize: 13,
        lineHeight: 20,
        fontWeight: '500',
    },
    labelMedium: {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: '500',
    },
    labelSmall: {
        fontSize: 11,
        lineHeight: 16,
        fontWeight: '500',
    },
    buttonLabel: {
        fontSize: 15,
        lineHeight: 24,
        fontWeight: '700',
    },
    iconFallback: {
        fontSize: 17,
        lineHeight: 17,
    },
    teamLogoFallback: {
        fontSize: 24,
        lineHeight: 40,
    },
} as const;
