import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetSummarySection, BetSummarySectionProps } from '@/components/BetSummarySection';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { Bet } from '../../hooks/types';
import { getMultiplier } from '../../utils/bet-summary-section-utils';
import { useBetDetailsVoidedBetSummary, useSharedBetDetailsBetSummary } from './utils';

/**
 * Helper Hooks
 */
const useOpenMultiBetSummary = (bet: Bet): BetSummarySectionProps => {
    return {
        ...useSharedBetDetailsBetSummary(bet),
        ...getMultiplier(bet),
        body3Text: toLocaleCurrency(bet.potential_payout),
    };
};

const useSettledMultiBetSummary = (bet: Bet): BetSummarySectionProps => {
    const { t } = useTranslation('bets');
    return {
        ...useSharedBetDetailsBetSummary(bet),
        ...getMultiplier(bet),
        header3Text: t('paid'),
        body3Text: toLocaleCurrency(bet.amount_won),
        header3TextColor: bet.amount_won > 0 ? 'green' : 'gray2',
        body3TextColor: bet.amount_won > 0 ? 'green' : 'gray2',
    };
};

/**
 * Components
 */
const OpenMultiBetSummary = ({ bet }: { bet: Bet }) => <BetSummarySection {...useOpenMultiBetSummary(bet)} />;

const VoidedMultiBetSummary = ({ bet }: { bet: Bet }) => <BetSummarySection {...useBetDetailsVoidedBetSummary(bet)} />;

const SettledMultiBetSummary = ({ bet }: { bet: Bet }) => <BetSummarySection {...useSettledMultiBetSummary(bet)} />;

// Handles both Script and Combo bet types
export const MultiBetSummarySection = ({ bet }: { bet: Bet }) => {
    switch (bet.result) {
        case 'PENDING':
            return <OpenMultiBetSummary bet={bet} />;
        case 'WON':
        case 'LOST':
        case 'CASHEDOUT':
            return <SettledMultiBetSummary bet={bet} />;
        case 'CANCELLED':
        case 'PUSHED':
            return <VoidedMultiBetSummary bet={bet} />;
        default:
            return <OpenMultiBetSummary bet={bet} />;
    }
};
