import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import {
    DueDiligence,
    DueDiligenceReasons,
    useAuthUserConfig,
    userDueDiligenceReasonSelector,
    userDueDiligenceSelector,
} from '@/hooks/use-auth-user-config';
import { RootStackParamList } from '@/navigation/types';
import { LoadingScreen } from '@/screens/LoadingScreen';

import { ManageDebitCardContent } from '../components/manage-debit-cards/ManageDebitCardContent';
import { useProcessCardRemoval } from '../hooks/use-process-card-removal';

type ManageExpiredDebitCardDetail = NativeStackScreenProps<RootStackParamList, 'ManageExpiredDebitCardDetail'>;

export const ManageExpiredDebitCardDetail = ({ route }: ManageExpiredDebitCardDetail) => {
    const { selectedMethod } = route.params;

    const { t } = useTranslation('manage_cards');

    const { data: userDueDiligence } = useAuthUserConfig({ select: userDueDiligenceSelector });
    const { data: userDueDiligenceReason } = useAuthUserConfig({ select: userDueDiligenceReasonSelector });
    const hasFailedEDD =
        userDueDiligence === DueDiligence.EDD_FAILED && userDueDiligenceReason === DueDiligenceReasons.PASSIVE;

    const { showInfoSheet } = useAlerts();
    const { handleRemovalProcess, isProcessingCardRemoval } = useProcessCardRemoval();

    const showSecondaryButton = useMemo(() => {
        return selectedMethod.is_removable && !hasFailedEDD && selectedMethod.closed_loop_remain < 500;
    }, [selectedMethod, hasFailedEDD]);

    const handlePressUpdateCard = () => {
        // TODO: Navigate to the update card form
    };

    const handlePressRemoveCard = () => {
        showInfoSheet({
            title: t('removing_a_debit_card'),
            description: t('debit_card_removal_steps_info'),
            buttonLabel: t('confirm'),
            secondaryLabel: t('close'),
            handlePress: () => handleRemovalProcess(selectedMethod.payment_type_id),
        });
    };

    if (isProcessingCardRemoval) {
        return <LoadingScreen />;
    }

    return (
        <Screen edges={['top', 'bottom']}>
            <ScreenNavBar />
            <ManageDebitCardContent
                card={selectedMethod}
                primaryButtonLabel={t('update_card')}
                handlePrimaryButtonPress={handlePressUpdateCard}
                showSecondaryButton={showSecondaryButton}
                secondaryButtonLabel={t('remove_card')}
                handleSecondaryButtonPress={handlePressRemoveCard}
            />
        </Screen>
    );
};
