import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, Switch, View } from 'react-native';
import { NativeModules } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Easing } from 'react-native-reanimated';

import Clipboard from '@react-native-clipboard/clipboard';
import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { TextInput } from '@/components/TextInput';
import { Tooltip } from '@/components/Tooltip';
import { Box, Column, Row } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import {
    activateRemoteConfig,
    getAllRemoteConfigs,
    getRemoteConfigByKey,
    refetchRemoteConfigs,
} from '@/feature/analytics/hooks/use-firebase-remote-config';
import { Flags, useLocalFlagsStore } from '@/feature/devsettings/hooks/use-dev-settings';
import { useLinkingStore } from '@/feature/entry-share/hooks/use-linking';
import { kycRouteNames } from '@/feature/kyc/const';
import {
    PUSH_PRIMER_IGNORE_PERIOD,
    PUSH_PRIMER_SKIP_COUNT,
} from '@/feature/push-primer/hooks/use-push-primer-eligibility';
import { useCalendarSheet } from '@/feature/transactions/components/CalendarSheetProvider';
import { useActiveWalletStore } from '@/hooks/use-active-wallet';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useShowOneTime } from '@/hooks/use-show-one-time';
import { user } from '@/hooks/use-user';
import { useBonusEngineClient, useGraphqlClient } from '@/providers';
import { common, designSystem } from '@/styles/styles';
import { remove } from '@/utils/async-storage';
import { isWeb } from '@/utils/constants-platform-specific';
import { FloatingModalProvider, useFloatingModal } from '@/utils/floatingModal/FloatingModalProvider';
import { logs } from '@/utils/logging';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList } from '@shopify/flash-list';

import { useKycFieldsStore } from '../../kyc/hooks/use-kyc-fields';
import { PaymentMethodDebugFlags } from '../components/PaymentMethodDebugFlags';

const style = StyleSheet.create({
    root: {
        flex: 1,
        backgroundColor: designSystem.colors.gray8,
    },
    settingRow: {
        flexDirection: 'row',
        marginVertical: 8,
        alignItems: 'center',
    },
    row: {
        padding: 4,
        flexDirection: 'column',
    },
    label: {
        marginEnd: 4,
        textTransform: 'uppercase',
    },
    debug: {
        color: '#000',
        backgroundColor: '#DDD',
    },
    info: {
        color: '#000',
        backgroundColor: '#BBB',
    },
    warn: {
        color: '#000',
        backgroundColor: '#C60',
    },
    error: {
        color: '#000',
        backgroundColor: '#D44',
    },
    expandBtn: {
        alignSelf: 'flex-end',
    },
    textFromExpandable: { flex: 1 },
    modalText: { color: 'green', textAlign: 'center' },
});

const TextCollapsable = ({ children }: PropsWithChildren) => {
    const [noOfLines, setNoOfLines] = useState<number | undefined>(10);

    return (
        <View>
            <TouchableOpacity
                onPress={() => {
                    Clipboard.setString(children as string);
                }}
            >
                <Text numberOfLines={noOfLines}>{children}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setNoOfLines(undefined)}>
                <Text style={style.expandBtn} fontWeight="bold">
                    EXPAND
                </Text>
            </TouchableOpacity>
            <SizedBox value={20} />
        </View>
    );
};

const easingEnum = {
    EASE: Easing.ease,
    LINEAR: Easing.linear,
    BOUNCE: Easing.bounce,
    CIRCLE: Easing.circle,
    QUAD: Easing.quad,
    CUBIC: Easing.cubic,
    EXP: Easing.exp,
    SIN: Easing.sin,
} as const;

export const DeveloperScreen = () => {
    const { navigate, reset } = useNavigation();
    const {
        localFlags,
        actions: { resetFlags },
    } = useLocalFlagsStore();
    const resetKycValues = useKycFieldsStore(state => state.reset); // TODO: Remove this line when KYC flow is taken out of developer mode
    const { showInfoSheet, showSelectionSheet, showToast } = useAlerts();
    const animationsModalRef = useRef<BottomSheetModal>(null);
    const oneLinkData = useLinkingStore(state => state.oneLinkData);
    const clearOneLinkData = useLinkingStore(state => state.setOneLinkData);
    const [easing, setEasing] = useState<keyof typeof easingEnum>('EASE');
    const setProduct = useJurisdictionStore(state => state.actions.setProduct);
    const setJurisdictionAndUpdateSettings = useJurisdictionStore(
        state => state.actions.setJurisdictionAndUpdateSettings
    );
    const { apiUrl, setApiUrl } = useBonusEngineClient();
    const graphqlClient = useGraphqlClient();
    const { showCalendarSheetModal } = useCalendarSheet();

    const [firebaseConfigKeys, setFirebaseConfigKeys] = useState(Object.keys(getAllRemoteConfigs() ?? {}));

    const [fantasyApiUrl, setFantasyApiUrl] = useState('http://localhost:8080/graphql');
    const [bonusEngineApiUrl, setBonusEngineApiUrl] = useState(apiUrl);

    return (
        <Screen>
            <FloatingModalProvider>
                <ScreenNavBar title="Developer screen" />
                <FlashList
                    data={logs}
                    estimatedItemSize={20}
                    ListHeaderComponentStyle={common.zIndex1}
                    ListHeaderComponent={
                        <View>
                            <NewArchEnabled />
                            <ExpandableComponent title="Onboarding Flow">
                                <Box>
                                    <Button
                                        label="Simulate KYC Success"
                                        onPress={() => navigate(kycRouteNames.VERIFY_IDENTITY)}
                                    />
                                </Box>
                            </ExpandableComponent>
                            <SizedBox value={8} />
                            <ExpandableComponent title="Promo code">
                                <Box>
                                    <Text>{`PROMO CODE: ${oneLinkData?.data.deep_link_sub1}`}</Text>
                                    <Button
                                        label="Clear promo code"
                                        onPress={() => {
                                            clearOneLinkData(undefined);
                                        }}
                                    />
                                </Box>
                            </ExpandableComponent>
                            <SizedBox value={8} />
                            <ExpandableComponent title="API Settings">
                                <Column p={'s12'} m={'s8'} borderRadius={'r12'} borderWidth={1} borderColor={'gray5'}>
                                    <Text>Connect the Fantasy app to a local running server</Text>
                                    <TextInput
                                        value={fantasyApiUrl}
                                        label={'Graphql API'}
                                        onChangeText={e => {
                                            setFantasyApiUrl(e);
                                        }}
                                    />
                                    <SizedBox value={8} />
                                    <Button
                                        label={'Connect'}
                                        onPress={() => {
                                            graphqlClient.recreateGraphqlClient({ url: fantasyApiUrl });
                                        }}
                                    />
                                </Column>
                                <Column p={'s12'} m={'s8'} borderRadius={'r12'} borderWidth={1} borderColor={'gray5'}>
                                    <Text>Connect the Fantasy app to a local running Bonuse Engine server</Text>
                                    <TextInput
                                        value={bonusEngineApiUrl}
                                        label={'Bonus Engine API'}
                                        onChangeText={e => {
                                            setBonusEngineApiUrl(e);
                                        }}
                                    />
                                    <SizedBox value={8} />
                                    <Button
                                        label={'Connect'}
                                        onPress={() => {
                                            setApiUrl(bonusEngineApiUrl);
                                        }}
                                    />
                                </Column>
                            </ExpandableComponent>
                            <SizedBox value={8} />
                            <ExpandableComponent title="Dev Settings">
                                {Object.keys(localFlags).map((id: string) => (
                                    <SettingSwitch key={id} id={id as Flags} />
                                ))}
                                <Button label={'reset to default flags'} onPress={resetFlags} />
                                <SizedBox value={8} />
                                <Button
                                    label={'Native sportsbook'}
                                    onPress={() => {
                                        setProduct(Product.Sportsbook);
                                        setJurisdictionAndUpdateSettings('OH', 'manual');
                                        useActiveWalletStore.getState().resetActiveWallet();
                                        reset({
                                            index: 0,
                                            routes: [
                                                {
                                                    name: 'SBKHome',
                                                    params: { screen: 'Lobby' },
                                                },
                                            ],
                                        });
                                    }}
                                />
                                <SizedBox value={8} />
                                <PaymentMethodDebugFlags />
                            </ExpandableComponent>
                            <SizedBox value={8} />
                            <ExpandableComponent title="Access Tokens">
                                <TouchableOpacity
                                    onPress={() => {
                                        Clipboard.setString(user.profile.sub);
                                        showToast({ message: 'User ID copied to clipboard.' });
                                    }}
                                >
                                    <Text padding={'s16'}>{`UserId:${user.profile.sub}`}</Text>
                                </TouchableOpacity>
                                <Button
                                    label={'Invalidate access token'}
                                    onPress={() => {
                                        if (user.session?.access_token) {
                                            user.session.access_token = 'invalid';
                                        }
                                        user._saveSession(user.session);
                                    }}
                                />
                                <SizedBox value={8} />
                                <Button
                                    label={'Invalidate refresh token'}
                                    onPress={() => {
                                        if (user.session?.refresh_token) {
                                            user.session.refresh_token = 'invalid';
                                        }
                                        user._saveSession(user.session);
                                    }}
                                />
                                <Button
                                    label="Start KYC Flow"
                                    onPress={() => {
                                        resetKycValues();
                                        navigate('KycSsn'); // Navigate to first step of KYC Flow (In the meantime is Ssn)
                                    }}
                                />
                            </ExpandableComponent>
                            <SizedBox value={8} />
                            <ExpandableComponent title="Firebase Remote Configs">
                                <Column>
                                    {firebaseConfigKeys.map(key => {
                                        return (
                                            <Row key={key} justifyContent={'space-between'} p={'s8'}>
                                                <Text color={'brandPrimary'}>{key}</Text>
                                                <Text variant={'bodyLarge'}>
                                                    {getRemoteConfigByKey(key).asString()}
                                                </Text>
                                            </Row>
                                        );
                                    })}
                                    <Button
                                        label={'Refetch Firebase Configs'}
                                        onPress={() => {
                                            refetchRemoteConfigs()
                                                .then(() => activateRemoteConfig())
                                                .then(() => {
                                                    showToast({ message: 'Firebase settings refetched' });
                                                    setFirebaseConfigKeys(Object.keys(getAllRemoteConfigs()));
                                                })
                                                .catch(() => {
                                                    showToast({
                                                        message: "Couldn't refetch. Too many retries ?",
                                                        toastType: 'warning',
                                                    });
                                                });
                                        }}
                                    />
                                </Column>
                            </ExpandableComponent>
                            <SizedBox value={8} />
                            <ExpandableComponent title="Push Primer Options">
                                <Button
                                    label="Reset PushPrimer local storage"
                                    onPress={async () => {
                                        await remove(PUSH_PRIMER_SKIP_COUNT);
                                        await remove(PUSH_PRIMER_IGNORE_PERIOD);
                                    }}
                                />
                                <Button label="Go to PushPrimer screen" onPress={() => navigate('PushPrimer')} />
                            </ExpandableComponent>
                            <ExpandableComponent title="Test modals">
                                <Button
                                    label={'Info Sheet'}
                                    onPress={() => {
                                        showInfoSheet({
                                            title: 'Title',
                                            description: 'Description\n Description.',
                                            buttonLabel: 'OK',
                                            handlePress: () => {
                                                // nothing here but,
                                                // dismiss will still be called on button press
                                            },
                                        });
                                    }}
                                />
                                <Button
                                    label={'Toast notification'}
                                    onPress={() => {
                                        showToast({ message: 'Player added' });
                                    }}
                                />
                                <Button
                                    label={'Info Sheet w/ secondary button'}
                                    onPress={() => {
                                        showInfoSheet({
                                            title: 'Title',
                                            description: 'Description\n Description.',
                                            buttonLabel: 'OK',
                                            secondaryLabel: 'Secondary Btn',
                                            handlePress: () => {
                                                showInfoSheet({
                                                    title: 'Inner Title',
                                                    description: 'Inner description',
                                                    buttonLabel: 'OK',
                                                    onDismiss: () => {
                                                        showToast({ message: 'Dismissed inner modal!' });
                                                    },
                                                });
                                            },
                                            onDismiss: () => {
                                                showToast({ message: 'Dismissed outer modal!' });
                                            },
                                        });
                                    }}
                                />
                                <Button
                                    label={'Info Error Sheet'}
                                    onPress={() => {
                                        showInfoSheet({
                                            title: 'Title',
                                            description: 'Description\n Description.',
                                            buttonLabel: 'OK',
                                            showCancel: true,
                                            errorStyling: true,
                                        });
                                    }}
                                />
                                <Button
                                    label={'Selection Sheet'}
                                    onPress={() => {
                                        const items = [
                                            { id: '1', label: 'Item 1', extra: 'extra' },
                                            { id: '2', label: 'Item 2' },
                                            { id: '3', label: 'Item 3' },
                                        ];
                                        showSelectionSheet({
                                            items,
                                            title: 'Selection title',
                                            selection: items[0],
                                            onItemSelected: i => {
                                                console.log('selected', i);
                                            },
                                        });
                                    }}
                                />
                                <Button
                                    label={'Calendar Sheet'}
                                    onPress={() => {
                                        showCalendarSheetModal({
                                            closeIconMode: 'close',
                                            showMonthYearHeader: isWeb,
                                        });
                                    }}
                                />
                                <NonDismissibleModalSection />
                                <ExpandableComponent title={" Test Modals' animations"}>
                                    {Object.keys(easingEnum).map(key => {
                                        return (
                                            <Box mb={'s10'}>
                                                <Button
                                                    key={key}
                                                    label={key}
                                                    onPress={() => {
                                                        setEasing(key as keyof typeof easingEnum);
                                                        animationsModalRef.current?.present();
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                                    <Modal
                                        id="animations-modal"
                                        modalRef={animationsModalRef}
                                        animation={{
                                            easing: easingEnum[easing],
                                            duration: 150,
                                        }}
                                    >
                                        <Box px={'s48'} py={'s96'}>
                                            <Text variant="buttonLabel"> {easing} </Text>
                                        </Box>
                                    </Modal>
                                </ExpandableComponent>
                            </ExpandableComponent>
                            <ExpandableComponent title="Crash app">
                                <Box gap={'s8'}>
                                    <Button
                                        label="Crash native main thread"
                                        onPress={() =>
                                            NativeModules.PerfKiller.crashNativeMainThread(
                                                'dev - crash native main thread'
                                            )
                                        }
                                    />
                                    <Button
                                        label="Crash javascript thread"
                                        onPress={() => {
                                            throw new Error('dev - crash javascript thread');
                                        }}
                                    />
                                </Box>
                            </ExpandableComponent>
                            <Row style={[common.flex, common.justifySpaceBetween]} padding={'s14'}>
                                <Text variant={'headlineSmall'}>Test Tooltip</Text>
                                <Tooltip
                                    tooltipText="It's a tooltip. It's a tooltip. It's a tooltip.It's a tooltip. It's a tooltip.It's a tooltip."
                                    direction="bottom"
                                >
                                    <Box>
                                        <Text>Click Me</Text>
                                    </Box>
                                </Tooltip>
                            </Row>
                            <SizedBox value={8} />
                            <ShowFloatingModal />
                            <SizedBox value={8} />
                            <OneTimeTooltipButton />
                            <SizedBox value={8} />
                        </View>
                    }
                    renderItem={({ item }) => {
                        return (
                            <View style={style.row}>
                                <View style={[common.row, common.spaceBetweenRow, style.label, style[item.type]]}>
                                    <Text style={[style.label, style[item.type]]}>{item.msg}</Text>
                                    <Text style={style[item.type]}>[{item.timestamp}]</Text>
                                </View>
                                <TextCollapsable>{item.details}</TextCollapsable>
                            </View>
                        );
                    }}
                />
            </FloatingModalProvider>
        </Screen>
    );
};

const ShowFloatingModal = () => {
    const { openModal } = useFloatingModal();

    return (
        <Box zIndex={-2} gap={'s10'}>
            <Button
                label={'Open Floating Modal NFL'}
                onPress={() => {
                    openModal({
                        url: 'https://gsm-widgets-uat.betstream.betgenius.com/multisportgametracker?productName=gt-sidebyside&fixtureId=11061059',
                        minimizedContentScale: 0.6,
                        maximizedContentScale: 0.9,
                        minimizedContentHeight: 180,
                        maximizedContentHeight: 280,
                    });
                }}
            />
            <Button
                label={'Open Floating Modal NBA'}
                onPress={() => {
                    openModal({
                        url: 'https://gsm-widgets-uat.betstream.betgenius.com/multisportgametracker?productName=gt-sidebyside&fixtureId=11351604',
                        minimizedContentScale: 0.6,
                        maximizedContentScale: 0.9,
                        minimizedContentHeight: 180,
                        maximizedContentHeight: 280,
                    });
                }}
            />
        </Box>
    );
};

const OneTimeTooltipButton = () => {
    const { show: showOneTimeTooltip, handleInteraction } = useShowOneTime('SBK_DEVScreen_GameTrackerTooltip');

    useEffect(() => {
        return () => {
            handleInteraction();
        };
    }, [handleInteraction]);

    return (
        <Box zIndex={-2}>
            {showOneTimeTooltip ? (
                <>
                    <Tooltip
                        tooltipText="This is a one time show tooltip. After you close it or go away from this screen it won't be seen again."
                        tooltipContentPosition={{ right: 30, top: 32 }}
                        initialState="visible"
                        performSideEffectOnClose={handleInteraction}
                    >
                        <Button label={'One Time Show Button'} onPress={handleInteraction} />
                    </Tooltip>
                </>
            ) : null}
        </Box>
    );
};

const ExpandableComponent = (props: PropsWithChildren & { title: string }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <Column padding={'s4'}>
            <TouchableOpacity
                onPress={() => {
                    setExpanded(!expanded);
                }}
            >
                <Row alignItems={'center'}>
                    <SizedBox value={8} />
                    <Text variant={'headlineSmall'} style={style.textFromExpandable}>
                        {props.title}
                    </Text>
                </Row>
            </TouchableOpacity>
            {expanded && props.children}
        </Column>
    );
};

const SettingSwitch: React.FC<{
    id: Flags;
}> = ({ id }) => {
    const {
        actions: { changeFlag },
        localFlags,
    } = useLocalFlagsStore();
    const { showInfoSheet } = useAlerts();
    return (
        <View style={style.settingRow}>
            <Switch
                ios_backgroundColor={designSystem.colors.gray5}
                onValueChange={() => {
                    changeFlag(id);
                }}
                value={localFlags[id].enabled}
            />
            <SizedBox value={8} />
            <TouchableOpacity
                onPress={() => {
                    showInfoSheet({
                        title: 'Description',
                        description: localFlags[id].description,
                        buttonLabel: 'OK',
                    });
                }}
            >
                <Text>{localFlags[id].label}</Text>
            </TouchableOpacity>
        </View>
    );
};

const NonDismissibleModalSection = () => {
    const sheetRef = useRef<BottomSheetModal>(null);
    const data = useMemo(
        () =>
            Array(10)
                .fill(0)
                .map((_, index) => index),
        []
    );

    return (
        <>
            <Button
                label={'Not dismissible by drag/overlay press'}
                onPress={() => {
                    sheetRef.current?.present();
                }}
            />
            <Modal modalRef={sheetRef} id={'test modal'} dismissible={false}>
                {data.map(item => (
                    <View key={item}>
                        <Text style={style.modalText}>{1 + item}</Text>
                    </View>
                ))}
                <Button label="Close" onPress={() => sheetRef.current?.dismiss()} />
            </Modal>
        </>
    );
};

const NewArchEnabled = () => {
    // Fabric - new arch
    // Paper - old arch
    const uiManager = global?.nativeFabricUIManager ? 'Fabric' : 'Paper';

    return (
        <Row paddingHorizontal={'s14'} justifyContent={'space-between'}>
            <Text variant={'headlineSmall'}>UI Manager</Text>
            <Text variant={'headlineSmall'}>{uiManager}</Text>
        </Row>
    );
};
