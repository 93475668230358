import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { useGetTradingLimitsAndEntryRulesQuery } from '@/api/limits-and-rules/query.generated';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useLocalFlagsStore } from '@/feature/devsettings/hooks/use-dev-settings';
import { useAppResumeEffect } from '@/hooks/use-resume';
import { common, designSystem } from '@/styles/styles';
import { EntryRulesOutput, EntryTradingLimitOutput, GameMode, GameType } from '@/types/api.generated';
import { isWeb } from '@/utils/constants-platform-specific';
import { subscribe } from 'valtio';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

import { useJurisdictionStore } from './use-jurisdiction';
import { user } from './use-user';

type State = {
    multipliers: Record<EntryTradingLimitOutput['numberOfPicks'], TradingLimit>;
    allEntryRules: EntryRulesOutput[];
    hydrated: boolean;
    applicantId: string;
    actions: {
        setMultipliers: (multipliers: Record<EntryTradingLimitOutput['numberOfPicks'], TradingLimit>) => void;
        setAllEntryRules: (entryRules: EntryRulesOutput[]) => void;
        setHydrated: () => void;
        setApplicantId: (applicantId: string) => void;
    };
};

type TradingLimit = Omit<EntryTradingLimitOutput, 'betrBucksMultiplier' | 'gameMode'>;
type Multipliers = Record<EntryTradingLimitOutput['numberOfPicks'], TradingLimit>;

const defaultEntryRules = {
    minNumberOfPicks: 0,
    maxNumberOfPicks: 0,
    minNumberOfTeams: 0,
    maxNumberOfBoostedPicks: 0,
    maxNumberOfSpecialPicks: 0,
    gameType: GameType.House,
    gameMode: GameMode.Perfect,
};

export const useGlobalState = createWithEqualityFn<State>()(
    persist(
        set => ({
            multipliers: {},
            allEntryRules: [defaultEntryRules],
            hydrated: false,
            applicantId: '',
            actions: {
                setMultipliers: multipliers => {
                    set({ multipliers });
                },
                setAllEntryRules: entryRules => {
                    set({ allEntryRules: entryRules });
                },
                setHydrated: () => {
                    set({ hydrated: true });
                },
                // This action and flag are added to the GlobalState to ensure that these values are available on the Web App even after the user is redirected from IdComply site in IDPV flow
                setApplicantId: (applicantId: string) => {
                    set({ applicantId });
                },
            },
        }),
        {
            name: 'global-storage',
            version: 1,
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, hydrated, ...rest }: State) => rest,
            onRehydrateStorage: () => store => {
                store?.actions.setHydrated();
            },
        }
    ),
    Object.is
);

export const GlobalStateProvider = ({ children }: PropsWithChildren) => {
    const jurisdiction = useJurisdictionStore(state => state.jurisdiction);
    const [{ data: tradingLimitsAndEntryRules }, fetchTradingLimitsAndRules] = useGetTradingLimitsAndEntryRulesQuery({
        pause: true,
    });
    const { ready: isPhraseLoaded } = useTranslation();

    useAppResumeEffect(
        useCallback(() => {
            // ! postpone request until jurisdiction is available
            if (jurisdiction && user.session) {
                fetchTradingLimitsAndRules({ requestPolicy: 'cache-and-network' });
            }
        }, [fetchTradingLimitsAndRules, jurisdiction])
    );

    useEffect(() => {
        if (isWeb && jurisdiction && user.session) {
            // on the web the subscribe doesn't get triggered when jurisdiction changes
            // we need to trigger it manually
            fetchTradingLimitsAndRules({ requestPolicy: 'cache-and-network' });
        }
        const unsubscribe = subscribe(user, () => {
            if (jurisdiction && user.session) {
                fetchTradingLimitsAndRules({ requestPolicy: 'cache-and-network' });
            }
        });
        return () => unsubscribe();
    }, [fetchTradingLimitsAndRules, jurisdiction]);

    const multipliers = useMemo(() => {
        if (!tradingLimitsAndEntryRules || !Array.isArray(tradingLimitsAndEntryRules?.getAllEntryTradingLimits)) {
            return {};
        }

        const perfectRulesId = tradingLimitsAndEntryRules.getAllEntryRules?.find(
            it => it.gameMode === GameMode.Perfect
        )?.id;

        // getAllEntryTradingLimits returns an array of multipliers for both dynamic and perfect
        // we only need the perfect ones to display the multipliers when the pickslip is empty
        return tradingLimitsAndEntryRules.getAllEntryTradingLimits.reduce(
            (out: Multipliers, { numberOfPicks, multiplier, ...rest }) => {
                if (rest.entryRulesId !== perfectRulesId) {
                    return out;
                }
                out[numberOfPicks] = {
                    // defaulting to regular multiplier if betrBucksMultiplier is not available
                    multiplier,
                    numberOfPicks,
                    ...rest,
                };
                return out;
            },
            {}
        );
    }, [tradingLimitsAndEntryRules]);

    const allEntryRules = useMemo(() => {
        return tradingLimitsAndEntryRules?.getAllEntryRules ?? [defaultEntryRules];
    }, [tradingLimitsAndEntryRules?.getAllEntryRules]);

    useEffect(() => {
        const { setMultipliers, setAllEntryRules } = useGlobalState.getState().actions;
        setMultipliers(multipliers);
        setAllEntryRules(allEntryRules);
    }, [allEntryRules, multipliers]);

    const persistedJurisdictionDataLoaded = useJurisdictionStore(state => state._hydrated);
    const persistedLocalFlagsLoaded = useLocalFlagsStore(state => state._hydrated);
    const persistedGlobalDataLoaded = useGlobalState(state => state.hydrated);
    const persistedDataLoaded =
        persistedJurisdictionDataLoaded && persistedLocalFlagsLoaded && persistedGlobalDataLoaded;

    //only render the content when all persisted data is loaded
    //and when phrase resources are loaded
    const renderContent = persistedDataLoaded && isPhraseLoaded;

    if (!renderContent) {
        return (
            <View
                style={[
                    { backgroundColor: designSystem.colors.purple },
                    common.paddingVertical,
                    common.flex,
                    common.justifyCenter,
                ]}
            >
                <StatusBar barStyle={'light-content'} translucent backgroundColor={designSystem.colors.purple} />
                <LoadingSpinner />
            </View>
        );
    }
    return <>{children}</>;
};
