import { useMemo } from 'react';

import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { actions, user } from '@/hooks/use-user';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { useSnapshot } from 'valtio';

// Lazily create a react-query client
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 1000 * 15,
        },
    },
    queryCache: new QueryCache({
        onError: async error => {
            if (typeof error === 'object' && error) {
                if ('error_codes' in error && Array.isArray(error.error_codes)) {
                    if (error?.error_codes?.includes('keycloak_token_invalid')) {
                        // Session expired
                        await actions.refresh(user.session);
                    }
                    if (error?.error_codes?.includes('keycloak_session_invalid')) {
                        // Session doesn't exist, force logout
                        await actions.logout();
                    }
                }
            }
        },
    }),
});

export const Jurisdiction = {
    FANTASY: 'FANTASY',
} as const;

/**
 * Hook returning the appropriate Jurisdiction and Authorization headers to be used with Chameleon API.
 * New headers will be returned when the access token or jurisdiction change.
 */
export const useJurisdictionHeaders = (): { [key: string]: string } => {
    const userData = useSnapshot(user);
    const userState = useJurisdictionStore(state => state.jurisdiction);
    const product = useJurisdictionStore(state => state.product);

    const headers = useMemo(() => {
        const jurisdiction = getJurisidictionHeader(product, userState);
        return {
            JURISDICTION: jurisdiction,
            Authorization: `Bearer ${userData.session?.access_token}`,
        };
    }, [userData.session?.access_token, userState, product]);

    return headers;
};

/**
 * Creates the appropriate Jurisdiction and Authorization headers to be used with Chameleon API.
 */
export const createJurisdictionHeaders = (): { [key: string]: string } => {
    const product = useJurisdictionStore.getState().product;
    const jurisdiction = useJurisdictionStore.getState().jurisdiction;
    const accessToken = user.session?.access_token;
    const jurisdictionHeader = getJurisidictionHeader(product, jurisdiction);
    return {
        JURISDICTION: jurisdictionHeader,
        Authorization: `Bearer ${accessToken}`,
    };
};

const getJurisidictionHeader = (mode: Product | undefined, state: string | undefined) => {
    return mode === Product.Pickem ? Jurisdiction.FANTASY : state ?? '';
};
