import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';

import { ShareEntryModal, ShareEntryModalRef, ShareEntryProps } from './ShareEntryModal';

type ShareEntry = {
    showShareEntryModal: (props: ShareEntryProps) => void;
};

const ShareEntryContext = React.createContext<ShareEntry>({
    showShareEntryModal: () => {},
});

/**
 * Provides access to the Modal used to display the share options/channels for your entry
 */
export const ShareEntryProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<ShareEntryModalRef>(null);

    const showShareEntryModal = useCallback((props: ShareEntryProps) => {
        modalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <ShareEntryContext.Provider value={{ showShareEntryModal }}>
                {children}
                <ShareEntryModal ref={modalRef} />
            </ShareEntryContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useShareEntry = () => {
    return useContext(ShareEntryContext);
};
