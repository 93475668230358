import { hasNoLimit } from '@/feature/betslip-sbk/utils/validators';
import { UserLimit } from '@/feature/responsible-gaming/hooks/types';
import { CreateEntryAccuracyError, CreateEntryError } from '@/hooks/use-entries';
import { getGeoComplyErrorMessage } from '@/utils/get-geocomply-error-message';

import { toLocaleCurrency } from '../numeric/currency';

const defaultErrorMsg = 'Sorry, there is an issue with your entry, please try again';

export enum EntryErrorCodes {
    INVALID_CRITERIA = 101,
    INVALID_AMOUNT = 102,
    GENERIC_FU_FAILURE = 105,
    MAX_ENTRY_AMOUNT_SURPASSED = 109,
    MAX_PLAYER_AMOUNT_SURPASSED = 110,
    INSUFFICIENT_FUNDS = 103,
    DUPLICATE_PICKS = 104,
    SESSION_INVALID = 120,
    NOT_VERIFIED = 121,
    INVALID_ENTRY_PARAMS = 400,
    INVALID_EVENT_STATUS = 401,
    INVALID_PROJECTION = 402,
    RESTRICTION_SAME_OUTCOME = 250,
    RESTRICTION_DIFFERENT_OUTCOME = 251,
    NFL_RESTRICTION_SAME_OUTCOME = 257,
    NFL_RESTRICTION_DIFFERENT_OUTCOME = 258,
    NFL_REGULAR_SEASON_RESTRICTION_LESS_OUTCOMES = 259,
    NHL_RESTRICTION_SAME_OUTCOME = 255,
    NHL_RESTRICTION_DIFFERENT_OUTCOME = 256,
    P2P_MAX_ENTRIES_REACHED = 270,
}

const CustomErrorRange = {
    MIN: 250,
    MAX: 299,
} as const;

const errorMessages: { [key: string]: string } = {
    [EntryErrorCodes.INVALID_CRITERIA]:
        'Entry does not meet the min. requirements.\n- Choose at least {minPlayers} Players \n- Choose at least {minTeams} different teams',
    [EntryErrorCodes.MAX_ENTRY_AMOUNT_SURPASSED]: 'Entry amount exceeded max amount.',
    [EntryErrorCodes.MAX_PLAYER_AMOUNT_SURPASSED]: 'This player is no longer available.',
    [EntryErrorCodes.SESSION_INVALID]:
        'Sorry, it looks like you are not logged in. Please log in again and place entry',
    [EntryErrorCodes.NOT_VERIFIED]: 'Your account has not been verified, please complete verification to place entry',
    [EntryErrorCodes.INSUFFICIENT_FUNDS]: 'Not enough funds in your account to enter this contest.',
};

export type EntryErrorInfo = {
    title: string;
    subtitle?: string;
    primaryButton: string;
    needsLocationAccuracy: boolean;
};

export const getErrorInfo = (
    e: any,
    { minNumberOfPicks, minNumberOfTeams }: { minNumberOfPicks: number; minNumberOfTeams: number }
): EntryErrorInfo => {
    let title = 'Failed to submit entry',
        subtitle = 'If the issue persists try re-starting the app, or logout and login again.',
        primaryButton = 'Try Again',
        needsLocationAccuracy = false;

    if (!e?.can_bet && e?.geo_session_token) {
        title = 'Location failure';
        subtitle = getGeoComplyErrorMessage(e) || subtitle;
    } else if (e instanceof CreateEntryError) {
        title = getCustomErrorTitle(e.response?.reasonCode, title);

        subtitle = getSubmitEntryErrorMsg(e.response?.reasonCode, {
            minPlayers: minNumberOfPicks,
            minTeams: minNumberOfTeams,
        });
        const code = e.response?.reasonCode;
        if (customReasonErrorCode(code)) {
            //For some specicic reason codes, we should attempt to use the 'reasonText' from the BE response
            subtitle = e?.response?.reasonText ?? subtitle;
        }
    } else if (e instanceof CreateEntryAccuracyError) {
        primaryButton = 'Open settings';
        subtitle =
            'Precise location must be turned on before you can place an entry.\n Go to app Settings → Permissions → Location and allow access and precise location.';
        needsLocationAccuracy = true;
    }

    return {
        title,
        subtitle,
        primaryButton,
        needsLocationAccuracy,
    };
};

const getCustomErrorTitle = (code: number | undefined, title: string) => {
    if (code === EntryErrorCodes.P2P_MAX_ENTRIES_REACHED) {
        return 'Maximum Entry Limit Reached';
    }

    return title;
};

/**
 * Checks if this error code indicates that we should display the `reasonText` message sent by the GrahpQL API.
 */
const customReasonErrorCode = (code: number | undefined): boolean => {
    return (
        !!code &&
        ([
            EntryErrorCodes.GENERIC_FU_FAILURE,
            EntryErrorCodes.INVALID_ENTRY_PARAMS,
            EntryErrorCodes.INVALID_EVENT_STATUS,
            EntryErrorCodes.INVALID_PROJECTION,
        ].includes(code) ||
            (CustomErrorRange.MIN <= code && code <= CustomErrorRange.MAX))
    );
};

export const getSubmitEntryErrorMsg = (code: number = -1, args: { [arg: string]: string | number } = {}) => {
    if (code) {
        let errorMsg = errorMessages[code] ?? defaultErrorMsg;
        Object.keys(args ?? {}).forEach(key => {
            errorMsg = errorMsg.replace(`{${key}}`, args[key].toString());
        });
        return errorMsg;
    } else {
        return defaultErrorMsg;
    }
};

export const getWagerLimitErrorMessage = (amountInput: string, data: UserLimit[] = []): string => {
    const amount = parseFloat(amountInput);

    const single = data.find((d: UserLimit) => d.type === 'SINGLE_WAGER');
    const daily = data.find((d: UserLimit) => d.duration === 'DAY');
    const weekly = data.find((d: UserLimit) => d.duration === 'WEEK');
    const monthly = data.find((d: UserLimit) => d.duration === 'MONTH');

    if (single && amount > single.amount && !hasNoLimit(single)) {
        return `Exceeds single play limit of ${toLocaleCurrency(single.amount)}.`;
    }

    if (daily && amount + daily.current_state > daily.amount && !hasNoLimit(daily)) {
        return `Exceeds daily limit of ${toLocaleCurrency(daily.amount)}.`;
    } else if (weekly && amount + weekly.current_state > weekly.amount && !hasNoLimit(weekly)) {
        return `Exceeds weekly limit of ${toLocaleCurrency(weekly.amount)}.`;
    } else if (monthly && amount + monthly.current_state > monthly.amount && !hasNoLimit(monthly)) {
        return `Exceeds monthly limit of ${toLocaleCurrency(monthly.amount)}.`;
    }

    return '';
};
