import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={9} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 7c.648 0 1.248-.205 1.738-.555L2.055 2.262A3 3 0 0 0 4.5 7Zm-.78-5.897A3.004 3.004 0 0 1 7.396 4.78l.785.784A4 4 0 0 0 2.935.318l.784.785ZM1.97.763l-.002.003 5.798 5.798.002-.002.511.511a.25.25 0 0 1 0 .354l-.353.353a.25.25 0 0 1-.354 0l-.62-.62A4 4 0 0 1 1.34 1.547L.677.884a.25.25 0 0 1 0-.354L1.03.177a.25.25 0 0 1 .354 0l.587.587Z"
            fill="#41494E"
        />
    </Svg>
);

export default SvgComponent;
