import { useCallback, useRef } from 'react';

import { useResumeEffect } from '@/hooks/use-resume';

/**
 * Similar to `useResumeEffect`, but this is only triggered when the component is focused for the first time.
 *
 * @template T - The type of the data returned by the refetch function.
 * @param {() => Promise<T>} refetch - The function that triggers the data refresh.
 */
export function useFetchOnInitialFocus<T>(refetch: () => Promise<T>) {
    const firstTimeRef = useRef(true);

    useResumeEffect(
        useCallback(() => {
            if (firstTimeRef.current) {
                firstTimeRef.current = false;
                return;
            }
            refetch();
        }, [refetch])
    );
}
