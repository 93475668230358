import { Bet } from '../hooks/types';

/**
 * Returns the multiplier values for combo and script bets with a voided leg
 */
export const getMultiplier = (bet: Bet) => {
    let multiplier = bet.requested_odds;
    let strikeThroughMultiplier = 0;

    if (bet.is_betr_bucks) {
        const adjustedOdds = (bet.requested_odds - 1).toFixed(2);
        if (adjustedOdds === bet.current_odds.toFixed(2)) {
            multiplier = bet.current_odds;
        } else {
            multiplier = bet.current_odds;
            strikeThroughMultiplier = Number(adjustedOdds);
        }
    } else {
        if (bet.requested_odds !== bet.current_odds) {
            multiplier = bet.current_odds;
            strikeThroughMultiplier = bet.requested_odds;
        }
    }

    return { multiplier, strikeThroughMultiplier };
};
