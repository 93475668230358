import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';

export const productNames = {
    [Product.Pickem]: 'Fantasy',
    [Product.Sportsbook]: 'Sportsbook',
    [Product.None]: '',
};

export const useActiveProductName = () => {
    const product = useJurisdictionStore(state => state.product);
    return productNames[product];
};
