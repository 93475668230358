import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

function SvgComponent(props) {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" width={86} height={24} viewBox="0 0 86 24" fill="none" {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.005 15.937l1.84-12.416c.07-.476.438-.853.911-.936L17.366.009a.559.559 0 01.594.804l-4.144 8.131c-.21.41.14.884.594.804l3.814-.673c.544-.096.882.573.482.956L4.201 23.843c-.415.394-1.084-.015-.92-.564l1.993-6.703a.558.558 0 00-.633-.709l-3.985.703a.56.56 0 01-.65-.633H.005zm9.019-5.606c.713.28 1.169.458 1.006-.46-.286-1.628-1.812-2.72-3.408-2.438-1.594.281-2.655 1.829-2.369 3.457.163.917.529.595 1.104.086.445-.392 1.015-.894 1.711-1.017.696-.123 1.403.154 1.956.372z"
                fill={props.color || designSystem.colors.gray1}
            />
            <Path
                d="M79.916 18.786c-2.052 0-4.266-.738-5.274-1.782l-.108-3.564c1.224 1.422 3.312 2.52 5.328 2.52 1.386 0 2.34-.522 2.34-1.296 0-.9-1.404-1.134-3.42-1.71-2.61-.756-4.302-1.854-4.302-3.798 0-2.304 2.376-3.906 5.58-3.906 1.944 0 3.96.612 4.986 1.35l.234 3.312c-1.602-1.188-3.474-1.854-5.202-1.854-1.26 0-2.196.378-2.196 1.044 0 .756 1.044.99 3.384 1.656 2.7.702 4.446 1.71 4.446 3.834 0 2.502-2.592 4.194-5.796 4.194zM69.19 18.552l-5.363-5.706v5.706h-3.402V5.484h3.402v5.31l5.454-5.31h4.374l-6.624 6.21 6.588 6.858H69.19zM53.725 18.786c-4.482 0-8.046-2.718-8.046-6.696 0-3.816 3.33-6.84 8.172-6.84 1.602 0 3.186.342 4.5 1.116l.108 3.636c-1.35-1.188-2.79-1.782-4.482-1.782-2.448 0-4.608 1.368-4.608 3.762 0 2.34 2.016 3.834 4.626 3.834 1.728 0 3.474-.702 4.68-1.8l-.468 3.6c-1.026.666-2.628 1.17-4.482 1.17zM40.528 18.552V5.484h3.438v13.068h-3.438zM26.88 18.552V5.484h5.814c4.374 0 6.156 2.034 6.156 4.446 0 2.61-2.088 4.59-6.084 4.59h-2.484v4.032H26.88zm5.634-6.732c1.566 0 2.7-.558 2.7-1.836 0-1.242-1.098-1.8-2.7-1.8h-2.232v3.636h2.232z"
                fill={props.color || designSystem.colors.gray1}
            />
        </Svg>
    );
}

export default SvgComponent;
