import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, LayoutAnimation, StyleSheet, TouchableOpacity, View } from 'react-native';

import { TeamInfoFragment, useEventInfoWithPlayersQuery } from '@/api/events/query.generated';
import { EventWithTeams, EventWithoutTeams, isTeamEvent } from '@/api/events/types/types';
import DownIcon from '@/assets/icons/down';
import { LineSeparator } from '@/components/LineSeparator';
import { Loading } from '@/components/Loading';
import MergedLogos from '@/components/MergedLogos';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Image, Row } from '@/components/lib/components';
import { leagueConfigSelector, useLeagueConfigsStore } from '@/feature/betslip-pickem/hooks/use-league-configs-store';
import { useUpdatePlayerStoreWithNewData } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { isPlayerSelected } from '@/feature/betslip-pickem/utils/betslip-utils';
import { eventUtils } from '@/feature/betslip-pickem/utils/event-utils';
import { getPositionFilters } from '@/feature/lobby/utils/filters';
import { useGetOrderedLeagues } from '@/hooks/use-fantasy-league-configs';
import { common, designSystem } from '@/styles/styles';
import { EventAttributesType } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { findEventAttribute } from '@/utils/fantasy-attribute-utils';
import { gameUtils } from '@/utils/games';
import { isUFC } from '@/utils/league';

import { PickSelectionMethods } from '../hooks/use-pick-selection';
import { PlayerRow } from './PlayerRow';

type Props = {
    onDataLoaded: () => void;
    onPress: (params: { isLoaded: boolean }) => void;
    isOpen: boolean;
    game: EventWithTeams | EventWithoutTeams;
    testID?: string;
} & PickSelectionMethods;

type PlayersLogos = [Pick<TeamInfoFragment, 'icon' | 'color'>, Pick<TeamInfoFragment, 'icon' | 'color'>];

const keyExtractor = (item: PlayerWithTeam) => `${item.id}`;

export const GameCard = ({
    onDataLoaded,
    game,
    isOpen,
    testID,
    onPress,
    openPlayerPickModal,
    removeSelection,
    makeSelection,
}: Props) => {
    const { id } = game;
    const teams = eventUtils.getTeams(game);
    const isUFCLeague = isUFC(game.league);
    // For some sports we are passing directly the game object with players data
    const hasPlayersData = isUFCLeague;
    const [{ data: eventsInfoWithPlayers, fetching: loading }, fetchEventInfo] = useEventInfoWithPlayersQuery({
        pause: true,
        requestPolicy: 'network-only',
        variables: { id },
    });

    const data = hasPlayersData ? game ?? eventsInfoWithPlayers?.getEventByIdV2 : eventsInfoWithPlayers?.getEventByIdV2;

    const loaded = !!(data && !loading);

    //only create a new array when the data changes, otherwise this might create infinite re-renders
    const memoData = useMemo(() => (data ? [data] : undefined), [data]);
    useUpdatePlayerStoreWithNewData(memoData);

    useEffect(() => {
        // re-request event data when game changes
        // (when a refresh is triggered in PickemSportScreen, that will also trigger a refresh here)
        if (isOpen) {
            fetchEventInfo();
        }
    }, [game, fetchEventInfo, isOpen]);

    useEffect(() => {
        if (isOpen) {
            fetchEventInfo();
        }
    }, [isOpen, fetchEventInfo]);

    useEffect(() => {
        if (loaded) {
            onDataLoaded();
        }
    }, [loaded, onDataLoaded, loading]);

    const { leagues } = useGetOrderedLeagues();
    const positionFilters = useMemo(() => getPositionFilters(game.league, leagues), [game.league, leagues]);

    const sortedPlayers = useMemo(
        () => (data ? gameUtils.filterAndSortPlayers(data, positionFilters) : []),
        [data, positionFilters]
    );

    const eventTime = gameUtils.createTimeLabel(game);
    const isLive = gameUtils.isLive(game);
    const startDate = findEventAttribute(game, EventAttributesType.TournamentStartDateTime) ?? '';
    const endDate = findEventAttribute(game, EventAttributesType.TournamentEndDateTime) ?? '';
    const dateRanges = gameUtils.formatTournamentDates(startDate, endDate);
    const eventTitle = gameUtils.createTitleLabel(game);
    const { leagueColor } = useLeagueConfigsStore(leagueConfigSelector(game.league), defaultZustandCompareFunction);
    const eventTimeColor = gameUtils.getEventInfoTextColor(undefined, isLive, undefined, undefined);

    const playersLogos: PlayersLogos = useMemo(
        () => [
            { icon: sortedPlayers?.[0]?.icon || '', leagueColor },
            { icon: sortedPlayers?.[1]?.icon || '', leagueColor },
        ],
        [sortedPlayers, leagueColor]
    );
    const roundNumber = findEventAttribute(game, EventAttributesType.RoundNumber);

    const noAvailablePlayers = sortedPlayers.length === 0 && loaded;
    const { t } = useTranslation('betslip_pickem');
    return (
        <View style={[styles.root, isOpen && styles.openedBottom]}>
            <TouchableOpacity
                style={[common.spaceBetweenRow]}
                onPress={() => {
                    LayoutAnimation.easeInEaseOut();
                    onPress({ isLoaded: loaded });
                }}
            >
                <View style={[common.row, common.flex]} testID={`match-${testID}`}>
                    {isUFCLeague || (isTeamEvent(game) && teams[0] && teams[1]) ? (
                        <MergedLogos
                            logos={isUFCLeague ? playersLogos : teams}
                            renderMode={isUFCLeague ? 'player' : 'team'}
                            overlap={14}
                            playerImageSizes={{ width: 36, height: 36 }}
                            backdropSizes={[40, 36]}
                            imageShape="square"
                            borderColor={designSystem.colors.gray8}
                            imagePlayerBgColor={leagueColor}
                            league={game.league}
                            teamShortCodes={[teams[0]?.name, teams[1]?.name]}
                            showFullTeamName={true}
                            fontSizes={[getFontSize(teams[0]?.name), getFontSize(teams[1]?.name)]}
                        />
                    ) : (
                        <Image source={{ uri: game?.icon }} style={styles.eventImage} />
                    )}

                    <SizedBox value={16} />
                    <View style={[common.spaceBetweenRow, common.flex]}>
                        <Text variant="titleMedium" ellipsizeMode={'tail'} numberOfLines={2} style={styles.eventTitle}>
                            {eventTitle}
                        </Text>
                        <Row alignItems={'center'}>
                            {roundNumber ? (
                                <Box
                                    backgroundColor={'darkBlue'}
                                    paddingHorizontal={'s6'}
                                    marginHorizontal={'s8'}
                                    style={styles.roundLabel}
                                    borderRadius={'r6'}
                                >
                                    <Text variant="labelLarge" textAlign={'center'}>
                                        R{roundNumber}
                                    </Text>
                                </Box>
                            ) : null}
                            <Text variant={isLive ? 'titleSmall' : 'bodySmall'} color={eventTimeColor}>
                                {eventTime ? eventTime : dateRanges}
                            </Text>
                        </Row>
                    </View>
                </View>
                <SizedBox value={8} />
                <DownIcon />
            </TouchableOpacity>
            {isOpen ? (
                <>
                    <SizedBox value={16} />
                    {sortedPlayers.length > 0 ? (
                        <FlatList<PlayerWithTeam>
                            data={sortedPlayers}
                            style={common.flex}
                            keyExtractor={keyExtractor}
                            renderItem={({ item: p, index }) => {
                                return (
                                    <MemoPlayerRow
                                        gameId={game.id}
                                        player={p}
                                        event={data}
                                        index={index}
                                        isLastItem={index === sortedPlayers.length - 1}
                                        openPlayerPickModal={openPlayerPickModal}
                                        removeSelection={removeSelection}
                                        makeSelection={makeSelection}
                                    />
                                );
                            }}
                        />
                    ) : loading ? (
                        <View>
                            <SizedBox value={10} />
                            <Loading />
                            <SizedBox value={10} />
                        </View>
                    ) : noAvailablePlayers ? (
                        <View>
                            <SizedBox value={10} />
                            <Text>{t('no_available_players')}</Text>
                            <SizedBox value={10} />
                        </View>
                    ) : null}
                </>
            ) : null}
        </View>
    );
};

const getFontSize = (teamName?: string) => {
    const numberOfLetters = teamName?.length ?? 0;
    let fontSize = 16;
    if (numberOfLetters > 2 && numberOfLetters < 5) {
        fontSize = 12;
    } else if (numberOfLetters > 4) {
        fontSize = 8;
    }
    return fontSize;
};

const MemoPlayerRow = memo(
    ({
        gameId,
        event,
        player,
        index,
        isLastItem,
        makeSelection,
        openPlayerPickModal,
        removeSelection,
    }: {
        gameId: string;
        event: EventWithTeams | EventWithoutTeams | undefined;
        player: PlayerWithTeam;
        index: number;
        isLastItem: boolean;
    } & PickSelectionMethods) => {
        const playerPickedEntry = isPlayerSelected({
            eventId: gameId,
            playerId: player.id,
        });

        return (
            <Box flex={1} accessible={false}>
                {playerPickedEntry ? (
                    <PlayerRow
                        player={player}
                        mode="selection"
                        outcome={playerPickedEntry.outcome}
                        projection={playerPickedEntry.projection}
                        event={event}
                        pressable={true}
                        testID={index.toString()}
                        removeSelection={removeSelection}
                        makeSelection={makeSelection}
                        openPlayerPickModal={openPlayerPickModal}
                        analyticsTag={'Games Tab'}
                    />
                ) : (
                    <PlayerRow
                        player={player}
                        mode="all"
                        event={event}
                        pressable={true}
                        testID={index.toString()}
                        removeSelection={removeSelection}
                        makeSelection={makeSelection}
                        openPlayerPickModal={openPlayerPickModal}
                        analyticsTag={'Games Tab'}
                    />
                )}
                {!isLastItem ? <LineSeparator style={common.separator} /> : null}
            </Box>
        );
    }
);

const styles = StyleSheet.create({
    root: {
        paddingRight: 16,
        paddingLeft: 14,
        paddingVertical: 14,
    },
    openedBottom: {
        paddingBottom: 8,
    },
    eventTitle: {
        flex: 1,
    },
    eventImage: {
        width: 36,
        height: 36,
        borderRadius: 10,
    },
    roundLabel: {
        paddingBottom: 2.5,
        paddingTop: 1.5,
        borderCurve: 'continuous',
    },
});
