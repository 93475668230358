import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, TouchableWithoutFeedback } from 'react-native';

import AlertStraightTriangleIcon from '@/assets/icons/alertStraightTriangle';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';

import { useComboBetType, useOrderedEventIds } from '../../hooks/betslip-hooks';
import { useComboBetCardAlert } from '../../hooks/use-combo-bet-card-alert';
import { useSbkBetSlipStore } from '../../hooks/use-sbk-betslip-store';
import { BetIds, isComboSelectionEnabled } from '../../utils/betslip-utils';
import { Banner } from '../Banner';
import { BetInputs } from '../BetInputs';
import { ComboBetList } from './ComboBetList';
import { ComboOdds } from './ComboOdds';

export const ComboCard = () => {
    const setShowKeyboard = useSbkBetSlipStore(state => state.actions.setShowKeyboard);
    const orderedEventIds = useOrderedEventIds();
    const betId = BetIds.COMBO;
    const lastOrderedEventId = useMemo(() => orderedEventIds[orderedEventIds.length - 1], [orderedEventIds]);

    const alerts = useComboBetCardAlert();
    const isDisabled = !!alerts.length;

    const renderItem = useCallback(
        ({ item: eventId }: { item: string }) => {
            const isLastEvent = eventId === lastOrderedEventId;
            return (
                <ComboBetList
                    isLastEvent={isLastEvent}
                    eventId={eventId}
                    testID={orderedEventIds.indexOf(eventId).toString()}
                />
            );
        },
        [lastOrderedEventId, orderedEventIds]
    );

    const activeSelectionCount = useSbkBetSlipStore(
        state => Object.values(state.selections).filter(selection => isComboSelectionEnabled(selection, state)).length
    );
    const comboBetType = useComboBetType();

    const { t } = useTranslation('bets');
    const betTypeKey = comboBetType === 'SGP' ? 'script_bet_name' : 'combo_bet_name';
    const betName = t(betTypeKey, { count: activeSelectionCount });

    return (
        <TouchableWithoutFeedback onPress={() => setShowKeyboard(false)} accessible={false}>
            <Box pb="s20" mb="s20">
                {isDisabled ? (
                    <Box gap="s16" mb="s16" testID="inactiveCombosWarningMsg">
                        {alerts.map(alert => (
                            <Banner
                                key={alert.type}
                                leftIcon={<AlertStraightTriangleIcon width={24} height={24} />}
                                message={alert.message}
                                backgroundColor="gray6"
                            />
                        ))}
                    </Box>
                ) : (
                    <>
                        <Row justifyContent="space-between" alignItems="center" mb="s12">
                            <Text variant="titleMedium" color="gray1" testID="comboCardTitle">
                                {betName}
                            </Text>
                            <ComboOdds variant="titleMedium" color="gray1" testID="comboOdds" />
                        </Row>
                        <BetInputs betId={betId} />
                    </>
                )}

                <TouchableWithoutFeedback onPress={() => setShowKeyboard(false)} accessible={false}>
                    <FlatList
                        keyExtractor={eventId => `event${eventId}`}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        data={orderedEventIds}
                        renderItem={renderItem}
                    />
                </TouchableWithoutFeedback>
            </Box>
        </TouchableWithoutFeedback>
    );
};
