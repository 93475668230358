import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import LockIcon from '@/assets/icons/lock';
import { getFormattedOdds } from '@/components/Odds';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useSelectionActions } from '@/feature/betslip-sbk/hooks/use-selection-actions';
import { designSystem } from '@/styles/styles';

import { useFeaturedBetActions } from '../hooks/use-featured-bet-actions';
import { useSelectionInfo } from '../hooks/use-selection-info';
import { FeaturedBet } from '../types';

type AddBetButtonProps = {
    testID?: string;
    featuredBet: FeaturedBet;
};

export const AddBetButton = ({ testID, featuredBet }: AddBetButtonProps) => {
    const selections = featuredBet.events.flatMap(e => e.selections);
    const oddDetails = featuredBet.odds;

    const { addFeaturedBet, removeFeaturedBet } = useFeaturedBetActions();

    const { isSelected } = useSelectionActions();

    const { isSuspended, isAlreadySelected } = useSelectionInfo(selections, isSelected);

    const toggleSelection = () => {
        if (isAlreadySelected) {
            removeFeaturedBet(selections.map(s => s.option.id));
        } else {
            addFeaturedBet(featuredBet);
        }
    };

    const { t } = useTranslation('bets');

    return (
        <Box flexGrow={1}>
            <TouchableOpacity onPress={toggleSelection} disabled={isSuspended} testID={`${testID}`}>
                <Box
                    borderRadius="r10"
                    backgroundColor={isAlreadySelected ? 'white' : 'gray6'}
                    alignItems="center"
                    justifyContent="center"
                    paddingVertical="s12"
                    paddingHorizontal="s16"
                    height={44}
                >
                    {isSuspended ? (
                        <Box flex={1} justifyContent="center" alignItems="center" gap="s2">
                            <Box marginTop="s2">
                                <LockIcon />
                            </Box>
                            <Text variant="labelLarge" color="gray4">
                                {t('suspended')}
                            </Text>
                        </Box>
                    ) : (
                        <Box flexDirection={'row'}>
                            <Text
                                style={[
                                    stylesheet.smallTitle,
                                    {
                                        color: isAlreadySelected
                                            ? designSystem.colors.gray8
                                            : designSystem.colors.gray1,
                                    },
                                ]}
                            >
                                {getFormattedOdds(oddDetails.total_odds)}
                            </Text>
                        </Box>
                    )}
                </Box>
            </TouchableOpacity>
            <SizedBox value={8} />
        </Box>
    );
};

const stylesheet = StyleSheet.create({
    smallTitle: {
        fontSize: 13,
        lineHeight: 20,
        fontWeight: '600',
    },
});
