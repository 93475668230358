import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@/assets/icons/info';
import LossIcon from '@/assets/icons/loss';
import StrikedThroughDotLarge from '@/assets/icons/striked-through-dot-large';
import WonIcon from '@/assets/icons/won';
import { Text } from '@/components/TextComponent';
import { Box, TouchableBox } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { MarketName } from '@/feature/event-details-sbk/components/MarketName';
import { OptionName } from '@/feature/event-details-sbk/components/OptionName';
import { designSystem } from '@/styles/styles';

import { BetEvent, BetSelection, Result } from '../hooks/types';

type EventGroupingSelectionItemProps = {
    selection: BetSelection;
    event: BetEvent;
    isLastItem: boolean;
    isCashedOut: boolean;
};

const renderIcon = (result: Result) => {
    switch (result) {
        case 'WON':
            return <WonIcon />;
        case 'LOST':
            return <LossIcon width={20} height={20} />;
        case 'CANCELLED':
        case 'PUSHED':
        case 'CASHEDOUT':
            return (
                <Box
                    backgroundColor="gray4"
                    borderRadius="r40"
                    width={21}
                    height={21}
                    borderWidth={4}
                    borderColor="gray4"
                >
                    <StrikedThroughDotLarge width={13} height={13} fillColor={designSystem.colors.gray8} />
                </Box>
            );
        default:
            return (
                <Box
                    borderRadius="r96"
                    width={20}
                    height={20}
                    borderWidth={2}
                    borderColor="gray5"
                    backgroundColor="gray8"
                />
            );
    }
};

export const EventGroupingSelectionItem = ({
    selection,
    event,
    isCashedOut,
    isLastItem,
}: EventGroupingSelectionItemProps) => {
    const { t } = useTranslation(['bets']);
    const { showInfoSheet } = useAlerts();
    const voidedResults = ['CANCELLED', 'PUSHED'];
    const isVoided = voidedResults.includes(selection.result);
    const isSelectionCashedOut = !selection.result && isCashedOut;
    const isInactive = isVoided || isSelectionCashedOut;

    const handleVoidAlertPress = () =>
        showInfoSheet({
            title: t('voided_leg'),
            description: isSelectionCashedOut ? t('cashed_out_desc') : t('voided_leg_desc'),
            buttonLabel: t('got_it'),
            icon: (
                <Box
                    width={72}
                    height={72}
                    backgroundColor="gray5"
                    borderRadius="r96"
                    justifyContent="center"
                    alignItems="center"
                >
                    <StrikedThroughDotLarge width={'36'} height={'36'} fillColor="white" />
                </Box>
            ),
        });

    return (
        <Box p="s8" pr="s0">
            <Box justifyContent="center" alignItems="center" top={0} left={20} width={1} bottom={0} position="absolute">
                <Box position="absolute" top={0} left={0} width={2} bottom={0}>
                    <Box backgroundColor="gray6" flex={1} width={2} />
                    <Box backgroundColor="gray6" flex={1} width={isLastItem ? 0 : 2} />
                </Box>
                <Box
                    width={28}
                    height={28}
                    position="absolute"
                    top={16}
                    borderRadius="r32"
                    borderWidth={4}
                    backgroundColor="mainBackground"
                    borderColor="mainBackground"
                >
                    {renderIcon(isSelectionCashedOut ? 'CASHEDOUT' : selection.result)}
                </Box>
            </Box>
            <Box ml="s24" marginStart="s20" paddingLeft="s20">
                <Box alignItems="flex-start" marginVertical={'s10'}>
                    {isInactive ? (
                        <Box flexDirection="row" alignItems="center" gap="s10">
                            <Text variant="titleSmall" color="gray3">
                                {isSelectionCashedOut ? t('voided_cashout_leg') : t('voided_leg')}
                            </Text>
                            <TouchableBox onPress={handleVoidAlertPress}>
                                <InfoIcon width="12" height="12" color={designSystem.colors.gray3} />
                            </TouchableBox>
                        </Box>
                    ) : null}
                    <OptionName
                        optionType={selection.option.option_type}
                        marketType={selection.market.market_type}
                        homeTeam={event.home_team}
                        awayTeam={event.away_team}
                        fallback={selection.option.description}
                        sportName={event.sport.name}
                        variant="titleMedium"
                        color={isInactive ? 'gray4' : 'gray1'}
                        textDecorationLine={isInactive ? 'line-through' : 'none'}
                        isDisplayFullName
                        testID="betCardSelectionName"
                    />
                    <MarketName
                        event={event}
                        sportName={event.sport.name}
                        marketType={selection.market.market_type}
                        fallback={selection.market.description}
                        player={selection.market.player}
                        appendPlayerName={true}
                        variant="bodySmall"
                        color={isInactive ? 'gray4' : 'gray2'}
                        textDecorationLine={isInactive ? 'line-through' : 'none'}
                        appendMicroMarketDescription={selection.market.is_micro_market}
                        appendTeamName={!selection.market.is_micro_market}
                        isExtendedName
                        testID="betCardMarketName"
                    />
                </Box>
            </Box>
        </Box>
    );
};
