import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
        <Path
            fill={props.fill ?? '#6D707C'}
            fillRule="evenodd"
            d="M3.1 2h18v20l-3-2.273-3 2.273-3-2.273L9.1 22l-3-2.273L3.1 22V2Zm4 5.5h10v2h-10v-2Zm6 4h-6v2h6v-2Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
