import { common } from '@/styles/styles';
import { Outcome } from '@/types/api.generated';

/* Standard value used often for paddings and margins */
export const standardValue = common.padding.padding;

export const PICK_TYPE_LABEL = {
    [Outcome.More]: '↑',
    [Outcome.Less]: '↓',
};

export const PICK_TYPE_LABEL_SIMPLE = {
    [Outcome.More]: 'More',
    [Outcome.Less]: 'Less',
};

export const NFL_FULL_SEASON = 'NFL Full Season';
export const FULL_SEASON_2023 = '2023 Full Season';

/* NCPG Phone Number */
export const NCPG_PHONE_NUMBER = '1-800-522-4700';

/* If the user has SBK balance, but not in a RMG jurisdiction, we can use `OH` as a generic SBK jurisdiction for all API requests */
export const GENERIC_SBK_JURISDICTION = 'OH';

export const ANIMATION_SPRING_CONFIG = {
    damping: 20,
    stiffness: 100,
    mass: 1,
};

export const BUNDLE_IDENTIFIER = 'app.instabet.betr';

export const APP_ID = '1635215598';

/* Backoffice top level links */
export const BACKOFFICE_URL_PRODUCTION = 'https://backoffice.betr.app';
