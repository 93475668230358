import React from 'react';
import { StyleSheet, View } from 'react-native';

import { designSystem } from '../styles/styles';

const radioStyles = StyleSheet.create({
    defaultStyles: {
        width: 20,
        height: 20,
        borderRadius: 10,
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: designSystem.colors.gray3,
    },
    selected: {
        borderColor: designSystem.colors.gray1,
        borderWidth: 6,
    },
});

export type RadioItemProps = {
    selected: boolean;
    color?: string;
};

export function RadioItem({ selected, color }: RadioItemProps) {
    return (
        <View
            style={[
                radioStyles.defaultStyles,
                selected && { ...radioStyles.selected, borderColor: color ?? designSystem.colors.white },
            ]}
        />
    );
}
