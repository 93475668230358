import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} fill="none" {...props}>
        <Circle cx={40} cy={40} r={38} fill="#fff" stroke="#fff" strokeWidth={4} />
        <Path
            fill="#000"
            fillRule="evenodd"
            d="M59.037 28.268a.5.5 0 0 1 0 .707L35.35 52.663a2 2 0 0 1-2.828 0L20.146 40.289a.5.5 0 0 1 0-.708l2.122-2.12a.5.5 0 0 1 .707 0l10.96 10.96 22.274-22.275a.5.5 0 0 1 .707 0l2.121 2.122Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
