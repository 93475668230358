import { SportSpecific } from '@/feature/event-details-sbk/types';
import { Event } from '@/feature/event-details-sbk/types';
import { AtLeast } from '@/types/utils';

import { getSportKey } from '../get-sport-key';

export type EventPeriodDetails = AtLeast<Event, 'event_details' | 'sport' | 'away_team' | 'home_team' | 'id'>;

const downsToLabel: { [key: number]: string } = {
    1: '1st',
    2: '2nd',
    3: '3rd',
    4: '4th',
};

/**
 * Returns the current status of a live event. This is sport-specific and only applies to baseball and american football.
 *  - Baseball: '2 Outs'
 *  - American Football: '2nd & 20, MIN 20'
 */
export const formatSportPeriodDetails = (event: EventPeriodDetails, sportSpecific?: SportSpecific) => {
    switch (getSportKey(event.sport)) {
        case 'baseball':
            const currentInning = sportSpecific?.current_inning;
            const inningDetails = currentInning ? sportSpecific?.inning_details?.[currentInning] : null;
            const outs = inningDetails?.outs;
            return outs !== undefined ? `${outs} Outs` : '';
        case 'american_football':
            const down = sportSpecific?.down;
            const yardsRemainingForDrive = sportSpecific?.yards_remaining_for_drive;
            const yardline = sportSpecific?.yardline;
            const downLabel = down && downsToLabel[down];
            const ballPossession = sportSpecific?.ball_possession;
            let team = '';
            if (ballPossession === 'AWAY') {
                team = event.away_team?.short_code;
            } else if (ballPossession === 'HOME') {
                team = event.home_team?.short_code;
            }
            return downLabel && yardsRemainingForDrive && team && yardline
                ? `${downLabel} & ${yardsRemainingForDrive}, ${team} ${yardline}`
                : '';
        default:
            return '';
    }
};
