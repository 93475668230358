import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { UpdateExpiryCardForm } from '@/components/UpdateExpiryCardForm';
import { RootStackParamList } from '@/navigation/types';

type WithdrawalExpiryUpdateProps = NativeStackScreenProps<RootStackParamList, 'WithdrawalExpiryUpdate'>;

export const WithdrawalExpiryUpdate = ({ route }: WithdrawalExpiryUpdateProps) => {
    const { selectedMethod, methods } = route.params;
    const navigation = useNavigation();
    const { t } = useTranslation('wallet');

    const onSuccessUpdateCard = () => {
        navigation.navigate('WithdrawForm', {
            selectedMethod,
            methods,
        });
    };

    const onPressSecondaryButton = () => {
        navigation.navigate('ChooseWithdrawalMethod', { methods });
    };

    return (
        <UpdateExpiryCardForm
            paymentTypeId={selectedMethod.payment_type_id}
            onSuccessUpdateCard={onSuccessUpdateCard}
            onPressSecondaryButton={onPressSecondaryButton}
            secondaryButtonLabel={t('withdrawal_update_expiry_card_form_secondary_label')}
        />
    );
};
