import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import CreditIcon from '@/assets/icons/credit';
import LightningBoltIcon from '@/assets/icons/lightning-bolt';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components/Box';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useDepositAmountConverter } from '@/feature/devsettings/hooks/use-dev-settings';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { designSystem, withOpacity } from '@/styles/styles';

import { preferredDebitPaymentSelector, useDepositPaymentMethods } from '../hooks/use-deposit-payment-methods';
import { AddPaymentMethod } from './AddPaymentMethod';

const styles = StyleSheet.create({
    tagContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
        borderRadius: 6,
        backgroundColor: withOpacity(designSystem.colors.purple2, 0.16),
        paddingVertical: 2,
        paddingLeft: 6,
        paddingRight: 8,
        marginHorizontal: 12,
    },
    tagLabel: {
        color: designSystem.colors.purple2,
        fontSize: 13,
    },
});

const FastestTag = () => {
    const { t } = useTranslation('common');

    return (
        <Box style={styles.tagContainer}>
            <LightningBoltIcon />
            <Text style={styles.tagLabel}>{t('fastest')}</Text>
        </Box>
    );
};

type PaymentMethodDebitCardProps = {
    selectedAmount: string;
    paysafeAccountId: string;
    canUse3ds: boolean;
};

export const AddPaymentMethodDebitCard = ({
    selectedAmount,
    paysafeAccountId,
    canUse3ds = false,
}: PaymentMethodDebitCardProps) => {
    const navigation = useNavigation();
    const content = useJurisdictionStore(store => store.jurisdictionSettings?.productConfig?.keyValuePairs);
    const { data: preferredDebitPayments = [] } = useDepositPaymentMethods({ select: preferredDebitPaymentSelector });

    const cmsContent = content?.['Content Keys'];
    const { t } = useTranslation('transactions');
    const { convertToMinorUnitAmount } = useDepositAmountConverter();

    return (
        <AddPaymentMethod
            icon={<CreditIcon />}
            title={t('add_debit_card')}
            description={cmsContent?.['deposit.debitCard.description'] ?? ''}
            tag={!preferredDebitPayments.length && <FastestTag />}
            onPress={() => {
                navigation.navigate('AddDebitCardWebView', {
                    selectedAmount: convertToMinorUnitAmount(selectedAmount),
                    paysafeAccountId,
                    canUse3ds,
                });
            }}
            analyticsEvent={AnalyticsEvent.DEPOSIT_METHOD_ADD_DEBIT}
        />
    );
};
