import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import SubtractIcon from '@/assets/icons/subtract';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { common } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';

import { WithdrawMethod } from '../hooks/use-withdrawal-methods';
import { styles as chooseWithdrawalMethodStyles } from '../screens/ChooseWithdrawalMethod';
import { WithdrawalMethodItem } from './WithdrawalMethodItem';

type WithdrawalMethodsProps = {
    methods: WithdrawMethod[];
    isPromptedFromForm?: boolean;
};

const styles = StyleSheet.create({
    container: {
        height: 435,
    },
});

const WithdrawalMethodsContent = ({ methods }: { methods: WithdrawMethod[] }) => {
    const { t } = useTranslation('wallet');
    return (
        <>
            <SizedBox value={26} />
            <Text fontWeight="600" fontSize={22}>
                {t('choose_withdrawal_method')}
            </Text>
            <SizedBox value={28} />

            {methods?.map((method: WithdrawMethod, idx: number) => (
                <View key={`${method.name}_${idx}`} style={common.full}>
                    {idx !== 0 ? <LineSeparator style={chooseWithdrawalMethodStyles.separator} /> : null}
                    <WithdrawalMethodItem method={method} methods={methods} />
                </View>
            ))}

            <SizedBox value={24} />
            <Banner
                leftIcon={<SubtractIcon />}
                borderColor={'gray5'}
                borderWidth={1}
                message={t('withdrawal_choose_method_banner')}
            />
            <SizedBox value={16} />
        </>
    );
};

export const WithdrawalMethods = ({ methods, isPromptedFromForm = false }: WithdrawalMethodsProps) => {
    const content = (
        <View style={[isPromptedFromForm && styles.container]}>
            <ScrollView contentContainerStyle={[common.alignCenter, common.paddingHorizontal]}>
                <WithdrawalMethodsContent methods={methods} />
            </ScrollView>
        </View>
    );

    if (!isPromptedFromForm && isWeb) {
        return <MaxWidthWrapper>{content}</MaxWidthWrapper>;
    }

    return content;
};
