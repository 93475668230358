import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import CheckmarkLargeWhite from '@/assets/icons/checkmark-large-white';
import { Button } from '@/components/ButtonComponent';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { RootStackParamList } from '@/navigation/types';

type ScreenPropsType = NativeStackScreenProps<RootStackParamList, 'SuccessModal'>;

export const SuccessModal = ({ route: { params } }: ScreenPropsType) => {
    usePreventAndroidBackNavigation();
    const {
        title,
        subtitle,
        primaryButton,
        closeIconMode = 'close',
        closeIconHandlePress,
        handlePress,
        secondaryButton,
        handleSecondaryPress,
    } = params;
    const navigation = useNavigation();

    return (
        <Screen edges={['top', 'bottom']}>
            <ScreenNavBar closeIconMode={closeIconMode} onClose={closeIconHandlePress} />
            <Box flex={1} paddingHorizontal="s16">
                <Box flex={1} justifyContent="center" alignItems="center">
                    <CheckmarkLargeWhite />
                    <Text textAlign="center" variant="headlineMedium" mt="s16" testID="successModalMsg">
                        {title}
                    </Text>
                    <Text textAlign="center" color="gray2" variant="bodyMedium" mt="s8" testID="successModalSubText">
                        {subtitle}
                    </Text>
                </Box>

                <Box pb="s16">
                    <Button
                        label={primaryButton}
                        variant="rounded"
                        type="active"
                        onPress={() => {
                            navigation.goBack();
                            handlePress?.();
                        }}
                        testID="successModalPrimaryButton"
                    />
                    {secondaryButton ? (
                        <Box mt="s12">
                            <Button
                                label={
                                    <Text textAlign={'center'} variant="titleMedium">
                                        {secondaryButton}
                                    </Text>
                                }
                                type="delete"
                                onPress={() => {
                                    if (handleSecondaryPress) {
                                        handleSecondaryPress();
                                    } else {
                                        navigation.goBack();
                                    }
                                }}
                                testID="successModalSecondaryButton"
                            />
                        </Box>
                    ) : null}
                </Box>
            </Box>
        </Screen>
    );
};
