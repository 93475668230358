import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { common, designSystem } from '@/styles/styles';

import { Text } from './TextComponent';

const styles = StyleSheet.create({
    root: {
        height: 56,
        borderBottomWidth: 1,
        borderBottomColor: designSystem.colors.gray5,
    },
});

type Props = {
    label: string;
    secondaryLabel?: string;
    buttonLabel: string;
    handlePress: () => void;
    testID?: string;
};

export const ActionItem = ({ label, secondaryLabel, buttonLabel, handlePress, testID }: Props) => {
    return (
        <View style={[styles.root, common.spaceBetweenRow]}>
            <View>
                <Text variant="bodyMedium">{label}</Text>
                {secondaryLabel ? (
                    <Text color={'gray2'} variant="bodySmall" testID="currentLimitAmount">
                        {secondaryLabel}
                    </Text>
                ) : null}
            </View>
            <TouchableOpacity onPress={handlePress} testID={testID}>
                <Text fontWeight={'bold'} textDecorationLine={'underline'} fontSize={15}>
                    {buttonLabel}
                </Text>
            </TouchableOpacity>
        </View>
    );
};
