import { useCallback, useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import { useIsNativeSportsbook } from '@/hooks/use-is-native-sportsbook';
import { useWebsocket } from '@/utils/websocket/provider';
import { OddsUpdateMessageOption } from '@/utils/websocket/types';
import { runStoreUpdate } from '@/utils/zustand';

import { useMarketIds } from '../hooks/betslip-hooks';
import { useSbkBetSlipStore } from '../hooks/use-sbk-betslip-store';

type MarketListener = (e: { data: OddsUpdateMessageOption[] }) => void;

const marketHandlers = new Map<string, MarketListener>();

/**
 * Subscribes to unique markets (`market:{marketId}` channel) and listens to `odds_update` messages
 * Handles odds changes, status changes, and market removals
 */
export const useSubscribeMarkets = () => {
    const { websocketService, connected, authenticated } = useWebsocket();
    const marketIds = useMarketIds();
    const handleOddsUpdateMessages = useSbkBetSlipStore(state => state.actions.handleOddsUpdateMessages);
    const isNativeSportsbook = useIsNativeSportsbook();

    const unsubscribeListener = useCallback(
        (marketId: string, listener: MarketListener) => {
            websocketService?.unsubscribePublic<'marketOddsUpdate'>(
                'market:{marketId}',
                { marketId },
                'odds_update',
                listener
            );
            marketHandlers.delete(marketId);
        },
        [websocketService]
    );

    const subscribeAll = useCallback(() => {
        // subscribe new handlers
        marketIds.forEach(marketId => {
            if (!marketHandlers.has(marketId)) {
                const handler = (data: OddsUpdateMessageOption[]) => {
                    runStoreUpdate(() => handleOddsUpdateMessages(data));
                };

                const listener = websocketService?.subscribePublic<'marketOddsUpdate'>(
                    'market:{marketId}',
                    { marketId },
                    'odds_update',
                    handler,
                    // rewind: '1' option is used to get the last odds_update message on subscription
                    // this is to handle the case when a user returns to the app and their selections have become stale
                    { params: { rewind: '1' } }
                );
                if (listener) {
                    marketHandlers.set(marketId, listener);
                }
            }
        });

        // unsubscribe unused handlers
        marketHandlers.forEach((listener, marketId) => {
            if (!marketIds.includes(marketId)) {
                unsubscribeListener(marketId, listener);
            }
        });
    }, [handleOddsUpdateMessages, marketIds, unsubscribeListener, websocketService]);

    useEffect(() => {
        if (isNativeSportsbook && authenticated && connected) {
            subscribeAll();
        }
    }, [authenticated, connected, isNativeSportsbook, subscribeAll]);

    const unsubscribeAll = useCallback(() => {
        marketHandlers.forEach((listener, marketId) => {
            unsubscribeListener(marketId, listener);
        });
    }, [unsubscribeListener]);

    // Clean up subscriptions
    useEffect(() => {
        return () => {
            unsubscribeAll();
        };
    }, [unsubscribeAll]);

    useEffect(() => {
        // When the user leaves the app, we want to unsubscribe (and detach) from all the market channels
        // When the user returns to the app, we want to resubscribe to all the market channels.
        // In doing so, we'll always receive the latest odds_update message for each market with rewind parameter set to `1`
        const handleAppStateChange = (nextAppState: AppStateStatus) => {
            if (nextAppState === 'background') {
                unsubscribeAll();
            } else if (nextAppState === 'active') {
                subscribeAll();
            }
        };
        const subscription = AppState.addEventListener('change', handleAppStateChange);

        return () => {
            subscription.remove();
        };
    }, [unsubscribeAll, subscribeAll]);
};
