import { GeocomplyMessage, GeocomplyPayload } from '@/data/location/types';

/**
 * Parses the geocomply payload and returns the error message
 */
export const getGeoComplyErrorMessage = (payload: GeocomplyPayload) => {
    let messages = payload.original_payload?.nodes?.troubleshooter?.message ?? [];
    if (!Array.isArray(messages)) {
        messages = [messages];
    }
    return messages.map((item: GeocomplyMessage) => item.content).join('\n');
};
