import { Platform } from 'react-native';
import { PERMISSIONS, RESULTS, check } from 'react-native-permissions';

import { UserInfo } from '@/hooks/use-auth-user-info';
import cryptojs from 'crypto-js';

import { EVENT_TYPES, RUDDERSTACK_EVENTS } from '../constants';
import { trackRudderStackEvent } from '../trackRudderStackEvent';

/**
 * Maps an object and return a new object with hashed values
 * e.g: { firstName: 'Jane' } => { firstName: 'TyN5jZJwg1m3NKGBcsnIZPHUgESnVBFaDUuEO8o6UzI=' }
 * @param object - The object to be mapped
 * @returns the new object with hashed values
 */
export const convertObjectToHashedValues = (object: Record<string, string>) => {
    const hashedObject = Object.fromEntries(
        Object.keys(object).map(key => [key, cryptojs.SHA256(object[key]).toString(cryptojs.enc.Hex)])
    );
    return hashedObject;
};

export const getHashedUserInfo = ({ userInfo }: { userInfo: UserInfo }) => {
    const { first_name: firstName, last_name: lastName, address, phone_number: phone, dob } = userInfo.data;
    const birthdayDate = new Date(`${dob.dob_year}-${dob.dob_month}-${dob.dob_day}`);
    const birthday = !isNaN(birthdayDate.getTime()) ? birthdayDate.toISOString() : '';
    const hashedAddress = convertObjectToHashedValues({
        city: address.city,
        country: address.country,
        postalCode: address.post_code,
        state: address.state,
        street: address.street_name,
    });
    const hashedObject = convertObjectToHashedValues({ firstName, lastName, phone, birthday });
    return { address: hashedAddress, ...hashedObject };
};

export const getAppTrackingPermissionStatus = async (): Promise<boolean> => {
    if (Platform.OS === 'ios') {
        const appTrackingPermissionStatus = await check(PERMISSIONS.IOS.APP_TRACKING_TRANSPARENCY);
        return appTrackingPermissionStatus === RESULTS.GRANTED;
    } else {
        return true;
    }
};

type EventArguments = Record<string, any>;
type EventType = keyof typeof RUDDERSTACK_EVENTS;

const createTrackRGEvent = (eventType: EventType) => {
    return (eventLabel: string, args: EventArguments = {}) => {
        const eventName = RUDDERSTACK_EVENTS[eventType](eventLabel);
        trackRudderStackEvent(eventName, {
            ...args,
            eventType: EVENT_TYPES.RG,
        });
    };
};

export const TrackRGEvent = {
    accountScreen: createTrackRGEvent('click'),
    depositLimitsScreen: createTrackRGEvent('click'),
    depositLimitAddScreen: createTrackRGEvent('submit'),
    depositLimitEditConfirmation: createTrackRGEvent('submit'),
    gamingLimitScreen: createTrackRGEvent('click'),
    maxSingleEntryLimitAddScreen: createTrackRGEvent('submit'),
    maxSingleEntryLimitsScreen: createTrackRGEvent('click'),
    playLimitsScreen: createTrackRGEvent('click'),
    playLimitAddScreen: createTrackRGEvent('submit'),
    realityCheckScreen: createTrackRGEvent('click'),
    selfExcludeConfirmation: createTrackRGEvent('submit'),
    timeoutScreen: createTrackRGEvent('click'),
    timeoutAddScreen: createTrackRGEvent('click'),
};
