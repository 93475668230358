import React from 'react';
import { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { useNavigation } from '@react-navigation/native';

import { SizedBox } from '@/components/SizedBox';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { Event } from '@/feature/event-details-sbk/types';
import { SbkLobbyTabNavigationProp } from '@/navigation/sbk/types';
import { designSystem } from '@/styles/styles';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

import { EventCard } from './event-card/EventCard';

type EventTickerProps = {
    events: Event[];
};

const keyExtractor = (item: Event) => item.id;
const ItemSeparator = () => <SizedBox value={12} />;

export const EventsTray = ({ events }: EventTickerProps) => {
    const navigation = useNavigation<SbkLobbyTabNavigationProp>();

    const navigateToEvent = useCallback(
        (event: Event) => {
            GASbkEvents.clickEvent(event.id);
            navigation.navigate('EventDetails', { eventId: event.id, leagueId: event?.league?.id });
        },
        [navigation]
    );

    const renderItem = useCallback(
        ({ item, index }: { item: Event; index: number }) => (
            <EventCard event={item} navigateToEvent={navigateToEvent} testID={index.toString()} />
        ),
        [navigateToEvent]
    );

    return (
        <FlatList
            data={events}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            ItemSeparatorComponent={ItemSeparator}
            horizontal
            style={styles.flatList}
            ListHeaderComponent={<SizedBox value={16} />}
            ListFooterComponent={<SizedBox value={16} />}
            showsHorizontalScrollIndicator={showScrollIndicator}
            testID="eventTickerFlatList"
        />
    );
};

const styles = StyleSheet.create({
    flatList: {
        backgroundColor: designSystem.colors.gray8,
    },
});
