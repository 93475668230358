import React from 'react';
import { useTranslation } from 'react-i18next';

import SubtractIcon from '@/assets/icons/subtract';
import { Button } from '@/components/ButtonComponent';
import { PaymentLogo } from '@/components/PaymentLogo';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { designSystem, withOpacity } from '@/styles/styles';
import { RemovableCardType } from '@/types/removable-cards';

const CARD_BRANDS: Record<string, string> = {
    VI: 'VISA',
    MC: 'MASTERCARD',
};

type ManageDebitCardContentProps = {
    card: RemovableCardType;
    primaryButtonLabel: string;
    handlePrimaryButtonPress: () => void;
    showSecondaryButton?: boolean;
    secondaryButtonLabel?: string;
    handleSecondaryButtonPress?: () => void;
};

export const ManageDebitCardContent = ({
    card,
    primaryButtonLabel,
    handlePrimaryButtonPress,
    showSecondaryButton = false,
    secondaryButtonLabel,
    handleSecondaryButtonPress,
}: ManageDebitCardContentProps) => {
    const { t } = useTranslation('manage_cards');
    return (
        <MaxWidthWrapper paddingHorizontal="s16" flex={1}>
            <Box flex={1} gap="s16">
                <Box flexDirection="row" alignItems="center">
                    <PaymentLogo source={card.payment_logos} />
                    <Text variant="headlineMedium">{CARD_BRANDS[card.card_brand] ?? ''}</Text>
                </Box>
                <Banner
                    leftIcon={<SubtractIcon color={designSystem.colors.red} />}
                    textColor="red"
                    message={t('expired_card_details_banner')}
                    style={{ backgroundColor: withOpacity(designSystem.colors.red, 0.1) }}
                />
                <Box>
                    <Text variant="bodyMedium" color="gray2">
                        {t('card_number')}
                    </Text>
                    <Text variant="bodyMedium" color="gray1">
                        {`•••• ${card.last4_digits}`}
                    </Text>
                </Box>
            </Box>
            <Box gap="s16">
                <Button label={primaryButtonLabel} variant="rounded" type="active" onPress={handlePrimaryButtonPress} />
                {showSecondaryButton ? (
                    <Button
                        label={
                            <Text textAlign="center" variant="titleMedium">
                                {secondaryButtonLabel}
                            </Text>
                        }
                        type="text"
                        onPress={handleSecondaryButtonPress}
                    />
                ) : null}
            </Box>
        </MaxWidthWrapper>
    );
};
