import React, { FC, PropsWithChildren } from 'react';
import { CellRendererProps, StyleSheet, View, useWindowDimensions } from 'react-native';

import { MAX_WEB_WIDTH, isWeb } from '@/utils/constants-platform-specific';

import { Box, BoxProps } from '../../components/lib/components';

const styles = StyleSheet.create({
    cellRenderer: {
        maxWidth: MAX_WEB_WIDTH,
        width: '100%',
    },
    web: {
        flexGrow: 1,
        alignItems: 'center',
    },
});

// Custom component to add a max-width to a simple FlatList (or other components that use CellRendererComponent)
// this component should be used along with webListContentContainerStyle
export const CellRendererComponent = isWeb
    ? ({ children, ...props }: CellRendererProps<unknown>) => {
          return (
              <View {...props} style={[props.style, styles.cellRenderer]}>
                  {children}
              </View>
          );
      }
    : undefined;
// style to be added to contentContainerStyle of FlatList (when using CellRendererComponent)
export const webListContentContainerStyle = isWeb ? styles.web : {};
// style to use when you want to create responsive component with max-width on web
export const webMaxWidthStyle = isWeb ? styles.cellRenderer : {};

type MaxWidthItemWrapperProps = Omit<BoxProps, 'children'> & { ratio?: number };

const MaxWidthItemWrapperNative: FC<PropsWithChildren<MaxWidthItemWrapperProps>> = ({ children, ...rest }) => {
    if (Object.keys(rest).length > 0) {
        return <Box {...rest}>{children}</Box>;
    }
    return children;
};

export const SCROLLBAR_WIDTH = isWeb ? 16 : 0;

const MaxWidthItemWrapperWeb: FC<PropsWithChildren<MaxWidthItemWrapperProps>> = ({ children, ratio = 1, ...rest }) => {
    const { width } = useWindowDimensions();

    const containerWidth = MAX_WEB_WIDTH > width ? '100%' : width / ratio - SCROLLBAR_WIDTH;

    return (
        <Box width={containerWidth} alignItems={'center'} {...rest}>
            <Box maxWidth={MAX_WEB_WIDTH} width={'100%'} height={'100%'}>
                {children}
            </Box>
        </Box>
    );
};

// This component should be used to wrap the content that should have a max-width on web
// it uses two Box components to handle the width and center the content, avoiding "display: flex"
// It accepts (almost) all Box props.
// On native, if not any additional props are provided, it will render the children directly
export const MaxWidthWrapper = isWeb ? MaxWidthItemWrapperWeb : MaxWidthItemWrapperNative;
