import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';

import { EventInfo, EventInfoWithoutPlayers } from '@/api/events/types/types';
import { useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { Row } from '@/components/lib/components';
import { PlayerPositionsFilters } from '@/feature/lobby/components/PlayerPositionFilters';
import PlayersList from '@/feature/lobby/components/PlayersList';
import { LeagueType } from '@/feature/lobby/hooks/use-league-selector';
import { Filters, leagueHasPositions } from '@/feature/lobby/utils/filters';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { common, designSystem } from '@/styles/styles';

type PositionTabViewProps = {
    selectedLeague: LeagueType;
    sortedEvents: EventInfoWithoutPlayers[] | EventInfo[];
    hideLoader: boolean;
    loading: boolean;
    refresh: (silent?: boolean) => void;
    separatorStyles: StyleProp<ViewStyle>;
    openPlayerWithId?: string;
    translationDistance: number;
};

export const PositionTabView = ({
    selectedLeague,
    sortedEvents,
    hideLoader,
    loading,
    refresh,
    separatorStyles,
    openPlayerWithId,
    translationDistance,
}: PositionTabViewProps) => {
    const shouldRenderFilters = sortedEvents.length > 0 && leagueHasPositions(selectedLeague.id);
    const { smoothScrollHeaderStyleBelowTabsPullToRefresh } = useStickyTabsAnimation();

    return (
        <>
            {shouldRenderFilters ? (
                <Animated.View
                    style={[
                        common.full,
                        smoothScrollHeaderStyleBelowTabsPullToRefresh,
                        {
                            backgroundColor: designSystem.colors.gray8,
                            // filters should be just bellow the tabs
                            top: translationDistance + TAB_HEIGHT,
                        },
                    ]}
                >
                    <MaxWidthWrapper>
                        <Row py={'s16'}>
                            <PlayerPositionsFilters key={`filters_${selectedLeague.id}`} league={selectedLeague.id} />
                        </Row>
                    </MaxWidthWrapper>
                </Animated.View>
            ) : null}
            <Animated.View style={[common.separator, separatorStyles]} />
            <PlayersList
                openPlayerWithId={openPlayerWithId}
                key={selectedLeague.id}
                sortedEvents={sortedEvents}
                loading={!hideLoader && loading}
                onRefresh={refresh}
                filterType={Filters.Position}
                translationDistance={translationDistance}
                hasFilters={shouldRenderFilters}
                analyticsTag={'Players Tab'}
            />
        </>
    );
};
