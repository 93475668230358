import React from 'react';
import { DimensionValue } from 'react-native';

import { SEPARATOR_HEGIHT } from '@/styles/styles';

import { Box } from '../lib/components';
import { AppSpacing, DesignSystemColor } from '../lib/theme';

export const Seperator = ({
    show,
    styles,
}: {
    show: boolean;
    styles: { backgroundColor: DesignSystemColor; height: DimensionValue; marginTop: AppSpacing };
}) => <Box opacity={show ? 1 : 0} {...styles} width={SEPARATOR_HEGIHT} />;
