import { URLS, createJurisdictionHeaders } from '@/data';
import { authUserKeys } from '@/hooks/use-auth-user-config';
import { user } from '@/hooks/use-user';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

export type UserInfo = z.infer<typeof UserInfoSchema>;

const UserInfoDataSchema = z.object({
    username: z.string(),
    email: z.string(),
    phone_number: z.string(),
    phone_number_country_code: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    dob: z.object({
        dob_day: z.number(),
        dob_month: z.number(),
        dob_year: z.number(),
    }),
    kyc_verified: z.boolean(),
    national_id: z.string(),
    address: z.object({
        street_name: z.string(),
        city: z.string(),
        state: z.string(),
        post_code: z.string(),
        country: z.string(),
    }),
});

const UserInfoSchema = z.object({
    data: UserInfoDataSchema,
});

export const getUserInfo = async (): Promise<UserInfo> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/user-information`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return UserInfoSchema.parse(data);
};

export const useAuthUserInfo = () =>
    useQuery(authUserKeys.info(), () => getUserInfo(), {
        enabled: !user.sessionHasExpired(),
        staleTime: 1000 * 60 * 3,
        select: data => data.data,
    });
