import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { ScrollableModal } from '@/feature/alerts/components/Modal';
import { common } from '@/styles/styles';
import { League } from '@/types/api.generated';
import { NFL_FULL_SEASON } from '@/utils/constants';
import { nflFullSeason } from '@/utils/league';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

export type FantasyPointsProps = {
    league: League;
};
export type FantasyPointsModalRef = {
    show: (data: FantasyPointsProps) => void;
};

export const FantasyPointsModal = React.forwardRef<FantasyPointsModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<FantasyPointsProps | undefined>(undefined);
    const modalRef = useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    const { t } = useTranslation('pickem_leagues');
    const leagueTitle = nflFullSeason(data?.league) ? NFL_FULL_SEASON : data?.league;
    const safeInsets = useSafeAreaInsets();

    return (
        <ScrollableModal
            sheetRef={modalRef}
            showsVerticalScrollIndicator={false}
            id={'fantasyPointsBottomSheet'}
            contentContainerStyle={[common.paddingHorizontal, { paddingBottom: safeInsets.bottom }]}
            stickyHeader={
                <Box px={'s16'}>
                    <Text variant={'titleLarge'} textAlign={'center'}>
                        {t('fantasy_scoring_system')}
                    </Text>
                    <SizedBox value={20} />
                    <Text color={'gray2'} variant="bodySmall">
                        {t('fractional_points')}
                    </Text>
                    {data?.league === League.Mlb ? (
                        <Box mt={'s8'}>
                            <Text color={'gray2'} variant="bodySmall">
                                {t('mlb_scoring_notes.scoring_notes')}
                            </Text>
                            <Text color={'gray2'} variant="bodySmall">
                                {t('mlb_scoring_notes.hit_statistics')}
                            </Text>
                        </Box>
                    ) : null}
                    <SizedBox value={28} />
                    <Text variant="headlineMedium">{leagueTitle}</Text>
                    <SizedBox value={28} />
                </Box>
            }
        >
            {data && <ModalContent league={data.league} />}
        </ScrollableModal>
    );
});

const ModalContent = ({ league }: FantasyPointsProps) => {
    const { t } = useTranslation('pickem_leagues');
    const scorings = t('scorings', { returnObjects: true });
    const leagueId = league === League.NflFullSeason ? League.Nfl : league;
    const hasScoring = Object.keys(scorings).includes(leagueId);
    const fantasyPoints = hasScoring ? scorings[leagueId] : [];
    const { height } = useWindowDimensions();

    if (typeof fantasyPoints === 'string') {
        return (
            <Box height={height}>
                <Text variant="bodyMedium">{t('scoring_not_available')}</Text>
            </Box>
        );
    }

    return fantasyPoints.map((item, index) => {
        return (
            <View key={`${item.name}-${index}`}>
                <Text variant="titleLarge">{item.name}</Text>
                <SizedBox value={28} />
                <>
                    {item.points.map(fantasyPoint => (
                        <View key={fantasyPoint.name}>
                            <Text variant="bodyMedium">{fantasyPoint.name}</Text>
                            <Text color={'gray2'} variant="bodySmall">
                                {fantasyPoint.value}
                            </Text>
                            <SizedBox value={15} />
                            <LineSeparator />
                            <SizedBox value={16} />
                        </View>
                    ))}
                </>
            </View>
        );
    });
};
