import { getAllBets } from '@/feature/bets-sbk/hooks/use-bets';
import { SBK_BETSLIP_LOG_TAG } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { logger } from '@/utils/logging';

// Helper delay function
export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

// fetch open bets from /auth/bets endpoint, check if all submitted bets are in open bets
export const pollSubmittedBets = async (
    submittedBetIds: string[]
): Promise<{ success: boolean; globalBetIds: string[] }> => {
    let success = false;
    let globalBetIds: string[] = [];
    try {
        const openBets = await getAllBets({ tab: 'Open' });
        const allBetSubmitted = submittedBetIds.every(betId => {
            return openBets.data.find(bet => bet.bet_producer_id === betId);
        });
        if (allBetSubmitted) {
            success = true;
            globalBetIds = openBets.data
                .filter(bet => submittedBetIds.includes(bet.bet_producer_id))
                .map(bet => bet.id);
            return { success, globalBetIds };
        }
    } catch (e) {
        logger.warn(SBK_BETSLIP_LOG_TAG, 'bet submission poll error', e);
    }
    return { success, globalBetIds };
};
