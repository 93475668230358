import { createJurisdictionHeaders } from '@/data';
import { URLS } from '@/data/config';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { userLimitsKeys } from './query-keys';
import { ConfirmUserLimitPayload } from './types';

/**
 * React query mutation hook to confirm pending user limits
 * POST /auth/user-limits/confirm-limit
 * On success it invalidates all the user limits queries
 * @returns {Object} - react-query mutation object
 */
export const useConfirmPendingLimits = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (payload: ConfirmUserLimitPayload) => {
            const body = JSON.stringify(payload);
            return fetch(`${URLS.CHAMELON_API_URL}/auth/user-limits/confirm-limit`, {
                method: 'POST',
                headers: {
                    ...createJurisdictionHeaders(),
                    'Content-Type': 'application/json',
                },
                body,
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(userLimitsKeys.all);
            },
        }
    );
};
