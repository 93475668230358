import React, { ReactNode } from 'react';

import DiscountIcon from '@/assets/icons/discount';
import InflatedIcon from '@/assets/icons/inflated';
import RocketIcon from '@/assets/icons/rocket';
import { designSystem } from '@/styles/styles';
import { ProjectionType } from '@/types/api.generated';

import { getColorByProjType } from './getProjectionsTypes';

const { colors } = designSystem;
export const getIconByProjType = ({
    type,
    specialIncrease,
    color,
}: {
    type: ProjectionType;
    specialIncrease?: boolean;
    color?: string;
}): ReactNode | null => {
    const iconColor = color ?? colors[getColorByProjType(type, specialIncrease)];

    switch (type) {
        case ProjectionType.Boosted:
            return <RocketIcon color={iconColor} width={16} height={16} />;
        case ProjectionType.Special: {
            return specialIncrease ? (
                <InflatedIcon color={iconColor} width={16} height={16} />
            ) : (
                <DiscountIcon color={iconColor} width={16} height={16} />
            );
        }
        case ProjectionType.Regular:
        default:
            return null;
    }
};
