import React, { ReactNode } from 'react';

import LockIcon from '@/assets/icons/lock';
import { AnimatedFadeOutView } from '@/components/AnimatedFadeOutView';
import { Box } from '@/components/lib/components';
import { AnimatedOddsChangeIcon } from '@/feature/betslip-sbk/components/AnimatedOddsChangeIcon';

type BetListIconProps = {
    odds: number;
    previousOdds?: number;
    isSuspended: boolean;
};

type IconWrapperProps = {
    children: ReactNode;
    top: number;
    left: number;
};

const IconWrapper = ({ children, top, left }: IconWrapperProps) => (
    <Box width={16} height={16} position="absolute" top={top} left={left}>
        {children}
    </Box>
);

export const BetListIcon = ({ odds, previousOdds, isSuspended }: BetListIconProps) => {
    const hasOddsChanged = !!previousOdds;
    const shouldShowAnimatedOddsChangeIcon = previousOdds && (previousOdds < odds || previousOdds > odds);

    if (isSuspended) {
        return (
            <IconWrapper top={16} left={-6}>
                <LockIcon />
            </IconWrapper>
        );
    }

    return (
        <IconWrapper top={20} left={-3}>
            {shouldShowAnimatedOddsChangeIcon ? (
                <AnimatedOddsChangeIcon hasOddsChanged={hasOddsChanged} previousOdds={previousOdds} odds={odds} />
            ) : (
                <AnimatedFadeOutView>
                    <Box width={8} height={8} left={1} borderRadius="r8" backgroundColor="gray5" />
                </AnimatedFadeOutView>
            )}
        </IconWrapper>
    );
};
