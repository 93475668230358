import { useNavigation } from '@react-navigation/native';

import { URLS, createJurisdictionHeaders } from '@/data';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import { kycRouteNames } from '../const';
import { useKycFieldsStore } from './use-kyc-fields';

const LOG_TAG = '[PROVE KYC]';

// Added this for type reference
export const FetchKycDataBySsnResponseSchema = z.object({
    response: z.object({
        individual: z.object({
            firstName: z.string().nullish(),
            lastName: z.string().nullish(),
            addresses: z
                .array(
                    z
                        .object({
                            address: z.string().nullish(),
                            city: z.string().nullish(),
                            region: z.string().nullish(),
                            postalCode: z.string().nullish(),
                        })
                        .nullish()
                )
                .nullish(),
            dob: z.string().nullish(),
            ssn: z.string().nullish(),
        }),
    }),
});

/**
 * This function takes a date of birth string in the 'yyyy-MM-dd' format and returns
 * a formatted string in the 'MMddyyyy' format.
 *
 * @param dob - The date of birth in 'yyyy-MM-dd' format.
 * @returns The formatted date of birth in 'MMddyyyy' format.
 *
 */
const composeDob = (dob: string) => {
    const parts = dob.split('-');
    return `${parts[1]}${parts[2]}${parts[0]}`;
};

/**
 * Retrieves pre-fill information for KYC verification using the last four digits of the customer's Social Security Number.
 *
 * POST /auth/user/prove-kyc
 *
 * @param ssn4 - The last 4 digits of the customer's Social Security Number.
 */
export const fetchKycDataBySsn = async (ssn4: string) => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/prove-kyc`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ssn4 }),
    });

    const data = await response.json();
    if (!response.ok) {
        logger.warn(LOG_TAG, data);
        throw data;
    }

    logger.info(LOG_TAG, data);
    return FetchKycDataBySsnResponseSchema.parse(data);
};

/**
 * Custom react query mutation hook to fetch pre-fill information for KYC verification.
 *
 * POST /auth/user/prove-kyc
 *
 * @returns {Object} - react-query mutation object
 */
export const useProveKycMutation = () => {
    const navigation = useNavigation();

    const setMultipleFieldValues = useKycFieldsStore(state => state.setMultipleFieldValues);

    return useMutation(fetchKycDataBySsn, {
        onSuccess: data => {
            const { firstName, lastName, addresses, dob, ssn } = data.response.individual;

            const address = addresses?.[0] || {};

            setMultipleFieldValues({
                ssn: ssn ? ssn.slice(-4) : '',
                firstName: firstName ?? '',
                lastName: lastName ?? '',
                dateOfBirth: dob ? composeDob(dob) : '',
                address: address?.address ?? '',
                city: address?.city ?? '',
                americanState: address?.region ?? '',
                zip: address?.postalCode ?? '',
            });

            navigation.navigate(kycRouteNames.REVIEW_PERSONAL_INFO);
        },
    });
};
