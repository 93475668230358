import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';

type MoreLessComponentProps = {
    truncatedText: string;
    fullText: string;
};

const MoreLessComponent = ({ truncatedText, fullText }: MoreLessComponentProps) => {
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation(['common']);

    return (
        <Box>
            <Text color={'gray2'} variant={'bodySmall'}>
                {!expanded ? `${truncatedText}... ` : fullText + ' '}
                <TouchableOpacity onPress={() => setExpanded(!expanded)} style={styles.expandButton}>
                    <Text variant={'titleSmall'}>{expanded ? t('common:see_less') : t('common:see_more')}</Text>
                </TouchableOpacity>
            </Text>
        </Box>
    );
};

type Props = {
    text: string;
    linesToTruncate: number;
};

export const ExpandableText = ({ text, linesToTruncate }: Props) => {
    const [clippedText, setClippedText] = useState('');

    return clippedText ? (
        <MoreLessComponent truncatedText={clippedText} fullText={text} />
    ) : (
        <Text
            variant={'bodySmall'}
            color={'gray2'}
            numberOfLines={clippedText ? linesToTruncate : 3}
            ellipsizeMode={'tail'}
            onTextLayout={event => {
                const { lines } = event.nativeEvent;
                if (lines.length > linesToTruncate) {
                    const textInfo = lines
                        .splice(0, linesToTruncate)
                        .map(line => line.text)
                        .join('');
                    setClippedText(textInfo.slice(0, textInfo.length - 20));
                }
            }}
        >
            {text}
        </Text>
    );
};

const styles = StyleSheet.create({
    expandButton: {
        transform: [{ translateY: 3 }],
    },
});
