import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = ({ width = 16, height = 16, fill = '#6D707C', stroke = '#6D707C', ...props }) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none" {...props}>
        <Path fill={fill} d="M2 2.75h12V4.5H2zM5.34 1h5v1.75h-5z" />
        <Path stroke={stroke} strokeWidth={1.75} d="M3.875 3.625h8.25v10.25h-8.25z" />
    </Svg>
);
export default SvgComponent;
