import { useCallback, useEffect } from 'react';

import { EVENT_TYPES, RUDDERSTACK_EVENTS } from '@/feature/analytics/constants';
import { trackRudderStackEvent } from '@/feature/analytics/trackRudderStackEvent';
import { getHashedUserInfo } from '@/feature/analytics/utils/rudderUtils';
import { userLimitsKeys } from '@/feature/responsible-gaming/hooks/query-keys';
import { withdrawalMethodKeys } from '@/feature/withdraw/hooks/use-withdrawal-methods';
import { authUserKeys, hasMadeFirstDepositSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { getUserInfo } from '@/hooks/use-auth-user-info';
import { logger } from '@/utils/logging';
import { useQueryClient } from '@tanstack/react-query';

import { PayPalStatus, constructFTDPayload, getDepositStatus, getFormattedDepositAmount } from '../utils';
import { PaymentMethodType } from './use-deposit-payment-methods';

const DEPOSIT_STATUS_LOG_TAG = '[Deposit-Status]';

// this event name is currently required to sync with marketing tracking
const FIRST_DEPOSIT_COMPLETE = 'first_deposit_complete';
const FIRST_DEPOSIT_FAILURE = 'first_deposit_failure';

export const useTrackFirstDepositEvent = (
    currentStatus: PayPalStatus,
    paymentProvider: PaymentMethodType,
    selectedAmount: string,
    errorCode?: string
) => {
    const queryClient = useQueryClient();

    const { data: hasMadeFirstDeposit, isLoading } = useAuthUserConfig({
        select: hasMadeFirstDepositSelector,
    });

    const trackFirstDepositEvent = useCallback(async () => {
        const depositAmount = getFormattedDepositAmount(selectedAmount);
        const ftdPayload = constructFTDPayload(paymentProvider, currentStatus, depositAmount);
        const depositStatus = getDepositStatus(currentStatus);

        let hashedUserInfo = null;
        try {
            const userInfo = await getUserInfo();
            if (userInfo?.data) {
                hashedUserInfo = getHashedUserInfo({ userInfo });
            }
        } catch (e) {
            logger.error(DEPOSIT_STATUS_LOG_TAG, 'Error loading user info', e);
        }

        trackRudderStackEvent(
            RUDDERSTACK_EVENTS.custom(
                currentStatus === PayPalStatus.SUCCESS ? FIRST_DEPOSIT_COMPLETE : FIRST_DEPOSIT_FAILURE
            ),
            {
                ...ftdPayload,
                eventType: EVENT_TYPES.FTD,
                depositStatus,
                ...(hashedUserInfo && hashedUserInfo),
                ...(errorCode ? { errorCode } : {}),
            }
        );
    }, [currentStatus, errorCode, paymentProvider, selectedAmount]);

    useEffect(() => {
        if (!hasMadeFirstDeposit && !isLoading) {
            trackFirstDepositEvent();
        }
    }, [hasMadeFirstDeposit, isLoading, trackFirstDepositEvent]);

    useEffect(() => {
        if (currentStatus === PayPalStatus.SUCCESS) {
            queryClient.invalidateQueries(userLimitsKeys.limits());
            queryClient.invalidateQueries(withdrawalMethodKeys.all);
            queryClient.invalidateQueries(authUserKeys.config());
        }
    }, [currentStatus, queryClient, paymentProvider, selectedAmount, hasMadeFirstDeposit]);
};
