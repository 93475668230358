import React from 'react';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';

export type EmptyBetsProps = {
    title: string;
    textMessage: string;
};

export const EmptyBets = ({ title, textMessage }: EmptyBetsProps) => {
    return (
        <Box flex={1} justifyContent="center" alignItems="center" paddingHorizontal="s40">
            <Text variant="titleLarge" testID="emptyBetsTitle">
                {title}
            </Text>
            <Text textAlign="center" color="gray2" variant="bodyMedium" letterSpacing={-0.23} testID="emptyBetsMessage">
                {textMessage}
            </Text>
        </Box>
    );
};
