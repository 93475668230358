import { useMemo } from 'react';

import { PlayerInfoWithProjectionsFragment, useLeagueUpcomingEventsQuery } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import { useUpdatePlayerStoreWithNewData } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { eventUtils } from '@/feature/betslip-pickem/utils/event-utils';
import { League } from '@/types/api.generated';

export type PlayerAndEventInfo = {
    player: PlayerInfoWithProjectionsFragment;
    event: EventInfo;
};

export const usePlayersSearch = (query: string, league: League) => {
    const [{ data: eventsWithPlayers, fetching: loading }] = useLeagueUpcomingEventsQuery({
        variables: { league: league },
        requestPolicy: 'cache-and-network',
        pause: !league,
    });

    useUpdatePlayerStoreWithNewData(eventsWithPlayers?.getUpcomingEventsV2);

    const players = useMemo(() => {
        const playersWithEvents: PlayerAndEventInfo[] = [];
        eventsWithPlayers?.getUpcomingEventsV2.forEach(event => {
            const allPlayersInEvent = eventUtils.getAllPlayers(event);
            allPlayersInEvent.forEach(player => {
                playersWithEvents.push({ player, event });
            });
        });
        return playersWithEvents;
    }, [eventsWithPlayers?.getUpcomingEventsV2]);

    const playersToDisplay = useMemo(() => {
        const queryWords = query.trim().toLowerCase().split(/\s+/);
        return players.filter(({ player: { firstName, lastName } }) => {
            const fullName = `${firstName.toLowerCase()} ${lastName.toLowerCase()}`;

            // Check if every word in the query matches some part of the full name
            return queryWords.every(word => fullName.includes(word));
        });
    }, [players, query]);

    return { playersToDisplay, loading };
};
