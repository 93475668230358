import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';
import {
    FantasyPointsModal,
    FantasyPointsModalRef,
    FantasyPointsProps,
} from '@/feature/betslip-pickem/components/FantasyPointsModal';

type FantasyPointsContextType = {
    showFantasyPointsModal: (props: FantasyPointsProps) => void;
};

const FantasyPointsContext = React.createContext<FantasyPointsContextType>({
    showFantasyPointsModal: () => {},
});

/**
 * Provides access to the Modal used to display the rules by which fantasy points are calculated
 */
export const FantasyPointsModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const fantasyPointsModalRef = useRef<FantasyPointsModalRef>(null);

    const showFantasyPointsModal = useCallback((props: FantasyPointsProps) => {
        fantasyPointsModalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <FantasyPointsContext.Provider value={{ showFantasyPointsModal }}>
                {children}
                <FantasyPointsModal ref={fantasyPointsModalRef} />
            </FantasyPointsContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useFantasyPoints = () => {
    return useContext(FantasyPointsContext);
};
