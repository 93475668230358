import { useCallback } from 'react';

import { TabType } from '@/feature/bets-sbk/hooks/types';
import { betKeys, getAllBets } from '@/feature/bets-sbk/hooks/use-bets';
import { useQueryClient } from '@tanstack/react-query';

export const usePrefetchBets = (): ((tab: TabType) => void) => {
    const queryClient = useQueryClient();

    return useCallback(
        async (tab: TabType) => {
            queryClient.removeQueries(betKeys.infiniteList({ tab: tab }));
            await queryClient.prefetchInfiniteQuery(betKeys.infiniteList({ tab: tab }), () =>
                getAllBets({ tab: tab, page: 1 })
            );
        },
        [queryClient]
    );
};
