import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M10.2476 4.10616L2.63011 17.9561C1.89701 19.289 2.86134 20.9199 4.38254 20.9199H19.6175C21.1387 20.9199 22.103 19.289 21.3699 17.9561L13.7524 4.10616C12.9926 2.72461 11.0074 2.72461 10.2476 4.10616Z"
            stroke="#EDEFF3"
            strokeWidth="1.75"
        />
        <Path d="M12 8.75V14.25M12 16.25V18" stroke="#000000" fillRule="nonzero" strokeWidth="1.75" />
    </Svg>
);
export default SvgComponent;
