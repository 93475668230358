import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useAddUserLimits } from '@/feature/responsible-gaming/hooks/use-add-user-limits';
import { getConfirmDialogData } from '@/feature/responsible-gaming/utils/utils';
import { AccountTabNavigationProp, RootStackParamList } from '@/navigation/types';

import { LimitsModal } from '../components/LimitModal';
import { LIMIT_DURATION } from '../constants';
import { useAddLimitErrorMessage } from '../hooks/use-add-limit-error-message';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'EntryLimitEdit'>;

export const EntryLimitEdit = ({ route: { params } }: ScreenProps) => {
    const { t } = useTranslation(['rg', 'common']);
    const navigation = useNavigation<AccountTabNavigationProp>();
    const { showInfoSheet } = useAlerts();

    const { duration } = params;
    const durationTextLower = `${LIMIT_DURATION[duration].toLowerCase()} play`;
    const { getErrorMessage } = useAddLimitErrorMessage(duration, 'WAGER');
    const errorMessageHandler = (value: number) => getErrorMessage(value);
    const { mutate: confirmLimit } = useAddUserLimits();

    const onConfirmPress = (amount: number) => {
        confirmLimit(
            { type: 'WAGER', duration: params.duration, amount },
            {
                onSuccess: () => {
                    BetrAnalytics.trackProductEvent(
                        amount ? AnalyticsEvent.CONFIRM_UPDATE_PLAY_LIMIT : AnalyticsEvent.CONFIRM_REMOVE_PLAY_LIMIT,
                        {
                            duration,
                            amount,
                        }
                    );
                    navigation.popToTop();
                },
            }
        );
    };

    const handlePress = (amount: number) => {
        BetrAnalytics.trackProductEvent(amount ? AnalyticsEvent.UPDATE_PLAY_LIMIT : AnalyticsEvent.REMOVE_PLAY_LIMIT, {
            duration,
            amount,
        });

        const existingLimitAmount = params.amount;
        const hasExistingLimit = existingLimitAmount !== 0;

        const { title, description } = getConfirmDialogData({
            oldAmount: existingLimitAmount,
            newAmount: amount,
            limitName: durationTextLower,
        });

        if (hasExistingLimit) {
            showInfoSheet({
                title: title,
                description,
                buttonLabel: t('common:confirm'),
                handlePress: () => onConfirmPress(amount),
                showCancel: true,
                onPressCancel: () => {
                    BetrAnalytics.trackProductEvent(
                        amount ? AnalyticsEvent.CANCEL_UPDATE_PLAY_LIMIT : AnalyticsEvent.CANCEL_REMOVE_PLAY_LIMIT,
                        {
                            duration,
                        }
                    );
                },
            });
        } else {
            onConfirmPress(amount);
        }
    };

    const title = t('duration_play_limit', { duration: LIMIT_DURATION[duration] });

    return (
        <LimitsModal
            title={title}
            buttonLabel={t('update_duration_limit', { duration: LIMIT_DURATION[duration] })}
            handleButtonPress={handlePress}
            initialValue={params.amount}
            errorMessageHandler={errorMessageHandler}
            handleRemovePress={handlePress}
        />
    );
};
