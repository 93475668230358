import { createJurisdictionHeaders } from '@/data';
import { URLS } from '@/data/config';
import { LOG_TAG_ACCOUNT_DETAILS_UPDATE } from '@/feature/verify-phone-number/utils/constants';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';

/**
 * Validate verification code
 * @returns verification code validation response
 */
const validateVerificationCode = async (verificationCode: string): Promise<boolean> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/verify-code`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: verificationCode }),
    });

    const responseData = await response.json();

    if (!response.ok) {
        logger.warn(LOG_TAG_ACCOUNT_DETAILS_UPDATE, 'Error validating verification code', responseData);
        throw { message: responseData.message || 'Error validating verification code', responseData };
    }

    return responseData.success;
};

/**
 * Custom react-query hook for validating verification code
 * @returns verification code validation response
 */
export const useValidateVerificationCode = () => {
    return useMutation(['request-verification-code'], (verificationCode: string) =>
        validateVerificationCode(verificationCode)
    );
};
