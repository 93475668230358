import { useEffect, useState } from 'react';

const getInitialDate = (targetDate: Date) => {
    const timeLeft = targetDate.getTime() - new Date().getTime();

    if (timeLeft < 0) {
        return 0;
    }
    return timeLeft;
};

/**
 * Hook that returns the seconds left until a target date.
 * @param targetDate the target date to count down to
 * @returns the seconds left until the target date
 */
export const useCountdownSeconds = (targetDate: Date) => {
    const [timeLeft, setTimeLeft] = useState(getInitialDate(targetDate));

    useEffect(() => {
        let countDownDate = targetDate?.getTime();
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDownDate - now;

            if (distance < 0) {
                // If the countdown is finished, clear the interval
                clearInterval(interval);
                setTimeLeft(0);
            } else {
                setTimeLeft(distance);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate]);

    const seconds = Math.floor(timeLeft / 1000);

    return seconds;
};
