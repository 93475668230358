import { useEffect, useState } from 'react';

import { MicroMarketChipItem } from '@/feature/league-hub-sbk/components/MicroMarketChipBar';
import { MicroMarketData } from '@/feature/league-hub-sbk/hooks/use-micro-market-data';

export const useActiveMicroMarket = (data: MicroMarketData, chipItems: MicroMarketChipItem[]) => {
    const [activeMicroMarket, setActiveMicroMarket] = useState<MicroMarketChipItem | undefined>();

    useEffect(() => {
        const isActiveItemInData = activeMicroMarket && activeMicroMarket.id in data;
        if (!activeMicroMarket || !isActiveItemInData) {
            setActiveMicroMarket(chipItems[0]);
        }
    }, [activeMicroMarket, data, chipItems]);

    return {
        activeMicroMarket,
        setActiveMicroMarket: (item: MicroMarketChipItem) => setActiveMicroMarket(item),
    };
};
