import { Event, Team } from '@/feature/event-details-sbk/types';

/**
 * Returns the team object given a teamId and an event
 * @param teamId - the team id
 * @param event - the event object
 */
export const getTeam = (teamId?: string, event?: Pick<Event, 'home_team' | 'away_team'>): Team | undefined => {
    if (event?.home_team.id === teamId) {
        return event?.home_team;
    }
    if (event?.away_team.id === teamId) {
        return event?.away_team;
    }
    return;
};
