import { useRef } from 'react';

import { EventArg } from '@react-navigation/native';

import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';

/**
 * Hook to track the toggle event of the bet slip between singles and combo
 */
export const useTrackToggleBetSlip = () => {
    const isUserAction = useRef(false);
    const screenListeners = {
        focus: (data: EventArg<'focus'>) => {
            if (isUserAction.current) {
                const isSinglesTab = data.target?.includes('Singles');
                if (isSinglesTab !== undefined) {
                    GASbkEvents.toggleBetTypeTab(isSinglesTab ? 'singles' : 'combo');
                }
                isUserAction.current = false;
            }
        },
        swipeStart: () => {
            isUserAction.current = true;
        },
        tabPress: () => {
            isUserAction.current = true;
        },
    };
    return { screenListeners };
};
