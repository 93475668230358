import { URLS, createJurisdictionHeaders } from '@/data';

import { SubmitBetError } from '../../types';
import { PlaceBetsRequest, PlaceBetsResponse } from './types';

/**
 * Submits a bet to the chameleon API
 * POST /auth/bet
 * @param payload
 */
export const submitBets = async (payload: PlaceBetsRequest): Promise<PlaceBetsResponse> => {
    const body = JSON.stringify(payload);
    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/bet`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body,
    });
    const data = await resp.json();
    if (!resp.ok) {
        // TODO: Check the 3rd argument here,
        // Right now params are being passed from the errors object, but it needs to be moved.
        throw new SubmitBetError(data.message, data.error_codes[0], data.errors);
    }
    return data;
};
