import { useMemo } from 'react';

import { useMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { useSubscribePublic } from '@/utils/websocket/hooks';

// example usage for subscribing to event:{eventId}:markets:{marketGroupId} channel
export const useSubscribeEventOddsUpdate = (eventId: string, marketGroupId: string, enabled?: boolean) => {
    const handleOddsUpdate = useMarketCacheStore(state => state.actions.handleOddsUpdate);
    const params = useMemo(() => ({ eventId, marketGroupId }), [eventId, marketGroupId]);
    useSubscribePublic<'eventOddsUpdate'>(
        'event:{eventId}:marketGroup:{marketGroupId}',
        params,
        'odds_update',
        handleOddsUpdate,
        enabled
    );
};
