import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { AccountDetailsScreen } from '@/feature/account-details/screens/AccountDetails';
import { PreferencesScreen } from '@/feature/preferences/screens/Preferences';
import { DepositLimitsScreen } from '@/feature/responsible-gaming/screens/DepositLimits';
import { EntryLimitsScreen } from '@/feature/responsible-gaming/screens/EntryLimits';
import { MaxSingleEntryLimitsScreen } from '@/feature/responsible-gaming/screens/MaxSingleEntryLimits';
import { RealityCheck } from '@/feature/responsible-gaming/screens/RealityCheck';
import { SelfExclusionAdd } from '@/feature/responsible-gaming/screens/SelfExclusionAdd';
import { TimeoutScreen } from '@/feature/responsible-gaming/screens/Timeout';
import { TransactionDetailsScreen } from '@/feature/transactions/screens/TransactionDetails';
import { TransactionHistoryScreen } from '@/feature/transactions/screens/TransactionHistory';
import { YearEndStatementScreen } from '@/feature/year-end-statement/screens/YearEndStatement';

import { AccountStackParamList } from '../../navigation/types';
import { LimitsHistoryScreen } from '../responsible-gaming/screens/LimitsHistory';
import { AccountScreen } from './screens/AccountScreen';
import { GamingLimitsScreen } from './screens/GamingLimits/GamingLimits';
import { StateSelector } from './screens/StateSelector';

const Stack = createNativeStackNavigator<AccountStackParamList>();

const screenOptions = {
    headerShown: false,
};

export const AccountStackTabs = () => {
    return <AccountStackInner mode="tabs" />;
};

export const AccountStack = () => {
    return <AccountStackInner mode="stack" />;
};

const AccountStackInner = ({ mode }: { mode: 'tabs' | 'stack' }) => {
    return (
        <>
            <Stack.Navigator screenOptions={screenOptions} initialRouteName="Account">
                <Stack.Screen name="Account" component={AccountScreen} initialParams={{ mode }} />
                <Stack.Screen name="ResponsibleGaming" component={GamingLimitsScreen} />
                <Stack.Screen name="DepositLimits" component={DepositLimitsScreen} />
                <Stack.Screen name="LimitsHistory" component={LimitsHistoryScreen} />
                <Stack.Screen name="EntryLimits" component={EntryLimitsScreen} />
                <Stack.Screen name="MaxSingleEntryLimits" component={MaxSingleEntryLimitsScreen} />
                <Stack.Screen name="RealityCheck" component={RealityCheck} />
                <Stack.Screen name="Timeout" component={TimeoutScreen} />
                <Stack.Screen name="SelfExclusionAdd" component={SelfExclusionAdd} />
                <Stack.Screen name="TransactionHistory" component={TransactionHistoryScreen} />
                <Stack.Screen name="AccountDetails" component={AccountDetailsScreen} />
                <Stack.Screen name="YearEndStatement" component={YearEndStatementScreen} />
                <Stack.Screen name="StateSelector" component={StateSelector} />
                <Stack.Screen name="TransactionDetails" component={TransactionDetailsScreen} />
                <Stack.Screen name="Preferences" component={PreferencesScreen} />
            </Stack.Navigator>
        </>
    );
};
