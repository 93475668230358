import React from 'react';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Screen } from '@/components/ScreenComponent';
import { Box } from '@/components/lib/components';
import { common } from '@/styles/styles';

type LoadingScreenProps = React.ComponentProps<typeof Box>;

export const LoadingScreen = ({ ...props }: LoadingScreenProps) => (
    <Screen edges={['top', 'bottom']}>
        <Box style={[common.paddingVertical, common.flex, common.justifyCenter]} {...props}>
            <LoadingSpinner />
        </Box>
    </Screen>
);
