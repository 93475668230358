import React from 'react';

import { Box } from './Box';

type ColumnProps = React.ComponentProps<typeof Box>;

export const Column = ({ children, ...rest }: Omit<ColumnProps, 'flexDirection'>) => {
    return (
        <Box {...rest} flexDirection="column">
            {children}
        </Box>
    );
};
