import React, { useEffect } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Animated, { SlideOutDown, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import Warning from '@/assets/icons/alertTriangleWhite';
import Success from '@/assets/icons/checkmark-thin';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

export const toastTypes = {
    SUCCESS: 'success',
    WARNING: 'warning',
} as const;

export type ToastType = (typeof toastTypes)[keyof typeof toastTypes];

export type ToastProps = {
    message: string;
    toastType?: ToastType;
    positionY?: number;
    onPress?: () => void;
};

const styles = StyleSheet.create({
    commonToastStyle: {
        backgroundColor: designSystem.colors.gray5,
        borderRadius: 10,
        paddingVertical: 12,
        paddingHorizontal: 16,
        elevation: 4,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        position: 'absolute',
        bottom: 0,
        zIndex: designSystem.zIndex.zIndex100,
        alignSelf: 'center',
    },
    message: {
        marginLeft: 8,
        color: designSystem.colors.gray1,
    },
});

export const Toast = ({ message, toastType, positionY = 46, onPress }: ToastProps): JSX.Element => {
    const animatedPositionY = useSharedValue(positionY);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            transform: [{ translateY: withTiming(animatedPositionY.value) }],
        };
    });

    const renderIcon = () => {
        switch (toastType) {
            case toastTypes.WARNING:
                return <Warning color={designSystem.colors.white} />;
            default:
                return <Success color={designSystem.colors.white} />;
        }
    };

    useEffect(() => {
        if (message) {
            animatedPositionY.value = -1 * positionY;
        }
    }, [message, animatedPositionY, positionY]);

    return (
        <Animated.View style={[styles.commonToastStyle, animatedStyle]} exiting={SlideOutDown.duration(500)}>
            <TouchableOpacity onPress={onPress} disabled={!onPress}>
                <Row alignItems="center" justifyContent="center">
                    {renderIcon()}
                    <Text variant="bodySmall" style={styles.message}>
                        {message}
                    </Text>
                </Row>
            </TouchableOpacity>
        </Animated.View>
    );
};
