import React from 'react';
import { DimensionValue } from 'react-native';

import { ResponsiveValue, TypographyProps } from '@shopify/restyle';

import { SizedBox } from '../SizedBox';
import { Text } from '../TextComponent';
import { Box } from '../lib/components';
import { AppTheme, DesignSystemColor } from '../lib/theme';
import { GridPeriodScore } from './types';

export const PeriodColumn = ({
    homeScore,
    awayScore,
    periodLabel,
    highlighted,
    width,
    fontSize,
    sizedBoxDimensions,
    fontWeight,
    textColor = 'gray3',
}: GridPeriodScore & {
    highlighted?: boolean;
    periodLabel: string;
    width?: ResponsiveValue<
        DimensionValue | undefined,
        {
            readonly phone: 0;
            readonly tablet: 768;
        }
    >;
    fontSize: number;
    sizedBoxDimensions: { top: number; middle: number; bottom: number };
    fontWeight?: TypographyProps<AppTheme>['fontWeight'];
    textColor?: DesignSystemColor;
}) => {
    return (
        <Box width={width} minWidth={14} alignItems={'flex-end'}>
            <SizedBox value={sizedBoxDimensions.top} />
            <Text
                variant="labelMedium"
                color={highlighted ? 'gray1' : textColor}
                fontSize={fontSize}
                fontWeight={fontWeight}
            >
                {periodLabel}
            </Text>
            <SizedBox value={sizedBoxDimensions.middle} />
            <Text
                variant="labelMedium"
                color={highlighted ? 'gray1' : 'gray2'}
                fontSize={fontSize}
                fontWeight={fontWeight}
            >
                {awayScore ?? '-'}
            </Text>
            <SizedBox value={sizedBoxDimensions.bottom} />
            <Text
                variant="labelMedium"
                color={highlighted ? 'gray1' : 'gray2'}
                fontSize={fontSize}
                fontWeight={fontWeight}
            >
                {homeScore ?? '-'}
            </Text>
        </Box>
    );
};
