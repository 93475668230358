import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withSequence,
    withTiming,
} from 'react-native-reanimated';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { textVariants } from '@/components/lib/typography';
import { Event, MarketType } from '@/feature/event-details-sbk/types';
import { getTeam } from '@/feature/event-details-sbk/utils/get-team';
import {
    MicroMarketParams,
    getMicroMarketDescriptionParams,
} from '@/feature/event-details-sbk/utils/micro-market-descriptions';
import { designSystem } from '@/styles/styles';

import { SizedBox } from './SizedBox';

const DURATION_MS = 2000;

type AnimatedMicroMarketDescriptionProps = {
    marketType: MarketType;
    event: Event;
    showDescription?: boolean;
    prependTeamName?: boolean;
};

const AnimatedTextFlash = ({ value }: { value?: string | number }) => {
    const color = useSharedValue(designSystem.colors.gray2);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        color.value = withSequence(
            withTiming(designSystem.colors.gray1, { duration: 0 }),
            withTiming(designSystem.colors.gray1, { duration: DURATION_MS / 6 }),
            withDelay((DURATION_MS / 3) * 2, withTiming(designSystem.colors.gray2, { duration: DURATION_MS / 6 }))
        );
    }, [value, color]);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            color: color.value,
            ...textVariants.bodySmall,
        };
    });

    return (
        <Animated.Text allowFontScaling={false} style={animatedStyle}>
            {value}
        </Animated.Text>
    );
};

const isMarketTypeParam = (key: string, marketTypeParams: MicroMarketParams): key is keyof MicroMarketParams => {
    return key in marketTypeParams;
};

export const AnimatedMicroMarketDescription = ({
    marketType,
    event,
    showDescription = true,
    prependTeamName = false,
}: AnimatedMicroMarketDescriptionProps) => {
    const { t } = useTranslation('sbk_micro_markets');
    const marketTypeParams = getMicroMarketDescriptionParams(marketType);
    const sportName = event.sport.name;
    const sportKey = sportName?.replace(/\s/g, '_').toLowerCase();
    const translationKey = `${sportKey}.${marketType.code}`;

    //@ts-ignore
    let originalTranslation: string = t(translationKey);

    // If the translation key is not found, return null
    if (originalTranslation === translationKey) {
        return null;
    }

    // Regular expression to match placeholders
    const regex = /{{(.*?)}}/g;

    // Split the original string into parts based on placeholders
    const parts = originalTranslation.split(regex);

    // Map over the parts and replace placeholders with Highlight component
    const replacedParts = parts.map(part => {
        if (isMarketTypeParam(part, marketTypeParams)) {
            const value = marketTypeParams[part];
            if (typeof value !== 'object') {
                return <AnimatedTextFlash key={part} value={value} />;
            }
        }
        return (
            <Text key={part} variant="bodySmall" color="gray2">
                {part}
            </Text>
        );
    });

    if (prependTeamName && marketType.params.team_id && event) {
        const team = getTeam(marketType.params.team_id, event);
        if (team) {
            replacedParts.unshift(
                <Text key="teamName" variant="bodySmall" color="gray2">
                    {team.short_code} -{' '}
                </Text>
            );
        }
    }

    return (
        <Box flexDirection="row" alignItems="center" flexWrap="wrap">
            {showDescription ? replacedParts : <SizedBox value={20} />}
        </Box>
    );
};
