import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';

import { AddSelectionError, AddSelectionErrors, BetSlipEvent, BetSlipMarket, BetSlipOption } from '../types';
import { useIsOptionSelected } from './betslip-hooks';
import { useSbkBetSlipStore } from './use-sbk-betslip-store';

export const useSelectionActions = () => {
    const addNewSelection = useSbkBetSlipStore(state => state.actions.addSelection);
    const removeSelection = useSbkBetSlipStore(state => state.actions.removeSelection);
    const toggleComboSelectionStatus = useSbkBetSlipStore(state => state.actions.toggleComboSelectionStatus);
    const isSelected = useIsOptionSelected;
    const { showToast, showInfoSheet } = useAlerts();
    const { t } = useTranslation('betslip_sbk');

    const onAddSelectionError = useCallback(
        (errorType: AddSelectionError, selectionId?: string) => {
            switch (errorType) {
                case AddSelectionErrors.ConflictingSelections:
                    if (selectionId) {
                        showInfoSheet({
                            title: t('conflicting_selections_title'),
                            description: t('conflicting_selections_description'),
                            buttonLabel: t('conflicting_selections_primary_button'),
                            secondaryLabel: t('conflicting_selections_secondary_button'),
                            handlePress: () => {
                                toggleComboSelectionStatus(selectionId);
                            },
                            handleSecondaryPress: () => {
                                removeSelection(selectionId);
                            },
                        });
                    }
                    break;
                case AddSelectionErrors.MaxSelections:
                    showToast({ message: 'Your bet slip is maxed out', toastType: 'warning' });
            }
        },
        [removeSelection, showInfoSheet, showToast, t, toggleComboSelectionStatus]
    );

    const addSelection = (option: BetSlipOption, market: BetSlipMarket, event: BetSlipEvent) => {
        addNewSelection(option, market, event, onAddSelectionError);
    };

    return { addSelection, removeSelection, isSelected };
};
