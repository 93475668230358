import { MutableRefObject, useEffect } from 'react';
import { FlatList } from 'react-native';
import { SharedValue } from 'react-native-reanimated';

import { MarketCategory } from '@/feature/event-details-sbk/types';

type UseScrollToTopOnChangeMarketProps = {
    activeSubMarket?: MarketCategory;
    scrollY: SharedValue<number>;
    headerHeight: number;
    listRef: MutableRefObject<FlatList<any> | null | undefined>;
};

/**
 * Scroll to top of the flat list when market chip menu changes
 */
export const useScrollToTopOnChangeMarket = ({
    activeSubMarket,
    scrollY,
    headerHeight,
    listRef,
}: UseScrollToTopOnChangeMarketProps) => {
    useEffect(() => {
        const offset = scrollY.value < headerHeight ? scrollY.value : headerHeight;
        listRef.current?.scrollToOffset({ offset, animated: false });
    }, [activeSubMarket, headerHeight, listRef, scrollY]);
};
