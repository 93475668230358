import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import Animated, {
    interpolate,
    interpolateColor,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withSequence,
    withTiming,
} from 'react-native-reanimated';

import LockIcon from '@/assets/icons/lock';
import { getFormattedOdds } from '@/components/Odds';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { AppColors } from '@/components/lib/theme';
import { useProducerStatus } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useSelectionActions } from '@/feature/betslip-sbk/hooks/use-selection-actions';
import { useOddsDiffAnimation } from '@/hooks/useOddsDiffAnimation';
import { designSystem } from '@/styles/styles';
import { isOptionSuspended } from '@/utils/option-status';

import { useIsMarketPublished, useOptionOdds, useOptionStatus } from '../hooks/use-market-cache';
import { Event, Market, Option } from '../types';
import { OptionName } from './OptionName';

const DURATION_MS = 2000;

/**
 * Get AnimatedStyles for option button
 */
export const useAnimatedOptionEffect = (
    odds: number,
    prevOdds: number,
    isAlreadySelected: boolean,
    diff: number,
    unselectedSourceColor?: string
) => {
    const progress = useSharedValue(0);

    const animatedOpacityStyle = useAnimatedStyle(() => {
        return {
            opacity: interpolate(progress.value, [0, 1], [0, 1]),
        };
    });

    const animatedColorStyle = useAnimatedStyle(() => {
        const srcColor = isAlreadySelected
            ? designSystem.colors.gray8
            : unselectedSourceColor ?? designSystem.colors.gray2;

        const destColor = diff > 0 ? designSystem.colors.green : diff < 0 ? designSystem.colors.red : srcColor;

        return {
            color: interpolateColor(progress.value, [0, 1], [srcColor, destColor]),
        };
    }, [isAlreadySelected, diff]);

    useEffect(() => {
        if (prevOdds !== odds) {
            progress.value = withSequence(
                withTiming(0, { duration: 0 }),
                withTiming(1, { duration: DURATION_MS / 6 }),
                withDelay((DURATION_MS / 3) * 2, withTiming(0, { duration: DURATION_MS / 6 }))
            );
        }
    }, [prevOdds, odds, progress]);

    return { animatedOpacityStyle, animatedColorStyle };
};

type OptionButtonProps = {
    option: Option;
    market: Market;
    event: Event;
    optionColor?: AppColors;
    onSelectionAdded?: () => void;
    testID?: string;
};

export const OptionButton = ({
    option,
    market,
    event,
    optionColor = 'gray6',
    onSelectionAdded,
    testID,
}: OptionButtonProps) => {
    const { t } = useTranslation('bets');
    const { addSelection, removeSelection, isSelected } = useSelectionActions();
    const odds = useOptionOdds(option.id) || option.odds;

    const formattedOdds = getFormattedOdds(odds);

    const optionStatus = useOptionStatus(option.id) || option.status;
    const isMarketPublished = useIsMarketPublished(market.id) ?? market.published;
    const producerStatus = useProducerStatus();
    const isSuspended = isOptionSuspended(optionStatus, producerStatus, isMarketPublished);
    const isAlreadySelected = isSelected(option.id) && !isSuspended;

    const { animatedOpacityStyle, animatedColorStyle, oddsDiff } = useOddsDiffAnimation({ odds, isAlreadySelected });

    const toggleSelection = () => {
        if (isAlreadySelected) {
            removeSelection(option.id);
        } else {
            const betSlipOption = {
                id: option.id,
                description: option.description,
                odds: odds,
                originalOdds: odds,
                marketId: market.id,
                optionType: option.option_type,
                status: optionStatus,
            };
            const betSlipMarket = {
                id: market.id,
                description: market.description,
                eventId: event.id,
                marketType: market.market_type,
                player: market.player,
                isMicroMarket: market.is_micro_market,
                published: isMarketPublished,
            };
            const betSlipEvent = {
                id: event.id,
                start_time: event.start_time,
                home_team: event.home_team,
                away_team: event.away_team,
                is_sgp_enabled: event.is_sgp_enabled,
                sport: event.sport,
                event_details: event.event_details,
                status: event.status,
            };
            addSelection(betSlipOption, betSlipMarket, betSlipEvent);
            onSelectionAdded?.();
        }
    };

    return (
        <Box flexGrow={1}>
            <TouchableOpacity
                onPress={toggleSelection}
                disabled={isSuspended}
                testID={`${option.description}-${testID}`}
            >
                <Box
                    borderRadius="r10"
                    backgroundColor={isAlreadySelected ? 'white' : optionColor}
                    alignItems="center"
                    justifyContent="center"
                    paddingVertical="s8"
                    paddingHorizontal="s16"
                    style={styles.cornerRibbonWrapper}
                    height={56}
                >
                    {isSuspended ? (
                        <Box flex={1} justifyContent="center" alignItems="center" gap="s2">
                            <Box marginTop="s2">
                                <LockIcon />
                            </Box>
                            <Text variant="labelLarge" color="gray4">
                                {t('suspended')}
                            </Text>
                        </Box>
                    ) : (
                        <>
                            <Box mb="s2">
                                <OptionName
                                    optionType={option.option_type}
                                    marketType={market?.market_type}
                                    homeTeam={event.home_team}
                                    awayTeam={event.away_team}
                                    fallback={option.description}
                                    sportName={event.sport.name}
                                    variant="titleSmall"
                                    numberOfLines={1}
                                    color={isAlreadySelected ? 'gray8' : 'gray1'}
                                    testID={`optionName-${testID}`}
                                />
                            </Box>
                            <Animated.Text allowFontScaling={false} style={[animatedColorStyle]}>
                                {formattedOdds}
                            </Animated.Text>

                            {oddsDiff > 0 ? (
                                <AnimatedCornerRibbon position="TOP" style={[animatedOpacityStyle]} />
                            ) : null}
                            {oddsDiff < 0 ? (
                                <AnimatedCornerRibbon position="BOTTOM" style={[animatedOpacityStyle]} />
                            ) : null}
                        </>
                    )}
                </Box>
            </TouchableOpacity>
        </Box>
    );
};

type CornerRibbonPropsRef = {
    position: 'TOP' | 'BOTTOM';
    style?: ViewStyle;
};

const CornerRibbon = React.forwardRef<View, CornerRibbonPropsRef>((props, ref) => {
    return (
        <View
            ref={ref}
            style={[styles.ribbon, props.position === 'TOP' ? styles.ribbonIncrease : styles.ribbonDecrease]}
        />
    );
});

export const AnimatedCornerRibbon = Animated.createAnimatedComponent(CornerRibbon);

const styles = StyleSheet.create({
    cornerRibbonWrapper: {
        position: 'relative',
        overflow: 'hidden',
    },
    ribbon: {
        position: 'absolute',
        right: 0,
        width: 28,
        height: 28,
    },
    ribbonIncrease: {
        top: 0,
        backgroundColor: designSystem.colors.green,
        transform: [{ translateX: 14 }, { translateY: -14 }, { rotate: '45deg' }],
    },
    ribbonDecrease: {
        bottom: 0,
        backgroundColor: designSystem.colors.red,
        transform: [{ translateX: 14 }, { translateY: 14 }, { rotate: '45deg' }],
    },
});
