import { ReactElement, useCallback } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Linking } from 'react-native';

import { Link, useNavigation } from '@react-navigation/native';

import RGLogo from '@/assets/icons/rgLogo';
import { SizedBox } from '@/components/SizedBox';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useUser } from '@/hooks/use-user';
import { common, designSystem } from '@/styles/styles';
import { ProductDocumentDataBodyScreenLinksSliceItem } from '@/types/prismic.generated';
import { ResponsiveValue, SpacingProps } from '@shopify/restyle';

import { Badge } from './Badge';
import { ButtonMore } from './ButtonMore';
import RichText, { ParsedTypes } from './RichText';
import { Box } from './lib/components';
import { AppSpacing, AppTheme } from './lib/theme';

type Link = Partial<ProductDocumentDataBodyScreenLinksSliceItem>;

export const FooterRG = ({ marginTop, ...props }: SpacingProps<AppTheme>): ReactElement => {
    const { richTextsSlices, links = [] } = useJurisdictionStore(state => ({
        richTextsSlices: state.jurisdictionSettings?.globalSettings?.richTextsSlices,
        links: state.jurisdictionSettings?.productConfig?.screenLinks,
    }));

    const footerLinks = links.filter(link => link.should_add_to_rg_footer);

    const footerRichText = richTextsSlices?.AccountFooter || [];

    const navigation = useNavigation();
    const { profile } = useUser();
    const { t } = useTranslation('common');

    const handlePress = useCallback(
        async (link: Link) => {
            const label = link.label ?? '';
            BetrAnalytics.trackProductEvent(AnalyticsEvent.ACCOUNT_SETTINGS, { label });
            trackRG.accountScreen(label);

            switch (true) {
                case !!link.url:
                    const parsed = link.url?.replace('{userId}', profile.sub);
                    if (parsed) {
                        if (link.internal) {
                            navigation.navigate('ModalWebView', { uri: parsed });
                        } else if (await Linking.canOpenURL(parsed)) {
                            Linking.openURL(parsed);
                        }
                    }
                    break;
                default:
                    Alert.alert(link.label ?? '', t('feature_not_available'));
                    break;
            }
        },
        [navigation, profile.sub, t]
    );

    return (
        <Box
            paddingTop="s32"
            paddingBottom={footerLinks.length > 0 ? 's0' : 's32'}
            backgroundColor={'gray8'}
            borderRadius={'r16'}
            marginBottom={'s24'}
            marginTop={marginTop ?? 's80'}
            {...props}
        >
            <Box style={(common.justifyCenter, common.alignCenter)}>
                <RGLogo />
            </Box>
            <SizedBox value={16} />
            <Box paddingHorizontal={'s24'}>
                <RichText
                    data={footerRichText}
                    typeProps={{
                        [ParsedTypes.BR]: {
                            value: 4,
                        },
                        [ParsedTypes.H1]: {
                            variant: 'titleLarge',
                        },
                    }}
                />
            </Box>
            <Box paddingHorizontal={'s16'}>
                {footerLinks.length > 0 ? (
                    <>
                        <SizedBox value={24} />
                        {footerLinks.map((link: Link, linkIndex: number) => (
                            <ButtonMore
                                key={`link-${linkIndex}-${link.label}`}
                                label={link.label ?? ''}
                                onPress={() => handlePress(link)}
                                style={[
                                    linkIndex === footerLinks.length - 1
                                        ? {}
                                        : { borderBottomColor: designSystem.colors.gray6 },
                                ]}
                            >
                                {link?.badge_text ? <Badge label={link.badge_text} /> : null}
                            </ButtonMore>
                        ))}
                    </>
                ) : null}
            </Box>
        </Box>
    );
};

/**
 * FooterRG component that can be placed inside Scroll containers to fill their height and be always at the bottom.
 * Make sure to specify flexGrow:1 on parent container so this can fill the height.
 */
export const BottomFooterRG = ({
    footerMarginTop,
    ...props
}: {
    footerMarginTop?: ResponsiveValue<AppSpacing, {}>;
} & SpacingProps<AppTheme>): ReactElement => {
    return (
        <Box flex={1} justifyContent="flex-end" {...props}>
            <FooterRG marginTop={footerMarginTop} />
        </Box>
    );
};
