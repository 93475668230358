import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg width={80} height={80} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Circle cx="40" cy="40" r="40" fill={designSystem.colors.red} />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43 22H37L38 48H42L43 22ZM43 52H37V58H43V52Z"
            fill={designSystem.colors.gray8}
        />
    </Svg>
);

export default SvgComponent;
