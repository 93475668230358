import { BetSelection } from '@/feature/bets-sbk/hooks/types';
import { useProducerStatus } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { isOptionSuspended } from '@/utils/option-status';

export const useSelectionInfo = (
    eventSelections: BetSelection[],
    isSelected: (optionId: string) => boolean
): {
    isSuspended: boolean;
    isAlreadySelected: boolean;
} => {
    const areMarketsPublished = eventSelections.map(selection => selection.market.published);
    const optionListStatus = eventSelections.map(selection => selection.option.status);
    const producerStatus = useProducerStatus();

    let someSuspended = false;
    let allAlreadySelected = true;

    eventSelections.forEach((selection, index) => {
        const optionStatus = optionListStatus[index];
        const isMarketPublished = areMarketsPublished[index];
        const isSuspended = isOptionSuspended(optionStatus, producerStatus, isMarketPublished);
        const isAlreadySelected = isSelected(selection.option.id) && !isSuspended;

        if (isSuspended) {
            someSuspended = true;
        }
        if (!isAlreadySelected) {
            allAlreadySelected = false;
        }
    });

    return {
        isSuspended: someSuspended,
        isAlreadySelected: allAlreadySelected,
    };
};
