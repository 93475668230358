import React, { useCallback, useMemo, useRef } from 'react';
import { FlatList } from 'react-native';
import Animated from 'react-native-reanimated';

import { useStickyTabList, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { SubMarketTabs } from '@/feature/event-details-sbk/components/SubMarketTabs';
import { useActiveSubMarket } from '@/feature/event-details-sbk/hooks/use-active-submarket';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { useHasPublishedMarkets } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { Event, MarketCategory } from '@/feature/event-details-sbk/types';
import { useScrollToTopOnChangeMarket } from '@/feature/league-hub-sbk/hooks/use-scroll-to-top';
import { common, designSystem } from '@/styles/styles';
import { QueryObserverResult } from '@tanstack/react-query';

import { useLeagueMarketList } from '../../hooks/use-league-market-list';
import { useSectionHeights } from '../../hooks/use-section-heights';
import { LeagueMarketEventCard } from '../LeagueMarketEventCard';

type LeagueEventListMarketLayoutProps = {
    events: Event[];
    leagueId: string;
    marketCategories: MarketCategory[];
    sportName: string;
    refetch: () => Promise<QueryObserverResult<any, unknown>[]>;
    tabKey: string;
    isTabBarVisible: boolean;
};

const keyExtractor = (item: Event) => item.id;

export const LeagueEventListMarketLayout = ({
    events,
    leagueId,
    marketCategories,
    sportName,
    refetch,
    tabKey,
    isTabBarVisible,
}: LeagueEventListMarketLayoutProps) => {
    const { gameSectionHeight, marketHeaderSectionHeight } = useSectionHeights(leagueId);
    const { activeSubMarket, setActiveSubMarket } = useActiveSubMarket(marketCategories);
    const hasPublishedMarkets = useHasPublishedMarkets(activeSubMarket ? [activeSubMarket] : []);

    const filteredEvents = useMemo(() => {
        if (!hasPublishedMarkets) {
            return [];
        }
        const marketEventIds = new Set(activeSubMarket?.markets.map(market => market.event_id));
        return events.filter(event => marketEventIds.has(event.id));
    }, [events, hasPublishedMarkets, activeSubMarket?.markets]);

    const renderItem = useCallback(
        ({ item: event }: { item: Event }) => {
            const markets = activeSubMarket?.markets.filter(m => m.event_id === event.id) ?? [];
            return <LeagueMarketEventCard event={event} markets={markets} testID={events.indexOf(event).toString()} />;
        },
        [activeSubMarket, events]
    );

    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);
    const { scrollableProps, setStickyRef, contentHeight, headerHeight } = useStickyTabList(
        tabKey,
        forceRefreshing,
        onRefresh
    );
    const { flatListProps } = useLeagueMarketList(
        hasPublishedMarkets,
        contentHeight,
        isTabBarVisible,
        marketHeaderSectionHeight
    );
    const { smoothScrollHeaderStyle, scrollY } = useStickyTabsAnimation();

    const listRef = useRef<FlatList<any> | null>();
    useScrollToTopOnChangeMarket({ activeSubMarket, scrollY, headerHeight, listRef });

    if (!activeSubMarket) {
        return null;
    }

    const top = gameSectionHeight + (isTabBarVisible ? TAB_HEIGHT : 0);
    const paddingTop = marketHeaderSectionHeight + (isTabBarVisible ? TAB_HEIGHT : 0);

    return (
        <>
            <Animated.View
                style={[common.full, smoothScrollHeaderStyle, { top, backgroundColor: designSystem.colors.gray8 }]}
            >
                <SubMarketTabs
                    {...{
                        sportName,
                        paddingTop,
                        activeSubMarket,
                        marketCategories,
                        setActiveSubMarket,
                    }}
                />
            </Animated.View>
            <Animated.FlatList<Event>
                data={filteredEvents}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                windowSize={6}
                initialNumToRender={6}
                maxToRenderPerBatch={6}
                {...scrollableProps}
                // ! flatListProps should be after scrollableProps as it overrides contentContainerStyle
                {...flatListProps}
                // ! ref cannot be set through spread operator
                ref={ref => {
                    setStickyRef(ref);
                    listRef.current = ref;
                }}
            />
        </>
    );
};
