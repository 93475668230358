import { useTranslation } from 'react-i18next';

import {
    BasketballPeriod,
    FootballPeriod,
    GameClock,
    HockeyPeriod,
    PeriodName,
    SportSpecific,
} from '@/feature/event-details-sbk/types';
import { EventPeriodDetails } from '@/utils/format-sport-period-details/format-sport-period-details';
import { getSportKey } from '@/utils/get-sport-key';

type FormatPeriodProps = {
    event: EventPeriodDetails;
    periodName?: PeriodName;
    gameClock?: GameClock;
    sportSpecific?: SportSpecific;
};

const inningStatusToLabel = {
    TOP: 'Top',
    BOTTOM: 'Bottom',
    MIDDLE: 'Middle',
    FINISHED: 'Finished',
};

const basketballPeriods = [
    'FIRST_PERIOD',
    'BEFORE_SECOND_PERIOD',
    'SECOND_PERIOD',
    'HALF_TIME',
    'THIRD_PERIOD',
    'BEFORE_FOURTH_PERIOD',
    'FOURTH_PERIOD',
    'BEFORE_OVERTIME',
    'OVERTIME_1',
    'OVERTIME_2',
    'OVERTIME_3',
    'OVERTIME_4',
    'OVERTIME_5',
    'OVERTIME_6',
    'OVERTIME_7',
    'OVERTIME_8',
    'OVERTIME_9',
    'OVERTIME_10',
    'POST_GAME',
    'GAME_ABANDONED',
];

const footballPeriods = [
    'PERIOD_1',
    'PERIOD_2',
    'AT_HALF_TIME',
    'PERIOD_3',
    'PERIOD_4',
    'NORMAL_TIME_COMPLETED',
    'IN_OVERTIME',
    'OVERTIME_COMPLETED',
    'EVENT_COMPLETED',
    'N_A',
];

const hockeyPeriods = [
    'FIRST_PERIOD',
    'BEFORE_SECOND_PERIOD',
    'SECOND_PERIOD',
    'BEFORE_THIRD_PERIOD',
    'THIRD_PERIOD',
    'BEFORE_OVERTIME',
    'OVERTIME',
    'OVERTIME_1',
    'OVERTIME_2',
    'OVERTIME_3',
    'OVERTIME_4',
    'OVERTIME_5',
    'OVERTIME_6',
    'OVERTIME_7',
    'OVERTIME_8',
    'OVERTIME_9',
    'OVERTIME_10',
    'BEFORE_PENALTY_SHOOTOUT',
    'PENALTY_SHOOTOUT',
    'SUSPENDED',
    'POST_GAME',
    'GAME_ABANDONED',
];

const inPlayPeriods = [
    'FIRST_PERIOD',
    'SECOND_PERIOD',
    'THIRD_PERIOD',
    'FOURTH_PERIOD',
    'OVERTIME_1',
    'OVERTIME_2',
    'OVERTIME_3',
    'OVERTIME_4',
    'OVERTIME_5',
    'OVERTIME_6',
    'OVERTIME_7',
    'OVERTIME_8',
    'OVERTIME_9',
    'OVERTIME_10',
    'PERIOD_1',
    'PERIOD_2',
    'PERIOD_3',
    'PERIOD_4',
    'IN_OVERTIME',
    'OVERTIME',
];

const isBasketballPeriod = (period: PeriodName): period is BasketballPeriod => {
    return basketballPeriods.includes(period);
};

const isFootballPeriod = (period: PeriodName): period is FootballPeriod => {
    return footballPeriods.includes(period);
};

const isHockeyPeriod = (period: PeriodName): period is HockeyPeriod => {
    return hockeyPeriods.includes(period);
};

/**
 * Formats the given number of seconds into a string representation of time in the format "m:ss".
 *
 * @param seconds - The number of seconds to format.
 * @returns The formatted time string.
 */
export const formatTime = (seconds: number) => {
    const periodMinutes = Math.floor(seconds / 60);
    const periodSeconds = seconds % 60;
    return `${periodMinutes}:${periodSeconds > 9 ? periodSeconds : '0' + periodSeconds}`;
};

/**
 * Custom hook that formats the period details of an event.
 * @param event - The event period details.
 * @param periodName - The name of the period for specific sport type (e.g: SECOND_PERIOD).
 * @param gameClock - The game clock details.
 * @param sportSpecific - The sport specific fields details (currently only for Baseball and American Football).
 *
 * @returns The formatted period details based on sport type.
 * @example:
 *      Basketball: "2nd, 15:00"
 *      Ice Hockey: "2nd, 20:00"
 *      Baseball: "Top 7"
 *      American Football: "2nd, 15:00"
 */
export const useFormatPeriod = ({ event, periodName, gameClock, sportSpecific }: FormatPeriodProps) => {
    const { t } = useTranslation(['common', 'event_time']);

    let eventTime;
    const sportNamespace = getSportKey(event.sport);
    if (periodName) {
        if (sportNamespace === 'basketball' && isBasketballPeriod(periodName)) {
            eventTime = t(`event_time:${sportNamespace}.${periodName}`);
        }
        if (sportNamespace === 'american_football' && isFootballPeriod(periodName)) {
            eventTime = t(`event_time:${sportNamespace}.${periodName}`);
        }
        if (sportNamespace === 'ice_hockey' && isHockeyPeriod(periodName)) {
            eventTime = t(`event_time:${sportNamespace}.${periodName}`);
        }
        if (eventTime) {
            const remainingTime =
                gameClock?.remaining_time_in_period && formatTime(parseInt(gameClock?.remaining_time_in_period, 10));
            if (inPlayPeriods.includes(periodName) && remainingTime) {
                eventTime += `, ${remainingTime}`;
            }
            return eventTime;
        }
    }
    if (sportNamespace === 'baseball') {
        const currentInning = sportSpecific?.current_inning;
        const inningDetails = currentInning ? sportSpecific?.inning_details?.[currentInning] : null;
        const inningStatus = inningDetails?.status ? inningStatusToLabel[inningDetails?.status] : null;
        return inningStatus && currentInning ? `${inningStatus} ${currentInning}` : '';
    }
};
