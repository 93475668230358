import React, { useState } from 'react';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import VerificationScreenTemplate from '@/feature/account-details/screens/VerificationScreenTemplate';
import { usePhoneVerificationSuccess } from '@/hooks/use-phone-verification-success';
import { useVerificationCode } from '@/hooks/use-verification-code';
import { RootStackParamList } from '@/navigation/types';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'PhoneVerification'>;

export const PhoneVerificationScreen = ({ route }: ScreenProps) => {
    const { title, screenTitle, redirectRoute } = route.params;
    const { handleSuccessfulVerification } = usePhoneVerificationSuccess();
    const [isResendRequested, setIsResendRequested] = useState(false);

    const { verificationCode, isSubmitting, errorMessage, onInputChange, setExternalError, setErrorMessage } =
        useVerificationCode(() => handleSuccessfulVerification(redirectRoute));

    return (
        <VerificationScreenTemplate
            isLoading={isSubmitting}
            errorMessage={errorMessage}
            verificationCode={verificationCode}
            isResendRequested={isResendRequested}
            setExternalError={setExternalError}
            onResendPress={() => {
                setIsResendRequested(true);
                setErrorMessage(''); // Clearing error message once the resend is triggered.
            }}
            onInputChange={onInputChange}
            title={title}
            screenTitle={screenTitle}
        />
    );
};
