import React, { Dispatch, SetStateAction } from 'react';

import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';

import { QuickDepositButton } from './QuickDepositButton';

type QuickDepositButtonsSectionProps = {
    setInputValue: Dispatch<SetStateAction<string>>;
    quickDepositValues: Record<string, string>;
};

export const QuickDepositButtonsSection = ({
    setInputValue,
    quickDepositValues,
}: QuickDepositButtonsSectionProps): React.ReactElement | null => {
    const handlePress = (value: string) => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.DEPOSIT_QUICKBUTTON, { amount: value });
        setInputValue(value);
    };
    return quickDepositValues ? (
        <Box flexDirection="row" justifyContent="space-between" flexGrow={1} columnGap={'s6'}>
            {Object.values(quickDepositValues).map(value => (
                <QuickDepositButton
                    key={`quick-deposit-button-${value}`}
                    label={`+$${value}`}
                    onPress={() => handlePress(value)}
                />
            ))}
        </Box>
    ) : null;
};
