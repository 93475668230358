import { useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import { useMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { useIsNativeSportsbook } from '@/hooks/use-is-native-sportsbook';

/**
 * Clears the market cache when the app goes to the background to prevent cache from growing too large and being stale
 */
export const useClearMarketCache = () => {
    const clearMarketCache = useMarketCacheStore(state => state.actions.clear);
    const isNativeSportsbook = useIsNativeSportsbook();
    useEffect(() => {
        if (isNativeSportsbook) {
            const handleAppStateChange = (nextAppState: AppStateStatus) => {
                if (nextAppState === 'background') {
                    clearMarketCache();
                }
            };
            const subscription = AppState.addEventListener('change', handleAppStateChange);

            return () => {
                subscription.remove();
            };
        }
    }, [isNativeSportsbook, clearMarketCache]);
};
