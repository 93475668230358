import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { FilterType, Filters } from '@/feature/lobby/utils/filters';
import { ProjectionType } from '@/types/api.generated';

export type Section = {
    title: string;
    data: PlayerWithTeam[];
    projectionType: ProjectionType;
};

export const playersUtils = {
    getProjectionSectionLabel: (
        projectionType: ProjectionType,
        filterType: FilterType,
        playerPositionDescription: string | undefined,
        playerProjectionLabel: string | undefined
    ) => {
        const position = playerPositionDescription === 'All' ? '' : playerPositionDescription;
        switch (projectionType) {
            case ProjectionType.Boosted:
                return 'Multiplier Boosters';
            case ProjectionType.Regular:
                return `All ${filterType === Filters.Position ? position ?? '' : playerProjectionLabel ?? ''}`;
            case ProjectionType.Special:
                return 'Specials';
            default:
                return '';
        }
    },
    groupPlayersByProjections: (
        sortedPlayers: PlayerWithTeam[],
        nonRegularProjectionTypes: ProjectionType[],
        filterType: FilterType,
        playerProjectionFilter: string | undefined,
        projectionsOrder: ProjectionType[],
        playerPositionDescription: string | undefined,
        playerProjectionLabel: string | undefined
    ) => {
        const sections: Section[] = [];
        Object.values(ProjectionType).forEach(projectionType =>
            sections.push({
                title: playersUtils.getProjectionSectionLabel(
                    projectionType,
                    filterType,
                    playerPositionDescription,
                    playerProjectionLabel
                ),
                data: [],
                projectionType,
            })
        );

        sortedPlayers.forEach(player => {
            let playerAdded = false;
            let specialPlayer = false;
            if (!player.projections) {
                return;
            }
            nonRegularProjectionTypes.forEach(key => {
                const matchingProjections = player.projections.filter(proj => proj.type === key);
                const hasExactProjectionMatch = matchingProjections.some(
                    projection => projection.key === playerProjectionFilter
                );
                const projectionMatch = matchingProjections.find(proj => proj.key === playerProjectionFilter);
                //verify if special/boosted player has special/boosted projection with regular value as well
                const playerHasRegularValue =
                    hasExactProjectionMatch &&
                    player.projections.some(
                        proj => proj.type === ProjectionType.Regular && proj.key === projectionMatch?.key
                    );

                if (matchingProjections.length > 0) {
                    const currentSection = sections.find(section => section.projectionType === key);
                    // if player has projections of this type or has exactly the current projection filter, add to section and mark him as added
                    if (
                        (filterType === Filters.Projection && hasExactProjectionMatch) ||
                        filterType === Filters.Position
                    ) {
                        currentSection?.data.push(player);
                        playerAdded = true;
                        // mark special player in order to be added as regular as well
                        specialPlayer = filterType === Filters.Projection && playerHasRegularValue;
                    }
                }
            });
            if (!playerAdded || specialPlayer) {
                // if player has no projections that match non-regular types OR when projection type is special, add him to regular projections
                sections.find(section => section.projectionType === ProjectionType.Regular)?.data.push(player);
            }
        });
        // filter out empty sections
        const filteredSections = sections.filter(section => section.data.length > 0);

        // sort the sections so that the order is according to projectionsOrder
        return filteredSections.sort(
            (a, b) => projectionsOrder.indexOf(a.projectionType) - projectionsOrder.indexOf(b.projectionType)
        );
    },
};
