import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import { BoostedMultiplier, DynamicMultiplier } from '@/types/api.generated';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { getBoostedMultiplierDetails } from '../utils/betslip-utils';
import { WinningProjection } from './WinningProjection';

const styles = StyleSheet.create({
    button: {
        marginVertical: 16,
        paddingVertical: 10,
    },
});

export const DynamicPayoutSheet = ({
    payoutSheetRef,
    entryAmount,
    dynamicMultipliers,
    dynamicBoostedMultiplier,
}: {
    entryAmount: number;
    payoutSheetRef: React.RefObject<BottomSheetModal>;
    dynamicMultipliers: DynamicMultiplier[];
    dynamicBoostedMultiplier?: BoostedMultiplier;
}) => {
    const { t } = useTranslation(['betslip_pickem']);

    const infoText =
        entryAmount <= 0
            ? t('dynamic_payout_info_add_entry')
            : t('dynamic_payout_info_entry_amount', { amount: toLocaleCurrency(entryAmount) });
    return (
        <Modal modalRef={payoutSheetRef} id={'dynamic-payout-sheet'}>
            <Box px={'s16'} mt={'s8'}>
                <Text textAlign={'center'} variant={'titleLarge'}>
                    {t('dynamic_payout_summary')}
                </Text>
                <Text variant={'bodySmall'} color={'gray2'} mb={'s12'} textAlign={'center'}>
                    {infoText}
                </Text>

                {dynamicMultipliers
                    .sort((a, b) => b.winningPicks - a.winningPicks)
                    .map((dynamicMultiplier, index) => {
                        const showSeparator = dynamicMultipliers ? index !== dynamicMultipliers.length - 1 : false;
                        // only the highest multiplier can get boosted, so we only show the boosted multiplier for the first one
                        const { boostedMultiplier, toWin } = getBoostedMultiplierDetails(
                            index === 0,
                            dynamicMultiplier,
                            dynamicBoostedMultiplier
                        );
                        return (
                            <WinningProjection
                                key={`win-projection${dynamicMultiplier.multiplier}-${index}`}
                                description={`${dynamicMultiplier.winningPicks} correct`}
                                boostedMultiplier={boostedMultiplier}
                                multiplier={dynamicMultiplier.multiplier}
                                winning={toWin}
                                showSeparator={showSeparator}
                                displayMode={'sheet'}
                            />
                        );
                    })}
                <Button
                    label={'Close'}
                    active={true}
                    style={styles.button}
                    type={'active'}
                    variant="rounded"
                    onPress={() => payoutSheetRef.current?.close()}
                />
            </Box>
        </Modal>
    );
};
