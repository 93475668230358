import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import StripeDot from '@/assets/icons/stripe-dot';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { designSystem } from '@/styles/styles';

import { SizedBox } from './SizedBox';
import { Text } from './TextComponent';
import { Box, Image, Row } from './lib/components';

const styles = StyleSheet.create({
    image: {
        width: 345,
        height: 225,
    },
});

const Dot = ({ color = designSystem.colors.purple2 }: { color?: string }) => {
    return <Box width={8} height={8} borderRadius={'r64'} style={[{ backgroundColor: color }]} />;
};

export const StateAvailability = () => {
    const { t } = useTranslation('common');
    // Image is from Prismic because product team changes it without code change
    const stateAvailabilityImagePrimary = useJurisdictionStore(
        state => state.jurisdictionSettings?.globalSettings?.stateAvailabilityImage
    );
    const imageUrl = stateAvailabilityImagePrimary?.state_availability_image.url;
    if (!imageUrl) {
        return null;
    }

    return (
        <Box justifyContent={'center'} alignItems={'center'}>
            <SizedBox value={12} />

            <Row gap={'s20'}>
                <Row gap={'s8'} alignItems={'center'}>
                    <Dot />
                    <Text>{t('picks')}</Text>
                </Row>
                <Row gap={'s8'} alignItems={'center'}>
                    {/* TODO: refactor when the color is added to the theme with proper name */}
                    <Dot color={'#F8E0FF'} />
                    <Text>{t('sportsbook')}</Text>
                </Row>
                <Row gap={'s8'} alignItems={'center'}>
                    <StripeDot />
                    <Text>{t('both')}</Text>
                </Row>
            </Row>

            <SizedBox value={16} />
            <Image source={{ uri: imageUrl }} resizeMode="contain" style={styles.image} />
        </Box>
    );
};
