import React from 'react';
import { useWindowDimensions } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { CloseIcon } from '@/assets/icons/close';
import { BetSlipBalanceButton } from '@/components/BetSlipBalanceButton';
import { Box, Row, TouchableBox } from '@/components/lib/components';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { MAX_WEB_WIDTH, isWeb } from '@/utils/constants-platform-specific';

type HeaderProps = {
    rightItem?: React.ReactNode;
    testId?: string;
    isSubmitting?: boolean;
    onRightItemPress?: () => void;
};
const ITEM_WIDTH = 50;

export const Header = ({ rightItem, testId, isSubmitting, onRightItemPress }: HeaderProps) => {
    const navigation = useNavigation();

    const onClosePress = () => {
        GASbkEvents.clickBetSlipCloseButton();
        navigation.goBack();
    };

    const { width } = useWindowDimensions();
    const buttonPadding = (width - MAX_WEB_WIDTH) / 2;
    const buttonStyle =
        // aligns the button correctly on web, while keeping other components centered
        isWeb ? { paddingRight: buttonPadding, marginLeft: -buttonPadding + ITEM_WIDTH } : {};

    return (
        <Row paddingHorizontal="s16" paddingVertical="s12" alignItems="center" justifyContent="space-between">
            <TouchableBox onPress={onClosePress} hitSlop={20} disabled={isSubmitting} width={ITEM_WIDTH}>
                <CloseIcon />
            </TouchableBox>
            <BetSlipBalanceButton testId={testId} />
            {rightItem ? (
                <TouchableBox
                    alignItems="flex-end"
                    width={ITEM_WIDTH}
                    hitSlop={20}
                    disabled={isSubmitting}
                    // add additional padding only if the width of the page is bigger then the maximum width we have on web
                    style={width > MAX_WEB_WIDTH ? buttonStyle : {}}
                    onPress={onRightItemPress}
                >
                    {rightItem}
                </TouchableBox>
            ) : (
                <Box width={ITEM_WIDTH} />
            )}
        </Row>
    );
};
