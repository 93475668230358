import React from 'react';

import { useNavigation } from '@react-navigation/native';

import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';

import { LimitLobby } from '../components/LimitLobby';
import { LimitLobbyLinks } from '../components/LimitLobbyLinks';
import { DEPOSIT_LIMIT_HEADER } from '../constants';
import { UserLimit, UserLimitDuration } from '../hooks/types';

export const DepositLimitsScreen = () => {
    const { navigate } = useNavigation();

    const handlePress = (label: string, duration: UserLimitDuration, limit?: UserLimit) => {
        trackRG.depositLimitsScreen(label);
        if (!limit) {
            BetrAnalytics.trackProductEvent(AnalyticsEvent.ADD_DEPOSIT_LIMIT, { duration });
            navigate('DepositLimitAdd', { duration });
        } else {
            BetrAnalytics.trackProductEvent(AnalyticsEvent.EDIT_DEPOSIT_LIMIT, { duration });
            navigate('DepositLimitEdit', { duration, amount: limit.amount });
        }
    };

    return (
        <LimitLobby
            type="DEPOSIT"
            {...DEPOSIT_LIMIT_HEADER}
            links={<LimitLobbyLinks type="DEPOSIT" handlePress={handlePress} />}
            testID="depositLimitsDescription"
        />
    );
};
