/**
 * Function used to check if the browser currently loading the page is a mobile browser
 * It uses several checks to determine if the browser is a mobile browser or not, based on the MDN documentation
 * read more: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
 * @returns true if the browser is a mobile browser, false otherwise
 */
export const isMobileBrowser = () => {
    let hasTouchScreen = false;
    if (navigator.maxTouchPoints) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if (navigator.msMaxTouchPoints) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
        const mQ = matchMedia?.('(pointer:coarse)');
        if (mQ?.media === '(pointer:coarse)') {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            const UA = navigator.userAgent;
            hasTouchScreen =
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
    }
    return hasTouchScreen;
};
