import { useCallback, useMemo } from 'react';

import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { useSubscribePublic } from '@/utils/websocket/hooks';
import { MatchUpdateMessage } from '@/utils/websocket/types';

export const useSubscribeLeagueMatchUpdate = (leagueId: string, enabled: boolean = true) => {
    const handleMatchUpdate = useEventDetailsCache(state => state.actions.handleMatchUpdate);
    const params = useMemo(() => ({ leagueId }), [leagueId]);
    const messageHandler = useCallback(
        (message: MatchUpdateMessage) => {
            if (message.change_type === 'ScoreboardUpdate') {
                handleMatchUpdate(message);
            }
        },
        [handleMatchUpdate]
    );
    useSubscribePublic<'leagueMatchUpdate'>('league:{leagueId}', params, 'match_update', messageHandler, enabled);
};
