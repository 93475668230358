import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import EyeIcon from '@/assets/icons/eye';
import EyeOffIcon from '@/assets/icons/eye-off';
import RightIcon from '@/assets/icons/right';
import { Text } from '@/components/TextComponent';
import { common, designSystem } from '@/styles/styles';

const styles = StyleSheet.create({
    buttonMore: {
        borderBottomWidth: 1,
        borderBottomColor: designSystem.colors.gray5,
        paddingVertical: 16,
    },
});

export type PersonalInfoItem = {
    value: string;
    label: string;
    onPress: () => void;
    isMasked?: boolean;
};

type PersonalInfoButtonProps = React.ComponentProps<typeof TouchableOpacity> & PersonalInfoItem;

// Masks SSN digits, leaving last 4 visible
const maskSsn = (value: string) => `••••• ${value.slice(-4)}`;

// Masks SSN digits, '####-##-####'
const formatedSSN = (value: string) => value.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');

export const PersonalInfoButton = ({ label, value, onPress, isMasked }: PersonalInfoButtonProps) => {
    const [isSsnVisible, setIsSsnVisible] = useState(false);

    const handleVisibilityIconPress = () => setIsSsnVisible(prevState => !prevState);

    const EyeIconComponent = isSsnVisible ? EyeOffIcon : EyeIcon;
    const IconComponent = isMasked ? EyeIconComponent : RightIcon;
    const iconPressHandler = isMasked ? handleVisibilityIconPress : onPress;

    const displayValue = isMasked && !isSsnVisible ? maskSsn(value) : formatedSSN(value);

    return (
        <TouchableOpacity
            activeOpacity={0.8}
            style={[styles.buttonMore, common.spaceBetweenRow]}
            onPress={onPress}
            disabled={isMasked}
        >
            <View>
                <Text variant="labelLarge" color="gray2">
                    {label}
                </Text>
                <Text variant="bodyMedium" color="gray1">
                    {displayValue}
                </Text>
            </View>
            <TouchableOpacity style={[common.row, common.alignCenter]} onPress={iconPressHandler}>
                <IconComponent marginLeft={12} />
            </TouchableOpacity>
        </TouchableOpacity>
    );
};
