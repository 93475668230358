import * as React from 'react';
import Svg, { Circle } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={6} height={6} fill="none" {...props}>
        <Circle cx="3" cy="3" r="3" fill={props.color ?? designSystem.colors.gray4} />
    </Svg>
);
export default SvgComponent;
