import { EventStatus } from '@/feature/bets-sbk/hooks/types';
import { getSportKey } from '@/utils/get-sport-key';

import { Sport } from '../types';

export const HUD_HEIGHTS = {
    primaryMatchUpHeader: 96,
    secondaryMatchUpHeader: 104,
    altMatchUpHeader: 116, // Live American Football and Baseball + primary HUD
    scoreboard: 104,
    sgcInfo: 92,
};

const isAltMatchUpHeader = (sport?: Sport, eventStatus?: EventStatus, isPrimaryHud?: boolean) => {
    const isLive = eventStatus === 'LIVE';
    const sportKey = sport ? getSportKey(sport) : '';
    const isFootballOrBaseball = sportKey === 'american_football' || sportKey === 'baseball';
    return isLive && isFootballOrBaseball && isPrimaryHud;
};

export const getMatchUpHeaderHeight = (sport?: Sport, eventStatus?: EventStatus, isPrimaryHud?: boolean) => {
    if (!isPrimaryHud) {
        return HUD_HEIGHTS.secondaryMatchUpHeader;
    }
    if (isAltMatchUpHeader(sport, eventStatus, isPrimaryHud)) {
        return HUD_HEIGHTS.altMatchUpHeader;
    }
    return HUD_HEIGHTS.primaryMatchUpHeader;
};

/**
 * Helper function to get HUD's height dynamically based on the event sport type
 * and whether periods are available or not
 */
export const getHudHeight = ({
    eventStatus,
    hasScores,
    sport,
    isPrimaryHud,
    showSgcInfo,
}: {
    eventStatus: EventStatus;
    hasScores?: boolean;
    sport?: Sport;
    isPrimaryHud?: boolean;
    showSgcInfo?: boolean;
}) => {
    let hudHeight = getMatchUpHeaderHeight(sport, eventStatus, isPrimaryHud);
    if (hasScores && isPrimaryHud) {
        hudHeight += HUD_HEIGHTS.scoreboard;
    }
    if (showSgcInfo) {
        hudHeight += HUD_HEIGHTS.sgcInfo;
    }
    return hudHeight;
};
