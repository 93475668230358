import React from 'react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@/assets/icons/checkmark-thin';
import CloseIcon from '@/assets/icons/close-thin';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

export type PasswordValidationsType = {
    isOneLetter: boolean;
    isOneSpecialCharacter: boolean;
    isOneNumber: boolean;
    isEightChars: boolean;
};

type PasswordValidationRowProps = {
    passwordValidation: PasswordValidationsType;
};

const renderValidIcon = (validationCondition: boolean) => {
    return validationCondition ? <CheckIcon /> : <CloseIcon color={designSystem.colors.gray1} />;
};

export const PasswordValidation = ({ passwordValidation }: PasswordValidationRowProps) => {
    const { t } = useTranslation('account');
    return (
        <>
            <Row mt={'s16'}>
                <Row width={'35%'} alignItems={'center'}>
                    {renderValidIcon(passwordValidation.isOneLetter)}
                    <Text variant="bodySmall" color="gray2">
                        {' '}
                        {t('password_validation_one_letter')}
                    </Text>
                </Row>
                <Row alignItems={'center'}>
                    {renderValidIcon(passwordValidation.isOneSpecialCharacter)}
                    <Text variant="bodySmall" color="gray2">
                        {' '}
                        {t('password_validation_one_special_character')}
                    </Text>
                </Row>
            </Row>
            <Row mt={'s8'}>
                <Row width={'35%'} alignItems={'center'}>
                    {renderValidIcon(passwordValidation.isOneNumber)}
                    <Text variant="bodySmall" color="gray2">
                        {' '}
                        {t('password_validation_one_number')}
                    </Text>
                </Row>
                <Row alignItems={'center'}>
                    {renderValidIcon(passwordValidation.isEightChars)}
                    <Text variant="bodySmall" color="gray2">
                        {' '}
                        {t('password_validation_eight_characters_minimum')}
                    </Text>
                </Row>
            </Row>
        </>
    );
};
