import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useSubscribeEventsMatchUpdate } from '@/feature/bets-sbk/hooks/use-subscribe-events-match-update';
import { EventsTray } from '@/feature/league-hub-sbk/components/EventsTray';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';

import { useFeaturedEvents } from '../hooks/use-featured-events';

export const FeaturedEventsSection = () => {
    const { t } = useTranslation('lobby_sbk');
    const { data: events, refetch, isFetched, isInitialLoading } = useFeaturedEvents();

    const eventIds = useMemo(() => events?.map(event => event.id) ?? [], [events]);
    useSubscribeEventsMatchUpdate(eventIds);
    useFetchOnResume(refetch);

    if (isInitialLoading) {
        return null;
    }

    if (isFetched && !events?.length) {
        return null;
    }

    return (
        <Box>
            <Text variant="headlineMedium" px="s16" mb="s10" mt="s0">
                {t('featured_events')}
            </Text>
            {events ? <EventsTray events={events} /> : null}
        </Box>
    );
};
