import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

export const TipIcon = props => (
    <Svg
        width="250"
        height="8"
        viewBox="0 0 250 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        testID="tipIcon"
        {...props}
    >
        <G>
            <Path
                d="M16 8H0L6.438.682c.8-.91 2.323-.91 3.124 0L16 8Z"
                fill={props.color ?? designSystem.colors.gray6}
            />
        </G>
    </Svg>
);
