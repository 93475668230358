import React from 'react';

import FootballIcon from '@/assets/icons/footballIcon';
import { getTextColor } from '@/components/TeamScore';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';

import { LogoBox } from './LogoBox';

interface TeamRowProps {
    team: {
        short_code: string;
        logo_url: string | undefined;
        shouldShowFallbackLogo: boolean;
        side: 'home' | 'away';
    };
    primaryColor: string;
    secondaryColor: string;
    possession: string | undefined;
    isLive: boolean;
    winningSide: 'home' | 'away' | 'draw' | undefined;
    testID: string | undefined;
}

export const TeamRow = ({
    team,
    primaryColor,
    secondaryColor,
    possession,
    isLive,
    winningSide,
    testID,
}: TeamRowProps) => (
    <Row alignItems="center">
        <LogoBox
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            logoUrl={team.logo_url}
            shouldShowFallbackLogo={team.shouldShowFallbackLogo}
        />
        <Row alignItems="center" paddingLeft={'s8'} flexShrink={1}>
            <Box flexShrink={1}>
                <Text
                    color={getTextColor(team.side, winningSide)}
                    variant="titleSmall"
                    testID={`eventCard${team.side.charAt(0).toUpperCase() + team.side.slice(1)}Team-${testID}`}
                    numberOfLines={1}
                >
                    {team.short_code}
                </Text>
            </Box>
            {isLive && possession === team.side.toUpperCase() ? (
                <Box marginLeft="s8">
                    <FootballIcon />
                </Box>
            ) : null}
        </Row>
    </Row>
);
