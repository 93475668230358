import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { read, save } from '@/utils/async-storage';

export type OneTimeShowKeys =
    | 'SBK_EventDetailScreen_GameTrackerTooltip'
    | 'SBK_BetDetailScreen_GameTrackerTooltip'
    | 'SBK_DEVScreen_GameTrackerTooltip'
    | 'Picks_EntryScreen_GameTrackerTooltip';

// Each key in OneTimeShowKeys must be added to EXCEPTIONS list within clearAllKeysWithExceptions in src/utils/async-storage.tsx so it is not cleared on logout
export const useShowOneTime = (storageKey: OneTimeShowKeys) => {
    const [show, setShow] = useState(false);

    const localStorageKey = useMemo(() => storageKey, [storageKey]);

    const alreadyHandledInteraction = useRef(false);

    useEffect(() => {
        const checkShown = async () => {
            const hasBeenShown = await read(localStorageKey);
            // If the key does not exist, or the value is false, setShow to true
            if (hasBeenShown === null || (typeof hasBeenShown === 'boolean' && !hasBeenShown)) {
                setShow(true);
            }
        };

        checkShown();
    }, [localStorageKey]);

    const handleInteraction = useCallback(async () => {
        if (!alreadyHandledInteraction.current) {
            await save(localStorageKey, true);
            alreadyHandledInteraction.current = true;
            setShow(false);
        }
    }, [localStorageKey]);

    return { show, handleInteraction };
};
