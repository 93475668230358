import React from 'react';
import { TouchableOpacity } from 'react-native';

import GameVisualizerIcon from '@/assets/icons/gameVisualizer';
import { designSystem } from '@/styles/styles';
import { FloatingModalConfig, useFloatingModal } from '@/utils/floatingModal/FloatingModalProvider';

export const GameTrackerButton = ({
    config,
    sideEffectOnPress,
    iconColor,
}: {
    config: FloatingModalConfig;
    sideEffectOnPress?: () => Promise<void>;
    iconColor?: string;
}) => {
    const { openModal } = useFloatingModal();

    return (
        <TouchableOpacity
            onPress={() => {
                openModal(config);
                sideEffectOnPress?.();
            }}
        >
            <GameVisualizerIcon color={iconColor ?? designSystem.colors.gray3} />
        </TouchableOpacity>
    );
};
