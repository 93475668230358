import React, { useEffect, useState } from 'react';
import { LayoutAnimation, LayoutAnimationConfig, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';

import ChevronRight from '@/assets/icons/chevronRight';
import { Box, Column, Row } from '@/components/lib/components';
import { SEPARATOR_HEGIHT, designSystem } from '@/styles/styles';

type AccordionProps = {
    header: React.ReactNode;
    content: React.ReactNode;
    initialExpanded?: boolean;
    accordionStyle?: ViewStyle;
    headerStyle?: ViewStyle;
    contentStyle?: ViewStyle;
    enabled?: boolean;
    customLayoutAnimation?: LayoutAnimationConfig;
    setIsExpandedExplicit?: () => void;
    isExpandedExplicit?: boolean | undefined;
    accordionExpandedStyle?: ViewStyle;
    accordionCollapsedStyle?: ViewStyle;
    animateExplicitExpansion?: boolean;
};

export const Accordion = ({
    header,
    content,
    initialExpanded,
    accordionStyle = styles.defaultAccordionStyle,
    headerStyle = styles.defaultHeaderStyle,
    contentStyle = styles.defaultContentStyle,
    enabled = true,
    customLayoutAnimation = LayoutAnimation.Presets.easeInEaseOut,
    setIsExpandedExplicit,
    isExpandedExplicit,
    accordionExpandedStyle = {},
    accordionCollapsedStyle = {},
    animateExplicitExpansion = false,
}: AccordionProps) => {
    const [isExpanded, setIsExpanded] = useState(initialExpanded);

    useEffect(() => {
        if (animateExplicitExpansion) {
            LayoutAnimation.configureNext(customLayoutAnimation);
        }
    }, [initialExpanded, customLayoutAnimation, animateExplicitExpansion]);

    const setIsExpandedStateHandler = setIsExpandedExplicit || setIsExpanded;
    const isExpandedState = isExpandedExplicit || isExpanded;

    const toggleExpanded = () => {
        LayoutAnimation.configureNext(customLayoutAnimation);
        setIsExpandedStateHandler(prevIsExpanded => !prevIsExpanded);
    };

    return (
        <Box style={[accordionStyle, isExpanded ? accordionExpandedStyle : accordionCollapsedStyle]}>
            <TouchableOpacity onPress={toggleExpanded} disabled={!enabled} accessible={false}>
                <Row paddingVertical="s16" style={headerStyle} alignItems="center">
                    <Column flex={1}>{header}</Column>
                    <Column>
                        {enabled ? (
                            <Box style={{ transform: [{ rotate: isExpandedState ? '-90deg' : '90deg' }] }}>
                                <ChevronRight />
                            </Box>
                        ) : null}
                    </Column>
                </Row>
            </TouchableOpacity>
            {isExpandedState && content ? (
                <Row pb="s24" style={contentStyle}>
                    {content}
                </Row>
            ) : null}
        </Box>
    );
};

const styles = StyleSheet.create({
    defaultAccordionStyle: {
        borderBottomColor: designSystem.colors.divider,
        borderBottomWidth: SEPARATOR_HEGIHT,
    },
    defaultHeaderStyle: {
        paddingVertical: 16,
    },
    defaultContentStyle: {
        paddingBottom: 24,
    },
});
