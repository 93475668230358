import { NativeModules } from 'react-native';

import { isWeb } from './constants-platform-specific';

const { InfoModule } = NativeModules;

export const getAppVersion = () => {
    if (__DEV__) {
        // ! use require here to be sure pacakge.json is not bundled at all
        return require('../../package.json').version;
    }

    return isWeb ? process.env.version : InfoModule?.getConstants()?.appVersion;
};
