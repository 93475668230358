import * as React from 'react';
import { StyleSheet } from 'react-native';
import Svg, { G, Mask, Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const styles = StyleSheet.create({
    mask: {
        maskType: 'alpha',
    },
});

const SvgComponent = ({ width = 24, height = 24, color = designSystem.colors.white, ...props }) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" {...props}>
        <Mask id="a" width={22} height={22} x={1} y={1} maskUnits="userSpaceOnUse" style={styles.mask}>
            <Path
                fill={color}
                fillRule="evenodd"
                d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm0-3a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
                clipRule="evenodd"
            />
        </Mask>
        <G stroke={color} strokeWidth={2} mask="url(#a)">
            <Path d="m4 4 16 16M9.053 1l5.894 22M14.947 1 9.052 23M20 4 4 20M23 9.053 1 14.947M23 14.947 1 9.053" />
        </G>
    </Svg>
);
export default SvgComponent;
