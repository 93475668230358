import { useTranslation } from 'react-i18next';

import { formatPlayerName } from '@/utils/format-player-name';
import { ordinal } from '@/utils/numeric/format';
import { i18n as i18nType } from 'i18next';

import { Event, MarketType, Player } from '../types';
import { getTeam } from '../utils/get-team';
import { getMicroMarketDescriptionParams } from '../utils/micro-market-descriptions';

// for specific sports (baseball and hockey), we want to display the period as ordinal. ex. "1st Period"
const isPeriodOrdinal = (marketType: MarketType, sportNameSpace: string) => {
    const sports = ['baseball', 'ice_hockey', 'american_football'];
    return marketType?.params && 'period' in marketType?.params && sports.includes(sportNameSpace);
};

type AdditionalMarketNameParams = {
    period_ordinal?: string;
    team?: string;
    possession_ordinal?: string;
    context?: number;
    score_index_ordinal?: string;
};

export type MarketNameParams = MarketType['params'] & AdditionalMarketNameParams;

export type UseMarketNameProps = {
    sportName: string;
    marketType: MarketType;
    fallback: string;
    event?: Pick<Event, 'home_team' | 'away_team'>;
    player?: Player;
    appendTeamName?: boolean; // for specific cases, we want to include the team name within the market name ex. "Team total points: Miami Dolphins"
    appendPlayerName?: boolean; // for specific cases, we want to include the player name within the market name ex. "Touchdowns: Tom Brady"
    appendMicroMarketDescription?: boolean;
    isExtendedName?: boolean;
    replaceTeamBySide?: 'Home' | 'Away';
};

type GetMarketKeyAndParams = Pick<
    UseMarketNameProps,
    'marketType' | 'event' | 'replaceTeamBySide' | 'isExtendedName'
> & {
    sportNameSpace: string;
    i18n: i18nType;
};

type GetAppendedMarketName = Pick<
    UseMarketNameProps,
    'marketType' | 'event' | 'player' | 'appendTeamName' | 'appendPlayerName' | 'appendMicroMarketDescription'
> & {
    marketName: string;
    sportNameSpace: string;
    marketType: MarketType;
    t: ReturnType<typeof useTranslation>['t'];
};

const getMarketKeyAndParams = ({
    sportNameSpace,
    marketType,
    event,
    replaceTeamBySide,
    isExtendedName,
    i18n,
}: GetMarketKeyAndParams) => {
    let namespace = isExtendedName ? 'sbk_markets_extended' : 'sbk_markets';

    let key = `${namespace}:${sportNameSpace}.${marketType.code}`;

    // Check if extended key exists, if it doesn't then just use the main translation namespace (sbk_markets)
    const extendedKeyExists = isExtendedName && i18n.exists(key);
    if (!extendedKeyExists) {
        key = `sbk_markets:${sportNameSpace}.${marketType.code}`;
    }

    let params: MarketNameParams = { ...marketType?.params } ?? {};

    if (params.selections) {
        // using the `context` option here to allow for translations with the same key but different number of selections
        // https://www.i18next.com/translation-function/context
        // MARKET_NAME_2: "market name with 2 selections"
        // MARKET_NAME_3: "market name with 3 selections"
        // MARKET_NAME: "market name with any other number of selections
        params = { ...params, context: params.selections };
    }

    if (params.period && isPeriodOrdinal(marketType, sportNameSpace)) {
        params = { ...params, period_ordinal: ordinal(params.period) };
    }

    if (params.possession) {
        params = { ...params, possession_ordinal: ordinal(params.possession) };
    }

    if (params.team_id) {
        const team = getTeam(marketType.params.team_id, event);
        if (team) {
            params = { ...params, team: team.short_code };
        }
    }

    if (params.score_index) {
        params = { ...params, score_index_ordinal: ordinal(params.score_index) };
    }

    if (replaceTeamBySide) {
        params = { ...params, team: replaceTeamBySide };
    }

    return { key, params };
};

const getAppendedMarketName = ({
    marketName,
    sportNameSpace,
    marketType,
    event,
    player,
    appendTeamName,
    appendPlayerName,
    appendMicroMarketDescription,
    t,
}: GetAppendedMarketName) => {
    // append team name or player name
    if (appendPlayerName && player) {
        marketName += `: ${formatPlayerName({ firstName: player?.first_name, lastName: player?.last_name })}`;
    } else if (appendTeamName) {
        const team = getTeam(marketType.params?.team_id, event);
        if (team) {
            marketName += `: ${team.name}`;
        }
    } else if (appendMicroMarketDescription) {
        const microKey = `sbk_micro_markets:${sportNameSpace}.${marketType.code}`;
        const microMarketParams = getMicroMarketDescriptionParams(marketType);

        // @ts-ignore
        const microMarketDescription = t(microKey, microMarketParams);
        marketName += ` • ${microMarketDescription}`;
    }
    return marketName;
};

export const useMarketName = ({
    sportName,
    marketType,
    event,
    player,
    appendTeamName,
    appendPlayerName,
    appendMicroMarketDescription,
    replaceTeamBySide,
    isExtendedName,
    fallback,
}: UseMarketNameProps) => {
    const { t, i18n } = useTranslation(['sbk_markets', 'sbk_micro_markets', 'sbk_markets_extended']);
    const sportNameSpace = sportName?.replace(/\s/g, '_').toLowerCase();

    const { key, params } = getMarketKeyAndParams({
        sportNameSpace,
        marketType,
        event,
        replaceTeamBySide,
        isExtendedName,
        i18n,
    });
    // @ts-expect-error -- cannot type safe dynamic translation
    let marketName: string = t(key, params);

    // if is not translation not found, use fallback
    if (!marketName || marketName === key) {
        return fallback;
    }

    marketName = getAppendedMarketName({
        marketName,
        sportNameSpace,
        marketType,
        event,
        player,
        appendTeamName,
        appendPlayerName,
        appendMicroMarketDescription,
        t,
    });
    return marketName;
};
