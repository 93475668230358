import React from 'react';
import { StyleSheet } from 'react-native';

import InfoIcon from '@/assets/icons/info-full-white.js';
import { Box, Row } from '@/components/lib/components';
import { PlayerProfileImage } from '@/components/player-profile/PlayerProfileImage';
import { common } from '@/styles/styles';

const styles = StyleSheet.create({
    infoContainer: {
        transform: [{ translateX: -7 }],
    },
    secondPlayerImage: {
        transform: [{ translateX: -20 }],
    },
    playerImage: {
        transform: [{ translateX: 7 }],
    },
});

export type PlayerAlertOverlayProps = {
    playerImage: string;
    teamImageUrl?: string;
    secondPlayerImage?: string;
    secondTeamImageUrl?: string;
    teamColor?: string;
    arcText?: string;
    secondaryArcText?: string;
    secondTeamColor?: string;
    playerNumber?: number | null;
    secondPlayerNumber?: number | null;
};

export const PlayerAlertOverlay = ({
    playerImage,
    teamImageUrl,
    secondPlayerImage,
    secondTeamImageUrl,
    teamColor,
    secondTeamColor,
    arcText,
    secondaryArcText,
    playerNumber,
    secondPlayerNumber,
}: PlayerAlertOverlayProps) => {
    return (
        <Row>
            <PlayerProfileImage
                playerImageUrl={playerImage}
                teamImageUrl={teamImageUrl}
                style={styles.playerImage}
                teamColor={teamColor}
                bgColor={'gray5'}
                arcText={arcText}
                playerNumber={playerNumber}
            />
            <Row
                borderRadius={'r10'}
                justifyContent={'center'}
                alignItems={'center'}
                style={[styles.infoContainer, common.zIndex1]}
            >
                <InfoIcon width={32} height={32} />
            </Row>
            {secondPlayerImage ? (
                <Box style={[styles.secondPlayerImage, common.zIndex0]}>
                    <PlayerProfileImage
                        playerImageUrl={secondPlayerImage}
                        teamImageUrl={secondTeamImageUrl}
                        teamColor={secondTeamColor}
                        bgColor={'gray5'}
                        arcText={secondaryArcText}
                        playerNumber={secondPlayerNumber}
                    />
                </Box>
            ) : null}
        </Row>
    );
};
