import React from 'react';

import { LineSeparator } from '@/components/LineSeparator';
import { LoadingSkeleton } from '@/components/SkeletonLoader';
import { Box, Row } from '@/components/lib/components';
import { common, designSystem } from '@/styles/styles';

const rows = new Array(3).fill('');
export const FeaturedLineupSkeleton = () => {
    return (
        <Box
            flex={1}
            borderRadius="r16"
            backgroundColor="gray6"
            marginHorizontal="s16"
            paddingTop="s16"
            paddingHorizontal="s16"
            paddingBottom="s20"
            gap="s16"
        >
            <Row>
                <Box backgroundColor={'gray5'} borderRadius="r96" width={48} height={48}>
                    <LoadingSkeleton
                        width={48}
                        height={48}
                        skeletonColor={designSystem.colors.gray3}
                        borderRadius="r96"
                    />
                </Box>
                <Box paddingLeft="s12" gap="s6">
                    <Box backgroundColor={'gray5'} borderRadius="r6" height={24} width={100}>
                        <LoadingSkeleton
                            height={24}
                            width={100}
                            skeletonColor={designSystem.colors.gray3}
                            borderRadius="r6"
                        />
                    </Box>
                    <Box backgroundColor={'gray5'} borderRadius="r6" height={20} width={200}>
                        <LoadingSkeleton
                            height={20}
                            width={200}
                            skeletonColor={designSystem.colors.gray3}
                            borderRadius="r6"
                        />
                    </Box>
                </Box>
            </Row>
            <LineSeparator style={common.hairlineHeight} />
            <Box>
                {rows.map((_, index) => (
                    <Row key={index} alignItems="center">
                        <Box alignItems="center">
                            {index !== 0 ? (
                                <Box
                                    height={10}
                                    width={2}
                                    backgroundColor="gray5"
                                    borderBottomLeftRadius="r2"
                                    borderBottomRightRadius="r2"
                                />
                            ) : (
                                <Box height={10} />
                            )}
                            <Box width={8} height={8} backgroundColor="gray5" borderRadius="r8" marginVertical="s6" />
                            {index !== rows.length - 1 ? (
                                <Box
                                    height={10}
                                    width={2}
                                    backgroundColor="gray5"
                                    borderBottomLeftRadius="r2"
                                    borderBottomRightRadius="r2"
                                />
                            ) : (
                                <Box height={10} />
                            )}
                        </Box>
                        <Box marginLeft="s8" backgroundColor={'gray5'} borderRadius="r6" height={20}>
                            <LoadingSkeleton
                                height={20}
                                width={100}
                                skeletonColor={designSystem.colors.gray3}
                                borderRadius="r6"
                            />
                        </Box>
                        <Box
                            backgroundColor={'gray5'}
                            borderRadius="r6"
                            height={20}
                            width={150}
                            style={common.marginLeftAuto}
                        >
                            <LoadingSkeleton
                                height={20}
                                width={150}
                                skeletonColor={designSystem.colors.gray3}
                                borderRadius="r6"
                            />
                        </Box>
                    </Row>
                ))}
            </Box>
            <Box backgroundColor="gray5" borderRadius="r22" height={44}>
                <LoadingSkeleton height={44} skeletonColor={designSystem.colors.gray3} borderRadius="r22" />
            </Box>
        </Box>
    );
};
