import { PlayerInfoFragment } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import { League, PlayerAttributesType, Projection } from '@/types/api.generated';

import { FULL_SEASON_2023 } from './constants';
import { findPlayerAttribute } from './fantasy-attribute-utils';
import { isCollegeSport, isPGA, isUFC } from './league';

export const formatPlayerInfo = ({
    eventTime,
    readOnly,
    projections,
    teamInfoText,
    nflFullSeason,
}: {
    eventTime?: string;
    readOnly?: boolean;
    projections?: readonly Projection[];
    teamInfoText?: string;
    nflFullSeason?: boolean;
}) => {
    const noOfProjections = projections?.length || 0;
    const projectionsText = projections ? (readOnly ? '#' + noOfProjections : `${noOfProjections} proj`) : null;

    if (nflFullSeason) {
        return [FULL_SEASON_2023, projectionsText].filter(Boolean).join(' • ');
    }

    return [teamInfoText, eventTime, projectionsText].filter(Boolean).join(' • ');
};

const formatGolferInfo = (player: PlayerInfoFragment) => {
    const golferRank = findPlayerAttribute(player, PlayerAttributesType.GolfRank);
    const golferCountry = findPlayerAttribute(player, PlayerAttributesType.CountryCode);

    if (golferRank) {
        if (golferCountry) {
            return ` • #${golferRank}, ${golferCountry}`;
        } else {
            return ` • #${golferRank}`;
        }
    } else {
        return golferCountry ? ` • ${golferCountry}` : '';
    }
};

export const formatPlayerPositionAndTeam = (player: PlayerInfoFragment, teamName?: string, event?: EventInfo) => {
    if (!event) {
        return '';
    } else if (isPGA(event?.league)) {
        return formatGolferInfo(player);
    } else if (isUFC(event?.league)) {
        return '';
    } else {
        return ` • ${player.position}, ${teamName}`;
    }
};

export const getPlayerJerseyNumber = (league?: League, jerseyNumber?: number) => {
    return isCollegeSport(league) ? jerseyNumber || 99 : null;
};
