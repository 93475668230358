import React, { useCallback, useRef } from 'react';
import { Animated, useWindowDimensions } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { Box } from '@/components/lib/components';
import { common } from '@/styles/styles';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

import { MarketCategory } from '../types';
import { SubMarketTabItem } from './SubMarketTabItem';

const keyExtractor = (item: MarketCategory) => JSON.stringify(item.market_type);

type SubMarketTabsProps = {
    sportName: string;
    marketCategories: Array<MarketCategory>;
    activeSubMarket: MarketCategory;
    setActiveSubMarket: (marketCategory: MarketCategory) => void;
};

export function useScrollToCenter<T>(
    buttonRefs: React.MutableRefObject<number[]>,
    flatListRef: React.RefObject<FlatList<T>>
) {
    const { width: screenWidth } = useWindowDimensions();
    return useCallback(
        (index: number) => {
            let position = 0;
            for (let i = 0; i < index; i++) {
                position += buttonRefs.current[i];
            }
            position -= (screenWidth - buttonRefs.current[index]) / 2;
            flatListRef.current?.scrollToOffset({ offset: position, animated: true });
        },
        [buttonRefs, flatListRef, screenWidth]
    );
}

export const SubMarketTabs = ({
    sportName,
    marketCategories,
    activeSubMarket,
    setActiveSubMarket,
}: SubMarketTabsProps) => {
    const flatListRef = useRef<FlatList<MarketCategory>>(null);
    const buttonRefs = useRef<Array<number>>([]);

    const scrollToCenter = useScrollToCenter(buttonRefs, flatListRef);

    const handlePressTab = useCallback(
        (marketCategory: MarketCategory, index: number) => {
            setActiveSubMarket(marketCategory);
            scrollToCenter(index);
        },
        [setActiveSubMarket, scrollToCenter]
    );

    const renderItem = useCallback(
        ({ item, index }: { item: MarketCategory; index: number }) => {
            return (
                <Animated.View
                    onLayout={event => {
                        buttonRefs.current[index] = event.nativeEvent.layout.width;
                    }}
                >
                    <SubMarketTabItem
                        sportName={sportName}
                        marketCategory={item}
                        key={JSON.stringify(item.market_type)}
                        isSelected={activeSubMarket.market_type === item.market_type}
                        onPress={marketCategory => handlePressTab(marketCategory, index)}
                        testID={`subMarketTab-${index.toString()}`}
                    />
                </Animated.View>
            );
        },
        [activeSubMarket.market_type, handlePressTab, sportName]
    );

    return (
        <Box marginVertical="s16">
            <FlatList
                horizontal
                ref={flatListRef}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                data={marketCategories}
                showsHorizontalScrollIndicator={showScrollIndicator}
                contentContainerStyle={common.paddingHorizontal}
            />
        </Box>
    );
};
