import * as Urql from 'urql';
import gql from 'graphql-tag';

import * as Types from '../../types/api.generated';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LeagueConfigsFragment = {
    __typename?: 'LeagueConfig';
    league: Types.League;
    playerProfileBackgroundColor?: string;
    icon?: string;
    enabled: boolean;
    label: string;
    order: number;
    jurisdictions: Array<{ __typename?: 'Jurisdiction'; symbol: string }>;
    positionFilters: Array<{
        __typename?: 'PositionFilters';
        position?: string;
        label?: string;
        positionDescription?: string;
    }>;
};

export type GetAllLeaguesConfigsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAllLeaguesConfigsQuery = {
    __typename?: 'Query';
    getLeagueConfigs: Array<{
        __typename?: 'LeagueConfig';
        league: Types.League;
        playerProfileBackgroundColor?: string;
        icon?: string;
        enabled: boolean;
        label: string;
        order: number;
        jurisdictions: Array<{ __typename?: 'Jurisdiction'; symbol: string }>;
        positionFilters: Array<{
            __typename?: 'PositionFilters';
            position?: string;
            label?: string;
            positionDescription?: string;
        }>;
    }>;
};

export const LeagueConfigsFragmentDoc = gql`
    fragment LeagueConfigs on LeagueConfig {
        league
        playerProfileBackgroundColor
        icon
        enabled
        icon
        label
        order
        jurisdictions {
            symbol
        }
        positionFilters {
            position
            label
            positionDescription
        }
    }
`;
export const GetAllLeaguesConfigsDocument = gql`
    query getAllLeaguesConfigs {
        getLeagueConfigs {
            ...LeagueConfigs
        }
    }
    ${LeagueConfigsFragmentDoc}
`;

export function useGetAllLeaguesConfigsQuery(
    options?: Omit<Urql.UseQueryArgs<GetAllLeaguesConfigsQueryVariables>, 'query'>
) {
    return Urql.useQuery<GetAllLeaguesConfigsQuery, GetAllLeaguesConfigsQueryVariables>({
        query: GetAllLeaguesConfigsDocument,
        ...options,
    });
}
