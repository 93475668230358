import { OddsUpdateMessageOption } from '@/utils/websocket/types';
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { INITIAL_EXPAND_ACCORDION_COUNT } from '../components/market-page-layouts/constants';
import { Market, MarketCategory, Option } from '../types';

type MarketCacheStore = {
    markets: Record<
        string,
        {
            isMainLine: boolean;
            published: boolean;
            status: Market['status'];
        }
    >;
    options: Record<
        string,
        {
            odds: Option['odds'];
            status: Option['status'];
        }
    >;
    actions: {
        handleOddsUpdate: (message: OddsUpdateMessageOption[]) => void;
        populate: (markets: Market[]) => void;
    };
};

export const useMarketCacheStore = create<MarketCacheStore>()(set => {
    return {
        markets: {},
        options: {},
        actions: {
            handleOddsUpdate: (message: OddsUpdateMessageOption[]) => {
                const formattedOptions: MarketCacheStore['options'] = {};
                const formattedMarkets: MarketCacheStore['markets'] = {};
                message.forEach(msg => {
                    formattedOptions[msg.id] = {
                        odds: msg.odds,
                        status: msg.status,
                    };
                    formattedMarkets[msg.marketId] = {
                        status: msg.status,
                        isMainLine: msg.isMainLine,
                        published: msg.published,
                    };
                });

                set(state => ({
                    ...state,
                    options: {
                        ...state.options,
                        ...formattedOptions,
                    },
                    markets: {
                        ...state.markets,
                        ...formattedMarkets,
                    },
                }));
            },
            populate: (markets: Market[]) => {
                const marketCache: MarketCacheStore['markets'] = {};
                const optionCache: MarketCacheStore['options'] = {};
                markets.forEach(market => {
                    marketCache[market.id] = {
                        status: market.status,
                        isMainLine: market.is_main_line,
                        published: market.published,
                    };
                    market.options.forEach(option => {
                        optionCache[option.id] = {
                            odds: option.odds,
                            status: option.status,
                        };
                    });
                });
                set(state => ({
                    ...state,
                    markets: {
                        ...state.markets,
                        ...marketCache,
                    },
                    options: {
                        ...state.options,
                        ...optionCache,
                    },
                }));
            },
        },
    };
});

export const useOptionOdds = (optionId: string): number | undefined =>
    useMarketCacheStore(state => state.options[optionId]?.odds);
export const useOptionStatus = (optionId: string) => useMarketCacheStore(state => state.options[optionId]?.status);

export const useMarketStatus = (marketId: string) => useMarketCacheStore(state => state.markets[marketId]?.status);
export const useIsMarketPublished = (marketId: string) =>
    useMarketCacheStore(state => state.markets[marketId]?.published);

export const usePublishedMarkets = (markets: Market[]) =>
    useMarketCacheStore(useShallow(state => markets.filter(market => state.markets[market.id]?.published)));

export const useHasPublishedMarkets = (marketCategories: MarketCategory[]) =>
    useMarketCacheStore(state =>
        marketCategories.reduce(
            (hasPublished, category) =>
                hasPublished || category.markets.some(market => state.markets[market.id]?.published),
            false
        )
    );

// Returns the first 3 market groups that have at least one published market
// Used to determine which market accordions should be expanded by initially
export const useInitialExpandedMarketAccordions = (marketCategories: MarketCategory[]) =>
    useMarketCacheStore(
        useShallow(state => {
            return (
                marketCategories
                    // Filter out market groups that have no published markets
                    .filter(category => category.markets.some(market => state.markets[market.id]?.published))
                    // Get the first 3 market types
                    .slice(0, INITIAL_EXPAND_ACCORDION_COUNT)
                    .map(category => category.market_type)
            );
        })
    );
