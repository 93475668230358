import React from 'react';
import { Animated, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import BackArrow from '@/assets/icons/backArrow';
import { CloseIcon } from '@/assets/icons/close';
import { LineSeparator } from '@/components/LineSeparator';
import { common } from '@/styles/styles';

import { SizedBox } from './SizedBox';
import { StyleVariant, Text } from './TextComponent';

export const SCREEN_NAV_BAR_HEIGHT = 60;

const styles = StyleSheet.create({
    container: {
        height: SCREEN_NAV_BAR_HEIGHT,
    },
});

export type ScreenNavBarProps = {
    title?: string;
    subTitle?: string;
    rightIcon?: React.ReactNode;
    titleOverrideStyle?: StyleProp<TextStyle>;
    titleVariant?: StyleVariant;
    closeIconMode?: 'back' | 'close' | 'none';
    onClose?: () => void;
    scrollAnim?: React.MutableRefObject<Animated.Value>;
    style?: StyleProp<ViewStyle>;
    secondaryBar?: React.ReactNode;
    handlePressTracking?: () => void;
    showNavbarTitle?: boolean;
};

export const ScreenNavBar = ({
    title = '',
    subTitle,
    rightIcon = null,
    titleOverrideStyle,
    titleVariant,
    closeIconMode = 'back',
    onClose,
    scrollAnim,
    style,
    secondaryBar,
    handlePressTracking,
    showNavbarTitle = true,
}: ScreenNavBarProps) => {
    const navigation = useNavigation();

    const handleOnClose = () => {
        handlePressTracking?.();
        if (onClose) {
            onClose();
        } else {
            navigation.goBack();
        }
    };

    return (
        <>
            <View style={[common.spaceBetweenRow, common.paddingHorizontal, styles.container, style]}>
                <View style={common.flex}>
                    <TouchableOpacity onPress={handleOnClose} testID="backArrow">
                        {closeIconMode === 'close' ? <CloseIcon /> : null}
                        {closeIconMode === 'back' ? <BackArrow /> : null}
                    </TouchableOpacity>
                </View>
                {showNavbarTitle ? (
                    <View style={[common.alignCenter]} testID={`screenTitle-${title.replace(/\s/g, '_')}`}>
                        <Text variant={titleVariant || 'titleLarge'} style={titleOverrideStyle}>
                            {title}
                        </Text>
                        {subTitle ? (
                            <>
                                <SizedBox value={5} />
                                <Text fontSize={13} color={'gray3'} textAlign={'center'}>
                                    {subTitle}
                                </Text>
                            </>
                        ) : null}
                    </View>
                ) : null}

                <View style={common.flex}>
                    {rightIcon ? <View style={common.alignEnd}>{rightIcon}</View> : <SizedBox value={5} />}
                </View>
            </View>
            {secondaryBar}
            {scrollAnim ? <LineSeparator style={{ opacity: scrollAnim.current }} /> : null}
        </>
    );
};
