export type ErrorResponse<T> = {
    message: string;
    errors: Record<string, T | T[]>;
    error_codes: T[];
    fields?: string[];
};

export class GenericError extends Error implements ErrorResponse<string> {
    errors: Record<string, string | string[]>;
    error_codes: string[];
    fields?: string[];
    constructor(
        message: string,
        errors: Record<string, string | string[]> = {},
        error_codes: string[] = [],
        fields: string[] = []
    ) {
        super(message);
        this.errors = errors;
        this.error_codes = error_codes;
        this.fields = fields;
    }
}

export const isErrorResponse = <T>(error: unknown): error is T => {
    return !!error && typeof error === 'object' && 'error_codes' in error;
};
