import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { BottomFooterRG } from '@/components/FooterRG';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { MarketsUnavailable } from '@/feature/league-hub-sbk/components/MarketsUnavailable';
import { isSmallScreen } from '@/styles/sizes';
import { common } from '@/styles/styles';

const LEAGUE_OFF_HEIGHT = 300;

const styles = StyleSheet.create({
    listContainer: {
        paddingTop: 8,
        paddingHorizontal: 16,
    },
});

// Returns shared flat list props for league market category flat lists
export const useLeagueMarketList = (
    hasPublishedMarkets: boolean,
    contentHeight: number,
    isTabBarVisible: boolean,
    marketHeaderSectionHeight: number
) => {
    const { t } = useTranslation('league_hub_sbk');

    const paddingTop = marketHeaderSectionHeight + (isTabBarVisible ? TAB_HEIGHT : 0);
    let leagueOffHeight = isSmallScreen ? LEAGUE_OFF_HEIGHT - 30 : LEAGUE_OFF_HEIGHT;
    leagueOffHeight = hasPublishedMarkets ? leagueOffHeight : leagueOffHeight - (isTabBarVisible ? TAB_HEIGHT : 0);

    const flatListProps = {
        showsVerticalScrollIndicator: false,
        ListEmptyComponent: (
            <MarketsUnavailable
                offHeight={leagueOffHeight}
                description={t('category_markets_unavailable_description')}
            />
        ),
        ListFooterComponent: <BottomFooterRG footerMarginTop="s64" />,
        // ! we want to override the contentContainerStyle to add SUB_TAB_HEIGHT to padding
        contentContainerStyle: [
            common.grow,
            styles.listContainer,
            {
                // ! move all the content down to make space for the sticky tabs
                paddingTop,
                // ! set the height of the available content to be size of the content + padding
                // ! so that the tabs can be completely scrolled up or down
                minHeight: contentHeight + paddingTop,
            },
        ],
    };
    return { flatListProps };
};
