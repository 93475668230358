import { GeneratedTrendingPlayer } from '@/feature/lobby/utils/trendingPlayer';

import { PlayerAndEventInfo } from '../hooks/use-players-search';

export const mapTrendingPlayersToSearchPlayers = (trendingPlayers: GeneratedTrendingPlayer[]): PlayerAndEventInfo[] => {
    return trendingPlayers.map(player => {
        const { event, ...playerProps } = player;
        return {
            player: playerProps,
            event,
        };
    });
};
