import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';

import ArrowIcon from '@/assets/icons/arrow';
import { Button } from '@/components/ButtonComponent';
import { DesignSystemColor } from '@/components/lib/theme';
import { common, designSystem } from '@/styles/styles';
import { Outcome } from '@/types/api.generated';

type PickButtonProps = {
    outcome: string;
    isSelected: boolean;
    onPress: () => void;
    backgroundColor?: DesignSystemColor;
    testID?: string;
};

const styles = StyleSheet.create({
    selfCentered: {
        alignSelf: 'center',
    },
    roundButton: { paddingVertical: 12, paddingHorizontal: 12, borderWidth: 0 },
});

/**
 * PickProjectionButton renders the More/Less button used for picking a player's projection
 */
export const PickProjectionButton = ({
    outcome,
    isSelected,
    onPress,
    backgroundColor = 'gray6',
    testID,
}: PickButtonProps) => {
    const arrowStyle = outcome === Outcome.More ? common.rotate180 : {};

    return (
        <RoundButton
            onPress={onPress}
            label={<ArrowIcon style={arrowStyle} color={isSelected ? designSystem.colors.gray8 : 'white'} />}
            backgroundColor={isSelected ? 'white' : backgroundColor}
            analyticsTag={outcome}
            testID={testID}
        />
    );
};

export const RoundButton = ({
    label,
    onPress,
    backgroundColor,
    analyticsTag,
    testID,
}: {
    label: string | ReactElement;
    backgroundColor: DesignSystemColor;
    onPress: () => void;
    analyticsTag?: string;
    testID?: string;
}) => (
    <Button
        onPress={onPress}
        label={label}
        variant="rounded"
        style={[styles.roundButton, { backgroundColor: designSystem.colors[backgroundColor] }]}
        pickButton={true}
        analyticsTag={analyticsTag}
        hitSlop={{ top: 8, bottom: 8, left: 8, right: 8 }}
        testID={`projectionButton-${analyticsTag}-${testID}`}
    />
);
