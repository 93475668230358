import { BetSummarySectionProps } from '@/components/BetSummarySection';

import { Bet } from '../../hooks/types';
import { useSharedBetSummaryProps, useVoidedBetSummary } from '../bet-cards/utils';

/**
 * Shared bet summary props for Bet Details
 */
export const useSharedBetDetailsBetSummary = (bet: Bet) => {
    const bodyTextVariant = 'titleMedium' as const;
    return {
        ...useSharedBetSummaryProps(bet),
        bodyTextVariant,
    };
};

/**
 * Handles all 'Voided' bet summary for Bet Details
 */
export const useBetDetailsVoidedBetSummary = (bet: Bet): BetSummarySectionProps => {
    return {
        ...useSharedBetDetailsBetSummary(bet),
        ...useVoidedBetSummary(bet, 'gray4'),
    };
};
