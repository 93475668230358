import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={29} height={20} viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M19.9602 14.5962L16.7782 11.4142L13.5962 14.5962L12.182 13.182L15.364 10L12.182 6.81802L13.5962 5.40381L16.7782 8.58579L19.9602 5.40381L21.3744 6.81802L18.1924 10L21.3744 13.182L19.9602 14.5962Z"
            fill="white"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.0001 0H7.98882L0.766602 10L7.98882 20H29.0001V0ZM3.23367 10L9.01145 2H27.0001V18H9.01145L3.23367 10Z"
            fill="white"
        />
    </Svg>
);

export default SvgComponent;
