import React from 'react';
import { StyleSheet } from 'react-native';

import { PlayerProjectionFragment } from '@/api/events/query.generated';
import Anchor from '@/assets/icons/anchor';
import DiscountIcon from '@/assets/icons/discount';
import EdgeComboIcon from '@/assets/icons/edge-combo-icon';
import InflatedIcon from '@/assets/icons/inflated';
import RocketIcon from '@/assets/icons/rocket';
import { Box, Row } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';
import { getNonRegularProjections } from '@/utils/getProjectionsTypes';

type GroupProjectionsIconsProps = {
    projections?: PlayerProjectionFragment[];
};

/* Display the projection icons for all projections */
export const GroupProjectionsIcons = ({ projections }: GroupProjectionsIconsProps) => {
    const {
        hasBoostedProjections,
        hasSpecialIncreasedProjections,
        hasSpecialDecreasedProjections,
        hasAnchorProjections,
        hasEdgeProjections,
    } = getNonRegularProjections(projections);
    if (
        !hasBoostedProjections &&
        !hasSpecialIncreasedProjections &&
        !hasSpecialDecreasedProjections &&
        !hasEdgeProjections &&
        !hasAnchorProjections
    ) {
        return null;
    }

    return (
        <Row alignItems="center" pr={'s6'}>
            {hasSpecialDecreasedProjections ? (
                <Box
                    backgroundColor={'special'}
                    padding={'s2'}
                    justifyContent={'center'}
                    style={[
                        styles.iconWrapper,
                        styles.iconSpecialDiscountWrapper,
                        (hasSpecialIncreasedProjections ||
                            hasBoostedProjections ||
                            hasEdgeProjections ||
                            hasAnchorProjections) &&
                            styles.border,
                    ]}
                    borderRadius={'r6'}
                >
                    <DiscountIcon width={16} height={16} />
                </Box>
            ) : null}
            {hasSpecialIncreasedProjections ? (
                <Box
                    backgroundColor={'surge'}
                    padding={'s2'}
                    justifyContent={'center'}
                    style={[
                        styles.iconWrapper,
                        styles.iconSpecialIncreaseWrapper,
                        hasSpecialDecreasedProjections && styles.leftIcon,
                        (hasBoostedProjections || hasEdgeProjections || hasAnchorProjections) && styles.border,
                    ]}
                    borderRadius={'r6'}
                >
                    <InflatedIcon />
                </Box>
            ) : null}
            {hasBoostedProjections ? (
                <Box
                    backgroundColor={'boosted'}
                    padding={'s2'}
                    justifyContent={'center'}
                    style={[
                        styles.iconWrapper,
                        (hasSpecialDecreasedProjections || hasSpecialIncreasedProjections) && styles.leftIcon,
                        styles.iconBoosted,
                        (hasEdgeProjections || hasAnchorProjections) && styles.border,
                    ]}
                    borderRadius={'r6'}
                >
                    <RocketIcon width={16} height={16} color={designSystem.colors.boostedRocket} />
                </Box>
            ) : null}
            {hasEdgeProjections ? (
                <Box
                    backgroundColor={'edgeBlue'}
                    padding={'s2'}
                    justifyContent={'center'}
                    style={[
                        styles.iconWrapper,
                        (hasSpecialDecreasedProjections || hasSpecialIncreasedProjections || hasBoostedProjections) &&
                            styles.leftIcon,
                        styles.iconEdge,
                        hasAnchorProjections && styles.border,
                    ]}
                    borderRadius={'r6'}
                >
                    <EdgeComboIcon width={16} height={16} color={designSystem.colors.edgeLockshield} />
                </Box>
            ) : null}
            {hasAnchorProjections ? (
                <Box
                    backgroundColor={'white'}
                    padding={'s2'}
                    justifyContent={'center'}
                    style={[
                        styles.iconWrapper,
                        (hasSpecialDecreasedProjections ||
                            hasSpecialIncreasedProjections ||
                            hasBoostedProjections ||
                            hasEdgeProjections) &&
                            styles.leftIcon,
                    ]}
                    borderRadius={'r6'}
                >
                    <Anchor width={16} height={16} />
                </Box>
            ) : null}
        </Row>
    );
};

const styles = StyleSheet.create({
    iconWrapper: {
        borderCurve: 'continuous',
    },
    iconSpecialDiscountWrapper: {
        zIndex: designSystem.zIndex.zIndex4,
        elevation: 3,
    },
    iconSpecialIncreaseWrapper: {
        zIndex: designSystem.zIndex.zIndex3,
        elevation: 2,
    },
    leftIcon: {
        marginLeft: -4,
    },
    iconBoosted: {
        zIndex: designSystem.zIndex.zIndex2,
    },
    iconEdge: {
        zIndex: designSystem.zIndex.zIndex1,
    },
    border: {
        borderRightWidth: 2,
    },
});
