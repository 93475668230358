import React, { FC, PropsWithChildren, useRef } from 'react';
import { Animated, ScrollViewProps } from 'react-native';

import { Screen, ScreenProps } from '@/components/ScreenComponent';
import { ScreenNavBar, ScreenNavBarProps } from '@/components/ScreenNavBar';
import { common } from '@/styles/styles';
import { nativeDriverValue } from '@/utils/constants-platform-specific';

type ScrollableScreenProps = PropsWithChildren<{
    screenNavBarProps: ScreenNavBarProps;
    scrollViewProps?: ScrollViewProps;
    unsafe?: boolean;
    footer?: React.ReactNode;
    screenProps?: Omit<ScreenProps, 'children'>;
}>;

export const ScrollableScreen: FC<ScrollableScreenProps> = ({
    screenNavBarProps,
    scrollViewProps,
    footer,
    children,
    screenProps,
}) => {
    const scrollAnim = useRef(new Animated.Value(0));
    return (
        <Screen {...screenProps}>
            <ScreenNavBar {...screenNavBarProps} scrollAnim={scrollAnim} />
            <Animated.ScrollView
                {...scrollViewProps}
                keyboardShouldPersistTaps="handled"
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollAnim.current } } }], {
                    useNativeDriver: nativeDriverValue,
                })}
                style={[common.paddingHorizontal, scrollViewProps?.style && scrollViewProps.style]}
            >
                {children}
            </Animated.ScrollView>
            <>{footer}</>
        </Screen>
    );
};
