import { URLS, createJurisdictionHeaders } from '@/data';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';

const REJECT_DEPOSIT_LOG_TAG = '[Reject Deposit]';

type RejectDepositResponse = {
    success: boolean;
};

/**
 * Custom hook for marking the transaction as incomplete.
 *
 * @param user_wallet_id - Id of the user wallet
 */
const rejectDeposit = async (user_wallet_id: string): Promise<RejectDepositResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/reject-deposit`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user_wallet_id,
        }),
    });
    const responseData = await response.json();
    if (!responseData.success) {
        logger.warn(REJECT_DEPOSIT_LOG_TAG, 'Error rejecting deposit', responseData);
    }
    return responseData;
};

export const useRejectDeposit = () => {
    return useMutation({
        mutationKey: ['reject-deposit'],
        mutationFn: rejectDeposit,
    });
};
