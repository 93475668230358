import React from 'react';
import { View } from 'react-native';

/**
 * TODO: To Implement react native skia on web - https://pitchtech.atlassian.net/browse/PIC-3063.
 * we need first dynamic imports enabled i.e. updating tsconfig.json module to at least es2020
 *
 * Then we need something as below, refer https://shopify.github.io/react-native-skia/docs/getting-started/web/
 * <WithSkiaWeb getComponent={() => import('../components/SkiaView')} fallback={<Text>Loading ...</Text>}/>
 *
 * Then since the current WithSkiaWeb does not implement passing props we need to patch-package as per below PR
 * https://github.com/Shopify/react-native-skia/pull/2321
 *
 *
 * Once the above is there, go to EventDetails screen in SBK and try switching tabs and you may see one of the below error
 * https://github.com/Shopify/react-native-skia/discussions/1084
 *
 * As per above discussion's fix if we try using Using Deferred Component Registration method
 * : https://shopify.github.io/react-native-skia/docs/getting-started/web/#using-deferred-component-registration
 *
 * By updating index.web.js code to
 * import { LoadSkiaWeb } from "@shopify/react-native-skia/lib/module/web"
 *
 *  const init = () => {
        AppRegistry.registerComponent(app.name, () => App);
        AppRegistry.runApplication(app.name, {
            rootTag: document.getElementById('root'),
        });
    };
    LoadSkiaWeb().then(() =>init());
 *
 *  if you manage to get the above working, you may see below error on switching tabs
 *
 * https://github.com/Shopify/react-native-skia/issues/2426
 *
 */
const SkiaView = () => {
    return <View />;
};

export default SkiaView;
