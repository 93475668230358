import { createJurisdictionHeaders } from '@/data';
import { URLS } from '@/data/config';
import { useQuery } from '@tanstack/react-query';

export type WithdrawMethodResponse = {
    data: {
        start_of_year_balance: number;
        end_of_year_balance: number;
        total_amount_deposited: number;
        total_amount_wagered: number;
        total_winnings: number;
        total_successful_withdrawals: number;
        number_of_wagers: number;
        winning_wagers_count: number;
    };
};

const getYearEndStatement = async (): Promise<WithdrawMethodResponse> => {
    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/year-end-statement`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });
    const data = await resp.json();
    if (!resp.ok) {
        throw data;
    }
    return data;
};

/**
 * This is a hook that returns the year end statement
 * */
export const useYearEndStatement = () => {
    return useQuery(['year-end-statement'], getYearEndStatement, {
        select: data => data.data,
    });
};
