import { MarketType, Option } from '../types';

/**
 * Sort options for stacked_standard layout. Team that is favoured based on the market line is placed on top. Draw is always placed last
 * ex. For market with line -1.5, HOME team is placed on top. For market with line 1.5, AWAY team is placed on top.
 */
export const sortOptionsFavouriteOnTop = (a: Option, b: Option, marketType?: MarketType) => {
    const isADraw = a.option_type.code === 'DRAW';
    if (isADraw) {
        return 1;
    }
    const isBDraw = b.option_type.code === 'DRAW';
    if (isBDraw) {
        return -1;
    }

    const isHome = a.option_type.code === 'HOME';
    return marketType?.params?.line! <= 0 ? (isHome ? -1 : 1) : isHome ? 1 : -1;
};
