import * as React from 'react';

const SvgComponent = props => (
    <svg width="744" height="216" viewBox="0 0 393 216" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="744" height="216" transform="translate(-175)" fill="url(#paint0_radial_8109_10693)" />
        <defs>
            <radialGradient
                id="paint0_radial_8109_10693"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(372) rotate(90) scale(215 370.278)"
            >
                <stop stopColor="#CF51F5" stopOpacity="0.33" />
                <stop offset="1" stopColor="#CF51F5" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
);
export default SvgComponent;
