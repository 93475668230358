import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 4.5H2V15.5H14V4.5ZM8.875 12V8H7.125V12H8.875Z"
            fill="#43444F"
        />
        <Path
            d="M11 4.5V4.38C11 2.72315 9.65685 1.38 8 1.38V1.38C6.34315 1.38 5 2.72315 5 4.38V4.5"
            stroke="#43444F"
            strokeWidth="1.75"
            strokeLinecap="square"
        />
    </Svg>
);

export default SvgComponent;
