import { Event } from '@/feature/event-details-sbk/types';
import { designSystem } from '@/styles/styles';

/**
 * Get the team colors based on the event's teams.
 * If event is not given, it will return the fallback color.
 */
export const getTeamColors = (event?: Event) => {
    // If any missing colors, use fallback colors
    if (
        !event?.home_team?.primary_color ||
        !event?.home_team?.secondary_color ||
        !event?.away_team?.primary_color ||
        !event?.away_team?.secondary_color
    ) {
        return {
            homePrimary: designSystem.colors.gray3,
            homeSecondary: designSystem.colors.gray8,
            awayPrimary: designSystem.colors.gray3,
            awaySecondary: designSystem.colors.gray8,
            shouldShowFallbackLogo: true,
        };
    }
    return {
        homePrimary: event.home_team.primary_color.toLowerCase(),
        homeSecondary: event.home_team.secondary_color.toLowerCase(),
        awayPrimary: event.away_team.primary_color.toLowerCase(),
        awaySecondary: event.away_team.secondary_color.toLowerCase(),
        shouldShowFallbackLogo: !event?.away_team.logo_url || !event?.home_team.logo_url,
    };
};
