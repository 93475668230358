import React from 'react';

// import ShareIcon from '@/assets/icons/share-small';
import { BadgeStatus, StatusBadge } from '@/components/StatusBadge';
import { Text, TextProps } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';
import { SEPARATOR_HEGIHT } from '@/styles/styles';

import { BetSummarySection, BetSummarySectionProps } from './BetSummarySection';
import { DotStatus, ProgressDots } from './ProgressDots';

type BetCardProps = {
    title: string;
    subTitle: string;
    status: BadgeStatus;
    footer: string;
    summary: BetSummarySectionProps;
    titleColor?: TextProps['color'];
    subTitleColor?: TextProps['color'];
    progressDots?: Array<DotStatus>;
};

export const BetCard = ({
    title,
    subTitle,
    status,
    footer,
    summary,
    titleColor = 'gray1',
    subTitleColor = 'gray2',
    progressDots = [],
}: BetCardProps) => {
    return (
        <Box borderWidth={1} borderColor="gray6" borderRadius="r12">
            <Row pl="s16" paddingVertical="s16">
                <Column flex={1}>
                    <Text variant="titleMedium" numberOfLines={1} color={titleColor}>
                        {title}
                    </Text>
                    <Text variant="bodySmall" numberOfLines={1} color={subTitleColor}>
                        {subTitle}
                    </Text>
                    {progressDots.length > 1 ? (
                        <Box mt="s8">
                            <ProgressDots dots={progressDots} />
                        </Box>
                    ) : null}
                </Column>
                <Column paddingHorizontal="s16" alignItems="flex-end">
                    <StatusBadge status={status} />
                </Column>
            </Row>
            <Row
                paddingVertical="s12"
                borderTopWidth={SEPARATOR_HEGIHT}
                borderBottomWidth={SEPARATOR_HEGIHT}
                borderColor="gray7"
            >
                <BetSummarySection {...summary} />
            </Row>
            <Row paddingVertical="s16">
                <Column flex={1} pl="s16">
                    <Text variant="bodySmall" color="gray3" fontSize={12}>
                        {footer}
                    </Text>
                </Column>
                <Column width={52} alignItems="flex-end" pr="s16">
                    {/*<ShareIcon />*/}
                </Column>
            </Row>
        </Box>
    );
};
