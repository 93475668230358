import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';

import { useMarketLineSelection } from '../../hooks/use-market-line-selection';
import { Event, Market } from '../../types';
import { getTeam } from '../../utils/get-team';
import { OptionGridLayout } from './option-button-grid/OptionGridLayout';

type MultiSplitStandardMarketProps = {
    markets: Array<Market>;
    event: Event;
    testID?: string;
};

export const MultiSplitStandardMarket = ({ markets, event, testID }: MultiSplitStandardMarketProps) => {
    // group markets by team id to display them in separate sections
    const teamIds = [event?.away_team.id, event?.home_team.id];

    return (
        <Box flex={1} gap="s12">
            {teamIds.filter(Boolean).map(teamId => {
                const teamMarkets = markets.filter(m => m.market_type.params.team_id === teamId);
                if (teamMarkets.length) {
                    return <SplitMarketWithTeamName key={teamId} markets={teamMarkets} event={event} testID={testID} />;
                } else {
                    return null;
                }
            })}
        </Box>
    );
};

const SplitMarketWithTeamName = ({ markets, event, testID }: MultiSplitStandardMarketProps) => {
    const { hasAlternateMarkets, selectedMarket, showAlternateMarkets } = useMarketLineSelection({
        eventId: event.id,
        markets,
        marketAppendTeamName: true,
    });
    const team = getTeam(selectedMarket?.market_type.params.team_id, event);
    const { t } = useTranslation(['common']);
    return (
        <Column>
            <Row marginVertical={'s8'}>
                <Text color="gray2" variant="bodySmall">
                    {team?.short_code} {team?.name}
                </Text>
            </Row>
            <OptionGridLayout columns={2} event={event} market={selectedMarket} testID={testID} />
            {hasAlternateMarkets ? (
                <Box mt={'s16'}>
                    <Button label={t('view_more')} variant="roundedOutlined" onPress={showAlternateMarkets} />
                </Box>
            ) : null}
        </Column>
    );
};
