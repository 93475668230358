import { KeyboardEvent, useEffect } from 'react';

import { isWeb } from '@/utils/constants-platform-specific';

type Props = {
    onNumberPress: (number: number) => void;
    onDecimalPress: () => void;
    onBackspacePress: () => void;
};

const useWebKeyboard = ({ onNumberPress, onDecimalPress, onBackspacePress }: Props) => {
    useEffect(() => {
        if (isWeb) {
            const handleKeyDown = (event: KeyboardEvent) => {
                const { key } = event;
                if (key >= '0' && key <= '9') {
                    onNumberPress(Number(key));
                } else if (key === '.') {
                    onDecimalPress();
                } else if (key === 'Backspace') {
                    onBackspacePress();
                }
            };

            window.addEventListener('keydown', handleKeyDown);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [onNumberPress, onDecimalPress, onBackspacePress]);
};

export default useWebKeyboard;
