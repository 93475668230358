import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={12} height={13} fill="none" {...props}>
        <Path
            fill={props.color ?? '#EDEFF3'}
            fillRule="evenodd"
            d="m1.22 5.28 1.06-1.06L6 7.94l3.72-3.72 1.06 1.06L6 10.06 1.22 5.28Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
