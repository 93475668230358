import { useMemo } from 'react';

import { useLeague } from './use-league';

const SUB_TAB_HEIGHT = 68;
const GAME_SECTION_HEIGHT = 130;
const ONE_MARKET_GAME_SECTION_HEIGHT = 170;

export const useSectionHeights = (leagueId: string) => {
    const { data: league } = useLeague(leagueId);
    const gameSectionHeight = useMemo(() => {
        return league?.market_categories.length === 1 ? ONE_MARKET_GAME_SECTION_HEIGHT : GAME_SECTION_HEIGHT;
    }, [league]);

    const marketHeaderSectionHeight = useMemo(() => {
        return gameSectionHeight + SUB_TAB_HEIGHT;
    }, [gameSectionHeight]);

    return { marketHeaderSectionHeight, gameSectionHeight };
};
