import { DotStatus } from '@/components/ProgressDots';
import { GridPeriodScore } from '@/components/scoreboard/types';
import { BaseballInningDetail, EventDetails, Sport } from '@/feature/event-details-sbk/types';
import { AtLeast } from '@/types/utils';
import { getSportKey } from '@/utils/get-sport-key';
import { renderDate } from '@/utils/renderDate';

import { Bet, BetEvent, FULL_PERIOD } from '../hooks/types';

const mapSelectionResultToDotStatus = (result: string | undefined): DotStatus => {
    switch (result) {
        case 'WON':
            return DotStatus.Won;
        case 'LOST':
            return DotStatus.Lost;
        case 'PUSHED':
        case 'CANCELLED':
            return DotStatus.Voided;
        default:
            return DotStatus.Open;
    }
};

export const getEventTitle = (event: AtLeast<BetEvent, 'home_team' | 'away_team' | 'event_details' | 'start_time'>) => {
    const awayTeamDetails = [`${event?.away_team?.short_code ?? ''}`, `${event?.event_details?.away_score ?? ''}`]
        .filter(Boolean)
        .join(' ');
    const homeTeamDetails = [`${event?.home_team?.short_code ?? ''}`, `${event?.event_details?.home_score ?? ''}`]
        .filter(Boolean)
        .join(' ');

    switch (event.status) {
        case 'LIVE':
            return `${awayTeamDetails} @ ${homeTeamDetails}`;
        case 'FINISHED':
            return `${awayTeamDetails} @ ${homeTeamDetails} • Final`;
        // TODO - add case for overtime
        default:
            return `${event.away_team.short_code ?? ''} @ ${event.home_team.short_code ?? ''} • ${renderDate(
                new Date(event.start_time)
            )}`;
    }
};

/**
 * Returns progress indicators for Bet Card and Bet Details screen
 */
export const getProgressDots = (bet: Bet) => {
    const selections = bet.events.map(event => event.selections).flat();
    if (selections.length < 2) {
        return [];
    }

    if (bet.has_been_cashed_out) {
        return selections.map(selection => {
            if (!selection.result) {
                return DotStatus.Voided;
            }
            return mapSelectionResultToDotStatus(selection.result);
        });
    }

    return selections
        .map(selection => {
            return mapSelectionResultToDotStatus(selection.result);
        })
        .sort((a, b) => {
            // sort Open to end of array
            if (a === DotStatus.Open) {
                return 1;
            }
            if (b === DotStatus.Open) {
                return -1;
            }
            return 0;
        });
};

const BREAKDOWN_PERIODS: { [key: string]: FULL_PERIOD[] } = {
    basketball: ['FIRST_PERIOD', 'SECOND_PERIOD', 'THIRD_PERIOD', 'FOURTH_PERIOD'],
    hockey: ['FIRST_PERIOD', 'SECOND_PERIOD', 'THIRD_PERIOD'],
    // TODO: add here sport specific periods (if that's how are they coming for the API)
    football: ['PERIOD_1', 'PERIOD_2', 'PERIOD_3', 'PERIOD_4'],
};

const EMPTY_PERIOD = {
    awayScore: undefined,
    homeScore: undefined,
};

export const getScoreboard = (sport: Sport, eventDetails: EventDetails) => {
    switch (getSportKey(sport)) {
        case 'baseball':
            return eventDetails.sport_specific?.inning_details && eventDetails.sport_specific?.current_inning
                ? mapInningsToScoreGrid(
                      eventDetails.sport_specific?.inning_details,
                      eventDetails.sport_specific?.current_inning
                  )
                : [];
        default:
            return eventDetails?.period_scores?.length ? mapPeriodScoresToScoreGrid(eventDetails) : [];
    }
};

const mapInningsToScoreGrid = (inningDetails: Record<string, BaseballInningDetail>, activeInning: number) => {
    const breakdown = Object.entries(inningDetails).map(([inningNumber, { runs }]) => ({
        periodNumber: inningNumber,
        awayScore: runs?.team_b,
        homeScore: runs?.team_a,
    }));

    const activePeriodIndex = getActivePeriodIndex(breakdown, String(activeInning));
    const baseballInnings = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
    return mapGridAndFillEmptyColumns(breakdown, baseballInnings, activePeriodIndex);
};

export const mapPeriodScoresToScoreGrid = (eventDetails: AtLeast<EventDetails, 'period_scores' | 'period_name'>) => {
    const breakdown =
        eventDetails?.period_scores?.map(({ period_number, away_score, home_score, type }) => ({
            periodNumber: period_number,
            homeScore: home_score,
            awayScore: away_score,
            type,
        })) || [];

    const activePeriod = eventDetails?.period_name;

    const sportBreakdown = BREAKDOWN_PERIODS[breakdown[0]?.type] || BREAKDOWN_PERIODS.basketball;

    // ! we should only show "0" as score only if the period was played or is playing right now
    // ! therefore we find the index of the active period
    const activePeriodIndex = getActivePeriodIndex(breakdown, activePeriod);

    return mapGridAndFillEmptyColumns(breakdown, sportBreakdown, activePeriodIndex);
};

const mapGridAndFillEmptyColumns = (
    breakdown: GridPeriodScore[],
    sportBreakdown: string[],
    activePeriodIndex: number
) => {
    return sportBreakdown.map(period => {
        // ! the period scored (breakdown) should be sorted by the API
        const periodIndex = breakdown.findIndex(({ periodNumber }) => periodNumber === period);

        if (activePeriodIndex === -1) {
            return breakdown[periodIndex];
        }

        // ! check to see if the current period was before the active period
        if (periodIndex <= activePeriodIndex) {
            // ! period was played, so we return it
            return breakdown[periodIndex];
        }
        // ! period was not played, so we override the "score" for both teams so the components render "-"
        return { ...breakdown[periodIndex], ...EMPTY_PERIOD };
    });
};

export const getActivePeriodIndex = (breakdown: GridPeriodScore[], activePeriod?: string) => {
    return breakdown?.findIndex(period => period?.periodNumber === activePeriod);
};

export const getFinalScoreColumns = (eventDetails: EventDetails, sport: Sport) => {
    const { period_scores, away_score: awayScore, home_score: homeScore } = eventDetails;

    const isBaseball = sport.name === 'Baseball';

    const type = period_scores?.[0]?.type || '';

    if (isBaseball) {
        const { team_a = 0, team_b = 0 } = eventDetails.sport_specific?.match_hits ?? {};
        return [
            { awayScore, homeScore, periodNumber: 'R', type },
            { awayScore: team_b, homeScore: team_a, periodNumber: 'H', type },
        ];
    }

    return [{ awayScore, homeScore, periodNumber: 'T', type }];
};
