import React, { ComponentProps, useEffect, useState } from 'react';
import { LayoutRectangle, StyleSheet } from 'react-native';
import Animated, {
    Easing,
    interpolate,
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';

import { LinearGradient } from '@/components/linear-gradient/LinearGradient';
import { designSystem } from '@/styles/styles';

import { Box, Row } from './lib/components';

export const SkeletonLoader: React.FC<LoadingSkeletonProps> = ({
    children,
    animationSpeed,
    positions,
    skeletonColor,
    skeletonGradient,
    ...props
}) => {
    const { width, height } = props;
    const [layout, setLayout] = useState<LayoutRectangle>();
    const translateX = useSharedValue(0);

    useEffect(() => {
        translateX.value = withRepeat(
            withTiming(1, { duration: animationSpeed, easing: Easing.bezier(0.25, 0.1, 0.25, 1) }),
            Infinity
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const animatedStyle = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: interpolate(
                    translateX.value,
                    [0, 1],
                    [width ? -width : layout ? -layout.width : 0, width ? width : layout ? layout.width : 0]
                ),
            },
        ],
    }));

    if (!layout) {
        return <Box onLayout={event => setLayout(event.nativeEvent.layout)}>{children}</Box>;
    }

    const colors = skeletonGradient ? skeletonGradient : ['transparent', skeletonColor, 'transparent'];
    return (
        <Box
            style={{ width: width ? width : layout.width, height: height ? height : layout.height }}
            overflow="hidden"
            backgroundColor="transparent"
            {...props}
        >
            <Animated.View style={[StyleSheet.absoluteFill, animatedStyle]}>
                <LinearGradient
                    style={[StyleSheet.absoluteFill, { height: layout.height, width: layout.width }]}
                    start={{ x: 0, y: 0 }}
                    height={height ?? layout.height}
                    width={width ?? layout.width}
                    end={{ x: 1, y: 0 }}
                    colors={colors}
                    positions={positions}
                />
            </Animated.View>
        </Box>
    );
};

const Loader = ({ children, ...rest }: ComponentProps<typeof Box>) => <Box {...rest}>{children}</Box>;

interface LoadingSkeletonProps extends ComponentProps<typeof Box> {
    children?: React.ReactElement;
    skeletonColor: string;
    width?: number;
    height?: number;
    animationSpeed?: number;
    skeletonGradient?: string[];
    positions?: number[];
}

export const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
    skeletonColor = designSystem.colors.gray6,
    children,
    animationSpeed = 1000,
    skeletonGradient,
    positions = [],
    ...props
}) => {
    return (
        <SkeletonLoader
            skeletonColor={skeletonColor}
            animationSpeed={animationSpeed}
            skeletonGradient={skeletonGradient}
            positions={positions}
            {...props}
        >
            <Loader children={children} {...props} />
        </SkeletonLoader>
    );
};

export const HorizontalListSkeleton: React.FC<LoadingSkeletonProps> = ({
    skeletonColor = designSystem.colors.gray6,
    animationSpeed = 1000,
    skeletonGradient,
    positions = [],
    children,
    ...props
}) => {
    return (
        <Row padding="s16">
            {[...Array(4)].map((_, index) => (
                <SkeletonLoader
                    skeletonColor={skeletonColor}
                    animationSpeed={animationSpeed}
                    skeletonGradient={skeletonGradient}
                    positions={positions}
                    {...props}
                >
                    <Loader key={index} children={children} {...props} />
                </SkeletonLoader>
            ))}
        </Row>
    );
};
