import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { logger } from '@/utils/logging';

const DEFAULT_MINUTES = 15;

export const usePrismicStaleTime = () => {
    const globalPairs = useJurisdictionStore(state => state.jurisdictionSettings?.globalSettings?.keyValuePairs);

    const userSettings = globalPairs?.UserSettings ?? {};
    try {
        const cacheTime = parseInt(userSettings.prismic_cache_minutes ?? DEFAULT_MINUTES, 10);
        return toMillis(cacheTime);
    } catch (e) {
        logger.warn('[Prismic]', e);
        return toMillis(DEFAULT_MINUTES);
    }
};

const toMillis = (min: number) => min * 60 * 1000;
