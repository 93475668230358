import React, { Fragment, useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
    AnimatedStyle,
    Extrapolation,
    interpolate,
    useAnimatedStyle,
    withTiming,
} from 'react-native-reanimated';

import {
    PlayerInfoFragment,
    PlayerInfoWithProjectionsFragment,
    PlayerProjectionFragment,
    TeamInfoFragment,
} from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import Plus from '@/assets/icons/plus';
import SwapIcon from '@/assets/icons/swap';
import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { AppSpacingProps, DesignSystemColor } from '@/components/lib/theme';
import { MarketOption, PlayerCardProps } from '@/feature/betslip-pickem/components/PlayerCard';
import { playerPropsSelector, usePlayerPropsStore } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { ProjectionInvalidReason } from '@/feature/betslip-pickem/utils/betslip-projection-changed';
import { isPlayerSelected } from '@/feature/betslip-pickem/utils/betslip-utils';
import { RoundButton } from '@/feature/lobby/components/PickProjectionButton';
import { useReplacePick } from '@/feature/lobby/components/ReplacePickProvider';
import { common, designSystem } from '@/styles/styles';
import { Outcome } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { getPlayerArcDetails } from '@/utils/get-player-arc-details';

import { PickSelectionMethods } from '../hooks/use-pick-selection';
import { useTileButtonAnimation } from '../hooks/use-tile-buttons-animation';
import { createPropsForPickOut } from '../utils/createPropsForPickOut';
import { GAP_WIDTH, calculateTileButtonWidth } from '../utils/players';
import { PickProjectionRoundButton } from './PickProjectionButton';

type ButtonsSectionProps = {
    selection: Outcome | undefined;
    onSelection: (o: Outcome) => void;
    onRemove: () => void;
    allowedOutcomes: Array<MarketOption>;
    backgroundColor?: DesignSystemColor;
    showButtonsLabels: boolean;
    testID?: string;
};

type PlayerTilePickButtonsProps = {
    selection: Outcome | undefined;
    onSelection: (o: Outcome) => void;
    onRemove: () => void;
    allowedOutcomes: Array<MarketOption>;
    testID?: string;
    selectedProjectionsByValue?: { value: number; projections: PlayerProjectionFragment[] };
    swapProjection?: () => void;
    containerWidth?: number;
};

type GeneralProps = {
    event?: EventInfo;
    presentation?: 'default' | 'sheet';
    pressable?: boolean;
    testID?: string;
    analyticsTag?: string;
    paddingVertical?: AppSpacingProps['paddingVertical'];
} & Partial<PickSelectionMethods>;

type RemoveProps = { showTrash: true; onRemoveFromLineup: () => void } | { showTrash?: false };

type PropsMode =
    | {
          mode: 'selection';
          player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
          projection: PlayerProjectionFragment;
          outcome: Outcome;
          onOutcomeChange?: (outcome: Outcome, projection: PlayerProjectionFragment) => void;
      }
    | {
          mode: 'highlight';
          projection: PlayerProjectionFragment;
          player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
      }
    | {
          mode: 'change';
          player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
          projection: PlayerProjectionFragment;
          newProjection: PlayerProjectionFragment;
          outcome: Outcome;
          onOutcomeChange: (outcome: Outcome, projection: PlayerProjectionFragment) => void;
      }
    | {
          mode: 'all';
          player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
      }
    | { mode: 'display'; player: PlayerInfoFragment }
    | {
          mode: 'unavailable';
          reason: ProjectionInvalidReason;
          player: PlayerInfoFragment;
          projection: PlayerProjectionFragment;
      }
    | {
          mode: 'disable-changes';
          player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
          projection: PlayerProjectionFragment;
          newProjection: PlayerProjectionFragment;
      };

export type RowProps = GeneralProps & PropsMode & RemoveProps;

/*
 * This component is responsible for rendering the buttons for picking a player's projection.
 * It also contains the logic for handling the selection of a player's projection.
 * */
export const PickButtonsWithFunctionality = (
    props: RowProps & {
        leagueIcon?: string;
        leagueColor?: string;
        selectedProjection?: PlayerProjectionFragment;
        viewMode?: 'row' | 'tile';
        selectedProjectionsByValue?: { value: number; projections: PlayerProjectionFragment[] };
        swapProjection?: () => void;
        containerWidth?: number;
    }
) => {
    const {
        player,
        mode,
        event,
        showTrash,
        presentation = 'default',
        testID,
        openPlayerPickModal,
        makeSelection,
        removeSelection,
        leagueIcon,
        leagueColor,
        selectedProjection,
        viewMode = 'row',
        selectedProjectionsByValue,
        swapProjection,
    } = props;
    const team = 'team' in player ? player.team : undefined;
    const playerProjections = usePlayerPropsStore(playerPropsSelector(player.id), defaultZustandCompareFunction);
    const propsForPickOut: PlayerCardProps | undefined = createPropsForPickOut({
        event,
        playerProjections,
        player,
        team,
        analyticsTag: props.analyticsTag,
    });
    const onOutcomeChange = mode === 'change' || mode === 'selection' ? props.onOutcomeChange : undefined;

    const { showReplacePickModal } = useReplacePick();
    const playerPickedEntry = useMemo(
        () => isPlayerSelected({ eventId: event?.id, playerId: player && player.id }),
        [event?.id, player]
    );
    const isSamePlayer = playerPickedEntry?.player.id === player.id;
    const isThisProjectionPicked =
        playerPickedEntry?.projection.name === selectedProjection?.name &&
        playerPickedEntry?.projection.type === selectedProjection?.type;
    const shouldShowReplacePickModal = playerPickedEntry && isSamePlayer && !isThisProjectionPicked;
    const { arcText, teamLogo } = getPlayerArcDetails(player, event?.league, team);

    const isDefaultPresentation = presentation === 'default';
    const buttonColor = isDefaultPresentation ? 'gray6' : 'gray5';

    const openPlayerCard = () => {
        if (propsForPickOut && openPlayerPickModal) {
            openPlayerPickModal(propsForPickOut);
        }
    };

    const onProjectionSelection = (selectedOutcome: Outcome) => {
        if (shouldShowReplacePickModal) {
            if (selectedOutcome && propsForPickOut && selectedProjection && playerProjections && makeSelection) {
                showReplacePickModal({
                    currentPick: playerPickedEntry,
                    newPick: { projection: selectedProjection, outcome: selectedOutcome },
                    player: { ...player, projections: playerProjections },
                    teamIcon: teamLogo || leagueIcon,
                    teamColor: leagueColor || team?.color,
                    arcText,
                    playerNumber: getPlayerJerseyNumber(event?.league, player?.jerseyNumber),
                    onReplacePick: () =>
                        event &&
                        makeSelection(
                            {
                                player: propsForPickOut.player,
                                eventId: event.id,
                                projection: selectedProjection,
                                outcome: selectedOutcome,
                            },
                            props.analyticsTag
                        ),
                });
            }
        } else if (onOutcomeChange && selectedProjection) {
            onOutcomeChange(selectedOutcome, selectedProjection);
        } else if (propsForPickOut && selectedProjection && makeSelection) {
            event &&
                makeSelection(
                    {
                        player: propsForPickOut.player,
                        eventId: event.id,
                        projection: selectedProjection,
                        outcome: selectedOutcome,
                    },
                    props.analyticsTag
                );
        }
    };

    const onRemove = () => {
        if (mode === 'change') {
            // when displaying the row in change mode, we don't want to remove the player from the lineup
            // if the user presses the same outcome button
            return;
        }

        if (event) {
            if (showTrash) {
                props.onRemoveFromLineup();
            } else {
                if (playerProjections && removeSelection) {
                    removeSelection([
                        {
                            eventId: event.id,
                            player: {
                                ...player,
                                league: event.league,
                                sport: event.sport,
                                projections: playerProjections,
                            },
                        },
                    ]);
                }
            }
        }
    };

    const outcomeSelection = mode === 'change' || mode === 'selection' ? props.outcome : undefined;

    return selectedProjection ? (
        viewMode === 'row' ? (
            <PlayerRowPickButtons
                selection={outcomeSelection}
                allowedOutcomes={selectedProjection.allowedOptions ?? []}
                onSelection={onProjectionSelection}
                onRemove={onRemove}
                backgroundColor={buttonColor}
                showButtonsLabels={isDefaultPresentation && !showTrash}
                testID={testID}
            />
        ) : (
            <PlayerTilePickButtons
                selection={outcomeSelection}
                allowedOutcomes={selectedProjection.allowedOptions ?? []}
                onSelection={onProjectionSelection}
                onRemove={onRemove}
                testID={testID}
                selectedProjectionsByValue={selectedProjectionsByValue}
                swapProjection={swapProjection}
                containerWidth={props.containerWidth}
            />
        )
    ) : (
        <Box>
            <RoundButton
                label={<Plus />}
                analyticsTag={'expandPlayerCard'}
                backgroundColor={buttonColor}
                onPress={openPlayerCard}
            />
        </Box>
    );
};

/**
 * PlayerRowPickButtons renders both More & Less buttons, used for picking a player's projection
 */
export const PlayerRowPickButtons = ({
    onSelection,
    onRemove,
    allowedOutcomes,
    selection,
    backgroundColor,
    showButtonsLabels,
    testID,
}: ButtonsSectionProps) => {
    const onPress = (outcome: Outcome) => {
        if (outcome === selection) {
            onRemove();
        } else {
            onSelection(outcome);
        }
    };
    return (
        <Box style={common.spaceBetweenRow}>
            {allowedOutcomes?.map((marketOption: MarketOption, index: number) => {
                const spaceBetween = allowedOutcomes.length === 2 && index === 0 ? 12 : 0;
                const outcome = marketOption.outcome;
                return (
                    <Fragment key={`pick-${index}`}>
                        <Box>
                            <PickProjectionRoundButton
                                outcome={outcome}
                                isSelected={selection === outcome}
                                onPress={() => onPress(outcome)}
                                key={`pick-${outcome}`}
                                backgroundColor={backgroundColor}
                                testID={testID}
                            />
                            {showButtonsLabels ? (
                                <Text
                                    variant={'bodySmall'}
                                    color={'gray2'}
                                    textAlign={'center'}
                                    textTransform="capitalize"
                                    mt={'s8'}
                                >
                                    {outcome}
                                </Text>
                            ) : null}
                        </Box>

                        <SizedBox value={spaceBetween} />
                    </Fragment>
                );
            })}
        </Box>
    );
};

type AnimatedButtonProps = {
    outcome: Outcome;
    isSelected: boolean;
    isSwapButton: boolean;
    buttonStyle: AnimatedStyle;
    textStyle: AnimatedStyle;
    onPress: (() => void) | undefined;
    testID?: string;
    customStyle?: { flex: number };
};

export const AnimatedButton = ({
    outcome,
    isSelected,
    isSwapButton,
    buttonStyle,
    textStyle,
    onPress,
    testID,
    customStyle,
}: AnimatedButtonProps) => {
    const backgroundColor = isSelected ? 'white' : designSystem.colors.gray6;
    const color = isSelected ? designSystem.colors.gray8 : 'white';

    return (
        <Animated.View style={[styles.buttonContainer, buttonStyle, customStyle]}>
            <Button
                onPress={onPress}
                size="s"
                shape="rect"
                label={
                    isSwapButton ? (
                        <Box paddingVertical={'s2'}>
                            <SwapIcon />
                        </Box>
                    ) : (
                        <Animated.Text style={[{ color }, textStyle, styles.buttonText]}>
                            {outcome === Outcome.More ? 'More' : 'Less'}
                        </Animated.Text>
                    )
                }
                style={[styles.squareButton, { backgroundColor }]}
                testID={testID}
            />
        </Animated.View>
    );
};

export const PlayerTilePickButtons = ({
    onSelection,
    onRemove,
    allowedOutcomes,
    selection,
    testID,
    swapProjection,
    selectedProjectionsByValue,
    containerWidth,
}: PlayerTilePickButtonsProps) => {
    const onPress = (outcome: Outcome) => {
        if (outcome === selection) {
            onRemove();
        } else {
            onSelection(outcome);
        }
    };

    let halfWidthPercent = 50;
    if (containerWidth) {
        const containerWithoutGap = containerWidth - GAP_WIDTH;
        halfWidthPercent = ((containerWithoutGap / 2) * 100) / containerWidth;
    }

    const hasMore = allowedOutcomes.some(opt => opt.outcome === Outcome.More);
    const hasLess = allowedOutcomes.some(opt => opt.outcome === Outcome.Less);

    const shouldShowSwapButtonInsteadOfMore =
        allowedOutcomes.length === 1 &&
        selectedProjectionsByValue &&
        selectedProjectionsByValue.projections.length > 1 &&
        allowedOutcomes[0].outcome === Outcome.Less;

    const shouldShowSwapButtonInsteadOfLess =
        allowedOutcomes.length === 1 &&
        selectedProjectionsByValue &&
        selectedProjectionsByValue.projections.length > 1 &&
        allowedOutcomes[0].outcome === Outcome.More;

    const targetMoreWidth = useMemo(() => {
        return calculateTileButtonWidth(
            hasMore,
            hasLess,
            halfWidthPercent,
            shouldShowSwapButtonInsteadOfMore,
            shouldShowSwapButtonInsteadOfLess,
            containerWidth
        );
    }, [
        containerWidth,
        hasMore,
        hasLess,
        halfWidthPercent,
        shouldShowSwapButtonInsteadOfMore,
        shouldShowSwapButtonInsteadOfLess,
    ]);
    const targetLessWidth = useMemo(() => {
        return calculateTileButtonWidth(
            hasLess,
            hasMore,
            halfWidthPercent,
            shouldShowSwapButtonInsteadOfLess,
            shouldShowSwapButtonInsteadOfMore,
            containerWidth
        );
    }, [
        containerWidth,
        hasLess,
        hasMore,
        halfWidthPercent,
        shouldShowSwapButtonInsteadOfLess,
        shouldShowSwapButtonInsteadOfMore,
    ]);

    const {
        buttonWidth: moreButtonWidth,
        buttonStyle: moreButtonStyle,
        textStyle: moreTextStyle,
    } = useTileButtonAnimation(targetMoreWidth);

    const {
        buttonWidth: lessButtonWidth,
        buttonStyle: lessButtonStyle,
        textStyle: lessTextStyle,
    } = useTileButtonAnimation(targetLessWidth);

    useEffect(() => {
        const animationConfig = { duration: 200 };
        moreButtonWidth.value = withTiming(targetMoreWidth, animationConfig);
        lessButtonWidth.value = withTiming(targetLessWidth, animationConfig);
    }, [
        allowedOutcomes,
        containerWidth,
        halfWidthPercent,
        hasLess,
        hasMore,
        lessButtonWidth,
        moreButtonWidth,
        shouldShowSwapButtonInsteadOfLess,
        shouldShowSwapButtonInsteadOfMore,
        targetLessWidth,
        targetMoreWidth,
    ]);

    const gapStyle = useAnimatedStyle(() => ({
        gap: interpolate(
            Math.min(moreButtonWidth.value, lessButtonWidth.value),
            [0, halfWidthPercent / 2],
            [0, GAP_WIDTH],
            Extrapolation.CLAMP
        ),
    }));

    return (
        <Box>
            <AnimatedBox style={gapStyle} alignItems={'center'} flexDirection={'row'}>
                <AnimatedButton
                    outcome={Outcome.More}
                    isSelected={selection === Outcome.More}
                    isSwapButton={shouldShowSwapButtonInsteadOfMore || false}
                    buttonStyle={moreButtonStyle}
                    textStyle={moreTextStyle}
                    onPress={shouldShowSwapButtonInsteadOfMore ? swapProjection : () => onPress(Outcome.More)}
                    testID={testID}
                    customStyle={common.flex}
                />
                <AnimatedButton
                    outcome={Outcome.Less}
                    isSelected={selection === Outcome.Less}
                    isSwapButton={shouldShowSwapButtonInsteadOfLess || false}
                    buttonStyle={lessButtonStyle}
                    textStyle={lessTextStyle}
                    onPress={shouldShowSwapButtonInsteadOfLess ? swapProjection : () => onPress(Outcome.Less)}
                    testID={testID}
                />
            </AnimatedBox>
        </Box>
    );
};

const styles = StyleSheet.create({
    squareButton: {
        paddingHorizontal: 12,
        width: '100%',
    },
    buttonContainer: {
        height: 36,
        overflow: 'hidden',
    },
    buttonText: {
        fontSize: 13,
        lineHeight: 20,
        fontWeight: '600',
    },
});

const AnimatedBox = Animated.createAnimatedComponent(Box);
