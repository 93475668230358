import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={56} height={56} fill="none" {...props}>
        <Path
            fill="#FFFFFF"
            fillRule="evenodd"
            d="M56 28c0 15.464-12.536 28-28 28S0 43.464 0 28 12.536 0 28 0s28 12.536 28 28Zm-2.5 0c0 14.083-11.417 25.5-25.5 25.5S2.5 42.083 2.5 28 13.917 2.5 28 2.5 53.5 13.917 53.5 28Z"
            clipRule="evenodd"
        />
        <Path
            fill="#FFFFFF"
            d="M20.768 19 19 20.768l7.602 7.601L19 35.971l1.768 1.767 7.601-7.601 7.602 7.601 1.767-1.768-7.6-7.6 7.6-7.602L35.971 19l-7.602 7.601L20.768 19Z"
        />
    </Svg>
);
export default SvgComponent;
