import React from 'react';
import { RefreshControl } from 'react-native-gesture-handler';

import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { PendingLimits } from '@/feature/responsible-gaming/components/PendingLimits';
import { commonStyles } from '@/feature/responsible-gaming/screens/styles';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { designSystem } from '@/styles/styles';

import { UserLimitType } from '../hooks/types';
import { hasConfirmationLimitsSelector, useUserLimitConfirmations } from '../hooks/use-user-limit-confirmations';
import { hasPendingLimitsSelector, useUserLimits } from '../hooks/use-user-limits';

type LimitsScreenProps = {
    type: UserLimitType;
    title: string;
    content: string;
    subTitle: string;
    links: React.ReactElement;
    example?: string;
    testID?: string;
};

export const LimitLobby = ({ type, title, content, subTitle, links, example, testID }: LimitsScreenProps) => {
    const {
        data: hasConfirmationLimits,
        refetch: refetchConfirmations,
        isRefetching: isRefetchingConfirmations,
    } = useUserLimitConfirmations({
        select: hasConfirmationLimitsSelector(type),
    });
    const {
        data: hasPendingLimits,
        refetch: refetchLimits,
        isRefetching: isRefetchingLimits,
    } = useUserLimits({
        select: hasPendingLimitsSelector(type),
    });

    const showPendingLimits = hasConfirmationLimits || hasPendingLimits;

    const refetch = () => {
        refetchConfirmations();
        refetchLimits();
    };

    //  Make the words 'sportsbook' and 'fantasy' bold
    const renderContent = content.split(' ').map((word, i) => {
        if (['sportsbook', 'fantasy'].includes(word.toLocaleLowerCase())) {
            return (
                <Text key={`${word}-${i}`} fontWeight="bold" variant="bodySmall" color={'gray2'}>
                    {word}{' '}
                </Text>
            );
        }
        return word + ' ';
    });

    const refreshControl = (
        <RefreshControl
            enabled
            colors={[designSystem.colors.white]}
            tintColor={designSystem.colors.white}
            refreshing={isRefetchingConfirmations || isRefetchingLimits}
            onRefresh={refetch}
        />
    );

    return (
        <ScrollableScreen
            screenNavBarProps={{
                title,
                handlePressTracking: () => {
                    BetrAnalytics.trackProductEvent(AnalyticsEvent.LIMITS_ABORT, { type });
                },
            }}
            scrollViewProps={{
                showsVerticalScrollIndicator: false,
                refreshControl,
                style: [commonStyles.scrollView, { paddingHorizontal: 0 }],
            }}
        >
            <MaxWidthWrapper paddingHorizontal={'s16'} paddingBottom={'s20'}>
                <Text variant="bodySmall" color={'gray2'} testID={testID}>
                    {renderContent}
                </Text>
                {example ? (
                    <>
                        <SizedBox value={16} />
                        <Text variant="bodySmall" color={'gray2'}>
                            {example}
                        </Text>
                    </>
                ) : null}
                <SizedBox value={28} />
                {showPendingLimits ? <PendingLimits type={type} /> : null}
                <Text variant="headlineMedium">{subTitle}</Text>
                <SizedBox value={12} />
                {links}
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
