import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg
        width={props.width ?? 22}
        height={props.height ?? 22}
        fill="none"
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Circle
            cx={11}
            cy={11}
            r={9.75}
            transform="rotate(90 11 11)"
            stroke={props.color ?? '#959AA6'}
            strokeWidth={2.5}
        />
        <Path d="M12.25 10v6h-2.5v-6h2.5ZM12.25 6v2.5h-2.5V6h2.5Z" fill={props.color ?? '#959AA6'} />
    </Svg>
);

export default SvgComponent;
