import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={100} height={32} viewBox="0 0 100 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <G clipPath="url(#clip0_1133_13950)">
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M77.827 3.445a1.492 1.492 0 00-1.211 1.247l-2.458 16.544a.744.744 0 00.866.842l5.145-.908a.744.744 0 01.845.947l-2.658 8.92a.745.745 0 001.23.751l19.52-18.612a.745.745 0 00-.645-1.271l-4.93.868a.745.745 0 01-.797-1.068L98.128 1.08a.745.745 0 00-.8-1.081L77.828 3.445zm8.439 10.136c.908.36 1.462.588 1.265-.532a3.727 3.727 0 00-4.32-3.027 3.73 3.73 0 00-3.026 4.32c.2 1.12.653.71 1.37.069.567-.509 1.3-1.165 2.195-1.323.896-.157 1.798.209 2.516.494z"
                fill="#9F0CCC"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.773 29.463a8.175 8.175 0 002.319.303 8.716 8.716 0 003.529-.732 9.25 9.25 0 002.918-2.12 10.119 10.119 0 002.019-3.238c.525-1.287.789-2.666.775-4.056.013-1.39-.25-2.77-.775-4.059a9.655 9.655 0 00-2.02-3.193 8.475 8.475 0 00-2.917-2.12 8.28 8.28 0 00-3.52-.774 6.356 6.356 0 00-2.192.345 11.75 11.75 0 00-1.589.648c-.488.27-.949.587-1.374.947v-9.06H0v26.964h3.65l.857-1.71c.458.421.966.784 1.513 1.08a9.262 9.262 0 001.753.776zm-1.846-5.911c-.945-.98-1.418-2.292-1.42-3.935-.002-1.643.471-2.954 1.42-3.935a4.695 4.695 0 013.52-1.456 4.7 4.7 0 013.52 1.468c.944.977 1.417 2.289 1.417 3.935 0 1.647-.473 2.958-1.417 3.935a4.698 4.698 0 01-3.52 1.465 4.69 4.69 0 01-3.52-1.477z"
                fill="#fff"
            />
            <Path
                d="M48.318 5.052h-4.259v2.56c0 1.575-.78 2.363-2.34 2.365h-1.277v4.062h2.978v8.635a8.539 8.539 0 00.47 2.924 7.393 7.393 0 001.362 2.25 6.317 6.317 0 002.085 1.461c.857.32 1.766.48 2.682.472a5.777 5.777 0 001.662-.215c.233-.05.461-.121.68-.214v-4.314c-.108.055-.223.099-.341.13-.112.028-.24.058-.382.085h-.554a2.839 2.839 0 01-2.049-.735 2.897 2.897 0 01-.723-2.065V14.04h4.256V9.547h-4.25V5.052zM64.251 9.365a3.579 3.579 0 00-.605-.042 5.079 5.079 0 00-2.028.39c-.526.203-1.02.481-1.465.827-.471.33-.893.725-1.253 1.174l-.863-2.176h-3.665v20.004h4.955V18.888c0-1.304.373-2.347 1.12-3.13a3.933 3.933 0 012.978-1.21c.23-.004.461.01.69.041.23.028.418.058.56.088.142.03.33.058.475.085V9.538l-.433-.085c-.136-.03-.293-.07-.466-.088z"
                fill="#fff"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.35 23.528a8.477 8.477 0 01-1.212 2.237 9.15 9.15 0 01-1.93 2.022 10.087 10.087 0 01-2.577 1.42 8.299 8.299 0 01-3.09.56 9.56 9.56 0 01-3.823-.776 10.763 10.763 0 01-3.133-2.194 11.073 11.073 0 01-2.143-3.188 10.188 10.188 0 01-.772-3.959 9.858 9.858 0 01.772-3.91 10.95 10.95 0 012.15-3.23 10.13 10.13 0 013.132-2.149 9.413 9.413 0 017.48-.07 9.03 9.03 0 013.005 2.068 9.475 9.475 0 012.02 3.226c.526 1.297.789 2.687.771 4.087.001.23-.013.459-.042.687l-.085.605c-.03.23-.058.445-.088.648H26.031c.216.95.727 1.808 1.459 2.452.745.66 1.762.99 3.051.99a5.272 5.272 0 001.631-.216 4.982 4.982 0 001.16-.517 3.73 3.73 0 00.871-.793h5.146zm-8.803-9.25a4.564 4.564 0 00-3.178 1.12 5.096 5.096 0 00-1.547 2.763h9.447a4.994 4.994 0 00-1.59-2.751 4.41 4.41 0 00-3.132-1.132z"
                fill="#fff"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_1133_13950">
                <Path fill="#fff" d="M0 0H99.3235V32H0z" />
            </ClipPath>
        </Defs>
    </Svg>
);

export default SvgComponent;
