import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionItem } from '@/components/ActionItem';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { LIMIT_DURATION, USER_LIMIT_TYPE } from '../constants';
import { UserLimit, UserLimitDuration, UserLimitType } from '../hooks/types';
import { useUserLimits } from '../hooks/use-user-limits';

type LimitLobbyLinksProps = {
    type: UserLimitType;
    handlePress: (label: string, duration: UserLimitDuration, limit?: UserLimit) => void;
};

const DURATIONS: UserLimitDuration[] = ['DAY', 'WEEK', 'MONTH'];

export const LimitLobbyLinks = ({ type, handlePress }: LimitLobbyLinksProps) => {
    const { data: userLimits } = useUserLimits();
    const { t } = useTranslation(['rg', 'common']);
    return (
        <>
            {DURATIONS.map(duration => {
                const limit = userLimits?.find(userLimit => userLimit.type === type && userLimit.duration === duration);

                const isLimitSet = limit && limit.amount > 0;
                const label = t('duration_limit', { duration: LIMIT_DURATION[duration] });
                const buttonLabel = isLimitSet ? t('common:edit') : t('common:add');
                return (
                    <ActionItem
                        key={duration}
                        label={label}
                        buttonLabel={buttonLabel}
                        secondaryLabel={isLimitSet ? toLocaleCurrency(limit.amount) : ''}
                        handlePress={() =>
                            handlePress(
                                `${LIMIT_DURATION[duration]} ${USER_LIMIT_TYPE?.[type]} Limit ${buttonLabel}`,
                                duration,
                                limit
                            )
                        }
                        testID={`editLimitBtn-${duration}`}
                    />
                );
            })}
        </>
    );
};
