import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { Text } from '@/components/TextComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { AccountTabNavigationProp } from '@/navigation/types';

const RGResourcesLink = 'RGResources'; // Link Type for Responsible Gaming Resources Link

export const FindMoreResourcesRgr = () => {
    const { t } = useTranslation(['account']);
    const { navigate } = useNavigation<AccountTabNavigationProp>();
    const accountLinks = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.screenLinks) || [];

    const onFindMoreResources = () => {
        const rgResourcesLink = accountLinks.find(link => link.link_type === RGResourcesLink);
        BetrAnalytics.trackProductEvent(AnalyticsEvent.RESPONSIBLE_GAMING);
        if (rgResourcesLink?.url) {
            trackRG.gamingLimitScreen(rgResourcesLink.label ?? '');
            navigate('ModalWebView', { uri: rgResourcesLink.url });
        }
    };

    return (
        <Text variant="bodySmall" color="gray2" marginTop="s24">
            {t('account:find_more_resources_in')}{' '}
            <Text variant="bodySmall" fontWeight="500" textDecorationLine="underline" onPress={onFindMoreResources}>
                {t('account:responsible_gaming_resources')}
            </Text>
        </Text>
    );
};
