import React, { useCallback, useEffect, useMemo } from 'react';

import { CONSTANTS } from '@/data';
import { useIsNativeSportsbook } from '@/hooks/use-is-native-sportsbook';
import { useUser } from '@/hooks/use-user';

import { AblyService } from './ably';

type WebsocketContextType = {
    websocketService: AblyService | undefined;
    connected: boolean;
    authenticated: boolean;
};

const WebsocketContext = React.createContext<WebsocketContextType>({
    websocketService: undefined,
    connected: false,
    authenticated: false,
});

export const WebsocketProvider = ({ children }: { children: React.ReactNode }) => {
    const [connected, setConnected] = React.useState(false);
    const [authenticated, setAuthenticated] = React.useState(false);

    const onConnected = useCallback(() => {
        setConnected(true);
    }, [setConnected]);

    const onDisconnected = useCallback(() => {
        setConnected(false);
    }, [setConnected]);

    const onAuthenticated = useCallback(() => {
        setAuthenticated(true);
    }, [setAuthenticated]);

    const websocketService = useMemo(() => new AblyService(CONSTANTS.ABLY_API_KEY, __DEV__), []);
    const isNativeSportsbook = useIsNativeSportsbook();

    useEffect(() => {
        if (!websocketService) {
            return;
        }
        websocketService.addListener('connected', onConnected);
        websocketService.addListener('disconnected', onDisconnected);
        websocketService.addListener('closed', onDisconnected);
        websocketService.initializeListeners();
        return () => {
            websocketService?.removeListener('connected', onConnected);
            websocketService?.removeListener('disconnected', onDisconnected);
            websocketService?.removeListener('closed', onDisconnected);
        };
    }, [onConnected, onDisconnected, websocketService]);

    const { session } = useUser();
    useEffect(() => {
        if (isNativeSportsbook) {
            if (session?.access_token && !authenticated) {
                websocketService?.authenticate(session?.access_token, onAuthenticated);
            }
            if (!session?.access_token && connected) {
                setAuthenticated(false);
            }
        }
    }, [
        connected,
        onConnected,
        onDisconnected,
        session?.access_token,
        websocketService,
        onAuthenticated,
        authenticated,
        isNativeSportsbook,
    ]);

    return (
        <WebsocketContext.Provider value={{ websocketService, connected, authenticated }}>
            {children}
        </WebsocketContext.Provider>
    );
};

export const useWebsocket = () => {
    const context = React.useContext(WebsocketContext);
    if (context === undefined) {
        throw new Error('useWebsocket must be used within a WebsocketProvider');
    }
    return context;
};
