import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import ChevronRight from '@/assets/icons/chevronRight';
import { designSystem } from '@/styles/styles';

export type ArrowButtonProps = { direction: 'right' | 'left'; onPress: () => void };

export const ArrowButton = ({ direction, onPress }: ArrowButtonProps) => (
    <TouchableOpacity
        onPress={onPress}
        style={[styles.arrowButton, direction === 'left' ? styles.leftArrow : styles.rightArrow]}
    >
        <ChevronRight color={designSystem.colors.white} style={direction === 'left' ? styles.transformLeft : null} />
    </TouchableOpacity>
);

const styles = StyleSheet.create({
    transformLeft: {
        transform: [{ rotate: '-180deg' }],
    },
    arrowButton: {
        backgroundColor: designSystem.colors.gray8,
        padding: 8,
        borderRadius: 20,
    },
    leftArrow: {
        position: 'absolute',
        left: 10,
        zIndex: 1,
    },
    rightArrow: {
        position: 'absolute',
        right: 10,
        zIndex: 1,
    },
});
