import React, { memo, useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { useLiveEventsPropsUpdate } from '@/feature/betslip-pickem/hooks/use-live-props-update';
import { PlayerWithTeamAndEvent } from '@/feature/betslip-pickem/types';
import { isPlayerSelected } from '@/feature/betslip-pickem/utils/betslip-utils';
import { common } from '@/styles/styles';
import { FlashList } from '@shopify/flash-list';

import { useLobbyPlayers } from '../hooks/use-lobby-players';
import { PickSelectionMethods, usePickSelection } from '../hooks/use-pick-selection';
import { PlayerRow } from './PlayerRow';
import { PlayerRowSkeleton } from './Skeletons/PlayerRowSkeleton';

export const PLAYER_ROW_ESTIMATED_HEIGHT = 100;

const extractKey = (element: PlayerWithTeamAndEvent, index: number) =>
    `${element?.event?.id || index}-${element?.id || index}`;

export const TrendingPlayers = memo(({ title }: { title: string }) => {
    const {
        trendingPlayers: trending,
        trendingPlayersEvents,
        hasNoData,
        fetching,
    } = useLobbyPlayers({
        // we use `cache-first` since this call is already made in the parent component (Lobby)
        requestPolicy: 'cache-first',
    });

    const betslip = useBetslipStore(state => state.betslip);
    const validating = useBetslipStore(state => state.validating);

    const { openPlayerPickModal, makeSelection, removeSelection } = usePickSelection();

    const trendingPlayersData = useMemo(() => (fetching ? new Array(5) : trending), [fetching, trending]);

    useLiveEventsPropsUpdate({ events: trendingPlayersEvents });

    const renderItem = useCallback(
        ({ item, index }: { item: PlayerWithTeamAndEvent; index: number }) => {
            return (
                <MemoPlayerRow
                    fetching={fetching}
                    player={item}
                    makeSelection={makeSelection}
                    openPlayerPickModal={openPlayerPickModal}
                    removeSelection={removeSelection}
                    index={index}
                    isLastItem={index === trendingPlayersData.length}
                />
            );
        },
        [fetching, makeSelection, openPlayerPickModal, removeSelection, trendingPlayersData.length]
    );

    if (!fetching && hasNoData) {
        return null;
    }

    return (
        <Box paddingBottom={'s16'} paddingHorizontal={'s16'}>
            <Text variant="headlineMedium" style={styles.title}>
                {title}
            </Text>
            <SizedBox value={8} />
            <FlashList
                estimatedItemSize={PLAYER_ROW_ESTIMATED_HEIGHT}
                extraData={JSON.stringify({ ...betslip, validating })}
                showsHorizontalScrollIndicator={false}
                data={trendingPlayersData}
                keyExtractor={extractKey}
                renderItem={renderItem}
                scrollEnabled={false}
            />
        </Box>
    );
});

const MemoPlayerRow = memo(
    ({
        index,
        isLastItem,
        fetching,
        player,
        removeSelection,
        makeSelection,
        openPlayerPickModal,
    }: {
        player?: PlayerWithTeamAndEvent;
        index: number;
        fetching: boolean;
        isLastItem: boolean;
    } & PickSelectionMethods) => {
        if (fetching || !player) {
            return <PlayerRowSkeleton showSeparator={isLastItem} />;
        }

        const playerPickedEntry = isPlayerSelected({
            eventId: player?.event.id,
            playerId: player?.id,
        });

        return (
            <Box testID={`trendingPlayer-${index}`}>
                {playerPickedEntry ? (
                    <PlayerRow
                        player={player}
                        mode="selection"
                        outcome={playerPickedEntry.outcome}
                        projection={playerPickedEntry.projection}
                        event={player.event}
                        pressable={true}
                        analyticsTag={'trendingPlayers'}
                        removeSelection={removeSelection}
                        makeSelection={makeSelection}
                        openPlayerPickModal={openPlayerPickModal}
                    />
                ) : (
                    <PlayerRow
                        player={player}
                        mode="all"
                        event={player.event}
                        pressable={true}
                        analyticsTag={'trendingPlayers'}
                        testID={`${index}`}
                        removeSelection={removeSelection}
                        makeSelection={makeSelection}
                        openPlayerPickModal={openPlayerPickModal}
                    />
                )}
                {!isLastItem ? <LineSeparator style={common.separator} /> : null}
            </Box>
        );
    }
);

const styles = StyleSheet.create({
    title: {
        fontSize: 22,
    },
});
