import { useCallback } from 'react';

export function useRateApp() {
    /**
     * Shows the in-app rate dialog after the user has placed the min. required entries,
     * and with a random chance based on the percentage configured in Prismic.
     */
    const maybeShowAppRateAfterEntryPlaced = useCallback(() => {
        //TODO:web maybe... rate app on TrustPilot :P
    }, []);

    return { maybeShowAppRateAfterEntryPlaced };
}
