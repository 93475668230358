import React from 'react';
import { FlatList } from 'react-native';

import { common } from '@/styles/styles';

import { Bet } from '../hooks/types';
import { EventGroupingItem } from './EventGroupingItem';

type EventGroupingProps = {
    bet: Bet;
};

export const EventGrouping = ({ bet }: EventGroupingProps) => {
    const { events, status, has_been_cashed_out } = bet;

    return (
        <FlatList
            scrollEnabled={false}
            data={events}
            keyExtractor={item => item.id}
            renderItem={({ item: event, index }) => (
                <EventGroupingItem
                    key={event.id}
                    event={event}
                    isLastEvent={index === events.length - 1}
                    betStatus={status}
                    isCashedOut={has_been_cashed_out}
                />
            )}
            style={[common.full]}
        />
    );
};
