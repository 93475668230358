import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={77} height={76} fill="none" {...props}>
        <Rect width={76} height={76} x={0.5} fill="#1E1F24" rx={38} />
        <Path fill="#EDEFF3" fillRule="evenodd" d="M41.5 20h-6l1 26h4l1-26Zm0 30h-6v6h6v-6Z" clipRule="evenodd" />
    </Svg>
);
export default SvgComponent;
