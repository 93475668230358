import React, { useCallback, useEffect, useMemo } from 'react';
import { FlatList, ScrollView, StyleSheet } from 'react-native';

import { useFocusEffect } from '@react-navigation/native';

import {
    PlayerInfoWithProjectionsFragment,
    PlayerProjectionFragment,
    useEventsInfoQuery,
} from '@/api/events/query.generated';
import { EventInfoWithoutPlayers } from '@/api/events/types/types';
import { ChipButton } from '@/components/ChipButton';
import { SizedBox as SizeBoxComponent } from '@/components/SizedBox';
import { eventUtils } from '@/feature/betslip-pickem/utils/event-utils';
import { usePlayerFiltersStore } from '@/feature/lobby/hooks/use-player-filters';
import { common } from '@/styles/styles';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

const SizedBox = () => <SizeBoxComponent value={8} />;

export const PlayerProjectionsFilters = ({ events }: { events: EventInfoWithoutPlayers[] }) => {
    const [{ data }, fetchData] = useEventsInfoQuery({
        variables: { ids: events.map(it => it.id) },
        requestPolicy: 'cache-and-network',
        pause: true,
    });

    useFocusEffect(
        useCallback(() => {
            fetchData();
        }, [fetchData])
    );

    const allEvents = useMemo(() => {
        return data?.getEventsByIdsV2 ?? [];
    }, [data]);

    const allUniqueProjections = useMemo(() => {
        const allPlayers = allEvents?.reduce((acc: PlayerInfoWithProjectionsFragment[], event) => {
            return [...acc, ...eventUtils.getAllPlayers(event)];
        }, []);

        const allProjections = allPlayers.reduce(
            (acc: PlayerProjectionFragment[], player: PlayerInfoWithProjectionsFragment) => [
                ...acc,
                ...player.projections,
            ],
            []
        );
        const uniqueProjections = [...new Map(allProjections.map(item => [item.key, item])).values()];

        return uniqueProjections.sort((proj1, proj2) => proj1.order - proj2.order);
    }, [allEvents]);

    const playerProjectionFilter = usePlayerFiltersStore(state => state.playerProjectionFilter);
    const setPlayerProjectionFilter = usePlayerFiltersStore(state => state.actions.setPlayerProjectionFilter);

    useEffect(() => {
        const projectionsKeys = allUniqueProjections.map(obj => obj?.key);

        if (
            allUniqueProjections.length > 0 &&
            (playerProjectionFilter === undefined || !projectionsKeys.includes(playerProjectionFilter))
        ) {
            setPlayerProjectionFilter(allUniqueProjections[0].key ?? '', allUniqueProjections[0].label ?? '');
        }
    }, [allUniqueProjections, playerProjectionFilter, setPlayerProjectionFilter]);

    const renderItem = useCallback(
        ({ item }: { item: PlayerProjectionFragment }) => {
            return (
                <ChipButton
                    key={item.label}
                    label={item.label ?? ''}
                    active={playerProjectionFilter === item.key}
                    onPress={() => {
                        setPlayerProjectionFilter(item.key ?? '', item.label ?? '');
                    }}
                    testID={`projectionsChipButton-${item.key}`}
                />
            );
        },
        [playerProjectionFilter, setPlayerProjectionFilter]
    );

    /*
    Nesting the FlatList inside a ScrollView to allow horizontal scrolling - the "spring" effect, even when the list of item is short and doesn't require scrolling
    If they have different scroll directions, there will be no warning.
    Also it fixed the swipe gesture in the Chip bar that allows the user to navigate between tabs
    */

    if (allUniqueProjections.length === 0) {
        // there's a bug in section list where it doesn't render the next page when scrolling
        // not rendering the list when there's no data somehow fixes it: https://github.com/facebook/react-native/issues/39421
        return null;
    }

    return (
        <ScrollView style={common.flex} scrollEnabled={false}>
            <FlatList
                horizontal
                data={allUniqueProjections}
                ListFooterComponent={<SizeBoxComponent value={16} />}
                ListHeaderComponent={<SizeBoxComponent value={16} />}
                ItemSeparatorComponent={SizedBox}
                showsHorizontalScrollIndicator={showScrollIndicator}
                contentContainerStyle={styles.flatListStyle}
                renderItem={renderItem}
            />
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    flatListStyle: {
        minWidth: '100%',
    },
});
