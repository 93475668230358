import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={26} fill="none" {...props}>
        <Path
            fill="#EDEFF3"
            {...props}
            d="M17.995 21a9.956 9.956 0 0 1-6 2 9.956 9.956 0 0 1-6.001-2H3.05a11.97 11.97 0 0 0 8.944 4 11.97 11.97 0 0 0 8.945-4h-2.944ZM5.994 5a9.956 9.956 0 0 1 6-2c2.252 0 4.33.744 6.002 2h2.943a11.97 11.97 0 0 0-8.944-4A11.97 11.97 0 0 0 3.05 5h2.944Z"
        />
    </Svg>
);
export default SvgComponent;
