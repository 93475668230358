import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, StyleSheet, TouchableOpacity, View } from 'react-native';

import Clipboard from '@react-native-clipboard/clipboard';

import { EntryDetails } from '@/api/entries/types/types';
import { EventInfo } from '@/api/events/types/types';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useBetslipNavigation } from '@/feature/betslip-pickem/hooks/use-betslip-navigation';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { mapPicksToBetSlipPick } from '@/feature/betslip-pickem/utils/betslip-utils';
import { common } from '@/styles/styles';
import { GameType } from '@/types/api.generated';
import { format } from 'date-fns';

type EntryFooterProps = {
    entry?: EntryDetails;
    hasFullSeasonPicks?: boolean;
    handleContactUs: () => void;
    events: EventInfo[];
};

export const EntryFooter = ({ entry, hasFullSeasonPicks, handleContactUs, events }: EntryFooterProps) => {
    const { t } = useTranslation(['betslip_pickem', 'common', 'bets', 'lineup_update_modal']);
    const replacePicks = useBetslipStore(state => state.actions.replacePicks);
    const { navigateToPickslip } = useBetslipNavigation();
    const betslip = useBetslipStore(store => store.betslip);
    const { showInfoSheet } = useAlerts();

    const picks = mapPicksToBetSlipPick(entry?.picks ?? [], events);

    const addLineupToBetslip = useCallback(() => {
        if (betslip.length > 0) {
            showInfoSheet({
                title: t('lineup_update_modal:replace_lineup_title'),
                description: t('lineup_update_modal:replace_lineup_info'),
                buttonLabel: t('lineup_update_modal:replace_lineup'),
                handlePress: () => {
                    BetrAnalytics.trackEvent(AnalyticsEvent.REUSE_LINEUP_ENTRY_SCREEN);
                    replacePicks(picks, { entryId: entry?.id });
                    navigateToPickslip();
                },
                secondaryLabel: t('lineup_update_modal:keep_lineup'),
            });
        } else {
            BetrAnalytics.trackEvent(AnalyticsEvent.REUSE_LINEUP_ENTRY_SCREEN);
            replacePicks(picks, { entryId: entry?.id });
            navigateToPickslip();
        }
    }, [betslip.length, entry?.id, navigateToPickslip, picks, replacePicks, showInfoSheet, t]);

    return (
        <>
            {!entry?.result ? (
                <Button
                    label={t('betslip_pickem:reuse_lineup')}
                    onPress={addLineupToBetslip}
                    variant="rounded"
                    type="activeGray"
                    style={styles.reuseButton}
                />
            ) : null}
            <SizedBox value={20} />
            <View style={common.flex}>
                <TouchableOpacity
                    activeOpacity={0.9}
                    style={[common.row, common.justifySpaceBetween]}
                    onLongPress={() => {
                        Clipboard.setString(entry?.id ?? '');
                        Alert.alert('Entry ID copied to clipboard', entry?.id);
                    }}
                >
                    <Text variant="bodySmall" color={'gray2'}>
                        {t('entry_id')}
                    </Text>
                    <Text variant="bodySmall" color={'gray2'}>
                        {entry?.id}
                    </Text>
                </TouchableOpacity>
                <SizedBox value={5} />
                <View style={[common.row, common.justifySpaceBetween]}>
                    <Text variant="bodySmall" color={'gray2'}>
                        {t('common:submitted')}:
                    </Text>
                    <Text variant="bodySmall" color={'gray2'}>
                        {entry?.createdDate && Date.parse(entry?.createdDate)
                            ? format(Date.parse(entry?.createdDate), 'MM/dd/yyyy hh:mm aa')
                            : entry?.createdDate}
                    </Text>
                </View>
                <SizedBox value={5} />
                <View style={[common.row, common.justifySpaceBetween]}>
                    <Text variant="bodySmall" color={'gray2'}>
                        {t('common:closed')}:
                    </Text>
                    <Text variant="bodySmall" color={'gray2'}>
                        {entry?.settlementDate
                            ? format(Date.parse(entry?.settlementDate), 'MM/dd/yyyy hh:mm aa')
                            : '----'}
                    </Text>
                </View>
                <SizedBox value={5} />
                <View style={[common.row, common.justifySpaceBetween]}>
                    <Text variant="bodySmall"> {t('bets:result')}:</Text>
                    <Text variant="bodySmall">
                        {entry?.status === undefined
                            ? ''
                            : entry?.status === 'PENDING'
                            ? t('common:pending')
                            : entry?.result === 'WIN'
                            ? t('common:won')
                            : entry?.result === 'LOSS'
                            ? t('common:lost')
                            : t('common:voided')}
                    </Text>
                </View>
            </View>
            <SizedBox value={15} />
            {entry?.status === 'PENDING' ? (
                <View>
                    <LineSeparator />
                    <SizedBox value={15} />
                    <Text variant="bodySmall" color={'gray2'}>
                        {t('entry_delay_info')}
                    </Text>
                    {entry?.gameType === GameType.P2P ? (
                        <Text variant="bodySmall" color={'gray2'}>
                            {t('p2p_entry_delay_info')}
                        </Text>
                    ) : null}
                    <SizedBox value={15} />
                </View>
            ) : null}
            {entry?.status === 'PENDING' && hasFullSeasonPicks ? (
                <View>
                    <Text variant="bodySmall" color={'gray2'}>
                        {t('full_season_entry_delay')}
                    </Text>

                    <SizedBox value={15} />
                </View>
            ) : null}
            <LineSeparator />
            <SizedBox value={26} />
            <View style={[common.row, common.justifySpaceBetween, common.alignCenter]}>
                <Text variant="bodySmall">{t('need_further_support')}</Text>
                <Button
                    type="tertiary"
                    label={t('common:contact_us')}
                    size="large"
                    onPress={handleContactUs}
                    variant="outlined"
                />
            </View>
            <SizedBox value={48} />
        </>
    );
};

const styles = StyleSheet.create({
    reuseButton: {
        marginTop: 12,
        marginBottom: 4,
    },
});
