import { NavigationProp } from '@react-navigation/native';

import { URLS, createJurisdictionHeaders } from '@/data';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { TFunction } from 'i18next';

/**
 * Sends a POST request to the '/auth/user/confirm-channel' endpoint
 * to confirm the phone number channel in Keycloak.
 */
export const confirmKeycloakPhoneNumberChannel = async () => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/confirm-channel`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ channel: 'PHONE_NUMBER' }),
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return data;
};

/**
 * Formats a North American phone number to the format (XXX) XXX-XXXX.
 * @param phoneNumber The phone number to format.
 * @returns The formatted phone number.
 */
export const formatNorthAmericanPhoneNumber = (phoneNumber: string): string => {
    if (!phoneNumber || phoneNumber.length !== 10 || phoneNumber.charAt(0) === '1') {
        return phoneNumber;
    }

    // Format the phone number as (XXX) XXX-XXXX
    return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
};

export const showPhoneNumberEditErrorModal = (
    navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>,
    product: string,
    t: TFunction<['account', 'common'], undefined>
) => {
    navigation.navigate('ErrorModal', {
        title: t('edit_phone_number_failure_title'),
        subtitle: t('edit_phone_number_failure_description'),
        primaryButton: t('common:go_to_product_lobby', { product }),
        showSecondaryButton: true,
        handlePress: () => navigateHome(navigation),
    });
};
