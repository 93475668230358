import * as Urql from 'urql';
import gql from 'graphql-tag';

import * as Types from '../../types/api.generated';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EntryRuleFragment = {
    __typename?: 'EntryRulesOutput';
    id?: string;
    name?: string;
    active?: boolean;
    description?: string;
    gameMode: Types.GameMode;
    gameType: Types.GameType;
    minNumberOfPicks: number;
    minNumberOfTeams: number;
    maxNumberOfPicks: number;
    maxNumberOfBoostedPicks?: number;
    maxNumberOfSpecialPicks?: number;
};

export type GetTradingLimitsAndEntryRulesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetTradingLimitsAndEntryRulesQuery = {
    __typename?: 'Query';
    getAllEntryTradingLimits: Array<{
        __typename?: 'EntryTradingLimitOutput';
        id?: string;
        entryRulesId?: string;
        minAmount: number;
        maxAmount: number;
        multiplier: number;
        numberOfPicks: number;
        nightFeeMaxAmount?: number;
    }>;
    getAllEntryRules?: Array<{
        __typename?: 'EntryRulesOutput';
        id?: string;
        name?: string;
        active?: boolean;
        description?: string;
        gameMode: Types.GameMode;
        gameType: Types.GameType;
        minNumberOfPicks: number;
        minNumberOfTeams: number;
        maxNumberOfPicks: number;
        maxNumberOfBoostedPicks?: number;
        maxNumberOfSpecialPicks?: number;
    }>;
};

export const EntryRuleFragmentDoc = gql`
    fragment EntryRule on EntryRulesOutput {
        id
        name
        active
        description
        gameMode
        gameType
        minNumberOfPicks
        minNumberOfTeams
        maxNumberOfPicks
        maxNumberOfBoostedPicks
        maxNumberOfSpecialPicks
    }
`;
export const GetTradingLimitsAndEntryRulesDocument = gql`
    query GetTradingLimitsAndEntryRules {
        getAllEntryTradingLimits {
            id
            entryRulesId
            minAmount
            maxAmount
            multiplier
            numberOfPicks
            nightFeeMaxAmount
        }
        getAllEntryRules {
            ...EntryRule
        }
    }
    ${EntryRuleFragmentDoc}
`;

export function useGetTradingLimitsAndEntryRulesQuery(
    options?: Omit<Urql.UseQueryArgs<GetTradingLimitsAndEntryRulesQueryVariables>, 'query'>
) {
    return Urql.useQuery<GetTradingLimitsAndEntryRulesQuery, GetTradingLimitsAndEntryRulesQueryVariables>({
        query: GetTradingLimitsAndEntryRulesDocument,
        ...options,
    });
}
