import { designSystem } from '@/styles/styles';

import { googleAutocomplete } from './google-autocomplete';

const initializePaysafeSdk = /* JS */ `
    loadSDK()
        .then(setup)
        .then((inst) => {
            instance = inst;
            if (!instance.paymentMethods.card?.error) {
                addEventListeners(); // setting up the fields & button
                $("#deposit").bind("click", tokenize);
                // Remove loading state once SDK is initialized
                $(".loading-button").removeClass("loading-button");
            }
        })
        .catch((err) => {
            // Communicating back to the React Native app if SDK fails to initialize
            const error = JSON.stringify({ type: 'depositError', payload: { type: 'init', error: err }});
            postMessageToParentOrWebView(error);
        });

    // Read Paysafe SDK from config.ts and load it in <head> tag before Paysafe initialization
    async function loadSDK() {
        const sdkScript = document.createElement('script');
        sdkScript.src = paysafeSdk;
        // Apply loading state to inputs and button
        $("#deposit").addClass("loading-button");
        await new Promise((resolve, reject) => {
            sdkScript.addEventListener('load', resolve);
            sdkScript.addEventListener('error', reject);
            document.head.appendChild(sdkScript);
        });
    }
`;

const initializePaysafeFields = /* JS */ `
    async function setup() {
        // Initialize Paysafe fields with the provided API key and options
        const instance = await paysafe.fields.setup(API_KEY, options);
        // Display the payment methods available. This line is necessary to render the payment form.
        const paymentMethods = await instance.show();
        return instance;
    }
`;

const processPaysafePayment = /* JS */ `
    function tokenize() {
        // Check if there is an error in the last name field
        const $errorField = $(".holderName").find(".errorMessage");

        // Disable the button to prevent multiple clicks
        toggleDepositButtonState(true);
        const tokenizationOptions = {
            amount: selectedAmount*100,
            merchantRefNum: generateGuid(),
            transactionType: "PAYMENT",
            paymentType: "CARD",
            customerDetails: {
                holderName: $("#holderName").val(),
                billingDetails: {
                    country: "US",
                    state: selectedStateValue,
                    street: $("#street").val(),
                    zip: $("#zip").val(),
                },
                profile: {
                    firstName: userInfo.first_name,
                    lastName: userInfo.last_name,
                    phone: userInfo.phone_number,
                    email: userInfo.email,
                }
            }
        };
        instance
            .tokenize(tokenizationOptions)
            .then((result) => {
                validateAddressFields();
                const res = JSON.stringify({ type: 'depositSuccess', payload: result.token });
                postMessageToParentOrWebView(res);
            })
            .catch((err) => {
                handleTokenizationErrors(err);
                validateHolderLastName();
            });
    }
`;

// Function to handle errors during tokenization
const handleTokenizationErrors = /* JS */ `
    function handleTokenizationErrors(err) {
        hideAllErrors();  // Hide all error messages before displaying new ones
        if (err.code === INVALID_FIELD_ERROR_CODE) {
            let errorFields = addMissingAddressFieldsError(err.fieldErrors);
            errorFields.forEach((error) => {
                const errorField = convertToCamelCase(error.field);
                displayInvalidErrors(errorField);  // Display input-related errors
            });
            toggleDepositButtonState(false);  // Re-enable the button if only field errors
        } else if (err.code === CARD_NOT_SUPPORTED_ERROR_CODE) {
            const error = JSON.stringify({ type: 'depositError', payload: { type: 'notSupported', error: err }});
            postMessageToParentOrWebView(error);
            toggleDepositButtonState(false);  // Disable button due to critical error
        } else {
            const error = JSON.stringify({ type: 'depositError', payload: { type: 'generic', error: err }});
            postMessageToParentOrWebView(error);
            toggleDepositButtonState(false);  // Disable button due to critical error
        }
    }
`;

/**
 * Sets up event handlers for the debit deposit form.
 */
const setupEventHandlers = /* JS */ `
    // Set the selected STATE value when a message is received from RN
    // For Android
    document.addEventListener("message", (event) => {
        handleStateChangeEvent(event);
    });
    // For iOS
    window.addEventListener("message", (event) => {
        handleStateChangeEvent(event);
    });
    function addEventListeners() {
        const fields = instance.fields("cvv cardNumber expiryDate");

        // Update label and border styles on focus or blur
        fields.on("Focus Blur", (_, event) => {
            updateInputLabelAndBorderStyles(event);
        });

        // Handle field value changes
        fields.on("FieldValueChange", (_, event) => {
            removeErrorMessageForPaySafeFields(event);
            // Immediate validation for card number field
            if (event.target.fieldName === "CardNumber") {
                validateBadBin();
                validateCardBrandImmediately();
                if(instance.fields.cardNumber.isEmpty()) {
                    updateCardBrandError();
                }
            } else if (event.target.fieldName === 'ExpiryDate') {
                // Immediate validation for expiry date field
                validateExpDateImmediately();
            }
        });

        let $holderNameField = document.querySelector('#holderName');
        $holderNameField.addEventListener('blur', function() {
            validateHolderLastName();
        });

        // Show the picker when the STATE field is focused
        $('#state').focus(() => {
            postMessageToParentOrWebView(JSON.stringify({ type: 'showPicker'}));
        });

        $('#state').attr('readonly', 'readonly'); // Disable the keyboard for the STATE field

        // Hide error field on input for specific fields
        ["holderName", "zip", "street", "state"].map((field) => {
            const $errorField = $("." + field).find(".errorMessage");
            $("#" + field).on("input", () => {
                hideFieldError($errorField);
            });
        });
    }
`;

/**
 * Validate the holder's last name against the user's last name.
 */
const validateHolderLastName = /* JS */ `
    function validateHolderLastName() {
        let $holderNameField = document.querySelector('#holderName');
        let holderName = $holderNameField.value.trim().toLowerCase();
        let userInfoLastNameWords = userInfo.last_name.toLowerCase().split(' ');
        
        // Instead of checking for an exact match last names, we check if the filled holder name contains the user's last name
        let hasMatchedLastNameWords = userInfoLastNameWords.some((letter) => holderName.includes(letter));

        const $errorField = $(".holderName").find(".errorMessage");
        if (!hasMatchedLastNameWords) {
            $errorField.text('The card on file must be the owner of the betr account'); // Set the error message
            $errorField.css('display', 'block'); // Make sure the error message is visible
        } else {
            // Resets the error message if the last name matches
            $errorField.text('');
            $errorField.css('display', 'none');
        }
    }
`;

/**
 * Checks if the card's BIN (Bank Identification Number) has a high decline rate
 */
const validateBadBin = /* JS */ `
    function validateBadBin() {
        instance.badBin((_, event) => {
            if (event.data.declineRate > badBinThreshold) {
                // If the decline rate is higher than "badBinThreshold", which is configured in Prismic, we decline the card
                $(document.activeElement).blur(); // Dismiss the keyboard
                // Send a message to the React Native WebView indicating a bad BIN
                 postMessageToParentOrWebView(JSON.stringify({ type: 'badBin' }));
            }
        });
    }
`;

/**
 * Validates the card brand eg., Mastercard, Visa, American Express, and etc immediately.
 */
const validateCardBrandImmediately = /* JS */ `
    function validateCardBrandImmediately() {
        var cardBrand = instance.getCardBrand();
        switch (cardBrand) {
            case 'Visa':
            case 'MasterCard':
                // Clear any previous error messages related to unsupported card brands
                removeErrorMessageForPaySafeFields();
                break;
            default:
                // If the brand is known and not Visa or MasterCard, display an error
                updateCardBrandError('Only Debit Visa or Debit Mastercard Accepted');
                break;
        }
    }
`;

/**
 * Validates the expiry date immediately.
 */
const validateExpDateImmediately = /* JS */ `
    function validateExpDateImmediately() {
        if (!instance.fields.expiryDate.isValid()) {
            displayInvalidErrors('expiryDate');
        } else {
            const $errorField = $('.expiryDate').find('.errorMessage');
            hideFieldError($errorField);
        }
    }
`;

/**
 * Validates address fields.
 */
const validateAddressFields = /* JS */ `
    function validateAddressFields() {
        if ($('#street').val() === '' || selectedStateValue === '') {
            let errorFields = [];
            const error = new Error();
            error.code = INVALID_FIELD_ERROR_CODE;
            error.fieldErrors = addMissingAddressFieldsError(errorFields);
            throw error;
        }
    }
`;

/**
 * Updates the error message for unsupported card brands.
 */
const updateCardBrandError = /* JS */ `
    function updateCardBrandError(message) {
        const $errorField = $('.cardNumber').find('.errorMessage');
        if (message) {
            $errorField.text(message); // Set the error message
            $errorField.css('display', 'block'); // Make sure the error message is visible
        } else {
            $errorField.text(''); // Clear the error message
            $errorField.css('display', 'none'); // Hide the error message
        }
    }
`;

/**
 * Checks if the 'street' and 'state' fields are empty.
 * If they are, it adds an object with the field name to the passed array.
 */
const addMissingAddressFieldsError = /* JS */ `
    function addMissingAddressFieldsError(array) {
        if ($('#street').val() === '') {
            array.push({ field: 'street' });
        }
        if (selectedStateValue === '') {
            array.push({ field: 'state' });
        }
        return array;
    }
`;

const removeErrorMessageForPaySafeFields = /* JS */ `
    function removeErrorMessageForPaySafeFields(event) {
        const $errorField = $('.' + event.target.fieldName).find('.errorMessage');
        if (!$errorField.is(':empty')) {
            hideFieldError($errorField);
        }
    }
`;

const hideFieldError = /* JS */ `
    function hideFieldError($errorField) {
        $errorField.css('display', 'none');
    }
`;

const hideAllErrors = /* JS */ `
    function hideAllErrors() {
        $('.errorMessage').css('display', 'none');
    }
`;

const displayInvalidErrors = /* JS */ `
    function displayInvalidErrors(errorField) {
        const readableStr = errorField.replace(/([A-Z])/g, ' $1').toLowerCase().trim();
        const errorMessageDiv = $('.' + errorField).find('.errorMessage');
        let errorMessage = 'Enter a valid ' + readableStr;
        if (readableStr === 'street') {
            errorMessage = 'Enter a valid and full billing address';
        }
        errorMessageDiv.css('display', 'inline-block');
        errorMessageDiv.text(errorMessage);
    }
`;

const handleStateChangeEvent = /* JS */ `
    function handleStateChangeEvent(event) {
        if(event.data.includes('state')) {
            handleAmericanStateValues();
            updateAmericanStateFieldStyle();
            hideFieldError($(".state").find(".errorMessage"));
        }
    }
`;

const handleAmericanStateValues = /* JS */ `
    function handleAmericanStateValues() {
        selectedStateValue = event.data.split(':')[1];
        selectedStateLabel = event.data.split(':')[2];
        $('#state').val(selectedStateLabel);
    }
`;

const handleAlphanumericKeyPress = /* JS */ `
    /**
     * Prevents input of non-alphanumeric characters and consecutive spaces in key press events.
     * The function checks if the pressed key is alphanumeric (matches "ALPHANUMERIC_REGEX") or if
     * consecutive spaces are attempted. If either condition is met, the event is prevented.
     * @param {KeyboardEvent} event - The event triggered on key press.
     * @returns {boolean|undefined} False if the event is prevented, otherwise no return.
     */
    function handleAlphanumericKeyPress(event) {
        let lastKey = '';
        const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        // Check if the current key is a space and the last key was also a space to prevent consecutive spaces.
        if (key === ' ' && lastKey === ' ') {
            event.preventDefault();
            return false;
        }
        // Use the predefined "ALPHANUMERIC_REGEX" to test if the pressed key is alphanumeric.
        // If not, prevent the event to ignore non-alphanumeric characters.
        if (!testRegex(ALPHANUMERIC_REGEX, key)) {
            event.preventDefault();
            return false;
        }
        // Store the last pressed key to check for consecutive spaces in future key presses.
        lastKey = key;
    }
`;

/**
 * Handle alphanumeric paste event and is called in the HTML file
 */
const handleAlphanumericPaste = /* JS */ `
    function handleAlphanumericPaste(event) {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (!testRegex(ALPHANUMERIC_REGEX, pastedData)) {
            event.preventDefault();
            return false;
        }
    }
`;

/**
 * Updates the styles of the input fields and its labels based on the focus and blur events
 */
const updateInputLabelAndBorderStyles = /* JS */ `
    function updateInputLabelAndBorderStyles(event) {
        // Check whether the input field is focused or holding a value
        const isConditionMet = event.type === 'Focus' || (event.type === 'Blur' && !event.data.isEmpty);
        // Paysafe returns the fieldName in PascalCase, but we use camelCase.
        const $field = $('.' + event.target.fieldName.charAt(0).toLowerCase() + event.target.fieldName.slice(1));
        $field.find('.paysafeLabel').css({
            'font-size': isConditionMet ? '13px' : '15px',
            top: isConditionMet ? '10px' : '50%',
            transform: isConditionMet ? 'translateY(0)' : 'translateY(-50%)',
        });
        $field.find('.inputField').css('border', event.type === 'Focus' ? '2px solid ${designSystem.colors.white}' : '1px solid #2F3138');
        $field.find('.inputField').css('border-radius', '8px');
    }
`;

const updateAmericanStateFieldStyle = /* JS */ `
    function updateAmericanStateFieldStyle() {
        $('.state .htmlLabel').addClass('floatingLabel');
    }
`;

/**
 * Disable or enable the deposit button based on the provided flag.
 */
const toggleDepositButtonState = /* JS */ `
    function toggleDepositButtonState(isDisabled) {
        if (isDisabled) {
            $("#deposit").prop("disabled", true);
            $("#deposit").css({
                "background-color": "${designSystem.colors.gray5}",
                "border-color": "${designSystem.colors.gray5}",
            });
        } else {
            $("#deposit").prop("disabled", false);
            $("#deposit").css({
                "background-color": "${designSystem.colors.white}",
                "border-color": "${designSystem.colors.white}"
            });
        }
    }
`;

/**
 * Formats the field names to camel case. eg. "card number" -> "cardNumber", "options.customerDetails.holderName" -> "holderName"
 */
const convertToCamelCase = /* JS */ `
    function convertToCamelCase(fieldName) {
        const words = fieldName.split('.').pop().split(' ');
        const camelCaseWords = words.map((word, index) => {
            if (index === 0) {
                return word.toLowerCase();
            }
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        const camelCaseStr = camelCaseWords.join('');
        return camelCaseStr;
    }
`;

/**
 * Generates a unique identifier (GUID).
 * Source: Paysafe's code sandbox (https://codepen.io/paysafe/pen/zYPYrVv)
 */
const generateGuid = /* JS */ `
    function generateGuid() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
        );
    }
`;

const utils = /* JS */ `
    function postMessageToParentOrWebView(data) {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(data);
        } else {
            window.parent.postMessage(data);
        }
    }
`;

/**
 * Prefill the holderName field with the user's first and last name
 */
const preFillHolderName = /* JS */ `
    $('#holderName').val(userInfo.first_name + ' ' + userInfo.last_name);
`;

export const initializePaysafe = params => {
    return /* JS */ `
        const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9 '-]*$/;

        let selectedStateValue = "";
        let selectedStateLabel = "";

        const selectedAmount = ${params.selectedAmount};
        const productName = "${params.productName}";

        const username = "${params.username}";
        const pubKey = "${params.pubKey}";
        const paysafeAccountId = ${params.paysafeAccountId};
        const paysafeEnv = "${params.paysafeEnv}";
        const paysafeSdk = "${params.paysafeSdk}";

        const userInfo = ${params.userInfo};
        const badBinThreshold = ${params.badBinThreshold};

        const INVALID_FIELD_ERROR_CODE = "9003";
        const CARD_NOT_SUPPORTED_ERROR_CODE = "5281";

        // Display the amount to be deposited in the button
        $("#deposit").html("Deposit $" + selectedAmount.toLocaleString() + " to " + productName);

        // API_KEY has to be base64 encoded to use in Paysafe's JS SDK
        const API_KEY = btoa(username + ":" + pubKey);

        ${googleAutocomplete}

        const options = {
            currencyCode: "USD",
            accounts: {
                default: paysafeAccountId // State's paysafe account ID
            },
            environment: paysafeEnv,
            fields: {
                cardNumber: {
                    selector: "#cardNumber",
                    separator: " "
                },
                cvv: {
                    selector: "#cvv",
                    mask: false,
                    optional: false
                },
                expiryDate: {
                    selector: "#expiryDate",
                }
            },
            style: {
                input: {
                    "font-family": "'SF Pro Display', sans-serif",
                    "font-weight": "normal",
                    "width": "100%",
                    "font-size": "15px",
                    "color": "${designSystem.colors.white}"
                    // "caret-color": "${designSystem.colors.white}", // not working
                }
            }
        };

        let instance;

        ${preFillHolderName}

        ${initializePaysafeSdk}

        ${initializePaysafeFields}

        ${processPaysafePayment}

        ${handleTokenizationErrors}

        ${setupEventHandlers}

        ${validateHolderLastName}

        ${validateBadBin}

        ${validateCardBrandImmediately}

        ${validateExpDateImmediately}

        ${validateAddressFields}

        ${updateCardBrandError}

        ${addMissingAddressFieldsError}

        ${removeErrorMessageForPaySafeFields}

        ${hideFieldError}

        ${hideAllErrors}

        ${displayInvalidErrors}

        ${handleStateChangeEvent}

        ${handleAmericanStateValues}

        ${handleAlphanumericKeyPress}

        ${handleAlphanumericPaste}

        ${updateInputLabelAndBorderStyles}

        ${updateAmericanStateFieldStyle}

        ${toggleDepositButtonState}

        ${convertToCamelCase}

        ${generateGuid}

        ${utils}

        function testRegex(regex, text) {
            return regex.test(text);
        }`;
};
