import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <Path
            d="M10.1579 15.0926L9.42105 22.5L19 12.1296L13.8421 9.16667L14.5789 2.5L5 12.8704L10.1579 15.0926Z"
            fill="#C93EF4"
        />
    </Svg>
);

export default SvgComponent;
