import React, { useCallback } from 'react';
import { Animated, Pressable, StyleSheet } from 'react-native';

import { useFocusEffect } from '@react-navigation/native';

import { animationConfig } from '@/components/Switch';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';
import { Product } from '@/hooks/use-jurisdiction';
import { designSystem } from '@/styles/styles';

const MARGIN = 4;
const WIDTH = 234;
const HEIGHT = 44;
const FANTASY = 0;
const SPORTSBOOK = 1;

type Props = {
    onPressValue: (value: Product) => void;
    activeProduct: Product;
};

export const ProductSwitch = ({ onPressValue, activeProduct }: Props) => {
    const switchTranslate = React.useRef(new Animated.Value(Number(activeProduct === Product.Sportsbook))).current;
    const animate = useCallback(
        (toValue: number) => {
            Animated.spring(switchTranslate, { toValue, ...animationConfig }).start();
        },
        [switchTranslate]
    );

    useFocusEffect(
        useCallback(() => {
            if (activeProduct === Product.Sportsbook) {
                animate(SPORTSBOOK);
            } else if (activeProduct === Product.Pickem) {
                animate(FANTASY);
            }
        }, [activeProduct, animate])
    );

    return (
        <Animated.View style={styles.container}>
            <Row flex={1} zIndex={1}>
                <Pressable style={styles.pressable} onPress={() => onPressValue(Product.Pickem)}>
                    <Text variant="labelLarge">Fantasy</Text>
                </Pressable>
                <Pressable style={styles.pressable} onPress={() => onPressValue(Product.Sportsbook)}>
                    <Text variant="labelLarge">Sportsbook</Text>
                </Pressable>
            </Row>
            <Animated.View
                style={[
                    styles.button,
                    {
                        transform: [
                            {
                                translateX: switchTranslate.interpolate({
                                    inputRange: [FANTASY, SPORTSBOOK],
                                    outputRange: [MARGIN, WIDTH / 2],
                                    extrapolate: 'clamp',
                                }),
                            },
                        ],
                    },
                ]}
            />
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    button: {
        width: WIDTH / 2 - MARGIN,
        height: HEIGHT - MARGIN * 2,
        backgroundColor: designSystem.colors.gray4,
        borderRadius: 20,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowColor: designSystem.colors.gray8,
        shadowOpacity: 0.25,
        shadowRadius: 2,
        elevation: 1,
        position: 'absolute',
    },
    container: {
        width: WIDTH,
        height: HEIGHT,
        justifyContent: 'center',
        borderRadius: 100,
        backgroundColor: designSystem.colors.gray6,
    },
    pressable: {
        flex: 1,
        height: HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
