import { useCallback } from 'react';

import { useBetSubmissionComplete } from '@/feature/betslip-sbk/hooks/use-bet-submission-complete';
import { useIsNativeSportsbook } from '@/hooks/use-is-native-sportsbook';
import { useSubscribeAuth } from '@/utils/websocket/hooks';
import { BetGroupUpdateMessage } from '@/utils/websocket/types';

import { useSbkBetSlipStore } from './use-sbk-betslip-store';

export const useSubscribeBetGroupUpdate = () => {
    const updateSubmittedBetStatus = useSbkBetSlipStore(state => state.actions.updateSubmittedBetStatus);
    const enabled = useIsNativeSportsbook();

    const onComplete = useBetSubmissionComplete();

    const handler = useCallback(
        (message: BetGroupUpdateMessage) => {
            updateSubmittedBetStatus(
                message.betProducerId,
                message.status,
                message.betGroupId,
                message.rejectionReason,
                onComplete
            );
        },
        [updateSubmittedBetStatus, onComplete]
    );

    useSubscribeAuth<'betGroupUpdate'>('bet_group_update', handler, enabled);
};
