import React from 'react';

import CustomWebView from '@/components/CustomWebView';
import { Screen } from '@/components/ScreenComponent';
import { URLS } from '@/data/config';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { user } from '@/hooks/use-user';

export const InviteFriendsScreen = () => {
    const productConfig = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig);
    return (
        <Screen>
            <MaxWidthWrapper flex={1}>
                <CustomWebView
                    uri={`${URLS.PROMO_RAF_PAGE_URL}/${productConfig?.rafPromotionUid}?CustID=${user.profile.sub}`}
                    openNewTabOnWeb={false}
                />
            </MaxWidthWrapper>
        </Screen>
    );
};
