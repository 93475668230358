import React from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, StyleSheet } from 'react-native';

import BetrBucks from '@/assets/icons/betr-bucks';
import LockIcon from '@/assets/icons/lock';
import { getFormattedOdds } from '@/components/Odds';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { useBetSummary } from '../../hooks/betslip-hooks';

const styles = StyleSheet.create({
    borderSeparator: {
        borderTopColor: designSystem.colors.gray5,
        borderTopWidth: 0.5,
        paddingVertical: 16,
    },
});

export type BetSummaryTableBodyProps = {
    setWagerColWidth: (value: React.SetStateAction<number>) => void;
    setPayoutColWidth: (value: React.SetStateAction<number>) => void;
    maxBetMultiplierWidth: number;
    colMinWidth: number;
};

const ROW_HEIGHT = 76;
const SUSPENDED_TEXT_COLOR = 'gray3';

export const BetSummaryTableBody = ({
    setWagerColWidth,
    setPayoutColWidth,
    maxBetMultiplierWidth,
    colMinWidth,
}: BetSummaryTableBodyProps) => {
    const { t } = useTranslation(['betslip_sbk', 'bets']);

    const betSummary = useBetSummary();

    const onWagerColLayout = (e: LayoutChangeEvent) => {
        if (e.nativeEvent.layout.width > colMinWidth) {
            setWagerColWidth(e.nativeEvent.layout.width);
        }
    };

    const onPayoutColLayout = (e: LayoutChangeEvent) => {
        if (e.nativeEvent.layout.width > colMinWidth) {
            setPayoutColWidth(e.nativeEvent.layout.width);
        }
    };

    return (
        <Row justifyContent="space-between">
            <Column flex={1} maxWidth={maxBetMultiplierWidth}>
                {betSummary.map((row, idx) => {
                    const { key, selectionCount, eventName, odds } = row[0];
                    const isOptionSuspended = row[3];
                    return (
                        <Box key={`info-${idx}`} height={ROW_HEIGHT} style={styles.borderSeparator} paddingRight="s16">
                            <Text
                                variant="bodyMedium"
                                color={isOptionSuspended ? SUSPENDED_TEXT_COLOR : 'gray1'}
                                ellipsizeMode="tail"
                                numberOfLines={1}
                            >
                                {t(key, { selectionCount, eventName })}
                            </Text>
                            {isOptionSuspended ? (
                                <Box gap="s4" flexDirection="row" alignItems="center">
                                    <LockIcon />
                                    <Text variant="bodySmall" color={SUSPENDED_TEXT_COLOR}>
                                        {t('bets:suspended')}
                                    </Text>
                                </Box>
                            ) : (
                                <Text variant="bodySmall" color={'gray2'}>
                                    {`${t('multiplier')}: ${getFormattedOdds(odds)}`}
                                </Text>
                            )}
                        </Box>
                    );
                })}
            </Column>
            <Column onLayout={onWagerColLayout} minWidth={colMinWidth}>
                {betSummary.map((row, idx) => {
                    const { stake, betrBucksUsed } = row[1];
                    const isOptionSuspended = row[3];

                    return (
                        <Row
                            key={`wager-${idx}`}
                            height={ROW_HEIGHT}
                            style={styles.borderSeparator}
                            justifyContent="flex-end"
                            alignItems="center"
                            paddingLeft="s16"
                            gap="s8"
                        >
                            {betrBucksUsed ? (
                                <BetrBucks
                                    height={16}
                                    fill={isOptionSuspended ? designSystem.colors.gray3 : designSystem.colors.gray1}
                                />
                            ) : null}
                            <Text
                                variant="bodyMedium"
                                color={isOptionSuspended ? SUSPENDED_TEXT_COLOR : 'gray1'}
                                textAlign="right"
                                textDecorationLine={isOptionSuspended ? 'line-through' : 'none'}
                            >
                                {toLocaleCurrency(stake)}
                            </Text>
                        </Row>
                    );
                })}
            </Column>
            <Column onLayout={onPayoutColLayout} minWidth={colMinWidth}>
                {betSummary.map((row, idx) => {
                    const payout = row[2];
                    const isOptionSuspended = row[3];

                    return (
                        <Box
                            key={`payout-${idx}`}
                            height={ROW_HEIGHT}
                            style={styles.borderSeparator}
                            justifyContent="center"
                            paddingLeft="s16"
                        >
                            <Text
                                variant="bodyMedium"
                                color={isOptionSuspended ? SUSPENDED_TEXT_COLOR : 'gray1'}
                                textAlign="right"
                                textDecorationLine={isOptionSuspended ? 'line-through' : 'none'}
                            >
                                {toLocaleCurrency(payout)}
                            </Text>
                        </Box>
                    );
                })}
            </Column>
        </Row>
    );
};
