import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { useBetEjectedAlert } from '@/feature/betslip-sbk/hooks/use-bet-ejected-alert';
import { useOneLineSummary } from '@/feature/betslip-sbk/hooks/use-one-line-summary';
import { usePrefetchBets } from '@/feature/betslip-sbk/hooks/use-prefetch-bets';
import { SBK_BETSLIP_LOG_TAG, useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { BetSubmissionSummary } from '@/feature/betslip-sbk/types';
import { userLimitsKeys } from '@/feature/responsible-gaming/hooks/query-keys';
import { SBKBetsStackParamList } from '@/navigation/sbk/types';
import { RootStackParamList } from '@/navigation/types';
import { logger } from '@/utils/logging';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { useQueryClient } from '@tanstack/react-query';

// Callback to handle logic when a bet submission is complete
export const useBetSubmissionComplete = () => {
    const navigation = useNavigation<StackNavigationProp<SBKBetsStackParamList & RootStackParamList>>();
    const { t } = useTranslation(['betslip_sbk', 'common']);
    const { generateOneLineSummary } = useOneLineSummary();
    const clearBetSlip = useSbkBetSlipStore(state => state.actions.clearBetSlip);
    const { showBetEjectedAlert } = useBetEjectedAlert();
    const queryClient = useQueryClient();

    const prefetchBets = usePrefetchBets();
    return useCallback(
        (success: boolean, summary?: BetSubmissionSummary) => {
            if (success && summary) {
                // Success bet submission
                logger.info(SBK_BETSLIP_LOG_TAG, 'Bet submission success', summary);
                prefetchBets('Open');
                const { singleCount, comboCount, scriptCount, wageredAmount, betIds } = summary;
                const oneLineSummary = generateOneLineSummary({ singleCount, scriptCount, comboCount });

                const totalBets = betIds.length;
                GASbkEvents.betSlipInteractionSummary(totalBets, totalBets);
                GASbkEvents.viewBetConfirmation();

                // userLimit needs to be updated after bet submission
                queryClient.invalidateQueries(userLimitsKeys.limits());

                navigation.navigate('SuccessModal', {
                    title: t('betslip_sbk:bet_submission_success_title', { amount: toLocaleCurrency(wageredAmount) }),
                    subtitle: t('betslip_sbk:bet_submission_success_subtitle', { summary: oneLineSummary }),
                    primaryButton: t('common:done'),
                    secondaryButton: t('betslip_sbk:view_bet', { count: totalBets }),
                    handlePress: () => {
                        clearBetSlip();
                        navigation.goBack();
                    },
                    handleSecondaryPress: () => {
                        clearBetSlip();
                        if (betIds.length === 1) {
                            navigation.reset({
                                index: 0,
                                routes: [
                                    {
                                        name: 'SBKHome',
                                        params: { screen: 'Bets' },
                                    },
                                    {
                                        name: 'SbkBetScreen',
                                        params: { screen: 'Bet', betId: betIds[0] },
                                    },
                                ],
                            });
                        } else {
                            navigation.reset({
                                index: 0,
                                routes: [
                                    {
                                        name: 'SBKHome',
                                        params: { screen: 'Bets' },
                                    },
                                ],
                            });
                        }
                    },
                });
            } else if (!success) {
                showBetEjectedAlert();
            }
        },
        [generateOneLineSummary, navigation, t, clearBetSlip, showBetEjectedAlert, prefetchBets, queryClient]
    );
};
