import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 24}
        height={props.height ?? 24}
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <Path
            fill="#EDEFF3"
            fillRule="evenodd"
            d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10ZM11 9V7h2v2h-2Zm0 8v-6h2v6h-2Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
