import React, { useState } from 'react';
import { LayoutChangeEvent, View } from 'react-native';

import { LinearGradientProps } from './types';

export const LinearGradient = (props: LinearGradientProps) => {
    const [width, setWidth] = useState(1);
    const [height, setHeight] = useState(1);

    const measure = (event: LayoutChangeEvent) => {
        setWidth(event.nativeEvent.layout.width);
        setHeight(event.nativeEvent.layout.height);
    };

    const getAngle = () => {
        if (props.angle) {
            return props?.angle + 'deg';
        }

        const endX = props.end?.x || 0.5;
        const endY = props.end?.y || 0;
        const startX = props.start?.x || 0.5;
        const startY = props.start?.y || 1;

        const angle = Math.atan2(width * (endY || 1 - startY), height * (endX - startX)) + Math.PI / 2;
        return angle + 'rad';
    };

    const getColors = () =>
        props.colors
            .map((color, index) => {
                const location = props.positions ? props.positions[index] : undefined;
                let locationStyle = '';
                if (location) {
                    locationStyle = ' ' + location * 100 + '%';
                }
                return color + locationStyle;
            })
            .join(',');

    return (
        <View
            style={[props.style, { backgroundImage: `linear-gradient(${getAngle()},${getColors()})` }]}
            onLayout={measure}
        />
    );
};
