import React, { ReactNode } from 'react';

import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';

type AccountDetailsRowProps = {
    label: string;
    subLabel?: string | ReactNode;
    renderAction?: () => ReactNode;
};

export const AccountDetailsRow = ({ label, subLabel, renderAction }: AccountDetailsRowProps) => {
    return (
        <Row
            alignItems="center"
            justifyContent="space-between"
            paddingVertical="s8"
            borderBottomWidth={1}
            borderBottomColor="gray5"
        >
            <Box>
                <Text color="gray2" variant="bodySmall">
                    {label}
                </Text>
                {typeof subLabel === 'string' ? <Text variant="bodyMedium">{subLabel}</Text> : subLabel}
            </Box>
            {renderAction && renderAction()}
        </Row>
    );
};
