import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Odds } from '@/components/Odds';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';
import { useOptionName } from '@/feature/event-details-sbk/hooks/use-option-name';
import { SEPARATOR_HEGIHT } from '@/styles/styles';

import { useSbkBetSlipStore } from '../hooks/use-sbk-betslip-store';
import { ClosedSelection } from '../types';
import { formatEventHeader } from '../utils/formatter';

type ClosedSelectionItemProps = {
    selection: ClosedSelection;
    isLastItem: boolean;
};

const ClosedSelectionItem = ({ selection, isLastItem }: ClosedSelectionItemProps) => {
    const marketName = useMarketName({
        sportName: selection.event.sport.name,
        marketType: selection.market.marketType,
        fallback: selection.market.description,
    });
    const optionName = useOptionName({
        optionType: selection.option.optionType,
        marketType: selection.market.marketType,
        homeTeam: selection.event.home_team,
        awayTeam: selection.event.away_team,
        fallback: selection.option.description,
        sportName: selection.event.sport.name,
    });

    return (
        <Box
            borderBottomWidth={isLastItem ? 0 : SEPARATOR_HEGIHT}
            borderBottomColor={!isLastItem ? 'gray5' : undefined}
            pb={isLastItem ? 's0' : 's20'}
            mt="s16"
        >
            <Text color="gray2" variant="bodySmall">
                {formatEventHeader(selection.event)}
            </Text>
            <Row mt="s8" justifyContent="space-between">
                <Text variant="titleMedium">{optionName}</Text>
                <Odds odds={selection.option.odds} variant="titleMedium" />
            </Row>
            <Text variant="bodySmall" color="gray2">
                {marketName}
            </Text>
        </Box>
    );
};

export const ClosedSelectionsInfoSheet = () => {
    const closedSelections = useSbkBetSlipStore(state => state.closedSelections);

    const [screenModalHeight, setScreenModalHeight] = useState(0);
    const { height } = useWindowDimensions();
    const safeInsets = useSafeAreaInsets();
    const maxContainerHeight = height - safeInsets.top - 265;
    const { t } = useTranslation('betslip_sbk');

    return (
        <Box onLayout={event => setScreenModalHeight(event.nativeEvent.layout.height)}>
            <Text textAlign="center" color="gray2" mb="s16" paddingHorizontal="s8">
                {t('closed_bets_info_sheet_description')}
            </Text>
            <Box mb="s16">
                <FlatList
                    scrollEnabled={screenModalHeight >= maxContainerHeight}
                    showsVerticalScrollIndicator={false}
                    style={{ maxHeight: maxContainerHeight }}
                    data={closedSelections}
                    renderItem={({ item, index }) => (
                        <ClosedSelectionItem
                            key={item.option.id}
                            selection={item}
                            isLastItem={closedSelections.length - 1 === index}
                        />
                    )}
                />
            </Box>
        </Box>
    );
};
