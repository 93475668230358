import { useEffect, useState } from 'react';

import { useUpcomingEventsInfoQuery } from '@/api/events/query.generated';
import { EventInfo, isIndividualEvent, isTeamEvent } from '@/api/events/types/types';
import { useAuthUserSettings } from '@/hooks/use-auth-user-settings';
import { MarketStatus } from '@/types/api.generated';
import { logger } from '@/utils/logging';
import { runStoreUpdate } from '@/utils/zustand';
import { createWithEqualityFn } from 'zustand/traditional';

import { useLineupUpdate } from '../components/LineupUpdateModalProvider';
import { PlayerProjection } from '../types';
import { getPicksWithChangedProjectionValue } from '../utils/betslip-projection-changed';
import { eventUtils } from '../utils/event-utils';
import { useBetslipStore } from './use-betslip-store';

interface PlayerMarketsStore {
    playerMarkets: Record<string, PlayerProjection[]>;
    actions: {
        updateStorePlayerProjections: (playerId: string, projections: PlayerProjection[]) => void;
        updateStorePlayersProjections: (markets: Record<string, PlayerProjection[]>) => void;
        /**
         * Used for debugging purposes, to suspend markets for a given player ID.
         * @param playerId - the player ID for which to simulate suspended markets
         * @param mode - if the method should suspend all markets, or leave one active
         */
        _debugSuspendPlayerMarkets: (playerId: string, mode: 'all' | 'leave_one') => void;
        /**
         * Used for debugging purposes, simulates 'currentValues' for a given player ID.
         * @param playerId - the player ID for which to simulate 'currentValues'
         */
        _debugEnableCurrentValues: (playerId: string) => void;
    };
}

/**
 * Store that caches all the player projections that are loaded.
 * All components that need to access the player projections should use this store.
 * All components that fetch player projections should also update this store.
 */
export const usePlayerPropsStore = createWithEqualityFn<PlayerMarketsStore>()((set, get) => {
    return {
        playerMarkets: {},
        actions: {
            updateStorePlayerProjections: (playerId, markets) => {
                const playerMarkets = get().playerMarkets;
                playerMarkets[playerId] = markets;
                set({ playerMarkets: { ...playerMarkets } });
            },
            updateStorePlayersProjections: markets => {
                const playerMarkets = get().playerMarkets;
                set({ playerMarkets: { ...playerMarkets, ...markets } });
            },
            _debugSuspendPlayerMarkets: (playerId: string, mode: 'all' | 'leave_one') => {
                const marketsForPlayer = get().playerMarkets[playerId];
                if (marketsForPlayer && marketsForPlayer.length > 0) {
                    const firstMarketToSuspend = mode === 'all' ? 0 : 1;
                    for (let i = firstMarketToSuspend; i < marketsForPlayer.length; i++) {
                        marketsForPlayer[i].marketStatus = MarketStatus.Suspended;
                    }
                    set({ playerMarkets: { ...get().playerMarkets, playerId: [...marketsForPlayer] } });
                } else {
                    logger.debug(`No player markets found for ${playerId}`);
                }
            },
            _debugEnableCurrentValues: (playerId: string) => {
                const marketsForPlayer = get().playerMarkets[playerId];
                if (marketsForPlayer && marketsForPlayer.length > 0) {
                    marketsForPlayer.forEach(market => (market.currentValue = market.value / 2));
                    set({ playerMarkets: { ...get().playerMarkets, playerId: [...marketsForPlayer] } });
                } else {
                    logger.debug(`No player markets found for ${playerId}`);
                }
            },
        },
    };
}, Object.is);

export const playerPropsSelector = (playerId: string) => (state: PlayerMarketsStore) =>
    state.playerMarkets[playerId]?.filter(it => it.marketStatus === MarketStatus.Opened) ?? [];

/**
 * Hook that will update the player projections store cache with the new player projections.
 * This will also run some checks to see if projections have changed for the current betslip picks,
 * and update them or show the projection changed modal based on user settings.
 * @param data - the newly fetched data that contains the player projections
 */
export const useUpdatePlayerStoreWithNewData = (data: EventInfo[] | undefined) => {
    const updatePlayersMarkets = usePlayerPropsStore(state => state.actions.updateStorePlayersProjections);
    const updateBetslipProjections = useBetslipStore(state => state.actions.updateProjections);
    const { showLineupUpdateModal } = useLineupUpdate();

    const { data: userData } = useAuthUserSettings();
    const acceptAllProjectionsChanges = userData && userData.accept_all_odds_changes;

    useEffect(() => {
        if (data) {
            const currentPicks = useBetslipStore.getState().betslip ?? [];
            const playersMap = eventUtils.getAllPlayersAsMap(data);
            runStoreUpdate(() => updatePlayersMarkets(playersMap));
            const picksWithChangedProjections = getPicksWithChangedProjectionValue(currentPicks, data);
            if (acceptAllProjectionsChanges) {
                runStoreUpdate(() => updateBetslipProjections(picksWithChangedProjections));
            } else {
                if (picksWithChangedProjections.length > 0) {
                    showLineupUpdateModal({ picksWithChangedProjections });
                }
            }
        }
    }, [acceptAllProjectionsChanges, data, showLineupUpdateModal, updateBetslipProjections, updatePlayersMarkets]);
};

// This is a debug hook that updates the player projections every 5 seconds for easier testing of live updates
export const useUpdatePicksForDebug = () => {
    const PLAYER_ID = '6686b5344b3d3b49e73627f2';
    const EVENT_ID = '6686a884c4c1ed059c76648e';
    const [data, refetch] = useUpcomingEventsInfoQuery({ variables: { ids: [EVENT_ID] } });

    const [newData, setNewData] = useState<EventInfo[] | undefined>(undefined);

    useEffect(() => {
        if (data.data) {
            const event = data.data.getUpcomingEventsByIdsV2[0];
            if (isTeamEvent(event)) {
                const player =
                    event.teams[0].players.find(p => p.id === PLAYER_ID) ??
                    event.teams[1].players.find(p => p.id === PLAYER_ID);
                if (player) {
                    for (let i = 0; i < player.projections.length; i++) {
                        player.projections[i].value = Math.round(Math.random() * 100) + 0.5;
                        player.projections[i].nonRegularValue = Math.round(Math.random() * 100) + 0.5;
                    }
                } else {
                    console.warn('Player not found in event');
                }
            } else if (isIndividualEvent(event)) {
                const player = event.players.find(p => p.id === PLAYER_ID);
                if (player) {
                    for (let i = 0; i < player.projections.length; i++) {
                        player.projections[i].nonRegularValue = Math.round(Math.random() * 100) + 0.5;
                        player.projections[i].value = Math.round(Math.random() * 100) + 0.5;
                    }
                }
            }
            setNewData([{ ...event }]);
        }
    }, [data.data]);

    useUpdatePlayerStoreWithNewData(newData);

    useEffect(() => {
        const sub = setInterval(() => {
            refetch({ requestPolicy: 'network-only' });
        }, 7000);
        return () => clearInterval(sub);
    }, [refetch]);
};
