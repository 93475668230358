import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScrollableScreen } from '@/components/ScrollableScreen';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { YearEndStatementRow } from '../components/YearEndStatementRow';
import { useYearEndStatement } from '../hooks/use-year-end-statement';

export const YearEndStatementScreen = () => {
    const { t } = useTranslation('year_end_statement');
    const { data } = useYearEndStatement();

    const yearEndStatementData = [
        {
            leftLabel: t('balance_beginning'),
            rightLabel:
                data?.start_of_year_balance !== undefined ? `${toLocaleCurrency(data.start_of_year_balance)}` : '',
        },
        {
            leftLabel: t('balance_end'),
            rightLabel: data?.end_of_year_balance !== undefined ? `${toLocaleCurrency(data.end_of_year_balance)}` : '',
        },
        {
            leftLabel: t('amount_deposited'),
            rightLabel:
                data?.total_amount_deposited !== undefined ? `${toLocaleCurrency(data.total_amount_deposited)}` : '',
        },
        {
            leftLabel: t('amount_played'),
            rightLabel:
                data?.total_amount_wagered !== undefined ? `${toLocaleCurrency(data.total_amount_wagered)}` : '',
        },
        {
            leftLabel: t('amount_won'),
            rightLabel: data?.total_winnings !== undefined ? `${toLocaleCurrency(data.total_winnings)}` : '',
        },
        {
            leftLabel: t('amount_withdrawn'),
            rightLabel:
                data?.total_successful_withdrawals !== undefined
                    ? `${toLocaleCurrency(data.total_successful_withdrawals)}`
                    : '',
        },
        {
            leftLabel: t('total_number_of_bets'),
            rightLabel: data?.number_of_wagers !== undefined ? `${data.number_of_wagers}` : '',
        },
        {
            leftLabel: t('total_number_of_bets_won'),
            rightLabel: data?.winning_wagers_count !== undefined ? `${data.winning_wagers_count}` : '',
        },
    ];

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('year_end_statement') }}
            screenProps={{ edges: ['top', 'bottom'] }}
        >
            <MaxWidthWrapper paddingHorizontal={'s16'}>
                <Row mt="s8" mb="s12">
                    <Text variant="headlineMedium">{t('year_end_date_range')}</Text>
                </Row>
                {yearEndStatementData.map((item, index) => (
                    <YearEndStatementRow key={index} leftLabel={item.leftLabel} rightLabel={item.rightLabel} />
                ))}
                <Row mt="s12">
                    <Text variant="bodySmall" fontStyle="italic">
                        {t('year_end_note')}
                    </Text>
                </Row>
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
