import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';

import { UserLimitType } from '../hooks/types';
import { confirmationLimitsSelector, useUserLimitConfirmations } from '../hooks/use-user-limit-confirmations';
import { displayedPendingLimitsSelector, useUserLimits } from '../hooks/use-user-limits';
import { PendingLimitCard } from './PendingLimitCard';

type PendingLimitsProps = {
    type: UserLimitType;
};

export const PendingLimits = ({ type }: PendingLimitsProps) => {
    const { t } = useTranslation('rg');

    const { data: confirmationLimits } = useUserLimitConfirmations({ select: confirmationLimitsSelector(type) });
    const { data: pendingLimits } = useUserLimits({
        select: displayedPendingLimitsSelector(type, confirmationLimits),
    });

    return (
        <View>
            <Text fontSize={22} fontWeight="bold" lineHeight={32}>
                {t('pending_limits')}
            </Text>
            <SizedBox value={8} />
            <Text fontSize={13} color={'gray3'} lineHeight={20} testID="pendingLimitsText">
                {t('pending_limit_info')}
            </Text>
            <SizedBox value={20} />
            {confirmationLimits?.map(limit => (
                <PendingLimitCard key={limit.user_limit_id} limit={limit} type={type} />
            ))}
            {pendingLimits?.map((limit, i) => (
                <PendingLimitCard key={`pending-limits-${i}`} limit={limit} type={type} />
            ))}
        </View>
    );
};
