import { CONSTANTS, URLS } from '@/data/config';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { useDebounce } from './use-debounce';
import { useGooglePlaceSessionTokenStore } from './use-google-place-session-token';

const PredictionSchema = z.object({
    description: z.string(),
    place_id: z.string(),
});

/**
 * Prediction's type returned from Google Places Autocomplete API
 * https://developers.google.com/places/web-service/autocomplete#place_autocomplete_results
 */
export type PredictionType = z.infer<typeof PredictionSchema>;

/**
 * A callback function that is called when the text is changed.
 * by sending a request to the Google Places API.
 * API details: https://developers.google.com/maps/documentation/places/web-service/autocomplete
 */
const fetchOnChangeText = async (searchTerm: string, sessionToken: string) => {
    let predictions: PredictionType[] = [];
    if (searchTerm?.trim() === '') {
        return [];
    }

    // CORS implemented by the Google Places API prevent using this library directly on the web. We are using the proxy server to fetch the data.
    const apiUrl = isWeb
        ? `${URLS.PROXY_SERVER_URL}/autocomplete?input=${searchTerm}&components=country:us&sessiontoken=${sessionToken}&key=${CONSTANTS.GOOGLE_PLACES_API_KEY}`
        : `${URLS.GOOGLE_PLACES_API_BASE_URL}/place/autocomplete/json?input=${searchTerm}&components=country:us&sessiontoken=${sessionToken}&key=${CONSTANTS.GOOGLE_PLACES_API_KEY}`;
    try {
        const data = await (
            await fetch(apiUrl, {
                method: 'POST',
            })
        ).json();

        if (data) {
            predictions = data.predictions;
        }
    } catch (e) {
        logger.error('google place autocomplete error', e);
    }
    return predictions;
};

/**
 * It returns a query object that will fetch the data from the API when the searchTerm changes
 * @param {string} searchTerm - The search term that we want to use to fetch the predictions.
 * @returns Use Query Result is being returned.
 */
export const useGetGooglePlacePredictions = (searchTerm: string): UseQueryResult<PredictionType[]> => {
    // Getting current stored token
    const sessionToken = useGooglePlaceSessionTokenStore(state => state.sessionToken);

    /* Delaying the execution of the function by 800ms. */
    const debouncedSearchValue = useDebounce(800, searchTerm);
    return useQuery(['google', debouncedSearchValue], () => fetchOnChangeText(debouncedSearchValue, sessionToken), {});
};
