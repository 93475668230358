import { NavigationProp } from '@react-navigation/native';

import { UserLimit } from '@/feature/responsible-gaming/hooks/types';
import { RootStackParamList } from '@/navigation/types';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { AppFeatureFlags } from '@/utils/parse-prismic-content';
import { TFunction } from 'i18next';

import { EVENT_TYPES } from '../analytics/constants';
import { PaymentMethodTypes } from './const';
import { PaymentMethodType } from './hooks/use-deposit-payment-methods';

export const PAYMENT_METHOD_NAME: Record<PaymentMethodType, string> = {
    [PaymentMethodTypes.PaysafePaypal]: 'PayPal',
    [PaymentMethodTypes.PaysafeMazooma]: 'Plaid',
    [PaymentMethodTypes.Paysafe]: 'Debit card',
    [PaymentMethodTypes.PaysafePayByBank]: 'Pay By Bank',
};

export enum PayPalStatus {
    SUCCESS = 'paypal_success',
    ERROR = 'paypal_error',
    CARD_NOT_SUPPORTED_ERROR = 'paypal_card_not_supported_error',
    WEBVIEW_ERROR = 'paypal_webview_error', // this is a special case where the webview has an error
    DEFAULT = 'paypal_default',
}

/**
 * Function to generate the appropriate error messages based on the provided deposit 'amount' and deposit limits 'data'.
 * It checks if the provided amount is valid, below the minimum deposit amount, above the maximum deposit amount, or if it exceeds daily, weekly, or monthly limits.
 */
export const getDepositErrorMessage = (
    depositLimit: number,
    amountInput: string,
    data: UserLimit[] = [],
    minimumDeposit: string
): string => {
    const amount = parseFloat(amountInput);
    const parsedMinimumDeposit = parseFloat(minimumDeposit);

    if (amount < parsedMinimumDeposit) {
        return `The minimum deposit amount is $${minimumDeposit}.`;
    }

    // if data is empty array, only validate against DEPOSIT_LIMIT
    if (data.length === 0 && amount > depositLimit) {
        return `The maximum single deposit amount is ${toLocaleCurrency(depositLimit)}`;
    }

    const timeFrames = ['DAY', 'WEEK', 'MONTH'];
    const frameDescription: { [key: string]: string } = {
        DAY: 'daily',
        WEEK: 'weekly',
        MONTH: 'monthly',
    };

    for (const frame of timeFrames) {
        const limitData = data.find((d: UserLimit) => d.duration === frame);
        const isLimitSet = limitData && limitData.amount > 0;
        const isLimitExceeded = isLimitSet && amount + limitData.current_state > limitData.amount;
        if (isLimitExceeded) {
            return `The amount is over your ${frameDescription[frame]} deposit limit of ${toLocaleCurrency(
                limitData.amount
            )}.`;
        }
    }

    return '';
};

export type PaymentProviderHandler = {
    regex: RegExp;
    statuses: string[];
    onSuccess: (currentStatus: string) => void;
    onDefault: () => void;
};

/**
 * Creates a dictionary of payment provider handlers. Each handler is configured with
 * its own regex, statuses, and success/default navigation behaviours.
 * This allows dynamic handling of WebView's navigation state changes based on the selected payment provider.
 *
 * @param navigation - Navigation object from '@react-navigation/native'.
 * @param selectedAmount - The selected amount for deposit.
 *
 * @returns {Record<string, PaymentProviderHandler>} A dictionary where keys are payment provider names and values are their respective handlers.
 */
export const getPaymentProviderHandlers = (
    navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>,
    paymentProvider: PaymentMethodType,
    selectedAmount: string
): Record<string, PaymentProviderHandler> => ({
    paysafe: {
        regex: /\/([^/]*)$/,
        statuses: [PayPalStatus.SUCCESS, PayPalStatus.ERROR],
        onSuccess: currentStatus => {
            if (currentStatus === PayPalStatus.SUCCESS) {
                navigation.navigate('SuccessfulDepositModal', {
                    paymentProvider,
                    selectedAmount,
                });
            } else {
                navigation.navigate('FailedDepositModal', {
                    paymentProvider,
                    selectedAmount,
                });
            }
        },
        onDefault: () => {
            navigation.goBack();
        },
    },
});

export const getFormattedDepositAmount = (amount: string) => {
    if (isNaN(parseFloat(amount)) || amount === '') {
        return '0.00';
    }
    return parseFloat(amount).toFixed(2);
};

export const getDepositStatus = (status: string) => (status === PayPalStatus.SUCCESS ? 'Success' : 'Failed');

export const constructFTDPayload = (paymentProvider: string, currentStatus: string, depositAmount: string) => ({
    depositAmount,
    paymentProvider,
    ...(currentStatus === PayPalStatus.SUCCESS && { revenue: depositAmount }),
    eventType: EVENT_TYPES.FTD,
});

export type PaymentMethodsTranslationKeys =
    | PaymentMethodTypes.PaysafeMazooma
    | PaymentMethodTypes.PaysafePaypal
    | PaymentMethodTypes.PaysafePayByBank;

/**
 * Filters the provided options and returns a new object that only includes options where `enabled` is true.
 *
 * @param options - The options to filter
 * @returns A new object that only includes the options from the `options` parameter where `enabled` is true.
 */
export const getAvailableDepositSalvageOptions = (options: AppFeatureFlags): AppFeatureFlags => {
    if (!options) {
        return {};
    }
    return Object.keys(options)
        .filter(key => options[key].enabled)
        .reduce((result, key) => {
            result[key] = options[key];
            return result;
        }, {} as AppFeatureFlags);
};

/**
 * Generates a subtitle for DepositSalvage screen based on the provided keys.
 *
 * @param t - The translation function.
 * @param depositSalvageOptions - The object to be used for generating the subtitle.
 * @returns The generated subtitle.
 */
export const getDepositSalvageScreenSubtitle = (
    t: TFunction<['wallet', 'common'], undefined>,
    depositSalvageOptions: AppFeatureFlags
): string | undefined => {
    const enabledOptions = Object.values(depositSalvageOptions).filter(option => option.enabled);
    return enabledOptions?.[0]?.description ?? t('salvage_screen_subtitle_multiple_options', { ns: 'wallet' });
};
