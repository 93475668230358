import { formatCommaSeparatedNumber } from '@/utils/currency-num-pad-utils';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { renderDate } from '@/utils/renderDate';

import { BetSlipEvent } from '../types';

export const formatEventHeader = (event: BetSlipEvent) =>
    `${formatEventTeams(event)} • ${renderDate(new Date(event.start_time))}`;

export const formatEventTeams = (event: BetSlipEvent) =>
    `${event.away_team.short_code} @ ${event.home_team.short_code}`;

export const formatStakeInput = (stake: string) => {
    if (!stake || stake === '0') {
        return '$';
    }
    return `$${formatCommaSeparatedNumber(stake)}`;
};

export const fromPayoutInput = (number: number) => {
    if (!number) {
        return '$';
    }
    return toLocaleCurrency(number);
};
