import { URLS } from '@/data';
import { createJurisdictionHeaders } from '@/data';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { BetIds, isComboSelectionEnabled } from '@/feature/betslip-sbk/utils/betslip-utils';
import { useQuery } from '@tanstack/react-query';

type GetBetLimitPayload = {
    market_ids: string[];
};

type GetBetLimitResponse = {
    data: {
        limit: number;
    };
};

/**
 * Fetches the stake limit for a list of market ids
 * GET /auth/bet-stake-limit
 * @param payload - the market ids to fetch the stake limit for
 */
const getBetStakeLimit = async (payload: GetBetLimitPayload): Promise<GetBetLimitResponse> => {
    const queryParams = new URLSearchParams();
    queryParams.append('market_ids', payload.market_ids.join(','));

    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/bet-stake-limit?${queryParams.toString()}`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });

    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return data;
};

export const betStakeLimitKeys = {
    all: ['bet-stake-limit'] as const,
    bet: (payload: GetBetLimitPayload) => [...betStakeLimitKeys.all, payload] as const,
};

/**
 * Custom react-query hook to fetch the stake limit for a bet
 * @param betId - the id of the bet
 * @param enabled - whether the query should be enabled
 */
export const useBetStakeLimit = (betId: string, enabled = false) => {
    const payload = useSbkBetSlipStore(state => {
        if (betId === BetIds.COMBO) {
            return {
                market_ids: Object.values(state.selections)
                    .filter(selection => isComboSelectionEnabled(selection, state))
                    .map(selection => selection.marketId)
                    .sort(),
            };
        }
        if (!state.selections[betId]) {
            return { market_ids: [] };
        }
        return { market_ids: [state.selections[betId]?.marketId] };
    });
    return useQuery(betStakeLimitKeys.bet(payload), () => getBetStakeLimit(payload), {
        enabled: !!betId && !!payload.market_ids.length && enabled,
        select: data => data?.data.limit,
    });
};
