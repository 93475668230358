export const getMax3DigitNumber = (currency: string, amount?: number): string => {
    if (!amount) {
        return `${currency.substring(0, 1)}0.00`;
    }

    let finalNumber: string | number;
    if (amount < 100) {
        finalNumber = Math.floor(amount * 10 * 10) / 100;
        const hasNoDecimals = finalNumber === Math.floor(finalNumber);
        if (hasNoDecimals) {
            finalNumber = `${finalNumber}.00`;
        } else {
            const hasOneDecimal = Number(finalNumber) * 10 === Math.floor(Number(finalNumber) * 10);
            if (hasOneDecimal) {
                finalNumber = `${finalNumber}0`;
            }
        }
    } else if (amount < 1000) {
        finalNumber = Math.floor(amount);
    } else {
        //ammount > 999
        finalNumber = `${Math.trunc(amount / 100) / 10}k`;
    }
    return `${currency.substring(0, 1)}${finalNumber}`;
};

/**
 * Display currency with commas and cents by condition
 *
 * @param value usd value
 * @param hideZeroCents
 * @param max3Digits short version number maximum 3 digits
 */
export const toLocaleCurrency = (
    value?: number | string,
    hideZeroCents: boolean = true,
    max3Digits: boolean = false
): string => {
    let amount = typeof value === 'string' ? parseFloat(value) : value;

    if (typeof amount !== 'number' || isNaN(amount)) {
        amount = 0;
    }

    let strCurrency = amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    if (hideZeroCents) {
        strCurrency = strCurrency.replace('.00', '');
    }

    if (max3Digits) {
        strCurrency = getMax3DigitNumber(strCurrency, amount);
    }

    return strCurrency;
};
