import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Column } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import { CurrencySwitcher } from '@/feature/betslip-pickem/components/CurrencySwitcher';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { getGameType } from '@/feature/entries-pickem/entries-utils';
import { useQuickAmounts } from '@/feature/quick-amounts/hooks/use-quick-amounts';
import { QuickButton } from '@/feature/quick-amounts/hooks/use-user-quick-amounts-store';
import { useWallet } from '@/hooks/use-wallet';
import { designSystem, withOpacity } from '@/styles/styles';
import { Currency, GameMode, GameType } from '@/types/api.generated';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

export type QuickAmountsProps = {
    gameMode: GameMode;
    gameType: GameType;
};

export type QuickAmountsModalRef = {
    show: (data: QuickAmountsProps) => void;
    dismiss: () => void;
};

const modalId = 'quickAmountsModal';

export const QuickAmountsModal = React.forwardRef<QuickAmountsModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<QuickAmountsProps | null>(null);

    useImperativeHandle(ref, () => ({
        show: modalProps => {
            setData(modalProps);
            modalRef.current?.present();
        },
        dismiss: () => {
            setData(null);
            modalRef.current?.dismiss();
        },
    }));

    const modalRef = useRef<BottomSheetModal>(null);

    return (
        <Modal id={modalId} modalRef={modalRef}>
            {data ? <ModalContent {...data} dismissModal={() => modalRef.current?.dismiss()} /> : null}
        </Modal>
    );
});

const ModalContent = ({ gameMode, gameType, dismissModal }: QuickAmountsProps & { dismissModal: () => void }) => {
    const navigation = useNavigation();
    const { t } = useTranslation(['common', 'betslip_pickem']);
    const validationData = useBetslipStore(state => state.validationData[gameMode]);
    const plays = useBetslipStore(state => state.plays);
    const { betrBucks, realMoneyTotal } = useWallet();

    const maxAllowedEntryAmount = validationData.amountValidation?.maxAllowedEntryAmount;
    const [selectedCurrency, setSelectedCurrency] = useState<Currency>(plays[gameMode].currency);
    const setCurrency = useBetslipStore(state => state.actions.setCurrency);

    const usingUsd = selectedCurrency === Currency.Usd;

    const shouldShowCurrencySwitcher = betrBucks && betrBucks !== 0;

    const maxValueWithBalance = usingUsd
        ? Math.min(maxAllowedEntryAmount, realMoneyTotal)
        : Math.min(maxAllowedEntryAmount, betrBucks);

    const showMaxAmount = maxValueWithBalance > 0;

    const setEntryAmount = useBetslipStore(state => state.actions.setEntryAmount);

    const maxAmount = `${t('betslip_pickem:max_entry_amount')}: ${toLocaleCurrency(maxAllowedEntryAmount, false)}`;

    const quickAmounts = useQuickAmounts();

    const currencies = useMemo(
        () => [
            { currency: Currency.Usd, amount: realMoneyTotal, title: 'Cash' },
            { currency: Currency.Fre, amount: betrBucks, title: 'Betr Bucks' },
        ],
        [betrBucks, realMoneyTotal]
    );

    const quickAmountsButtons: QuickButton[] = [
        ...quickAmounts,
        { id: 'MAX', value: maxValueWithBalance },
        { id: 'CUSTOM', value: 0 },
    ];

    const handlePressQuickAmount = (quickButtonData: QuickButton) => {
        if (quickButtonData.id === 'CUSTOM') {
            dismissModal();
            navigation.navigate('EntryInputAmount', { gameMode, gameType });

            return;
        }

        setCurrency(selectedCurrency, gameMode);

        if (quickButtonData.id === 'MAX') {
            setEntryAmount(maxValueWithBalance, gameMode);
            dismissModal();
            return;
        }

        if (quickButtonData.value) {
            setEntryAmount(quickButtonData.value, gameMode);
            dismissModal();
        }
    };

    const navigateEditQuickAmounts = () => {
        dismissModal();
        navigation.navigate('EditQuickAmounts');
    };

    return (
        <Column paddingHorizontal={'s16'}>
            <Box justifyContent={'center'} alignItems={'center'} paddingVertical={'s10'}>
                <Text variant={'titleLarge'}>{getGameType(t, gameMode)}</Text>
                {showMaxAmount ? (
                    <Text variant="bodySmall" color="gray2">
                        {maxAmount}
                    </Text>
                ) : null}
            </Box>

            {shouldShowCurrencySwitcher ? (
                <Box paddingVertical="s16">
                    <CurrencySwitcher
                        onCurrencyPress={setSelectedCurrency}
                        activeCurrency={selectedCurrency}
                        currencies={currencies}
                        autoSwitchToNonZero={true}
                        switchColor="gray4"
                    />
                </Box>
            ) : null}

            <Box paddingVertical={'s16'}>
                <FlatList
                    scrollEnabled={false}
                    data={quickAmountsButtons}
                    numColumns={3}
                    ItemSeparatorComponent={ItemSeparatorComponent}
                    columnWrapperStyle={styles.itemGap}
                    renderItem={({ item }) => {
                        // if we are using betrBucks, the max value is the one which also checks the balance
                        // if we are using real money, the max value is the max allowed entry value
                        // since we want to show "balance error" if the user tries to enter more than the balance
                        // so we can redirect the user to the balance
                        const isDisabled = isQuickButtonDisabled(
                            item,
                            usingUsd ? maxAllowedEntryAmount : maxValueWithBalance
                        );
                        return (
                            <Button
                                label={getQuickButtonLabel(item)}
                                variant="light"
                                size="m"
                                hierarchy="secondary"
                                flex={1}
                                disabled={isDisabled}
                                onPress={() => handlePressQuickAmount(item)}
                                disableTextStyle={{ color: withOpacity(designSystem.colors.white, 0.15) }}
                                disableContainerStyle={{
                                    backgroundColor: withOpacity(designSystem.colors.white, 0.03),
                                }}
                            />
                        );
                    }}
                />
            </Box>

            <Box paddingBottom={'s4'}>
                <Button label={t('betslip_pickem:quick_amounts_edit_amounts')} onPress={navigateEditQuickAmounts} />
            </Box>
        </Column>
    );
};

const getQuickButtonLabel = (quickButtonData: QuickButton) => {
    if (quickButtonData.id === 'MAX') {
        return 'Max';
    }
    if (quickButtonData.id === 'CUSTOM') {
        return '•••';
    }
    return `$${quickButtonData.value}`;
};

const isQuickButtonDisabled = (quickButtonData: QuickButton, maxAllowedEntryAmount: number) => {
    if (quickButtonData.id === 'CUSTOM') {
        return false;
    }
    return (quickButtonData.value ?? 0) > maxAllowedEntryAmount;
};

const ItemSeparatorComponent = () => <SizedBox value={12} />;

const styles = StyleSheet.create({
    itemGap: {
        gap: 12,
    },
});
