import React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { FastImageProps } from 'react-native-fast-image';

import { LinearGradient } from '@/components/linear-gradient/LinearGradient';
import { common, designSystem } from '@/styles/styles';

import { Image } from './lib/components';

type Props = FastImageProps & {
    gradientColors?: string[];
    gradientStart?: { x: number; y: number };
    gradientEnd?: { x: number; y: number };
};

export const ImageWithGradient: React.FC<Props> = ({
    gradientColors = ['rgba(0,0,0,0.00)', designSystem.colors.gray8],
    gradientStart = { x: 0, y: 0 },
    gradientEnd = { x: 0, y: 1 },
    ...props
}) => {
    const { width } = useWindowDimensions();
    return (
        <>
            <LinearGradient
                colors={gradientColors}
                start={gradientStart}
                end={gradientEnd}
                height={250}
                width={width}
                style={[styles.gradient, common.zIndex1]}
            />
            <Image {...props} style={styles.img} />
        </>
    );
};

const styles = StyleSheet.create({
    gradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 250,
    },
    img: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 250,
    },
});
