import { useCallback, useMemo } from 'react';

import { useMicroMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-micro-market-cache';
import { useSubscribePublic } from '@/utils/websocket/hooks';
import { NewMarketMessage } from '@/utils/websocket/types';

export const useSubscribeLeagueNewMarket = (leagueId: string, marketGroupId: string, enabled?: boolean) => {
    const handleLeagueNewMarket = useMicroMarketCacheStore(state => state.actions.handleLeagueNewMarket);
    const handleNewMarket = useCallback(
        (message: NewMarketMessage) => {
            handleLeagueNewMarket(message);
        },
        [handleLeagueNewMarket]
    );
    const params = useMemo(() => ({ leagueId, marketGroupId }), [leagueId, marketGroupId]);
    useSubscribePublic<'leagueNewMarket'>(
        'league:{leagueId}:marketGroup:{marketGroupId}',
        params,
        'new_market',
        handleNewMarket,
        enabled
    );
};
