import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';

import { Market, MarketType, Option } from '../../types';
import { AlternateMarketsModal, AlternateMarketsModalRef } from './AlternateMarketsModal';

export type AlternateMarketOptions = {
    eventId: string;
    markets: Array<Market>;
    selectedMarket?: Market;
    sortOptions?: (a: Option, b: Option, marketType?: MarketType) => number;
    marketAppendTeamName?: boolean;
};
type Props = {
    showAlternateMarketsModal: (options: AlternateMarketOptions) => void;
};

const AlternateMarketModalContext = React.createContext<Props>({
    showAlternateMarketsModal: () => {},
});

/**
 * Provides access to the Modal used to display alternate markets
 */
export const AlternateMarketsModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<AlternateMarketsModalRef>(null);

    const showAlternateMarketsModal = useCallback((props: AlternateMarketOptions) => {
        modalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <AlternateMarketModalContext.Provider value={{ showAlternateMarketsModal }}>
                {children}
                <AlternateMarketsModal ref={modalRef} />
            </AlternateMarketModalContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useAlternateMarketsModal = () => {
    return useContext(AlternateMarketModalContext);
};
