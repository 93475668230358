import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Text } from '@/components/TextComponent';
import { useBetslipData } from '@/feature/betslip-pickem/hooks/use-betslip-data';
import { common } from '@/styles/styles';

const styles = StyleSheet.create({
    alignRight: { textAlign: 'right' },
});

interface Props {
    max: number;
    dotSize: number;
    dotPadding: number;
}
export const MultiplierLabels: React.FC<Props> = ({ max, dotSize, dotPadding }) => {
    const { betslip, multipliers } = useBetslipData();
    const dots = new Array(max).fill(0);

    return (
        <View style={[common.spaceBetweenRow, { marginLeft: dotSize, paddingHorizontal: dotPadding }]}>
            {dots.map((_, idx) => {
                if (idx === 0) {
                    return null;
                }
                const multiplier = multipliers[idx] ?? '';
                const isPicked = betslip[idx];
                const isLastPick = idx === betslip.length - 1;
                return (
                    <View key={`multiplier-${idx}`} style={common.flex}>
                        <Text
                            color={isLastPick ? 'gray1' : isPicked ? 'gray4' : 'gray2'}
                            fontWeight={!isLastPick ? '500' : '600'}
                            variant="labelLarge"
                            textDecorationLine={isPicked && !isLastPick ? 'line-through' : 'none'}
                            style={styles.alignRight}
                        >
                            {multiplier}x
                        </Text>
                    </View>
                );
            })}
        </View>
    );
};
