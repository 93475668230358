import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
        <G clipPath="url(#a)">
            <Path
                fill="#fff"
                d="m12.454 6.09.796-1.75L15 3.546l-1.75-.795L12.454 1l-.795 1.75-1.75.795 1.75.796.795 1.75Zm-4.772.32L6.09 2.91 4.5 6.41 1 8l3.5 1.59 1.59 3.5 1.592-3.5 3.5-1.59-3.5-1.59Zm4.772 3.5-.795 1.75-1.75.794 1.75.796.795 1.75.796-1.75 1.75-.796-1.75-.795-.796-1.75Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 0h16v16H0z" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgComponent;
