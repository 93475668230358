import { useMemo } from 'react';

import { useMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { useSubscribePublic } from '@/utils/websocket/hooks';

/**
 * Subscribe to 'league:{leagueId}:marketGroup:{marketGroupId}' channel and listens for `odds_update` messages
 */
export const useSubscribeLeagueOddsUpdate = (leagueId: string, marketGroupId: string, enabled: boolean = true) => {
    const handleOddsUpdate = useMarketCacheStore(state => state.actions.handleOddsUpdate);
    const params = useMemo(() => ({ leagueId, marketGroupId }), [leagueId, marketGroupId]);
    useSubscribePublic<'leagueOddsUpdate'>(
        'league:{leagueId}:marketGroup:{marketGroupId}',
        params,
        'odds_update',
        handleOddsUpdate,
        enabled
    );
};
