import { URLS, createJurisdictionHeaders } from '@/data';
import { MarketCategorySummary, Sport } from '@/feature/event-details-sbk/types';
import { useQuery } from '@tanstack/react-query';

export const leagueKeys = {
    all: ['league'] as const,
    getLeague: (leagueId: string) => [...leagueKeys.all, { leagueId }] as const,
    marketCategories: (leagueId: string, marketCategoryId: string) =>
        [...leagueKeys.getLeague(leagueId), 'market-categories', marketCategoryId] as const,
} as const;

export type League = {
    id: string;
    name: string;
    logo_url: string;
    market_categories: MarketCategorySummary;
    sport: Sport;
    event_count: number;
};

export type GetLeagueResponse = {
    data: League;
};

const getLeague = async (leagueId: string): Promise<GetLeagueResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/leagues/${leagueId}`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return data;
};

export const useLeague = (leagueId: string) => {
    return useQuery(leagueKeys.getLeague(leagueId), () => getLeague(leagueId), {
        select: data => data.data,
    });
};
