import React, { useState } from 'react';
import { Keyboard, StyleSheet, TouchableOpacity, View } from 'react-native';

import { LineSeparator } from '@/components/LineSeparator';
import { Text } from '@/components/TextComponent';
import { TextInput, TextInputProps } from '@/components/TextInput';
import { getPlaceDetails } from '@/hooks/use-google-place-address';
import { PredictionType, useGetGooglePlacePredictions } from '@/hooks/use-google-place-predictions';
import { useGooglePlaceSessionTokenStore } from '@/hooks/use-google-place-session-token';
import { common, designSystem } from '@/styles/styles';

import { AddressFormValues } from '../../hooks/use-kyc-fields';
import { isPOBox } from '../../screens/HomeAddress/HomeAddressStep';
import { AddressError } from './types';

const styles = StyleSheet.create({
    predictionsWrapper: {
        position: 'absolute',
        borderWidth: 2,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderColor: designSystem.colors.gray5,
        borderTopColor: 'transparent',
        backgroundColor: designSystem.colors.gray8,
        marginTop: -5,
        width: '100%',
    },
    errorText: {
        marginTop: 4,
        paddingLeft: 16,
        color: designSystem.colors.red,
    },
    separator: {
        height: 0.5,
        borderBottomColor: designSystem.colors.gray5,
        marginVertical: 16,
        width: '100%',
    },
    ellipsis: {
        width: '100%',
        overflow: 'hidden',
    },
});

type AddressAutoCompleteProps = TextInputProps & {
    fieldKey: keyof AddressFormValues;
    setFieldValue: (name: keyof AddressFormValues, value: string) => void;
    onChangeText?: () => void;
    handleSelectedPlace?: (addressDetails: AddressFormValues) => void;
    addressError: AddressError | null;
};

/**
 * AddressAutoComplete component
 * Provides an input field with autocomplete functionality for addresses
 */
export const AddressAutoComplete = ({
    setFieldValue,
    handleSelectedPlace,
    fieldKey,
    addressError,
    ...props
}: AddressAutoCompleteProps) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const { data: fetchedPredictions = [] } = useGetGooglePlacePredictions(searchTerm);
    const sessionTokenStore = useGooglePlaceSessionTokenStore(state => state);

    /**
     * Fetches place details and handles PO Box detection
     * @param placeId - The ID of the selected place
     */
    const onGetPlaceDetails = async (placeId: string) => {
        const placeDetails = await getPlaceDetails(placeId, sessionTokenStore);

        if (Object.keys(placeDetails).length) {
            handleSelectedPlace?.(placeDetails);
        }
    };

    /**
     * Handles text change in the input field for address autocomplete
     * @param _masked - The masked input value (visible to the user)
     * @param unmasked - The unmasked input value (raw input)
     */
    const handleChangeText = (_masked: string, unmasked: string) => {
        // Update the search term with the masked value for display purposes
        setSearchTerm(_masked);

        // Update the form field with the unmasked value for data consistency
        setFieldValue(fieldKey, unmasked);

        // Show the dropdown only if there's any input
        setDropdownVisible(unmasked.length > 0);
    };

    /**
     * Handles selection of a prediction from the dropdown
     * @param prediction - The selected prediction
     */
    const onSelectPrediction = (prediction: PredictionType) => {
        setDropdownVisible(false);
        onGetPlaceDetails(prediction.place_id);
        Keyboard.dismiss();
    };

    const handleBlur = () => {
        // prevent race condition between onBlur and onPress
        setTimeout(() => {
            setDropdownVisible(false);
        }, 300);
    };

    // Filter out PO Box predictions
    const filteredPredictions = fetchedPredictions.filter(prediction => !isPOBox(prediction.description));

    // Determine whether to show the dropdown
    const showDropdown = dropdownVisible && filteredPredictions.length > 0 && !addressError;

    return (
        <View style={common.zIndex1}>
            <TextInput
                {...props}
                onBlur={e => {
                    handleBlur();
                    props?.onBlur?.(e);
                }}
                onChangeText={handleChangeText}
                autoCorrect={false}
            />
            {addressError && <Text style={styles.errorText}>{addressError.message}</Text>}
            {showDropdown ? (
                <View style={common.zIndexNegative1}>
                    <View style={[styles.predictionsWrapper, common.paddingHorizontal, common.paddingVertical]}>
                        {filteredPredictions?.map((prediction, idx) => (
                            <TouchableOpacity
                                key={`prediction-${prediction.place_id}-${idx}`}
                                activeOpacity={0.5}
                                onPress={() => onSelectPrediction(prediction)}
                                style={common.full}
                            >
                                {idx !== 0 ? <LineSeparator style={styles.separator} /> : null}
                                <Text
                                    style={styles.ellipsis}
                                    numberOfLines={1}
                                    fontSize={15}
                                    letterSpacing={-0.23}
                                    color={'gray2'}
                                >
                                    {prediction.description}
                                </Text>
                            </TouchableOpacity>
                        ))}
                        <TouchableOpacity
                            activeOpacity={0.5}
                            onPress={() => setDropdownVisible(false)}
                            style={common.full}
                        >
                            <LineSeparator style={styles.separator} />
                            <Text
                                style={styles.ellipsis}
                                numberOfLines={1}
                                textAlign={'center'}
                                fontSize={14}
                                fontWeight="bold"
                                letterSpacing={-0.23}
                            >
                                Dismiss Search Results
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            ) : null}
        </View>
    );
};
