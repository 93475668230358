import { useTranslation } from 'react-i18next';

import { activeLimitsSelector, useUserLimits } from '@/feature/responsible-gaming/hooks/use-user-limits';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { StakeInputErrors } from '../types';
import { stakeInputValidators } from '../utils/validators';
import { MAX_BET_STAKE, MIN_BET_STAKE } from '../utils/validators';
import { useSbkBetSlipStore } from './use-sbk-betslip-store';

/**
 * Returns the error message for the stake input
 */
export const useStakeInputErrorMessage = (betId: string) => {
    const { data: limits = [] } = useUserLimits({ select: activeLimitsSelector('SINGLE_WAGER') });
    const { t } = useTranslation(['betslip_sbk']);

    return useSbkBetSlipStore(state => {
        const error = state.bets[betId]?.stakeInputError;
        if (error) {
            const key = stakeInputValidators[error]?.translationKey;
            switch (error) {
                case StakeInputErrors.ExceedSingleWalletBalance:
                    return t(key);
                case StakeInputErrors.BelowMin:
                    return t(key, { value: toLocaleCurrency(MIN_BET_STAKE) });
                case StakeInputErrors.AboveMax:
                    return t(key, { value: toLocaleCurrency(MAX_BET_STAKE) });
                case StakeInputErrors.ExceedMaxSingleWager:
                    const maxSingleEntryLimit = limits.find(limit => limit.type === 'SINGLE_WAGER');
                    if (maxSingleEntryLimit) {
                        return t(key, { value: toLocaleCurrency(maxSingleEntryLimit.amount) });
                    }
            }
        }
    });
};
