import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import CheckmarkLargeWhite from '@/assets/icons/checkmark-large-white';
import { Button } from '@/components/ButtonComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { ResultComponent } from '../components/ResultComponent';
import { WithdrawalFrame } from '../components/WithdrawalFrame';

type NavigationSuccessfulWithdrawalProps = NativeStackScreenProps<RootStackParamList, 'SuccessfulWithdrawal'>;

export const SuccessfulWithdrawal = ({
    route: {
        params: { amountToWithdraw, methodType },
    },
}: NavigationSuccessfulWithdrawalProps) => {
    const navigation = useNavigation();
    const navigateToLobby = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.WITHDRAW_SUCCESS_DONE, { amountToWithdraw, methodType });
        navigateHome(navigation, 'lobby');
    };

    const onClose = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.WITHDRAW_SUCCESS_ABORT, { amountToWithdraw, methodType });
        navigateHome(navigation, 'account');
    };
    return (
        <WithdrawalFrame showTitle={false} onClose={onClose}>
            <MaxWidthWrapper flex={1}>
                <ResultComponent
                    textTitle="Withdraw requested"
                    textDescription={`${toLocaleCurrency(
                        amountToWithdraw
                    )} has been taken out of your betr wallet. Please reference your transaction history for updates and allow 24-72 hours for funds to show up.`}
                    resultIcon={<CheckmarkLargeWhite />}
                    footerItems={<Button label="Done" type="active" variant="rounded" onPress={navigateToLobby} />}
                />
            </MaxWidthWrapper>
        </WithdrawalFrame>
    );
};
