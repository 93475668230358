import * as prismic from '@prismicio/client';

export const prismicClient = prismic.createClient('pitchtech');

export const isDocumentFilled = (
    arg?: prismic.ContentRelationshipField
): arg is prismic.FilledContentRelationshipField => {
    if (arg?.link_type === 'Document' && 'id' in arg) {
        return true;
    }
    return false;
};
