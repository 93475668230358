import { League, PlayerAttribute, PlayerAttributesType } from '@/types/api.generated';
import { findPlayerAttribute } from '@/utils/fantasy-attribute-utils';
import { isCollegeSport, isPGA } from '@/utils/league';

export const getPlayerArcDetails = (
    player?: { attributes?: PlayerAttribute[] },
    league?: League,
    team?: { largeIcon: string; name?: string } | null,
    shouldReturnCountry?: boolean
) => {
    // ! for phase 1 PGA doesn't have any background, but we need this logic for the ShareEntry (to show the country image)
    const countryIcon = shouldReturnCountry ? findPlayerAttribute(player, PlayerAttributesType.CountryIcon) : undefined;
    const countryText = findPlayerAttribute(player, PlayerAttributesType.CountryCode);

    return {
        teamLogo: team?.largeIcon || countryIcon,
        arcText: !shouldReturnCountry && isPGA(league) ? countryText : isCollegeSport(league) ? team?.name : undefined,
    };
};
