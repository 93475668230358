import React from 'react';
import { ActivityIndicator } from 'react-native';

import { Box } from '@/components/lib/components';

type LoadingBoxProps = React.ComponentProps<typeof Box>;

export const LoadingBox = (props: LoadingBoxProps) => {
    return (
        <Box {...props} mt="s24">
            {/* TODO: This is a temporary solution before Skeleton Animations design completed.
            LoadingDots Component(https://github.com/betrapp/pickem/blob/4b64047b4d3fe5215ee5c45b5a3a38839eca7cab/src/components/Loading.tsx#L21),
            and react-native-loading-dots package can be removed if decided to not using it for both SBK and Pickem!
            */}
            <ActivityIndicator size="large" />
        </Box>
    );
};
