import { useMemo } from 'react';

import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { useSubscribePublic } from '@/utils/websocket/hooks';

export const useSubscribeEventMatchUpdate = (eventId: string) => {
    const handleMatchUpdate = useEventDetailsCache(state => state.actions.handleMatchUpdate);
    const params = useMemo(() => ({ eventId }), [eventId]);
    useSubscribePublic<'eventMatchUpdate'>('event:{eventId}', params, 'match_update', handleMatchUpdate);
};
