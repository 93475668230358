import { URLS, createJurisdictionHeaders } from '@/data';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

/**
 * Enums
 */
export const FailedTransactionEnum = z.enum([
    'Unknown Error',
    'payment_incomplete',
    'payment_invalid_address',
    'payment_handle_error',
    'payment_expired',
    'payment_failed',
    'payment_type_error',
    'payment_error',
    'invalid_status',
    'account_already_used',
    'wrong_account',
    'missing_customer',
    'withdrawal_error',
    'withdrawal_expired',
    'withdrawal_failed',
    'withdrawal_invalid_status',
    'withdrawal_cancelled',
    'withdrawal_invalid_address',
    'bo_rejected',
]);
export type FailedTransactionType = z.infer<typeof FailedTransactionEnum>;

export const TransactionActionEnum = z.enum(['withdrawal', 'deposit', 'debit', 'credit', 'bonus', 'fre_bonus']);
export type TransactionActionType = z.infer<typeof TransactionActionEnum>;

export const TransactionStatusEnum = z.enum([
    'PAYMENT_CREATED',
    'PAYMENT_PENDING',
    'PAYMENT_PENDING_VERIFICATION',
    'PAYMENT_REVERSED',
    'PAYMENT_ABORTED',
    'PAYMENT_EXPIRED',
    'PAYMENT_DECLINED',
    'PAYMENT_CANCELLED',
    'WITHDRAWAL',
    'CREDIT',
    'DEBIT',
    'PAYMENT_CONFIRMED',
    'WITHDRAWAL_ATTEMPT',
    'WITHDRAWAL_CANCELLED',
    'WITHDRAWAL_FAILED',
]);
export type TransactionStatus = z.infer<typeof TransactionStatusEnum>;

export const TransactionToEnum = z.enum(['Betr Fantasy Wallet', 'Betr Wallet']);
export type TransactionTo = z.infer<typeof TransactionToEnum>;

export const PaymentMethodEnum = z.enum([
    'paysafe',
    'paysafe_mazooma',
    'paysafe_paypal',
    'paysafe_pay_by_bank',
    'paysafe_venmo',
    'trustly',
]);

const TransactionRequestStatusType = z.union([z.enum(['SUCCESS', 'PENDING', 'FAILED']), z.string()]);

export type TransactionRequestStatusType = z.infer<typeof TransactionRequestStatusType>;

const TransactionRequestStatus = z
    .object({
        status: TransactionRequestStatusType,
        completed_at: z.number().nullable(),
    })
    .optional();

/**
 * Transaction
 */
const TransactionSchema = z.object({
    user_wallet_id: z.string(),
    transaction_id: z.string().nullable(),
    action: TransactionActionEnum,
    to: TransactionToEnum,
    created_at: z.number(),
    initiated: z.number(),
    status: TransactionStatusEnum,
    updated_at: z.number(),
    before_balance: z.string().nullable(),
    after_balance: z.string().nullable(),
    amount: z.number(),
    payment_method: PaymentMethodEnum.nullable(),
    failed_reason: FailedTransactionEnum.nullable(),
    daily_deposit_limit_after: z.number().nullable(),
    weekly_deposit_limit_after: z.number().nullable(),
    monthly_deposit_limit_after: z.number().nullable(),
    cc_type: z.string().nullable().optional(),
    cc_last4: z.string().nullable().optional(),
    email: z.string().nullable().optional(),
    currency: z.string().nullable().optional(),
    status_events: z.object({
        requested: TransactionRequestStatus,
        reviewed: TransactionRequestStatus,
        complete: TransactionRequestStatus,
    }),
    venmo_id: z.string().nullable().optional(),
});
export type Transaction = z.infer<typeof TransactionSchema>;

/**
 * Transaction Details API
 */

const TransactionDetailsResponseSchema = z.object({
    data: TransactionSchema,
});
type TransactionDetailsResponse = z.infer<typeof TransactionDetailsResponseSchema>;

/**
 * Query Keys
 */
const transactionKeys = {
    all: ['transactions'] as const,
    details: (transactionId: string) => [...transactionKeys.all, transactionId] as const,
};

/**
 * Query functions
 */

/**
 * Fetch transaction details and validate response
 * @param userWalletId - User wallet id
 */
const getTransactionDetails = async (userWalletId: string): Promise<TransactionDetailsResponse> => {
    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/transaction/${userWalletId}`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });
    if (!resp.ok) {
        throw await resp.json();
    }
    return TransactionDetailsResponseSchema.parse(await resp.json());
};

/**
 * Custom react-query hook to fetch transaction details
 * GET /auth/wallet/transaction/${userWalletId}
 * @param userWalletId - User wallet id
 */
export const useTransactionDetails = (userWalletId: string) => {
    return useQuery({
        queryKey: transactionKeys.details(userWalletId),
        queryFn: () => getTransactionDetails(userWalletId),
        select: data => data.data,
    });
};
