import { EffectCallback, useCallback, useEffect, useRef } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';

/**
 * Similar to react-navigation.useFocusEffect, but this is also triggered when the app is resumed from background,
 * but ONLY! for the focused screen.
 *
 * This can be used to perform side-effects such as fetching data or subscribing to events.
 * The passed callback should be wrapped in `React.useCallback` to avoid running the effect too often.
 *
 * @param callback Memoized callback containing the effect, should optionally return a cleanup function.
 */
export const useResumeEffect = (callback: EffectCallback) => {
    const { isFocused } = useNavigation();
    useFocusEffect(callback);

    const previousAppState = useRef(AppState.currentState);

    useEffect(() => {
        let cleanup: undefined | void | (() => void);

        const onAppStateChange = (nextAppState: AppState['currentState']) => {
            if (previousAppState.current.match(/inactive|background/) && nextAppState === 'active') {
                if (isFocused()) {
                    cleanup = callback();
                }
            }

            previousAppState.current = nextAppState;
        };
        const appStateListener = AppState.addEventListener('change', onAppStateChange);
        return () => {
            appStateListener?.remove?.();
            cleanup?.();
        };
    }, [callback, isFocused]);
};

/**
 * Similar to the `useResumeEffect` but with the callback the screen focus triggers with a delay.
 * The reason we have this function is for cases where this the above function delays the screen transition.
 * This can be used to perform side-effects such as fetching data
 * @param callback Memoized callback containing the effect, should NOT return a cleanup function.
 * @param delayMs The delay in milliseconds before the callback is invoked.
 */
export const useResumeEffectWithDelay = (callback: EffectCallback, delayMs: number = 0) => {
    const isFocused = useIsFocused();

    useEffect(() => {
        if (isFocused) {
            setTimeout(callback, delayMs);
        }
    }, [isFocused, callback, delayMs]);

    const previousAppState = useRef(AppState.currentState);

    useEffect(() => {
        const onAppStateChange = (nextAppState: AppState['currentState']) => {
            if (previousAppState.current.match(/inactive|background/) && nextAppState === 'active') {
                if (isFocused) {
                    callback();
                }
            }

            previousAppState.current = nextAppState;
        };
        const appStateListener = AppState.addEventListener('change', onAppStateChange);
        return () => {
            appStateListener?.remove?.();
        };
    }, [callback, isFocused]);
};

/**
 * Custom hook that invokes the callback when the app is resumed from background.
 * This is similar to 'useResumeEffect' but it is executed regardless of the focused screen, so can be used outside of the navigation context.
 *
 * @param callback Memoized callback containing the effect, should optionally return a cleanup function.
 */
export const useAppResumeEffect = (callback: EffectCallback) => {
    const previousAppState = useRef(AppState.currentState);

    const onAppStateChange = useCallback(
        (nextAppState: AppStateStatus) => {
            if (previousAppState.current.match(/inactive|background/) && nextAppState === 'active') {
                callback();
            }
            previousAppState.current = nextAppState;
        },
        [callback]
    );

    useEffect(() => {
        const subscription = AppState.addEventListener('change', onAppStateChange);

        return () => subscription.remove();
    }, [onAppStateChange]);
};
