import React, { ReactNode, useCallback, useState } from 'react';
import { FlexStyle, StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';

import { CloseIcon } from '@/assets/icons/close';
import { TipIcon } from '@/assets/icons/tip';
import { common, designSystem } from '@/styles/styles';

import { Text } from './TextComponent';
import { Row } from './lib/components';
import { Box } from './lib/components/Box';

type TooltipProps = {
    children: ReactNode;
    viewStyles?: StyleProp<ViewStyle>;
    tooltipText: string;
    tooltipContentPosition?: StyleProp<FlexStyle>;
    tooltipContentStyles?: StyleProp<ViewStyle>;
    direction?: 'top' | 'bottom';
    initialState?: 'visible' | 'hidden';
    performSideEffectOnClose?: () => Promise<void>;
};

export const Tooltip = ({
    children,
    viewStyles,
    tooltipText,
    tooltipContentPosition,
    tooltipContentStyles,
    direction = 'bottom',
    initialState = 'hidden',
    performSideEffectOnClose,
}: TooltipProps) => {
    const [toggleTooltip, setToggleTooltip] = useState(initialState === 'hidden' ? false : true);

    const handleToggle = useCallback(() => {
        setToggleTooltip(oldValue => !oldValue);
        performSideEffectOnClose?.();
    }, [performSideEffectOnClose]);

    const tipStyles = direction === 'top' ? { top: 98, right: 10, transform: [{ rotate: '180deg' }] } : { left: 216 };

    const defaultContentPosition = direction === 'top' ? { right: 6, bottom: 50 } : { top: 40, right: 6 };

    return (
        <>
            <TouchableOpacity style={[common.alignEnd, viewStyles]} onPress={handleToggle}>
                {children}
            </TouchableOpacity>
            {toggleTooltip ? (
                <Box style={[toolTipStyles.bubbleContainer, defaultContentPosition, tooltipContentPosition]}>
                    <Box style={[toolTipStyles.tipIcon, tipStyles]}>
                        <TipIcon color={designSystem.colors.gray5} />
                    </Box>
                    <Row style={[toolTipStyles.container, tooltipContentStyles]}>
                        <Box flex={1}>
                            <Text variant="bodySmall">{tooltipText}</Text>
                        </Box>
                        <TouchableOpacity onPress={handleToggle}>
                            <CloseIcon height={16} width={16} />
                        </TouchableOpacity>
                    </Row>
                </Box>
            ) : null}
        </>
    );
};

const toolTipStyles = StyleSheet.create({
    bubbleContainer: {
        position: 'absolute',
        zIndex: designSystem.zIndex.zIndex1,
    },
    container: {
        maxWidth: 250,
        minHeight: 72,
        backgroundColor: designSystem.colors.gray5,
        padding: 16,
        borderRadius: 12,
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
    },
    tipIcon: {
        position: 'relative',
    },
});
