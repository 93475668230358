import { NavigationProp } from '@react-navigation/native';

import { RootStackParamList } from '@/navigation/types';
import { logger } from '@/utils/logging';

import { Alerts } from '../alerts/components/AlertsProvider';
import { PaymentMethodTypes } from './const';

export const ADD_DEBIT_CARD_LOG_TAG = '[Add Debit Card]';
export const UNKNOWN_WEBVIEW_MESSAGE =
    'Unknown message sent from webview. You might have to set up a new `MESSAGE_TYPES` and add a handler in the `messageHandlers`.';

type Navigation = Omit<NavigationProp<RootStackParamList>, 'getState'>;

export const MESSAGE_TYPES = {
    SHOW_PICKER: 'showPicker',
    BAD_BIN: 'badBin',
    STATE: 'state',
    DEPOSIT_SUCCESS: 'depositSuccess',
    DEPOSIT_ERROR: 'depositError',
} as const;

export const PAYSAFE_ERROR_TYPES = {
    INIT: 'init',
    GENERIC: 'generic',
    NOT_SUPPORTED: 'notSupported',
} as const;

export type MessageType = (typeof MESSAGE_TYPES)[keyof typeof MESSAGE_TYPES];
export type PaysafeErrorType = (typeof PAYSAFE_ERROR_TYPES)[keyof typeof PAYSAFE_ERROR_TYPES];

/**
 * Shows an InfoSheet to the user telling them that their bank does not accept gaming transactions and show an alternative payment method.
 */
export const handleBadBinMessage = (
    showInfoSheet: Alerts['showInfoSheet'],
    navigation: Navigation,
    selectedAmount: string
) => {
    showInfoSheet({
        title: 'This bank does not accept gaming transactions',
        description: 'Try using PayPal as an alternative or dismiss and use a different debit card.',
        buttonLabel: 'Deposit with PayPal',
        handlePress: () => {
            navigation.navigate('DepositMethodWebViewModal', {
                selectedAmount,
                selectedPaymentMethodType: PaymentMethodTypes.PaysafePaypal,
            });
        },
        secondaryLabel: 'Dismiss',
    });
};

/**
 * Navigates the user to the VerifyingDebitDeposit screen.
 */
export const handleDepositSuccessMessage = (
    token: string,
    selectedAmount: string,
    currency: string,
    navigation: Navigation
) => {
    navigation.navigate('VerifyingDebitDeposit', {
        selectedAmount,
        currency,
        payment_token: token,
    });
};

/**
 * Handles Paysafe error messages and navigates the user to the FailedDepositModal screen.
 * Depending on the type of error, it logs the error.
 */
export const handlePaysafeErrorMessage = (
    data: { type: PaysafeErrorType; error: unknown },
    selectedAmount: string,
    navigation: Navigation
) => {
    switch (data.type) {
        case PAYSAFE_ERROR_TYPES.INIT:
            logger.error(ADD_DEBIT_CARD_LOG_TAG, 'error: failed initializing paysafeJS', data.error);
            break;
        case PAYSAFE_ERROR_TYPES.NOT_SUPPORTED:
            logger.error(ADD_DEBIT_CARD_LOG_TAG, 'error: paysafeJS not supported', data.error);
            break;
        default:
            logger.error(ADD_DEBIT_CARD_LOG_TAG, 'error adding debit card', data.error);
    }
    navigation.navigate('FailedDepositModal', {
        selectedAmount,
        paymentProvider: PaymentMethodTypes.Paysafe,
    });
};
