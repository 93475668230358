import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { UpdateExpiryCardForm } from '@/components/UpdateExpiryCardForm';
import { RootStackParamList } from '@/navigation/types';

import { PaymentMethodTypes } from '../const';

type DepositExpiryUpdateProps = NativeStackScreenProps<RootStackParamList, 'DepositExpiryUpdate'>;

export const DepositExpiryUpdate = ({ route }: DepositExpiryUpdateProps) => {
    const navigation = useNavigation();
    const { t } = useTranslation('transactions');

    const onSuccessUpdateCard = () => {
        navigation.navigate('VerifyingDebitDeposit', { ...route.params });
    };

    const onPressSecondaryButton = () => {
        navigation.navigate('DepositMethodWebViewModal', {
            ...route.params,
            selectedPaymentMethodType: PaymentMethodTypes.PaysafePaypal,
        });
    };

    return (
        <UpdateExpiryCardForm
            paymentTypeId={route.params.payment_type_id ?? ''}
            onSuccessUpdateCard={onSuccessUpdateCard}
            onPressSecondaryButton={onPressSecondaryButton}
            secondaryButtonLabel={t('deposit_with_payment_name', { paymentMethodTypeName: 'Paypal' })}
        />
    );
};
