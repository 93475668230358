import React from 'react';
import { View } from 'react-native';

import { common } from '@/styles/styles';

import { Text } from './TextComponent';

type ErrorMessagePaymentProps = {
    errorMessage: string;
};

export const ErrorMessagePayment = ({ errorMessage }: ErrorMessagePaymentProps) => (
    <View style={[common.alignCenter, common.justifyCenter]}>
        <Text variant="bodySmall" color={'red'} textAlign={'center'} testID="paymentErrorMsg">
            {errorMessage || ' '}
        </Text>
    </View>
);
