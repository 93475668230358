import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { authUserKeys } from '@/hooks/use-auth-user-config';
import { useDebitCardRemovalStore } from '@/hooks/use-debit-card-removal-store';
import { useGlobalState } from '@/hooks/use-global-state';
import { useQueryClient } from '@tanstack/react-query';

import { useNavigateToErrorModal } from '../../hooks/use-navigate-to-error-modal';
import { useNavigateToSuccessModal } from '../../hooks/use-navigate-to-success-modal';
import { useGetIdpvStatus } from '../Idpv/utils';
import { trackKYCSuccessEventAsync } from './VerifyingIdentity';

export const VerifyingIdpv = () => {
    const navigation = useNavigation();
    const { t } = useTranslation('kyc');
    const queryClient = useQueryClient();

    const applicantId = useGlobalState(state => state.applicantId);
    const { verificationSource: source } = useDebitCardRemovalStore();
    const { data: idpvStatusData } = useGetIdpvStatus(applicantId);

    const navigateToSuccessModal = useNavigateToSuccessModal({ source });
    const navigateToErrorModal = useNavigateToErrorModal({ source });

    useEffect(() => {
        if (idpvStatusData) {
            // Invalidate the auth user config query to ensure that the user's KYC status is updated
            queryClient.invalidateQueries(authUserKeys.config());

            const isVerificationComplete = idpvStatusData.status === 'complete';

            if (isVerificationComplete) {
                trackKYCSuccessEventAsync();
                navigateToSuccessModal();
            } else {
                navigateToErrorModal();
            }
        }
    }, [idpvStatusData, navigateToErrorModal, navigateToSuccessModal, navigation, queryClient, source]);

    return (
        <Screen>
            <Box flex={1} alignItems="center" justifyContent="center">
                <LoadingSpinner />
                <SizedBox value={16} />
                <Text variant="titleSmall">{t('verifying_identity')}</Text>
            </Box>
        </Screen>
    );
};
