import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';

/**
 * Hook to get the market name for all bet slip components
 */
export const useBetSlipMarketName = ({ marketId, eventId }: { marketId: string; eventId: string }) => {
    const props = useSbkBetSlipStore(state => {
        const market = state.markets[marketId];
        return {
            sportName: state.events[eventId].sport.name,
            event: state.events[eventId],
            player: market.player,
            fallback: market.description,
            marketType: market.marketType,
            appendTeamName: !market.isMicroMarket,
            appendPlayerName: true,
            appendMicroMarketDescription: market.isMicroMarket,
            isExtendedName: true,
        };
    });
    return useMarketName(props);
};
