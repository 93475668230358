import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Picker } from '@/components/Picker';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Text } from '@/components/TextComponent';
import WebView, { WebViewMessageEvent } from '@/components/Webview';
import { URLS } from '@/data/config';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { STATES } from '@/feature/kyc/const';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useActiveWallet, useActiveWalletPaysafeCredentials } from '@/hooks/use-active-wallet';
import { useAuthUserInfo } from '@/hooks/use-auth-user-info';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useRouteByName } from '@/hooks/use-route-by-name';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { logger } from '@/utils/logging';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { DebitCardAdditionFormHTML } from '../components/debit-deposit-form/DebitCardAdditionFormHTML';
import {
    ADD_DEBIT_CARD_LOG_TAG,
    MESSAGE_TYPES,
    MessageType,
    PAYSAFE_ERROR_TYPES,
    PaysafeErrorType,
    UNKNOWN_WEBVIEW_MESSAGE,
    handleBadBinMessage,
    handleDepositSuccessMessage,
    handlePaysafeErrorMessage,
} from '../webview-message-handler';

const styles = StyleSheet.create({
    textBox: {
        margin: 40,
        marginTop: 0,
        marginBottom: 32,
    },
    fadedText: {
        textAlign: 'center',
        marginTop: 8,
    },
    indicatorStyle: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: designSystem.colors.gray8,
    },
});

type ParseEvent = {
    type: MessageType;
    payload?: any; // could be any type of data
};

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'AddDebitCardWebView'>;

const getStateLabel = (value: string) =>
    STATES.reduce((label, state) => (state.value === value ? state.label : label), '');

export const AddDebitCardWebView = ({ route }: ScreenProps) => {
    const [showPicker, setShowPicker] = useState(false);
    const [selectedStateValue, setSelectedStateValue] = useState('');
    const [paysafeLoaded, setPaysafeLoaded] = useState(false);

    const webviewRef = useRef<WebView>(null);

    const { t } = useTranslation('wallet');
    const navigation = useNavigation();
    const depositRoute = useRouteByName('DepositScreen');
    const forceDepositWithDebitCard = depositRoute?.params?.forceDepositWithDebitCard;
    const { data: info, isLoading: userInfoLoading } = useAuthUserInfo();
    const { wallet, activeWalletProductName: productName } = useActiveWallet();
    const { data: apiKeys, isLoading: apiKeysLoading } = useActiveWalletPaysafeCredentials();
    const { showInfoSheet } = useAlerts();
    const userSettings = useJurisdictionStore(
        state => state.jurisdictionSettings?.globalSettings?.keyValuePairs?.UserSettings
    );

    const params = {
        productName,
        selectedAmount: Number(route.params?.selectedAmount) ?? 0,
        username: apiKeys?.paysafe_username ?? '',
        pubKey: apiKeys?.paysafe_pub_key ?? '',
        paysafeAccountId: Number(route.params?.paysafeAccountId) ?? 0,
        paysafeEnv: URLS.PAYSAFE_ENV,
        paysafeSdk: URLS.PAYSAFE_SDK,
        userInfo: JSON.stringify(info),
        badBinThreshold: Number(userSettings?.bad_bin_threshold) ?? 0,
    };

    const trackCardSubmit = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.DEPOSIT_DEBITJS_SUBMIT, {
            kycCTADebitOnly: forceDepositWithDebitCard,
        });
    };

    const messageHandlers: Record<MessageType, (data?: any) => void> = {
        [MESSAGE_TYPES.SHOW_PICKER]: () => {
            setShowPicker(true);
        },
        [MESSAGE_TYPES.BAD_BIN]: () => {
            handleBadBinMessage(showInfoSheet, navigation, route.params?.selectedAmount);
        },
        [MESSAGE_TYPES.STATE]: (usState: string) => {
            setSelectedStateValue(usState);
        },
        [MESSAGE_TYPES.DEPOSIT_SUCCESS]: (token: string) => {
            trackCardSubmit();
            handleDepositSuccessMessage(token, route.params?.selectedAmount, wallet?.real_currency, navigation);
        },
        [MESSAGE_TYPES.DEPOSIT_ERROR]: (data: { type: PaysafeErrorType; error: unknown }) => {
            trackCardSubmit();
            handlePaysafeErrorMessage(data, route.params?.selectedAmount, navigation);
        },
        [MESSAGE_TYPES.PAYSAFE_LOADED]: () => {
            setPaysafeLoaded(true);
        },
    };

    const handleWebViewMessage = (event: WebViewMessageEvent) => {
        try {
            const parsedEvent: ParseEvent = JSON.parse(event.nativeEvent.data);
            const handler = messageHandlers[parsedEvent.type];
            if (handler) {
                handler(parsedEvent.payload);
            } else {
                console.warn(ADD_DEBIT_CARD_LOG_TAG, UNKNOWN_WEBVIEW_MESSAGE, parsedEvent);
            }
        } catch (err) {
            logger.error(ADD_DEBIT_CARD_LOG_TAG, 'Error parsing event from webview', err);
            handlePaysafeErrorMessage(
                { type: PAYSAFE_ERROR_TYPES.GENERIC, error: err },
                route.params?.selectedAmount,
                navigation
            ); // assume it's a `generic` error
        }
    };

    const sendAmericanStateToWebView = (state: string) => {
        if (webviewRef.current) {
            setSelectedStateValue(state);
            webviewRef.current.postMessage('state:' + state + ':' + getStateLabel(state)); // eg. "state:CA:California"
        }
    };

    const isInitialLoading = userInfoLoading || apiKeysLoading;
    const showContent = !isInitialLoading && paysafeLoaded;

    return (
        <Screen>
            <ScreenNavBar
                closeIconMode="close"
                handlePressTracking={() => {
                    BetrAnalytics.trackProductEvent(AnalyticsEvent.DEPOSIT_DEBITJS_ABORT);
                }}
            />
            <MaxWidthWrapper flex={1}>
                {showContent && (
                    <View style={[styles.textBox, common.alignCenter, common.paddingHorizontal]}>
                        <Text variant={'headlineMedium'} testID="addDebitCardTitle">
                            {t('add_debit_card_title')}
                        </Text>

                        <Text style={styles.fadedText} color={'gray2'}>
                            {t('add_debit_card_subtext', {
                                selected_amount: toLocaleCurrency(route.params?.selectedAmount),
                            })}
                        </Text>
                    </View>
                )}
                <View style={common.flex}>
                    <WebView
                        ref={webviewRef}
                        style={common.backgroundColorTransparent}
                        scalesPageToFit={Platform.OS === 'ios' ? false : true}
                        originWhitelist={['*']}
                        onMessage={handleWebViewMessage}
                        source={{ html: DebitCardAdditionFormHTML(params) }}
                    />
                </View>

                {!showContent ? (
                    <View style={styles.indicatorStyle}>
                        <LoadingSpinner />
                    </View>
                ) : null}

                {showPicker ? (
                    <Picker
                        items={STATES}
                        pickedValue={selectedStateValue}
                        closePicker={() => setShowPicker(false)}
                        handleValueChange={sendAmericanStateToWebView}
                    />
                ) : null}
            </MaxWidthWrapper>
        </Screen>
    );
};
