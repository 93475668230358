import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={69} height={22} fill="none" {...props}>
        <G fill="#EDEFF3" clipPath="url(#a)">
            <Path
                fillRule="evenodd"
                d="M5.623 20.178c.47.137 1 .206 1.588.206.843 0 1.647-.167 2.411-.502a6.36 6.36 0 0 0 2-1.448 6.933 6.933 0 0 0 1.381-2.217c.353-.847.53-1.774.53-2.779s-.177-1.93-.53-2.778a6.59 6.59 0 0 0-1.382-2.187 5.799 5.799 0 0 0-1.999-1.449 5.662 5.662 0 0 0-2.41-.532c-.57 0-1.07.08-1.5.237a8.313 8.313 0 0 0-1.088.443 5.669 5.669 0 0 0-.941.65V1.615H.302v18.462H2.8l.588-1.17c.294.275.637.522 1.029.738.333.198.735.375 1.205.533Zm-1.264-4.05c-.647-.67-.97-1.566-.97-2.69 0-1.123.323-2.02.97-2.69.647-.67 1.45-1.004 2.411-1.004.96 0 1.764.335 2.411 1.005.647.67.97 1.566.97 2.69 0 1.123-.323 2.02-.97 2.69-.647.67-1.45 1.004-2.41 1.004-.961 0-1.765-.335-2.412-1.005Z"
                clipRule="evenodd"
            />
            <Path d="M33.617 3.308h-2.915V5.06c0 1.08-.534 1.619-1.603 1.619h-.874V9.46h2.04v6.067c0 .746.107 1.413.32 2.002a5.03 5.03 0 0 0 .934 1.53 4.29 4.29 0 0 0 1.428 1 5.092 5.092 0 0 0 1.836.325c.428 0 .953-.05 1.283-.148a2.6 2.6 0 0 0 .466-.16v-2.93c-.077.04-.155.069-.233.088-.078.02-.212.051-.262.06-.056.008-.206.005-.346.002a8.57 8.57 0 0 0-.179-.003c-.583 0-1.05-.166-1.4-.5-.33-.353-.495-.824-.495-1.413v-5.92h2.915V6.385h-2.915V3.308ZM44.19 6.23c.158 0 .296.01.414.03.118.02.227.04.325.06l.295.06v3.573c-.098-.02-.206-.04-.324-.06a5.527 5.527 0 0 0-.384-.06 3.353 3.353 0 0 0-.473-.03c-.827 0-1.507.279-2.038.834-.512.536-.768 1.251-.768 2.144v7.296H37.84V6.379h2.51l.591 1.49a4.17 4.17 0 0 1 .857-.805 4.04 4.04 0 0 1 1.004-.566 3.466 3.466 0 0 1 1.389-.268Z" />
            <Path
                fillRule="evenodd"
                d="M27.476 16.113a5.864 5.864 0 0 1-.823 1.532c-.353.51-.794.972-1.323 1.384a6.928 6.928 0 0 1-1.764.972 5.717 5.717 0 0 1-2.117.383 6.562 6.562 0 0 1-2.617-.53 7.432 7.432 0 0 1-2.146-1.502 7.62 7.62 0 0 1-1.47-2.18 6.96 6.96 0 0 1-.53-2.71c0-.963.177-1.857.53-2.682a7.501 7.501 0 0 1 1.47-2.21 6.98 6.98 0 0 1 2.146-1.472 6.266 6.266 0 0 1 2.617-.56c.882 0 1.715.167 2.499.5a6.22 6.22 0 0 1 2.058 1.415c.588.609 1.049 1.345 1.382 2.21.353.844.53 1.777.53 2.798 0 .157-.01.314-.03.471l-.059.413-.059.442h-9.408c.157.668.49 1.227 1 1.68.51.45 1.205.677 2.087.677.45 0 .823-.05 1.117-.148.314-.098.578-.216.794-.353.235-.157.431-.334.588-.53h3.528ZM21.45 9.779c-.882 0-1.607.255-2.176.766a3.495 3.495 0 0 0-1.058 1.885h6.468a3.42 3.42 0 0 0-1.088-1.885c-.549-.511-1.264-.766-2.146-.766ZM51.385 14.61l1.687-11.382c.065-.436.401-.782.836-.859L67.298.01a.512.512 0 0 1 .545.736l-3.8 7.454c-.192.376.13.81.545.737l3.496-.616c.499-.089.809.525.442.875L55.23 21.856c-.38.362-.994-.014-.844-.517l1.827-6.144a.512.512 0 0 0-.58-.65l-3.653.644a.512.512 0 0 1-.595-.58Zm8.267-5.14c.654.257 1.072.42.923-.42-.263-1.493-1.661-2.493-3.123-2.236-1.463.258-2.435 1.677-2.172 3.17.149.84.485.544 1.012.079.408-.36.93-.82 1.568-.933.638-.112 1.286.142 1.792.34Z"
                clipRule="evenodd"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M.302 0h68.397v22H.302z" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgComponent;
