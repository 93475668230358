import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-native-calendars';
import { DateData, Direction } from 'react-native-calendars/src/types';

import Arrow from '@/assets/icons/right';
import { Button } from '@/components/ButtonComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Column } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import { designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { generateDateRangeObject } from '../utils/calendar-helper';

const calendarTheme = {
    calendarBackground: designSystem.colors.gray6,
    dayTextColor: designSystem.colors.gray1,
    monthTextColor: designSystem.colors.gray1,
    textDisabledColor: designSystem.colors.gray4,
    arrowColor: designSystem.colors.gray1,
    arrowStyle: {
        backgroundColor: designSystem.colors.gray5,
        borderRadius: 5,
    },
};

export type CalendarSheetProps = {
    selectDate?: () => void;
    onApply?: (selectedDates: DateData[]) => void;
};

export type CalendarSheetModalRef = {
    show: (params: CalendarSheetProps) => void;
};

export const CalendarSheetModal = forwardRef<CalendarSheetModalRef, {}>((_props, ref) => {
    const modalRef = useRef<BottomSheetModal>(null);
    const [data, setData] = useState<CalendarSheetProps>();

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    return (
        <Modal id="calendar-sheet" modalRef={modalRef} dismissible={false}>
            <ModalContent {...data} />
        </Modal>
    );
});

const ModalContent = (props: CalendarSheetProps) => {
    const { t } = useTranslation(['common', 'transactions']);
    const { onApply, selectDate } = props;
    const { dismissAll } = useModals();

    const [selectedDates, setSelectedDates] = useState<DateData[]>([]);

    const markedDates = useCallback(() => generateDateRangeObject(selectedDates), [selectedDates]);
    const onDayPress = useCallback((date: DateData) => {
        setSelectedDates(prevDates => {
            // If there's already one date selected, it adds the new date to the selection, allowing for a range selection.
            if (prevDates.length === 1) {
                return [...prevDates, date];
            } else {
                // If there are no dates selected or more than one, it resets the selection to just the new date.
                return [date];
            }
        });
    }, []);
    const renderArrow = useCallback((direction: Direction) => {
        const style = {
            transform: [{ rotate: direction === 'left' ? '180deg' : '0deg' }],
        };

        return <Arrow fill={designSystem.colors.gray1} style={style} />;
    }, []);

    const onClose = () => {
        dismissAll();
        selectDate?.();
    };

    const handleApplyDateRange = () => {
        onApply?.(selectedDates);
        setSelectedDates([]);
        dismissAll();
    };

    const gap = isWeb ? 20 : 0;
    const today = new Date().toISOString().split('T')[0];

    return (
        <Column width="100%" paddingHorizontal="s16" pb="s8" gap="s12">
            <ScreenNavBar style={{ gap }} title={t('transactions:transaction_date')} onClose={onClose} />
            <Calendar
                theme={calendarTheme}
                initialDate={today}
                maxDate={today}
                markedDates={markedDates()}
                onDayPress={onDayPress}
                markingType="period"
                enableSwipeMonths
                showSixWeeks
                disableAllTouchEventsForDisabledDays
                renderArrow={isWeb ? renderArrow : undefined}
            />
            <Button label={t('common:apply')} type="active" variant="rounded" onPress={handleApplyDateRange} />
            <Button
                label={t('common:clear_selection')}
                type="activeGray"
                variant="rounded"
                onPress={() => setSelectedDates([])}
            />
        </Column>
    );
};
