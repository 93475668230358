import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { BalanceInfo } from '@/feature/account-details/components/BalanceInfo';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { useActiveProductName } from '@/hooks/use-active-product-name';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

export const useBalanceModal = () => {
    const navigation = useNavigation();
    const { showInfoSheet } = useAlerts();
    const { t } = useTranslation(['wallet']);

    const productSettings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);
    const product = useJurisdictionStore(state => state.product);
    const productName = useActiveProductName();

    const onPressDepositToProduct = () => {
        if (productSettings?.deposit_enabled) {
            navigation.navigate('DepositScreen', { selectedAmount: '' });
        } else {
            showInfoSheet({
                title: 'Deposits Unavailable',
                description: t('wallet:unavailable_deposit'),
                buttonLabel: 'Close',
            });
        }
    };

    const showBalanceModal = () => {
        GASbkEvents.clickBalanceDropdown();
        showInfoSheet({
            description: <BalanceInfo product={product} />,
            buttonLabel: `Deposit to ${productName}`,
            handlePress: onPressDepositToProduct,
            secondaryLabel: 'Close',
        });
    };

    return { showBalanceModal };
};
