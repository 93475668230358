import { useCallback, useState } from 'react';

export type AuthFieldValues = {
    step: number;
    code: string;
    phone?: string;
    email?: string;
    password?: string;
    enableFaceID?: boolean;
};

export type AuthFieldHandler = {
    values: AuthFieldValues;
    setFieldValue: <K extends keyof AuthFieldValues>(name: K, value?: AuthFieldValues[K]) => void;
};

export const useFields = <T extends Record<string, any>>(initialValues: T) => {
    const [values, setValues] = useState<T>(initialValues);
    const setFieldValue = useCallback(
        <K extends keyof T>(name: K, value?: T[K]) => {
            setValues(snap => ({
                ...snap,
                [name]: value,
            }));
        },
        [setValues]
    );

    return {
        setFieldValue,
        values,
    };
};
