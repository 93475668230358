import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet } from 'react-native';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LineSeparator } from '@/components/LineSeparator';
import { Loading } from '@/components/Loading';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { SharedEntryInfoFragment, useGetFeaturedEntriesQuery } from '@/feature/entry-share/api/query.generated';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { RootStackParamList } from '@/navigation/types';
import { common } from '@/styles/styles';

import { useLatestLineups } from '../hooks/use-latest-lineups';
import { PrebuiltLineupCard } from './PrebuiltLineupCard';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'FeaturedLineupsScreen'>;

const ItemSeparatorComponent = () => <SizedBox value={20} />;

export const FeaturedLineupsScreen = ({ route: { params } }: ScreenProps) => {
    const title = params.title;

    const [{ data, fetching, error }] = useGetFeaturedEntriesQuery();
    const { t } = useTranslation(['common', 'prebuilt_lineup']);

    const filteredLineups = useLatestLineups(data?.getSharedEntries);

    const renderItem = useCallback(({ item, index }: { item: SharedEntryInfoFragment; index: number }) => {
        return (
            <MaxWidthWrapper>
                <PrebuiltLineupCard prebuiltLineup={item} index={index} />
            </MaxWidthWrapper>
        );
    }, []);

    return (
        <Screen>
            <ScreenNavBar closeIconMode="back" title={title} />
            <LineSeparator style={common.hairlineHeight} />
            {fetching && !data ? (
                <Box mt={'s20'} justifyContent={'center'} flex={1}>
                    <Loading />
                </Box>
            ) : error ? (
                <Text variant={'bodyMedium'} mt={'s20'} textAlign={'center'}>
                    {t('common:simple_error_message')}
                </Text>
            ) : (
                <FlatList
                    contentContainerStyle={styles.list}
                    keyExtractor={lineup => `lineup${lineup.id}`}
                    data={filteredLineups}
                    renderItem={renderItem}
                    ItemSeparatorComponent={ItemSeparatorComponent}
                />
            )}
        </Screen>
    );
};

const styles = StyleSheet.create({
    list: {
        paddingBottom: 36,
        paddingTop: 20,
    },
});
