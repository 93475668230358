import React from 'react';

import { StyleVariant, Text } from '@/components/TextComponent';
import { designSystem } from '@/styles/styles';

type TeamLogoFallbackTextProps = {
    height: number;
    teamName?: string;
    textColor: string;
    variant?: StyleVariant;
    showFullTeamName?: boolean;
    fontSize?: number;
};

export const TeamLogoFallbackText = ({
    height,
    teamName,
    textColor,
    variant = 'teamLogoFallback',
    showFullTeamName = false,
    fontSize,
}: TeamLogoFallbackTextProps) => {
    const firstLetter = teamName?.charAt(0);

    return (
        <Text
            textAlign="center"
            fontFamily={'Oswald-SemiBold'}
            variant={variant}
            lineHeight={height}
            style={[{ color: textColor || designSystem.colors.gray1 }, showFullTeamName ? { fontSize } : null]}
        >
            {showFullTeamName ? teamName : firstLetter}
        </Text>
    );
};
