import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useWithdrawalMethods } from '@/feature/withdraw/hooks/use-withdrawal-methods';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { canWithdrawSelector, hasMadeFirstDepositSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

const WithdrawUnavailable = () => {
    const { t } = useTranslation('wallet');
    const { launchIntercomMessenger } = useLaunchIntercomMessenger();

    return (
        <Box>
            <Text mb="s8" color="gray2">
                {t('withdrawal_insufficient_funds')}
            </Text>
            <Text ml="s8" color="gray2">
                1. {t('withdrawal_insufficient_funds_first_bullet')}
            </Text>
            <Text ml="s8" color="gray2">
                2. {t('withdrawal_insufficient_funds_second_bullet')}
            </Text>
            <Text ml="s8" mb="s8" color="gray2">
                3. {t('withdrawal_insufficient_funds_third_bullet')}
            </Text>
            <Text color="gray2">
                <Trans i18nKey="withdrawal_insufficient_funds_contact_support" ns="wallet">
                    If you have an active deposit match promotion but wish to withdraw your original deposit you will
                    forfeit your deposit match bonus,
                    <TouchableOpacity onPress={launchIntercomMessenger}>
                        <Text lineHeight={16} textDecorationLine="underline" color="gray2">
                            contact support
                        </Text>
                    </TouchableOpacity>
                    to proceed
                </Trans>
            </Text>
        </Box>
    );
};

export const useWithdrawalStatus = () => {
    const { t } = useTranslation('wallet');

    const settings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);

    const { balance } = useActiveWallet();

    const { data: hasSuccessfulDeposit } = useAuthUserConfig({
        select: hasMadeFirstDepositSelector,
    });
    const { data: userCanWithdraw } = useAuthUserConfig({
        select: canWithdrawSelector,
    });
    const { data: withdrawMethods = [] } = useWithdrawalMethods({
        select: data => data?.data,
    });

    const withdrawEnabled = settings?.withdraw_enabled;
    const hasWithdrawMethods = withdrawMethods && withdrawMethods.length > 0;
    const canWithdraw = userCanWithdraw && withdrawEnabled && balance && hasSuccessfulDeposit && hasWithdrawMethods;
    return {
        canWithdraw,
        infoSheetParams: {
            title: t('withdrawal_unavailable_title'),
            description: <WithdrawUnavailable />,
            buttonLabel: 'Close',
            handlePress: () => {
                BetrAnalytics.trackProductEvent(AnalyticsEvent.WITHDRAWAL_UNAVAILABLE_CLOSE);
            },
        },
    };
};
