import { z } from 'zod';

import { SelfExcludeType } from './use-self-exclude';

const UserLimitEnum = z.enum(['DEPOSIT', 'LOSE', 'WAGER', 'SINGLE_WAGER']);
export type UserLimitType = z.infer<typeof UserLimitEnum>;
const UserLimitDurationEnum = z.enum(['DAY', 'WEEK', 'MONTH']);
export type UserLimitDuration = z.infer<typeof UserLimitDurationEnum>;

export const UserLimitsSchema = z
    .object({
        type: UserLimitEnum,
        amount: z.number(),
        new_amount: z.number().nullable(),
        current_state: z.number(),
        limit_goes_live_at: z.number(),
        duration: UserLimitDurationEnum.nullable(),
    })
    .array();

export type UserLimit = z.infer<typeof UserLimitsSchema>[0];

type UserLimitPayload = Pick<UserLimit, 'type' | 'duration' | 'amount'>;
type SingleWagerLimitPayload = Pick<UserLimit, 'type' | 'amount'>;

export type AddUserLimitPayload = UserLimitPayload | SingleWagerLimitPayload;

export interface UserLimitConfirmation extends UserLimit {
    user_limit_id: string;
}

export type ConfirmUserLimitPayload = {
    user_limit_id: string;
    confirmation_action: 'accept' | 'reject';
};

type SelfExclude = SelfExcludeType.SELF_EXCLUDE | SelfExcludeType.TIME_OUT;
export type LimitHistoryTypes = UserLimitType | SelfExclude;

export type UserLimitHistory = {
    amount: number;
    confirmed_status: 'ACCEPTED' | 'REJECTED' | 'PENDING';
    created_at: number;
    duration: UserLimitDuration | null;
    limit_goes_live_at: number | null;
    limit_type: LimitHistoryTypes;
    operator_email: string;
    user_limit_id: string;
    user_made_change: boolean;
};
