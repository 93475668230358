import React from 'react';

import { BetListItem } from '@/components/bet-list/BetListItem';
import { BetSelection } from '@/feature/bets-sbk/hooks/types';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';
import { useOptionName } from '@/feature/event-details-sbk/hooks/use-option-name';

import { FeaturedEvent } from '../types';

export const FeaturedBetCardSelection = ({
    event,
    selection,
    isLastEvent,
    isFirstLeg,
    isLastLeg,
}: {
    event: FeaturedEvent;
    selection: BetSelection;
    isLastEvent: boolean;
    isFirstLeg: boolean;
    isLastLeg: boolean;
}) => {
    const marketName = useMarketName({
        sportName: event.sport.name,
        event: event,
        player: selection.market?.player,
        fallback: selection.market.description,
        marketType: selection.market.market_type,
        appendTeamName: selection.market.is_micro_market,
        appendPlayerName: true,
        appendMicroMarketDescription: selection.market.is_micro_market,
        isExtendedName: true,
    });

    const optionNameParams = {
        optionType: selection.option.option_type,
        marketType: selection.market.market_type,
        homeTeam: event.home_team,
        awayTeam: event.away_team,
        fallback: selection.option.description,
        sportName: event.sport.name,
    };

    const optionName = useOptionName(optionNameParams);

    return (
        <BetListItem
            isLastEvent={isLastEvent}
            isFirstLeg={isFirstLeg}
            isLastLeg={isLastLeg}
            isEnabled={true}
            isSuspended={false}
            isConflicting={false}
            optionName={optionName}
            marketName={marketName}
            odds={selection.odds}
        />
    );
};
