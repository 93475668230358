import { useNavigation } from '@react-navigation/native';

import { useAuthUserConfig, userDueDiligenceSelector } from '@/hooks/use-auth-user-config';
import { useAuthUserInfo } from '@/hooks/use-auth-user-info';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useSupportUrl } from '@/hooks/use-support-url';
import { user } from '@/hooks/use-user';
import { getAppVersion } from '@/utils/get-app-version';
import Intercom from '@intercom/messenger-js-sdk';

import { PROD, getEnv } from '../config';
import { INTERCOM_APP_ID } from '../config';

type IntercomCustomAttributes = {
    product: Product;
    location?: string;
    current_route?: string;
    payment_type?: string;
    payment_method?: string;
    payment_amount?: string;
    edd_status?: string;
    bo_link?: string;
};

type InitializeIntercomProps = Partial<IntercomCustomAttributes>;

export const useInitIntercom = () => {
    return () => {
        if (!window.Intercom) {
            Intercom({
                app_id: INTERCOM_APP_ID,
            });
        }
    };
};

export const closeIntercom = () => {
    if (window.Intercom) {
        window.Intercom('shutdown');
    }
};

export const updateUserDataForIntercom = (params: { [key: string]: any }) => {
    if (!window.Intercom) {
        return;
    }

    // intercom web sdk doesn't use customAttributes, so it needs to be merged into the main object
    // ref: https://developers.intercom.com/installing-intercom/web/attributes-objects#data-attributes
    if (params.customAttributes && typeof params.customAttributes === 'object' && params.customAttributes !== null) {
        Object.assign(params, params.customAttributes);
        delete params.customAttributes;
    }
    window.Intercom('update', params);
};

export const useLaunchIntercomMessenger = (callback?: () => void) => {
    const navigation = useNavigation();
    const supportUrl = useSupportUrl();
    const { product, jurisdiction } = useJurisdictionStore.getState();
    const { data: userDueDiligence } = useAuthUserConfig({ select: userDueDiligenceSelector });
    const { data: info } = useAuthUserInfo();

    const launchIntercomMessenger = (params: InitializeIntercomProps = {}) => {
        const { payment_type, current_route, payment_amount, payment_method, bo_link } = params;

        const intercomParams = {
            app_id: INTERCOM_APP_ID,
            email: user.profile.email,
            phone: info?.phone_number,
            user_id: user.profile.sub,
            product,
            location: jurisdiction,
            platform: 'web',
            appVersion: getAppVersion(),
            ...{ current_route },
            ...{ payment_type },
            ...{ payment_method },
            ...{ payment_amount },
            ...{ bo_link },
            ...(userDueDiligence && { edd_status: userDueDiligence }),
            ...(info?.first_name && info?.last_name && { name: `${info?.first_name} ${info?.last_name}` }),
        };

        // Disable intercom for non-PROD environments.
        if (getEnv() !== PROD) {
            return;
        }

        if (user.session?.access_token) {
            if (window.Intercom) {
                window.Intercom('update', intercomParams);
                window.Intercom('show');
                callback?.();
            }
        } else {
            navigation.navigate('ModalWebView', { uri: supportUrl });
        }
    };

    return { launchIntercomMessenger };
};
