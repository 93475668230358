import { URLS, createJurisdictionHeaders } from '@/data';
import { eventKeys } from '@/feature/event-details-sbk/hooks/use-event';
import { Event } from '@/feature/event-details-sbk/types';
import { featuredLeagueKeys } from '@/feature/lobby-sbk/hooks/use-featured-league-data';
import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

export type EventsResponse = {
    data: Event[];
};

export const getEvents = async (leagueId: string): Promise<EventsResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/events?league_ids=${leagueId}&limit=100`, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            ...cacheControlHeader,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    useEventDetailsCache
        .getState()
        .actions.populate(
            data?.data
                ?.map((event: Event) => ({ ...(event.event_details ?? {}), id: event.id, status: event.status }))
                .flat() ?? []
        );
    return data;
};

const getPlaceholderData = (queryClient: QueryClient, leagueId: string) => {
    return queryClient.getQueryData<{ data: Event[] }>(featuredLeagueKeys.eventsByLeague(leagueId));
};

export const useEvents = (leagueId: string) => {
    const queryClient = useQueryClient();
    return useQuery(eventKeys.byLeague(leagueId), () => getEvents(leagueId), {
        select: data => data.data,
        enabled: !!leagueId,
        placeholderData: getPlaceholderData(queryClient, leagueId),
    });
};

const selectEventsGroupedById = (data: EventsResponse) =>
    data.data.reduce<{ [key: string]: Event }>((acc, event) => {
        acc[event.id] = event;
        return acc;
    }, {});

/**
 * Custom hook to fetch and group events by their ID for a specific league.
 *
 * @param leagueId - The ID of the league.
 * @returns The result of the query, containing the grouped events.
 */
export const useEventsGroupedById = (leagueId: string) => {
    return useQuery(eventKeys.byLeague(leagueId), () => getEvents(leagueId), {
        select: selectEventsGroupedById,
    });
};
