import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutAnimation } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Row } from '@/components/lib/components/Row';
import { useQuickAmountsModal } from '@/feature/quick-amounts/components/QuickAmountsProvider';
import { useQuickAmountsSheetFeatureFlag } from '@/feature/quick-amounts/hooks/use-quick-amounts-sheet-feature-flag';
import { GameMode, GameType } from '@/types/api.generated';

import { useBetslipStore } from '../hooks/use-betslip-store';

// renders the buttons for the gameplay card (edit / remove)
export const GameplayCardButtons = ({ gameMode, gameType }: { gameMode: GameMode; gameType: GameType }) => {
    const navigation = useNavigation();
    const isQuickAmountsSheetModalEnabled = useQuickAmountsSheetFeatureFlag();

    const setEntryAmount = useBetslipStore(state => state.actions.setEntryAmount);

    const { t } = useTranslation(['common']);
    const { showQuickAmountsModal } = useQuickAmountsModal();

    const handleRemovePress = useCallback(() => {
        LayoutAnimation.easeInEaseOut();
        setEntryAmount(undefined, gameMode);
    }, [setEntryAmount, gameMode]);

    const handleEditPress = () => {
        if (isQuickAmountsSheetModalEnabled) {
            showQuickAmountsModal({ gameMode, gameType });
            return;
        }

        navigation.navigate('EntryInputAmount', { gameMode, gameType });
    };

    return (
        <Row flex={1}>
            <Button
                onPress={handleEditPress}
                analyticsTag={`Edit - ${gameMode} game card`}
                label={t('edit')}
                size="s"
                flex={1}
            />
            <SizedBox value={12} />
            <Button
                onPress={handleRemovePress}
                analyticsTag={`Remove - ${gameMode} game card`}
                label={t('remove')}
                size="s"
                flex={1}
            />
        </Row>
    );
};
