import React, { ReactNode } from 'react';
import { StyleSheet, View, ViewStyle, useWindowDimensions } from 'react-native';
import { StyleProp } from 'react-native';
import Animated from 'react-native-reanimated';
import { Edges, SafeAreaView } from 'react-native-safe-area-context';

import {
    MaterialTopTabBar,
    MaterialTopTabBarProps,
    MaterialTopTabNavigationOptions,
} from '@react-navigation/material-top-tabs';

import { common, designSystem } from '@/styles/styles';

import { LineSeparator } from './LineSeparator';
import { Text } from './TextComponent';

/**
 * Use this component with TOP_TAB_BAR_CONFIG and createMaterialTopTabNavigator to create a basic TopTabBar navigator
 * @param {MaterialTopTabBarProps & { headerComponent: ReactNode }} accepts all tab bar props and an additional
 * `headerComponent` that allows inject a component above the tab bar
 */

export const TAB_HEIGHT = 52;

export type TopTabBarProps = MaterialTopTabBarProps & {
    headerComponent?: ReactNode;
    customEdges?: Edges;
    separatorStyles?: StyleProp<ViewStyle>;
    forceShowOneTab?: boolean;
    customWidth?: number;
};

export const TopTabBar = ({
    headerComponent,
    customEdges,
    state,
    descriptors,
    navigation,
    separatorStyles,
    forceShowOneTab = false,
    customWidth,
    ...rest
}: TopTabBarProps) => {
    const shouldShow = state.routeNames.length > 1 || forceShowOneTab;
    const { width: screenWidth } = useWindowDimensions();

    return (
        <SafeAreaView edges={customEdges || ['left', 'right', 'top']} style={[styles.container]}>
            {headerComponent}
            {shouldShow && (
                <>
                    <View style={[common.row, common.full, common.alignCenter]}>
                        <Animated.View
                            style={[
                                common.noOverflow,
                                {
                                    width: customWidth || screenWidth,
                                },
                            ]}
                        >
                            <MaterialTopTabBar
                                {...rest}
                                state={state}
                                descriptors={descriptors}
                                navigation={navigation}
                            />
                        </Animated.View>
                    </View>
                    <LineSeparator style={[styles.separator, separatorStyles]} />
                </>
            )}
        </SafeAreaView>
    );
};

export const TopTabBarLabel = (props: { focused: boolean; children: string }) => (
    <Text variant="titleMedium" color={props.focused ? 'gray1' : 'gray3'} style={styles.text}>
        {props.children}
    </Text>
);

const TAB_BAR_ITEM_STYLE = {
    paddingHorizontal: 16,
    minHeight: TAB_HEIGHT,
};

const TAB_BAR_INDICATOR_STYLE = {
    backgroundColor: designSystem.colors.gray1,
    height: 4,
    marginBottom: -1,
};

/**
 * Default config use to style `@react-navigation/material-top-tabs`
 */
export const TOP_TAB_BAR_CONFIG: MaterialTopTabNavigationOptions = {
    tabBarStyle: {
        backgroundColor: 'transparent',
        // reset shadow to avoid warning  about `shadow efficiency`
        shadowColor: 'auto',
        shadowOpacity: 0,
        shadowRadius: 0,
        elevation: 0,
        height: TAB_HEIGHT,
        paddingTop: 0.6,
        marginBottom: 1,
    },
    tabBarItemStyle: TAB_BAR_ITEM_STYLE,
    tabBarIndicatorStyle: TAB_BAR_INDICATOR_STYLE,
    tabBarLabel: TopTabBarLabel,
};

/**
 * Use this config if you want your tabs to be 'flex' size and scrollable
 */
export const SCROLLABLE_TOP_TAB_BAR_CONFIG: MaterialTopTabNavigationOptions = {
    ...TOP_TAB_BAR_CONFIG,
    tabBarScrollEnabled: true,
    tabBarItemStyle: {
        ...TAB_BAR_ITEM_STYLE,
        width: 'auto',
    },
    tabBarIndicatorStyle: {
        ...TAB_BAR_INDICATOR_STYLE,
        marginLeft: -0.33,
    },
};

const styles = StyleSheet.create({
    separator: {
        marginTop: -4,
        height: 4,
        zIndex: designSystem.zIndex.zIndexNegative1,
    },
    container: {
        width: '100%',
        justifyContent: 'center',
    },
    text: {
        bottom: 2,
    },
});
