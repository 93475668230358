import React from 'react';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { RootStackParamList } from '@/navigation/types';

import { Authentication } from '../components/Authentication';

export type LoginScreenProps = NativeStackScreenProps<RootStackParamList, 'Login'>;

export const LoginScreen = ({ route }: LoginScreenProps) => {
    return (
        <Authentication
            initAuthType="login"
            code={route.params?.code}
            sessionState={route.params?.session_state}
            initialEmail={route.params?.initialEmail}
            registering={route.params?.registering}
        />
    );
};
