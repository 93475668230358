import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { RootStackParamList } from '@/navigation/types';
import { TFunction } from 'i18next';

import { KycStepContainer } from '../components/KycStepContainer';
import { PersonalInfoButton, PersonalInfoItem } from '../components/PersonalInfoButton';
import { formatDob } from '../components/utils';
import { kycFlowTypes, kycRouteNames } from '../const';
import { formatAddressText } from '../formatter';
import { KycFields, useKycFieldsStore } from '../hooks/use-kyc-fields';

type CreatePersonalInfoMapArgs = {
    navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>;
    t: TFunction<'kyc', undefined>;
    values: KycFields;
};

const createPersonalInfoMap = ({
    navigation,
    t,
    values,
}: CreatePersonalInfoMapArgs): { [key: string]: PersonalInfoItem } => {
    const { ssn, firstName, lastName, dateOfBirth, address, city, americanState, zip } = values;
    return {
        ssn: {
            value: ssn,
            label: t('ssn_full'),
            onPress: () => {},
            isMasked: true,
        },
        name: {
            value: `${firstName} ${lastName}`,
            label: t('full_name'),
            onPress: () => {
                BetrAnalytics.trackEvent(AnalyticsEvent.REVIEW_EDIT, { field: 'name' });
                navigation.navigate(kycRouteNames.FIRST_LAST_NAME_REVIEW);
            },
        },
        dob: {
            value: dateOfBirth ? formatDob(dateOfBirth, 'MM/dd/yyyy') : '',
            label: t('dob_header'),
            onPress: () => {
                BetrAnalytics.trackEvent(AnalyticsEvent.REVIEW_EDIT, { field: 'dob' });
                navigation.navigate(kycRouteNames.DOB_REVIEW);
            },
        },
        address: {
            value: formatAddressText(address, city, americanState, zip),
            label: t('address'),
            onPress: () => {
                BetrAnalytics.trackEvent(AnalyticsEvent.REVIEW_EDIT, { field: 'address' });
                navigation.navigate(kycRouteNames.HOME_ADDRESS_REVIEW);
            },
        },
    };
};

export const ReviewPersonalInfo = () => {
    const { t } = useTranslation('kyc');
    const navigation = useNavigation();

    const values = useKycFieldsStore(state => state.values);

    const personalInfoMap = useMemo(() => createPersonalInfoMap({ navigation, t, values }), [navigation, t, values]);

    return (
        <KycStepContainer
            kycFlowType={kycFlowTypes.RETRY}
            headerTitle={t('personal_info')}
            title={t('review_header')}
            subText={t('review_copy')}
            isNextDisabled={false}
            onCloseIconPress={() => {
                BetrAnalytics.trackEvent(AnalyticsEvent.REVIEW_ABORT);
                navigation.goBack();
            }}
        >
            <View>
                {Object.entries(personalInfoMap).map(([key, item]) => (
                    <PersonalInfoButton
                        key={key}
                        label={item.label}
                        value={item.value}
                        onPress={item.onPress}
                        isMasked={item.isMasked}
                    />
                ))}
            </View>
        </KycStepContainer>
    );
};
