import React, {
    FC,
    MutableRefObject,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { FlatList, LayoutChangeEvent, Platform, ScrollView } from 'react-native';
import { RefreshControl } from 'react-native';
import { Gesture, GestureType } from 'react-native-gesture-handler';
import {
    AnimatedStyle,
    Extrapolation,
    FrameInfo,
    ScrollHandler,
    SharedValue,
    cancelAnimation,
    clamp,
    interpolate,
    runOnJS,
    scrollTo,
    useAnimatedRef,
    useAnimatedScrollHandler,
    useAnimatedStyle,
    useFrameCallback,
    useSharedValue,
    withClamp,
    withDecay,
} from 'react-native-reanimated';
import { ReanimatedScrollEvent } from 'react-native-reanimated/lib/typescript/reanimated2/hook/commonTypes';

import { MaterialTopTabNavigationEventMap } from '@react-navigation/material-top-tabs';
import { ScreenListeners, TabNavigationState, useIsFocused } from '@react-navigation/native';

import { SCREEN_NAV_BAR_HEIGHT } from '@/components/ScreenNavBar';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { common, designSystem } from '@/styles/styles';

// ! here we store all our data for a specific tab
export type TabRefType = {
    [tabKey: string]: {
        // ref so we can scroll
        ref: FlatList<unknown>;
        // offset to know how much this tab was scrolled before
        offset?: number;
        // the size of the content so that we can clamp the pan between 0 this value
        contentHeight?: number;
    };
};

type TabListenerType = ScreenListeners<TabNavigationState<any>, MaterialTopTabNavigationEventMap>;

const warnAboutMissingProvider = () => {
    console.warn('You are trying to use StickyTabsContext outside of the StickyTabsProvider');
};

export const FULL_HEADER_HEIGHT = SCREEN_NAV_BAR_HEIGHT + TAB_HEIGHT;

const StickyTabsContext = createContext<{
    // ! used to warn about using the context outside of the provider
    __isStickyTabsContextInsideProvider: boolean;
    isActivelyScrolling: MutableRefObject<boolean>;
    panValue?: SharedValue<number>;
    isAnimatingPanHandler?: SharedValue<boolean>;
    currentlyRefreshingTab?: SharedValue<string>;
    currentFocusedTabHeight?: SharedValue<number>;
    getScrollHandler: (tabKey: string) => { [key: string]: ScrollHandler };
    headerOffset?: SharedValue<number>;
    smoothScrollHeaderStyle: AnimatedStyle;
    smoothScrollHeaderStyleBelowTabsPullToRefresh: AnimatedStyle;
    scrollToOffsetForAllLists: () => void;
    panGesture: GestureType;
    shouldUpdateOffsetAfterPan?: SharedValue<boolean>;
    panRef?: MutableRefObject<GestureType | undefined>;
    tabListRefs: MutableRefObject<TabRefType>;
    tabListeners: TabListenerType;
    headerHeight: number;
    updateHeaderHeight: (headerHeight: number) => void;
    scrollY: SharedValue<number>;
}>({
    __isStickyTabsContextInsideProvider: false,
    panGesture: Gesture.Pan(),
    isActivelyScrolling: { current: false },
    getScrollHandler: () => ({
        onScroll: warnAboutMissingProvider,
    }),
    smoothScrollHeaderStyle: {},
    smoothScrollHeaderStyleBelowTabsPullToRefresh: {},
    scrollToOffsetForAllLists: warnAboutMissingProvider,
    tabListRefs: {} as MutableRefObject<TabRefType>,
    tabListeners: {
        tabPress: warnAboutMissingProvider,
        swipeStart: warnAboutMissingProvider,
    } as TabListenerType,
    headerHeight: 0,
    updateHeaderHeight: warnAboutMissingProvider,
    scrollY: { value: 0 } as SharedValue<number>,
});

type StickyTabsProviderProps = {
    headerHeight?: number;
};

export const StickyTabsProvider: FC<PropsWithChildren<StickyTabsProviderProps>> = ({
    children,
    headerHeight = SCREEN_NAV_BAR_HEIGHT,
}) => {
    // here we store the refs + offset + content height for each tab
    const tabListRefs = useRef<TabRefType>({});
    const [stickyHeaderHeight, setStickyTabsHeaderHeight] = useState(headerHeight);
    // stores the value of "how much the header was scrolled"
    const headerOffset = useSharedValue(0);
    const currentFocusedTabHeight = useSharedValue(0);
    const currentlyRefreshingTab = useSharedValue('');
    const scrollY = useSharedValue(0);

    // ! the current value of the pan event (translationY of that event) - it is reversed (negative) when we scroll down
    const panValue = useSharedValue(0);

    // here we store the old value of the panning since the "pan" event always restarts (starts from 0)
    // with this approach we have a continuous "pan" value for the animation
    const prevPanValue = useSharedValue(0);
    const shouldAnimatePanHandlerGestureEnd = useSharedValue(false);
    const panRef = useRef<GestureType>();
    const isActivelyScrolling = useRef(false);
    const shouldUpdateOffsetAfterPan = useSharedValue(false);
    const isAnimatingPanHandler = useSharedValue(false);

    // create a custom scroll handler to be used with an Animated.FlatList on our tabs
    const getScrollHandler = useCallback(
        (tabKey?: string) => {
            // ! function used to update the offset of the header
            // ! and also update the offset of the current scrolling tab
            // ! we need a separate function to do this
            // ! since we do not want to references tabListsRefs on the UI thread - it freezes the object
            // ! so it and cannot be extended
            const updateTabOffset = (event: ReanimatedScrollEvent) => {
                const listOffset = event.contentOffset.y;
                if (tabListRefs && tabKey) {
                    headerOffset.value = listOffset > stickyHeaderHeight ? stickyHeaderHeight : listOffset;
                    if (tabListRefs.current[tabKey]) {
                        tabListRefs.current[tabKey].offset = listOffset;
                    }
                }
            };

            return {
                onBeginDrag: () => {
                    'worklet';
                    // we started scrolling, so we:
                    // reset variables
                    shouldUpdateOffsetAfterPan.value = false;
                    isAnimatingPanHandler.value = false;
                    // cancel animation if it was running before (if the use panned before this scroll)
                    cancelAnimation(panValue);
                },

                onEndDrag: (event: ReanimatedScrollEvent) => {
                    'worklet';
                    // the scroll has finished so we update the current offsets
                    runOnJS(updateTabOffset)(event);
                },
                onMomentumEnd: (event: ReanimatedScrollEvent) => {
                    'worklet';
                    // the scroll has finished so we update the current offsets
                    runOnJS(updateTabOffset)(event);
                },
                onScroll: (event: ReanimatedScrollEvent) => {
                    'worklet';

                    // ! we save the height of the content of the current tab
                    // ! we need this to clamp the pan value if we are over scrolling
                    // ! we store this as the difference between the actual height of the full content
                    // ! and the height of the viewport since the scroll/pan value represents the "offset"
                    // ! with which we move the content
                    currentFocusedTabHeight.value = event.contentSize.height - event.layoutMeasurement.height;

                    // ! sync the panValue with the scroll value if currently there isn't any animation running
                    if (!shouldAnimatePanHandlerGestureEnd.value) {
                        panValue.value = -event.contentOffset.y;
                    }

                    // ! if the user has panned (not scrolled) the screen
                    // ! update the offset based on the last event
                    if (shouldUpdateOffsetAfterPan.value) {
                        runOnJS(updateTabOffset)(event);
                    }

                    // ! update the scroll value
                    scrollY.value = event.contentOffset.y;
                },
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [stickyHeaderHeight]
    );

    /**
     * Pan gesture handler for the `GestureDetector` that wraps around the header
     * This is used to manage all the interaction above the tabs
     * ! The pan gesture event is negative meaning that we have to reverse it each time we use it
     */
    const panGesture = Gesture.Pan()
        .withRef(panRef)
        .onStart(() => {
            // reset variables
            cancelAnimation(panValue);
            shouldUpdateOffsetAfterPan.value = false;
            prevPanValue.value = panValue.value;
        })
        .onUpdate(event => {
            isAnimatingPanHandler.value = true;
            // we do not let the user pan up (like for pull to refresh)
            if (event.translationY + prevPanValue.value > 0) {
                panValue.value = 0;
                return;
            }

            // update the pan value
            panValue.value = event.translationY + prevPanValue.value;
        })
        .onFinalize((event, success) => {
            // resets variable for end of panning animation
            shouldAnimatePanHandlerGestureEnd.value = true;
            shouldUpdateOffsetAfterPan.value = true;

            if (!success) {
                // if the gesture event is not successful we "finish" everything
                shouldAnimatePanHandlerGestureEnd.value = false;
                isAnimatingPanHandler.value = false;
                return;
            }

            // ! preserve momentum - continue the scroll using the last event velocity
            panValue.value = withClamp(
                {
                    max: 0,
                },
                withDecay(
                    {
                        velocity: event.velocityY,
                        deceleration: 0.999,
                        velocityFactor: Platform.OS === 'ios' ? 0.5 : 1,

                        // ! continue the "scroll" but clamp it to current tab content height
                        // ! so that we do not "bounce" too much on iOS
                        clamp: [-currentFocusedTabHeight.value, 0],
                        rubberBandEffect: Platform.OS === 'ios',
                        rubberBandFactor: 0.8,
                    },
                    () => {
                        // ! set "finish animation" flags
                        shouldAnimatePanHandlerGestureEnd.value = false;
                        isAnimatingPanHandler.value = false;
                    }
                )
            );
        });

    // header animation that slides up the header when the user scrolls down
    // the header is clamped so you cannot pull it down
    // meaning that the activity indicator should appear below to tabs (you still need to offset it)
    const smoothScrollHeaderStyleBelowTabsPullToRefresh = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: clamp(
                        interpolate(
                            scrollY.value,
                            [-stickyHeaderHeight, stickyHeaderHeight],
                            [stickyHeaderHeight, -stickyHeaderHeight],
                            Extrapolation.CLAMP
                        ),
                        -Infinity,
                        0
                    ),
                },
            ],
            position: 'absolute',
            zIndex: 1,
        };
    });

    // header animation that slides up the header when the user scrolls down
    // the header is not clamped so you can pull it down
    // in this way the activity indicator should appear above the whole content (tabs + header)
    const smoothScrollHeaderStyle = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: interpolate(
                        scrollY.value,
                        [-stickyHeaderHeight, stickyHeaderHeight],
                        [stickyHeaderHeight, -stickyHeaderHeight],
                        Extrapolation.CLAMP
                    ),
                },
            ],
            position: 'absolute',
            zIndex: 1,
        };
    });

    // ! update each tab's "scroll value" to the last header offset
    // ! if the offset of the tab is smaller than the header offset
    const scrollToOffsetForAllLists = useCallback(() => {
        const isHeaderVisible = headerOffset.value < stickyHeaderHeight;

        if (tabListRefs?.current) {
            Object.keys(tabListRefs.current).forEach(key => {
                const tabList = tabListRefs.current[key];

                // ! we want to manually scroll the tab if its offset is smaller then the header offset,
                // ! meaning that, if the header is scrolled "much more" than the list
                // ! we scroll the list to the header's position but only if the screen is not refreshing!
                if (
                    (isHeaderVisible || !tabList?.offset || tabList?.offset < headerOffset.value) &&
                    key !== currentlyRefreshingTab.value
                ) {
                    // ! offset can be negative if you are refreshing the list but we do not want that
                    const positiveOffset = headerOffset.value > 0 ? headerOffset.value : 0;
                    tabList.offset = positiveOffset;

                    // we need to cast to `ScrollView` since the `getScrollResponder` returns `Element`
                    // but we need a ScrollView (and we receive one from the default implementation of FlatList/SectionList)
                    (tabList.ref?.getScrollResponder() as unknown as ScrollView)?.scrollTo({
                        y: positiveOffset,
                        animated: false,
                    });
                }
            });
        }
    }, [headerOffset.value, stickyHeaderHeight, currentlyRefreshingTab.value]);

    const tabListeners = useMemo<TabListenerType>(
        () => ({
            tabPress: ({ preventDefault }) => {
                if (isActivelyScrolling.current || isAnimatingPanHandler?.value) {
                    preventDefault();
                } else {
                    scrollToOffsetForAllLists();
                }
            },
            swipeStart: () => {
                scrollToOffsetForAllLists();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [scrollToOffsetForAllLists]
    );

    return (
        <StickyTabsContext.Provider
            value={{
                __isStickyTabsContextInsideProvider: true,
                getScrollHandler,
                headerOffset,
                smoothScrollHeaderStyle,
                smoothScrollHeaderStyleBelowTabsPullToRefresh,
                scrollToOffsetForAllLists,
                panGesture,
                panValue,
                isAnimatingPanHandler,
                shouldUpdateOffsetAfterPan,
                tabListRefs,
                isActivelyScrolling,
                currentlyRefreshingTab,
                panRef,
                tabListeners,
                scrollY,
                headerHeight: stickyHeaderHeight,
                updateHeaderHeight: setStickyTabsHeaderHeight,
                currentFocusedTabHeight,
            }}
        >
            {children}
        </StickyTabsContext.Provider>
    );
};

/**
 * Hook to export the context of the `StickyTabsProvider`
 * ! use this hook only if you need more control over the context values
 * ! otherwise use the other hooks (useStickyTabList, useStickyTabsAnimation)
 */
export const useStickyTabs = () => {
    return useContext(StickyTabsContext);
};

/**
 * Hook to be used in each tab where we have our `FlatList` for which we want to listen for the scroll
 *
 * @param tabKey id for this tab so we can identify it
 * @param isRefreshing the "pull-to-refresh" loading state
 * @param refresh the function to be called when we want to refresh the tab
 * @returns {
 *      smoothScrollHeaderStyle: style to be applied if you want some other component on the screen to slide up on scroll
 *      setStickyRef: function to set the ref for our flatList. This is necessary if you want the scroll to work
 *      scrollableProps: other props to be "spread" on the FlatList
 *      fakeLoading: boolean to indicate if the fake refresh is ongoing
 *      fakeRefresh: function to trigger the fake refresh
 * }
 */
export const useStickyTabList = (
    tabKey: string,
    isRefreshing?: boolean,
    refresh?: (() => Promise<unknown>) | (() => void),
    showRefreshControlBelow?: boolean
) => {
    const {
        headerHeight,
        isAnimatingPanHandler,
        panValue,
        isActivelyScrolling,
        tabListRefs,
        headerOffset,
        getScrollHandler,
        currentlyRefreshingTab,
        __isStickyTabsContextInsideProvider,
    } = useStickyTabs();

    if (!__isStickyTabsContextInsideProvider) {
        warnAboutMissingProvider();
    }

    const isFocused = useIsFocused();
    const animatedListRef = useAnimatedRef();

    const onMomentumScrollBegin = useCallback(() => {
        isActivelyScrolling.current = true;
    }, [isActivelyScrolling]);

    const onMomentumScrollEnd = useCallback(() => {
        isActivelyScrolling.current = false;
    }, [isActivelyScrolling]);

    // ! we need two refs for each tab since we have limitation on each type due to their underlying implementation
    // ! (we cannot access the normal refs on the UI thread, and we cannot store the "animatedRefs" as an array)
    const setStickyRef = useCallback(
        (ref: FlatList<any> | null) => {
            if (!ref) {
                return;
            }

            // animatedRef so that we can scroll on the UI thread sing reanimated's `scrollTo`
            // used when we pan
            animatedListRef(ref);
            if (ref) {
                // also add the ref to the list of tabs
                // used when we want to sync the tabs when swiping the tabs
                tabListRefs.current = {
                    ...tabListRefs.current,
                    [tabKey]: { ref },
                };
            }
        },
        [tabListRefs, animatedListRef, tabKey]
    );

    const [tabHeight, setTabHeight] = useState(0);

    const onLayout = useCallback(
        (event: LayoutChangeEvent) => {
            if (tabHeight < event.nativeEvent.layout.height) {
                setTabHeight(event.nativeEvent.layout.height);
            }

            const activeTabList = tabListRefs.current[tabKey];
            // ! if is the first time we render this tab we want to be scrolled at the position of the header
            // we need to cast to `ScrollView` since the `getScrollResponder` returns `Element`
            // but we need a ScrollView (and we receive one from the default implementation of FlatList/SectionList)
            (activeTabList?.ref?.getScrollResponder() as unknown as ScrollView)?.scrollTo({
                y: headerOffset?.value ?? 0,
                animated: false,
            });
        },
        [tabHeight, tabListRefs, tabKey, headerOffset]
    );

    // ! When we pan, we store the pan value of the event, and with this value
    // ! we manually scroll the current focused tab list
    useFrameCallback((frameInfo: FrameInfo) => {
        const { timeSincePreviousFrame: dt } = frameInfo;
        if (isAnimatingPanHandler?.value && isFocused && dt !== null && dt > 0 && panValue?.value !== undefined) {
            scrollTo(animatedListRef, 0, -panValue?.value, false);
        }
    });

    // we check if the focused tab is refreshing so that we do not "sync" the scroll
    if (isFocused) {
        if (currentlyRefreshingTab) {
            if (isRefreshing) {
                currentlyRefreshingTab.value = tabKey;
            } else {
                currentlyRefreshingTab.value = '';
            }
        }
    }

    const onScroll = useAnimatedScrollHandler(getScrollHandler(tabKey));

    // ! by default, we want the "pull-to-refresh" animation to be above all content
    // ! so, we fake the loading time so that the user can see the animation (has feedback)
    // ! but doesn't have enough time to interact with the content
    // ! meaning he doesn't have enough time to swipe the tabs
    const fakeRefreshTimerRef = useRef<NodeJS.Timeout | null>(null);
    const [fakeLoading, setFakeLoading] = useState(false);
    const fakeRefresh = useCallback(
        // give the function to use a customCallback for the refresh
        // if somehow we render different lists on the same tab
        (customRefresh?: () => Promise<unknown>) => {
            setFakeLoading(true);

            Promise.resolve((customRefresh || refresh)?.()).then(() => {
                if (fakeRefreshTimerRef.current) {
                    clearTimeout(fakeRefreshTimerRef.current);
                }
                fakeRefreshTimerRef.current = setTimeout(() => {
                    setFakeLoading(false);
                }, 700);
            });
        },
        [refresh]
    );

    const refreshControl = useMemo(() => {
        let progressViewOffset = 0;
        if (showRefreshControlBelow) {
            progressViewOffset = headerHeight + TAB_HEIGHT;
        } else {
            if (Platform.OS !== 'ios') {
                if (fakeLoading) {
                    progressViewOffset = -23;
                } else {
                    progressViewOffset = -200;
                }
            }
        }

        return (
            <RefreshControl
                colors={[designSystem.colors.white]}
                tintColor={designSystem.colors.white}
                refreshing={fakeLoading}
                progressViewOffset={progressViewOffset}
                onRefresh={fakeRefresh}
            />
        );
    }, [fakeLoading, fakeRefresh, headerHeight, showRefreshControlBelow]);

    const scrollableProps = useMemo(
        () => ({
            onMomentumScrollBegin,
            onLayout,
            setStickyRef,
            onScroll,
            onMomentumScrollEnd,
            overScrollMode: 'always' as const,
            scrollToOverflowEnabled: true,
            refreshControl,
            contentContainerStyle: [
                common.grow,
                {
                    // ! push all the content down to make space for the sticky tabs
                    paddingTop: FULL_HEADER_HEIGHT,
                    // ! set the height of the available content to be size of the content + padding
                    // ! so that the tabs can be completely scrolled up or down
                    minHeight: tabHeight + FULL_HEADER_HEIGHT,
                },
            ],
        }),
        [onLayout, onMomentumScrollBegin, onMomentumScrollEnd, onScroll, refreshControl, setStickyRef, tabHeight]
    );

    useEffect(() => {
        return () => {
            if (fakeRefreshTimerRef.current) {
                clearTimeout(fakeRefreshTimerRef.current);
            }
        };
    }, []);

    return {
        scrollableProps,
        setStickyRef,
        fakeLoading,
        fakeRefresh,
        contentHeight: tabHeight,
        headerHeight,
        isActivelyScrolling,
    };
};

/**
 * Hook to be used when we want to animate some component on the screen when the user scrolls
 * Usually we will wrap the header above the tabs with this animation and also the tab bar itself
 * Also exports the pan gesture handler to be used with the `GestureDetector` that wraps around the header
 *
 * @returns {
 *      smoothScrollHeaderStyle: style to be applied if you want a component to slide up on scroll and be sticky (header)
 *      smoothScrollHeaderStyleBelowTabsPullToRefresh: style to be applied if you want a component to slide up on scroll and be sticky
 *          but the animation will be clamped at the top of the screen, so that the user can see the "pull-to-refresh" below the content
 *      panGesture: gesture handler to be used with the `GestureDetector` that wraps around the header
 *      tabListeners: listeners to be used with the `Tab.Navigator` to prevent the user from swiping the tabs when the header is animating
 * }
 */

export const useStickyTabsAnimation = () => {
    const stickyContext = useStickyTabs();

    if (!stickyContext.__isStickyTabsContextInsideProvider) {
        warnAboutMissingProvider();
    }

    const {
        smoothScrollHeaderStyle,
        smoothScrollHeaderStyleBelowTabsPullToRefresh,
        panGesture,
        tabListeners,
        headerHeight,
        scrollY,
        updateHeaderHeight,
    } = stickyContext;

    return {
        smoothScrollHeaderStyle,
        smoothScrollHeaderStyleBelowTabsPullToRefresh,
        panGesture,
        tabListeners,
        headerHeight,
        scrollY,
        updateHeaderHeight,
    };
};

export type ScrollableProps = ReturnType<typeof useStickyTabList>['scrollableProps'];
export type StickyRefSetter = ReturnType<typeof useStickyTabList>['setStickyRef'];
