import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { PromohubStackParamList } from '../../navigation/types';
import { PromohubScreen } from './screens/Promohub';

const Stack = createNativeStackNavigator<PromohubStackParamList>();

export const PromoHubStackTabs = () => {
    return <PromohubStack mode="tabs" />;
};

export const PromoHubStack = () => {
    return <PromohubStack mode="stack" />;
};

export const PromohubStack = ({ mode }: { mode: 'tabs' | 'stack' }) => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Promohub" component={PromohubScreen} initialParams={{ mode }} />
        </Stack.Navigator>
    );
};
