import { URLS } from '@/data';

import { useJurisdictionStore } from './use-jurisdiction';

/**
 * Hook to get the support URL from Prismic. If it's not available, it will fall back to the default support URL.
 * @returns {string} The support URL.
 */
export const useSupportUrl = () => {
    const supportUrl: string =
        useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.keyValuePairs.URLS.SUPPORT_URL) ||
        URLS.SUPPORT_URL;

    return supportUrl;
};
