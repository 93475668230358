import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import BankAltIcon from '@/assets/icons/bankAlt';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useWallet } from '@/hooks/use-wallet';

import { AddPaymentMethod } from './AddPaymentMethod';

type PaymentMethodPayByBankProps = {
    selectedAmount: string;
};

export const AddPaymentMethodPayByBank = ({ selectedAmount }: PaymentMethodPayByBankProps) => {
    const { t } = useTranslation('transactions');
    const navigation = useNavigation();
    const { wallet } = useWallet();

    return (
        <AddPaymentMethod
            icon={<BankAltIcon />}
            title={t('add_bank_account')}
            description={t('add_pay_by_bank_description')}
            onPress={() => {
                navigation.navigate('PayByBankDepositWebViewModal', {
                    selectedAmount,
                    currency: wallet?.real_currency,
                });
            }}
            analyticsEvent={AnalyticsEvent.DEPOSIT_METHOD_ADD_PAY_BY_BANK}
        />
    );
};
