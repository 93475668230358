import { PlayerProjectionFragment } from '@/api/events/query.generated';
import { PickOutput, Projection, ProjectionType } from '@/types/api.generated';

export const getProjectionsTypes = (
    picks?: Pick<PickOutput, 'projection'>[] | Pick<Projection, 'type'>[] | [] | undefined
) => {
    const hasBoostedPicks = picks?.some(pick => {
        if ('projection' in pick) {
            return pick.projection.type === ProjectionType.Boosted;
        } else {
            if ('type' in pick) {
                return pick.type === ProjectionType.Boosted;
            }
        }
        return false;
    });

    const hasSpecialPicks = picks?.some(pick => {
        if ('projection' in pick) {
            return pick.projection.type === ProjectionType.Special;
        } else {
            if ('type' in pick) {
                return pick.type === ProjectionType.Special;
            }
        }
        return false;
    });

    const hasMultipleProjectionTypes = hasBoostedPicks && hasSpecialPicks;

    return {
        hasBoostedPicks,
        hasSpecialPicks,
        hasMultipleProjectionTypes,
    };
};

/*
This function is similar to the above function, but it returns more detailed information about Special projections
It can be concatenated with the above function when needed
*/
export const getNonRegularProjections = (projections?: PlayerProjectionFragment[] | undefined) => {
    let boostedCount = 0;
    let specialCount = 0;
    let hasBoostedProjections = false;
    let hasSpecialIncreasedProjections = false;
    let hasSpecialDiscountProjections = false;
    const nonRegularProjectionTypes: Set<ProjectionType> = new Set();

    projections?.forEach(projection => {
        if ('type' in projection) {
            if (projection.type === ProjectionType.Boosted) {
                nonRegularProjectionTypes.add(projection.type);
                hasBoostedProjections = true;
                boostedCount = boostedCount + 1;
            } else if (
                projection.type === ProjectionType.Special &&
                projection.nonRegularPercentage &&
                projection.nonRegularPercentage > 0
            ) {
                nonRegularProjectionTypes.add(projection.type);
                hasSpecialIncreasedProjections = true;
                specialCount = specialCount + 1;
            } else if (
                projection.type === ProjectionType.Special &&
                projection.nonRegularPercentage &&
                projection.nonRegularPercentage < 0
            ) {
                nonRegularProjectionTypes.add(projection.type);
                hasSpecialDiscountProjections = true;
                specialCount = specialCount + 1;
            }
        }
    });

    const hasMultipleNonRegularProjections =
        (hasBoostedProjections && (hasSpecialIncreasedProjections || hasSpecialDiscountProjections)) ||
        (hasSpecialIncreasedProjections && hasSpecialDiscountProjections);

    return {
        hasBoostedProjections,
        hasSpecialIncreasedProjections,
        hasSpecialDiscountProjections,
        hasMultipleNonRegularProjections,
    };
};

export const getColorByProjType = (projectionType: ProjectionType, specialIncrease?: boolean) => {
    if (projectionType === ProjectionType.Boosted) {
        return 'boosted';
    }
    if (projectionType === ProjectionType.Special) {
        return specialIncrease ? 'surge' : 'special';
    }
    return 'white';
};
