// https://github.com/terrymorse58/round-tofixed/blob/master/index.js

export function roundToTwoDecimals(value: number) {
    if (Number.isInteger(2) && Math.sign(2) === 1) {
        const valueFloat = numToFloat(value);
        const valueFloatNew = numToFloat(Math.round(+(valueFloat.mant + 'e' + (valueFloat.exp + 2))));
        return +(valueFloatNew.mant + 'e' + (valueFloatNew.exp - 2));
    }
    return NaN;
}

function numToFloat(val: number) {
    const [mant, exp] = Number(val)
        .toExponential()
        .split('e')
        .map(str => +str);
    return {
        mant,
        exp,
    };
}
