import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';
import {
    RestrictedComboModal,
    RestrictedComboModalRef,
    RestrictedComboProps,
} from '@/feature/betslip-pickem/components/RestrictedComboModal';

type RestrictedCombo = {
    showRestrictedComboModal: (props: RestrictedComboProps) => void;
};

const RestrictedComboContext = React.createContext<RestrictedCombo>({
    showRestrictedComboModal: () => {},
});
/**
 * Provides access to the Modal used to display the 2 players that are in a restricted combination
 */
export const RestrictedCombinationModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<RestrictedComboModalRef>(null);

    const showRestrictedComboModal = useCallback((props: RestrictedComboProps) => {
        modalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <RestrictedComboContext.Provider value={{ showRestrictedComboModal }}>
                {children}
                <RestrictedComboModal ref={modalRef} />
            </RestrictedComboContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useRestrictedCombo = () => {
    return useContext(RestrictedComboContext);
};
