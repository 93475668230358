import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={29} height={28} fill="none" {...props}>
        <G fill="#1E1F24" clipPath="url(#a)">
            <Path d="M19.816 4.932c.29 0 .577.013.86.038a10.497 10.497 0 0 0-6.24-2.053C8.497 2.917 3.68 7.879 3.68 14H.5l6.417 7.053L13.214 14h-3.13c.49-5.092 4.66-9.068 9.732-9.068ZM9.185 23.068c-.29 0-.577-.013-.86-.038a10.497 10.497 0 0 0 6.24 2.053c5.94 0 10.757-4.962 10.757-11.083H28.5l-6.416-7.053L15.787 14h3.13c-.49 5.092-4.66 9.068-9.732 9.068Z" />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M.5 0h28v28H.5z" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgComponent;
