import React, { useCallback, useEffect, useState } from 'react';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import { Text } from '@/components/TextComponent';
import { Box, Row, TouchableBox } from '@/components/lib/components';
import { DesignSystemColor } from '@/components/lib/theme';
import { DepositButton } from '@/feature/account-details/components/DepositButton';
import { Currency } from '@/types/api.generated';
import { toLocaleCurrency } from '@/utils/numeric/currency';

const AnimatedBox = Animated.createAnimatedComponent(Box);

type CurrencyTab = {
    currency: Currency;
    amount: number;
    title: string;
};

type Props = {
    activeCurrency: Currency;
    currencies: CurrencyTab[];
    showDepositButton?: boolean;
    autoSwitchToNonZero?: boolean;
    onCurrencyPress: (currency: Currency) => void;
    disabled?: boolean;
    switchColor?: DesignSystemColor;
};

export const CurrencySwitcher = ({
    onCurrencyPress,
    activeCurrency,
    currencies,
    showDepositButton,
    autoSwitchToNonZero,
    disabled,
    switchColor = 'gray5',
}: Props) => {
    const [tabDimensions, setTabDimensions] = useState({ width: 0, height: 0 });

    const initialCurrencyIndex = currencies.findIndex(tab => tab.currency === activeCurrency);
    const position = useSharedValue(initialCurrencyIndex);

    const handleTabPress = useCallback(
        (selectedCurrency: Currency) => {
            const toValue = currencies.findIndex(tab => tab.currency === selectedCurrency);
            position.value = withTiming(toValue, { duration: 250 });
            onCurrencyPress(selectedCurrency);
        },
        [currencies, position, onCurrencyPress]
    );

    const indicatorStyle = useAnimatedStyle(() => {
        return {
            transform: [{ translateX: position.value * tabDimensions.width }],
        };
    }, [tabDimensions.width]);

    // if the active currency has amount 0, switch to the first currency with amount > 0
    useEffect(() => {
        if (autoSwitchToNonZero) {
            const activeCurrencyTab = currencies.find(tab => tab.currency === activeCurrency);
            if (activeCurrencyTab && activeCurrencyTab.amount <= 0) {
                const firstCurrencyTabWithAmount = currencies.find(tab => tab.amount > 0);
                if (firstCurrencyTabWithAmount) {
                    handleTabPress(firstCurrencyTabWithAmount.currency);
                }
            }
        }
    }, [activeCurrency, currencies, handleTabPress, autoSwitchToNonZero]);

    if (showDepositButton) {
        return <DepositButton />;
    }

    return (
        <Row borderWidth={1} borderColor={'gray5'} alignItems={'center'} borderRadius={'r26'} p={'s3'}>
            <AnimatedBox
                style={indicatorStyle}
                backgroundColor={switchColor}
                borderRadius={'r22'}
                position={'absolute'}
                left={3}
                width={tabDimensions.width}
                height={tabDimensions.height}
            />
            {currencies.map(tab => {
                return (
                    <TouchableBox
                        key={`currency-switcher-${tab.currency}`}
                        onPress={() => handleTabPress(tab.currency)}
                        disabled={tab.amount <= 0 || tab.currency === activeCurrency || disabled}
                        paddingVertical={'s3'}
                        flex={1}
                        alignItems={'center'}
                        activeOpacity={0.8}
                        onLayout={event =>
                            setTabDimensions({
                                width: event.nativeEvent.layout.width,
                                height: event.nativeEvent.layout.height,
                            })
                        }
                    >
                        <Text variant={'titleSmall'}>{toLocaleCurrency(tab.amount)}</Text>
                        <Text variant={'labelMedium'} fontWeight={'400'} color={'gray2'}>
                            {tab.title}
                        </Text>
                    </TouchableBox>
                );
            })}
        </Row>
    );
};
