import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

function SvgComponent(props) {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" width={171} height={48} viewBox="0 0 171 48" fill="none" {...props}>
            <Path
                d="M153.562 48c-3.57 0-6.7-.683-9.388-2.05-2.689-1.366-4.783-3.217-6.281-5.553-1.499-2.336-2.314-4.959-2.447-7.868l10.116-.198c.176 1.322.595 2.49 1.256 3.504.661.97 1.543 1.763 2.645 2.38 1.102.573 2.446.86 4.033.86 1.498 0 2.733-.199 3.702-.596 1.014-.44 1.763-.991 2.248-1.653.485-.66.728-1.41.728-2.248 0-1.101-.397-2.027-1.191-2.776-.749-.75-1.696-1.367-2.843-1.852-1.146-.528-2.776-1.146-4.892-1.85-3.041-1.015-5.554-2.006-7.537-2.976-1.984-1.014-3.681-2.468-5.091-4.364-1.411-1.895-2.116-4.32-2.116-7.272 0-2.6.639-4.915 1.917-6.943 1.323-2.027 3.218-3.614 5.686-4.76C146.62.595 149.573 0 152.967 0c3.173 0 6.016.661 8.529 1.983 2.512 1.323 4.474 3.152 5.884 5.488 1.455 2.292 2.27 4.826 2.446 7.603l-10.115.199c-.088-1.278-.419-2.38-.992-3.306a5.849 5.849 0 00-2.38-2.248c-.97-.529-2.116-.793-3.438-.793-1.278 0-2.402.22-3.372.66-.926.397-1.631.948-2.116 1.654-.485.705-.727 1.52-.727 2.446 0 1.058.375 1.983 1.124 2.777.793.75 1.763 1.366 2.909 1.851 1.146.44 2.755 1.014 4.826 1.72 3.086.969 5.62 1.939 7.604 2.908 1.983.926 3.68 2.314 5.091 4.165 1.41 1.852 2.115 4.254 2.115 7.207 0 2.6-.661 4.937-1.983 7.008-1.278 2.072-3.196 3.703-5.752 4.893-2.557 1.19-5.576 1.785-9.058 1.785z"
                fill={props.color ?? designSystem.colors.gray1}
            />
            <Path
                d="M124.843 47.138l-16.727-23.47 16-22.81h11.835l-17.785 24.66v-4.43l19.107 26.05h-12.43zm-24.86 0V.858H110.1v46.28H99.984zM75.08 48c-4.142 0-7.977-1.058-11.503-3.174-3.482-2.16-6.26-5.068-8.33-8.727-2.072-3.702-3.108-7.735-3.108-12.099s1.035-8.375 3.107-12.033c2.072-3.703 4.849-6.612 8.33-8.727C67.104 1.08 70.939 0 75.082 0c3.879 0 7.295.86 10.248 2.579 2.953 1.719 5.333 3.9 7.14 6.545 1.852 2.6 3.108 5.355 3.769 8.264l-10.446.199c-.397-1.322-1.058-2.579-1.984-3.769-.926-1.234-2.138-2.226-3.636-2.975-1.455-.793-3.152-1.19-5.091-1.19-2.292 0-4.43.617-6.413 1.851-1.94 1.234-3.505 2.953-4.695 5.157-1.146 2.204-1.719 4.65-1.719 7.339s.573 5.135 1.72 7.339c1.19 2.204 2.754 3.923 4.694 5.157 1.983 1.234 4.12 1.851 6.413 1.851 2.82 0 5.157-.771 7.008-2.314 1.895-1.587 3.218-3.438 3.967-5.554l10.512.199c-.66 2.82-1.917 5.553-3.768 8.198-1.807 2.645-4.232 4.827-7.273 6.546C82.53 47.14 79.047 48 75.081 48zM38.235 47.138V.858H48.35v46.28H38.235zM0 47.138V.858h18.05c3.085 0 5.862.683 8.33 2.049 2.513 1.366 4.474 3.262 5.884 5.686 1.455 2.38 2.182 5.069 2.182 8.066 0 2.997-.727 5.708-2.181 8.132-1.411 2.38-3.372 4.276-5.885 5.686-2.468 1.367-5.245 2.05-8.33 2.05H8.529v-9.52h9.124a7.04 7.04 0 003.306-.794c1.014-.573 1.807-1.323 2.38-2.248.617-.97.926-2.072.926-3.306 0-1.19-.31-2.27-.926-3.24a5.85 5.85 0 00-2.38-2.248 6.605 6.605 0 00-3.306-.859H10.05v17.587l.066.595v18.644H0z"
                fill={props.color ?? designSystem.colors.gray1}
            />
        </Svg>
    );
}

export default SvgComponent;
