import React, { PropsWithChildren, useMemo } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import { appTheme } from '@/components/lib/theme';
import { createUrqlClient, queryClient } from '@/data';
import { common } from '@/styles/styles';
import { ThemeProvider } from '@shopify/restyle';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'urql';

export const ReactQueryClientProvider = ({ children }: PropsWithChildren) => {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export const FantasyUrqlClientProvider = ({ children }: PropsWithChildren) => {
    const client = useMemo(() => createUrqlClient(), []);
    return <Provider value={client}>{children}</Provider>;
};

export const GestureHandlerRootViewProvider = ({ children }: PropsWithChildren) => {
    return <GestureHandlerRootView style={common.flex}>{children}</GestureHandlerRootView>;
};

export const BetrThemeProvider = ({ children }: PropsWithChildren) => {
    return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};
