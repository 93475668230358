import React, { Fragment } from 'react';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { DesignSystemColor } from '@/components/lib/theme';
import { MarketOption } from '@/feature/betslip-pickem/components/PlayerCard';
import { common } from '@/styles/styles';
import { Outcome } from '@/types/api.generated';

import { PickProjectionButton } from './PickProjectionButton';

type Props = {
    selection: Outcome | undefined;
    onSelection: (o: Outcome) => void;
    onRemove: () => void;
    allowedOutcomes: Array<MarketOption>;
    backgroundColor?: DesignSystemColor;
    showButtonsLabels: boolean;
    testID?: string;
};

/**
 * PickProjectionButtonsSection renders both More & Less buttons, used for picking a player's projection
 * These 2 PickProjectionButton are usually used together with the same conditions
 */
export const PickProjectionButtonsSection = ({
    onSelection,
    onRemove,
    allowedOutcomes,
    selection,
    backgroundColor,
    showButtonsLabels,
    testID,
}: Props) => {
    const onPress = (outcome: Outcome) => {
        if (outcome === selection) {
            onRemove();
        } else {
            onSelection(outcome);
        }
    };

    return (
        <Box style={common.spaceBetweenRow}>
            {allowedOutcomes?.map((marketOption: MarketOption, index: number) => {
                const spaceBetween = allowedOutcomes.length === 2 && index === 0 ? 12 : 0;
                const outcome = marketOption.outcome;
                return (
                    <Fragment key={`pick-${index}`}>
                        <Box>
                            <PickProjectionButton
                                outcome={outcome}
                                isSelected={selection === outcome}
                                onPress={() => onPress(outcome)}
                                key={`pick-${outcome}`}
                                backgroundColor={backgroundColor}
                                testID={testID}
                            />
                            {showButtonsLabels ? (
                                <Text
                                    variant={'bodySmall'}
                                    color={'gray2'}
                                    textAlign={'center'}
                                    textTransform="capitalize"
                                    mt={'s8'}
                                >
                                    {outcome}
                                </Text>
                            ) : null}
                        </Box>

                        <SizedBox value={spaceBetween} />
                    </Fragment>
                );
            })}
        </Box>
    );
};
