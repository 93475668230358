import { logger } from '../logging';

const LOG_TAG = '[Camera WEB]: ';

export const hasCameraPermission = async () => {
    try {
        const result = await navigator.permissions.query({ name: 'camera' });
        return result.state === 'granted';
    } catch (e) {
        logger.warn(LOG_TAG, 'Permission check error: ', e);
        return false;
    }
};

export const requestCameraPermission = async () => {
    try {
        const stream = await navigator.mediaDevices?.getUserMedia({ video: true });
        stream?.getTracks().forEach(track => {
            // we have permission granted, but we stop the camera light
            track.stop();
        });
    } catch (e) {
        logger.warn(LOG_TAG, 'Camera permission request error: ', e);
    }
};

export const requestCameraPermissionIfNotGranted = async () => {
    const hasPermission = await hasCameraPermission();
    if (!hasPermission) {
        await requestCameraPermission();
    }
};
