import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';
import { AddLineupModal, AddLineupModalRef, AddLineupProps } from '@/feature/entry-share/screens/AddLineupModal';

type AddLineupContextType = {
    showAddLineupModal: (props: AddLineupProps) => void;
};

const AddLineupContext = React.createContext<AddLineupContextType>({
    showAddLineupModal: () => {},
});

/**
 * Provides access to the Modal used to display the lineup shared via link
 */
export const AddLineupModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const addLineupModalRef = useRef<AddLineupModalRef>(null);

    const showAddLineupModal = useCallback((props: AddLineupProps) => {
        addLineupModalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <AddLineupContext.Provider value={{ showAddLineupModal }}>
                {children}
                <AddLineupModal ref={addLineupModalRef} />
            </AddLineupContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useAddLineup = () => {
    return useContext(AddLineupContext);
};
