import AsyncStorage from '@react-native-async-storage/async-storage';

export const save = async (key: string, value: object | string | undefined | null) => {
    await AsyncStorage.setItem(key, JSON.stringify(value));
};

export const read = async (key: string) => {
    const result = await AsyncStorage.getItem(key);
    return result ? JSON.parse(result) : null;
};

export const remove = async (key: string) => {
    await AsyncStorage.removeItem(key);
};

export const clearAllKeysWithExceptions = async () => {
    // Add AsyncStorage keys that you do not want to be cleared here.
    const EXCEPTIONS = ['@phrase'];

    const keys = await AsyncStorage.getAllKeys();
    const keysToClear = keys.filter(key => !EXCEPTIONS.some(exception => key.includes(exception)));
    await AsyncStorage.multiRemove(keysToClear);
};
