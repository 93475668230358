import React, { useCallback } from 'react';

import { useNavigation } from '@react-navigation/native';

import Betr from '@/assets/icons/betr-small';
import PicksLogo from '@/assets/icons/picksLogo';
import SportsbookLogo from '@/assets/icons/sbkLogo';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useProductSwitchModal } from '@/hooks/use-product-switch';
import { common } from '@/styles/styles';

import { ProductSwitch } from './ProductSwitch';
import { SizedBox } from './SizedBox';
import { Row } from './lib/components';

const logoMap = {
    [Product.Pickem]: <PicksLogo />,
    [Product.Sportsbook]: <SportsbookLogo />,
} as const;

export const PRODUCT_SWITCH_BUTTON_HEIGHT = 60;

export const LobbyProductSwitchButton = () => {
    const navigation = useNavigation();
    const activeProduct = useJurisdictionStore(state => state.product);
    const { switchingAvailable } = useProductSwitchModal();

    const triggerSwitchAnimation = useCallback(
        (product: Product) => {
            if (activeProduct !== product) {
                navigation.navigate('ProductTransitionScreen', { product });
            }
        },
        [activeProduct, navigation]
    );

    return (
        <Row style={[common.justifySpaceBetween, common.paddingHorizontal, { height: PRODUCT_SWITCH_BUTTON_HEIGHT }]}>
            {switchingAvailable ? (
                <Row
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flex={1}
                    paddingHorizontal={'s16'}
                    paddingVertical={'s12'}
                >
                    <Betr />
                    <ProductSwitch activeProduct={activeProduct} onPressValue={triggerSwitchAnimation} />
                    {/* 24 - size of the loo - ensures the product is centered */}
                    <SizedBox value={24} />
                </Row>
            ) : (
                <Row
                    flex={1}
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingVertical={'s18'}
                    testID={`activeProduct-${activeProduct}`}
                >
                    {activeProduct !== Product.None ? logoMap[activeProduct] : null}
                </Row>
            )}
        </Row>
    );
};
