import { NavigationProp } from '@react-navigation/native';

import { URLS, getAuthRedirectURI } from '@/data';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AuthUserConfigErrorType } from '@/hooks/use-auth-user-config';
import { actions } from '@/hooks/use-user';
import { RootStackParamList } from '@/navigation/types';
import { isWeb } from '@/utils/constants-platform-specific';
import { isErrorResponse } from '@/utils/errors/response-handler';
import { logger } from '@/utils/logging';
import i18next from 'i18next';

import { AnalyticsEvent } from '../analytics/constants';

export type AuthType = 'login' | 'register';

/**
 * Returns the Keycloak URL based on the authentication type, initial email, and oneLink data.
 * @param authType - The authentication type ('login' or 'register').
 * @param initialEmail - The initial email to prefill in the login form (optional).
 * @param promoCode - The promo code to be prefilled during register (optional).
 * @param isWebRegister - Whether the user is registering (adding ?registering='true' to redirect_uri) (optional).
 * @returns The Keycloak URL with the specified parameters.
 */
export const getKeycloakUrl = (
    authType: AuthType,
    initialEmail?: string,
    promoCode?: string,
    isWebRegister?: boolean
) => {
    const query = new URLSearchParams();
    query.append('client_id', URLS.KEYCLOAK_CLIENT_ID);
    query.append('redirect_uri', getAuthRedirectURI(isWebRegister));
    query.append('grant_type', 'code');
    query.append('response_type', 'code');
    query.append('audience', URLS.KEYCLOAK_AUTH_AUDIENCE_URL);
    query.append('scope', 'openid profile email offline_access');
    if (initialEmail) {
        query.append('login_hint', initialEmail);
    }
    if (promoCode) {
        logger.info('Promo code prefill:', promoCode);
        query.append('promo_code', promoCode);
    }
    if (isWeb) {
        query.append('platform_os', 'web');
    }

    const baseUrl = authType === 'login' ? URLS.KEYCLOAK_AUTH_URL : URLS.KEYCLOAK_REGISTER_URL;
    return baseUrl + '?' + query.toString();
};

export const parseCode = (url: string) => {
    const match = /\?(.+)$/g.exec(url);
    if (match) {
        const [, params] = match;
        const paramPairs = params.split('&').map(part => part.split('='));
        const parsed = new Map<string, string>();
        for (const [key, value] of paramPairs) {
            parsed.set(key, value);
        }

        const code = parsed.get('code');
        if (code !== undefined) {
            return code;
        }
    }
};

/**
 * Handles the error when a user's account is blocked.
 * If so, will navigate to the 'ErrorModal' screen.
 */
export const handleUserBlockedError = (
    err: unknown,
    navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>
) => {
    if (isErrorResponse<AuthUserConfigErrorType>(err) && err.error_codes.includes('user_account_block')) {
        navigateToAccountSuspendedModal(navigation);
    }
};

export const navigateToAccountSuspendedModal = (navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>) => {
    navigation.navigate('ErrorModal', {
        title: i18next.t('account:suspended'),
        subtitle: i18next.t('account:suspended_subtext'),
        primaryButton: i18next.t('common:contact_support'),
        showSecondaryButton: true,
        gestureEnabled: false,
        handlePress: async () => {
            BetrAnalytics.trackEvent(AnalyticsEvent.LOGIN_SUSPENDED_CONTACT_SUPPORT);
            await actions.logout();
            navigation.navigate('ModalWebView', { uri: URLS.SUPPORT_URL });
        },
        handleSecondaryButtonPress: async () => {
            BetrAnalytics.trackEvent(AnalyticsEvent.LOGIN_SUSPENDED_DISMISS);
            await actions.logout();
        },
        closeIconHandlePress: async () => {
            BetrAnalytics.trackEvent(AnalyticsEvent.LOGIN_SUSPENDED_ABORTED);
            await actions.logout();
        },
    });
};
