import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={18} height={20} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path d="M18 8H0L9 0L18 8Z" fill="white" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M2 18V10H4V18H2Z" fill="white" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M6 18V10H8V18H6Z" fill="white" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M10 18V10H12V18H10Z" fill="white" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M14 18V10H16V18H14Z" fill="white" />
        <Path d="M0 18H18V20H0V18Z" fill="white" />
    </Svg>
);

export default SvgComponent;
