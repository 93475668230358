import { Result } from '@/types/api.generated';

type EntryPayoutParams = {
    picks?: { result?: Result }[];
};

export const getEntryPayout = ({ picks }: EntryPayoutParams) => {
    const voidedPicksCount = picks?.filter(pick => pick?.result === 'VOID')?.length;

    return {
        voidedCount: voidedPicksCount,
        availableCount: picks?.length,
    };
};
