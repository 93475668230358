import { Platform } from 'react-native';

// native driver is not available on the web, it should always be set to false there
// this will be true for mobile platforms: android & ios
export const nativeDriverValue = Platform.OS !== 'web';
// this specific 'cache-control:no-cache' header is automatically added on the web
// to avoid the failure in executing the request on the web we will not send it
export const cacheControlHeader = Platform.OS !== 'web' ? { 'Cache-Control': 'no-cache' } : null;
// show scrollIndicator for the web platform only
export const showScrollIndicator = Platform.OS === 'web';

// max width for the web platform
export const MAX_WEB_WIDTH = 1216;

export const isWeb = Platform.OS === 'web';
export const isIos = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';

// modal paddings for the web platform
export const MODAL_PADDINGS = 64;
export const VERTICAL_MODAL_PADDINGS = 96; // includes "x" button height
