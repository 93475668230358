import React from 'react';

import BetrLogo from '@/assets/icons/betr-full';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';

/*
 * This screen is displayed on the web when the user starts the app from outside the US
 * */
export const IneligibleCountryScreen = () => {
    return (
        <Box alignItems={'center'} justifyContent={'center'} flex={1} paddingHorizontal="s20">
            <Box alignItems={'center'} alignSelf={'center'} justifyContent={'center'}>
                <BetrLogo />
                <Text color={'white'} variant={'headlineSmall'} mt={'s24'} textAlign={'center'}>
                    This App is not supported in your country
                </Text>
                <Text variant={'titleMedium'} textAlign={'center'}>
                    You are attempting to access Betr from a location where we do not operate. Betr is available only in
                    the US.
                </Text>
            </Box>
        </Box>
    );
};
