import { format } from 'date-fns';

export const renderDate = (inputDate: Date): string => {
    if (inputDate.toDateString() === 'Invalid Date') {
        return '';
    }
    const today = new Date();
    const tomorrow = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    tomorrow.setDate(today.getDate() + 1);

    if (inputDate.toDateString() === today.toDateString()) {
        return format(inputDate, "'Today,' h:mm a");
    } else if (inputDate > today && inputDate < nextWeek) {
        return format(inputDate, 'eee, h:mm a');
    } else if (inputDate > nextWeek) {
        return `${format(inputDate, 'M/dd')}, ${format(inputDate, 'h:mm a')}`;
    } else {
        return `${format(inputDate, 'M/dd')}, ${format(inputDate, 'h:mm a')}`;
    }
};
