import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { RootStackParamList } from '@/navigation/types';

import { LimitsModal } from '../components/LimitModal';
import { LIMIT_DURATION } from '../constants';
import { useAddLimitErrorMessage } from '../hooks/use-add-limit-error-message';
import { useAddUserLimits } from '../hooks/use-add-user-limits';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'EntryLimitAdd'>;

export const EntryLimitAdd = ({ route: { params } }: ScreenProps) => {
    const { t } = useTranslation('rg');
    const navigation = useNavigation();
    const { mutate: addLimit, isLoading } = useAddUserLimits();
    const duration = params.duration;
    const { getErrorMessage } = useAddLimitErrorMessage(duration, 'WAGER');

    const handlePress = (amount: number) => {
        addLimit(
            { type: 'WAGER', duration: duration, amount },
            {
                onSuccess: () => {
                    BetrAnalytics.trackProductEvent(AnalyticsEvent.CONFIRM_ADD_PLAY_LIMIT, { duration, amount });
                    trackRG.playLimitAddScreen(`${duration} Play Limit`, {
                        duration,
                        amount,
                    });
                    navigation.goBack();
                },
            }
        );
    };

    const title = t('duration_play_limit', { duration: LIMIT_DURATION[duration] });

    return (
        <LimitsModal
            title={title}
            buttonLabel={t('add_limit')}
            handleButtonPress={handlePress}
            isButtonDisabled={isLoading}
            errorMessageHandler={getErrorMessage}
        />
    );
};
