import React, { memo, useCallback, useRef, useState } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import Animated, {
    SharedValue,
    interpolateColor,
    runOnJS,
    useAnimatedReaction,
    useAnimatedScrollHandler,
    useAnimatedStyle,
    useSharedValue,
} from 'react-native-reanimated';

import { Box } from '@/components/lib/components/Box';
import { SEPARATOR_HEGIHT, designSystem } from '@/styles/styles';

const AFlatList = Animated.createAnimatedComponent(FlatList<Values>);

type Values = {
    title: string;
};

type Props = {
    values: Values[];
    onChangeLine?: (index: number) => void;
    selectedTab?: number;
    scrollValue: SharedValue<number>;
};

const ITEM_WIDTH = 64;
const MARGIN_RIGHT = 0;
const SCREEN_WIDTH = Dimensions.get('window').width - 2 * MARGIN_RIGHT;

const ITEMS_PER_SCREEN = Math.round(SCREEN_WIDTH / ITEM_WIDTH);

const CONTENT_CONTAINER_PADDING = SCREEN_WIDTH / 2 - ITEM_WIDTH / 2;

const TAB_SCROLL_OFFSET = CONTENT_CONTAINER_PADDING - SCREEN_WIDTH / 2 + ITEM_WIDTH / 2;

const clampInterval = (value: number, lowerBound: number, upperBound: number) => {
    'worklet';
    return Math.min(Math.max(lowerBound, value), upperBound);
};

export const Dial = memo(({ values, scrollValue, onChangeLine = () => {}, selectedTab = 0 }: Props) => {
    const [hasRendered, setHasRendered] = useState(false);
    const scrollViewRef = useRef<FlatList<Values>>(null);

    const handlePress = useCallback((index: number) => {
        scrollViewRef.current?.scrollToOffset({
            offset: index * ITEM_WIDTH + TAB_SCROLL_OFFSET,
            animated: true,
        });
    }, []);

    // ! used to manage all internal scrolling scrollValue is not provided
    const internalScrollValue = useSharedValue(0);

    const scrollHandler = useAnimatedScrollHandler({
        onScroll: event => {
            // ! sync both animation values to scroll offset
            internalScrollValue.value = event.contentOffset.x;
            if (scrollValue) {
                scrollValue.value = event.contentOffset.x;
            }
        },
    });

    const setInitialTabScroll = useCallback(() => {
        setHasRendered(true);
        scrollViewRef.current?.scrollToOffset({
            offset: selectedTab * ITEM_WIDTH + TAB_SCROLL_OFFSET,
            animated: false,
        });
    }, [selectedTab]);

    useAnimatedReaction(
        () => {
            const newTab = Math.round((internalScrollValue.value - TAB_SCROLL_OFFSET * 2) / ITEM_WIDTH);
            const clampedTab = clampInterval(newTab, 0, values.length - 1);

            return clampedTab;
        },
        (currentTab, previousTab) => {
            if (hasRendered && currentTab !== previousTab) {
                onChangeLine && runOnJS(onChangeLine)(currentTab);
            }
        }
    );

    // ! make sure the selectedTab is rendered and can be centered when `onLayout` triggers
    const initialNumToRender = selectedTab + ITEMS_PER_SCREEN;

    return (
        <View style={styles.modalContainer}>
            <IndicatorLine />
            <AFlatList
                onScroll={scrollHandler}
                horizontal
                initialNumToRender={initialNumToRender}
                showsHorizontalScrollIndicator={false}
                ref={scrollViewRef}
                snapToInterval={ITEM_WIDTH}
                contentContainerStyle={styles.scrollViewContentContainer}
                scrollEventThrottle={16}
                decelerationRate={0.0001}
                onLayout={setInitialTabScroll}
                data={values}
                renderItem={({ item, index }) => (
                    <Label
                        key={index}
                        isSelected={selectedTab === index}
                        title={item.title}
                        index={index}
                        handlePress={handlePress}
                        internalScrollValue={internalScrollValue}
                        testID={`dialLabel-${index}`}
                    />
                )}
            />
            <IndicatorLine isBelow />
        </View>
    );
});

type LabelPropType = {
    isSelected: boolean;
    handlePress: (index: number) => void;
    title: string;
    index: number;
    internalScrollValue: SharedValue<number>;
    testID: string;
};

const Label = memo(({ isSelected, handlePress, title, index, internalScrollValue, testID }: LabelPropType) => {
    const animatedLabelStyle = useAnimatedStyle(() => {
        const inputRange = [
            ITEM_WIDTH * (index - 1) + TAB_SCROLL_OFFSET,
            ITEM_WIDTH * index + TAB_SCROLL_OFFSET,
            ITEM_WIDTH * (index + 1) + TAB_SCROLL_OFFSET,
        ];

        return {
            color: interpolateColor(internalScrollValue.value, inputRange, [
                designSystem.colors.gray2,
                designSystem.colors.gray1,
                designSystem.colors.gray2,
            ]),
        };
    });

    return (
        <TouchableOpacity key={index} onPress={() => handlePress(index)} accessible={false}>
            <Box width={ITEM_WIDTH} alignItems="center" paddingVertical="s10">
                <Animated.Text
                    allowFontScaling={false}
                    style={[styles.label, isSelected && styles.selectedLabel, animatedLabelStyle]}
                    testID={`alternateOption-${testID}`}
                >
                    {title}
                </Animated.Text>
            </Box>
        </TouchableOpacity>
    );
});

const IndicatorLine = ({ isBelow }: { isBelow?: boolean }) => (
    <Box
        width="100%"
        alignItems="center"
        backgroundColor="gray5"
        height={SEPARATOR_HEGIHT}
        flexDirection={isBelow ? 'column-reverse' : 'column'}
    >
        <Box width={ITEM_WIDTH} backgroundColor="gray1" height={2} />
    </Box>
);

const styles = StyleSheet.create({
    scrollViewContentContainer: {
        paddingHorizontal: CONTENT_CONTAINER_PADDING,
    },
    modalContainer: {
        paddingTop: 18,
        paddingBottom: 24,
    },
    label: {
        fontSize: 17,
        lineHeight: 24,
        fontWeight: '400',
        letterSpacing: -0.23,
    },
    selectedLabel: {
        fontWeight: '600',
    },
});
