import { useEffect } from 'react';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { RootStackParamList } from '@/navigation/types';

import { useActiveProductConfig } from './use-active-product';

export const useMaintenanceCheck = () => {
    const { replace } = useNavigation<StackNavigationProp<RootStackParamList>>();
    const productConfig = useActiveProductConfig();

    const { enabled: isMaintenanceEnabled } = productConfig?.appMaintenanceConfig?.primary || {};

    useEffect(() => {
        if (isMaintenanceEnabled) {
            replace('MaintenanceScreen');
        }
    }, [isMaintenanceEnabled, replace]);
};
