import { URLS, createJurisdictionHeaders } from '@/data';
import { useMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { MarketCategory } from '@/feature/event-details-sbk/types';
import { featuredLeagueKeys } from '@/feature/lobby-sbk/hooks/use-featured-league-data';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { QueryClient, UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';

import { leagueKeys } from './use-league';

export const getLeagueMarkets = async (leagueId: string, marketCategoryId: string): Promise<MarketCategory[]> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/leagues/${leagueId}/markets/${marketCategoryId}`, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            ...cacheControlHeader,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }

    useMarketCacheStore
        .getState()
        .actions.populate(
            data?.data?.map((marketCategory: MarketCategory) => marketCategory?.markets ?? []).flat() ?? []
        );
    return data.data;
};

const getPlaceholderData = (queryClient: QueryClient, leagueId: string, marketCategoryId: string) => {
    return queryClient.getQueryData<Array<MarketCategory>>(
        featuredLeagueKeys.marketCategories(leagueId, marketCategoryId)
    );
};

export const useLeagueMarkets = (
    leagueId: string,
    marketCategoryId: string,
    enabled = false,
    options: UseQueryOptions<Array<MarketCategory>, unknown, Array<MarketCategory>, any> = {}
) => {
    const queryClient = useQueryClient();
    const queryOptions = {
        enabled: !!leagueId && !!marketCategoryId && enabled,
        staleTime: 500, // prevent double fetching on initial load
        refetchInterval: 30 * 1000, // 30 seconds, we fetch often here as when there are live events, more markets are consistently added
        placeholderData: getPlaceholderData(queryClient, leagueId, marketCategoryId),
        ...options,
    };
    return useQuery(
        leagueKeys.marketCategories(leagueId, marketCategoryId),
        () => getLeagueMarkets(leagueId, marketCategoryId),
        queryOptions
    );
};
