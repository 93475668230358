import { KeyboardAvoidingView } from 'react-native';

/**
 * This class extends the KeyboardAvoidingView and disables the LayoutAnimation call
 * from the method.
 * source: https://github.com/facebook/react-native/blob/5c109b37a42d16b35d8ddf2371d42d47f4d49fb2/Libraries/Components/Keyboard/KeyboardAvoidingView.js#L123
 */
export class KeyboardAvoidingViewNoAnimation extends KeyboardAvoidingView {
    _updateBottomIfNecessary = async () => {
        if (this._keyboardEvent == null) {
            this.setState({ bottom: 0 });
            return;
        }

        const { endCoordinates } = this._keyboardEvent;
        const height = await this._relativeKeyboardHeight(endCoordinates);

        if (this.state.bottom === height) {
            return;
        }

        this.setState({ bottom: height });
    };
}
