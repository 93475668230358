import { useEffect } from 'react';

import { useUser } from '@/hooks/use-user';
import { read } from '@/utils/async-storage';

import { getKeycloakUrl } from '../utils';

/**
 * Handle web registration flow
 * @param enabled - boolean to enable registration flow, should only be enabled if on registration screen and on web
 * @param code - code from Keycloak redirect_uri
 * @param sessionState - sessionState from Keycloak redirect_uri
 */
export const useRegister = (enabled: boolean, code?: string, sessionState?: string) => {
    const { authorize } = useUser();
    useEffect(() => {
        const authorizeOrRedirect = async () => {
            if (enabled) {
                //This means the user has been redirected here via the redirect_uri from Keycloak
                const isAuthRedirect = !!code && !!sessionState;
                if (isAuthRedirect) {
                    authorize(code);
                } else {
                    // User is visiting the registration page for the first time
                    // Redirect them to Keycloak for registration
                    const promo_code: string | undefined = await read('promo_code');
                    // enabled is to add `?registering=true` to the redirect_uri sent to KC on web
                    // When user finishes registration on KC site, they will be redirected back to the web app
                    // with the `registering` param in the redirect_uri, which will be used to finish the registration process
                    // and allow RudderStack to track the registration success event!
                    window.location.href = getKeycloakUrl('register', undefined, promo_code, enabled);
                }
            }
        };
        authorizeOrRedirect();
    }, [enabled, authorize, code, sessionState]);
};
