import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';

export const useIsNativeSportsbook = () => {
    return useJurisdictionStore(state => {
        //TODO - we might want to revert this to && after testing behind the feature switch is done
        return (
            (state.product === Product.Sportsbook ||
                state.jurisdictionSettings?.productConfig?.settings?.native_sportsbook_enabled) ??
            false
        );
    });
};
