import { useMemo } from 'react';

import { useGlobalState } from '@/hooks/use-global-state';
import { GameMode, ProjectionType } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';

import { sortEntryRules } from '../utils/betslip-utils';
import { getGameModeError } from '../utils/game-mode-utils';
import { useBetslipStore } from './use-betslip-store';
import { useEntryRules } from './use-entry-rules';

const EMPTY_ARRAY = [] as const;

export const useBetslipData = (_tag?: string) => {
    const plays = useBetslipStore(state => state.plays);

    const selectedGameModes = useMemo(() => {
        // a game is selected if it has a non-zero amount
        return Object.entries(plays)
            .filter(([_key, value]) => !!value.amount)
            .map(([key]) => key as GameMode);
    }, [plays]);

    const betslip = useBetslipStore(state => state.betslip, defaultZustandCompareFunction);

    const globalMultipliers = useGlobalState(state => state.multipliers, defaultZustandCompareFunction);

    const defaultMultipliers = useMemo(
        () => Object.values(globalMultipliers).map(it => it.multiplier),
        [globalMultipliers]
    );

    const multipliers =
        useBetslipStore(
            state => state.validationData.PERFECT.betValidation?.multipliers,
            defaultZustandCompareFunction
        ) ??
        defaultMultipliers ??
        EMPTY_ARRAY;

    const perfectMultipliers = useMemo(() => [0, ...multipliers], [multipliers]);

    const validationData = useBetslipStore(state => state.validationData);

    const { allEntryRules, canPickBoosted, canPickRegular, canPickSpecial } = useEntryRules();

    const dynamicMultipliers = {
        DYNAMIC:
            validationData.DYNAMIC?.betValidation?.dynamicMultipliers?.filter(
                it => it.numberOfPicks === betslip.length
            ) ?? [],
    } as const;

    const boostedPicks = betslip.filter(pick => pick.projection.type === ProjectionType.Boosted);

    const dynamicBoostedMultiplier = validationData.DYNAMIC.betValidation?.boostedMultipliers?.find(
        it => it.numberOfPicks === betslip.length && it.numberOfBoostedPicks === boostedPicks.length
    );

    const perfectRegularMultiplier =
        boostedPicks.length > 0
            ? // if we have boosted picks, use the global multipliers (the ones we get from entry rules when app loads)
              // because the ones we get from validation only have the boosted multipliers, not the regular ones
              globalMultipliers[betslip.length]?.multiplier
            : perfectMultipliers[betslip.length - 1];
    const perfectBoostedMultiplier = boostedPicks.length > 0 ? perfectMultipliers[betslip.length - 1] : undefined;

    const validPicks = useMemo(() => {
        if (betslip.length === 0) {
            return false;
        }

        // go through each game mode and check if it has any INVALID_CRITERIA errors
        // we should look into data with betValidation - this contains the errors
        const availableBetValidations = Object.values(validationData).filter(data => !!data.betValidation);
        const validModes = availableBetValidations.filter(
            data => (data.betValidation?.errors ?? []).filter(it => it.key === 'INVALID_CRITERIA').length === 0
        );

        // if at least 1 game mode is valid, the betslip is valid
        return validModes.length > 0;
    }, [betslip, validationData]);

    const { error: dynamicModeError, disabled: dynamicModeDisabled } = getGameModeError(
        betslip,
        allEntryRules,
        validationData.DYNAMIC.betValidation
    );

    const { error: perfectModeError, disabled: perfectModeDisabled } = getGameModeError(
        betslip,
        allEntryRules,
        validationData.PERFECT.betValidation
    );

    const sortedEntryRules = useMemo(
        () => sortEntryRules(allEntryRules, dynamicModeDisabled),
        [allEntryRules, dynamicModeDisabled]
    );

    const multipliersWithZero = useMemo(() => [0, ...multipliers], [multipliers]);

    return {
        betslip: betslip,
        /**
         * The default, Perfect Play multipliers
         */
        multipliers: multipliersWithZero,
        dynamicMultipliers,
        canPick: canPickBoosted || canPickRegular || canPickSpecial,
        canPickBoosted,
        canPickRegular,
        canPickSpecial,
        validPicks,
        dynamicModeDisabled,
        dynamicModeError,
        dynamicBoostedMultiplier,
        perfectRegularMultiplier,
        perfectBoostedMultiplier,
        perfectModeDisabled,
        perfectModeError,
        selectedGameModes,
        sortedEntryRules,
    };
};
