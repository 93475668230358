import React, { useEffect } from 'react';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import WebView, { WebViewNavigation } from '@/components/Webview';
import { Box } from '@/components/lib/components';
import { RootStackParamList } from '@/navigation/types';
import { requestCameraPermissionIfNotGranted } from '@/utils/camera';
import { isWeb } from '@/utils/constants-platform-specific';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'KycIdpvWebView'>;

// IDPV - ID Photo Verification
export const IdpvWebView = ({ route }: ScreenProps) => {
    const { uri } = route?.params;
    const navigation = useNavigation();

    useEffect(() => {
        requestCameraPermissionIfNotGranted();
    }, []);

    const handleNavigationChange = (navState: WebViewNavigation) => {
        // Assume that the IDPV is done when the user is redirected to the Betr website
        if (navState.url.includes('https://betr.app/')) {
            navigation.navigate('KycVerifyingIdpv');
        }
    };

    // Redirect to the IDPV URL if the app is running on the web
    if (isWeb && uri) {
        window.location.href = uri;
        return null;
    }

    return (
        <Screen>
            <ScreenNavBar title={'Upload your ID'} closeIconMode="back" />
            <WebView
                source={{ uri }}
                allowsInlineMediaPlayback
                mediaPlaybackRequiresUserAction={false}
                startInLoadingState
                onNavigationStateChange={handleNavigationChange}
                renderLoading={() => (
                    <Box alignItems="center" justifyContent="center" height="100%">
                        <LoadingSpinner />
                    </Box>
                )}
            />
        </Screen>
    );
};
