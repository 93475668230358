import React from 'react';

import Alert from '@/assets/icons/alertStraightTriangle';
import { Box } from '@/components/lib/components';

export const AlertIcon = () => {
    return (
        <Box
            width={72}
            height={72}
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            borderRadius="r40"
            backgroundColor="gray5"
        >
            <Alert />
        </Box>
    );
};
