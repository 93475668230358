import React from 'react';

import { useIsFocused } from '@react-navigation/native';

import { Box } from '@/components/lib/components';

import { useSubscribeEventOddsUpdate } from '../hooks/use-subscribe-event-odds-update';
import { Event, MarketCategoryType } from '../types';
import { MarketAccordionLayout } from './market-page-layouts/market-accordion-layout/MarketAccordionLayout';
import { MicroMarketLayout } from './market-page-layouts/micro-market-layout/MicroMarketLayout';
import { PlayerPropLayout } from './market-page-layouts/player-props-layout/PlayerPropLayout';

type MarketCategoryContentProps = {
    event: Event;
    marketCategoryId: string;
    marketCategoryType: MarketCategoryType;
    tabKey: string;
};

export const MarketCategoryContent = React.memo(
    ({ event, marketCategoryId, marketCategoryType, tabKey }: MarketCategoryContentProps) => {
        const isFocused = useIsFocused();

        useSubscribeEventOddsUpdate(event.id, marketCategoryId, isFocused);

        const renderContent = () => {
            switch (marketCategoryType) {
                case 'play_by_play':
                case 'pitch_by_pitch':
                case 'plate_appearance':
                case 'drive':
                    return (
                        <MicroMarketLayout
                            {...{
                                event,
                                marketCategoryId,
                                marketCategoryType,
                                enabled: isFocused,
                                tabKey,
                            }}
                        />
                    );
                case 'player_props':
                    return (
                        <PlayerPropLayout
                            {...{
                                event,
                                marketCategoryId,
                                enabled: isFocused,
                                tabKey,
                            }}
                        />
                    );
                default:
                    return (
                        <MarketAccordionLayout
                            {...{
                                event,
                                marketCategoryId,
                                enabled: isFocused,
                                tabKey,
                            }}
                        />
                    );
            }
        };

        return <Box>{renderContent()}</Box>;
    }
);
