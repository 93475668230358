import { Platform, useWindowDimensions } from 'react-native';

import { getBreakpointByWidth } from '../utils/breakpoints';

// should be ordered as the breakpoints:  xs === array[0]
type ArrayWith4Elements = [number, number, number, number];

/**
 * in order to be able to support a dynamic number of columns in a FlatList,
 * the list's key should contain the column number to trigger a new instance
 * @param columns desired number of columns for each screen size
 * @returns the number of columns you want based on the screen's width
 */
export const useResponsiveColumnCount = (columns: ArrayWith4Elements): number => {
    const { width } = useWindowDimensions();

    if (Platform.OS !== 'web') {
        return 1;
    }
    const colsPerBreakpoint = { xs: columns[0], sm: columns[1], md: columns[2], lg: columns[3] };

    const breakpoint = getBreakpointByWidth(width);
    return colsPerBreakpoint[breakpoint];
};
