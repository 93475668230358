import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg width={8} height={8} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0.707107L0.707107 0L1.46695 0.759845L1.4668 0.76L7.26507 6.55828L7.26523 6.55812L7.95711 7.25L7.25 7.95711L6.45286 7.15997C5.77552 7.68652 4.92438 8.00008 4 8.00008C1.79086 8.00008 0 6.20921 0 4.00008C0 3.0757 0.313554 2.22456 0.840104 1.54721L0 0.707107ZM7.6834 5.56239C7.88724 5.08241 8 4.55441 8 4.00008C8 1.79094 6.20914 7.55191e-05 4 7.55191e-05C3.44566 7.55191e-05 2.91766 0.112838 2.43769 0.316676L3.22275 1.10174C3.47065 1.03543 3.73119 1.00008 4 1.00008C5.65685 1.00008 7 2.34322 7 4.00008C7 4.26888 6.96465 4.52943 6.89834 4.77733L7.6834 5.56239ZM1 4.00008C1 3.35214 1.20541 2.75218 1.55466 2.26176L5.73831 6.44542C5.24789 6.79467 4.64793 7.00008 4 7.00008C2.34315 7.00008 1 5.65693 1 4.00008Z"
            fill={props.fill || '#43444F'}
        />
    </Svg>
);

export default SvgComponent;
