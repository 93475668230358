import React from 'react';
import { Animated, Easing, Platform } from 'react-native';

import { createStackNavigator } from '@react-navigation/stack';

import { FeaturedLineupsScreen } from '@/feature/lobby/components/FeaturedLineupsScreen';
import { LobbyScreen } from '@/feature/lobby/screens/Lobby';
import { PickemSportScreen } from '@/feature/lobby/screens/PickemSport';
import { SearchScreen } from '@/feature/search/screens/SearchScreen';
import { PickemLobbyStackParamList } from '@/navigation/pickem/types';

const Stack = createStackNavigator<PickemLobbyStackParamList>();

export const LobbyStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="LobbyScreen" component={LobbyScreen} />
            <Stack.Screen name="PickemSportScreen" component={PickemSportScreen} />
            <Stack.Screen name="FeaturedLineupsScreen" component={FeaturedLineupsScreen} />
            <Stack.Screen
                name="SearchScreen"
                component={SearchScreen}
                options={() => ({
                    // we need every container to have the background `transparent`
                    cardStyle: { backgroundColor: 'transparent' },
                    cardStyleInterpolator: () => {
                        return {
                            containerStyle: {
                                // full opacity here to avoid flickering
                                opacity: 1,
                                backgroundColor: 'transparent',
                            },
                            cardStyle: {
                                backgroundColor: 'transparent',
                            },
                        };
                    },
                    // disable the animation config for the screen
                    transitionConfig: () => ({
                        transitionSpec: {
                            duration: 0,
                            timing: Animated.timing,
                            easing: Easing.step0,
                        },
                    }),
                    // show it as a transparent modal so that the stack navigator doesn't have a background
                    presentation: 'transparentModal',
                    gestureEnabled: false,
                    // animation is disabled only on android - on ios it flickers if it's disabled
                    animationEnabled: Platform.OS !== 'android',
                })}
            />
        </Stack.Navigator>
    );
};
