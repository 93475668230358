import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { leagueKeys } from './use-league';
import { getLeagueMarkets } from './use-league-markets';

export const usePrefetchEventLeagueMarketCategories = (): ((leagueId: string, marketCategoryId: string) => void) => {
    const queryClient = useQueryClient();

    return useCallback(
        (leagueId: string, marketCategoryId: string) => {
            queryClient.prefetchQuery(
                leagueKeys.marketCategories(leagueId, marketCategoryId),
                () => getLeagueMarkets(leagueId, marketCategoryId),
                {
                    staleTime: Infinity,
                }
            );
        },
        [queryClient]
    );
};
