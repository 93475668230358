import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { Route, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import ConfettiLottieJSON from '@/assets/animations/confetti.json';
import BackArrow from '@/assets/icons/backArrow';
import CheckmarkLargeWhite from '@/assets/icons/checkmark-large-white';
import { Button } from '@/components/ButtonComponent';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useBetslipNavigation } from '@/feature/betslip-pickem/hooks/use-betslip-navigation';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { hasMadeFirstDepositSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { isWeb } from '@/utils/constants-platform-specific';
import LottieView from 'lottie-react-native';

import { PaymentMethodTypes } from '../const';
import { PaymentMethodType } from '../hooks/use-deposit-payment-methods';
import { useTrackFirstDepositEvent } from '../hooks/use-track-first-deposit-event';
import { PayPalStatus } from '../utils';

const styles = StyleSheet.create({
    rightArrow: { transform: [{ rotate: '-180deg' }] },
    animContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '50%',
        left: '50%',
    },
    lottie: {
        width: '100%',
        height: '100%',
    },
});

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'SuccessfulDepositModal'>;

export const SuccessfulDeposit = ({ route }: ScreenProps) => {
    const navigation = useNavigation();
    const { t } = useTranslation(['wallet', 'common', 'kyc']);
    const { activeWalletProductName } = useActiveWallet();
    const { product } = useJurisdictionStore();
    const { resetToEntryInputAmount, resetToFantasyPickSlip } = useBetslipNavigation();
    const { data: hasMadeFirstDeposit, isLoading } = useAuthUserConfig({
        select: hasMadeFirstDepositSelector,
    });
    const [isFtdAttempt, setIsFtdAttempt] = useState(false);
    const { width, height } = useWindowDimensions();
    const maxWidth = isWeb ? Math.min(800, width) : width;
    const maxHeight = isWeb ? Math.min(800, height) : height;

    useEffect(() => {
        // hasMadeFirstDeposit is initially set to false when the screen is first loaded but soon changes to true.
        // This causes the animation to be skipped and disappear immediately. isFirstAttempt is used to prevent this issue.
        if (!isLoading && !hasMadeFirstDeposit) {
            setIsFtdAttempt(true);
        }
    }, [hasMadeFirstDeposit, isLoading]);

    usePreventAndroidBackNavigation();
    useTrackFirstDepositEvent(PayPalStatus.SUCCESS, route.params?.paymentProvider, route.params?.selectedAmount);

    const trackDepositSuccess = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.DEPOSIT_SUCCESS, { method: route.params?.paymentProvider });
    };

    const navigationState = navigation.getState();
    const depositRoute = navigationState?.routes.find(it => it.name === 'DepositScreen') as Route<
        'DepositScreen',
        RootStackParamList['DepositScreen']
    >;

    const shouldRedirectToLobby = depositRoute?.params?.shouldRedirectToLobby;

    const handleClose = () => {
        trackDepositSuccess();

        const shouldRedirectEntryAmountRoute = navigationState?.routes.find(it => it.name === 'EntryInputAmount');
        const shouldRedirectFantasyPickSlip = navigationState?.routes.find(it => it.name === 'FantasyPickSlip');

        // we check if we have navigated to the DepositScreen with the additional params
        // if so, we redirect to lobby with the transition screen
        if (shouldRedirectToLobby) {
            if (depositRoute?.params?.disableTransition) {
                navigateHome(navigation, 'lobby');
                return;
            }
            navigation.navigate('ProductTransitionScreen', { product });
            return;
        }

        // if the entry amount route is in the stack, the deposit flow was started from there
        // so we need to reset the stack to that point
        if (shouldRedirectEntryAmountRoute) {
            resetToEntryInputAmount();
            return;
        }

        // if the fantasy pick slip route is in the stack, but not the entry amount route
        // the deposit flow was started from the fantasy pick slip
        // so we need to reset the stack to that point
        if (shouldRedirectFantasyPickSlip) {
            resetToFantasyPickSlip();
            return;
        }

        navigateHome(navigation, 'account');
    };

    return (
        <Screen edges={['top', 'bottom']}>
            {isFtdAttempt ? (
                <View
                    style={[
                        styles.animContainer,
                        {
                            maxWidth: maxWidth,
                            maxHeight: maxHeight,
                            transform: [{ translateX: -maxWidth / 2 }, { translateY: -maxHeight / 2 }],
                        },
                    ]}
                >
                    <LottieView source={ConfettiLottieJSON} style={styles.lottie} autoPlay loop={false} />
                </View>
            ) : null}
            {shouldRedirectToLobby ? null : <ScreenNavBar closeIconMode="close" onClose={handleClose} />}
            <MaxWidthWrapper flex={1} paddingBottom={'s16'}>
                <Box flex={1} justifyContent="space-between" paddingHorizontal="s16">
                    <Box flex={1} justifyContent="center" alignItems="center">
                        <CheckmarkLargeWhite />
                        <Text textAlign="center" variant="headlineMedium" mt="s16">
                            {t('deposit_success_modal_title', { product: activeWalletProductName })}
                        </Text>
                        <Text textAlign="center" color="gray2" variant="bodyMedium" mt="s8">
                            {t('deposit_success_modal_description', {
                                loginMessage: getPaymentProviderLoginMessage(route.params?.paymentProvider),
                            })}
                        </Text>
                    </Box>
                    <Box gap="s16">
                        <Banner
                            message={t('kyc:set_gaming_limits')}
                            onPress={() => navigation.navigate('ResponsibleGaming')}
                            rightIcon={
                                <BackArrow
                                    height={16}
                                    width={16}
                                    viewBox="0 0 25 25"
                                    marginLeft={12}
                                    style={styles.rightArrow}
                                />
                            }
                        />

                        <Button
                            label={
                                shouldRedirectToLobby
                                    ? t('common:go_to_product_lobby', { product: activeWalletProductName })
                                    : t('common:done')
                            }
                            hierarchy={'primary'}
                            onPress={handleClose}
                            testID="depositDoneButton"
                        />
                    </Box>
                </Box>
            </MaxWidthWrapper>
        </Screen>
    );
};

const getPaymentProviderLoginMessage = (paymentProvider: PaymentMethodType): string => {
    const loginMessages: Record<PaymentMethodType, string> = {
        [PaymentMethodTypes.Paysafe]: 'linked your Debit card.',
        [PaymentMethodTypes.PaysafePaypal]: 'logged into your PayPal account.',
        [PaymentMethodTypes.PaysafeMazooma]: 'logged into your Bank account.',
        [PaymentMethodTypes.PaysafePayByBank]: 'logged into your Bank account.',
    };

    return loginMessages[paymentProvider];
};
