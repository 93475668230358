import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { useProcessCardRemoval } from '@/feature/account-details/hooks/use-process-card-removal';
import { useDebitCardRemovalStore } from '@/hooks/use-debit-card-removal-store';
import { navigateHome } from '@/navigation/navigation';
import { IdpvSource, IdpvSourceType } from '@/navigation/types';

import { kycRouteNames } from '../const';

export const useNavigateToSuccessModal = ({ source = IdpvSource.KYC }: { source?: IdpvSourceType }) => {
    const navigation = useNavigation();
    const { t } = useTranslation(['manage_cards', 'common']);

    const { paymentTypeId } = useDebitCardRemovalStore();
    const { handleVerificationSuccess } = useProcessCardRemoval();

    const navigateToSuccessModal = useCallback(() => {
        switch (source) {
            case IdpvSource.ACCOUNT_SETTING:
                navigation.navigate('SuccessModal', {
                    title: t('enhance_profile_complete'),
                    subtitle: t('profile_enhanced_confirmation'),
                    primaryButton: t('common:done'),
                    closeIconMode: 'close',
                    handlePress: () => navigateHome(navigation),
                    closeIconHandlePress: () => navigateHome(navigation, 'account'),
                });
                return;
            case IdpvSource.MANAGE_CARD:
                navigation.navigate('SuccessModal', {
                    title: t('enhance_profile_complete'),
                    subtitle: t('profile_enhanced_confirmation'),
                    primaryButton: t('continue_to_card_removal'),
                    closeIconMode: 'close',
                    handlePress: () => handleVerificationSuccess(paymentTypeId),
                    closeIconHandlePress: () => navigation.navigate('ManageDebitCards'),
                });
                return;
            default: // IdpvSource.KYC
                // Reset navigation stack to prevent the user from going back to the previous screens as the KYC process is complete
                navigation.reset({
                    index: 0,
                    routes: [{ name: kycRouteNames.VERIFICATION_SUCCESS }],
                });
                return;
        }
    }, [handleVerificationSuccess, navigation, source, paymentTypeId, t]);

    return navigateToSuccessModal;
};
