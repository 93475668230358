import { useMemo } from 'react';

import { useMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { Market } from '@/feature/event-details-sbk/types';
import { getEvents } from '@/feature/league-hub-sbk/hooks/use-events';
import { useLeague } from '@/feature/league-hub-sbk/hooks/use-league';
import { getLeagueMarkets } from '@/feature/league-hub-sbk/hooks/use-league-markets';
import { useQuery } from '@tanstack/react-query';

export const featuredLeagueKeys = {
    all: ['featured-league'] as const,
    events: () => [...featuredLeagueKeys.all, 'events'] as const,
    eventsByLeague: (leagueId: string) => [...featuredLeagueKeys.events(), { leagueId }] as const,
    marketCategories: (leagueId: string, marketCategoryId: string) =>
        [...featuredLeagueKeys.all, 'market-categories', { leagueId, marketCategoryId }] as const,
};

const MARKET_CATEGORY_TYPE = 'game_lines';

export const DEFAULT_EVENT_LIMIT = 5;

/**
 * Fetches and groups data for featured league
 */
export const useFeaturedLeagueData = (leagueId: string, marketCode = 'RESULT', limit = DEFAULT_EVENT_LIMIT) => {
    const { data: league } = useLeague(leagueId);
    const marketCategoryId = league?.market_categories?.find(cat => cat.type === MARKET_CATEGORY_TYPE)?.id ?? '';

    const options = {
        staleTime: 500,
        refetchInterval: 30 * 1000,
    };

    const { data: marketCategories, refetch: refetchMarkets } = useQuery(
        featuredLeagueKeys.marketCategories(leagueId, marketCategoryId),
        () => getLeagueMarkets(leagueId, marketCategoryId),
        {
            enabled: !!leagueId && !!marketCategoryId,
            ...options,
        }
    );

    const { data: events = [], refetch: refetchEvents } = useQuery(
        featuredLeagueKeys.eventsByLeague(leagueId),
        () => getEvents(leagueId),
        {
            select: data => data.data,
            enabled: !!leagueId,
            ...options,
        }
    );

    const marketCategory = marketCategories?.find(cat => cat.market_type.code === marketCode);

    const eventMarkets = useMemo(() => {
        return (
            marketCategory?.markets.reduce((acc, market) => {
                if (!acc[market.event_id]) {
                    acc[market.event_id] = [];
                }
                acc[market.event_id].push(market);
                return acc;
            }, {} as Record<string, Market[]>) ?? {}
        );
    }, [marketCategory?.markets]);

    const refetch = () => Promise.allSettled([refetchMarkets(), refetchEvents()]);

    const filteredEvents = useMarketCacheStore(state => {
        return (
            events
                // filter out events that have published markets
                .filter(event => {
                    const markets = eventMarkets[event.id] ?? [];
                    return markets.some(market => state.markets[market.id]?.published);
                })
                // limit the number of events to the limit
                .filter((_, index) => index < limit)
        );
    });

    return {
        eventMarkets,
        marketCategoryId,
        marketCategory,
        events: filteredEvents,
        refetch,
    };
};
