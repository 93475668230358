import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { SizedBox } from '@/components/SizedBox';
import { Seperator } from '@/components/scoreboard/Seperator';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

import { Box, Column, Row } from '../lib/components';
import { MatchUpStatus } from './MatchUpStatus';
import { PeriodColumn } from './PeriodColumn';
import { GridPeriodScore, ScoreBoardGridProps, Spec } from './types';

const spec: Spec = {
    small: {
        fontSize: 12,
        innerPadding: 's10',
        outerPadding: 12,
        boxColWidth: 16,
        scoreContainerPaddingRight: 10,
        logoWidth: 16,
        logoHeight: 16,
        sizedBoxDimensionsForBoxScore: {
            top: 2,
            middle: 8,
            bottom: 8,
        },
        sizedBoxDimensionsForMatchup: {
            top: 8,
            middle: 8,
            bottom: 2,
        },
        scoreBreakdownMinWidth: 74, //min 3 periods visible =>  3 * boxColWidth + 3 * GAP_WIDTH + 2 * LIST_MARGIN
        dividerStyles: {
            height: '94.5%',
            marginTop: 's2',
            backgroundColor: 'gray5',
        },
    },
    large: {
        fontSize: 13,
        innerPadding: 's8',
        outerPadding: 16,
        boxColWidth: 18,
        scoreContainerPaddingRight: 8,
        logoWidth: 20,
        logoHeight: 20,
        sizedBoxDimensionsForBoxScore: {
            top: 2,
            middle: 10,
            bottom: 10,
        },
        sizedBoxDimensionsForMatchup: {
            top: 8,
            middle: 6,
            bottom: 0,
        },
        scoreBreakdownMinWidth: 100, //min 4 periods visible =>  4 * boxColWidth + 3 * GAP_WIDTH + 2 * LIST_MARGIN
        dividerStyles: {
            height: '100%',
            marginTop: 's0',
            backgroundColor: 'divider',
        },
    },
};
const LIST_MARGIN = 8;
const GAP_WIDTH = 4;
const ITEM_HEIGHT = 24;

const ListGap = () => <SizedBox value={GAP_WIDTH} />;

export const ScoreBoardGrid = ({
    awayTeamIcon,
    homeTeamIcon,
    awayTeamShortCode,
    homeTeamShortCode,
    breakdown,
    backgroundColor,
    finalScoreColumns,
    matchDetails,
    activePeriodIndex,
    variant = 'large',
}: ScoreBoardGridProps) => {
    const chosenSpec = spec[variant];

    const flatListRef = useRef<FlatList<GridPeriodScore>>(null);

    const [scrollState, setScrollState] = useState({
        showLeftBar: false,
        showRightBar: false,
        contentWidth: 0,
        containerWidth: 0,
    });

    const [layoutState, setLayoutState] = useState({
        resultsWidth: 0,
        totalWidth: 0,
    });

    useEffect(() => {
        const requiresScrolling = scrollState.contentWidth > scrollState.containerWidth;
        setScrollState(prev => ({ ...prev, showRightBar: requiresScrolling }));
    }, [scrollState.contentWidth, scrollState.containerWidth]);

    useEffect(() => {
        if (activePeriodIndex && activePeriodIndex < breakdown.length && activePeriodIndex > 0) {
            flatListRef.current?.scrollToIndex({ animated: true, index: activePeriodIndex });
        }
    }, [activePeriodIndex, breakdown?.length]);

    const handleContentSizeChange = useCallback((width: number) => {
        setScrollState(prev => ({ ...prev, contentWidth: width }));
    }, []);

    const handleLayout = useCallback((event: LayoutChangeEvent) => {
        const { width } = event.nativeEvent.layout;
        setScrollState(prev => ({ ...prev, containerWidth: width }));
    }, []);

    const handleScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
        const { contentOffset, contentSize, layoutMeasurement } = event.nativeEvent;
        const isAtStart = contentOffset.x <= 0;
        const isAtEnd = contentOffset.x + layoutMeasurement.width >= contentSize.width - 1;
        setScrollState(prev => ({
            ...prev,
            showLeftBar: !isAtStart,
            showRightBar: !isAtEnd && contentSize.width > layoutMeasurement.width,
        }));
    }, []);

    const handleResultsLayout = useCallback((event: LayoutChangeEvent) => {
        const { width } = event.nativeEvent.layout;
        setLayoutState(prev => ({ ...prev, resultsWidth: width }));
    }, []);

    const handleRowLayout = useCallback((event: LayoutChangeEvent) => {
        const { width } = event.nativeEvent.layout;
        setLayoutState(prev => ({ ...prev, totalWidth: width }));
    }, []);

    const maxMatchupWidth = useMemo(
        () =>
            layoutState.totalWidth -
            layoutState.resultsWidth -
            2 * chosenSpec.outerPadding -
            chosenSpec.scoreBreakdownMinWidth,
        [layoutState.totalWidth, layoutState.resultsWidth, chosenSpec]
    );

    return (
        <Row
            onLayout={handleRowLayout}
            maxWidth={'100%'}
            minWidth={'100%'}
            backgroundColor={backgroundColor}
            style={{ paddingHorizontal: chosenSpec.outerPadding }}
        >
            {/* Matchup */}
            <MatchUpStatus
                awayTeamIcon={awayTeamIcon}
                homeTeamIcon={homeTeamIcon}
                awayTeamShortCode={awayTeamShortCode}
                homeTeamShortCode={homeTeamShortCode}
                matchDetails={matchDetails}
                maxMatchupWidth={maxMatchupWidth}
                fontSize={chosenSpec.fontSize}
                innerPadding={chosenSpec.innerPadding}
                logoWidth={chosenSpec.logoWidth}
                logoHeight={chosenSpec.logoHeight}
                sizedBoxDimensions={chosenSpec.sizedBoxDimensionsForMatchup}
                textColor={backgroundColor === 'gray8' ? 'gray2' : undefined}
            />
            <Seperator show={scrollState.showLeftBar} styles={chosenSpec.dividerStyles} />
            <Box flex={1} />
            <Box overflow={'hidden'} flexShrink={10}>
                <Column minWidth={chosenSpec.scoreBreakdownMinWidth} onLayout={handleLayout}>
                    <FlatList<GridPeriodScore>
                        ref={flatListRef}
                        horizontal
                        showsHorizontalScrollIndicator={showScrollIndicator}
                        data={breakdown}
                        ListHeaderComponent={<SizedBox value={LIST_MARGIN} />}
                        ListFooterComponent={<SizedBox value={LIST_MARGIN} />}
                        ItemSeparatorComponent={ListGap}
                        renderItem={({ item }) => (
                            <Box>
                                <PeriodColumn
                                    {...item}
                                    periodLabel={item.label ?? ''}
                                    minWidth={chosenSpec.boxColWidth}
                                    fontSize={chosenSpec.fontSize}
                                    sizedBoxDimensions={chosenSpec.sizedBoxDimensionsForBoxScore}
                                    fontWeight={400}
                                    textColor={backgroundColor === 'gray8' ? 'gray2' : undefined}
                                />
                            </Box>
                        )}
                        getItemLayout={(_, index) => ({ length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index })}
                        onScroll={handleScroll}
                        scrollEventThrottle={16}
                        onContentSizeChange={handleContentSizeChange}
                    />
                </Column>
            </Box>
            <Seperator show={scrollState.showRightBar} styles={chosenSpec.dividerStyles} />

            {/* Results */}
            <Row paddingLeft={chosenSpec.innerPadding} onLayout={handleResultsLayout}>
                {finalScoreColumns?.map((period, index) => (
                    <Box
                        paddingRight={index !== finalScoreColumns?.length - 1 ? chosenSpec.innerPadding : 's0'}
                        key={`${period.periodNumber}`}
                    >
                        <PeriodColumn
                            {...period}
                            highlighted
                            periodLabel={`${period.periodNumber}`}
                            fontSize={chosenSpec.fontSize}
                            sizedBoxDimensions={chosenSpec.sizedBoxDimensionsForBoxScore}
                            fontWeight={700}
                        />
                    </Box>
                ))}
            </Row>
        </Row>
    );
};
