import React, { useEffect } from 'react';
import { NativeModules } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Box } from '@/components/lib/components';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { RootStackParamList } from '@/navigation/types';

import { PayPalStatus } from '../utils';

const { PaymentsWebView } = NativeModules;

type DepositStatusProps = NativeStackScreenProps<RootStackParamList, 'DepositStatusModal'>;

export const DepositStatus = ({ route }: DepositStatusProps) => {
    const { selectedAmount, paymentProvider, currentStatus } = route.params;

    const navigation = useNavigation();
    const selectedProduct = useJurisdictionStore.getState().product;
    const root = selectedProduct === Product.Sportsbook ? 'SBKHome' : 'PickemHome';

    useEffect(() => {
        PaymentsWebView?.closeWebView();
        if (currentStatus === PayPalStatus.SUCCESS) {
            navigation.navigate('SuccessfulDepositModal', {
                paymentProvider,
                selectedAmount,
            });
        } else if (currentStatus === PayPalStatus.ERROR) {
            navigation.navigate('FailedDepositModal', {
                paymentProvider,
                selectedAmount,
            });
        } else {
            navigation.reset({
                routes: [
                    {
                        name: root,
                        params: { screen: 'AccountStack' },
                    },
                    {
                        name: 'DepositScreen',
                        params: {
                            selectedAmount,
                        },
                    },
                ],
            });
        }
    }, [currentStatus, navigation, paymentProvider, selectedAmount, root]);

    return (
        <Box bg="gray8" flex={1} justifyContent="center">
            <LoadingSpinner />
        </Box>
    );
};
