import React, { useMemo } from 'react';
import { View } from 'react-native';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LineSeparator } from '@/components/LineSeparator';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { AccountStackParamList } from '@/navigation/types';

import { TransactionRequestStatus } from '../components/TransactionRequestStatus';
import { useTransactionDetails } from '../hooks/use-transaction-details';
import { formatTransaction } from '../utils/formatters';

type ScreenProps = NativeStackScreenProps<AccountStackParamList, 'TransactionDetails'>;

const rows = [
    { label: 'Initiated', key: 'initiated' },
    { label: 'Status', key: 'status' },
    { label: 'Before Total Balance', key: 'beforeBalance' },
    { label: 'After Total Balance', key: 'afterBalance' },
    { label: 'From', key: 'from' },
    { label: 'To', key: 'to' },
    { label: 'Daily Deposit Limit After', key: 'dailyDailyDepositLimitAfter' },
    { label: 'Weekly Deposit Limit After', key: 'weeklyDepositLimitAfter' },
    { label: 'Monthly Deposit Limit After', key: 'monthlyDepositLimitAfter' },
    { label: 'Transaction ID', key: 'transactionId' },
] as const;

export const TransactionDetailsScreen = ({ route }: ScreenProps) => {
    const { userWalletId } = route.params;
    const { data: transaction } = useTransactionDetails(userWalletId);

    const transactionDetails = useMemo(() => formatTransaction(transaction), [transaction]);

    if (!transaction || !transactionDetails) {
        return null;
    }

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: 'Transaction Details' }}
            scrollViewProps={{ style: { paddingHorizontal: 0 } }}
        >
            <MaxWidthWrapper paddingHorizontal={'s16'}>
                <Text
                    mt="s8"
                    mb="s4"
                    variant="displaySmall"
                    textDecorationLine={transactionDetails.isFailed ? 'line-through' : undefined}
                    color={transactionDetails.isFailed ? 'gray2' : undefined}
                >
                    {transactionDetails.header}
                </Text>
                <Text variant="bodyMedium" mb="s16">
                    {transactionDetails.subText}
                </Text>
                <Box mb="s40">
                    {rows.map(({ label, key }) =>
                        transactionDetails[key] ? (
                            <View key={label}>
                                <Text color="gray3" variant="bodySmall" mt="s16">
                                    {label}
                                </Text>
                                {label === 'Status' ? (
                                    <TransactionRequestStatus transaction={transactionDetails} />
                                ) : (
                                    <Text variant="bodyMedium" mb="s16" testID={`txnDetails-${key}`}>
                                        {transactionDetails[key]}
                                    </Text>
                                )}
                                <LineSeparator />
                            </View>
                        ) : null
                    )}
                </Box>
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
