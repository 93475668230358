import { getFirebaseWebConfig } from '@/data';
import { useActiveWalletStore } from '@/hooks/use-active-wallet';
import { Product } from '@/hooks/use-jurisdiction';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

import { AnalyticsEvent } from './constants';

const firebaseConfig = getFirebaseWebConfig();
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class BetrAnalytics {
    setUserId(userId: string) {
        setUserId(analytics, userId);
    }

    setUserProperties(properties: { [key: string]: string | null }) {
        setUserProperties(analytics, properties);
    }

    trackEvent(event: AnalyticsEvent, params?: { [key: string]: any }) {
        logEvent(analytics, event, params);
    }

    setAnalyticsConsent(_consent: boolean) {}

    trackProductEvent(event: AnalyticsEvent, params?: { [key: string]: any }) {
        const activeProduct = useActiveWalletStore.getState().activeWalletProduct;
        if (activeProduct !== Product.None) {
            const trackedEvent = `${activeProduct}_${event}`;
            logEvent(analytics, trackedEvent, params);
        }
    }

    trackScreenView(screen: string, params?: { [key: string]: any }) {
        logEvent(analytics, 'screen_view', {
            firebase_screen: screen,
            firebase_screen_class: screen,
            ...params,
        });
    }

    trackEntryPlaced(amount: number, entryId: string) {
        logEvent(analytics, 'purchase', { value: amount, currency: 'USD', transaction_id: entryId });
    }
}

export default new BetrAnalytics();
