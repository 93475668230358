import {
    automaticallyShowInAppMessages,
    changeUser,
    initialize,
    isInitialized,
    isPushSupported,
    openSession,
    requestPushPermission,
    unregisterPush,
} from '@braze/web-sdk';

import { logger } from '../utils/logging';
import { BRAZE_WEB_BASE_URL, CONSTANTS } from './config';

const LOG_TAG = '[Braze Web]';
const webAppId = CONSTANTS.BRAZE_WEB_API_KEY;

export const requestNotificationPermission = () => {
    requestPushPermission();

    if (isPushSupported()) {
        logger.info(LOG_TAG, 'Push notifications are supported');
    }
};

export const unregisterNotification = () => unregisterPush();

export const initBraze = () => {
    if (isInitialized()) {
        logger.info(LOG_TAG, 'SDK is initialized');
    } else {
        initialize(webAppId, {
            baseUrl: BRAZE_WEB_BASE_URL,
            enableLogging: true,
        });
    }

    // optionally show all in-app messages without custom handling
    automaticallyShowInAppMessages();
};

export const setBrazeUser = (userId: string) => {
    changeUser(userId);

    requestNotificationPermission();

    // `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
    openSession();
};

export const handleBrazeInitialUrl = (_callback: (url: string | null) => void) => {
    // Braze.getInitialURL(callback) is not supported on web
    return;
};
