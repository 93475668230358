import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import Svg, { Circle, G } from 'react-native-svg';

import BetrLarge from '@/assets/icons/betr-large';
import { designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';

interface DonutChartProps {
    data: {
        value: number;
        color: string;
    }[];
    radius?: number;
    fill?: string;
    strokeWidth?: number;
    gap?: number;
    bgStrokeColor?: string;
    bgStrokeOpacity?: string;
    bgStrokePadding?: number;
}
export const DonutChart: React.FC<DonutChartProps> = ({
    data,
    radius = 60,
    fill = 'transparent',
    strokeWidth = 6,
    gap = 8,
    bgStrokeColor = designSystem.colors.green,
    bgStrokeOpacity = '.1',
    bgStrokePadding = 0,
}) => {
    let lastOffsetRotation = 0;
    const [donutState, setDonutState] = useState({
        max: 100,
        circumference: 33,
        radius: 60,
        fill: 'transparent',
        strokeWidth: strokeWidth,
        gap: 6,
        bgStrokeColor: designSystem.colors.green,
        bgStrokeOpacity: '.1',
        bgStrokePadding: 0,
        width: 0,
        height: 0,
    });

    const halfCircle = useMemo(() => radius + strokeWidth, [radius, strokeWidth]);

    const updateChart = useCallback(() => {
        if (!data) {
            return;
        }
        const newMax = data.reduce((p, c) => {
            return p + c.value;
        }, 0);
        const circumference = 2 * Math.PI * radius;

        setDonutState({
            max: newMax,
            circumference,
            radius,
            fill,
            strokeWidth,
            gap,
            bgStrokeColor,
            bgStrokeOpacity,
            bgStrokePadding,
            width: radius * 2,
            height: radius * 2,
        });
    }, [data, radius, bgStrokeColor, bgStrokeOpacity, bgStrokePadding, fill, gap, strokeWidth]);

    useEffect(() => updateChart(), [updateChart]);

    const filtered = Array.isArray(data) ? data.filter(item => item.value) : [];
    const minValue = filtered.reduce((p, c) => (c.value < p ? c.value : p), filtered[0].value);

    return (
        <View style={[{ width: donutState.width, height: donutState.height }]}>
            <Svg
                height={donutState.width}
                width={donutState.height}
                viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}
            >
                {filtered.map((item, index) => {
                    const totalGap = donutState.gap * (filtered.length - 1);
                    const startOffset = donutState.circumference - totalGap;
                    const endOffset = (item.value * donutState.circumference) / donutState.max;
                    const currentRotation = (item.value * 360) / donutState.max + lastOffsetRotation;
                    lastOffsetRotation = currentRotation;

                    return (
                        <Circle
                            key={index}
                            cx="50%"
                            cy="50%"
                            r={donutState.radius}
                            originX={donutState.radius + donutState.strokeWidth}
                            originY={donutState.radius + donutState.strokeWidth}
                            rotation={currentRotation - 90}
                            stroke={item.color}
                            strokeWidth={isWeb ? (minValue !== item.value ? 7 : 5) : donutState.strokeWidth}
                            strokeLinecap={'round'}
                            strokeDashoffset={startOffset + endOffset}
                            strokeDasharray={donutState.circumference}
                        />
                    );
                })}
                <G x={donutState.radius - 20} y={donutState.radius - 20} {...({} as any)}>
                    <BetrLarge width={donutState.radius} height={donutState.radius} />
                </G>
            </Svg>
        </View>
    );
};
