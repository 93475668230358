import React from 'react';
import { Modal, Pressable, ScrollView, StyleSheet, View } from 'react-native';

import { designSystem } from '@/styles/styles';

import { Text } from './TextComponent';

type Item = {
    value: string;
    label: string;
};

type PickerProps = {
    items: Item[];
    pickedValue: string;
    closePicker: () => void;
    handleValueChange: (pickedValue: string) => void;
    onValueSelected?: (pickedValue: string) => void;
};

export const Picker = ({ items, closePicker, handleValueChange, onValueSelected }: PickerProps) => {
    const handlePressItem = (itemValue: string) => {
        handleValueChange(itemValue);
        onValueSelected?.(itemValue);
        closePicker();
    };

    return (
        <Modal visible transparent>
            <View style={[styles.root]}>
                <Pressable onPress={closePicker} style={styles.overlay} />

                <View style={styles.modalContent}>
                    <ScrollView
                        showsVerticalScrollIndicator={true}
                        contentContainerStyle={[styles.scrollViewContentContainer]}
                        style={[styles.scrollView]}
                    >
                        {items.map((item, index) => (
                            <Text
                                key={`${item.value}-${index}`}
                                variant={'buttonLabel'}
                                onPress={() => handlePressItem(item.value)}
                            >
                                {item.label}
                            </Text>
                        ))}
                    </ScrollView>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    root: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: designSystem.colors.gray8,
        margin: 30,
        minWidth: '50%',
        maxHeight: '80%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    scrollView: {
        borderColor: designSystem.colors.gray5,
        borderWidth: 1,
        width: '100%',
        padding: 16,
    },
    scrollViewContentContainer: {
        flex: 1,
        alignContent: 'flex-start',
        rowGap: 20,
    },
});
