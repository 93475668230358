import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { AlertIcon } from '@/feature/betslip-sbk/components/AlertIcon';

export const useBetEjectedAlert = () => {
    const { t } = useTranslation(['betslip_sbk', 'common']);
    const { showInfoSheet } = useAlerts();
    const showBetEjectedAlert = () => {
        showInfoSheet({
            title: t('betslip_sbk:bet_rejected'),
            description: t('betslip_sbk:place_error_fallback'),
            buttonLabel: t('common:close'),
            icon: <AlertIcon />,
        });
    };
    return { showBetEjectedAlert };
};
