import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none" {...props}>
        <G fill={props.color ? props.color : '#000'} clipPath="url(#a)">
            <Path
                fillRule="evenodd"
                d="M11.496 2.437s-3.349.057-4.785 2.544c-.16.276-.299.562-.421.853l-2.293.979-1.687 4 3.152-1.068c-.032.62-.011 1.016-.011 1.016l1.865 1.077s.351-.18.87-.518l.651 3.261 2.62-3.461-.298-2.478c.19-.25.367-.514.526-.79 1.436-2.487-.19-5.415-.19-5.415ZM8.914 6.916a.861.861 0 1 0 .862-1.493.861.861 0 0 0-.862 1.493Z"
                clipRule="evenodd"
            />
            <Path d="M5.876 11.629a1 1 0 0 0-.532-.114l-.418.027a1 1 0 0 0-.902 1.257l.473 1.764 1.764-.473a1 1 0 0 0 .638-1.41l-.186-.375a1 1 0 0 0-.368-.406c.258.357.1.861-.315 1.008l-.727.256-.142-.758a.658.658 0 0 1 .715-.776Z" />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 .5h16v16H0z" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgComponent;
