import { ProductConfig, getProductType, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { isWeb } from '@/utils/constants-platform-specific';

import { useActiveWalletStore } from './use-active-wallet';

/**
 * Custom hook to get the product config of the currently active product within the app.
 *
 * @returns the currently active product config
 */
export const useActiveProductConfig = (): ProductConfig | undefined => {
    const product = useJurisdictionStore(state => state.product);
    const products = useJurisdictionStore(state => state.jurisdictionSettings?.products);
    return products?.find(p => getProductType(p.uid) === product);
};

/**
 * Custom hook that returns if placing entries/bets is disabled in the current active product.
 *
 * This is needed, because we are looking at offering our products in states that are currently not eligible, and we want to let users to browse
 * our sports and picks, but they wont be able to also place bets/entries.
 *
 * @returns - true if gameplay is offline for the active product
 */
export const useIsGameplayOfflineForActiveProduct = (): boolean => {
    const config = useActiveProductConfig();
    const usState = useJurisdictionStore(state => state.jurisdiction);

    const gameplayOfflineStates = config?.gameplayOfflineStates ?? [];
    return gameplayOfflineStates.includes(usState ?? '');
};

/**
 * Similar to `useIsGampleayOfflineForActiveProduct`, but instead checks if gampleay is offline for the product that is selected in the wallet.
 * @returns - true if gameplay is offline for the active wallet product
 */
export const useIsGameplayOfflineForActiveProductWallet = (): boolean => {
    const activeWalletProduct = useActiveWalletStore(state => state.activeWalletProduct);
    const userInIneligibleState = useIsUserInIneligibleState();
    const usState = useJurisdictionStore(state => state.jurisdiction);
    const productsConfigs = useJurisdictionStore(state => state.jurisdictionSettings?.products);
    const activeWalletProductCfg = productsConfigs?.find(it => getProductType(it.uid) === activeWalletProduct);
    const gameplayOfflineStates = activeWalletProductCfg?.gameplayOfflineStates ?? [];
    let canDepositInCurrentState = !gameplayOfflineStates.includes(usState ?? '');

    // only on WEB we can open the app in an ineligible state, so
    // we need to restrict deposit on web for these ineligible states
    if (isWeb && canDepositInCurrentState) {
        canDepositInCurrentState = !userInIneligibleState;
    }

    //- If there is no product config for the active selected wallet, this means play is not available in this state for that product
    //- If the active wallet product config has a list of gampelay_offline_states that includes the current state, this means
    //gameplay is not available in this state
    return activeWalletProductCfg === undefined || !canDepositInCurrentState;
};

/**
 * Custom hook to check if the user is in an ineligible state to play.
 * @returns true if user is in an ineligible state to play
 */
const useIsUserInIneligibleState = () => {
    const usState = useJurisdictionStore(state => state.jurisdiction);
    const ineligibleStates = useJurisdictionStore(state => state.jurisdictionSettings?.ineligibleStates);
    return usState && ineligibleStates ? ineligibleStates.includes(usState) : false;
};
