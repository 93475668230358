import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';

import BetrBucks from '@/assets/icons/betr-bucks';
import { CurrencyInput } from '@/components/CurrencyInput';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { designSystem } from '@/styles/styles';

import { useIsBetSlipSubmitting } from '../hooks/betslip-hooks';
import { useSbkBetSlipStore } from '../hooks/use-sbk-betslip-store';
import { formatStakeInput } from '../utils/formatter';

export const StakeInput = ({ betId, hasError }: { betId: string; hasError?: boolean }) => {
    const displayStake = useSbkBetSlipStore(state => state.bets[betId]?.displayStake ?? '');
    const setEditingBet = useSbkBetSlipStore(state => state.actions.setEditingBet);
    const setShowKeyboard = useSbkBetSlipStore(state => state.actions.setShowKeyboard);
    const showKeyboard = useSbkBetSlipStore(state => state.showKeyboard);
    const ref = useRef<TextInput>(null);

    const isBetUsingBetrBucks = useSbkBetSlipStore(state => state.bets[betId]?.isBetrBucks);
    const isBetrBucksEnabled = useSbkBetSlipStore(state => state.useBetrBucks);
    const isCurrentlyEditing = useSbkBetSlipStore(state => state.editBetId) === betId;
    const isBetSlipSubmitting = useIsBetSlipSubmitting();

    const { t } = useTranslation(['betslip_sbk', 'wallet']);
    const isBetrBucks = (isBetrBucksEnabled && isCurrentlyEditing && showKeyboard) || isBetUsingBetrBucks;
    const label = isBetrBucks ? t('wallet:betr_bucks') : t('betslip_sbk:wager');
    const iconColor = isCurrentlyEditing && showKeyboard ? designSystem.colors.white : designSystem.colors.gray3;
    const icon = isBetrBucks ? <BetrBucks fill={iconColor} /> : undefined;

    useEffect(() => {
        if (ref.current?.isFocused() && !showKeyboard) {
            ref.current.blur();
        }
    }, [showKeyboard]);

    return (
        <CurrencyInput
            hasError={hasError}
            editable={!isBetSlipSubmitting}
            ref={ref}
            showSoftInputOnFocus={false}
            onFocus={() => {
                GASbkEvents.clickWagerInput();
                setEditingBet(betId);
                setShowKeyboard(true);
            }}
            label={label}
            value={formatStakeInput(displayStake)}
            icon={icon}
            testID="wager-input"
        />
    );
};
