import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetCard } from '@/components/BetCard';
import { DotStatus } from '@/components/ProgressDots';

import { Bet } from '../../hooks/types';
import {
    useFooter,
    useGameStatus,
    useMarketStatus,
    useOpenBadge,
    voidedBetCardProps,
} from '../../utils/bet-card-utils';
import { getProgressDots } from '../../utils/utils';
import { CommonBetCardProps } from './SingleSbkBetCard';
import { useOpenMultiBetSummary, useSettledMultiBetSummary, useVoidedBetSummary } from './utils';

type CommonScriptBetCardProps = CommonBetCardProps & {
    progressDots: DotStatus[];
};

/**
 * Helper Hooks
 */
const useTitle = (bet: Bet) => {
    const { t } = useTranslation('bets');
    return t('script_bet_name', { count: bet.selection_count });
};

const useSubTitle = (bet: Bet): string => {
    const marketStatus = useMarketStatus(bet.events[0]);
    const gameStatus = useGameStatus(bet);
    return [marketStatus, gameStatus].join(' • ');
};

/**
 * Components
 */
const OpenScriptBetCard = ({ bet, ...commonBetDetails }: CommonScriptBetCardProps) => {
    const status = useOpenBadge(bet);
    return <BetCard status={status} summary={useOpenMultiBetSummary(bet)} {...commonBetDetails} />;
};

const SettledScriptBetCard = ({
    bet,
    status,
    ...commonBetDetails
}: CommonScriptBetCardProps & {
    status: 'won' | 'lost';
}) => {
    return <BetCard status={status} summary={useSettledMultiBetSummary(bet)} {...commonBetDetails} />;
};

const VoidedScriptBetCard = ({ bet, ...commonBetDetails }: CommonScriptBetCardProps) => {
    return <BetCard status="voided" summary={useVoidedBetSummary(bet)} {...commonBetDetails} {...voidedBetCardProps} />;
};

export const ScriptSbkBetCard = ({ bet }: { bet: Bet }) => {
    const title = useTitle(bet);
    const subTitle = useSubTitle(bet);
    const footer = useFooter(bet);
    const progressDots = getProgressDots(bet);
    const commonBetDetails = { title, subTitle, footer, progressDots };

    switch (bet.result) {
        case 'PENDING':
            return <OpenScriptBetCard bet={bet} {...commonBetDetails} />;
        case 'WON':
        case 'CASHEDOUT':
            return <SettledScriptBetCard bet={bet} status={'won'} {...commonBetDetails} />;
        case 'LOST':
            return <SettledScriptBetCard bet={bet} status={'lost'} {...commonBetDetails} />;
        case 'CANCELLED':
        case 'PUSHED':
            return <VoidedScriptBetCard bet={bet} {...commonBetDetails} />;
    }
};
