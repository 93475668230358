import { URLS, createJurisdictionHeaders } from '@/data';
import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { useQuery } from '@tanstack/react-query';

import { GetBetDetailResponse } from './types';
import { Bet, BetEvent } from './types';
import { betKeys } from './use-bets';

const getBetDetail = async (betId: string): Promise<GetBetDetailResponse> => {
    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/bet/${betId}`, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
    });

    const data = await resp.json();

    if (!resp.ok) {
        throw data;
    }

    useEventDetailsCache.getState().actions.populate(
        data?.data?.events.map((event: BetEvent) => ({
            ...(event.event_details ?? {}),
            id: event.id,
            status: event.status,
        }))
    );

    return data;
};

/**
 *
 * @param betId required field for fetching bet details from api
 * @param bet optional field to use as placeholder data to avoid loading spinner
 */
export function useBetDetailQuery(betId: string, bet?: Bet) {
    return useQuery(betKeys.detail(betId), async () => await getBetDetail(betId), {
        select: data => data.data,
        placeholderData: bet && { data: bet },
    });
}
