import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Easing, LayoutChangeEvent, StyleSheet, View } from 'react-native';

import { common, designSystem } from '@/styles/styles';
import { Outcome, Result } from '@/types/api.generated';

import { LineSeparator } from './LineSeparator';
import { LoadingSkeleton } from './SkeletonLoader';
import { Text } from './TextComponent';

export type ProgressBarProps = {
    max: number;
    value?: number | null;
    result?: Result;
    outcome: Outcome;
    loading: boolean;
    isTie?: boolean;
};

const styles = StyleSheet.create({
    root: {
        borderRadius: 4,
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
    },
    content: {
        borderRadius: 2,
        height: 4,
        backgroundColor: designSystem.colors.gray6,
    },
    rightLine: {
        width: 24,
    },
    lineSeparator: {
        height: 14,
        width: 2,
        backgroundColor: designSystem.colors.gray3,
        borderRadius: 2,
        marginHorizontal: 6,
    },
    progressBar: { flex: 1, flexDirection: 'row', alignItems: 'center' },
    textSpace: { marginLeft: 6, paddingRight: 6 },
    textPadding: { paddingRight: 6 },
});

const isResultValid = (result: Result) => {
    return result === Result.Win || result === Result.Loss;
};

const shouldFillBar = (result: Result | undefined, outcome: Outcome) => {
    return (result === Result.Win && outcome === Outcome.More) || (result === Result.Loss && outcome === Outcome.Less);
};

const resultColors = {
    [Result.Win]: designSystem.colors.green,
    [Result.Loss]: designSystem.colors.red,
    [Result.Void]: designSystem.colors.gray6,
};

const EndBarWidth = 24;

// isTie is a state that we don't currently have, but it's a feature that will be worked on
export const Progressbar = ({ value = 0, max, result, outcome, loading, isTie = false }: ProgressBarProps) => {
    const { t } = useTranslation(['pickem_entries', 'common']);
    const [barContainerWidth, setBarContainerWidth] = useState(0);
    const [textWidth, setTextWidth] = useState(0);
    const progressBar = useRef(new Animated.Value(0)).current;
    const endBar = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.stagger(450, [
            Animated.timing(progressBar, {
                toValue: value ?? 0,
                duration: 500,
                easing: Easing.inOut(Easing.ease),
                useNativeDriver: false,
            }),
            Animated.timing(endBar, {
                toValue: EndBarWidth,
                duration: 100,
                easing: Easing.inOut(Easing.ease),
                useNativeDriver: false,
            }),
        ]).start();
    }, [value, progressBar, endBar, result, outcome]);

    const displaySeparator = !loading && !isTie && !shouldFillBar(result, outcome);

    const getSeparator = useMemo(() => {
        if (result === Result.Void) {
            return (
                <Text color={'gray4'} variant={'bodySmall'} letterSpacing={-0.08} style={styles.textSpace}>
                    {t('common:voided')}
                </Text>
            );
        }

        return <LineSeparator style={styles.lineSeparator} />;
    }, [result, t]);

    const getCurrentStatus = useMemo(() => {
        if (isTie) {
            return (
                <Text color={'gray1'} variant={'bodySmall'} letterSpacing={-0.08}>
                    {t('tied_value', { value })}
                </Text>
            );
        }

        return (
            <Text color={'gray1'} variant={'bodySmall'} letterSpacing={-0.08}>
                {value}
            </Text>
        );
    }, [isTie, t, value]);

    const endBarBackgroundColor = isTie
        ? designSystem.colors.gray3
        : typeof result === 'undefined' || !shouldFillBar(result, outcome)
        ? designSystem.colors.gray6
        : resultColors[result];

    const fillBarBackgroundColor = isTie
        ? designSystem.colors.gray3
        : typeof result === 'undefined' || !isResultValid(result)
        ? designSystem.colors.gray1
        : resultColors[result];

    const getBarWidth = useCallback((event: LayoutChangeEvent) => {
        setBarContainerWidth(event.nativeEvent.layout.width);
    }, []);

    const getTextWidth = useCallback((event: LayoutChangeEvent) => {
        const gap = 6;
        setTextWidth(event.nativeEvent.layout.width + gap);
    }, []);

    const displayCurrentValue = value != null && result !== Result.Void;

    return (
        <View style={styles.root}>
            <View style={styles.progressBar} onLayout={getBarWidth}>
                {displayCurrentValue ? (
                    <>
                        <Animated.View
                            style={[
                                styles.content,
                                {
                                    backgroundColor: fillBarBackgroundColor,
                                    width: progressBar.interpolate({
                                        inputRange: [0, max],
                                        outputRange: [0, barContainerWidth - textWidth],
                                        extrapolate: 'clamp',
                                    }),
                                },
                            ]}
                        />
                        <View onLayout={getTextWidth} style={value > 0 ? styles.textSpace : styles.textPadding}>
                            {getCurrentStatus}
                        </View>
                    </>
                ) : null}
                <View style={common.flex}>
                    {!loading ? (
                        <View style={styles.content} />
                    ) : (
                        <LoadingSkeleton
                            style={styles.content}
                            height={4}
                            animationSpeed={1500}
                            skeletonColor={designSystem.colors.gray4}
                            skeletonGradient={[
                                'transparent',
                                designSystem.colors.gray4,
                                designSystem.colors.gray4,
                                'transparent',
                            ]}
                            positions={[0, 0.25, 0.75, 1]}
                        />
                    )}
                </View>
            </View>

            {displaySeparator ? getSeparator : null}
            {!loading ? (
                <View style={[styles.content, { width: EndBarWidth }]}>
                    <Animated.View
                        style={[
                            styles.content,
                            {
                                backgroundColor: endBarBackgroundColor,
                                width: endBar.interpolate({
                                    inputRange: [0, EndBarWidth],
                                    outputRange: ['0%', '100%'],
                                    extrapolate: 'clamp',
                                }),
                            },
                        ]}
                    />
                </View>
            ) : null}
        </View>
    );
};
