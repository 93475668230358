import React from 'react';

import { Text, TextProps } from '@/components/TextComponent';
import { useBetSlipMarketName } from '@/feature/betslip-sbk/hooks/use-bet-slip-market-name';

type MarketNameTextProps = TextProps & { eventId: string; marketId: string };

export const BetSlipMarketName = ({ eventId, marketId, ...textProps }: MarketNameTextProps) => {
    const marketName = useBetSlipMarketName({ marketId, eventId });
    return (
        <Text {...textProps} testID="marketName">
            {marketName}
        </Text>
    );
};
