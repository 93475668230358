import { useCallback, useMemo } from 'react';

import { useMicroMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-micro-market-cache';
import { MarketCategoryType } from '@/feature/event-details-sbk/types';
import { useSubscribePublic } from '@/utils/websocket/hooks';
import { NewMarketMessage } from '@/utils/websocket/types';

export const useSubscribeEventNewMarket = (
    eventId: string,
    marketGroupId: string,
    marketCategoryType: MarketCategoryType,
    enabled?: boolean
) => {
    const { actions } = useMicroMarketCacheStore();
    const handleNewMarket = useCallback(
        (message: NewMarketMessage) => {
            actions.handleNewMarket(message, marketCategoryType);
        },
        [actions, marketCategoryType]
    );
    const params = useMemo(() => ({ eventId, marketGroupId }), [eventId, marketGroupId]);
    useSubscribePublic<'eventNewMarket'>(
        'event:{eventId}:marketGroup:{marketGroupId}',
        params,
        'new_market',
        handleNewMarket,
        enabled
    );
};
