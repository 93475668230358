import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';

import SubtractIcon from '@/assets/icons/subtract';
import { LineSeparator } from '@/components/LineSeparator';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { Jurisdiction } from '@/data';
import { useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { common } from '@/styles/styles';
import { toTitleCase } from '@/utils/toTitleCase';
import { TouchableOpacity } from '@gorhom/bottom-sheet';
import { format } from 'date-fns';

import { LIMIT_DURATION } from '../constants';
import { UserLimitDuration } from '../hooks/types';
import { StateLimitData, StateLimitStatus, useStateLimits } from '../hooks/use-state-limits';

type StateLimitsOption = {
    id: string;
    title: string;
    description: string;
};

type ListItemProps = {
    item: StateLimitsOption;
};

const styles = StyleSheet.create({
    listItem: {
        display: 'flex',
        justifyContent: 'center',
        height: 56,
    },
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
});

const transformStateLimits = (
    stateLimits: StateLimitData[],
    handleTitleTranslation: (limit: StateLimitData) => string
): StateLimitsOption[] => {
    const fantasyLimits = [
        ...stateLimits
            .filter(limit => limit.vertical === Jurisdiction.FANTASY)
            .map((limit, index) => {
                const formattedDate = format(limit?.limit_goes_live_at, 'MM-dd-yy');
                return {
                    id: `${index}+${limit.jurisdiction}`,
                    title: handleTitleTranslation(limit),
                    description: `$${limit.amount} ${toTitleCase(limit?.status ?? '')} ${
                        limit.status === StateLimitStatus.Removed ? formattedDate : ''
                    }`,
                };
            }),
    ];

    return fantasyLimits;
};

const ListItem = ({ item }: ListItemProps) => {
    const touchableOpacityStyles = [common.column, common.justifySpaceBetween, styles.listItem];

    return (
        <TouchableOpacity style={touchableOpacityStyles} onPress={() => trackRG.realityCheckScreen(item.title)}>
            <Text fontSize={15}>{item.title}</Text>
            <Text fontSize={15} color={'gray2'}>
                {item.description}
            </Text>
        </TouchableOpacity>
    );
};

export const StateLimitsScreen = () => {
    const { t } = useTranslation('rg');
    const { launchIntercomMessenger } = useLaunchIntercomMessenger();
    const { data, isLoading } = useStateLimits();

    const handleTitleTranslation = (limit: StateLimitData) => {
        return t('state_limit_item_title', {
            jurisdiction: limit.jurisdiction,
            duration: LIMIT_DURATION[limit.duration as UserLimitDuration],
            type: limit.type.toLowerCase(),
        });
    };

    if (isLoading) {
        return (
            <View style={[common.alignCenter, common.justifyCenter, common.fullHeight]}>
                <LoadingSpinner />
            </View>
        );
    }

    const transformedStateLimits = data ? transformStateLimits(data.jurisdiction_limits, handleTitleTranslation) : [];

    return (
        <Screen>
            <ScreenNavBar title={t('jurisdiction_limits')} />
            <ScrollView style={styles.container} contentContainerStyle={styles.scrollContent}>
                <MaxWidthWrapper>
                    <Box paddingHorizontal={'s16'}>
                        <Text fontSize={13} color={'gray2'} lineHeight={20}>
                            {t('state_limits_body')}
                        </Text>
                        <SizedBox value={20} />
                        <Text variant="headlineSmall" marginBottom="s18">
                            {t('current_limits')}
                        </Text>

                        {transformedStateLimits.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <ListItem item={item} />
                                {index < transformedStateLimits.length - 1 && <LineSeparator />}
                            </React.Fragment>
                        ))}

                        <SizedBox value={24} />
                        <Banner
                            leftIcon={<SubtractIcon />}
                            message={
                                <Trans i18nKey="state_limits_info_box" ns="rg">
                                    The state that you're currently in has set monthly limits on how much you can
                                    deposit while you're located there. To manage this limit, please,
                                    <TouchableOpacity onPress={launchIntercomMessenger}>
                                        <Text
                                            lineHeight={16}
                                            fontWeight="700"
                                            textDecorationLine="underline"
                                            color="white"
                                        >
                                            contact support
                                        </Text>
                                    </TouchableOpacity>
                                </Trans>
                            }
                            borderColor={'gray5'}
                            borderWidth={1}
                        />
                    </Box>
                </MaxWidthWrapper>
            </ScrollView>
        </Screen>
    );
};
