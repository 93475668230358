import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { URLS, createJurisdictionHeaders } from '@/data';
import { League, leagueKeys } from '@/feature/league-hub-sbk/hooks/use-league';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { ProductDocumentDataBodyLeaguesSliceItem } from '@/types/prismic.generated';
import { useQuery } from '@tanstack/react-query';

export type GetAllLeaguesResponse = {
    data: League[];
};

export type LaunchpadLeagueProps = {
    eventsCount: number;
    subLabel: string | JSX.Element;
} & ProductDocumentDataBodyLeaguesSliceItem;

const getAllLeagues = async (): Promise<GetAllLeaguesResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/leagues`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return data;
};

const useAllLeagues = () => {
    return useQuery(leagueKeys.all, () => getAllLeagues(), {
        select: data => data.data,
    });
};

/**
 * Fetches all leagues from /leagues endpoint and add event count to leagues from prismic, sort to show leagues with events first
 */
export const useSortedAllLeaguesWithEventCount = () => {
    const productConfig = useJurisdictionStore(store => store.jurisdictionSettings?.productConfig);

    const { t } = useTranslation('sports_events');
    const { data: leaguesData } = useAllLeagues();

    const sortedLeagues = useMemo(() => {
        const leagues = productConfig?.leagues ?? [];
        const leaguesWithEvents: LaunchpadLeagueProps[] = [];
        const emptyLeagues: LaunchpadLeagueProps[] = [];

        leagues.forEach(league => {
            const leagueData = leaguesData?.find(({ id }) => id === league.id);
            const eventsCount = leagueData?.event_count ?? 0;
            const subLabel = t('game', { count: eventsCount });

            if (eventsCount === 0) {
                emptyLeagues.push({ ...league, eventsCount, subLabel });
            } else {
                leaguesWithEvents.push({ ...league, eventsCount, subLabel });
            }
        });

        return [...leaguesWithEvents, ...emptyLeagues];
    }, [leaguesData, productConfig?.leagues, t]);

    return sortedLeagues;
};
