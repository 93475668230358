import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { PaymentErrorModalContent } from '@/components/PaymentErrorModalContent';
import { Screen } from '@/components/ScreenComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useBetslipNavigation } from '@/feature/betslip-pickem/hooks/use-betslip-navigation';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';

import { useTrackFirstDepositEvent } from '../hooks/use-track-first-deposit-event';
import { PayPalStatus } from '../utils';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'FailedDepositModal'>;

export const FailedDeposit = ({ route }: ScreenProps) => {
    const { selectedAmount, paymentProvider, errorCode = '', showContactSupport = false } = route.params;

    const navigation = useNavigation();
    const { t } = useTranslation();

    const { resetToEntryInputAmount } = useBetslipNavigation();

    usePreventAndroidBackNavigation();
    useTrackFirstDepositEvent(PayPalStatus.ERROR, paymentProvider, selectedAmount, errorCode);

    const handleClose = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.DEPOSIT_FAILURE_ABORT, { method: paymentProvider });
        const navigationState = navigation.getState();
        const entryAmountRoute = navigationState?.routes.find(it => it.name === 'EntryInputAmount');

        // if the entry amount route is in the stack, the deposit flow was started from there
        // so we need to reset the stack to that point
        if (entryAmountRoute) {
            resetToEntryInputAmount();
            return;
        }

        navigateHome(navigation, 'account');
    };

    const handlePrimaryButtonPress = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.DEPOSIT_FAILURE_RETRY, { method: paymentProvider });
        navigation.navigate('DepositScreen', { selectedAmount });
    };

    return (
        <Screen edges={['top', 'bottom']}>
            <PaymentErrorModalContent
                transactionType="deposit"
                onClose={handleClose}
                errorCode={errorCode}
                selectedAmount={selectedAmount}
                primaryButton={t('retry')}
                handlePrimaryButtonPress={handlePrimaryButtonPress}
                showSecondaryButton={false}
                showContactSupport={showContactSupport}
                handleSupportEmailPress={() => {
                    BetrAnalytics.trackProductEvent(AnalyticsEvent.DEPOSIT_FAILURE_SUPPORT_REQUEST, {
                        method: paymentProvider,
                    });
                }}
                invokingScreen={route.name}
                paymentMethod={paymentProvider}
            />
        </Screen>
    );
};
