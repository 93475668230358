import { useCallback, useState } from 'react';

import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';

type UrlEventMapping = {
    [key: string]: {
        event: AnalyticsEvent;
        params: Record<string, string | number | boolean>;
    };
};

/**
 * Custom hook for tracking registration and login events, mainly close button on top left of screen.
 *
 * This hook provides functionality to track specific authentication-related events based on the current KeyCloak URL. Each pattern is associated with a specific event and optional parameters for analytics tracking.
 *
 * @returns An object containing:
 * - `handleTrackAbortAuth`: A callback function to check the current auth URL against predefined patterns and track the corresponding event.
 * - `setAuthUrl`: A function to update the internal state of the auth URL.
 */
export const useTrackAuth = () => {
    const [authUrl, setAuthUrl] = useState('');

    const handleTrackAbortAuth = useCallback(() => {
        const urlEventMapping: UrlEventMapping = {
            auth: {
                event: AnalyticsEvent.LOGIN_ABORTED,
                params: {},
            },
            authenticate: {
                event: AnalyticsEvent.LOGIN_MFA_ABORTED,
                params: {},
            },
            registration: {
                event: AnalyticsEvent.CREATION_EMAIL_ABORTED,
                params: {},
            },
            'promo-code-ra': {
                event: AnalyticsEvent.CREATION_PHONE_NUMBER_ABORTED,
                params: {},
            },
            'verify-phone-number-ra': {
                event: AnalyticsEvent.CREATION_MFA_ABORTED,
                params: {},
            },
        };

        const matchedEvent = Object.entries(urlEventMapping).find(([key]) => authUrl.includes(key));

        if (matchedEvent) {
            const [, { event, params }] = matchedEvent;
            BetrAnalytics.trackEvent(event, params);
        }
    }, [authUrl]);

    return {
        handleTrackAbortAuth,
        setAuthUrl,
    };
};
