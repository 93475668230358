import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo } from 'react-native';

import InfoIcon from '@/assets/icons/info-full-white';
import { Box } from '@/components/lib/components';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { RemovableCardType } from '@/types/removable-cards';

import { useIsCardRemovable } from '../../hooks/use-is-card-removable';
import { ExpiredCardItem } from './debit-card-items/ExpiredCardItem';
import { NonInteractableCardItem } from './debit-card-items/NonInteractableCardItem';
import { RemovableCardItem } from './debit-card-items/RemovableCardItem';

const EmptyDebitCardListBanner = () => {
    const { t } = useTranslation('manage_cards');
    return (
        <Box marginTop="s24">
            <Banner
                leftIcon={<InfoIcon />}
                message={t('debit_card_removal_info')}
                borderColor={'gray5'}
                borderWidth={1}
            />
        </Box>
    );
};

type ManageDebitCardItemListProps = {
    cards: RemovableCardType[];
    handleRemovalProcess: (paymentTypeId: string | null) => void;
};

export const ManageDebitCardItemList = ({ cards, handleRemovalProcess }: ManageDebitCardItemListProps) => {
    const isCardRemovable = useIsCardRemovable();

    const renderCard = ({ item: cardData }: ListRenderItemInfo<RemovableCardType>): React.ReactElement => {
        if (cardData.is_expired) {
            return <ExpiredCardItem key={cardData.payment_type_id} card={cardData} />;
        }
        if (isCardRemovable(cardData)) {
            return (
                <RemovableCardItem
                    key={cardData.payment_type_id}
                    card={cardData}
                    handleRemovalProcess={handleRemovalProcess}
                />
            );
        }
        return <NonInteractableCardItem key={cardData.payment_type_id} card={cardData} />;
    };

    if (cards.length === 0) {
        return <EmptyDebitCardListBanner />;
    }

    return <FlatList data={cards} renderItem={renderCard} keyExtractor={item => item.payment_type_id} />;
};
