import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetSummarySection, BetSummarySectionProps } from '@/components/BetSummarySection';
import { Bet } from '@/feature/bets-sbk/hooks/types';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { useBetDetailsVoidedBetSummary, useSharedBetDetailsBetSummary } from './utils';

/**
 * Helper Hooks
 */
const useBetDetailsOpenSingleBetSummary = (bet: Bet): BetSummarySectionProps => {
    return {
        ...useSharedBetDetailsBetSummary(bet),
        body3Text: toLocaleCurrency(bet.potential_payout),
    };
};

const useBetDetailsSettledSingleBetSummary = (bet: Bet): BetSummarySectionProps => {
    const { t } = useTranslation('bets');
    return {
        ...useSharedBetDetailsBetSummary(bet),
        header3Text: t('paid'),
        header3TextColor: bet.amount_won > 0 ? 'green' : 'gray2',
        body3Text: toLocaleCurrency(bet.amount_won),
        body3TextColor: bet.amount_won > 0 ? 'green' : 'gray2',
    };
};

/**
 * Components
 */
const OpenSingleBetSummary = ({ bet }: { bet: Bet }) => (
    <BetSummarySection {...useBetDetailsOpenSingleBetSummary(bet)} />
);

const VoidedSingleBetSummary = ({ bet }: { bet: Bet }) => <BetSummarySection {...useBetDetailsVoidedBetSummary(bet)} />;

const SettledSingleBetSummary = ({ bet }: { bet: Bet }) => (
    <BetSummarySection {...useBetDetailsSettledSingleBetSummary(bet)} />
);

export const SingleBetSummarySection = ({ bet }: { bet: Bet }) => {
    switch (bet.result) {
        case 'PENDING':
            return <OpenSingleBetSummary bet={bet} />;
        case 'WON':
        case 'LOST':
        case 'CASHEDOUT':
            return <SettledSingleBetSummary bet={bet} />;
        case 'CANCELLED':
        case 'PUSHED':
            return <VoidedSingleBetSummary bet={bet} />;
        default:
            return <OpenSingleBetSummary bet={bet} />;
    }
};
