import React, { useCallback, useRef } from 'react';
import { FlatList } from 'react-native';
import Animated from 'react-native-reanimated';

import { useStickyTabList, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { SubMarketTabs } from '@/feature/event-details-sbk/components/SubMarketTabs';
import PlayerProp from '@/feature/event-details-sbk/components/market-page-layouts/player-props-layout/PlayerProp';
import { useActiveSubMarket } from '@/feature/event-details-sbk/hooks/use-active-submarket';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { useHasPublishedMarkets, usePublishedMarkets } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { Market, MarketCategory, MarketLayout } from '@/feature/event-details-sbk/types';
import { useScrollToTopOnChangeMarket } from '@/feature/league-hub-sbk/hooks/use-scroll-to-top';
import { common, designSystem } from '@/styles/styles';
import { QueryObserverResult } from '@tanstack/react-query';

import { useEventsGroupedById } from '../../hooks/use-events';
import { useLeagueMarketList } from '../../hooks/use-league-market-list';
import { useSectionHeights } from '../../hooks/use-section-heights';

const keyExtractor = (item: Market) => item.id;

type LeaguePlayerPropLayoutProps = {
    leagueId: string;
    sportName: string;
    marketCategories: MarketCategory[];
    refetch: () => Promise<QueryObserverResult<any, unknown>[]>;
    tabKey: string;
    isTabBarVisible: boolean;
};

const PLAYER_PROP_HEIGHT = 88;
const PLAYER_PROP_STANDARD_HEIGHT = 152;

const getItemLayout = (height: number) => (_: any, index: number) => ({
    length: height,
    offset: height * index,
    index,
});

const getPlayerPropFlatListConfig = (layout?: MarketLayout | null) => {
    switch (layout) {
        case 'player_prop_list':
            return {
                getItemLayout: getItemLayout(PLAYER_PROP_HEIGHT),
                initialNumToRender: 6,
                maxToRenderPerBatch: 6,
            };
        case 'player_prop_standard':
            return {
                getItemLayout: getItemLayout(PLAYER_PROP_STANDARD_HEIGHT),
                initialNumToRender: 12,
                maxToRenderPerBatch: 12,
            };
        default:
            return {};
    }
};

export const LeaguePlayerPropLayout = ({
    leagueId,
    sportName,
    marketCategories,
    refetch,
    tabKey,
    isTabBarVisible,
}: LeaguePlayerPropLayoutProps) => {
    const { gameSectionHeight, marketHeaderSectionHeight } = useSectionHeights(leagueId);
    const { activeSubMarket, setActiveSubMarket } = useActiveSubMarket(marketCategories);
    const hasPublishedMarkets = useHasPublishedMarkets(activeSubMarket ? [activeSubMarket] : []);
    const publishedMarkets = usePublishedMarkets(activeSubMarket?.markets ?? []);

    const { data: eventsById = {} } = useEventsGroupedById(leagueId);

    const renderItem = useCallback(
        ({ item: market }: { item: Market }) => {
            const event = eventsById[market.event_id];
            return <PlayerProp hasEventInfo market={market} event={event} />;
        },
        [eventsById]
    );

    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);
    const { scrollableProps, setStickyRef, contentHeight, headerHeight } = useStickyTabList(
        tabKey,
        forceRefreshing,
        onRefresh
    );
    const { flatListProps } = useLeagueMarketList(
        hasPublishedMarkets,
        contentHeight,
        isTabBarVisible,
        marketHeaderSectionHeight
    );
    const { smoothScrollHeaderStyle, scrollY } = useStickyTabsAnimation();

    const listRef = useRef<FlatList<any> | null>();
    useScrollToTopOnChangeMarket({ activeSubMarket, scrollY, headerHeight, listRef });

    if (!activeSubMarket) {
        return null;
    }

    const top = gameSectionHeight + (isTabBarVisible ? TAB_HEIGHT : 0);
    const paddingTop = marketHeaderSectionHeight + (isTabBarVisible ? TAB_HEIGHT : 0);
    const flatListConfig = getPlayerPropFlatListConfig(activeSubMarket?.layout);

    return (
        <>
            <Animated.View
                style={[common.full, smoothScrollHeaderStyle, { top, backgroundColor: designSystem.colors.gray8 }]}
            >
                <SubMarketTabs
                    {...{
                        sportName,
                        paddingTop,
                        marketCategories,
                        activeSubMarket,
                        setActiveSubMarket,
                    }}
                />
            </Animated.View>
            <Animated.FlatList<Market>
                data={publishedMarkets}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                windowSize={5}
                {...flatListConfig}
                {...scrollableProps}
                // ! flatListProps should come after scrollableProps as it overrides contentContainerStyle
                {...flatListProps}
                // ! ref cannot be set through spread operator
                ref={ref => {
                    setStickyRef(ref);
                    listRef.current = ref;
                }}
            />
        </>
    );
};
