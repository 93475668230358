import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Path
            fill="#15D115"
            fillRule="evenodd"
            d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
            clipRule="evenodd"
        />
        <Path
            stroke="#000"
            strokeLinejoin="round"
            strokeWidth={1.75}
            d="m6.52 11.752 3.535 3.536a.5.5 0 0 0 .707 0L17.48 8.57"
        />
    </Svg>
);
export default SvgComponent;
