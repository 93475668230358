import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <Path
            fill="#6D707C"
            d="m13.536 7.56 1.178-1.18L10 1.668 5.286 6.38l1.179 1.178 2.702-2.702v8.476h1.667V4.857l2.702 2.702Z"
        />
        <Path fill="#6D707C" d="M3.75 11.667H2.083v6.666h15.834v-6.666H16.25v5H3.75v-5Z" />
    </Svg>
);
export default SvgComponent;
