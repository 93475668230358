import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';

import InfoIcon from '@/assets/icons/info-full-white';
import { PaymentLogo } from '@/components/PaymentLogo';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { ccTypeMap } from '@/feature/deposit/const';
import { RemovableCardType } from '@/types/removable-cards';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { styles } from '../../styles';

type BaseCardInfoProps = {
    card: RemovableCardType;
    showDistributionAmount?: boolean;
    isInteractable?: boolean;
};

export const BaseCardInfo = ({ card, showDistributionAmount = true, isInteractable = true }: BaseCardInfoProps) => {
    const { t } = useTranslation('manage_cards');
    return (
        <>
            <PaymentLogo source={card.payment_logos} style={!isInteractable && styles.opacity} />
            <Box flex={1} style={!isInteractable && styles.opacity}>
                <Text variant="bodyMedium">{card.card_brand ? ccTypeMap[card.card_brand] : ''}</Text>
                <Text color="gray2" variant="bodySmall" numberOfLines={1}>
                    {`•••• ${card.last4_digits}`}
                </Text>
                {showDistributionAmount ? (
                    <>
                        <SizedBox value={2} />
                        <Text color="gray2" variant="bodySmall">
                            {t('required_distribution', {
                                close_loop_remain: toLocaleCurrency(card.closed_loop_remain),
                            })}
                        </Text>
                    </>
                ) : null}
            </Box>
        </>
    );
};

export const InfoIconComponent = () => {
    const { t } = useTranslation('manage_cards');
    const { showInfoSheet } = useAlerts();
    const handlePressInfo = () => {
        showInfoSheet({
            title: t('removing_a_debit_card'),
            description: t('security_removable_cards_info'),
            buttonLabel: t('close'),
        });
    };
    return (
        <TouchableOpacity onPress={handlePressInfo}>
            <InfoIcon />
        </TouchableOpacity>
    );
};
