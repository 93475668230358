import { betKeys } from '@/feature/bets-sbk/hooks/use-bets';
import { EventDetails, MarketType, OptionStatus, OptionType, Player, Team } from '@/feature/event-details-sbk/types';
import { QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';

export type BetChannel = 'WEB' | 'MOBILE_WEB' | 'ANDROID' | 'IOS' | 'RETAIL' | 'OTHER';

export type BetTypeV1 =
    | 'SINGLE'
    | 'DOUBLE'
    | 'TREBLE'
    | 'FOURFOLD'
    | 'FIVEFOLD'
    | 'SIXFOLD'
    | 'SEVENFOLD'
    | 'EIGHTFOLD'
    | 'NINEFOLD'
    | 'TENFOLD';

export type BetSelectionType = 'WIN' | 'BETBUILDER';

export type BetSettings = {
    currency: string;
    allow_odd_changes: boolean;
    channel: BetChannel;
};

export type PlaceBetSelectionPart = {
    fixture_id: string;
    side_bet_id: string;
    winner_id: string;
    odds?: number;
    type: 'WIN';
    starting_price: boolean;
    best_odds_guaranteed: boolean;
    order: number;
};

export type PlaceBetSelection = {
    selection_type: BetSelectionType;
    parts: PlaceBetSelectionPart[];
};

export type PlaceBet = {
    is_fixed_odds: boolean;
    total_stake?: number;
    channel: BetChannel;
    bet_type: BetType;
    currency?: string;
    stake_per_line: number;
    allow_odd_changes: boolean;
    use_free_money: boolean;
    selections: PlaceBetSelection[];
};

export type Customer = {
    user_id: string;
    latitude?: number;
    longitude?: number;
    esp_bet_key?: string;
};

export type PlaceBetsRequest = {
    customer: Customer;
    bets: PlaceBet[];
};

export type BetPartStatus =
    | 'ACCEPTED'
    | 'WON'
    | 'LOST'
    | 'PUSHED'
    | 'HALFWONHALFPUSHED'
    | 'HALFLOSTHALFPUSHED'
    | 'REFUNDED'
    | 'CANCELLED';

export type BetPartType = 'WIN' | 'PLACE' | 'EACH_WAY';

export type BetSelectionPart = {
    odds_requested: number;
    odds_given: number;
    odds?: number;
    type: BetPartType;
    starting_price: number;
    best_odds_guaranteed: number;
    order: number;
    status?: BetPartStatus;
    competition: string;
    market_type: string;
    option_bet?: string;
    option: string;
    option_position: string;
    title: string;
    start_time: number;
    game_type: string;
    game_name: string;
    fixture_id: string;
    side_bet_id: string;
    winner_id: string;
    each_way_terms: number;
    number_of_places: number;
    is_match_live: boolean;
    is_non_runner: boolean;
};

export type BetType = 'SINGLE' | 'SCRIPT' | 'COMBO';
export type Result = 'PENDING' | 'WON' | 'LOST' | 'PUSHED' | 'CANCELLED' | 'CASHEDOUT';
export type BetStatus = 'SETTLED' | 'CANCELLED' | 'CONFIRMED' | 'UNCONFIRMED' | 'REJECTED' | 'REFUNDED' | 'CASHEDOUT';
export type EventStatus = 'NOTSTARTED' | 'FINISHED' | 'LIVE' | 'UNKNOWN';

export type BetSelection = {
    id: string;
    option: BetSelectionOption;
    market: BetSelectionMarket;
    result: Result;
    odds: number;
};

type BetSelectionMarket = {
    id: string;
    description: string;
    market_type: MarketType;
    is_micro_market: boolean;
    player?: Player;
    published: boolean;
};

export type BetSelectionOption = {
    id: string;
    description: string;
    option_type: OptionType;
    status: OptionStatus;
};

export type PERIOD_NUMBER = 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH' | 'FIFTH' | 'SIXTH' | 'SEVENTH' | 'EIGHT' | 'NINTH';
export type FOOTBALL_PERIOD_NUMBER = 'PERIOD_1' | 'PERIOD_2' | 'PERIOD_3' | 'PERIOD_4';
export type FULL_PERIOD = `${PERIOD_NUMBER}_PERIOD` | FOOTBALL_PERIOD_NUMBER;
export type PERIOD_TYPE = 'basketball' | 'baseball' | 'americanFootball' | 'hockey';

export type PeriodScore = {
    home_score: number;
    away_score: number;
    period_number: FULL_PERIOD;
    type: PERIOD_TYPE;
};

export type BetEvent = {
    id: string;
    status: EventStatus;
    start_time: number;
    sport: {
        id: string;
        name: string;
    };
    league: {
        id: string;
        name: string;
    };
    home_team: Team;
    away_team: Team;
    event_details: EventDetails;
    selections: BetSelection[];
};

export type Bet = {
    id: string;
    bet_producer_id: string;
    bet_type: BetType;
    status: BetStatus;
    result: Result;
    stake: number;
    amount_won: number;
    potential_payout: number;
    requested_odds: number;
    current_odds: number;
    created_at: number;
    is_betr_bucks: boolean;
    selection_count: number;
    settled_at: number;
    location: string;
    can_be_cashed_out: boolean;
    has_been_cashed_out: boolean;
    events: BetEvent[];
};

export type GetBetsResponse = {
    data: Bet[];
    meta: {
        current_page: number;
        from: number;
        to: number;
        total: number;
        last_page: number;
    };
};

export type GetBetDetailResponse = {
    data: Bet;
};

export type TabType = 'Open' | 'Settled' | 'Won' | 'Lost';

export type GetBetsQueryParams = {
    page?: number;
    tab: TabType;
};

type InfiniteQueryOptions<T, K extends (...args: any) => QueryKey, J> = UseInfiniteQueryOptions<
    T,
    unknown,
    J,
    T,
    ReturnType<K>
>;

export type UseInfiniteBetsQueryOptions<T> = InfiniteQueryOptions<GetBetsResponse, typeof betKeys.infiniteList, T>;

export type GetBetCashoutAmountResponse = {
    data: {
        [bet_id: string]: number | null;
    };
};

export type GetBetCashoutResponse = {
    data: Bet;
};

export type CashoutErrorCodes =
    | 'no_cashout_allowed'
    | 'cashout_not_enabled'
    | 'cashout_bet_not_found'
    | 'cashout_bet_not_eligible'
    | 'cashout_already_cashedout'
    | 'cashout_unavailable'
    | 'cashout_offer_change';

export class CashoutBetError extends Error {
    code: CashoutErrorCodes;
    cashable_amount?: number;

    constructor(message: string, code: CashoutErrorCodes, cashable_amount: number) {
        super(message);
        this.code = code;
        this.cashable_amount = cashable_amount;
    }
}
