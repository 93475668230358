import React, { useCallback } from 'react';
import { ListRenderItem } from 'react-native';
import { FlatList, RefreshControl } from 'react-native-gesture-handler';

import { useFocusEffect } from '@react-navigation/native';

import { BottomFooterRG } from '@/components/FooterRG';
import { LobbyProductSwitchButton } from '@/components/LobbyProductSwitchButton';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Box } from '@/components/lib/components';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { FeaturedBetsSection } from '@/feature/lobby-sbk/components/FeaturedBetsSection';
import { FeaturedEventsSection } from '@/feature/lobby-sbk/components/FeaturedEventsSection';
import { FeaturedLeagueSection } from '@/feature/lobby-sbk/components/FeaturedLeagueSection';
import { Launchpad } from '@/feature/lobby-sbk/components/Launchpad';
import { useFeaturedLeagues } from '@/feature/lobby-sbk/hooks/use-featured-leagues';
import { useRefreshLobbyScreen } from '@/feature/lobby-sbk/hooks/use-refresh-lobby-screen';
import { LobbyCarousel } from '@/feature/promohub/components/LobbyCarousel';
import { usePromotions } from '@/feature/promohub/hooks/use-promotions';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { LoadingScreen } from '@/screens/LoadingScreen';
import { designSystem } from '@/styles/styles';

const MOCK_FLATLIST_DATA = ['logo', 'search', 'content'];

export const BettingLobbyScreen = () => {
    useFocusEffect(
        useCallback(() => {
            GASbkEvents.viewLobby();
        }, [])
    );

    const leagues = useFeaturedLeagues();
    const { onRefresh, forceRefreshing, isInitialLoading } = useRefreshLobbyScreen();
    const { promotions } = usePromotions();
    const { featuredEventsEnabled, featuredBetsEnabled } = useJurisdictionStore(state => ({
        featuredEventsEnabled: state.jurisdictionSettings?.productConfig?.settings?.sbk_lobby_featured_events_enabled,
        featuredBetsEnabled: state.jurisdictionSettings?.productConfig?.settings?.sbk_lobby_featured_bets_enabled,
    }));

    const searchEnabled = false;

    const renderItem: ListRenderItem<string> = useCallback(
        ({ item }) => {
            switch (item) {
                case 'logo':
                    return (
                        <MaxWidthWrapper>
                            <LobbyProductSwitchButton />
                        </MaxWidthWrapper>
                    );
                case 'search':
                    return searchEnabled ? <SearchContainer /> : null;
                case 'content':
                    return (
                        <MaxWidthWrapper>
                            <Box gap="s32">
                                <Launchpad />
                                {promotions.length > 0 ? <LobbyCarousel /> : null}
                                {featuredEventsEnabled ? <FeaturedEventsSection /> : null}
                                {leagues.map(league => (
                                    <FeaturedLeagueSection key={league.id} featuredLeague={league} />
                                ))}
                                {featuredBetsEnabled ? <FeaturedBetsSection /> : null}
                            </Box>
                            <BottomFooterRG marginHorizontal="s16" footerMarginTop="s64" />
                        </MaxWidthWrapper>
                    );
                default:
                    return null;
            }
        },
        [featuredBetsEnabled, featuredEventsEnabled, leagues, promotions.length, searchEnabled]
    );

    return (
        <Screen>
            {isInitialLoading ? <LoadingScreen /> : null}
            <FlatList
                data={MOCK_FLATLIST_DATA}
                stickyHeaderIndices={STICKY_HEADER_INDICES}
                renderItem={renderItem}
                refreshControl={
                    <RefreshControl
                        enabled
                        onRefresh={onRefresh}
                        refreshing={forceRefreshing}
                        colors={[designSystem.colors.white]}
                        tintColor={designSystem.colors.white}
                    />
                }
            />
        </Screen>
    );
};

const STICKY_HEADER_INDICES = [1];

// Search Placeholder
const SearchContainer = () => {
    return (
        <MaxWidthWrapper>
            <Box pt={'s8'} px={'s16'} mb={'s16'} backgroundColor={'gray8'}>
                <SizedBox value={42} />
            </Box>
        </MaxWidthWrapper>
    );
};
