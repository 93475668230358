import { useEffect } from 'react';
import { BackHandler, Platform } from 'react-native';

/**
 * Custom hook used to disable Android back button action on specific screen
 */
export const usePreventAndroidBackNavigation = (enabled = true) => {
    useEffect(() => {
        if (enabled && Platform.OS === 'android') {
            // Android back button press will be overridden without triggering action.
            const backHandler = BackHandler.addEventListener('hardwareBackPress', () => true);
            // Remove BackHandler event listener
            return () => backHandler.remove();
        }
    }, [enabled]);
};
