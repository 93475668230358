import React from 'react';
import { useTranslation } from 'react-i18next';

import ClockIcon from '@/assets/icons/clock';
import { Badge } from '@/components/Badge';
import { LineSeparator } from '@/components/LineSeparator';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { format } from 'date-fns';

import { UserLimitHistory } from '../hooks/types';
import { getLimitHistoryDisplayAmount, getLimitHistoryTranslation } from '../utils/get-limit-history-texts';

type LimitsHistoryCardProps = {
    limitHistoryItem: UserLimitHistory;
};

const formatDateTime = (timestamp: number) => `${format(timestamp, 'MM/dd/yyyy')} AT ${format(timestamp, 'h:mm a')}`;

export const LimitsHistoryCard = ({ limitHistoryItem }: LimitsHistoryCardProps) => {
    const { t } = useTranslation(['rg', 'common', 'self_exclusion']);

    const timeToFormat = limitHistoryItem.limit_goes_live_at || limitHistoryItem.created_at;
    const formattedDateTime = formatDateTime(timeToFormat);
    const displayAmount = getLimitHistoryDisplayAmount(limitHistoryItem, t);

    return (
        <Box mb="s14">
            <Row alignItems="center">
                <Text color="gray2" variant="labelMedium" mr="s8">
                    {formattedDateTime}
                </Text>
                {limitHistoryItem.confirmed_status === 'PENDING' ? (
                    <Badge
                        label={t('pending')}
                        type="info"
                        textProps={{ textTransform: 'uppercase', color: 'gray8', lineHeight: 20 }}
                        leftIcon={<ClockIcon />}
                    />
                ) : null}
            </Row>
            <Row justifyContent="space-between" mb="s14">
                <Text>{getLimitHistoryTranslation(t, limitHistoryItem.limit_type, limitHistoryItem.duration!)}</Text>
                <Text>{displayAmount}</Text>
            </Row>
            <LineSeparator />
        </Box>
    );
};
