import { useEffect } from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { LeagueConfigsFragment } from '@/api/leagues/query.generated';
import { useLeaguesConfigs } from '@/hooks/use-fantasy-league-configs';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { League } from '@/types/api.generated';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

export type LeagueConfigStore = {
    leagueConfigs: LeagueConfigsFragment[];
    actions: {
        setLeagueConfigs: (value: LeagueConfigsFragment[]) => void;
    };
};

export const leagueConfigSelector = (league?: League) => (state: LeagueConfigStore) => {
    const leagueConfig = state.leagueConfigs.find(it => it.league === league);
    if (!league || !leagueConfig) {
        return { leagueColor: undefined, leagueIcon: undefined };
    }
    return { leagueColor: leagueConfig.playerProfileBackgroundColor, leagueIcon: leagueConfig.icon };
};

export const useLeagueConfigsStore = createWithEqualityFn<LeagueConfigStore>()(
    persist(
        (set, _get) => ({
            leagueConfigs: [],
            actions: {
                setLeagueConfigs: value => {
                    set({ leagueConfigs: value });
                },
            },
        }),
        {
            storage: createJSONStorage(() => AsyncStorage),
            name: 'league-configs',
            version: 1, // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, ...rest }: LeagueConfigStore) => rest,
        }
    ),
    Object.is
);

/**
 * Hook to fetch and store league configs in the zustand store
 */
export const useUpdatePickemLeagueConfigs = () => {
    const product = useJurisdictionStore(store => store.product) ?? '';
    const [{ data }] = useLeaguesConfigs({ pause: product !== Product.Pickem });
    const setLeagueConfigs = useLeagueConfigsStore(state => state.actions.setLeagueConfigs);

    useEffect(() => {
        if (data?.getLeagueConfigs) {
            setLeagueConfigs(data?.getLeagueConfigs);
        }
    }, [data?.getLeagueConfigs, setLeagueConfigs]);
};
