import { URLS } from '@/data/config';
import { createActiveWalletJurisdictionHeaders, useActiveWallet } from '@/hooks/use-active-wallet';
import { Product } from '@/hooks/use-jurisdiction';
import { useInfiniteQuery } from '@tanstack/react-query';

import {
    QueryParams,
    TransactionActivityResponse,
    TransactionActivityResponseWithMeta,
    TransactionTypes,
    TransactionTypesType,
} from './types';

export const getTxnActivity = async (queryParams: QueryParams, pageParam: number) => {
    const params = new URLSearchParams({
        ...(queryParams.dateTo && { date_to: queryParams.dateTo }),
        ...(queryParams.dateFrom && { date_from: queryParams.dateFrom }),
        ...(queryParams.type && queryParams.type !== TransactionTypes.ALL_TYPES && { type: queryParams.type }),
        page: String(pageParam),
    }).toString();

    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/transaction-activity?${params}`, {
        method: 'GET',
        headers: createActiveWalletJurisdictionHeaders(),
    });
    const data = await response.json();

    if (!response.ok) {
        throw data;
    }
    return data;
};

const infiniteTxnActivityKeys = {
    all: ['transaction-activity'] as const,
    byFilter: (activeWallet: Product, type?: TransactionTypesType, dateFrom?: string, dateTo?: string) =>
        [...infiniteTxnActivityKeys.all, activeWallet, type, dateFrom, dateTo] as const,
};

export function useInfiniteTransactionActivity(queryParams: QueryParams) {
    const { activeWalletProduct } = useActiveWallet();

    return useInfiniteQuery<TransactionActivityResponseWithMeta, unknown, TransactionActivityResponse>(
        infiniteTxnActivityKeys.byFilter(
            activeWalletProduct,
            queryParams.type,
            queryParams.dateFrom,
            queryParams.dateTo
        ),
        ({ pageParam = 1 }) => getTxnActivity(queryParams, pageParam),
        {
            getNextPageParam: res => {
                const { current_page, last_page } = res.data.transactions;
                return last_page > current_page + 1 ? current_page + 1 : undefined;
            },
            select: data => {
                const validTxn = data?.pages.map(page => page.data).filter(Boolean);
                return {
                    pageParams: data.pageParams,
                    pages: validTxn?.flatMap(page => page) || [],
                };
            },
            staleTime: 0,
            cacheTime: 0,
        }
    );
}
