import { DateData, MarkedDates } from 'react-native-calendars/src/types';

import { designSystem } from '@/styles/styles';

const getDatesInRange = (startDate: Date, endDate: Date): string[] => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        dates.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
};

/**
 * Generate a date range object for marking dates on a calendar.
 *
 * @param selectedDates - Array of selected date objects.
 * @returns An object where keys are date strings and values are `MarkedDate` objects.
 */
export const generateDateRangeObject = (selectedDates: DateData[]): MarkedDates => {
    const dateRangeObject: MarkedDates = {};

    // Sort selectedDates by timestamp to ensure chronological order
    selectedDates.sort((a, b) => a.timestamp - b.timestamp);

    // Handle cases based on the number of selected dates
    switch (selectedDates.length) {
        case 1: // Single date selection
            const singleDate = selectedDates[0].dateString;
            dateRangeObject[singleDate] = {
                startingDay: true,
                endingDay: true,
                color: designSystem.colors.purple,
                textColor: designSystem.colors.gray1,
            };
            break;
        case 2: // Range selection
            const [startDate, endDate] = selectedDates.map(date => date.dateString);
            const allDatesInRange = getDatesInRange(new Date(startDate), new Date(endDate));

            allDatesInRange.forEach(date => {
                dateRangeObject[date] = {
                    color: designSystem.colors.purple,
                    startingDay: date === startDate,
                    endingDay: date === endDate,
                    textColor: designSystem.colors.gray1,
                };
            });
            break;
        default:
            break;
    }

    return dateRangeObject;
};
