import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useResumeEffect } from '@/hooks/use-resume';
import { useWalletStore } from '@/hooks/use-wallet';
import { read, save } from '@/utils/async-storage';

import { DID_PROMPT_BIOMETRICS_ENABLE } from './use-prompt-biometric-guard';

const productNamesMap = {
    [Product.Pickem]: 'Picks',
    [Product.Sportsbook]: 'Sportsbook',
    [Product.None]: '',
};

const DID_PROMPT_FIRST_DEPOSIT = 'didPromptFirstDeposit';

/**
 * Hook used to show "Make your first Deposit" modal the first time
 * It waits for the `didPromptBiometricsEnable` flag to be set
 */
export const useMakeFirstDeposit = () => {
    const {
        walletLoading,
        // balance for current product
        balance,
        actions: {},
    } = useWalletStore();
    const activeProduct = useJurisdictionStore(state => state.product);
    const { t } = useTranslation('transactions');
    const { showInfoSheet } = useAlerts();

    const { navigate } = useNavigation();

    const handleFirstDepositModal = useCallback(() => {
        const checkFirstDeposit = async () => {
            const didPromptBiometricsEnable = await read(DID_PROMPT_BIOMETRICS_ENABLE);
            // flag for the modal per product
            const didPromptFirstDeposit = await read(`${DID_PROMPT_FIRST_DEPOSIT}-${activeProduct}`);

            if (
                // make sure we have the wallet loaded
                walletLoading ||
                // make sure we have active product
                activeProduct === Product.None ||
                // show the modal only after the prompt for biometric guard
                !didPromptBiometricsEnable ||
                // show the modal only once
                didPromptFirstDeposit ||
                // show the modal only if the balance is 0 (this should be the first deposit)
                balance > 0
            ) {
                return;
            }

            showInfoSheet({
                title: t('first_deposit_modal.title'),
                description: t('first_deposit_modal.description'),
                buttonLabel: t('deposit_to_product', { product: productNamesMap[activeProduct] }),
                secondaryLabel: t('first_deposit_modal.do_it_later'),
                handlePress: () => {
                    navigate('DepositScreen', {
                        selectedAmount: '',
                        shouldRedirectToLobby: true,
                        disableTransition: true,
                    });
                },
            });

            save(`${DID_PROMPT_FIRST_DEPOSIT}-${activeProduct}`, 'true');
        };

        // delay execution so that the modal animation doesn't seem that abrupt
        const timeout = setTimeout(() => {
            checkFirstDeposit();
        }, 100);

        return () => clearInterval(timeout);
    }, [activeProduct, balance, navigate, showInfoSheet, t, walletLoading]);

    useResumeEffect(handleFirstDepositModal);
};
