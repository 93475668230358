import React, { ReactNode, useMemo } from 'react';

import { EventWithTeams } from '@/api/events/types/types';
import { Text } from '@/components/TextComponent';
import { ScoreBoardAccordion } from '@/components/scoreboard/ScoreBoardAccordion';
import { getEventTitle, getFinalScoreColumns, getScoreboard } from '@/feature/bets-sbk/utils/utils';
import { useSubscribeEventMatchUpdate } from '@/feature/event-details-sbk/hooks/use-subscribe-event-match-update';
import { Event, EventDetails } from '@/feature/event-details-sbk/types';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';
import { useFormatPeriod } from '@/hooks/use-format-period';
import { useGameTrackerConfig } from '@/hooks/use-game-tracker-config';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useEventDetailsCache, useEventStatus } from '@/hooks/use-sbk-event-details-cache';
import { designSystem } from '@/styles/styles';
import { formatSportPeriodDetails } from '@/utils/format-sport-period-details/format-sport-period-details';
import { useShallow } from 'zustand/react/shallow';

export const PickemScoreBoardAccordion = ({
    event,
    eventInfo,
    eventDetails,
    showGameTrackerTooltip,
}: {
    event: Event;
    eventInfo: EventWithTeams;
    eventDetails: EventDetails;
    showGameTrackerTooltip?: boolean;
}) => {
    const breakdown = useMemo(() => getScoreboard(event.sport, eventDetails), [event.sport, eventDetails]);
    const eventStatus = useEventStatus(event.id) ?? event.status;
    const productFlags = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);
    const defaultExpandedScoreboard = productFlags?.default_opened_scoreboard;

    const [away, home] = eventInfo.teams;

    const updatedEvent = {
        ...event,
        status: eventStatus,
        event_details: eventDetails,
    };

    const finalScoreColumns = useMemo(
        () => (event && eventDetails ? getFinalScoreColumns(eventDetails, event.sport) : []),
        [event, eventDetails]
    );

    const matchDetails = formatSportPeriodDetails(event, event.event_details?.sport_specific);

    const { periodName, gameClock, sportSpecific } = useEventDetailsCache(
        useShallow(state => {
            return {
                periodName: state.eventDetails[event.id]?.period_name ?? event.event_details?.period_name,
                gameClock: state.eventDetails[event.id]?.game_clock ?? event.event_details?.game_clock,
                sportSpecific: state.eventDetails[event.id]?.sport_specific ?? event.event_details?.sport_specific,
            };
        })
    );

    const periodLabel = useFormatPeriod({ event, periodName, gameClock, sportSpecific });
    const gameTrackerConfig = useGameTrackerConfig(event.league, event.event_details);
    const isLive = eventStatus === 'LIVE';

    return (
        <ScoreBoardAccordion
            breakdown={breakdown}
            eventTitle={getEventTitleWithGameClock(updatedEvent, periodLabel)}
            awayTeamIcon={away.icon}
            homeTeamIcon={home.icon}
            backgroundColor={'gray8'}
            awayTeamShortCode={event.away_team.short_code}
            homeTeamShortCode={event.home_team.short_code}
            awayTeamColor={away.color}
            homeTeamColor={home.color}
            finalScoreColumns={finalScoreColumns}
            isLive={isLive}
            gameTrackerConfig={gameTrackerConfig}
            matchDetails={matchDetails}
            showGameTrackerTooltip={showGameTrackerTooltip}
            animateExplicitInitialExpansion={Boolean(defaultExpandedScoreboard)}
            accordionStyle={{ backgroundColor: designSystem.colors.gray8 }}
            screenName={'Picks_EntryScreen_GameTrackerTooltip'}
        />
    );
};

const getEventTitleWithGameClock = (event: Event, periodLabel: string | undefined): ReactNode | string => {
    const teamsTitle = getEventTitle(event);

    if (event.status === 'LIVE') {
        return (
            <Text>
                {`${teamsTitle} • `}
                <Text color={'red'}>{periodLabel}</Text>
            </Text>
        );
    }
    return teamsTitle;
};

export const PickemScoreBoardWithSubscription = ({
    event,
    eventInfo,
    refetch,
    eventDetails,
    showGameTrackerTooltip,
}: {
    event: Event;
    eventInfo: EventWithTeams;
    refetch: () => Promise<unknown>;
    eventDetails: EventDetails;
    showGameTrackerTooltip?: boolean;
}) => {
    useSubscribeEventMatchUpdate(event.id);
    useFetchOnResume(refetch);

    return (
        <PickemScoreBoardAccordion
            eventInfo={eventInfo}
            event={event}
            eventDetails={eventDetails}
            showGameTrackerTooltip={showGameTrackerTooltip}
        />
    );
};
