import { createJurisdictionHeaders } from '@/data';
import { URLS } from '@/data/config';
import { user } from '@/hooks/use-user';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { userLimitsKeys } from './query-keys';
import { UserLimitConfirmation, UserLimitType } from './types';

type UseUserLimitsConfirmationsQueryOptions<TData> = UseQueryOptions<
    UserLimitConfirmation[],
    unknown,
    TData,
    ReturnType<typeof userLimitsKeys.confirmations>
>;

/**
 * Fetches user limits that can be confirmed from /auth/user-limits/confirmations endpoint
 * @return {Promise<UserLimitConfirmation[]>} - confirmable user limits array
 */
const getUserLimitConfirmations = async (): Promise<UserLimitConfirmation[]> => {
    const resp = await (
        await fetch(`${URLS.CHAMELON_API_URL}/auth/user-limits/confirmations`, {
            method: 'GET',
            headers: createJurisdictionHeaders(),
        })
    ).json();
    return resp.data;
};

/**
 * Custom hook for getting confirmable user limits
 * @param {Object} options - react-query options
 * @return {Object} - react-query query object
 */
export const useUserLimitConfirmations = <TData = UserLimitConfirmation[]>(
    options?: UseUserLimitsConfirmationsQueryOptions<TData>
) =>
    useQuery(userLimitsKeys.confirmations(), getUserLimitConfirmations, {
        enabled: !user.sessionHasExpired(),
        ...options,
    });

/**
 * Selector for getting confirmable user limits by type
 * @param {string} type - limit type
 */
export const confirmationLimitsSelector = (type: UserLimitType) => (limits: UserLimitConfirmation[]) =>
    limits.filter(limit => limit.type === type);

/**
 * Selector for getting the count of confirmable limits
 * @param type
 */
export const hasConfirmationLimitsSelector = (type: UserLimitType) => (limits: UserLimitConfirmation[]) =>
    confirmationLimitsSelector(type)(limits).length > 0;
