import React from 'react';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import CustomWebView from '@/components/CustomWebView';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { WebViewNavigation } from '@/components/Webview';
import { RootStackParamList } from '@/navigation/types';
import { openExternalUrlAndroid } from '@/utils/webview';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'ModalWebView'>;

export const ModalWebViewScreen = ({
    route: {
        params: { title, uri },
    },
}: ScreenProps) => {
    return (
        <Screen>
            <ScreenNavBar title={title} closeIconMode="close" />
            <CustomWebView
                uri={uri}
                onNavigationStateChange={(event: WebViewNavigation) => openExternalUrlAndroid(event?.url)}
                openNewTabOnWeb={true}
            />
        </Screen>
    );
};
