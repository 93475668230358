import { useMemo } from 'react';

import { useAllLeaguesUpcomingEventsQuery } from '@/api/events/query.generated';
import { useGetOrderedLeagues } from '@/hooks/use-fantasy-league-configs';

export const useAvailableLeagues = () => {
    const [{ data: leaguesEvents }] = useAllLeaguesUpcomingEventsQuery({ requestPolicy: 'cache-first' });
    const { leagues: leaguesFragments } = useGetOrderedLeagues();
    return useMemo(() => {
        return leaguesFragments.filter(league => {
            // filter out leagues that don't have upcoming events
            return leaguesEvents?.getUpcomingEventsV2.find(event => event.league === league.league);
        });
    }, [leaguesEvents?.getUpcomingEventsV2, leaguesFragments]);
};
