import { URLS } from '@/data/config';
import { createActiveWalletJurisdictionHeaders } from '@/hooks/use-active-wallet';
import { isWeb } from '@/utils/constants-platform-specific';
import { isErrorResponse } from '@/utils/errors/response-handler';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';

import { PaymentMethodTypes } from '../const';
import { PaymentMethod } from './use-deposit-payment-methods';

const LOG_TAG = '[DEPOSIT_URL_FETCH]';

type DepositParams = {
    currency: string;
    amount: string;
    type: PaymentMethod['type'];
    payment_token?: string;
    payment_type_id?: string;
};

/**
 * Sends a request to deposit API endpoint.
 *
 * @async
 * @param {Object} payload - The data payload for the API call.
 * @param {number} payload.selectedAmount - The selected amount for deposit.
 * @param {PaymentMethod} payload.selectedPaymentMethodType - The selected payment method type.
 * @param {string} payload.currency - The currency of the amount.
 * @returns {Promise} Promise object represents the data returned from the API.
 */
const getPaymentUrl = async (payload: DepositParams): Promise<DepositResponseData> => {
    const { type, amount, currency, payment_token, payment_type_id } = payload;

    const params = new URLSearchParams({
        type,
        amount,
        currency,
        ...(payment_token && { payment_token }),
        ...(payment_type_id && { payment_type_id }),
    });

    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/deposit`, {
        method: 'POST',
        headers: {
            ...createActiveWalletJurisdictionHeaders(),
            'Content-Type': 'application/x-www-form-urlencoded',
            'DYNAMIC-REDIRECT': String(
                type === PaymentMethodTypes.PaysafeMazooma || type === PaymentMethodTypes.PaysafePayByBank
            ), // this will set the return links for paysafe to universal links instead of static urls
            'X-Agent-Source': isWeb ? 'web' : 'mobile',
        },
        body: params.toString(),
    });

    const jsonResponse = await response.json();

    if (!response.ok) {
        throw jsonResponse;
    }

    return jsonResponse.data;
};

type DepositResponseData = {
    extra: {
        payment_handle_id: string;
        user_wallet_id: string;
    };
    payment_url: string;
    status: string;
    token: string;
};

export type DepositErrorResponse = Error & {
    error_codes: string[];
    fields: string[];
    errors: {
        error_code: string;
    };
    message: string;
};

/**
 * Custom hook to perform a mutation for deposit operation.
 * In case of an error, it logs the error details.
 *
 * @returns The mutation object from `react-query` with methods to mutate, check the status of the mutation, etc.
 */
export const useDeposit = () => {
    return useMutation<DepositResponseData, DepositErrorResponse, DepositParams>(['getPaymentUrl'], getPaymentUrl, {
        onError: (err, variables) => {
            if (isErrorResponse(err)) {
                logger.warn(LOG_TAG, { ...err, ...variables });
            } else {
                const errorMessage = `An unknown error occurred while fetching deposit URL: ${String(err)}`;
                logger.warn(LOG_TAG, { message: errorMessage });
            }
        },
    });
};
