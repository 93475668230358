import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScrollableScreen } from '@/components/ScrollableScreen';
import { Text } from '@/components/TextComponent';

/* TODO: https://pitchtech.atlassian.net/browse/CSP-534 */
export const UpdateExpiredCard = () => {
    const { t } = useTranslation('manage_cards');

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('update_expired_card'), closeIconMode: 'back' }}
            scrollViewProps={{
                contentContainerStyle: {
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                },
                style: {
                    paddingHorizontal: 0,
                },
            }}
        >
            <Text>{t('update_expired_card')}</Text>
        </ScrollableScreen>
    );
};
