import React, { memo, useEffect, useState } from 'react';

import { common, designSystem } from '@/styles/styles';

import './playerImage.css';
import { PlayerImageCanvasProps } from './types';
import { addResizeParamToURL, sizes, teamColorCircleOffsets } from './utils';

export const PlayerImageCanvas = memo(
    ({ teamColor, source, bgColor, onLoad, hideImage, variant }: PlayerImageCanvasProps) => {
        const containerSize = sizes[variant];

        const teamColorCircleOffset = teamColorCircleOffsets[variant];

        const teamColorCircleRadius = (containerSize.width - 8) / 2;

        const [imageSource, setImageSource] = useState<string>();

        useEffect(() => {
            let url: string | null = null;

            const fetchResizedImage = async () => {
                if (!source) {
                    return;
                }

                try {
                    const response = await fetch(addResizeParamToURL(source));

                    if (response.ok) {
                        const blob = await response.blob();
                        url = URL.createObjectURL(blob);
                        setImageSource(url);
                        onLoad();
                    } else {
                        setImageSource(source);
                    }
                } catch (e) {
                    console.error('Failed to fetch resized image', e);
                    setImageSource(source);
                }
            };

            fetchResizedImage();

            return () => {
                // Cleanup function to revoke the object URL so we do not have memory issues
                if (url !== null) {
                    URL.revokeObjectURL(url);
                    url = null;
                }
            };
        }, [source, onLoad]);

        return (
            <>
                <svg
                    width={containerSize.width}
                    height={containerSize.height}
                    fill="none"
                    className="playerTeamColorBlur"
                >
                    <g>
                        <circle
                            cx={containerSize.width / 2}
                            cy={containerSize.height - teamColorCircleOffset}
                            r={teamColorCircleRadius}
                            fill={teamColor || designSystem.colors[bgColor]}
                        />
                    </g>
                </svg>
                {!hideImage && imageSource ? (
                    // we cannot apply position absolute directly on img (ts error)
                    <div style={common.absolute}>
                        <img
                            alt=""
                            src={imageSource}
                            className="playerImage"
                            style={{ width: containerSize.width, height: containerSize.width }}
                        />
                    </div>
                ) : null}
            </>
        );
    }
);
