import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = ({ width = 24, height = 24, color = designSystem.colors.white, ...props }) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" {...props}>
        <Path
            fill={color}
            fillRule="evenodd"
            d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm6.167-14.396a.5.5 0 0 1 0 .707l-6.717 6.717a1 1 0 0 1-1.414 0l-3.89-3.889a.5.5 0 0 1 0-.707l.708-.707a.5.5 0 0 1 .707 0l3.182 3.182 6.01-6.01a.5.5 0 0 1 .707 0l.707.707Z"
            clipRule="evenodd"
        />
    </Svg>
);

export default SvgComponent;
