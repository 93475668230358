export const RUDDERSTACK_EVENTS = Object.freeze({
    submit: (form: string): string => `Submitted ${form}`,
    click: (element: string): string => `Clicked ${element}`,
    trigger: (activity: string): string => `Triggered ${activity}`,
    custom: (customEvent: string): string => customEvent,
});

export const EVENT_TYPES = Object.freeze({
    FTD: 'FTD',
    ENTRY: 'ENTRY',
    KYC: 'KYC',
    RG: 'RG',
    REGISTRATION: 'REGISTRATION',
});

export enum AnalyticsEvent {
    CLICK = 'click',
    VIEW = 'view',
    EXPAND_PLAYER_PROPS = 'expand_player_props',
    ADD_1ST_PICK = 'add_1st_pick',
    SELECT_ENTRY_CARD = 'select_entry_card',
    INPUT_ENTRY_AMOUNT = 'input_entry_amount',
    ALWAYS_ACCEPT_PROJECTION_CHANGES = 'always_accept_projection_changes',
    ACCEPT_PROJECTION_CHANGES = 'accept_projection_changes',
    REJECT_PROJECTION_CHANGES = 'reject_projection_changes',
    NEW_PICK = 'new_pick',
    REMOVE_PICK = 'remove_pick',
    REUSE_LINEUP = 'reuse_lineup',
    REUSE_LINEUP_ENTRY_SCREEN = 'reuse_lineup_view_entry_screen',
    P2P_MAX_ENTRY_EXCEEDED = 'p2p_max_entry_exceeded',
    //errors
    PRE_SUBMIT_ERRORS = 'pre_submit_errors',
    //restricted combos
    RESTRICTED_COMBINATION = 'restricted_combination',
    RESTRICTED_COMBINATION_KEEP_PICK = 'restricted_combination_keep_pick',
    RESTRICTED_COMBINATION_REPLACE_PICK = 'restricted_combination_replace_pick',
    // share entry
    SHARE_ENTRY = 'share_entry',
    SHARE_OPTION = 'share_option',
    //prebuilt lineups
    VIEW_FEATURED_LINEUPS = 'view_featured_lineups',
    ADD_PREBUILT_LINEUP = 'add_prebuilt_lineup',
    SHOW_REPLACE_LINEUP_MODAL = 'show_replace_lineup_modal',
    REPLACE_LINEUP_BUTTON = 'replace_lineup_button',
    KEEP_LINEUP_BUTTON = 'keep_lineup_button',
    // deposit
    WALLET_DEPOSIT = 'wallet_depositCTA',
    DEPOSIT_QUICKBUTTON = 'deposit_amt_quickbutton',
    DEPOSIT_ABORT = 'deposit_amt_abort',
    DEPOSIT_SUBMIT = 'deposit_amt_submit',
    DEPOSIT_SUBMIT_EDIT = 'deposit_amt_edit_submit',
    DEPOSIT_METHOD_ABORT = 'deposit_method_abort',
    DEPOSIT_METHOD_ADD_DEBIT = 'deposit_method_add_debit',
    DEPOSIT_METHOD_ADD_IBT = 'deposit_method_add_IBT',
    DEPOSIT_METHOD_ADD_PAY_BY_BANK = 'deposit_method_add_pay_by_bank',
    DEPOSIT_DEBITJS_ABORT = 'deposit_debitJS_abort',
    DEPOSIT_METHOD_PAYPAL = 'deposit_method_paypal',
    DEPOSIT_METHOD_SAVED_DEBIT = 'deposit_method_saved_debit',
    DEPOSIT_METHOD_SAVED_IBT = 'deposit_method_saved_IBT',
    DEPOSIT_SUCCESS = 'deposit_success',
    DEPOSIT_FAILURE_SUPPORT_REQUEST = 'deposit_failure_supportrequest',
    DEPOSIT_FAILURE_RETRY = 'deposit_failure_retry',
    DEPOSIT_FAILURE_ABORT = 'deposit_failure_abort',
    DEPOSIT_SALVAGE_DISMISS = 'deposit_salvage_dismiss',
    DEPOSIT_SALVAGE_ABORT = 'deposit_salvage_abort',
    DEPOSIT_SALVAGE = 'deposit_salvage',
    DEPOSIT_DEBITJS_SUBMIT = 'deposit_debitJS_submit',
    // create entry
    PLACE_ENTRY = 'place_entry',
    PLACE_ENTRY_P2P = 'place_entry_p2p',
    // search players
    SEARCH_OPEN = 'search_open',
    SEARCH_LEAGUE_SELECTED = 'search_league_selected',
    SEARCH_PLAYER_SELECTED = 'search_player_selected',

    // authentication
    CREATION_EMAIL_ABORTED = 'creation_email_aborted',
    CREATION_PHONE_NUMBER_ABORTED = 'creation_phone_number_aborted',
    CREATION_MFA_ABORTED = 'creation_mfa_aborted',
    LOGIN_ABORTED = 'login_aborted',
    LOGIN_MFA_ABORTED = 'login_mfa_aborted',
    LOGIN_SUSPENDED_ABORTED = 'login_suspended_aborted',
    LOGIN_SUSPENDED_CONTACT_SUPPORT = 'login_suspended_contact_support',
    LOGIN_SUSPENDED_DISMISS = 'login_suspended_dismiss',
    // kyc
    SSN4_ABORT = 'ssn4_abort',
    SSN4_SUBMIT = 'ssn4_submit',
    SSN9_SUBMIT = 'ssn9_submit',
    SSN9_ABORT = 'ssn9_abort',
    TCS_ABORT = 'tcs_abort',
    TCS_SUBMIT = 'tcs_submit',
    ATTEMPT_FAILED_ABORT = 'attempt_failed_abort',
    ATTEMPT_FAILED_RETRY = 'attempt_failed_retry',
    ATTEMPT_FAILED_SUPPORT_REQUEST = 'attempt_failed_supportrequest',
    IDPV_ABORT = 'idpv_abort',
    IDPV_LAUNCH = 'idpv_launch',
    IDPV_CANCEL_REQUEST = 'idpv_cancel_request',
    IDPV_CANCEL_CONFIRM = 'idpv_cancel_confirm',
    IDPV_CANCEL_ABORTED = 'idpv_cancel_aborted',
    IDPV_FAILED_ABORT = 'idpv_failed_abort',
    IDPV_FAILED_DISMISS = 'idpv_failed_dismiss',
    IDPV_FAILED_SUPPORT_REQUEST = 'idpv_failed_supportrequest',
    REVIEW_ABORT = 'review_abort',
    REVIEW_EDIT = 'review_edit',
    KYC_SUCCESS_LIMIT = 'kyc_success_limit',
    KYC_SUCCESS_DEPOSIT = 'kyc_success_deposit',
    KYC_SUCCESS_LOBBY = 'kyc_success_lobby',

    // SBK Place Bet
    VIEW_LOBBY = 'view_lobby',
    CLICK_LEAGUE = 'click_league',
    CLICK_EVENT = 'click_event',
    ADD_SELECTION = 'add_selection',
    CLICK_VIEW_BETS = 'click_view_bets',
    CLICK_PLACE_BET = 'click_place_bet',
    VIEW_BET_CONFIRMATION = 'view_bet_confirmation',
    ADD_PREBUILT_BETS = 'add_prebuilt_bets',

    // SBK Bet Slip
    CLICK_BETSLIP_CLOSE_BUTTON = 'click_betslip_close_button',
    TOGGLE_BET_TYPE_TAB = 'toggle_bet_type_tab',
    TOGGLE_SELECTION = 'toggle_selection',
    CLICK_BALANCE_DROPDOWN = 'click_balance_dropdown',
    CLICK_BET_SUMMARY = 'click_bet_summary',
    CLICK_WAGER_INPUT = 'click_wager_input',
    CLICK_PAYOUT_SECTION = 'click_payout_section',
    TOGGLE_CURRENCY = 'toggle_currency',
    CLEAR_BET_SLIP = 'clear_bet_slip',
    DELETE_INDIVIDUAL_SELECTION = 'delete_individual_selection',
    REVIEW_MULTIPLIER_CHANGES = 'review_multiplier_changes',
    BETSLIP_INTERACTION_SUMMARY = 'betslip_interaction_summary',

    // account
    DETAILS_EDIT_EMAIL = 'details_edit_email',
    DETAILS_EDIT_PHONE = 'details_edit_phone',
    DETAILS_EDIT_PASSWORD = 'details_edit_password',
    DETAILS_EDIT_BIOMETRICS_TURNON = 'details_edit_biometrics_turnon',
    DETAILS_EDIT_BIOMETRICS_TURNOFF = 'details_edit_biometrics_turnoff',
    DETAILS_CLOSEACCT = 'details_closeacct',
    DETAILS_CLOSEACCT_CONFIRM = 'details_closeacct_confirm',
    DETAILS_CLOSEACCT_CANCEL = 'details_closeacct_cancel',
    STATE_CHANGE = 'state_change',
    ACCOUNT_SETTINGS = 'account_settings',
    WALLET_WITHDRAW = 'wallet_withdrawCTA',
    WITHDRAWAL_UNAVAILABLE_CLOSE = 'withdrawal_unavailable_close',
    WITHDRAW_SUBMIT = 'withdraw_submit',
    WITHDRAW_CHANGE_METHOD = 'withdraw_change_method',
    SELECT_WITHDRAW_METHOD = 'select_withdraw_method',
    WITHDRAW_VIEW_TRANSACTION_HISTORY = 'withdraw_view_transaction_history',
    WITHDRAW_SUCCESS_DONE = 'withdraw_success_done',
    WITHDRAW_FAIL_LOBBY = 'withdraw_fail_lobby',
    WITHDRAW_FAIL_RETRY = 'withdraw_fail_retry',
    CONTACT_SUPPORT = 'contact_support',
    WITHDRAW_SUCCESS_ABORT = 'withdraw_success_abort',

    //gaming limits
    DEPOSIT_LIMITS = 'deposit_limit',
    PLAY_LIMITS = 'play_limit',
    MAX_SINGLE_PLAY_LIMITS = 'single_max',
    LIMITS_HISTORY = 'limits_history',
    STATE_LIMITS = 'state_limits',
    REALITY_CHECK = 'reality_check',
    TIMEOUT_LIMITS = 'timeout',
    SELF_EXCLUSION = 'exclusion',
    ADD_DEPOSIT_LIMIT = 'add_deposit_limit',
    CONFIRM_ADD_DEPOSIT_LIMIT = 'confirm_add_deposit_limit',
    EDIT_DEPOSIT_LIMIT = 'edit_deposit_limit',
    REMOVE_DEPOSIT_LIMIT = 'remove_deposit_limit',
    UPDATE_DEPOSIT_LIMIT = 'update_deposit_limit',
    CONFIRM_REMOVE_DEPOSIT_LIMIT = 'confirm_remove_deposit_limit',
    CONFIRM_UPDATE_DEPOSIT_LIMIT = 'confirm_update_deposit_limit',
    CANCEL_REMOVE_DEPOSIT_LIMIT = 'cancel_remove_deposit_limit',
    CANCEL_UPDATE_DEPOSIT_LIMIT = 'cancel_update_deposit_limit',
    RESPONSIBLE_GAMING = 'responsible_gaming_resources',
    ADD_PLAY_LIMIT = 'add_play_limit',
    EDIT_PLAY_LIMIT = 'edit_play_limit',
    CONFIRM_ADD_PLAY_LIMIT = 'confirm_add_play_limit',
    CONFIRM_UPDATE_PLAY_LIMIT = 'confirm_update_play_limit',
    CONFIRM_REMOVE_PLAY_LIMIT = 'confirm_remove_play_limit',
    UPDATE_PLAY_LIMIT = 'update_play_limit',
    REMOVE_PLAY_LIMIT = 'remove_play_limit',
    CANCEL_UPDATE_PLAY_LIMIT = 'cancel_update_play_limit',
    CANCEL_REMOVE_PLAY_LIMIT = 'cancel_remove_play_limit',
    ADD_MAX_SINGLE_PLAY_LIMIT = 'add_max_single_play_limit',
    EDIT_MAX_SINGLE_PLAY_LIMIT = 'edit_max_single_play_limit',
    CONFIRM_ADD_MAX_SINGLE_PLAY_LIMIT = 'add_max_single_play_limit',
    CONFIRM_UPDATE_MAX_SINGLE_PLAY_LIMIT = 'update_max_single_play_limit',
    CONFIRM_REMOVE_MAX_SINGLE_PLAY_LIMIT = 'remove_max_single_play_limit',
    UPDATE_MAX_SINGLE_PLAY_LIMIT = 'update_max_single_play_limit',
    REMOVE_MAX_SINGLE_PLAY_LIMIT = 'remove_max_single_play_limit',
    CANCEL_UPDATE_MAX_SINGLE_PLAY_LIMIT = 'cancel_update_max_single_play_limit',
    ACCEPT_PAYMENT_LIMIT = 'accept_payment_limit',
    CANCEL_PAYMENT_LIMIT = 'cancel_payment_limit',
    ADD_TIMEOUT = 'add_timeout',
    SET_TIMEOUT_PERIOD = 'set_timeout_period',
    CONFIRM_ADD_TIMEOUT = 'confirm_add_timeout',
    CANCEL_ADD_TIMEOUT = 'cancel_add_timeout',
    GAMING_LIMITS_ABORT = 'gaming_limits_abort',
    LIMITS_ABORT = 'limits_abort',
    AMOUNT_ABORT = 'amount_abort',
    ADD_SELF_EXCLUSION = 'add_self_exclusion',
    CONFIRM_ADD_SELF_EXCLUSION = 'confirm_add_self_exclusion',
    CANCEL_SELF_EXCLUSION = 'cancel_self_exclusion',

    //scoreboard
    SCOREBOARD_EXPAND = 'scoreboard_expand',
    SCOREBOARD_COLLAPSE = 'scoreboard_collapse',
}

export enum AnalyticsUserProperty {
    CUSTOM_USER_ID = 'custom_user_id',
}

export const LOG_TAG = '[RudderStack]';
