import React, { useCallback, useEffect, useRef } from 'react';
import { Animated, Pressable, StyleSheet } from 'react-native';

import Slash from '@/assets/icons/slash';
import { designSystem } from '@/styles/styles';
import { nativeDriverValue } from '@/utils/constants-platform-specific';

export const animationConfig = {
    useNativeDriver: nativeDriverValue,
    mass: 1,
    damping: 15,
    stiffness: 120,
    overshootClamping: false,
    restSpeedThreshold: 0.001,
    restDisplacementThreshold: 0.001,
};

type Switch = {
    val: boolean;
    handleOnPress: (value: boolean) => void;
    disabled?: boolean;
    warning?: boolean;
    activeTrackColor?: string;
    inactiveTrackColor?: string;
    testID?: string;
    editable?: boolean;
};

const Switch = ({
    handleOnPress,
    activeTrackColor = designSystem.colors.green,
    inactiveTrackColor = designSystem.colors.gray5,
    val,
    disabled,
    warning,
    testID,
    editable = true,
}: Switch) => {
    const switchTranslate = useRef(new Animated.Value(Number(val))).current;

    const animate = useCallback(
        (toValue: number) => {
            Animated.spring(switchTranslate, { toValue, ...animationConfig }).start();
        },
        [switchTranslate]
    );

    const trackBackgroundColor = {
        backgroundColor: val ? activeTrackColor : inactiveTrackColor,
    };

    const isWarningStyle = warning && Boolean(val) && !disabled;

    useEffect(() => animate(Number(val)), [animate, val]);

    return (
        <Pressable
            disabled={disabled || !editable}
            onPress={() => {
                animate(Number(!val));
                handleOnPress(Boolean(!val));
            }}
            testID={testID}
        >
            <Animated.View
                style={[
                    styles.containerStyle,
                    trackBackgroundColor,
                    isWarningStyle && styles.warningStyle,
                    disabled && styles.disabledStyle,
                ]}
            >
                <Animated.View
                    style={[
                        styles.circleStyle,
                        disabled && styles.disabledCircleStyle,
                        {
                            transform: [
                                {
                                    translateX: switchTranslate.interpolate({
                                        inputRange: [0, 1],
                                        outputRange: [2, 18],
                                        extrapolate: 'clamp',
                                    }),
                                },
                            ],
                        },
                    ]}
                >
                    {isWarningStyle ? <Slash /> : null}
                </Animated.View>
            </Animated.View>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    circleStyle: {
        width: 24,
        height: 24,
        backgroundColor: designSystem.colors.white,
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowColor: designSystem.colors.gray8,
        shadowOpacity: 0.25,
        shadowRadius: 2,
        elevation: 1,
    },
    disabledCircleStyle: {
        backgroundColor: designSystem.colors.gray4,
    },
    disabledStyle: {
        backgroundColor: designSystem.colors.gray5,
    },
    warningStyle: {
        backgroundColor: designSystem.colors.warning,
    },
    containerStyle: {
        width: 44,
        height: 28,
        justifyContent: 'center',
        borderRadius: 100,
    },
});

export default Switch;
