import React from 'react';

import { LoadingSkeleton } from '@/components/SkeletonLoader';
import { Box } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

export const LobbyLeagueSkeleton = () => {
    return (
        <Box height={118} width={72} alignItems="center" justifyContent={'center'}>
            <Box backgroundColor="gray6" borderRadius={'r96'}>
                <LoadingSkeleton
                    skeletonColor={designSystem.colors.gray3}
                    width={72}
                    height={72}
                    borderRadius={'r96'}
                />
            </Box>
            <LoadingSkeleton
                skeletonColor={designSystem.colors.gray3}
                width={50}
                height={20}
                borderRadius="r4"
                marginTop="s8"
            />
            <LoadingSkeleton
                skeletonColor={designSystem.colors.gray3}
                width={80}
                height={12}
                borderRadius="r4"
                marginTop="s4"
            />
        </Box>
    );
};
