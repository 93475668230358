import { useCallback } from 'react';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { URLS } from '@/data';
import { navigateToAccountSuspendedModal } from '@/feature/authentication/utils';
import { initialKycScreens } from '@/feature/kyc/const';
import { useActiveWalletStore } from '@/hooks/use-active-wallet';
import { getAuthUserConfig } from '@/hooks/use-auth-user-config';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { AccountStackParamList, RootStackParamList } from './types';

type HomeScreen = 'lobby' | 'account';

/**
 * Navigates to the Home tabs of the active Product (Pickem/SBK)
 * @param navigation the root navigation object
 * @param screen the tab that should be pre-selected
 */
export function navigateHome(
    navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>,
    screen: HomeScreen = 'lobby'
) {
    const selectedProduct = useJurisdictionStore.getState().product;
    navigateToProduct(navigation, selectedProduct, screen);
}

/**
 * Similar to {@link navigateHome}, but also takes a product as parameter, when we want to explicitly
 * specify to which product it should navigate.
 * Useful to call this from "product switchers".
 *
 * @param navigation the root navigation object
 * @param product the product(Pickem/SBK) to navigate to
 * @param screen the tab that should be pre-selected
 */
export function navigateToProduct(
    navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>,
    product?: Product,
    screen: HomeScreen = 'lobby'
) {
    // reset active wallet whenever switching products, it will re-initialized when navigating to account screen
    useActiveWalletStore.getState().resetActiveWallet();
    const root = product === Product.Sportsbook ? 'SBKHome' : 'PickemHome';
    const screenParams = screen === 'lobby' ? { screen: 'Lobby' } : { screen: 'AccountStack' };

    navigation.reset({
        index: 0,
        routes: [
            {
                name: root,
                params: screenParams,
            },
        ],
    });
}

/**
 * Determines navigation based on the user's failed KYC count:
 * - If `failedKycCount` < (total initial KYC screens - 1) -> navigate to next KYC screen
 * - Otherwise -> navigate to account suspended modal
 */
const navigateBasedOnKYCCount = (
    navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>,
    failedKycCount: number
) => {
    if (failedKycCount < initialKycScreens.length) {
        navigation.navigate(initialKycScreens[failedKycCount]);
    } else {
        navigateToAccountSuspendedModal(navigation);
    }
};

/**
 * Navigates the user to the appropriate screen based on their KYC status after logging in.
 */
export async function handleKYCNavigation(navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>) {
    const userConfig = await getAuthUserConfig();
    const { USER_KYC, FAILED_KYC_COUNT = 0 } = userConfig.data.config;

    if (USER_KYC) {
        navigateHome(navigation);
    } else {
        navigateBasedOnKYCCount(navigation, FAILED_KYC_COUNT);
    }
}

/**
 * Resets the navigation to a specific screen inside the Account stack.
 */
export function resetToAccountScreen(
    navigation: Omit<NavigationProp<RootStackParamList>, 'getState'>,
    screen: keyof Pick<AccountStackParamList, 'TransactionHistory' | 'Timeout'>
) {
    const selectedProduct = useJurisdictionStore.getState().product;
    const root = selectedProduct === Product.Pickem ? 'PickemHome' : 'SBKHome';

    // This if statement will be removed once RUNNING_NATIVE_SPORTSBOOK = true as we'll have consistent
    // navigation tree for both products
    if (root === 'PickemHome' || root === 'SBKHome') {
        navigation.navigate(root, { screen: 'AccountStack', params: { screen } });
    } else {
        // navigates directly to screen when SBKHome is SBWebView (ie navigation.navigate('TransactionHistory'))
        navigation.navigate(screen as any);
    }
}

export function useCoreNavigation() {
    const navigation = useNavigation();
    const clearGpsData = useJurisdictionStore(state => state.actions.clearGpsData);

    const openLaunch = useCallback(() => {
        navigation.reset({ routes: [{ name: 'Launch' }] });
    }, [navigation]);

    const openHome = useCallback(
        (screen: HomeScreen = 'lobby') => {
            navigateHome(navigation, screen);
        },
        [navigation]
    );

    const showIneligibleCountry = useCallback(() => {
        clearGpsData();
        navigation.navigate('IneligibleLocationWeb');
    }, [clearGpsData, navigation]);

    const showIneligibleStateError = useCallback(() => {
        clearGpsData();
        navigation.navigate('IneligibleStateModal', {
            title: 'Ineligible location',
            subtitle:
                'It looks like you are in a state that does not allow game play for our products. If you think this is a mistake make sure to turn off VPNs and enable your phone location so we can accurately detect your state.',
            primaryButton: 'See eligible states',
            handlePress: () => {
                navigation.navigate('ModalWebView', { uri: URLS.ALLOWED_STATES });
            },
        });
    }, [clearGpsData, navigation]);

    return { openLaunch, openHome, showIneligibleStateError, showIneligibleCountry };
}
