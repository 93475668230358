import { useCallback, useMemo } from 'react';

import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { useSubscribePublic } from '@/utils/websocket/hooks';
import { MatchUpdateMessage } from '@/utils/websocket/types';

/**
 * Hook to subscribe to updates for SGP odds for a specific event.
 * @param {string} eventId - The ID of the event to subscribe to.
 * @param {boolean} enabled - Whether the subscription is enabled. Defaults to true.
 */
export const useSubscribeEventSgpOddsUpdate = (eventId: string, enabled: boolean = true) => {
    const updateSgpOdds = useSbkBetSlipStore(state => state.actions.updateSgpOdds);

    const handleMatchUpdate = useCallback(
        (message: MatchUpdateMessage) => {
            if (message.change_type === 'SgpUpdate') {
                updateSgpOdds({
                    updateEventId: eventId,
                    forceUpdate: true,
                    shouldHandleOddsChangeFlow: true,
                    isWebsocketUpdate: true,
                });
            }
        },
        [updateSgpOdds, eventId]
    );

    const params = useMemo(() => ({ eventId }), [eventId]);
    useSubscribePublic<'eventMatchUpdate'>('event:{eventId}', params, 'match_update', handleMatchUpdate, enabled);
};
