import { Bet, SBKBetSlip } from '../types';
import { getComboBetType, getComboOdds, isComboSelectionEnabled } from './betslip-utils';
import { formatEventTeams } from './formatter';

/**
 * Returns the required information to display the first column of the Bet Summary Sheet
 */
export const getBetSummary = (bet: Bet, state: SBKBetSlip) => {
    switch (bet.betType) {
        case 'SINGLE':
            const selection = state.selections[bet.id];
            return {
                key: 'bet_summary_singles_bet' as const,
                odds: state.options[selection.optionId].odds,
                eventName: formatEventTeams(state.events[selection.eventId]),
            };
        case 'COMBO':
            const comboBetType = getComboBetType(state);
            const odds = getComboOdds(state);
            const selections = Object.values(state.selections).filter(currentSelection =>
                isComboSelectionEnabled(currentSelection, state)
            );
            const selectionCount = selections.length;
            switch (comboBetType) {
                case 'SGP':
                    const eventName = formatEventTeams(state.events[selections[0].eventId]);
                    return {
                        key: 'bet_summary_script_bet' as const,
                        odds,
                        eventName,
                        selectionCount,
                    };
                default:
                    return {
                        key: 'bet_summary_combo_bet' as const,
                        odds,
                        selectionCount,
                    };
            }
    }
};

const betTypeOrder = ['COMBO', 'SINGLE'];

/**
 * Order bets in bet summary by bet type: combos, then scripts, then singles.
 */
export const sortBetSummary = (aBet: Bet, bBet: Bet) =>
    betTypeOrder.indexOf(aBet.betType) - betTypeOrder.indexOf(bBet.betType);
