import { useEffect, useState } from 'react';

import { MarketCategory } from '../types';

/**
 * Custom hook that manages the active submarket state.
 *
 * @param marketCategories - An array of MarketCategory objects.
 * @returns An object containing the activeSubMarket state and a function to update it.
 */
export const useActiveSubMarket = (marketCategories: Array<MarketCategory>) => {
    const [activeSubMarket, setActiveSubMarket] = useState<MarketCategory | undefined>();

    useEffect(() => {
        const noActiveSubMarket = !activeSubMarket;
        const activeSubMarketNotInMarketCategories =
            activeSubMarket &&
            !marketCategories.find(marketCategory => marketCategory.market_type === activeSubMarket.market_type);
        if (noActiveSubMarket || activeSubMarketNotInMarketCategories) {
            setActiveSubMarket(marketCategories?.[0]);
        }
    }, [activeSubMarket, marketCategories]);

    return {
        activeSubMarket,
        setActiveSubMarket: (marketCategory: MarketCategory) => setActiveSubMarket(marketCategory),
    };
};
