import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { summarySentencesConjunctor } from '@/feature/betslip-sbk/utils/betslip-utils';

type OneLineSummaryInput = {
    singleCount: number;
    scriptCount: number;
    comboCount: number;
};

export const useOneLineSummary = () => {
    const { t } = useTranslation(['betslip_sbk']);
    const generateOneLineSummary = useCallback(
        ({ singleCount, scriptCount, comboCount }: OneLineSummaryInput) => {
            const sentencesArray = [];
            if (singleCount > 0) {
                sentencesArray.push(t('single_with_count', { count: singleCount }));
            }
            if (scriptCount > 0) {
                sentencesArray.push(t('script_with_count', { count: scriptCount }));
            }
            if (comboCount > 0) {
                sentencesArray.push(t('combo_with_count', { count: comboCount }));
            }

            return summarySentencesConjunctor(sentencesArray);
        },
        [t]
    );

    return { generateOneLineSummary };
};
