import React, { useEffect, useRef } from 'react';
import { LayoutAnimation, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { common, designSystem } from '@/styles/styles';

const styles = StyleSheet.create({
    inputBox: {
        color: designSystem.colors.white,
        fontSize: 104,
    },
    dollarContainer: {
        paddingTop: 20,
    },
});

type WithdrawalNumberSectionProps = {
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    displayValue: string;
};

export const WithdrawalNumberSection = ({ isEditing, setIsEditing, displayValue }: WithdrawalNumberSectionProps) => {
    const inputRef = useRef<TextInput>(null);

    useEffect(() => {
        if (isEditing) {
            const timer = setTimeout(() => {
                inputRef.current?.focus();
            }, 300);

            return () => clearTimeout(timer);
        }
    }, [isEditing]);

    return (
        <>
            <View style={[common.row, common.justifyCenter]}>
                <View style={styles.dollarContainer}>
                    <Text variant="headlineLarge">$</Text>
                </View>
                <Text style={styles.inputBox} lineHeight={114} fontWeight="600" numberOfLines={1} adjustsFontSizeToFit>
                    {displayValue}
                </Text>
            </View>

            {!isEditing ? (
                <>
                    <SizedBox value={12} />
                    <TouchableOpacity
                        onPress={() => {
                            setIsEditing(true);
                            LayoutAnimation.easeInEaseOut();
                        }}
                    >
                        <View style={[common.row, common.justifyCenter, common.alignCenter]}>
                            <SizedBox value={24} />
                            <Text fontWeight="bold" textDecorationLine={'underline'}>
                                Edit
                            </Text>
                        </View>
                    </TouchableOpacity>
                </>
            ) : null}
        </>
    );
};
