import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
        <Path
            fill="#fff"
            d="m2.913 10.5.812-3.513L1 4.625l3.6-.313L6 1l1.4 3.313 3.6.312-2.725 2.362.813 3.513L6 8.637 2.913 10.5Z"
        />
    </Svg>
);
export default SvgComponent;
