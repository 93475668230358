import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntryDetails } from '@/api/entries/types/types';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { getMultiplier } from '@/hooks/use-entries-utils';
import { user } from '@/hooks/use-user';
import { Outcome, Status } from '@/types/api.generated';
import { Defined } from '@/types/utils';
import { isEventAcceptingBets } from '@/utils/filterEvents';
import { logger } from '@/utils/logging';
import { useClient } from 'urql';

import {
    GetSharedEntryDocument,
    GetSharedEntryQuery,
    GetSharedEntryQueryVariables,
    useCreateShareableEntryMutation,
} from '../api/query.generated';
import { SharedEntry } from '../api/types';
import { useLinking } from './use-linking';
import { CreateLinkResponse, extractShortIdFromURL, isSocialShareLink, useSocialLink } from './use-social-link';

const LOG_TAG = '[FantasyLink]';

export const useFantasyEntryShare = () => {
    const { createSocialLink } = useSocialLink();
    const [, createShareableEntry] = useCreateShareableEntryMutation();
    const [creatingLink, setCreatingLink] = useState(false);
    const [shareLink, setShareLink] = useState<string | undefined>(undefined);

    const createFantasyShareLink = useCallback(
        async (entry: Defined<EntryDetails>): Promise<CreateLinkResponse> => {
            try {
                setCreatingLink(true);
                const response = await createShareableEntry({ entryId: entry.id });
                const publicId = response.data?.createSharedEntry?.id;
                if (publicId) {
                    const multiplier = getMultiplier(entry, 'initial');
                    const title = `${entry.picks.length} picks for ${multiplier}`;
                    const desc = entry.picks
                        .slice(0, 4)
                        .map(
                            p =>
                                `• ${p.player.firstName} ${p.player.lastName} ${
                                    p.outcome === Outcome.Less ? '↓' : '↑'
                                } ${p.projection.value} ${p.projection.name}`
                        )
                        .join('\n');
                    const link = await createSocialLink({
                        payload: { entryId: publicId },
                        user_id: user.profile.sub,
                        user_email: user.profile.email,
                        type: 'fantasy',
                        meta: { title, desc },
                    });
                    setShareLink(link.shortUrl);
                    return link;
                } else {
                    throw new Error('Can not make entry shareable');
                }
            } catch (e) {
                logger.warn('Share Entry Link', e);
                throw e;
            } finally {
                setCreatingLink(false);
            }
        },
        [createShareableEntry, createSocialLink]
    );

    return { createFantasyShareLink, creatingLink, shareLink };
};

export const useFantasyEntryImport = () => {
    const gqlClient = useClient();
    const { showToast, showInfoSheet } = useAlerts();
    const { t } = useTranslation(['lineup_update_modal']);
    const { importSocialLink } = useSocialLink();
    const [importing, setImporting] = useState(false);
    const [sharedEntry, setSharedEntry] = useState<{ sharedEntry: SharedEntry; linkId: string } | undefined>(undefined);

    const showLineupUnavailable = useCallback(() => {
        showInfoSheet({
            title: t('lineup_unavailable_title'),
            description: t('lineup_unavailable_info'),
            buttonLabel: t('browse_players'),
        });
    }, [showInfoSheet, t]);

    const importSharedEntry = useCallback(
        async (shortID: string) => {
            try {
                setImporting(true);
                const socialLink = await importSocialLink(shortID);
                logger.info(LOG_TAG, 'imported social link', socialLink);
                if (socialLink?.payload.entryId) {
                    const response = await gqlClient.query<GetSharedEntryQuery, GetSharedEntryQueryVariables>(
                        GetSharedEntryDocument,
                        {
                            id: socialLink?.payload.entryId,
                        },
                        {
                            requestPolicy: 'network-only',
                        }
                    );
                    const entry = response.data?.getSharedEntry;
                    if (entry?.entry.status === Status.Settled) {
                        showLineupUnavailable();
                        return;
                    } else {
                        const noEventAcceptingBets = entry?.entry.picks.every(
                            p => !isEventAcceptingBets({ league: p.league, status: p.eventStatus })
                        );
                        if (noEventAcceptingBets) {
                            showLineupUnavailable();
                            return;
                        }
                    }
                    logger.info(LOG_TAG, 'imported entry');
                    if (entry && socialLink) {
                        setSharedEntry({ sharedEntry: entry, linkId: shortID });
                    } else {
                        showLineupUnavailable();
                        return;
                    }
                } else {
                    showLineupUnavailable();
                }
            } catch (error) {
                showLineupUnavailable();
                logger.warn(LOG_TAG, error);
            } finally {
                setImporting(false);
            }
        },
        [gqlClient, importSocialLink, showLineupUnavailable]
    );

    const importEntry = useCallback(
        (url: string) => {
            if (isSocialShareLink(url)) {
                logger.info(LOG_TAG, `Open link ${url}`);
                const shortId = extractShortIdFromURL(url);
                if (shortId) {
                    importSharedEntry(shortId);
                } else {
                    showToast({ message: 'Invalid link :(' });
                }
            }
        },
        [importSharedEntry, showToast]
    );

    const importEntryFromInitialLink = useCallback(
        (url: string) => {
            logger.info(LOG_TAG, `Initial link ${url}`);
            importEntry(url);
        },
        [importEntry]
    );

    const importEntryFromLink = useCallback(
        (url: string) => {
            logger.info(LOG_TAG, `New link ${url}`);
            importEntry(url);
        },
        [importEntry]
    );
    useLinking({
        source: 'fantasy-entry-import',
        onInitialLink: importEntryFromInitialLink,
        onNewLink: importEntryFromLink,
    });

    return { importing, sharedEntry };
};
