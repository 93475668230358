import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { EntriesTopTabBarScreen } from '@/feature/entries-pickem/screens/EntriesTopTabBarScreen';
import { PickemEntriesStackParamList } from '@/navigation/pickem/types';

const Stack = createStackNavigator<PickemEntriesStackParamList>();

export const EntriesStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="EntriesScreen" component={EntriesTopTabBarScreen} />
        </Stack.Navigator>
    );
};
