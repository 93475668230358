import { Route, useNavigation } from '@react-navigation/native';

import { RootStackParamList } from '@/navigation/types';

/**
 * A custom hook to find and return a specific route by its name.
 *
 * @template T - A key from the RootStackParamList that corresponds to a route name.
 * @param {T} routeName - The name of the route you want to retrieve.
 * @returns {(Route<T, RootStackParamList[T]> | undefined)} - The route object if found, otherwise undefined.
 *
 * @example
 * Example of returned route object:
 * {
 *   key: string;
 *   name: string;
 *   path?: string;
 *   params?: object;
 * }
 * https://reactnavigation.org/docs/navigation-state#:~:text=Each%20route%20object%20in%20a%20routes%20array%20may%20contain%20the%20following%20properties%3A
 *
 */
export function useRouteByName<T extends keyof RootStackParamList>(
    routeName: T
): Route<T, RootStackParamList[T]> | undefined {
    const navigation = useNavigation();
    const navigationState = navigation.getState();

    const targetRoute = navigationState?.routes.find(route => route.name === routeName) as
        | Route<T, RootStackParamList[T]>
        | undefined;

    return targetRoute;
}
