import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { PlayerProjectionFragment } from '@/api/events/query.generated';
import ArrowIcon from '@/assets/icons/arrow';
import { Box, Row } from '@/components/lib/components';
import { SingleProjectionIcon } from '@/feature/lobby/components/PlayerDetails';
import { common, designSystem } from '@/styles/styles';
import { Outcome } from '@/types/api.generated';
import { getColorByProjType } from '@/utils/getProjectionsTypes';

const styles = StyleSheet.create({
    projectionIcon: {
        borderRightWidth: 1,
        borderColor: designSystem.colors.gray8,
    },
});

type PickProjectionsIconsProps = {
    isVoided: boolean;
    projection: PlayerProjectionFragment;
    outcome: Outcome;
    singleProjectionStyle?: StyleProp<ViewStyle>;
    arrowStyles?: StyleProp<ViewStyle>;
    containerStyles?: StyleProp<ViewStyle>;
};

export const PickProjectionsIcons = ({
    isVoided,
    projection,
    outcome,
    singleProjectionStyle,
    arrowStyles,
    containerStyles,
}: PickProjectionsIconsProps) => {
    const arrowDirection = outcome === Outcome.More ? common.rotate180 : {};
    const computedSpecialIncrease = projection?.nonRegularPercentage ? projection.nonRegularPercentage > 0 : null;
    const pickColor = isVoided
        ? 'gray4'
        : projection && getColorByProjType(projection.type, computedSpecialIncrease ?? false);

    return (
        <Row style={containerStyles}>
            <SingleProjectionIcon
                overrideColor={isVoided ? 'gray4' : undefined}
                selectedProjection={projection}
                style={[singleProjectionStyle, styles.projectionIcon]}
            />
            <Box
                backgroundColor={pickColor}
                width={20}
                height={20}
                alignItems={'center'}
                justifyContent={'center'}
                borderRadius={'r6'}
                borderWidth={0.5}
                style={[arrowStyles, common.zIndexNegative1, common.borderCurve]}
            >
                <ArrowIcon style={arrowDirection} color={designSystem.colors.gray8} />
            </Box>
        </Row>
    );
};
