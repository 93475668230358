import React from 'react';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';

type Props = {
    onTryAgain: () => void;
};

export const FailedButtons = ({ onTryAgain }: Props) => {
    const navigation = useNavigation();
    return (
        <>
            <Button label="Try again" variant="rounded" type="active" onPress={onTryAgain} />
            <SizedBox value={8} />
            <Button label="Dismiss" type="text" onPress={() => navigation.goBack()} />
        </>
    );
};
