import React from 'react';
import { Pressable, View } from 'react-native';

import { CurrencyInput } from '@/components/CurrencyInput';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';

import { useBetPayout } from '../hooks/betslip-hooks';
import { fromPayoutInput } from '../utils/formatter';

export const PayoutInput = ({ betId }: { betId: string }) => {
    const payout = useBetPayout(betId);
    return (
        <Pressable onPress={() => GASbkEvents.clickPayoutSection()}>
            <View pointerEvents="none">
                <CurrencyInput editable={false} label="Payout" value={fromPayoutInput(payout)} />
            </View>
        </Pressable>
    );
};
