import { useCallback, useEffect } from 'react';

import { GLI, getEnv } from '@/data';
import { initAppsFlyer } from '@/data/appsflyer';
import { initBraze } from '@/data/braze';
import { enableDataDog } from '@/data/datadog';
import { useInitIntercom } from '@/data/intercom/intercom';
import { getGeocomplyClient } from '@/data/location/geocomply-common';
import { useFetchAndActivateFirebaseRemoteConfigs } from '@/feature/analytics/hooks/use-firebase-remote-config';
import { useRudderStack } from '@/feature/analytics/hooks/use-rudder-stack';
import { setTrackersUserId } from '@/feature/analytics/utils/analyticsUtils';
import { useUpdatePickemLeagueConfigs } from '@/feature/betslip-pickem/hooks/use-league-configs-store';
import { useMakeFirstDeposit } from '@/feature/lobby/hooks/use-make-first-deposit';
import { usePromptBiometricGuard } from '@/feature/lobby/hooks/use-prompt-biometric-guard';
import { useStoreUserIdToAsyncStorage } from '@/feature/lobby/hooks/use-store-user-id-to-async-storage';
import { usePhraseUpdateTranslations } from '@/hooks/use-phrase-update-translations';
import { useBiometricCheck } from '@/utils/biometrics';

import { useAppVersionCheck } from './use-app-version-check';
import { useJurisdictionStore } from './use-jurisdiction';
import { useMaintenanceCheck } from './use-maintenance-check';
import { useAppResumeEffect } from './use-resume';
import { useTrackingEnabled } from './use-tracking-enabled';
import { useUser } from './use-user';

/**
 * Hook that runs the initialization of the app SDKs.
 */
export const useAppSdksInit = () => {
    const ENV = getEnv();
    const initIntercom = useInitIntercom();

    enableDataDog(ENV);
    useTrackingEnabled();

    //Marketing tech SDKs should be disabled for GLI variant
    const marTechEnabled = ENV !== GLI;
    if (marTechEnabled) {
        initAppsFlyer();
        initBraze();
        initIntercom();
    }
    useRudderStack(marTechEnabled);
};

/**
 * Hook that runs the initializations for the apps home screen.
 *
 * This includes checking app version updates, setting up biometric checks, initializing the geocomply client, etc.
 */
export const useAppInit = () => {
    const product = useJurisdictionStore(store => store.product) ?? '';
    const jurisdiction = useJurisdictionStore(store => store.jurisdiction) ?? '';
    const refetchSettings = useJurisdictionStore(store => store.actions.refetchAndUpdateSettings);
    const { profile } = useUser();

    useEffect(() => {
        setTrackersUserId(profile.sub);
    }, [profile.sub]);

    useStoreUserIdToAsyncStorage();

    useAppVersionCheck();

    usePromptBiometricGuard();

    useBiometricCheck();

    useMaintenanceCheck();

    useMakeFirstDeposit();

    usePhraseUpdateTranslations();

    useAppResumeEffect(
        useCallback(() => {
            refetchSettings();
        }, [refetchSettings])
    );

    useUpdatePickemLeagueConfigs();

    useFetchAndActivateFirebaseRemoteConfigs();

    useEffect(() => {
        getGeocomplyClient({ product: product, jurisdiction: jurisdiction })?.listenToIpDetection();
    }, [product, jurisdiction]);
};
