import React, { useCallback, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

import { useIsFocused } from '@react-navigation/native';

import { useStickyTabList, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { useMicroMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-micro-market-cache';
import { Event, Market, MarketCategory } from '@/feature/event-details-sbk/types';
import { MicroMarketChipBar } from '@/feature/league-hub-sbk/components/MicroMarketChipBar';
import { MicroMarketEventCard } from '@/feature/league-hub-sbk/components/MicroMarketEventCard';
import { useActiveMicroMarket } from '@/feature/league-hub-sbk/hooks/use-active-micro-market';
import { useLeagueMarketList } from '@/feature/league-hub-sbk/hooks/use-league-market-list';
import { useSubscribeLeagueNewMarket } from '@/feature/league-hub-sbk/hooks/use-subscribe-league-new-market';
import { common, designSystem } from '@/styles/styles';
import { QueryObserverResult } from '@tanstack/react-query';

import { MicroMarketListItem, useMicroMarketData } from '../../hooks/use-micro-market-data';
import { useSectionHeights } from '../../hooks/use-section-heights';

type LeagueMicroMarketLayoutProps = {
    marketCategoryId: string;
    events: Event[];
    leagueId: string;
    sportName: string;
    marketCategories: MarketCategory[];
    refetch: () => Promise<QueryObserverResult<any, unknown>[]>;
    tabKey: string;
    isTabBarVisible: boolean;
};

const keyExtractor = (item: { event: Event; market: Market }) => item.market.id;

export const LeagueMicroMarketLayout = ({
    marketCategoryId,
    leagueId,
    events,
    sportName,
    marketCategories,
    refetch,
    tabKey,
    isTabBarVisible,
}: LeagueMicroMarketLayoutProps) => {
    const isFocused = useIsFocused();
    useSubscribeLeagueNewMarket(leagueId, marketCategoryId, isFocused);

    //clear cache whenever we get new marketCategories data
    const clearMicroMarketCache = useMicroMarketCacheStore(state => state.actions.clearCache);
    useEffect(() => {
        if (isFocused) {
            clearMicroMarketCache();
        }
    }, [clearMicroMarketCache, isFocused, marketCategories]);

    const { data, chipItems } = useMicroMarketData(leagueId, events, marketCategories);
    const { gameSectionHeight, marketHeaderSectionHeight } = useSectionHeights(leagueId);
    const { activeMicroMarket, setActiveMicroMarket } = useActiveMicroMarket(data, chipItems);

    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);
    const { scrollableProps, setStickyRef, contentHeight } = useStickyTabList(tabKey, forceRefreshing, onRefresh);
    const { flatListProps } = useLeagueMarketList(true, contentHeight, isTabBarVisible, marketHeaderSectionHeight);
    const { smoothScrollHeaderStyle } = useStickyTabsAnimation();

    const renderItem = useCallback(({ item }: { item: { event: Event; market: Market } }) => {
        return <MicroMarketEventCard market={item.market} event={item.event} />;
    }, []);

    if (!activeMicroMarket) {
        return null;
    }

    const top = gameSectionHeight + (isTabBarVisible ? TAB_HEIGHT : 0);

    return (
        <>
            <Animated.View
                style={[common.full, smoothScrollHeaderStyle, { top, backgroundColor: designSystem.colors.gray8 }]}
            >
                <MicroMarketChipBar
                    sportName={sportName}
                    chipItems={chipItems}
                    activeMicroMarket={activeMicroMarket}
                    setActiveMicroMarket={setActiveMicroMarket}
                />
            </Animated.View>
            <Animated.FlatList<MicroMarketListItem>
                data={data?.[activeMicroMarket.id] ?? []}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                {...scrollableProps}
                // ! flatListProps should be after scrollableProps as it overrides contentContainerStyle
                {...flatListProps}
                // ! ref cannot be set through spread operator
                ref={setStickyRef}
                ListFooterComponentStyle={styles.footer}
            />
        </>
    );
};

const styles = StyleSheet.create({
    footer: {
        paddingTop: 16,
    },
});
