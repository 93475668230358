import { EventInfo, isTeamEvent } from '@/api/events/types/types';
import { BetValidationData } from '@/feature/betslip-pickem/api/types';
import { BetslipPick } from '@/feature/betslip-pickem/types';
import { SharedEntryPicks } from '@/feature/entry-share/api/types';
import {
    Pick as ApiPick,
    BoostedMultiplier,
    Currency,
    DynamicMultiplier,
    EntryError,
    EntryErrorType,
    EntryRulesOutput,
    GameMode,
    GameType,
} from '@/types/api.generated';
import { EntryErrorCodes } from '@/utils/errors/entry-errors';
import { logger } from '@/utils/logging';

import { EntryAmountValidationData } from '../hooks/use-betslip-actions';
import { useBetslipStore } from '../hooks/use-betslip-store';
import { eventUtils } from './event-utils';

export const mapPicksToApiData = (picks: BetslipPick[]): ApiPick[] => {
    return picks.map(pick => {
        const selectedOption = pick.projection.allowedOptions?.find(it => it.outcome === pick.outcome);
        const option = selectedOption
            ? { marketOptionId: selectedOption?.marketOptionId, outcome: selectedOption?.outcome }
            : undefined;
        return {
            eventId: pick.eventId,
            marketId: pick.projection.marketId,
            isLive: pick.projection.isLive,
            league: pick.player.league,
            option: option,
            outcome: pick.outcome,
            sport: pick.player.sport,
            teamId: pick.player.team?.id,
            projectionName: pick.projection.name,
            projectionValue: pick.projection.value,
            projectionType: pick.projection.type,
            nonRegularProjectionPercentage: pick.projection.nonRegularPercentage,
            nonRegularProjectionValue: pick.projection.nonRegularValue,
            playerId: pick.player.id,
        };
    });
};

export const getInvalidPicksError = (data: BetValidationData) => {
    return data?.errors?.find(
        err => err.code === EntryErrorCodes.INVALID_PROJECTION || err.code === EntryErrorCodes.INVALID_EVENT_STATUS
    );
};

export const containsRestrictionErrors = (data: BetValidationData): boolean => {
    return !!data.errors.find(it => it.type === EntryErrorType.Restriction);
};

export const containsMultipleRestrictionErrors = (restrictionErrors: EntryError) => {
    return restrictionErrors.restrictedPlayerIdPairs && restrictionErrors.restrictedPlayerIdPairs.length > 1;
};

export const containsSingleRestrictionError = (restrictionErrors: EntryError) => {
    return restrictionErrors.restrictedPlayerIdPairs && restrictionErrors.restrictedPlayerIdPairs.length === 1;
};

export const getRestrictedCombinationErrorMsg = (errors: EntryError[]): string => {
    return errors
        .filter(err => err.type === EntryErrorType.Restriction)
        .map(err => err.message)
        .join('\n');
};

export const getWinningPicksAndToWin = (
    data: DynamicMultiplier[],
    dynamicBoostedMultiplier?: BoostedMultiplier
): { winningPicksRange: string; highestToWin: number; highestMultiplier: number } => {
    if (data.length === 0) {
        return { winningPicksRange: '', highestToWin: 0, highestMultiplier: 0 };
    }

    const winningPicksArray = data.map(item => item.winningPicks);
    const minWinningPicks = Math.min(...winningPicksArray);
    const maxWinningPicks = Math.max(...winningPicksArray);
    const highestMultiplier = dynamicBoostedMultiplier?.multiplier ?? Math.max(...data.map(item => item.multiplier));

    const highestToWin = dynamicBoostedMultiplier?.toWin ?? Math.max(...data.map(item => item.toWin));

    const winningPicksRange = `${minWinningPicks}-${maxWinningPicks}`;

    return { winningPicksRange, highestToWin, highestMultiplier };
};

export const getBoostedMultiplierDetails = (
    shouldShowMultiplier: boolean,
    dynamicMultiplier: DynamicMultiplier,
    dynamicBoostedMultiplier?: BoostedMultiplier
) => {
    if (!shouldShowMultiplier) {
        return { boostedMultiplier: undefined, toWin: dynamicMultiplier.toWin };
    }
    return {
        boostedMultiplier: dynamicBoostedMultiplier?.multiplier,
        toWin: dynamicBoostedMultiplier?.toWin ?? dynamicMultiplier.toWin,
    };
};

export const mapValidationDataToGameModes = (
    data: BetValidationData[],
    entryRules: EntryRulesOutput[],
    limitsValidation: Record<GameMode, EntryAmountValidationData>
) => {
    const result: Record<GameMode, { betValidation?: BetValidationData; amountValidation: EntryAmountValidationData }> =
        {
            DYNAMIC: {
                betValidation: undefined,
                amountValidation: { errorMessage: undefined, validEntry: false, maxAllowedEntryAmount: 0 },
            },
            PERFECT: {
                betValidation: undefined,
                amountValidation: { errorMessage: undefined, validEntry: false, maxAllowedEntryAmount: 0 },
            },
        };

    data.forEach(it => {
        const entryRule = entryRules.find(er => er.id === it.entryRulesId);
        if (entryRule?.gameMode) {
            result[entryRule.gameMode] = {
                betValidation: it,
                amountValidation: limitsValidation?.[entryRule.gameMode],
            };
        } else {
            logger.error('[BetValidation] No entry rule for:', it);
        }
    });

    return {
        ...result,
        DEFAULT: result.PERFECT ?? data[0],
    };
};

export const calculateDollarPlaceholderFilling = (winningPicks: number, maxWinningPicks: number) => {
    const maxFilling = 3;
    const diff = maxWinningPicks - winningPicks;
    return maxFilling - diff;
};

export const getBetslipTitle = (dynamicModeDisabled: boolean, perfectModeDisabled: boolean, gameTypes: GameType[]) => {
    if (dynamicModeDisabled && perfectModeDisabled) {
        return '';
    }
    if (gameTypes.includes(GameType.P2P)) {
        return 'One available';
    }
    if (dynamicModeDisabled) {
        return 'Only perfect play is available';
    }
    if (perfectModeDisabled) {
        return 'Only dynamic play is available';
    }
    return 'Choose one';
};

export const mapPicksToBetSlipPick = (pickOutputs: SharedEntryPicks, events: EventInfo[]): BetslipPick[] => {
    return pickOutputs.map(pick => {
        const event = events.find(ev => ev.id === pick.eventId);
        const team = eventUtils.getTeamById(event, pick.teamId);
        const players = isTeamEvent(event) ? team?.players : event?.players;
        const projections = players?.find(player => player.id === pick.player.id)?.projections ?? [];
        return {
            eventId: pick.eventId,
            player: {
                ...pick.player,
                team: team,
                sport: pick.sport,
                league: pick.league,
                projections: projections,
            },
            projection: pick.projection,
            outcome: pick.outcome,
            team: team,
        };
    });
};

export const isPrebuiltLineupInBetslip = (betslip: BetslipPick[], picks: BetslipPick[]): boolean => {
    const hasPick = (pick: BetslipPick, data: BetslipPick[]) =>
        data.find(
            it =>
                it.player.id === pick.player.id &&
                it.eventId === pick.eventId &&
                it.projection.type === pick.projection.type &&
                it.outcome === pick.outcome
        );

    return picks.every(pick => hasPick(pick, betslip));
};

export const isPlayerSelected = ({ eventId, playerId }: { eventId?: string; playerId?: string }) => {
    return useBetslipStore.getState().betslip.find(item => item.eventId === eventId && item.player.id === playerId);
};

export const getErrorForGameMode = (gameMode: GameMode, perfectModeError?: string, dynamicModeError?: string) => {
    switch (gameMode) {
        case GameMode.Perfect:
            return perfectModeError;
        case GameMode.Dynamic:
            return dynamicModeError;
        default:
            return undefined;
    }
};

export const sortEntryRules = (allEntryRules: EntryRulesOutput[], dynamicModeDisabled: boolean) => {
    const dynamicPlay = allEntryRules.find(rule => rule.gameMode === GameMode.Dynamic);
    const perfectPlay = allEntryRules.find(rule => rule.gameMode === GameMode.Perfect);

    if (dynamicModeDisabled) {
        //dynamic disabled, show perfect play first
        return [perfectPlay, dynamicPlay].filter(Boolean);
    } else {
        //otherwise, always show dynamic first
        return [dynamicPlay, perfectPlay].filter(Boolean);
    }
};

export const getPickslipDefaultEntryAmount = (
    defaultAmount: number,
    cash: number,
    betrBucks: number,
    maxAllowedEntryAmount: number,
    userLimits?: number[]
): { amount: number; currency: Currency } => {
    const limit = userLimits && userLimits.length > 0 ? userLimits[0] : Infinity;

    const effectiveDefaultAmount = Math.min(defaultAmount, limit, maxAllowedEntryAmount);
    if (cash >= 1) {
        return { amount: Math.min(effectiveDefaultAmount, cash), currency: Currency.Usd };
    }
    return { amount: Math.min(effectiveDefaultAmount, betrBucks), currency: Currency.Fre };
};
