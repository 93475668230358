import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
        <Path
            fill={props.fill ?? '#6D707C'}
            fillRule="evenodd"
            d="M11.188 22.5 2 13.312V1.5h11.812L23 10.688 11.188 22.5ZM8.646 6.17a1.37 1.37 0 1 1-1.937 1.937A1.37 1.37 0 0 1 8.646 6.17Zm8.45 3.355-7.07 7.071 1.414 1.415 7.07-7.071-1.413-1.415Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
