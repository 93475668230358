import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, SectionList, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { NotifyOnSettledEntryOrPickDocument, useGetEntryDetailsQuery } from '@/api/entries/query.generated';
import { EntryDetails } from '@/api/entries/types/types';
import { useEventsInfoQuery } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import { CloseIcon } from '@/assets/icons/close';
import DownIcon from '@/assets/icons/downSmall';
import ShareIcon from '@/assets/icons/share-icon';
import { KeyboardAvoidingViewNoAnimation } from '@/components/KeyboardAvoidingViewNoAnimation';
import { Loading } from '@/components/Loading';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { trackRUMAction } from '@/data/datadog';
import { useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { DynamicPayoutSheet } from '@/feature/betslip-pickem/components/DynamicPayoutSheet';
import { EntryPickPlayerCard } from '@/feature/entries-pickem/components/EntryPickPlayerCard';
import { EntrySectionEventHeader } from '@/feature/entries-pickem/components/EntrySectionEventHeader';
import { ViewP2pLeaderboardsButton } from '@/feature/p2p-leaderboards/components/ViewP2pLeaderboardsButton';
import {
    CellRendererComponent,
    MaxWidthWrapper,
    webListContentContainerStyle,
    webMaxWidthStyle,
} from '@/feature/responsive-design/WebComponents';
import {
    defaultNegativeToZero,
    getToWin,
    pendingEntryDynamic,
    pendingEntryNotSettled,
} from '@/hooks/use-entries-utils';
import { useResumeEffect } from '@/hooks/use-resume';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { GameMode, GameType, ProjectionType, Result, Status } from '@/types/api.generated';
import { isWeb, showScrollIndicator } from '@/utils/constants-platform-specific';
import { getEntryPayout } from '@/utils/getEntryPayout';
import { nflFullSeason } from '@/utils/league';
import { groupPicksByEvents } from '@/utils/map-to-events';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { useSubscription } from 'urql';

import { EntryFooter } from '../components/EntryFooter';
import { EntryHeader } from '../components/EntryHeader';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'EntryScreen'>;

export const EntryScreen = ({ route: { params } }: ScreenProps) => {
    const { t } = useTranslation(['bets', 'common']);

    const [entry, setLocalEntry] = useState<EntryDetails>();
    const [eventsMap, setEventsMap] = useState<Record<string, EventInfo>>({});
    const [{ data, fetching }, executeGetEntryDetails] = useGetEntryDetailsQuery({
        variables: { id: params.id },
        pause: true,
    });
    const dynamicPayoutSheet = useRef<BottomSheetModal>(null);
    const showLeaderboards = entry?.gameType === GameType.P2P && !!entry.id;

    const navigation = useNavigation();
    const { launchIntercomMessenger } = useLaunchIntercomMessenger();
    const [] = useSubscription(
        { query: NotifyOnSettledEntryOrPickDocument, variables: { id: entry?.id } },
        (_, next) => {
            setLocalEntry(val => {
                const nextPayload = next?.notifyOnSettledEntryOrPick;
                if (nextPayload) {
                    return nextPayload;
                }

                return val;
            });
        }
    );

    const picks = entry?.picks;
    const hasFullSeasonPicks = picks?.some(it => nflFullSeason(it.league));

    const sections = groupPicksByEvents(picks ?? []);
    const uniqueEventIds = Array.from(new Set(sections?.map(section => section.eventId) ?? []));

    const [{ data: eventsInfoData }, executeEventsInfoQuery] = useEventsInfoQuery({
        requestPolicy: 'network-only',
        variables: {
            ids: uniqueEventIds,
        },
        pause: true,
    });

    const entryPayout = useMemo(() => getEntryPayout({ picks: entry?.picks }), [entry]);

    const isVoided = useMemo(() => entry?.result === 'VOID', [entry?.result]);

    const dynamicMultipliers = useMemo(() => {
        // show the multipliers for the non voided number of picks
        const nonVoidedCount = entry?.picks.filter(pick => pick.result !== Result.Void).length ?? 0;
        const initialMultipliers = entry?.dynamicMultipliers?.filter(it => it.numberOfPicks === entry.picks.length);
        const multipliersForNonVoided = entry?.dynamicMultipliers?.filter(it => it.numberOfPicks === nonVoidedCount);
        // if there are no multipliers for the non voided number of picks, show the initial multipliers
        return !!multipliersForNonVoided && multipliersForNonVoided.length > 0
            ? multipliersForNonVoided
            : initialMultipliers;
    }, [entry]);

    const boostedPicks = picks?.filter(pick => pick.projection.type === ProjectionType.Boosted);
    const nonVoidedPickCount = picks?.filter(pick => pick.result !== Result.Void).length ?? 0;
    const nonVoidedBoostedPicksCount = boostedPicks?.filter(pick => pick.result !== Result.Void).length ?? 0;
    const dynamicBoostedMultiplier = entry?.boostedMultipliers?.find(
        it => it.numberOfPicks === nonVoidedPickCount && it.numberOfBoostedPicks === nonVoidedBoostedPicksCount
    );
    const safeInsets = useSafeAreaInsets();

    const entryStatus = useMemo(() => {
        if (pendingEntryNotSettled(entry) || pendingEntryDynamic(entry)) {
            return t('common:win_up_to');
        }
        if (entry?.status === Status.Pending || entry?.result === Result.Void) {
            return t('common:to_win');
        }
        return t('common:won');
    }, [entry, t]);

    useEffect(() => {
        if (data?.getEntry) {
            setLocalEntry(data?.getEntry);
        }
    }, [data?.getEntry]);

    useEffect(() => {
        if (!entry) {
            return;
        }
        executeEventsInfoQuery();
    }, [entry, executeEventsInfoQuery]);

    useEffect(() => {
        if (!eventsInfoData?.getEventsByIdsV2?.length) {
            return;
        }

        setEventsMap(eventsInfoData.getEventsByIdsV2.reduce((acc, event) => ({ ...acc, [event.id]: event }), {}));
    }, [eventsInfoData?.getEventsByIdsV2]);

    const reload = useCallback(() => {
        executeGetEntryDetails({ requestPolicy: 'network-only' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useResumeEffect(reload);

    const isLoading = fetching || !entry || !Array.isArray(picks);

    const { height: windowHeight } = useWindowDimensions();
    return (
        <Screen>
            <Box height={isWeb ? windowHeight : '100%'}>
                <KeyboardAvoidingViewNoAnimation
                    behavior={Platform.OS === 'ios' ? 'padding' : undefined}
                    style={common.flex}
                    keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
                >
                    <SizedBox value={10} />
                    <Row
                        px={'s16'}
                        py={entry?.gameMode === GameMode.Dynamic ? 's6' : 's16'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <TouchableOpacity hitSlop={20} activeOpacity={0.8} onPress={() => navigation.goBack()}>
                            <CloseIcon />
                        </TouchableOpacity>
                        <Box>
                            <Text variant={'titleLarge'}>
                                {entryPayout?.availableCount
                                    ? `${entryPayout?.availableCount || ''} pick${
                                          entryPayout?.availableCount !== 1 ? 's' : ''
                                      } ${
                                          entry?.status === Status.Pending &&
                                          (entry?.gameMode === GameMode.Dynamic || !!entry?.result)
                                              ? t('to_win_up_to')
                                              : t('to_win')
                                      } $${defaultNegativeToZero(getToWin(entry, true))}`
                                    : undefined}
                            </Text>
                            {entry?.gameMode === GameMode.Dynamic ? (
                                <TouchableOpacity
                                    hitSlop={20}
                                    activeOpacity={0.8}
                                    onPress={() => dynamicPayoutSheet.current?.present()}
                                    style={[common.row, common.justifyCenter, common.alignCenter]}
                                >
                                    <Text fontSize={13} color={'gray2'} textAlign={'center'}>
                                        {t('payout_breakdown')}
                                    </Text>
                                    <Box style={common.justifyCenter} height={20} paddingHorizontal={'s4'}>
                                        <DownIcon color={designSystem.colors.gray2} />
                                    </Box>
                                </TouchableOpacity>
                            ) : null}
                        </Box>
                        <TouchableOpacity
                            onPress={() => {
                                BetrAnalytics.trackEvent(AnalyticsEvent.SHARE_ENTRY, { screen: 'entry_details' });
                                trackRUMAction('share-entry');
                                navigation.navigate('EntryShare', {
                                    id: entry?.id,
                                });
                            }}
                        >
                            <ShareIcon />
                        </TouchableOpacity>
                    </Row>

                    {fetching ? (
                        <View style={[common.paddingVertical]}>
                            <Loading />
                        </View>
                    ) : null}
                    {
                        // there's a bug in section list where it doesn't render the next page when scrolling
                        // not rendering the list when there's no data somehow fixes it: https://github.com/facebook/react-native/issues/39421
                        sections?.length > 0 ? (
                            <SectionList
                                style={common.paddingHorizontal}
                                contentContainerStyle={[webListContentContainerStyle]}
                                CellRendererComponent={CellRendererComponent}
                                sections={sections}
                                maxToRenderPerBatch={3}
                                initialNumToRender={3}
                                stickySectionHeadersEnabled={false}
                                showsVerticalScrollIndicator={showScrollIndicator}
                                ListHeaderComponent={
                                    <EntryHeader
                                        entry={entry}
                                        picks={picks}
                                        entryPayout={entryPayout}
                                        entryStatus={entryStatus}
                                        isLoading={isLoading}
                                        isVoided={isVoided}
                                    />
                                }
                                ListFooterComponent={
                                    <EntryFooter
                                        entry={entry}
                                        hasFullSeasonPicks={hasFullSeasonPicks}
                                        handleContactUs={launchIntercomMessenger}
                                        events={eventsInfoData?.getEventsByIdsV2 ?? []}
                                    />
                                }
                                ListHeaderComponentStyle={webMaxWidthStyle}
                                ListFooterComponentStyle={webMaxWidthStyle}
                                renderSectionHeader={({ section }) => {
                                    return (
                                        <EntrySectionEventHeader
                                            event={eventsMap[section.eventId]}
                                            status={entry?.status}
                                        />
                                    );
                                }}
                                renderItem={({ item, index, section }) => {
                                    const isFirst = index === 0;
                                    const isLast = index + 1 === section.data.length;
                                    return (
                                        <EntryPickPlayerCard
                                            key={item.teamId}
                                            event={eventsMap[item.eventId]}
                                            isLast={isLast}
                                            isFirst={isFirst}
                                            {...item}
                                        />
                                    );
                                }}
                            />
                        ) : null
                    }
                </KeyboardAvoidingViewNoAnimation>
                <MaxWidthWrapper paddingHorizontal={'s16'} alignSelf={'center'} width={'100%'}>
                    <Box style={{ paddingBottom: safeInsets.bottom }}>
                        {showLeaderboards ? <ViewP2pLeaderboardsButton id={entry.id} /> : null}
                    </Box>
                </MaxWidthWrapper>
                <DynamicPayoutSheet
                    payoutSheetRef={dynamicPayoutSheet}
                    entryAmount={entry?.amount ?? 0}
                    dynamicMultipliers={dynamicMultipliers ?? []}
                    dynamicBoostedMultiplier={dynamicBoostedMultiplier}
                />
            </Box>
        </Screen>
    );
};
