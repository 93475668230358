import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { ActionItem } from '@/components/ActionItem';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { LimitLobby } from '../components/LimitLobby';
import { MAX_SINGLE_ENTRY_LIMIT_HEADER } from '../constants';
import { useUserLimits } from '../hooks/use-user-limits';

export const MaxSingleEntryLimitsScreen = () => {
    const { t } = useTranslation(['rg']);
    const { navigate } = useNavigation();
    const { data: limit } = useUserLimits({
        select: userLimits => userLimits.find(userLimit => userLimit.type === 'SINGLE_WAGER'),
    });

    const isLimitSet = limit && limit.amount > 0;
    const actionType = isLimitSet ? 'Edit' : 'Add';

    const handlePress = () => {
        const eventName = `Max Single Play Limit ${actionType}`;
        trackRG.maxSingleEntryLimitsScreen(eventName, limit ? { amount: limit.amount } : {});

        if (!limit) {
            BetrAnalytics.trackProductEvent(AnalyticsEvent.ADD_MAX_SINGLE_PLAY_LIMIT);
            navigate('MaxSingleEntryLimitAdd');
        } else {
            BetrAnalytics.trackProductEvent(AnalyticsEvent.EDIT_MAX_SINGLE_PLAY_LIMIT);
            navigate('MaxSingleEntryLimitEdit', { amount: limit.amount });
        }
    };
    return (
        <LimitLobby
            type="SINGLE_WAGER"
            {...MAX_SINGLE_ENTRY_LIMIT_HEADER}
            links={
                <ActionItem
                    label={t('max_single_play_limit')}
                    buttonLabel={actionType}
                    secondaryLabel={isLimitSet ? toLocaleCurrency(limit.amount) : ''}
                    handlePress={handlePress}
                    testID="maxSingleEntryLimitButton"
                />
            }
        />
    );
};
